export const docsIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M19.5045 10.932L12.1819 18.2553C10.4235 20.0137 7.57244 20.0137 5.81397 18.2553C4.0555 16.4968 4.0555 13.6458 5.81397 11.8873L11.8284 5.87354C12.5821 5.09319 13.6982 4.78022 14.7477 5.05494C15.7973 5.32966 16.6169 6.1493 16.8916 7.19885C17.1664 8.24839 16.8534 9.3645 16.073 10.1182L10.0593 16.1353C9.47044 16.7042 8.53429 16.6962 7.95524 16.1173C7.37619 15.5385 7.36791 14.6023 7.93663 14.0133L13.9511 7.99687" stroke="#394A64" stroke-width="1.5"/>
</svg>`

export const feedbackIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.3841 14.9961L15.0843 20.298L12.4785 20.8192L12.9997 18.2134L18.3023 12.9115C18.5777 12.6355 18.9516 12.4805 19.3415 12.4805C19.7314 12.4805 20.1052 12.6355 20.3807 12.9115L20.3869 12.917C20.9602 13.492 20.9589 14.4227 20.3841 14.9961Z" stroke="#394A64" stroke-width="1.5"/>
<path d="M10.3913 18.7337H6.22201C5.64635 18.7337 5.17969 18.2671 5.17969 17.6914V6.22591C5.17969 5.65026 5.64635 5.18359 6.22201 5.18359H16.6452C17.2208 5.18359 17.6875 5.65026 17.6875 6.22591V10.9163" stroke="#394A64" stroke-width="1.5"/>
<path d="M10.3916 8.3125H14.5609" stroke="#394A64" stroke-width="1.5"/>
<path d="M8.30664 11.4375H14.5605" stroke="#394A64" stroke-width="1.5"/>
<path d="M8.30664 14.5625H13.5182" stroke="#394A64" stroke-width="1.5"/>
</svg>`

export const feedbackIndicatorIcon = `<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5.5 7.5H4.5V4.5H5.5V7.5ZM5.5 3.5H4.5V2.5H5.5V3.5Z" fill="#089171"/>
</svg>
`
