import { FC, ReactNode, useState } from 'react'
import { StyledTooltipContainer, StyledTooltipWrap } from './index.style'

interface ITooltip {
    delay: number | null
    children: ReactNode
    direction: string
    content?: string
}

const Tooltip: FC<ITooltip> = ({ delay, children, direction, content }) => {
    let timeout: any
    const [active, setActive] = useState<boolean>(false)

    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true)
        }, delay || 400)
    }

    const hideTip = () => {
        clearInterval(timeout)
        setActive(false)
    }

    return (
        <StyledTooltipWrap
            className="Tooltip-Wrapper"
            // When to show the tooltip
            onMouseEnter={showTip}
            onMouseLeave={hideTip}
        >
            {/* Wrapping */}
            {children}
            {active && (
                <StyledTooltipContainer position={'top'}>
                    {/* Content */}
                    {content}
                </StyledTooltipContainer>
            )}
        </StyledTooltipWrap>
    )
}

export default Tooltip
