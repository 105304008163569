import { FC } from 'react'
import { useParams } from 'react-router-dom'
import UserMain from './UserMain'
import { ENavTab } from './navbar/config'

interface IProps {}

const UserReportPage: FC<IProps> = () => {
    const { userId } = useParams()
    return <UserMain userId={userId} tab={ENavTab.REPORTS}  />
}
export default UserReportPage
