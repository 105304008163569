import { FC, useEffect } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import {
    StyledCommentsContainer,
    StyledFunctionalAssessmentTableBody,
    StyledFunctionalAssessmentTableBodyItem,
    StyledFunctionalAssessmentTableBodyItemActivity,
    StyledFunctionalAssessmentTableContainer,
    StyledFunctionalAssessmentTableHeader,
    StyledFunctionalAssessmentTableHeaderItem,
    StyledResponsesContainer,
    StyledScoreContainer,
    StyledResetButtonContainer,
    StyledFunctionalAssessmentTableBodyItemLeft,
    StyledFunctionalAssessmentTableBodyItemMiddle,
    StyledFunctionalAssessmentTableBodyItemRight
} from './index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import {
    ICommentedScoredMultipleChoiceList,
    ICommentedScoredMultipleChoiceListItem,
    ICommentedScoredMultipleChoiceListOption,
    ISectionElement
} from '../index.models'
import { performFieldSynchronizationStep } from '../utils'
import { AwvTooltip } from '../AwvTooltip'
import useErrors from 'hooks/useErrors'
import IconButton from '@mui/material/IconButton'
import { Tooltip } from '@mui/material'
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp'
import { useResetValue } from 'hooks/useResetValue'

interface ICommentedScoredMultipleChoiceListProps {
    name: string
    titleColumnLabel: string
    scoreColumnLabel: string
    commentsColumnLabel: string
    totalScoreLabel: string
    totalScoreCommentsFieldName: string
    items: ICommentedScoredMultipleChoiceListItem[]
}

const CommentedScoredMultipleChoiceList: FC<ICommentedScoredMultipleChoiceListProps> = ({
    name,
    titleColumnLabel,
    scoreColumnLabel,
    commentsColumnLabel,
    totalScoreLabel,
    totalScoreCommentsFieldName,
    items
}) => {
    const { register, control, watch, setValue, getValues } = useFormContext()
    const errors = useErrors()
    const resetValue = useResetValue()
    const { fields, append, remove, move } = useFieldArray({ control, name })

    useEffect(() => {
        performFieldSynchronizationStep(items, fields, append, remove, move)
    }, [items, fields, append, remove, move])

    const itemsByName = items.reduce((acc: any, item: any) => {
        acc[item.name] = item
        return acc
    }, {})

    const totalScore = watch(name)?.reduce((acc: number, item: any) => acc + (item?.value ?? 0), 0)

    return (
        <StyledFunctionalAssessmentTableContainer>
            <StyledFunctionalAssessmentTableHeader>
                <StyledFunctionalAssessmentTableHeaderItem>
                    {titleColumnLabel}
                </StyledFunctionalAssessmentTableHeaderItem>
                <StyledFunctionalAssessmentTableHeaderItem>
                    {scoreColumnLabel}
                </StyledFunctionalAssessmentTableHeaderItem>
                <StyledFunctionalAssessmentTableHeaderItem>
                    {commentsColumnLabel}
                </StyledFunctionalAssessmentTableHeaderItem>
                <StyledFunctionalAssessmentTableHeaderItem></StyledFunctionalAssessmentTableHeaderItem>
            </StyledFunctionalAssessmentTableHeader>
            {fields.map((field: any, idx: number) => (
                <AwvTooltip
                    key={`CommentedScoredMultipleChoiceList_tooltip.${name}.${idx}`}
                    message={errors.getError(`${name}.${idx}`)}
                    placement="top-start"
                >
                    <StyledFunctionalAssessmentTableBody key={field.id}>
                        <StyledFunctionalAssessmentTableBodyItemLeft isInError={errors.getError(`${name}.${idx}`)}>
                            <StyledFunctionalAssessmentTableBodyItemActivity>
                                <Typography type={TYPOGRAPHY_TYPES.h3}>{itemsByName[field.name].label}</Typography>
                                <AwvTooltip
                                    key={`CommentedScoredMultipleChoiceList_tooltip_${name}_${idx}_value`}
                                    message={errors.getError(`${name}.${idx}.value`)}
                                >
                                    <StyledResponsesContainer isInError={errors.getError(`${name}.${idx}.value`)}>
                                        {itemsByName[field.name].options.map(
                                            (option: ICommentedScoredMultipleChoiceListOption) => (
                                                <label key={`option-${field.id}-${option.value}`}>
                                                    <Controller
                                                        name={`${name}.${idx}.value`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <input
                                                                id={`${name}.${idx}.${option.value}`}
                                                                {...field}
                                                                type="radio"
                                                                value={option.value}
                                                                checked={field.value === option.value}
                                                                onChange={() =>
                                                                    setValue(`${name}.${idx}.value`, option.value)
                                                                }
                                                            />
                                                        )}
                                                    />
                                                    {option.label}
                                                </label>
                                            )
                                        )}
                                    </StyledResponsesContainer>
                                </AwvTooltip>
                            </StyledFunctionalAssessmentTableBodyItemActivity>
                        </StyledFunctionalAssessmentTableBodyItemLeft>
                        <StyledFunctionalAssessmentTableBodyItemMiddle>
                            <StyledScoreContainer>
                                <input value={watch(`${name}.${idx}.value`) ?? ''} readOnly={true} />
                            </StyledScoreContainer>
                        </StyledFunctionalAssessmentTableBodyItemMiddle>
                        <StyledFunctionalAssessmentTableBodyItemRight>
                            <AwvTooltip message={errors.getError(`${name}.${idx}.comments`)}>
                                <StyledCommentsContainer isInError={errors.getError(`${name}.${idx}.comments`)}>
                                    <input {...register(`${name}.${idx}.comments`)} />
                                </StyledCommentsContainer>
                            </AwvTooltip>
                        </StyledFunctionalAssessmentTableBodyItemRight>
                        <StyledFunctionalAssessmentTableBodyItem>
                            <StyledResetButtonContainer>
                                {getValues(`${name}.${idx}.value`) !== null &&
                                    getValues(`${name}.${idx}.value`) !== undefined && (
                                        <Tooltip title="Reset">
                                            <IconButton
                                                onClick={() => {
                                                    resetValue(`${name}.${idx}.value`, `${name}.${idx}.comments`)
                                                }}
                                            >
                                                <HighlightOffSharpIcon sx={{ color: '#9e9e9e' }} />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                            </StyledResetButtonContainer>
                        </StyledFunctionalAssessmentTableBodyItem>
                    </StyledFunctionalAssessmentTableBody>
                </AwvTooltip>
            ))}
            <StyledFunctionalAssessmentTableBody key={`${name}`}>
                <StyledFunctionalAssessmentTableBodyItemLeft>
                    <StyledFunctionalAssessmentTableBodyItemActivity>
                        <Typography type={TYPOGRAPHY_TYPES.h3}>{totalScoreLabel}</Typography>
                    </StyledFunctionalAssessmentTableBodyItemActivity>
                </StyledFunctionalAssessmentTableBodyItemLeft>
                <StyledFunctionalAssessmentTableBodyItemMiddle>
                    <StyledScoreContainer>
                        <input value={totalScore} readOnly={true} />
                    </StyledScoreContainer>
                </StyledFunctionalAssessmentTableBodyItemMiddle>
                <StyledFunctionalAssessmentTableBodyItemRight>
                    <AwvTooltip message={errors.getError(totalScoreCommentsFieldName)}>
                        <StyledCommentsContainer isInError={errors.getError(totalScoreCommentsFieldName)}>
                            <input {...register(totalScoreCommentsFieldName)} />
                        </StyledCommentsContainer>
                    </AwvTooltip>
                </StyledFunctionalAssessmentTableBodyItemRight>
                <StyledFunctionalAssessmentTableBodyItem></StyledFunctionalAssessmentTableBodyItem>
            </StyledFunctionalAssessmentTableBody>
        </StyledFunctionalAssessmentTableContainer>
    )
}

export const renderCommentedScoredMultipleChoiceList = (element: ISectionElement, key: string) => {
    const commentedScoredMultipleChoiceList = element as ICommentedScoredMultipleChoiceList
    return <CommentedScoredMultipleChoiceList key={key} {...commentedScoredMultipleChoiceList} />
}
