import { IHeaderInfo } from 'models/headerInfo'
import moment from 'moment'

export const SUPER_ADMIN_HEADERS_LIST = [
    { displayName: 'First Name', fieldName: 'FirstName' },
    { displayName: 'Last Name', fieldName: 'LastName' },
    { displayName: 'Phone Number', fieldName: 'PhoneNumber' },
    { displayName: 'Email', fieldName: 'Email' },
    { displayName: 'Role', fieldName: 'Role' },
    { displayName: 'Last Login', fieldName: 'LastLogin' },
    { displayName: '', fieldName: 'IsActive' }
]
export const ADMIN_HEADERS_LIST: IHeaderInfo[] = [
    { displayName: 'First Name', fieldName: 'FirstName' },
    { displayName: 'Last Name', fieldName: 'LastName' },
    { displayName: 'Phone Number', fieldName: 'Phone' },
    { displayName: 'Email', fieldName: 'Email' },
    { displayName: 'Role', fieldName: 'RoleId' },
    { displayName: 'Status', fieldName: 'UserState' },
    { displayName: 'Last Login', fieldName: 'LastLoginDate' },
    { displayName: 'Actions', fieldName: 'Actions' }
]

export enum ETableType {
    SUPER_ADMIN = 'SUPER_ADMIN',
    ADMIN = 'ADMIN'
}

const formatDate = (value: string, format: string, localTimeZone: boolean) => {
    if (value) {
        const localDate = (localTimeZone ? moment(value) : moment.utc(value)).local()
        return `${localDate.format(format)}`
    } else {
        return null
    }
}

export const setFormatedDateTime = (value: string, localTimeZone: boolean = false) =>
    formatDate(value, 'MM-DD-YYYY hh:mm A', localTimeZone)

export const setFormattedDateTimeWithSlash = (value: string, localTimeZone: boolean = false) =>
    formatDate(value, 'MM/DD/YYYY hh:mm A', localTimeZone)

export const setFormatedDate = (value: string, localTimeZone: boolean = false) =>
    formatDate(value, 'MM-DD-YYYY', localTimeZone)

export const setFormattedDateWithSlash = (value: string, localTimeZone: boolean = false): string =>
    formatDate(value, 'MM/DD/YYYY', localTimeZone)
