import CustomSvgIcon from 'components/CustomSvgIcon'
import NpiSelect from 'components/NpiSelect'
import PaginationController from 'components/PaginationController'
import SearchTextField from 'components/SearchTextField'
import { StyledSpinner, StyledSpinnerContainer } from 'components/Spinner/index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import useCurrentUser from 'hooks/useCurrentUser'
import useDebounce from 'hooks/useDebounce'
import { useMemberProfileListConfig } from 'hooks/useListConfig'
import { ERoles } from 'models/enums/role'
import { SortDirection } from 'models/sortColumn'
import { IMemberProfile } from 'pages/hcp/users/create/index.models'
import { noData } from 'pages/provider/memberOutreach/icons'
import { StyledEmptyState } from 'pages/provider/memberOutreach/index.style'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    exportAdminPatientsToExcel,
    exportPatientsToExcel,
    fetchAdminMemberProfiles,
    fetchMemberProfiles,
    getAwvYears,
    resetMemberDetails
} from 'redux/slices/memberProfile'
import { fetchProviderNpis, Pulse8State, setCurrentNpi } from 'redux/slices/pulse8'
import { dispatch } from 'redux/store'
import { appPath } from 'utilities/appPath'
import {
    StyledActionsContainer,
    StyledCalendarIcon,
    StyledHeader,
    StyledMemberProfileContainerWrap,
    StyledSearchBarContainer,
    StyledCalendarDropDownContainer
} from '../list/index.style'
import MembersTable from './membersTable'
import { StyledAppendedSpinner, StyledExportButton, StyledExportButtonGroup, StyledExportSpacer } from './index.style'
import { excelExport, excelExportActive } from './icons'
import SmallSpinner from 'components/SmallSpinner'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import usePostCustomEvent from 'hooks/usePostCustomEvent'
import smallCalendar from 'assets/smallCalendar.svg'
import DropDownList from 'components/DropDownList'
import { calendarIncentivesDropDownListStyleParams } from 'components/TaxIdProviderInfo/index.style'

interface ICallCenterAdminListProps {}

const CallCenterAdminList: FC<ICallCenterAdminListProps> = ({}) => {
    const navigate = useNavigate()

    const currentUser = useCurrentUser()

    const isAdmin =
        currentUser?.primaryRole === ERoles.CallCenterAdmin ||
        currentUser?.primaryRole === ERoles.ProviderRep ||
        currentUser?.primaryRole === ERoles.Quality

    const { currentNpi, providerNpis, providerNpisLoaded } = useSelector(
        (state: { pulse8: Pulse8State }) => state.pulse8
    )

    const { memberProfiles, memberProfilesLoading, totalNumberOfMemberProfiles, filteredNumberOfMemberProfiles } =
        useSelector((state: { memberProfile: any }) => state.memberProfile)

    const { awvYears } = useSelector((state: { memberProfile: any }) => state.memberProfile)
    const [initialContentLoaded, setInitialContentLoaded] = useState(false)
    const listConfig = useMemberProfileListConfig()
    const debouncedFilter = useDebounce(listConfig.effectiveFilter, 500)
    const [isActiveExcelButton, setIsActiveExcelButton] = useState(false)
    const [yearsDropDownData, setYearsDropDownData] = useState<{ label: string; value: number }[]>([])

    const changeYearEvent = usePostCustomEvent(pageTitle.PatientsSummary, customEvents.ChangeYear)
    const setSelectedValue = (value: any) => {
        if (value !== listConfig.year) {
            listConfig.setYear(value)
            changeYearEvent()
        }
    }

    useEffect(() => {
        if (!providerNpisLoaded) {
            dispatch(fetchProviderNpis())
        }
    }, [providerNpisLoaded])

    useEffect(() => {
        if (!awvYears) {
            dispatch(getAwvYears())
        }
    }, [awvYears])

    useEffect(() => {
        dispatch(resetMemberDetails())
    }, [])

    const [members, setMembers] = useState<IMemberProfile[]>([])

    useEffect(() => {
        setMembers(memberProfiles)
    }, [memberProfiles])

    useEffect(() => {
        setYearsDropDownData(
            (awvYears ?? [new Date().getFullYear()]).map((year: number) => ({ label: year.toString(), value: year }))
        )
    }, [awvYears])

    const changeProviderEvent = usePostCustomEvent(pageTitle.PatientsSummary, customEvents.ChangeProvider)
    const setNpi = (newNpi: string) => {
        changeProviderEvent()

        if (currentNpi && newNpi && currentNpi !== newNpi) {
            setInitialContentLoaded(false)
            listConfig.setPageNumber(1)
            listConfig.setFilter('')
            dispatch(setCurrentNpi(newNpi))
        }
    }

    const handleEditMember = (memberId: number | null) => {
        if (currentUser?.primaryRole === ERoles.CallCenterAdmin || currentUser?.primaryRole === ERoles.ProviderRep) {
            navigate(appPath(`/members/memberInformation/${memberId}`))
        } else {
            navigate(appPath(`/members/memberInformation/${memberId}-${currentNpi}`))
        }
    }

    const handleEditMemberAwvFile = (e: any, memberId: number | null) => {
        let yr = listConfig.year
        e.stopPropagation()
        if (currentUser?.primaryRole === ERoles.CallCenterAdmin || currentUser?.primaryRole === ERoles.ProviderRep) {
            navigate(appPath(`/members/memberInformation/awvFile/${memberId}-${yr}`))
        } else {
            navigate(appPath(`/members/memberInformation/awvFile/${memberId}-${currentNpi}-${yr}`))
        }
    }

    useEffect(() => {
        if (!currentNpi && !isAdmin) return

        const memberListRequest = {
            npi: currentNpi,
            pageNumber: listConfig.pageNumber,
            size: 10,
            sortColumn: listConfig.sortColumn,
            isDescending: listConfig.sortDirection === SortDirection.descending,
            filter: debouncedFilter,
            year: listConfig.year
        }

        // Admins can see all members, so don't send the NPI
        if (isAdmin) {
            delete memberListRequest.npi
            dispatch(fetchAdminMemberProfiles(memberListRequest))
            setInitialContentLoaded(true)
        } else {
            dispatch(fetchMemberProfiles(memberListRequest))
            setInitialContentLoaded(true)
        }
    }, [
        currentNpi,
        debouncedFilter,
        listConfig.sortColumn,
        listConfig.sortDirection,
        listConfig.pageNumber,
        isAdmin,
        listConfig.year
    ])

    const [isExcelDownloading, setIsExcelDownloading] = useState(false)

    const excelExportEvent = usePostCustomEvent(pageTitle.PatientsSummary, customEvents.ExcelExport)
    const handleExport = () => {
        excelExportEvent()
        setIsExcelDownloading(true)
        const memberListRequest = {
            npi: currentNpi,
            pageNumber: 1,
            size: 1,
            sortColumn: listConfig.sortColumn,
            isDescending: listConfig.sortDirection === SortDirection.descending,
            filter: debouncedFilter,
            year: listConfig.year
        }

        if (isAdmin) {
            dispatch(exportAdminPatientsToExcel(memberListRequest)).then((response: any) => {
                setIsExcelDownloading(false)
            })
        } else {
            dispatch(exportPatientsToExcel(memberListRequest)).then((response: any) => {
                setIsExcelDownloading(false)
            })
        }
    }

    return (
        <>
            <StyledMemberProfileContainerWrap>
                <StyledHeader>
                    <Typography type={TYPOGRAPHY_TYPES.p}>Patients</Typography>
                    {currentUser.primaryRole === ERoles.OfficeAdmin && providerNpis.length > 0 && (
                        <div>
                            <NpiSelect providerNpis={providerNpis} selectedNpi={currentNpi} setSelectedNpi={setNpi} />
                        </div>
                    )}
                </StyledHeader>

                {(initialContentLoaded || (providerNpisLoaded && providerNpis.length === 0)) && (
                    <>
                        <StyledActionsContainer>
                            <StyledSearchBarContainer>
                                {totalNumberOfMemberProfiles > 0 && (
                                    <SearchTextField
                                        placeholder={'Search by name, email, phone number and more...'}
                                        value={listConfig.filter}
                                        loading={memberProfilesLoading}
                                        onClearSearch={listConfig.clearFilter}
                                        onValueChanged={listConfig.setFilter}
                                    />
                                )}
                            </StyledSearchBarContainer>
                            <StyledCalendarDropDownContainer>
                                <StyledCalendarIcon image={smallCalendar} />
                                <DropDownList
                                    key="year-key"
                                    placeholder="Year"
                                    options={yearsDropDownData}
                                    selectedValue={listConfig.year}
                                    setSelectedValue={(value) => setSelectedValue(value)}
                                    styleParams={calendarIncentivesDropDownListStyleParams}
                                />
                            </StyledCalendarDropDownContainer>

                            {totalNumberOfMemberProfiles > 0 && (
                                <StyledExportButtonGroup>
                                    <>
                                        <StyledExportButton
                                            onClick={() => handleExport()}
                                            onMouseDown={() => setIsActiveExcelButton(true)}
                                            onMouseUp={() => setIsActiveExcelButton(false)}
                                        >
                                            <StyledExportSpacer />
                                            {isActiveExcelButton ? (
                                                <CustomSvgIcon
                                                    size={25}
                                                    outerMargin={'0px'}
                                                    isActive={false}
                                                    iconSet={{ icon: excelExportActive }}
                                                    svg
                                                ></CustomSvgIcon>
                                            ) : (
                                                <CustomSvgIcon
                                                    size={25}
                                                    outerMargin={'0px'}
                                                    isActive={false}
                                                    iconSet={{ icon: excelExport }}
                                                    svg
                                                ></CustomSvgIcon>
                                            )}
                                            <span>Export To Excel</span>
                                        </StyledExportButton>
                                        <StyledAppendedSpinner>
                                            {isExcelDownloading && <SmallSpinner />}
                                        </StyledAppendedSpinner>
                                    </>
                                </StyledExportButtonGroup>
                            )}
                        </StyledActionsContainer>

                        {memberProfilesLoading ? (
                            <StyledSpinnerContainer>
                                <StyledSpinner viewBox="0 0 50 50">
                                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2.5" />
                                </StyledSpinner>
                            </StyledSpinnerContainer>
                        ) : (
                            ''
                        )}

                        {memberProfiles && filteredNumberOfMemberProfiles > 0 && (
                            <>
                                <MembersTable
                                    npi={currentNpi}
                                    memberProfiles={members}
                                    sortCallback={listConfig.setSorting}
                                    sortDirection={listConfig.sortDirection}
                                    sortColumn={listConfig.sortColumn}
                                    onEditMember={handleEditMember}
                                    onEditMemberAwvFile={(e, memberId) => handleEditMemberAwvFile(e, memberId)}
                                />

                                <PaginationController
                                    totalItems={filteredNumberOfMemberProfiles}
                                    selectedPage={listConfig.pageNumber}
                                    selectedPageChanged={listConfig.setPageNumber}
                                    singularRecordName="Member"
                                    pluralRecordName="Members"
                                />
                            </>
                        )}

                        {totalNumberOfMemberProfiles === 0 && !memberProfilesLoading && (
                            <StyledEmptyState>
                                <CustomSvgIcon iconSet={{ icon: noData }} svg></CustomSvgIcon>
                                <Typography type={TYPOGRAPHY_TYPES.h4}>Data not available at the moment</Typography>
                                <Typography type={TYPOGRAPHY_TYPES.p}>Please check back later</Typography>
                            </StyledEmptyState>
                        )}

                        {totalNumberOfMemberProfiles > 0 &&
                            filteredNumberOfMemberProfiles === 0 &&
                            !memberProfilesLoading && (
                                <StyledEmptyState>
                                    <CustomSvgIcon iconSet={{ icon: noData }} svg></CustomSvgIcon>
                                    <Typography type={TYPOGRAPHY_TYPES.h4}>
                                        Your search didn’t match any results
                                    </Typography>
                                    <Typography type={TYPOGRAPHY_TYPES.p}>
                                        Please modify your search and try again
                                    </Typography>
                                </StyledEmptyState>
                            )}
                    </>
                )}
            </StyledMemberProfileContainerWrap>
        </>
    )
}

export default CallCenterAdminList
