import styled, { css } from 'styled-components'
import { pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { white } = UI_COLORS

export const StyledToastContainer = styled.article<{ isImpersonating: boolean }>`
    position: fixed;
    width: ${pxToRem(360)};
    height: ${pxToRem(48)};
    background-color: ${white};
    right: ${pxToRem(30)};
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
    border-radius: ${pxToRem(4)};
    z-index: 1000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    ${(props) => {
        return css`
            top: ${props.isImpersonating ? pxToRem(155) : pxToRem(90)};
        `
    }}
`

export const StyledCloseButton = styled.button<{ image?: string }>`
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
    margin-right: ${pxToRem(16)};

    border: 0;

    background-size: contain;
    background-color: transparent;

    cursor: pointer;

    ${(props) => {
        if (props.image) {
            return css`
                background-image: url(${props.image});
            `
        }
    }};
`
export const StyledToastInnerContainer = styled.article<{ variant?: string }>`
    display: flex;
    p {
        ${(props) => {
            if (props.variant) {
                return css`
                    color: ${props.variant};
                `
            }
        }};
        font-weight: 400;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(20)};
    }

    figure {
        margin: 0;
        margin-left: ${pxToRem(16)};
        margin-right: ${pxToRem(16)};
        transform: translateY(2px);
    }
`
