import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js"
import CustomSvgIcon from "components/CustomSvgIcon"
import DropDownList from "components/DropDownList"
import { editPen } from "components/PaginationController/icons"
import { emptyAddress, FullLocationIndexes, IEditAddress, ILocationData, ILocationDetailData, INPIDetail, INPILocationsData, IOfficeAdminProviderInfo, ITaxIdNpiData, IUserIdTracker } from "pages/hcp/users/create/index.models"
import { StyledAdminAddLocationButtonWrap } from "pages/hcp/users/create/index.style"
import { getUsaStateAbbreviationByName } from "pages/hcp/users/create/NewOfficeAdmin/AddressModal/index.models"
import { SetStateAction, useEffect, useState } from "react"
import { IAddressModalInfo, TranslateToClientLocation } from "utilities/addressUtilities"
import { locationDropDownListStyleParams, NpiHorizontalLine, StyledAddUserLink, StyledCenterSecondaryEditLocationIcon, StyledDeletePlaceHolder, StyledDeleteSecondaryLocationButton, StyledEditLocationButton, StyledLocationErrorMessage, StyledLocationGroup, StyledLocationPlaceHolder, StyledLocationSelectContainer, StyledLocationWrap, TaxIdHalfHorizontalLine, TaxIdVerticalLine, NpiVerticalLine, NpiHalfHorizontalLine } from './index.style';
import { LocationModalDialog } from "./LocationModalDialog"
import { isValidAreaCode } from '../../utilities/stringUtilities';
import { useSelector } from "react-redux"
import { handleAddingInitialLocation } from "./common"

interface ILocationListProps {
    userId:string
    taxId: string
    npidata  : INPILocationsData
    providerInfo: ITaxIdNpiData
    taxIdIndex: number
    npiIndex: number
    currentProviderInfo: IOfficeAdminProviderInfo
    validFormCallback: (isValid: boolean) => void
    setProviderInfo: (providerInfo: IOfficeAdminProviderInfo) => void
    isLocationDisabled: boolean
    isReadOnly: boolean
    dictionaryOfNpis: Map<string, INPIDetail[]>
    dictionaryOfLocations: Map<string, ILocationDetailData[]>
    checkIfLocationsAreMissingInDropDown: (taxId: string, npiData: INPILocationsData) => boolean
    setNewLocationAdded : React.Dispatch<React.SetStateAction<FullLocationIndexes>>
    setCurrentNpi:  React.Dispatch<React.SetStateAction<string>>
    handleTaxIdInput: (e: any, index: number) => void
    handleTaxIdBlur: (e: React.FocusEvent<HTMLInputElement, Element>, taxIdIndex: number) => void
    handleNpiSelection: (taxIdIndex: number, npiIndex: number, npi: string) => void
}



export const LocationList = ({ userId, taxId, providerInfo, npidata, 
    taxIdIndex, npiIndex, 
    currentProviderInfo, validFormCallback, setProviderInfo, 
    isLocationDisabled, isReadOnly, 
    dictionaryOfNpis, dictionaryOfLocations,
    checkIfLocationsAreMissingInDropDown, setNewLocationAdded,
    setCurrentNpi, handleTaxIdInput, handleTaxIdBlur, handleNpiSelection }: ILocationListProps) => {

    const appInsights = useAppInsightsContext() // set up app insights
    const [trackUserOpeningLocationDialog, setTrackUserOpeningLocationDialog] = useState<IUserIdTracker>()

    const validAreaCodes = useSelector((state: { user: any }) => state.user.validAreaCodes.areaCodes) as number[]


    const trackOpenAddressDialogEvent = useTrackEvent(
        appInsights,
        'UpdateOfficeAdminAddressModal',
        trackUserOpeningLocationDialog
    )

    useEffect(() => {
        if (trackUserOpeningLocationDialog) {
            trackOpenAddressDialogEvent(trackUserOpeningLocationDialog)
        }
    }, [trackOpenAddressDialogEvent, trackUserOpeningLocationDialog])
        

    const concatenateLocationInfo = (location: ILocationDetailData) => {
        return `${location.address1}, ${
            location.address2 == null || location.address2.trim() === '' ? '' : location.address2 + ','
        } ${location.city}, ${location.state} ${location.zip}`
    }


    const [modalLocationPosition, setModalLocationPosition] = useState<FullLocationIndexes>({
        taxIdIndex: 0,
        npiIndex: 0,
        locationIndex: 0
    })

    const initialAddressModalInfo: IAddressModalInfo = { isShown: false, locationType: 1 }
    const [addressModalInfo, setAddressModalInfo] = useState(initialAddressModalInfo)
    const [editingAddress, setEditingAddress] = useState<IEditAddress>({
        isEditable: false,
        address: emptyAddress,
        id: null,
        isNewAddress: false
    })
   

    const concatenateLocationClientInfo = (location: ILocationData) => {
        return `${location.address}, ${
            location.suite == null || location.suite.trim() === '' ? '' : location.suite + ','
        } ${location.city}, ${location.state} ${location.zipCode}`
    }


    function handleDuplicateLocations(
        newData: { taxIdProviderGroupings: ITaxIdNpiData[] },
        taxIdIndex: number,
        npiIndex: number,
        locationIndex: number
    ) {
        const locations = newData.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].locations
        const otherEquivalentLocation = locations.find(
            (location, index) =>
                concatenateLocationClientInfo(location) === concatenateLocationClientInfo(locations[locationIndex]) &&
                locationIndex !== index
        )
        const isDuplicate = otherEquivalentLocation !== undefined && otherEquivalentLocation !== null
        if (isDuplicate) {
            // clear the required field errors
            newData.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].locations[locationIndex].error = true
            newData.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].locations[locationIndex].errorMessage =
                'DuplicateLocation'
        }

        return isDuplicate
    }

    function handleLocationSelection(
        taxIdIndex: number,
        npiIndex: number,
        locationIndex: number,
        npi: string,
        locationSelectionValue: any
    ): void {
        // reset the validity of the parent form
        validFormCallback(true)

        // clear the required field errors
        currentProviderInfo.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].locations[locationIndex].error =
            false
        currentProviderInfo.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].locations[
            locationIndex
        ].errorMessage = 'Required'

        // Insert the selected npi into the data structure with its matching taxid

        if (locationSelectionValue === 'notlisted') {
            setModalLocationPosition({ taxIdIndex, npiIndex, locationIndex })
            setAddressModalInfo({ isShown: true, locationType: locationIndex === 0 ? 1 : 2 })
            setEditingAddress({ ...editingAddress, isNewAddress: true, address: { ...emptyAddress } })
            locationSelectionValue = '0'
            return
        }

        // check if duplicate npi is being selected within the same tax id

        const newData = { ...currentProviderInfo }

        // need to add npi to provider structure
        const taxIdData = newData.taxIdProviderGroupings[taxIdIndex]
        taxIdData.providers[npiIndex].npi = npi
        taxIdData.providers[npiIndex].locations[locationIndex].selectedLocationIndex = Number(locationSelectionValue)

        const npiProviders = newData.taxIdProviderGroupings[taxIdIndex].providers

        const npiLocations = npiProviders[npiIndex]
        const locationList = npiLocations.locations

        locationList[locationIndex] = TranslateToServerLocation(
            dictionaryOfLocations.get(`${npi}`)[Number(locationSelectionValue)],
            locationIndex
        )
        locationList[locationIndex].selectedLocationIndex = Number(locationSelectionValue)
        const isDuplicate = handleDuplicateLocations(newData, taxIdIndex, npiIndex, locationIndex)

        newData.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].locations[locationIndex].error = isDuplicate
        if (isDuplicate) {
            newData.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].locations[locationIndex].errorMessage =
                'Duplicate Location'
        }

        setProviderInfo(newData)
        setCurrentNpi(npi)

        // add the location now
    }

    const handleEditSelectedLocation = (taxIdIndex: number, npiIndex: number, locationIndex: number): void => {
        const temp = { ...currentProviderInfo }
        const officeLocation =
            locationIndex == null
                ? null
                : temp.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].locations[locationIndex]
        if (locationIndex || locationIndex === 0) {
            setEditingAddress({
                isEditable: true,
                address: {
                    ...officeLocation,
                    address1: officeLocation?.address,
                    address2: officeLocation?.suite,
                    zip: officeLocation?.zipCode,
                    ezCapLocationId: officeLocation?.ezCapLocationId
                },
                id: locationIndex,
                isNewAddress: false
            })

            setModalLocationPosition({ taxIdIndex, npiIndex, locationIndex })
            setAddressModalInfo({ isShown: true, locationType: locationIndex === 0 ? 1 : 2 })
        }
    }

    const handleCloseModal = () => {
        setAddressModalInfo({ isShown: false, locationType: 1 })
    }


    const handleAddressFromModal = (formData: any) => {
        formData = { ...formData, state: getUsaStateAbbreviationByName(formData.state) } // use abbreviated state
        const { taxIdIndex, npiIndex, locationIndex } = modalLocationPosition
        const newData = { ...currentProviderInfo }
        const taxId = newData.taxIdProviderGroupings[taxIdIndex].taxId
        const npi = newData.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].npi
        const existingLocations = [...newData.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].locations]

        const locationsFromServerLength = dictionaryOfLocations.get(`${npi}`).length

        if (editingAddress.isNewAddress || formData.ezCapLocationId == null) {
            // add the new location to the dictionary
            dictionaryOfLocations.set(`${npi}`, [
                ...dictionaryOfLocations.get(`${npi}`),
                { ...TranslateToClientLocation(formData) }
            ])

            const updatedLocations = existingLocations.map((loc, idx) => {
                if (idx === locationIndex) {
                    return {
                        ...formData,
                        selectedLocationIndex: locationsFromServerLength,
                        locationType: locationIndex === 0 ? 1 : 2
                    }
                } else {
                    return { ...loc }
                }
            })

            newData.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].locations = updatedLocations
            setProviderInfo(newData)
            const locationDetailFromForm: ILocationDetailData = TranslateToClientLocation(formData)

            setAddressModalInfo({ isShown: false, locationType: locationIndex === 0 ? 1 : 2 })
            setCurrentNpi(npi)
        } else {
            let replacedAddress: ILocationDetailData = null
            const locationList = newData.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].locations
            const locationOptions = dictionaryOfLocations.get(`${npi}`)
            const selectedIndex = locationOptions.length

            replacedAddress = {
                address2: formData.suite,
                address1: formData.address,
                city: formData.city,
                state: formData.state,
                zip: formData.zipCode,
                ezCapLocationId: formData.ezCapLocationId,
                officePhoneNumber: formData.officePhoneNumber,
                isPrimary: formData.locationType === 1,
                locationId: formData.locationId,
            }

            const newNpiLocationsArray = [...locationOptions, replacedAddress]

            dictionaryOfLocations.set(`${npi}`, newNpiLocationsArray)

            setTrackUserOpeningLocationDialog({
                ...trackUserOpeningLocationDialog,
                user: userId,
                address: concatenateLocationInfo(replacedAddress)
            })

            locationList[locationIndex] = TranslateToServerLocation(replacedAddress, locationIndex, selectedIndex)
            setProviderInfo(newData)
        }

        handleCloseModal()
    }

   

    const [editSelectedLocation, setEditSelectedLocation] = useState<FullLocationIndexes>({
        taxIdIndex: -1,
        npiIndex: -1,
        locationIndex: -1
    })

    useEffect(() => {
        if (editSelectedLocation.taxIdIndex !== -1) {
            handleEditSelectedLocation(
                editSelectedLocation.taxIdIndex,
                editSelectedLocation.npiIndex,
                editSelectedLocation.locationIndex
            )
        }
    }, [editSelectedLocation])



    const TranslateToServerLocation = (
        location: ILocationDetailData,
        locationIndex: number,
        selectedOptionIndex = -1
    ): ILocationData => {
        const translatedLocation: ILocationData = {
            locationId: location.locationId,
            city: location.city,
            state: location.state,
            zipCode: location.zip,
            address: location.address1,
            suite: location.address2,
            officePhoneNumber: location.officePhoneNumber,
            locationType: locationIndex === 0 ? 1 : 2,
            selectedLocationIndex: selectedOptionIndex,
            error: false,
            errorMessage: '',
            ezCapLocationId: location.ezCapLocationId
        }

        return translatedLocation
    }

    const [deleteSecondaryLocation, setDeleteSecondaryLocation] = useState<FullLocationIndexes>({
        taxIdIndex: -1,
        npiIndex: -1,
        locationIndex: -1
    })

    useEffect(() => {
        if (deleteSecondaryLocation.taxIdIndex !== -1) {
            handleDeleteSecondaryLocation(
                deleteSecondaryLocation.taxIdIndex,
                deleteSecondaryLocation.npiIndex,
                deleteSecondaryLocation.locationIndex
            )
        }
    }, [deleteSecondaryLocation])

    const handleDeleteProvider = (taxIdIndex: number, npiIndex: number) => {
        // special case, remove taxid and all contents
        if (npiIndex === 0 && taxIdIndex !== 0) {
            let temp = { ...currentProviderInfo }
            temp.taxIdProviderGroupings = [
                ...temp.taxIdProviderGroupings.filter((taxGrouping, index) => index !== taxIdIndex)
            ]
            setProviderInfo(temp)
            return
        }

        // otherwise just remove row for particular NPI
        let temp = { ...currentProviderInfo }
        const updatedProviderList: INPILocationsData[] = [
            ...temp.taxIdProviderGroupings[taxIdIndex].providers.filter((provider, index) => index !== npiIndex)
        ]
        temp.taxIdProviderGroupings[taxIdIndex].providers = updatedProviderList
        setProviderInfo(temp)
    }

    const handleDeleteSecondaryLocation = (taxIdIndex: number, npiIndex: number, locationIndex: number) => {
        if (locationIndex === 0) {
            handleDeleteProvider(taxIdIndex, npiIndex)
            return
        }

        const temp = { ...currentProviderInfo }
        const locations = temp.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].locations.filter(
            (location, index) => locationIndex !== index
        )
        temp.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].locations = locations
        setProviderInfo(temp)
    }

    

    const handleAddingNewLocation = (taxIdIndex: number, npiIndex: number) => {
        const newData = { ...currentProviderInfo }
        validFormCallback(false)       
    

        const updatedLocationList: ILocationData[] = [
            ...newData.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].locations,
            {
                locationId: null,
                locationType: 2,
                address: '',
                suite: '',
                city: '',
                state: '',
                zipCode: '',
                officePhoneNumber: '',
                selectedLocationIndex: -1,
                error: false,
                errorMessage: '',
                ezCapLocationId: null
            }
        ]
        newData.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].locations = updatedLocationList
        setProviderInfo(newData)
        setNewLocationAdded({
            taxIdIndex,
            npiIndex,
            locationIndex: newData.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].locations.length - 1
        } as FullLocationIndexes)
    }

    const getErrorInfo = (taxIdIndex: number, npiIndex: number, locationIndex: number) => {
        const errorInfo = currentProviderInfo.taxIdProviderGroupings[
            taxIdIndex
        ].providers[npiIndex].locations[locationIndex]
            .error
        return errorInfo
    }


    return (
        <div key={`taxid_npi_${providerInfo.taxId}_${npidata.npi}`}>
            {npidata.locations.map((location, locationIndex) => {
                return (
                    <StyledLocationWrap 
                        key={`styled_loc_wrap_${taxId}_${npidata?.npi}_${locationIndex}_${location.ezCapLocationId ?? '00'}}`}
                    >
                       {locationIndex == 0 ? <NpiHorizontalLine /> : '' }

                        {(locationIndex < npidata.locations.length - 1) ? <NpiVerticalLine /> : ''}
                        {(locationIndex < npidata.locations.length - 1) ? <NpiHalfHorizontalLine /> : ''}
                        <StyledLocationGroup key={`loc_group_taxid_npi_${providerInfo.taxId}_${npidata.npi}_${locationIndex}_${location.ezCapLocationId ?? '00'}`}>
                            <StyledLocationSelectContainer key={`container_taxid_npi_${providerInfo.taxId}_${npidata.npi}_${locationIndex}_${location.ezCapLocationId ?? '00'}`}>
                                <label key={`label_loc_${taxId}_${npidata?.npi}_${locationIndex}_${location.ezCapLocationId ?? '00'}`}>
                                    {locationIndex === 0
                                        ? 'Primary Location'
                                        : 'Secondary Location'}
                                </label>
                                <DropDownList key={`dropdown_taxid_npi_${providerInfo.taxId}_${npidata.npi}_${locationIndex}_${location.ezCapLocationId ?? '00'}`}
                                    options={dictionaryOfLocations
                                        .get(`${npidata.npi}`)
                                        ?.map(
                                            (
                                                locationData: ILocationDetailData,
                                                locationSelectionIndex: number
                                            ) => ({
                                                label: concatenateLocationInfo(
                                                    locationData
                                                ),
                                                value: locationSelectionIndex as any
                                            })
                                        )
                                        .concat([
                                            {
                                                label: `My ${
                                                    locationIndex === 0
                                                        ? 'Primary'
                                                        : 'Secondary'
                                                } Location Is Not Listed`,
                                                value: 'notlisted'
                                            }
                                        ])}
                                    selectedValue={location.selectedLocationIndex}
                                    setSelectedValue={(value) =>
                                        handleLocationSelection(
                                            taxIdIndex,
                                            npiIndex,
                                            locationIndex,
                                            npidata.npi,
                                            value
                                        )
                                    }
                                    placeholder={
                                        locationIndex === 0
                                            ? 'Primary Location'
                                            : 'Secondary Location'
                                    }
                                    isDisabled={isLocationDisabled}
                                    isReadOnly={isReadOnly || isLocationDisabled}
                                    isInError={
                                        getErrorInfo(taxIdIndex, npiIndex, locationIndex)
                                    }
                                    styleParams={locationDropDownListStyleParams}
                                />
                            </StyledLocationSelectContainer>

                            {!isReadOnly ? (
                                <>
                                    <StyledAdminAddLocationButtonWrap key={`styled_admin_add_button_taxid_npi_${providerInfo.taxId}_${npidata.npi}_${locationIndex}_${location.ezCapLocationId ?? '00'}`}>
                                        <StyledEditLocationButton key={`edit_location_button_taxid_npi_${providerInfo.taxId}_${npidata.npi}_${locationIndex}_${location.ezCapLocationId ?? '00'}`}
                                            style={
                                                checkIfLocationsAreMissingInDropDown(
                                                    taxId,
                                                    npidata
                                                ) ||
                                                location.selectedLocationIndex === -1
                                                    ? { visibility: 'hidden' }
                                                    : {}
                                            }
                                            onClick={() =>
                                                setEditSelectedLocation({
                                                    taxIdIndex,
                                                    npiIndex,
                                                    locationIndex
                                                })
                                            }
                                        >
                                            <StyledCenterSecondaryEditLocationIcon key={`center_secondary_location_icon_taxid_npi_${providerInfo.taxId}_${npidata.npi}_${locationIndex}_${location.ezCapLocationId ?? '00'}`}>
                                                <CustomSvgIcon
                                                    size={18}
                                                    iconSet={{ icon: editPen }}
                                                    svg
                                                />
                                            </StyledCenterSecondaryEditLocationIcon>
                                            <div key={`Edit_location_icon_taxid_npi_${providerInfo.taxId}_${npidata.npi}_${locationIndex}_${location.ezCapLocationId ?? '00'}`}>
                                                <p>Edit</p>
                                            </div>
                                        </StyledEditLocationButton>
                                        {locationIndex > 0 ||
                                        (locationIndex === 0 && npiIndex > 0) ||
                                        taxIdIndex > 0 ? (
                                            <StyledDeleteSecondaryLocationButton 
                                                key={`styled_delete_secondary_${taxId}_${npidata?.npi}_${locationIndex}_${location.ezCapLocationId ?? '00'}`}
                                                size="30"
                                                onClick={() => {
                                                    setDeleteSecondaryLocation({
                                                        taxIdIndex,
                                                        npiIndex,
                                                        locationIndex
                                                    })
                                                }}
                                            >
                                                <div key={`div_with_loc_${taxId}_${npidata?.npi}_${locationIndex}`}>
                                                    <img  
                                                        key={`del_img_loc_${taxId}_${npidata?.npi}_${locationIndex}`}
                                                        src={
                                                            require('../../assets/delete-2.svg')
                                                                .default
                                                        }
                                                        alt="remove location"
                                                    />
                                                </div>

                                                <div key={`del_p_${taxId}_${npidata?.npi}_${locationIndex}_${location.ezCapLocationId ?? '00'}`}>
                                                    <p>Delete</p>
                                                </div>
                                            </StyledDeleteSecondaryLocationButton>
                                        ) : (
                                            <StyledDeletePlaceHolder  key={`del_ph_${taxId}_${npidata?.npi}_${locationIndex}_${location.ezCapLocationId ?? '00'}`} />
                                        )}
                                    </StyledAdminAddLocationButtonWrap>
                                </>
                            ) : (
                                <StyledLocationPlaceHolder  key={`location_ph_${taxId}_${npidata?.npi}_${locationIndex}_${location.ezCapLocationId ?? '00'}`} ></StyledLocationPlaceHolder>
                            )}
                        </StyledLocationGroup>
                        <StyledLocationErrorMessage 
                            key={`styled_loc_err_msg_${taxId}_${npidata?.npi}_${locationIndex}_${location.ezCapLocationId ?? '00'}`} 
                            hidden={
                                !currentProviderInfo.taxIdProviderGroupings[taxIdIndex]
                                    .providers[npiIndex].locations[locationIndex].error
                            }
                        >
                            {
                                currentProviderInfo.taxIdProviderGroupings[taxIdIndex]
                                    .providers[npiIndex].locations[locationIndex]
                                    .errorMessage
                            }
                        </StyledLocationErrorMessage>
                    </StyledLocationWrap>
                )
            })}
            <StyledAddUserLink key={`styled_add_user_link_${taxId}_${npidata?.npi}`}
                 
                disabled={
                    npidata.npi === '' ||
                    !dictionaryOfNpis.has(taxId) ||
                    dictionaryOfNpis.get(taxId)?.length === 0
                }
                onClick={() => {
                    handleAddingNewLocation(taxIdIndex, npiIndex)
                }}
            >
                + Add Location
            </StyledAddUserLink>
            <LocationModalDialog key = {`location_modal_dialog_${taxId}_${npidata?.npi}`}
                userId={userId}
                taxId={taxId}
                editingAddress={editingAddress}
                validAreaCodes={validAreaCodes}
                addressModalInfo={addressModalInfo}
                handleAddressFromModal={(formData) => handleAddressFromModal(formData)}
                handleCloseModal={handleCloseModal}
                />
        </div>
    )
}