import styled, { css } from 'styled-components'
import { DEVICE, pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { dark_gray, white, grey } = UI_COLORS

export const StyledBenefitInfoContainer = styled.article`
    padding: ${pxToRem(25)} ${pxToRem(30)};
    margin-top: ${pxToRem(40)};

    h2 {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(22)};
        line-height: ${pxToRem(31)};
        letter-spacing: -0.01em;
        color: #293854;
    }

    h3 {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(26)};
        letter-spacing: -0.01em;
        color: #293854;

        position: relative;

        :after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            display: inline-block;
            width: 40px;
            height: 3px;
            background-color: #293854;
        }
    }

    fieldset {
        margin: 0;
        border: 0;
        padding: 0;

        :disabled {
            input {
                background-color: #f5f5f5;
            }
        }
    }

    ${DEVICE.desktop} {
        padding: ${pxToRem(140)} ${pxToRem(30)};
    }
`

export const StyledBenefitInfoRowContainer = styled.article`
    display: flex;
    flex-direction: column;

    ${DEVICE.desktop} {
        flex-direction: row;
        margin-bottom: ${pxToRem(24)};
    }
`

export const StyledBenefitInfoSectionContainer = styled.div`
    border-bottom: 1px solid #dee5ef;
`

export const StyledBenefitInfoInputContainer = styled.article`
    ${DEVICE.desktop} {
        width: ${pxToRem(400)};
        margin-right: ${pxToRem(40)};
    }
`

export const StyledBenefitInfoSecondaryInputContainer = styled.article`
    ${DEVICE.desktop} {
        width: ${pxToRem(220)};
        margin-right: ${pxToRem(40)};
    }
`
