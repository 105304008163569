import { IHeaderInfo } from 'models/headerInfo'

export const SUPER_ADMIN_HEADERS_LIST = [
    { displayName: 'First Name', fieldName: 'FirstName' },
    { displayName: 'Last Name', fieldName: 'LastName' },
    { displayName: 'Phone Number', fieldName: 'Phone' },
    { displayName: 'Email', fieldName: 'Email' },
    { displayName: 'Role', fieldName: 'RoleId' },
    { displayName: 'Last Login', fieldName: 'LastLogin' },
    { displayName: '', fieldName: 'IsActive' }
]
export const ADMIN_HEADERS_LIST: IHeaderInfo[] = [
    { displayName: 'First Name', fieldName: 'FirstName' },
    { displayName: 'Last Name', fieldName: 'LastName' },
    { displayName: 'Phone Number', fieldName: 'Phone' },
    { displayName: 'Email', fieldName: 'Email' },
    { displayName: 'Role', fieldName: 'RoleId' },
    { displayName: 'Status', fieldName: 'UserState' },
    { displayName: 'Actions', fieldName: 'UserState' },
    { displayName: 'Last Login', fieldName: 'LastLoginDate' }
]

export enum ETableType {
    SUPER_ADMIN = 'SUPER_ADMIN',
    ADMIN = 'ADMIN'
}
