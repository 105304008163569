import styled, { css } from 'styled-components'
import { DEVICE, pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { white, dark_gray } = UI_COLORS

export const StyledModalBackground = styled.article`
    z-index: 12;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(41, 56, 84, 0.8);

    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: ${pxToRem(16)};
    align-items: center;

    ${DEVICE.tablet} {
        /* padding-top: ${pxToRem(180)}; */
    }
`

export const StyledModalContainer = styled.article<{ minHeight?: number; minWidth?: number }>`
    position: fixed;
    background: ${white};

    width: ${pxToRem(315)};
    min-height: ${pxToRem(244)};

    border-radius: 4px;
    padding: 30px;
    color: ${dark_gray};
    font-size: ${pxToRem(14)};

    ${DEVICE.tablet} {
        /* width: ${pxToRem(650)}; */
        min-height: ${pxToRem(275)};
        font-size: ${pxToRem(16)};

        h2 {
            font-size: ${pxToRem(22)};
        }

        ${(props) => {
            if (props.minHeight) {
                return css`
                    min-height: ${pxToRem(props.minHeight)};
                `
            }
        }}

        ${(props) => {
            if (props.minWidth) {
                return css`
                    min-width: ${pxToRem(props.minWidth)};
                `
            }
        }}
    }

    ${DEVICE.desktop} {
        /* width: ${pxToRem(650)}; */
        padding: 40px;

        ${(props) => {
            if (props.minHeight) {
                return css`
                    min-height: ${pxToRem(props.minHeight)};
                `
            }
        }}

        ${(props) => {
            if (props.minWidth) {
                return css`
                    min-width: ${pxToRem(props.minWidth)};
                `
            }
        }}
    }
`
