import CustomSvgIcon from 'components/CustomSvgIcon'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import React, { FC, useEffect, useState } from 'react'
import { IExternalStateValue } from 'hooks/useExternalState'
import { warningIcon } from './icons'
import { StyledInput, StyledInputContainer, StyledInputLegend } from './index.style'

export interface ITextFieldProps {
    name?: string
    legend?: string
    disabled?: boolean
    inputType?: string
    placeholder: string
    value?: string
    onTextChanged: (e: React.ChangeEvent<HTMLInputElement>) => void
    pattern?: string
    maxlength?: number
    error?: boolean
    errorMessage?: string
    externalStateValue?: IExternalStateValue<string>
    reference?: React.RefObject<HTMLInputElement>
    handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
}

const TextField: FC<ITextFieldProps> = ({
    name,
    legend,
    disabled,
    inputType,
    placeholder,
    value,
    onTextChanged,
    pattern,
    maxlength,
    error,
    errorMessage,
    externalStateValue,
    reference,
    handleBlur
}) => {
    const [inputValue, setInputValue] = useState(value ?? '')

    useEffect(() => {
        setInputValue(value ?? '')
    }, [value])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()

        const doesInputMatchPattern = !pattern || new RegExp(pattern).test(e.target.value)
        const doesInputStayWithinMaximumLength = !maxlength || e.target.value?.length <= maxlength

        if (doesInputMatchPattern && doesInputStayWithinMaximumLength) {
            onTextChanged(e)
            if (externalStateValue) {
                externalStateValue.set(e.target.value)
            } else {
                setInputValue(e.target.value)
            }
        }
    }

    const hasError = () => {
        return (
            <>
                <CustomSvgIcon iconSet={{ icon: warningIcon }} svg></CustomSvgIcon>
                <Typography type={TYPOGRAPHY_TYPES.p}>{errorMessage}</Typography>
            </>
        )
    }

    return (
        <StyledInputContainer hasLegend={legend ? legend.length > 0 : false}>
            <StyledInputLegend>{legend}</StyledInputLegend>
            <StyledInput
                name={name}
                type={inputType}
                placeholder={placeholder}
                onChange={handleInputChange}
                disabled={disabled}
                ref = {reference}
                value={externalStateValue ? externalStateValue.get() : inputValue}
                error={error}
                onBlur={handleBlur}
            />
            {error && hasError()}
        </StyledInputContainer>
    )
}

export default TextField
