import ReviewableAwvFormsList from 'components/ReviewableAwvFormsList'
import { FC, useEffect, useState, useLayoutEffect } from 'react'
import {
    StyledAWVFormsActionsContainer,
    StyledAWVFormsHeaderContainer,
    StyledAWVFormsScreenHeader,
    StyledCodingAWVFormsContainer,
    StyledEmptyState,
    StyledSearchBarContainer,
    StyledSpinnerContainer,
    StyledDropdownContainer,
    calendarDropDownListStyleParams,
    StyledCalendarIcon,
    StyledStatusIcon,
    statusDropDownListStyleParams
} from './index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import CustomSvgIcon from 'components/CustomSvgIcon'
import PaginationController from 'components/PaginationController'
import { useCoderFormsListConfig } from 'hooks/useListConfig'
import { useDispatch, useSelector } from 'redux/store'
import useDebounce from 'hooks/useDebounce'
import {
    MemberProfileState,
    approveAwvFormResponse,
    downloadAwvFormDocumentResponse,
    getReviewableAwvForms,
    rejectAwvFormResponse,
    denyAwvFormResponse,
    reviewAwvFormResponse,
    getAwvYears,
    getAllElectronicFormTypeNames
} from 'redux/slices/memberProfile'
import SearchTextField from 'components/SearchTextField'
import { SortDirection } from 'models/sortColumn'
import { noData } from 'pages/provider/memberOutreach/icons'
import { StyledSpinner } from 'components/Spinner/index.style'
import {
    IReviewableAwvForm,
    ISetAwvFormResponseStatusRequest
} from 'pages/hcp/members/information/PointsInCare/PointsInCare.models'
import { appPath } from 'utilities/appPath'
import ReviewableAwvFormActionConfirmationBox, {
    ReviewableAwvFormAction
} from 'components/ReviewableAwvFormActionConfirmationBox'
import { AsyncThunk } from '@reduxjs/toolkit'
import { hideMainWindowScrollbars } from 'utilities/index'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import usePostCustomEvent from 'hooks/usePostCustomEvent'
import useCurrentUser from 'hooks/useCurrentUser'
import { ERoles } from 'models/enums/role'
import DropDownList, { IOption } from 'components/DropDownList'
import smallCalendar from 'assets/smallCalendar.svg'
import checkList from 'assets/pass-fail.png'
import allForms from 'assets/copyPaste.svg'

interface IReviwableAwvFormsProps {}

const ReviewableAwvForms: FC<IReviwableAwvFormsProps> = ({}) => {
    const dispatch = useDispatch()

    const listConfig = useCoderFormsListConfig()
    const debouncedFilter = useDebounce(listConfig.effectiveFilter, 500)

    const [initialContentLoaded, setInitialContentLoaded] = useState(false)
    const [isBusy, setIsBusy] = useState(false)

    const [selectedForm, setSelectedForm] = useState<IReviewableAwvForm>(null)

    const [isReviewBeforeOpenBoxOpen, setIsReviewBeforeOpenBoxOpen] = useState(false)
    const [statusAction, setStatusAction] = useState<ReviewableAwvFormAction>(null)
    const [isConfirmStatusActionBoxOpen, setIsConfirmStatusActionBoxOpen] = useState(false)

    const enum FilterNames {
        FormType = 'FormType',
        Statuses = 'Statuses',
        Year = 'Year'
    }

    const {
        reviewableAwvForms,
        reviewableAwvFormsLoading,
        totalNumberOfReviewableAwvForms,
        filteredNumberOfReviewableAwvForms,
        allElectronicFormTypeNames
    } = useSelector((state: { memberProfile: MemberProfileState }) => state.memberProfile)

    const listConfigFormType = listConfig.getNamedFilter(FilterNames.FormType) ?? ''
    const listConfigStatuses = listConfig.getNamedFilter(FilterNames.Statuses) ?? 'NeedsAction'
    const listConfigYear = listConfig.getNamedFilter(FilterNames.Year) ?? new Date().getFullYear()
    const [initialElectronicFormTypesLoaded, setInitialElectronicFormTypesLoaded] = useState(false)
    const currentUser = useCurrentUser()
    const isReadOnly = currentUser?.primaryRole !== ERoles.Coding
    const [formTypesDropdownData, setFormTypesDropDownData] = useState<{ label: string; value: string }[]>([])

    useLayoutEffect(() => {
        if (!allElectronicFormTypeNames) {
            dispatch(getAllElectronicFormTypeNames()).then(() => {
                setInitialElectronicFormTypesLoaded(true)
            })
        }
    }, [allElectronicFormTypeNames, dispatch])

    useEffect(() => {
        if (initialElectronicFormTypesLoaded || (allElectronicFormTypeNames && formTypesDropdownData.length === 0)) {
            var formTypeOptions = allElectronicFormTypeNames.map((formType: string) => ({
                label: formType,
                value: formType
            }))
            formTypeOptions.unshift({ label: 'All Forms', value: '' })
            setFormTypesDropDownData(formTypeOptions)
        }
    }, [initialElectronicFormTypesLoaded, allElectronicFormTypeNames, formTypesDropdownData])

    useEffect(() => {
        var statuses: string[] = []
        if (listConfigStatuses === 'NeedsAction') {
            statuses.push('InReview')
            statuses.push('Submitted')
        } else {
            statuses.push(listConfigStatuses)
        }
        dispatch(
            getReviewableAwvForms({
                pageNumber: listConfig.pageNumber,
                size: 10,
                sortColumn: listConfig.sortColumn,
                isDescending: listConfig.sortDirection === SortDirection.descending ? true : false,
                filter: debouncedFilter,
                formFilter: listConfigFormType,
                statusFilter: statuses,
                yearFilter: listConfigYear
            })
        ).then(() => {
            setInitialContentLoaded(true)
        })
    }, [
        dispatch,
        listConfig.pageNumber,
        listConfig.sortColumn,
        listConfig.sortDirection,
        debouncedFilter,
        listConfigFormType,
        listConfigStatuses,
        listConfigYear
    ])

    useEffect(() => {
        hideMainWindowScrollbars(isReviewBeforeOpenBoxOpen || isConfirmStatusActionBoxOpen)
    }, [isReviewBeforeOpenBoxOpen, isConfirmStatusActionBoxOpen])

    const reviewAndViewFormEvent = usePostCustomEvent(pageTitle.SubmittedAWVForms, customEvents.ReviewAndViewForm)
    const viewFormEvent = usePostCustomEvent(pageTitle.SubmittedAWVForms, customEvents.ViewForm)

    const openReviewableAwvForm = (form: IReviewableAwvForm) => {
        if (form.type === 'PDF') {
            setIsBusy(true)
            dispatch(
                downloadAwvFormDocumentResponse({
                    memberId: form.memberId,
                    npi: form.providerNpi,
                    year: form.year
                })
            ).then(() => {
                setIsBusy(false)
            })
        } else {
            window.open(
                appPath(
                    `/members/viewElectronicAnnualWellnessVisitForm/${form.memberId}/${form.providerNpi}/${form.year}`
                ),
                '_blank',
                'noreferrer'
            )
        }
    }

    const dispatchSetStatusAction = (
        setStatus: AsyncThunk<any, ISetAwvFormResponseStatusRequest, {}>,
        successCallback: () => void = null
    ) => {
        setIsBusy(true)
        dispatch(
            setStatus({
                memberId: selectedForm.memberId,
                npi: selectedForm.providerNpi,
                year: selectedForm.year
            })
        ).then((action) => {
            setIsBusy(false)
            if (action.type === setStatus.fulfilled.type && successCallback) {
                successCallback()
            }
        })
    }

    const reviewAndOpenCurrentReviewableAwvForm = () => {
        setIsReviewBeforeOpenBoxOpen(false)
        reviewAndViewFormEvent()
        dispatchSetStatusAction(reviewAwvFormResponse, () => openReviewableAwvForm(selectedForm))
    }

    const openCurrentReviewableAwvForm = () => {
        setIsReviewBeforeOpenBoxOpen(false)
        viewFormEvent()
        openReviewableAwvForm(selectedForm)
    }

    const handleOpenReviewableAwvForm = (form: IReviewableAwvForm) => {
        if (form.status === 'Submitted' || form.status === 'Rejected') {
            if (isReadOnly) {
                openReviewableAwvForm(form)
            } else {
                setSelectedForm(form)
                setIsReviewBeforeOpenBoxOpen(true)
            }
        } else {
            viewFormEvent()
            openReviewableAwvForm(form)
        }
    }

    const changeStatusApproveEvent = usePostCustomEvent(pageTitle.SubmittedAWVForms, customEvents.ChangeStatusApprove)
    const changeStatusInReviewEvent = usePostCustomEvent(pageTitle.SubmittedAWVForms, customEvents.ChangeStatusInReview)
    const changeStatusRejectEvent = usePostCustomEvent(pageTitle.SubmittedAWVForms, customEvents.ChangeStatusReject)
    const changeStatusDenyEvent = usePostCustomEvent(pageTitle.SubmittedAWVForms, customEvents.ChangeStatusDeny)

    const setReviewableEventName = (action: ReviewableAwvFormAction) => {
        switch (action) {
            case ReviewableAwvFormAction.approve: {
                changeStatusApproveEvent()
                break
            }
            case ReviewableAwvFormAction.review: {
                changeStatusInReviewEvent()
                break
            }
            case ReviewableAwvFormAction.reject: {
                changeStatusRejectEvent()
                break
            }
            case ReviewableAwvFormAction.deny: {
                changeStatusDenyEvent()
                break
            }
            default: {
                break
            }
        }
    }

    const handleSetStatus = (form: IReviewableAwvForm, status: string) => {
        setSelectedForm(form)
        setIsConfirmStatusActionBoxOpen(true)
        if (status === 'InReview') {
            setStatusAction(ReviewableAwvFormAction.review)
        } else if (status === 'Approved') {
            setStatusAction(ReviewableAwvFormAction.approve)
        } else if (status === 'Rejected') {
            setStatusAction(ReviewableAwvFormAction.reject)
        } else if (status === 'Denied') {
            setStatusAction(ReviewableAwvFormAction.deny)
        } else {
            setIsConfirmStatusActionBoxOpen(false)
        }
    }

    const setStatus = () => {
        setIsConfirmStatusActionBoxOpen(false)
        if (statusAction === ReviewableAwvFormAction.review) {
            setReviewableEventName(ReviewableAwvFormAction.review)
            dispatchSetStatusAction(reviewAwvFormResponse)
        } else if (statusAction === ReviewableAwvFormAction.approve) {
            setReviewableEventName(ReviewableAwvFormAction.approve)
            dispatchSetStatusAction(approveAwvFormResponse)
        } else if (statusAction === ReviewableAwvFormAction.reject) {
            setReviewableEventName(ReviewableAwvFormAction.reject)
            dispatchSetStatusAction(rejectAwvFormResponse)
        } else if (statusAction === ReviewableAwvFormAction.deny) {
            setReviewableEventName(ReviewableAwvFormAction.deny)
            dispatchSetStatusAction(denyAwvFormResponse)
        }
    }

    const formStatuses = [
        { label: 'All Statuses', value: '' },
        { label: 'Needs Action', value: 'NeedsAction' },
        { label: 'Submitted', value: 'Submitted' },
        { label: 'In Review', value: 'InReview' },
        { label: 'Rejected', value: 'Rejected' },
        { label: 'Approved', value: 'Approved' },
        { label: 'Denied', value: 'Denied' }
    ]

    const { awvYears } = useSelector((state: { memberProfile: any }) => state.memberProfile)
    useEffect(() => {
        if (!awvYears) {
            dispatch(getAwvYears())
        } else {
            var yearOptions = (awvYears ?? [new Date().getFullYear()]).map((year: number) => ({
                label: year.toString(),
                value: year
            }))
            yearOptions.push({ label: 'All Years', value: 0 })
            setYearsDropDownData(yearOptions)
        }
    }, [awvYears, dispatch])

    const [yearsDropDownData, setYearsDropDownData] = useState<{ label: string; value: number }[]>([])

    const handleFormTypeChanged = (formType: string) => {
        listConfig.setNamedFilter(FilterNames.FormType, formType)
    }
    const handleFormStatusChanged = (formStatus: string) => {
        listConfig.setNamedFilter(FilterNames.Statuses, formStatus)
    }
    const handleYearChanged = (year: number) => {
        listConfig.setNamedFilter(FilterNames.Year, year)
    }

    return (
        <>
            {isReviewBeforeOpenBoxOpen && (
                <ReviewableAwvFormActionConfirmationBox
                    form={selectedForm}
                    action={ReviewableAwvFormAction.openOrDownload}
                    onConfirm={reviewAndOpenCurrentReviewableAwvForm}
                    onCancel={openCurrentReviewableAwvForm}
                    onCancelAll={() => setIsReviewBeforeOpenBoxOpen(false)}
                />
            )}

            {isConfirmStatusActionBoxOpen && (
                <ReviewableAwvFormActionConfirmationBox
                    form={selectedForm}
                    action={statusAction}
                    onConfirm={setStatus}
                    onCancel={() => setIsConfirmStatusActionBoxOpen(false)}
                />
            )}

            <StyledCodingAWVFormsContainer>
                <StyledAWVFormsHeaderContainer>
                    <StyledAWVFormsScreenHeader>
                        <Typography type={TYPOGRAPHY_TYPES.p}>AWV Forms</Typography>
                    </StyledAWVFormsScreenHeader>
                </StyledAWVFormsHeaderContainer>

                {initialContentLoaded && (
                    <>
                        <StyledAWVFormsActionsContainer>
                            <StyledSearchBarContainer>
                                <SearchTextField
                                    placeholder={'Search by patient, year, provider, provider NPI and more...'}
                                    value={listConfig.filter}
                                    loading={reviewableAwvFormsLoading}
                                    onClearSearch={listConfig.clearFilter}
                                    onValueChanged={listConfig.setFilter}
                                />
                            </StyledSearchBarContainer>
                            <StyledDropdownContainer>
                                <StyledCalendarIcon image={allForms} />
                                <DropDownList
                                    options={formTypesDropdownData as IOption[]}
                                    selectedValue={listConfigFormType}
                                    setSelectedValue={(value) => handleFormTypeChanged(value)}
                                    styleParams={statusDropDownListStyleParams}
                                    isDisabled={isBusy}
                                    isReadOnly={isBusy}
                                />
                            </StyledDropdownContainer>
                            <StyledDropdownContainer>
                                <StyledStatusIcon image={checkList} />
                                <DropDownList
                                    options={formStatuses as IOption[]}
                                    selectedValue={listConfigStatuses}
                                    setSelectedValue={(value) => handleFormStatusChanged(value)}
                                    styleParams={statusDropDownListStyleParams}
                                    isDisabled={isBusy}
                                    isReadOnly={isBusy}
                                />
                            </StyledDropdownContainer>
                            <StyledDropdownContainer>
                                <StyledCalendarIcon image={smallCalendar} />
                                <DropDownList
                                    options={yearsDropDownData}
                                    isDisabled={isBusy}
                                    isReadOnly={isBusy}
                                    selectedValue={listConfigYear}
                                    styleParams={calendarDropDownListStyleParams}
                                    setSelectedValue={(value) => handleYearChanged(value)}
                                />
                            </StyledDropdownContainer>
                        </StyledAWVFormsActionsContainer>

                        {reviewableAwvForms && reviewableAwvForms.length > 0 && (
                            <ReviewableAwvFormsList
                                reviewableAwvForms={reviewableAwvForms}
                                sortColumn={listConfig.sortColumn}
                                sortDirection={listConfig.sortDirection}
                                sortCallback={listConfig.setSorting}
                                openReviewableAwvForm={handleOpenReviewableAwvForm}
                                setStatus={handleSetStatus}
                                isBusy={isBusy}
                                isReadOnly={isReadOnly}
                            />
                        )}

                        {filteredNumberOfReviewableAwvForms > 0 && (
                            <PaginationController
                                totalItems={filteredNumberOfReviewableAwvForms}
                                selectedPage={listConfig.pageNumber}
                                selectedPageChanged={listConfig.setPageNumber}
                                singularRecordName="Form"
                                pluralRecordName="Forms"
                            />
                        )}

                        {totalNumberOfReviewableAwvForms === 0 && (
                            <StyledEmptyState>
                                <CustomSvgIcon iconSet={{ icon: noData }} svg></CustomSvgIcon>
                                <Typography type={TYPOGRAPHY_TYPES.h4}>Data not available at the moment</Typography>
                                <Typography type={TYPOGRAPHY_TYPES.p}>Please check back later</Typography>
                            </StyledEmptyState>
                        )}

                        {totalNumberOfReviewableAwvForms > 0 && filteredNumberOfReviewableAwvForms === 0 && (
                            <StyledEmptyState>
                                <CustomSvgIcon iconSet={{ icon: noData }} svg></CustomSvgIcon>
                                <Typography type={TYPOGRAPHY_TYPES.h4}>Your search didn’t match any results</Typography>
                                <Typography type={TYPOGRAPHY_TYPES.p}>
                                    Please modify your search and try again
                                </Typography>
                            </StyledEmptyState>
                        )}
                    </>
                )}

                {!initialContentLoaded && (
                    <StyledSpinnerContainer>
                        <StyledSpinner viewBox="0 0 50 50">
                            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2.5" />
                        </StyledSpinner>
                    </StyledSpinnerContainer>
                )}
            </StyledCodingAWVFormsContainer>
        </>
    )
}

export default ReviewableAwvForms
