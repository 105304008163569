import { useFormContext } from 'react-hook-form'

const useErrors = () => {
    const {
        formState: { errors }
    } = useFormContext()

    return {
        getError: (name: string) => errors[name.replaceAll('.', '_')]?.message
    }
}

export default useErrors
