import styled from 'styled-components'
import { DEVICE, pxToRem } from 'utilities/style'

export const StyledTableContainerWrap = styled.article`
    ${DEVICE.desktop} {
        width: ${pxToRem(1118)};
    }
`

export const StyledTableHeader = styled.ul`
    width: 1145px;
    list-style: none;
    margin: 1em 0 0 0;
    padding: 0;
    background: #ebeff7;

    display: flex;
    flex-direction: row;
`

export const StyledTableHeaderItem = styled.li`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #73839c;

    padding: 12px 0;

    :nth-child(1) {
        margin-left: 26px;
        width: 453px;
    }

    :nth-child(2) {
        width: 114px;
    }

    :nth-child(3) {
        width: 123px;
    }

    :nth-child(4) {
        width: 133px;
    }

    :nth-child(5) {
        width: 140px;
    }

    :nth-child(6) {
        width: 140px;
    }
`

export const StyledTableBodyRowContainer = styled.article`
    width: 1145px;
`

export const StyledTableBodyRowOdd = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    background: #ffffff;

    display: flex;
    flex-direction: row;
    padding: 20px 0 10px;
`

export const StyledTableBodyRowEven = styled.ul`
    border-bottom: 1px solid #dee5ef;
    list-style: none;
    margin: 0;
    padding: 0;
    background: #ffffff;

    display: flex;
    flex-direction: row;

    li {
        :nth-child(1) {
            width: 100%;

            div {
                margin-left: 32px;
                padding-bottom: 12px;
            }
        }
    }
`

export const StyledTableBodyItem = styled.li<{ status?: string }>`
    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #293854;
    }

    padding: 0px 0;

    :nth-child(1) {
        margin-left: 26px;
        width: 453px;
    }

    :nth-child(2) {
        width: 114px;
    }

    :nth-child(3) {
        width: 123px;
    }

    :nth-child(4) {
        width: 133px;
    }

    :nth-child(5) {
        width: 140px;
    }

    :nth-child(6) {
        width: 140px;
    }
`

export const StyledServiceNoteCheckMarkContainer = styled.article`
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    padding-left: ${pxToRem(20)};

    figure {
        margin: 0;
    }
`

export const StyledActionsContainer = styled.article`
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    figure {
        margin: 0;
    }

    button {
        border: 0;
        padding: 0;
        background-color: transparent;
        cursor: pointer;

        :disabled {
            cursor: default;
        }

        :hover {
            border-radius: 15px;
        }
    }
`

export const StyledReferralContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;

    figure {
        margin: 0;
    }

    button {
        margin-right: 3px;
        border: 0;
        background-color: transparent;
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        color: #2281c4;
        cursor: pointer;
        :disabled {
            cursor: default;
            color: #293854;
        }
    }
`

export const StyledTableButton = styled.button`
    background-color: transparent;
    border: 0;

    background-repeat: no-repeat;
    background-size: cover;

    cursor: pointer;

    :disabled {
        cursor: default;
    }

    :hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.14);
        border-radius: 20px;

        :disabled {
            box-shadow: none;
        }
    }

    width: 32px;
    height: 32px;
`
