import { EUserSide } from 'models/enums/role'

export const enum DocumentType {
    Other = 1,
    MedicalChart = 2,
    ProviderReport = 3
}

export interface IProviderDocument {
    id: string
    statusId: number
    status: string
    documentTypeId: number
    documentType: keyof typeof DocumentType
    dateOfSubmission: string
    uploadedBy: string
    fileName: string
    hasNewFeedback: boolean
    providerNpi: string
    providerFirstName: string
    providerLastName: string
    uploadedBySide: keyof typeof EUserSide
    isNew: boolean
}

export type IGetMemberProviderDocumentsRequest = {
    memberId: number
}

export type IGetMemberProviderDocumentsResponse = {
    providerDocuments: IProviderDocument[]
}

export type IGetMemberNpiProviderDocumentsRequest = {
    memberId: number
    npi: string
}

export type IGetMemberNpiProviderDocumentsResponse = {
    providerDocuments: IProviderDocument[]
}

export type ProviderDocumentUploadRequest = {
    file: any
    memberId: number
    npi: string
    documentTypeId: number
}

export type ProviderDocumentByIdRequest = {
    providerDocumentId: string
}

export type SupportingDocs = {
    contentType: string
    fileName: string
    id: string
    length: number
    uploadedOn: string
    uploadedBy: string
}

export type AWVSupportingDocs = {
    awvSupportingDocuments: SupportingDocs[]
}
