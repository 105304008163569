import styled from 'styled-components'
import { UI_COLORS } from 'theme'

const { grey, red } = UI_COLORS

export const StyledRepeaterItemContainer = styled.div`
    border-bottom: dotted 1px ${grey};
    width: 1145px;
    margin-top: 20px;
`

export const StyledDeleteLink = styled.button`
    border: 0;
    background-color: transparent;
    margin: 0;
    text-align: left;
    height: 18px;
    position: relative;
    top: -10px;
    margin-bottom: 5px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    color: ${red};
    cursor: pointer;

    :focus {
        outline: none;
    }

    :disabled {
        visibility: collapse;
    }
`

export const StyledAddLink = styled.button`
    border: 0;
    background-color: transparent;
    margin: 0;
    text-align: left;
    height: 18px;
    position: relative;
    top: -10px;
    margin-top: 20px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    color: #2281c4;
    cursor: pointer;

    :focus {
        outline: none;
    }

    :disabled {
        visibility: collapse;
    }
`
