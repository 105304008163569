import { StyledConfirmButton } from 'components/ConfirmationBox/index.style'
import styled from 'styled-components'
import { pxToRem } from 'utilities/style'

export const StyleHeaderContainer = styled.article`
    display: flex;
`

export const StyledConfirmAndSubmitButton = styled(StyledConfirmButton)`
    width: ${pxToRem(160)};
`
