import {
    IGapUploadArgs,
    IProviderConsiderationFeedbackResponse,
    IProviderQualityFeedbackResponse,
    ISupportDocumentUploadArgs,
    IUploadRecaptureFileRequest
} from './../../pages/hcp/members/information/PointsInCare/PointsInCare.models'
import {
    IAddQualityFeedbackRequest,
    IAddReviewFeedbackRequest,
    IAwvFeedbackRequest,
    IDeletePointsInCareDocument,
    IDownloadGapFormRequest,
    IFeedbackData,
    IQualityFeedbackRequest,
    IGetTreatmentPlanRequest,
    IPointsInCare,
    IReviewFeedbackRequest,
    ISaveTreatmentPlanRequest,
    ISpecialist,
    ISpecialistRequest,
    ISpecialistTypeRequest,
    IUpdateReferralRequest
} from '../../pages/hcp/users/create/index.models'
import { createSlice } from '@reduxjs/toolkit'
import axios, { fileStreamTimeout } from '../../utilities/axios'
import { createExceptionAwareAsyncThunk } from 'utilities/createExceptionAwareAsyncThunk'
import { dispatch } from 'redux/store'
import { addToast } from './toast'
import { successCheckmark } from 'components/Toast/icons'
import { EToastVariant } from 'models/IToast'
import { IProviderReviewFeedbackRequest } from '../../pages/hcp/members/information/PointsInCare/PointsInCare.models'

export type PointsInCareState = {
    memberId: string
    npi: string
    pointsInCare: IPointsInCare
    specialists: ISpecialist[]
    specialistTypes: string[]
    currentQualityFeedback: IFeedbackData
    currentReviewFeedback: IFeedbackData
    currentAwvFeedback: IFeedbackData
    currentTreatmentPlan: string
}

const initialState: PointsInCareState = {
    memberId: '',
    npi: '',
    pointsInCare: {
        quality: [],
        considerations: [],
        reviews: [],
        support: []
    },
    specialists: [],
    specialistTypes: [],
    currentQualityFeedback: null,
    currentAwvFeedback: null,
    currentReviewFeedback: null,
    currentTreatmentPlan: null
}

export const pointsInCareSlice = createSlice({
    name: 'pointsInCare',
    initialState,
    reducers: {
        clearSpecialists: (state) => {
            state.specialists = []
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchPointsInCare.fulfilled, (state, action) => {
                state.pointsInCare = action.payload.pointsInCare
                state.memberId = action.payload.memberId
                state.npi = action.payload.npi
            })
            .addCase(fetchPointsInCareAdmin.fulfilled, (state, action) => {
                state.pointsInCare = action.payload.pointsInCare
                state.memberId = action.payload.memberId
                state.npi = action.payload.npi
            })
            .addCase(cacheProviderConsiderationFeedback.fulfilled, (state, action) => {
                const consideration = state.pointsInCare.considerations.find(
                    (x) => x.targetedConditionForConsiderationId === action.payload.Id
                )
                if (consideration) {
                    consideration.feedback = action.payload.Status
                }
            })
            .addCase(cacheProviderReviewFeedback.fulfilled, (state, action) => {
                const review = state.pointsInCare.reviews.find(
                    (x) => x.targetedConditionUnderReviewId === action.payload.Id
                )
                if (review) {
                    review.feedback = action.payload.Status
                }
            })
            .addCase(cacheProviderQualityFeedback.fulfilled, (state, action) => {
                const consideration = state.pointsInCare.quality.find(
                    (x) => x.targetedConditionQualityId === action.payload.Id
                )
                if (consideration) {
                    consideration.feedbackStatus = action.payload.Status
                }
            })
            .addCase(submitPointsOfCareReview.fulfilled, (state, action) => {})
            .addCase(submitPointsOfCareQuality.fulfilled, (state, action) => {})
            .addCase(saveDraftForPointsInCare.fulfilled, (state, action) => {})
            .addCase(approvePointsInCare.fulfilled, (state, action) => {})
            .addCase(resendPointsInCare.fulfilled, (state, action) => {})
            .addCase(retrieveSpecialists.fulfilled, (state, action) => {
                state.specialists = action.payload.specialists
            })
            .addCase(retrieveSpecialistTypes.fulfilled, (state, action) => {
                state.specialistTypes = action.payload.specialistTypes
            })
            .addCase(retrieveQualityFeedback.fulfilled, (state, action) => {
                state.currentQualityFeedback = action.payload
            })
            .addCase(retrieveReviewFeedback.fulfilled, (state, action) => {
                state.currentReviewFeedback = action.payload
            })
            .addCase(retrieveAwvFeedback.fulfilled, (state, action) => {
                state.currentAwvFeedback = action.payload
            })
            .addCase(addQualityFeedback.fulfilled, (state, action) => {})
            .addCase(updateReferral.fulfilled, (state, action) => {})
            .addCase(updateReviewStatus.fulfilled, (state, action) => {})
            .addCase(downloadGapsForm.fulfilled, (state, action) => {})
            .addCase(uploadGapForm.fulfilled, (state, action) => {})
            .addCase(uploadRecaptureForm.fulfilled, (state, action) => {})
            .addCase(uploadSupportingDocument.fulfilled, (state, action) => {})
            .addCase(fetchSupportingDocuments.fulfilled, (state, action) => {
                state.pointsInCare.support = action.payload.documents
                state.memberId = action.payload.memberId
                state.npi = action.payload.npi
            })
            .addCase(retrieveTreatmentPlan.fulfilled, (state, action) => {
                state.currentTreatmentPlan = action.payload.treatmentPlan
            })
            .addCase(saveTreatmentPlan.fulfilled, (state, action) => {
                // clear treatment plan
                state.currentTreatmentPlan = null
            })
    }
})

// export const {  } = pointsInCareSlice.actions
export default pointsInCareSlice.reducer

export const { clearSpecialists } = pointsInCareSlice.actions

// Thunks for this slice

export const fetchPointsInCare = createExceptionAwareAsyncThunk('pointsInCare/getPointsInCare', async (args: any) => {
    const response = await axios.post('api/PointsInCare/PointsInCare', args)
    return response.data
})

export const fetchPointsInCareAdmin = createExceptionAwareAsyncThunk(
    'pointsInCare/getPointsInCareAdmin',
    async (args: any) => {
        const response = await axios.post('api/PointsInCare/PointsInCareAdmin', args)
        return response.data
    }
)

export const cacheProviderConsiderationFeedback = createExceptionAwareAsyncThunk(
    'pointsInCare/cacheProviderConsiderationFeedback',
    async (response: IProviderConsiderationFeedbackResponse) => {
        return response
    }
)

export const cacheProviderReviewFeedback = createExceptionAwareAsyncThunk(
    'pointsInCare/cacheProviderReviewFeedback',
    async (response: IProviderConsiderationFeedbackResponse) => {
        return response
    }
)

export const cacheProviderQualityFeedback = createExceptionAwareAsyncThunk(
    'pointsInCare/cacheProviderQualityFeedback',
    async (response: IProviderQualityFeedbackResponse) => {
        return response
    }
)

export const updateReviewStatus = createExceptionAwareAsyncThunk(
    'pointsInCare/updateReviewStatus',
    async (args: any) => {
        const response = await axios.post('api/PointsInCare/updateReviewStatus', args as IProviderReviewFeedbackRequest)
        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'Submitted successfully',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const submitPointsOfCareReview = createExceptionAwareAsyncThunk(
    'pointsInCare/submitPointInCareReview',
    async (args: any) => {
        const response = await axios.post('api/PointsInCare/SubmitPointsInCareReview', args)
        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'Submitted successfully',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const submitPointsOfCareQuality = createExceptionAwareAsyncThunk(
    'pointsInCare/submitPointInCareQuality',
    async (args: any) => {
        const response = await axios.post('api/PointsInCare/SubmitPointsInCareQuality', args)
        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'Submitted successfully',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const saveDraftForPointsInCare = createExceptionAwareAsyncThunk(
    'pointsInCare/saveDraftForPointsInCare',
    async (args: any) => {
        const response = await axios.post('api/PointsInCare/SaveDraft', args)
        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'Draft Saved successfully',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

// ***** Retrieve Specialists *****
export const retrieveSpecialists = createExceptionAwareAsyncThunk(
    'pointsInCare/retrieveSpecialists',
    async (args: ISpecialistRequest | null) => {
        const response = await axios.post('api/PointsInCare/RetrieveSpecialists', args)
        return response.data
    }
)

// ***** Retrieve SpecialistTypes *****
export const retrieveSpecialistTypes = createExceptionAwareAsyncThunk(
    'pointsInCare/retrieveSpecialistTypes',
    async (args: ISpecialistTypeRequest | null) => {
        const response = await axios.post('api/PointsInCare/RetrieveSpecialistTypes', args)
        return response.data
    }
)

// **** Update Referral ****
export const updateReferral = createExceptionAwareAsyncThunk(
    'pointsInCare/updateReferral',
    async (args: IUpdateReferralRequest) => {
        const response = await axios.post('api/PointsInCare/UpdateReferral', args)
        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'Referral Updated successfully',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

// ***** Upload Gaps In Care Document *****
export const uploadGapForm = createExceptionAwareAsyncThunk(
    'pointsInCare/uploadGapForm',
    async (args: IGapUploadArgs) => {
        var formData = new FormData()
        formData.append('file', args.file)
        formData.append('pointOfCareId', args.pointOfCareId)
        formData.append('gapInCareId', args.gapInCareId)

        const response = await axios.post('api/PointsInCareDocument/UploadGapForm', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            timeout: fileStreamTimeout // This is where we're setting the 30 second timeout
        })

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `Upload Successful`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

// ***** Upload Gaps In Care Document *****
export const uploadRecaptureForm = createExceptionAwareAsyncThunk(
    'pointsInCare/uploadRecaptureForm',
    async (args: IUploadRecaptureFileRequest) => {
        var formData = new FormData()
        formData.append('file', args.file)
        formData.append('pointOfCareId', args.pointOfCareId)
        formData.append('RecaptureId', args.recaptureId)

        const response = await axios.post('api/PointsInCareDocument/UploadRecaptureForm', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            timeout: fileStreamTimeout // This is where we're setting the 30 second timeout
        })

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `Upload Successful`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

// ***** Upload Support Document *****
export const uploadSupportingDocument = createExceptionAwareAsyncThunk(
    'pointsInCare/uploadSupportingDocument',
    async (args: ISupportDocumentUploadArgs) => {
        var formData = new FormData()
        formData.append('file', args.file)
        formData.append('npi', args.npi)
        formData.append('memberId', args.memberId.toString())

        const response = await axios.post('api/PointsInCareDocument/UploadSupportingDocument', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `Upload Successful`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const fetchSupportingDocuments = createExceptionAwareAsyncThunk(
    'pointsInCare/fetchSupportingDocuments',
    async (args: any) => {
        const response = await axios.post('api/PointsInCare/GetSupportingDocuments', args)
        return response.data
    }
)

//  ***** Download Gaps In Care Document *****
//${args.memberId}__${args.year}.pdf
export const downloadGapsForm = createExceptionAwareAsyncThunk(
    'api/PointsInCare/DownloadGapForm',
    async (args: any) => {
        const response = await axios.post(
            'api/PointsInCareDocument/DownloadGapForm',
            { documentId: (args as IDownloadGapFormRequest).documentId },
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/pdf'
                },
                timeout: fileStreamTimeout // This is where we're setting the 30 second timeout
            }
        )

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${args.npi}_${args.memberId}_${args.gap}_${args.fileName}`) //or any other extension
        document.body.appendChild(link)
        link.click()

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `File Downloaded successfully`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

// ***** Delete Gaps In Care Document *****

export const deleteGapForm = createExceptionAwareAsyncThunk(
    'api/PointsInCare/DeletePointOfCareDocument',
    async (args: IDeletePointsInCareDocument) => {
        const response = await axios.post('api/PointsInCareDocument/DeletePointOfCareDocument', args)
        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `File Deleted Successfully`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

// ***** Retrieve Quality Feedback *****
export const retrieveQualityFeedback = createExceptionAwareAsyncThunk(
    'pointsInCare/retrieveQualityFeedback',
    async (args: IQualityFeedbackRequest | null) => {
        const response = await axios.post('api/PointsInCare/RetrieveFeedback', args)
        return response.data
    }
)

// ***** Retrieve Review Feedback *****
export const retrieveReviewFeedback = createExceptionAwareAsyncThunk(
    'pointsInCare/retrieveReviewFeedback',
    async (args: IReviewFeedbackRequest | null) => {
        const response = await axios.post('api/PointsInCare/RetrieveReviewFeedback', args)
        return response.data
    }
)

// ***** Retrieve Awv Feedback *****
export const retrieveAwvFeedback = createExceptionAwareAsyncThunk(
    'pointsInCare/retrieveAwvFeedback',
    async (args: IAwvFeedbackRequest | null) => {
        const response = await axios.post('api/PointsInCare/RetrieveAwvFeedback', args)
        return response.data
    }
)

// ***** Add Quality Feedback *****
export const addQualityFeedback = createExceptionAwareAsyncThunk(
    'pointsInCare/addQualityFeedback',
    async (args: IAddQualityFeedbackRequest | null) => {
        const response = await axios.post('api/PointsInCare/AddFeedback ', args)
        return response.data
    }
)

// ***** Add Review Feedback *****
export const addReviewFeedback = createExceptionAwareAsyncThunk(
    'pointsInCare/addReviewFeedback',
    async (args: IAddReviewFeedbackRequest | null) => {
        const response = await axios.post('api/PointsInCare/AddReviewFeedback ', args)
        return response.data
    }
)

// ***** Retrieve Treatment Plan *****
export const retrieveTreatmentPlan = createExceptionAwareAsyncThunk(
    'pointsInCare/retrieveTreatmentPlan',
    async (args: IGetTreatmentPlanRequest) => {
        const response = await axios.post('api/PointsInCare/GetTreatmentPlan', args)
        return response.data
    }
)

// ***** Save Treatment Plan *****
export const saveTreatmentPlan = createExceptionAwareAsyncThunk(
    'pointsInCare/saveTreatmentPlan',
    async (args: ISaveTreatmentPlanRequest) => {
        const response = await axios.post('api/PointsInCare/SaveTreatmentPlan', args)
        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'Treatment plan saved successfully',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }

        return response.data
    }
)

// *********************** Admin Approvals and Resends *************************

// Approve for Review

export const approvePointsInCare = createExceptionAwareAsyncThunk(
    'pointsInCare/approvePointsInCare',
    async (args: any) => {
        const response = await axios.post('api/PointsInCare/Approve', args)
        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'Points In Care Approved successfully',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const resendPointsInCare = createExceptionAwareAsyncThunk(
    'pointsInCare/resendPointsInCare',
    async (args: any) => {
        const response = await axios.post('api/PointsInCare/Resend', args)
        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'Points In Care Resent successfully',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const alertFileLimitExceeded = createExceptionAwareAsyncThunk('pointsInCare/AlertFileLimit', async () => {
    dispatch(
        addToast({
            message: 'File size is too large for the application',
            icon: '',
            time: 3000,
            variant: EToastVariant.ERROR
        })
    )
})
