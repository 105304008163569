import { IDropDownListStyleParams } from 'components/DropDownList/index.style'
import styled from 'styled-components'
import { UI_COLORS } from 'theme/constants'

export const StyledUploadTemplateDetailsContainer = styled.article`
    margin-bottom: 30px;
    padding-bottom: 55px;
    margin: 6.25rem 1.875rem;
    width: 1200px;

    h2 {
        margin-top: 40px;
        color: #293854;
    }

    p {
        font-family: 'Inter', 'sans-serif';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #293854;
    }
`

export const electronicFormTypeDropDownListStyleParams: IDropDownListStyleParams = {
    width: 400,
    fontSize: '16px',
    paddingTop: '0px',
    paddingLeft: '9px !important',
    indicatorMarginTop: '-51px',
    placeholderTop: '-10px !important',
    singleValueMarginTop: '11px',
    singleValueFontSize: '16px',
    singleValueFontWeight: '400',
    singleValueColor: UI_COLORS.black,
    placeholderColor: UI_COLORS.light_gray3,
    placeholderFontSize: '16px',
    placeholderFontWeight: '500'
}
