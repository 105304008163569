import Input from '@mui/material/Input'
import MaskedInput from 'react-input-mask'
import { Box } from '@mui/material'

const CustomMaskedInput = (props: any) => {
    const { value, onChange, name, width, placeholder } = props
    return (
        <Box
            sx={{
                '& .MuiInput-root:hover::before': {
                    borderBottom: 'none !important'
                }
            }}
        >
            <MaskedInput
                name={name}
                placeholder={placeholder}
                value={value?.trim() == '' ? null : value}
                mask="(999) 999-9999"
                alwaysShowMask={false}
                onChange={(e) => {
                    e.persist()
                    onChange(e.target.value)
                }}
                style={{ width: width }}
            >
                {(inputProps: any) => <Input {...inputProps} type="tel" autoComplete="tel-national" />}
            </MaskedInput>
        </Box>
    )
}

export default CustomMaskedInput
