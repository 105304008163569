import {
    addressesIcon,
    authIcon,
    benefitIcon,
    calendarIcon,
    checkListIcon,
    claimsIcon,
    creditCard,
    documentsIcon,
    personalIcon,
    pharmacyIcon
} from './icons'
import { pageTitle } from 'appInsights/appInsights'

export interface INavBarConfig {
    icon: string
    label: string
    link: string
    pageTitle: string
}

export enum ENavBarItems {
    PERSONAL = 'Personal',
    ADDRESSES = 'Addresses',
    BENEFITINFO = 'Benefit Info',
    GAPSINCARE = 'Gaps In Care',
    CLAIMS = 'Claims',
    AUTHORIZATIONS = 'Authorizations',
    PHARMACY = 'Pharmacy',
    AWV = 'Annual Wellness Visit',
    DOCUMENTS = 'Documents'
}

export const NavBarConfig: INavBarConfig[] = [
    {
        icon: personalIcon,
        label: ENavBarItems.PERSONAL,
        link: '',
        pageTitle: pageTitle.PatientsPersonal
    },
    {
        icon: addressesIcon,
        label: ENavBarItems.ADDRESSES,
        link: '',
        pageTitle: pageTitle.PatientsAddresses
    },
    {
        icon: creditCard,
        label: ENavBarItems.BENEFITINFO,
        link: '',
        pageTitle: pageTitle.PatientsBenefitInfo
    },
    {
        icon: checkListIcon,
        label: ENavBarItems.GAPSINCARE,
        link: '',
        pageTitle: pageTitle.PatientsGapsInCare
    },
    {
        icon: calendarIcon,
        label: ENavBarItems.AWV,
        link: '',
        pageTitle: pageTitle.PatientsAWV
    },
    {
        icon: documentsIcon,
        label: ENavBarItems.DOCUMENTS,
        link: '',
        pageTitle: pageTitle.PatientsDocuments
    }
    // {
    //     icon: claimsIcon,
    //     label: ENavBarItems.CLAIMS,
    //     link: ''
    // },
    // {
    //     icon: authIcon,
    //     label: ENavBarItems.AUTHORIZATIONS,
    //     link: ''
    // },
    // {
    //     icon: pharmacyIcon,
    //     label: ENavBarItems.PHARMACY,
    //     link: ''
    // }
]
