import styled, { css } from 'styled-components'
import { pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { dark_gray, white, grey } = UI_COLORS

export const StyledMemberInputContainer = styled.article`
    width: 100%;

    display: flex;
    flex-direction: column;

    label {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(18)};
        color: #293854;

        margin-bottom: ${pxToRem(15)};
    }

    input {
        height: ${pxToRem(50)};
        background: #ffffff;
        border: 1px solid #dee5ef;
        border-radius: ${pxToRem(4)};
        border: 1px solid #73839c;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: ${pxToRem(16)};
        line-height: ${pxToRem(22)};
        color: #293854;

        padding-left: ${pxToRem(18)};

        :disabled {
            background-color: #f5f5f5;
        }
    }

    small {
        margin: 0;
        color: #b11b1b;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    .MuiInputBase-input {
        padding: 0;
        padding-left: ${pxToRem(18)};
    }

    div {
        width: 400px;
        :before {
            border-bottom: none;
            :hover {
                border-bottom: none;
            }
        }
    }
`

export const StyledAdressMemberInputContainer = styled.article`
    width: 100%;

    display: flex;
    flex-direction: column;

    label {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(18)};
        color: #293854;

        margin-bottom: ${pxToRem(15)};
    }

    input {
        height: ${pxToRem(50)};
        background: #ffffff;
        border: 1px solid #dee5ef;
        border-radius: ${pxToRem(4)};

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: ${pxToRem(16)};
        line-height: ${pxToRem(22)};
        color: #293854;

        padding-left: ${pxToRem(18)};

        :disabled {
            background-color: #dee5ef;
        }
    }

    small {
        margin: 0;
        color: #b11b1b;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
`
