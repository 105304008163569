import styled from 'styled-components'
import { UI_COLORS } from 'theme'
import { DEVICE, pxToRem } from 'utilities'

const { dark_gray, light_gray2, white, red } = UI_COLORS

export const StyledTextArea = styled.textarea`
    width: 100%;
    height: ${pxToRem(174)};
    border: 1px solid #dee5ef;
    border-radius: ${pxToRem(4)};
    resize: none;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: ${pxToRem(16)};
    line-height: 140%;
    /* or 22px */

    color: ${dark_gray};
    padding: ${pxToRem(18)};

    :read-only {
        background-color: ${light_gray2};
        color: #a4b0c0;
    }

    &:focus {
        outline: none;
    }
`

export const StyledButtonContainer = styled.article`
    display: flex;
    flex-direction: row;
    margin-top: ${pxToRem(25)};

    ${DEVICE.tablet} {
        position: absolute;
        bottom: ${pxToRem(30)};
        right: ${pxToRem(30)};
    }
`

export const StyledCloseButton = styled.button`
    width: ${pxToRem(120)};
    height: ${pxToRem(50)};
    border-radius: ${pxToRem(4)};
    border: 0px;
    cursor: pointer;

    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};

    background-color: ${red};
    color: ${white};

    :active {
        background-color: ${white};
        color: ${red};
        border: 1px solid ${red};
    }
`
