import styled, { css } from 'styled-components'
import { pxToRem, DEVICE } from 'utilities'
import { UI_COLORS } from 'theme'

const { light_gray4, light_blue2 } = UI_COLORS

export const StyledCalendarIcon = styled.div<{ image?: string }>`
    &:after {
        content: '';
        position: absolute;
        transform: TranslateX(-100%);
        width: 20px;
        height: 70px;

        width: ${pxToRem(16)};
        height: ${pxToRem(16)};
        margin-top: ${pxToRem(-9)};
        margin-left: ${pxToRem(30)};
        z-index: 1;

        border: 0;

        background-size: cover;
        background-color: white;

        cursor: pointer;

        ${(props) => {
            if (props.image) {
                return css`
                    background-image: url(${props.image});
                `
            }
        }};
    }
`

export const StyledAWVContainer = styled.article`
    padding: ${pxToRem(180)} ${pxToRem(30)};
    width: ${pxToRem(1145)};
    font-family: 'Work Sans', sans-serif;
    position: relative;

    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #293854;
    }

    h2 {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 31px;
        letter-spacing: -0.01em;
        color: #293854;
    }

    h4 {
        margin-top: ${pxToRem(20)};
        margin-bottom: ${pxToRem(10)};
        color: #293854;
    }

    p {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 31px;
        margin-top: 5px;

        letter-spacing: -0.01em;

        color: #293854;
    }

    fieldset {
        margin: 0;
        border: 0;
        padding: 0;

        :disabled {
            input {
                background-color: #f5f5f5;
            }
        }
    }
`

export const StyledAWVInfoContainer = styled.article`
    display: flex;
    flex-direction: column;
    align-items: left;

    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;

    h3 {
        margin: 0;
        margin-right: 16px;
        :after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            display: inline-block;
            width: 40px;
            height: 3px;
            background-color: #293854;
        }
    }

    button {
        margin: 0;
    }
`

export const StyledAWVInfoInputWrap = styled.article`
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    width: auto;

    input {
        width: 100%;
        margin-bottom: ${pxToRem(24)};
    }

    ${DEVICE.tablet} {
        input {
            width: ${pxToRem(330)};
        }
    }

    ${DEVICE.desktop} {
        flex-direction: row;
        /* width: ${pxToRem(880)}; */

        input {
            :nth-child(2) {
                margin-bottom: ${pxToRem(0)};
            }
        }
    }
`

export const StyledAWVInfoInputContainer = styled.article`
    padding-right: 25px;
    display: flex;
    flex-direction: column;

    label {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(18)};
        color: #293854;

        margin-bottom: ${pxToRem(15)};
    }

    input {
        height: ${pxToRem(50)};
        background: #ffffff;
        border: 1px solid #dee5ef;
        border-radius: ${pxToRem(4)};
        border: 1px solid #73839c;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(22)};
        color: #293854;

        padding-left: ${pxToRem(18)};

        :disabled {
            background-color: #f5f5f5;
        }
    }

    .MuiInputBase-input {
        padding: 0;
        padding-left: ${pxToRem(18)};
    }

    div {
        /* width: 300px; */
        :before {
            border-bottom: none;
            :hover {
                border-bottom: none;
            }
        }
    }
`

export const StyledAWVInfoInputWithRightButtonContainer = styled.article`
    width: 100%;

    display: flex;
    flex-direction: column;

    label {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(18)};
        color: #293854;

        margin-bottom: ${pxToRem(15)};
    }

    div {
        width: 330px;
        border: 1px solid #73839c;
        border-radius: 4px;
        margin: 0;
        margin-bottom: 2px;
        height: 50px;
        background: #f5f5f5;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        :before {
            border-bottom: none;
            :hover {
                border-bottom: none;
            }
        }
    }
`

export const StyledAWVInfoActionsContainer = styled.article`
    width: 100%;
    margin-top: 10px;
    padding-right: 40px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;

    article {
        width: 16px;
        height: 16px;
        margin-top: 28px;
    }
`

export const StyledAWVTitleDetailContainer = styled.article`
    width: 100%;
    margin-top: 22px;

    h2 {
        margin-bottom: 5px;
    }
`

export const StyledActionButton = styled.button<{ color?: string }>`
    width: 100px;
    height: 50px;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    padding: 3px;

    cursor: pointer;

    figure {
        margin: 2px;
        /* margin-top: 10px; */
        width: 20px;
        height: 20px;
    }
    :disabled {
        cursor: default;
        background: ${light_gray4};
    }

    ${(props) => {
        return css`
            background: ${props.color || light_blue2};
            :enabled {
                :active {
                    background: transparent;
                    color: ${props.color || light_blue2};
                    border: ${pxToRem(1)} solid ${props.color || light_blue2};
                }
            }
        `
    }}
`

export const StyledSubmitButton = styled(StyledActionButton)`
    width: 160px;
    margin-left: 0 !important;
    padding-left: 0;
    margin-bottom: 35px;
    margin-top: 10px;
`

export const StyledActionButtonPlaceholder = styled.article`
    width: 100px;
    height: 50px;
    margin-left: 15px !important;
`

export const StyledDetailsContainer = styled.article`
    display: flex;
    flex-direction: column;
    padding-bottom: ${pxToRem(10)};
`

export const StyledDetailsWrap = styled.article`
    width: 100%;
    p {
        font-family: 'Inter', 'sans-serif';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #293854;
    }
`

export const StyledAWVInstructionWrap = styled.article`
    p {
        width: ${pxToRem(870)};
        font-family: 'Inter', 'sans-serif';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: #293854;
        margin-top: 0;
        a {
            text-decoration: none;
            color: #2281c4;
        }
    }
`

export const StyledAWVLongInstructionWrap = styled(StyledAWVInstructionWrap)`
    width: 100%;
    p {
        width: ${pxToRem(900)};
        ul {
            list-style-type: decimal;
        }
    }
`

export const StyledDetailsButton = styled.button<{ color?: string; noFill?: boolean; stroke?: boolean }>`
    width: ${pxToRem(163)};
    height: ${pxToRem(50)};
    border: none;
    border-radius: 4px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    color: #ffffff;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    cursor: pointer;

    figure {
        margin: 0;
        margin-top: 5px;
        margin-left: 5px;
    }

    :disabled {
        cursor: default;
        background: ${light_gray4};
    }

    svg {
        fill: ${(props) => (props.noFill === true ? 'none' : 'white')};
        stroke: ${(props) => (props.stroke === true ? 'white' : 'none')};
    }

    ${(props) => {
        return css`
            background: ${props.color || light_blue2};
            :enabled {
                :active {
                    background: transparent;
                    color: ${props.color || light_blue2};
                    border: ${pxToRem(1)} solid ${props.color || light_blue2};
                    svg {
                        fill: ${props.noFill === true ? 'none' : props.color || light_blue2} !important;
                        stroke: ${props.stroke === true ? props.color || light_blue2 : 'none'} !important;
                    }
                }
            }
        `
    }}
`

export const StyledSmallDetailsButton = styled(StyledDetailsButton)`
    width: ${pxToRem(100)};
    margin-right: 15px !important;
`

export const StyledMediumDetailsButton = styled(StyledDetailsButton)`
    width: ${pxToRem(125)};
    margin-right: 15px !important;
`

export const StyledNoLeftMarginSmallDetailsButton = styled(StyledSmallDetailsButton)`
    margin-left: 0 !important;
`

export const StyledUploadDetailsContainer = styled.article`
    margin-top: 20px;
    margin-bottom: 15px;
    padding-bottom: 0;
    /* border-bottom: 1px solid #dee5ef; */

    h4 {
        margin-top: 0px;
        color: #293854;
    }

    p {
        font-family: 'Inter', 'sans-serif';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #293854;

        em {
            color: #2281c4;
            font-style: normal;
        }
    }
`

export const StyledDownloadButtonWrap = styled.article`
    display: flex;

    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;

    article {
        width: 25px;
        height: 25px;
        margin-left: 50px;
        margin-top: 20px;
    }
`

export const StyledDetailsButtonWrap = styled.article`
    display: flex;

    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    article {
        width: 25px;
        height: 25px;
        margin-right: 10px;
        margin-top: 10px;
    }
`

export const StyledUploadButtonWrap = styled.article`
    display: flex;

    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    article {
        width: 25px;
        height: 25px;
        margin-right: 10px;
        margin-top: 10px;
    }
`
export const StyledAWVSubmitButtonWrap = styled(StyledUploadButtonWrap)`
    justify-content: flex-start;
    height: 90px;
    article {
        margin-left: 50px;
        margin-top: 0;
    }
`

export const StyledUploadButton = styled.button`
    width: ${pxToRem(163)};
    height: ${pxToRem(50)};
    border: none;
    background: #2281c4;
    border-radius: 4px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    color: #ffffff;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    cursor: pointer;

    svg {
        fill: white;
    }

    :enabled {
        :active {
            background: transparent;
            color: #2281c4;
            border: ${pxToRem(1)} solid #2281c4;
            svg {
                fill: #2281c4 !important;
            }
        }
    }

    figure {
        margin: 0;
        margin-top: 5px;
        margin-left: 10px;
    }

    :disabled {
        background-color: #a4b0c0;
        cursor: auto;
    }
`

export const StyledAWVFileTableContainer = styled.article`
    width: 100%;
`

export const StyledAWVFileTableHeader = styled.ul`
    height: 40px;
    background-color: #ebeff7;

    padding: 0;
    list-style: none;

    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
`

export const StyledAWVFileTableHeaderItem = styled.li`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    color: #73839c;

    :nth-child(1) {
        width: 150px;
        margin-left: 26px;
    }

    :nth-child(2) {
        width: 150px;
    }

    :nth-child(3) {
        width: 225px;
    }

    :nth-child(4) {
        width: 150px;
    }
`

export const StyledAWVFileTableBody = styled.ul`
    height: 90px;
    background-color: #ffffff;

    padding: 0;
    list-style: none;

    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
`

export const StyledAWVFileTableBodyItem = styled.li`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    :nth-child(1) {
        width: 150px;
        margin-left: 26px;
        color: #2281c4;
        cursor: pointer;

        :hover {
            text-decoration: underline;
        }
    }

    :nth-child(2) {
        width: 150px;
    }

    :nth-child(3) {
        width: 225px;
    }

    :nth-child(4) {
        width: 150px;
    }
`

export const StyledTableActionButton = styled.button`
    padding: 0;
    border: 0;
    width: 25px;
    height: 25px;
    background-color: transparent;

    :nth-child(1) {
        margin-right: 20px;
    }

    cursor: pointer;

    :hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
        border-radius: 20px;
    }
`

export const StyledDownloadFileButton = styled.button`
    background-color: transparent;
    border: none;
    color: #2281c4;
`

export const StyledModal = styled.article`
    h1 {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #293854;
        margin: 0;
    }

    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #293854;
    }
`

export const StyledModalActionsContainer = styled.article`
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 50px;
`

export const StyledCancelButton = styled.button`
    width: 120px;
    height: 50px;
    border: none;
    background: #a4b0c0;
    border-radius: 4px;
    color: #ffffff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    margin-right: 16px;

    cursor: pointer;

    :active {
        background: transparent;
        color: #a4b0c0;
        border: 1px solid #a4b0c0;
    }
`
export const StyledContinueButton = styled.button`
    width: 120px;
    height: 50px;
    border: none;
    background: #2281c4;
    border-radius: 4px;
    color: #ffffff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    cursor: pointer;
`

export const StyledDropdownContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
        margin-right: 16px;
        margin-top: 15px;
    }
`

export const StyledAWVFormTitle = styled.article`
    display: flex;
    flex-direction: column;
    align-items: left;

    h3 {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(26)};
        letter-spacing: -0.01em;
        color: #293854;

        position: relative;

        :after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            display: inline-block;
            width: 40px;
            height: 3px;
            background-color: #293854;
        }
    }
`

export const StyledAwvSummaryInfoPanel = styled.article`
    margin-bottom: 30px;
    padding-bottom: 55px;
    border-bottom: 1px solid #dee5ef;
`

export const StyledAwvSummarInfoPanelDownloadLink = styled.div`
    color: #2281c4;
    cursor: pointer;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    :hover {
        text-decoration: underline;
    }
`

export const StyledDocumentsModalListContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
`

export const StyledDocumentsModalActionButton = styled.button`
    margin-left: 20px;
    width: 50px;
    height: 50px;

    background-color: transparent;
    border: none;

    cursor: pointer;

    figure {
        margin: 0;
        padding: 0;
    }
`

export const StyledStatusBox = styled.div`
    border: 1px solid #73839c;
    border-radius: 4px;
    height: 50px;
    background: #f5f5f5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    figure {
        height: 24px;
        width: 24px;
        padding: 1px;
        margin: 8px 2px 2px 8px;
    }

    input {
        border: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(22)};
        color: #293854;
        margin: 0;

        :disabled {
            background-color: #f5f5f5;
        }
    }

    button {
        width: 34px;
        height: 34px;
        margin-top: 0px;
        margin-right: 4px;
    }
`
