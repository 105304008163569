import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC, useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import {
    StyledBottomButtonsContainer,
    StyledButton,
    StyledUserDetailsControllerButton,
    StyledUserDetailsControllerContainer,
    StyledUserDetailsTitleContainer,
    StyledUserTypeContainer
} from 'pages/hcp/users/details/OfficeAdminUser/style'
import { editPen, medicalFolder } from 'pages/hcp/users/details/OfficeAdminUser/icons'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { personalInformation } from 'messages/personalInformation'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'redux/store'
import {
    clearUserDetails,
    disableUser,
    enableUser,
    resendInvitation,
    updateOfficeManager,
    UserState
} from 'redux/slices/user'
import { appPath } from 'utilities/appPath'
import NewOfficeAdmin from 'pages/hcp/users/create/NewOfficeAdmin'
import { IOfficeAdminClientUpdate, IOfficeAdminServerUpdate } from 'pages/hcp/users/create/index.models'
import { mirrorIcon } from 'components/UsersTable/icons'
import { checkCircleEnable, envelopeResendInvitation, redCircleDisable } from 'components/PaginationController/icons'
import { appPathForRoutes } from 'utilities/appPathForRoutes'
import { impersonationUserIdKey } from 'utilities/impersonationUtilities'
import { hideMainWindowScrollbars } from 'utilities'
import DisableUserConfirmationBox from 'components/DisableUserConfirmationBox'
import EnableUserConfirmationBox from 'components/EnableUserConfirmationBox'
import ResendInvitationConfirmationBox from 'components/ResendInvitationConfirmationBox'
import LoginOrInvitationStatus from 'pages/hcp/users/create/LoginOrInvitationStatus'
import { StyledEditUserContainer } from '../style'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import usePostCustomEvent from 'hooks/usePostCustomEvent'
import { ERoles } from 'models/enums/role'
import useCurrentUser from 'hooks/useCurrentUser'

interface IOfficeAdminEditUserProps {
    editable: boolean
}
const OfficeAdminUser: FC<IOfficeAdminEditUserProps> = ({ editable }) => {
    const [isCancelingEdit, setIsCancelingEdit] = useState(false)
    const [formData, setFormData] = useState({} as IOfficeAdminClientUpdate)
    const [formValid, setFormValid] = useState(false)
    const [hasNewUserDetail, setHasNewUserDetail] = useState(false)
    const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)
    const [isEditEnabled, setIsEditEnabled] = useState(editable ?? false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [isToggleEnabledModalShown, setIsToggleEnabledModalShown] = useState(false)
    const [isResendInvitationModalShown, setIsResendInvitationModalShown] = useState(false)

    useEffect(() => {
        hideMainWindowScrollbars(isToggleEnabledModalShown || isResendInvitationModalShown)
    }, [isToggleEnabledModalShown, isResendInvitationModalShown])

    useEffect(() => {
        setIsEditEnabled(!editable)
    }, [setHasNewUserDetail])

    const updateInfoEvent = usePostCustomEvent(pageTitle.UsersOfficeAdmin, customEvents.UpdateInfo)
    const submitOfficeAdminFormData = async () => {
        updateInfoEvent()
        setHasBeenSubmitted(true)
        setTimeout(() => {
            setHasBeenSubmitted(false)
        }, 500)
    }

    const setAndResetHasNewUserDetail = () => {
        setHasNewUserDetail(true)
        setTimeout(() => {
            setHasNewUserDetail(false)
        }, 500)
    }

    const details = useSelector((state: { user: UserState }) => state.user.details)

    useEffect(() => {
        setAndResetHasNewUserDetail()
        isEditEnabled && handleEditButton()
    }, [])

    useEffect(() => {
        if (isCancelingEdit) {
            setIsCancelingEdit(false)
            setAndResetHasNewUserDetail()
        }
    }, [isCancelingEdit])

    const handleFormData = (data: any) => {
        setFormData(data)
    }

    const handleEditButton = () => {
        setIsEditEnabled(false)
    }

    const handleBackButton = () => {
        dispatch(clearUserDetails())
        navigate(appPath('/users'))
    }

    const handleCancelButton = () => {
        if (editable) {
            handleBackButton()
        } else {
            setIsCancelingEdit(true)
            setFormData({} as IOfficeAdminClientUpdate)
            setFormValid(false)
            setHasNewUserDetail(false)
            setHasBeenSubmitted(false)
            setIsEditEnabled(true)
            setIsToggleEnabledModalShown(false)
            setIsResendInvitationModalShown(false)
        }
    }

    const onValidationComplete = () => {
        const request: IOfficeAdminServerUpdate = {
            userId: formData.userId,
            sid: '00000000-0000-0000-0000-000000000000',
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phoneNumber: formData.phoneNumber,
            role: formData.role,
            taxIdProviderGroupings: formData.taxIdProviderGroupings
        }

        dispatch(updateOfficeManager(request))
        navigate(appPath('/users'))
    }

    const enableUserEvent = usePostCustomEvent(pageTitle.UsersOfficeAdmin, customEvents.EnableUser)
    const disableUserEvent = usePostCustomEvent(pageTitle.UsersOfficeAdmin, customEvents.DisableUser)

    const handleEnableStateForUser = (isActive: boolean, userId: string) => {
        let USER_ID = { userId: details?.id }
        if (isActive) {
            disableUserEvent()
            dispatch(disableUser(USER_ID))
        } else {
            enableUserEvent()
            dispatch(enableUser(USER_ID))
        }
    }

    const closeIsToggleEnabledModal = () => {
        setIsToggleEnabledModalShown(false)
    }

    const toggleUserEnabledState = () => {
        handleEnableStateForUser(details?.isActive, details?.id)
        closeIsToggleEnabledModal()
    }

    const handleToggleEnabled = () => {
        setIsToggleEnabledModalShown(true)
    }

    const handleResendInvitation = () => {
        setIsResendInvitationModalShown(true)
    }

    const resentInvitationEvent = usePostCustomEvent(pageTitle.UsersOfficeAdmin, customEvents.ResendInvitation)
    const resendInvitationEmail = () => {
        resentInvitationEvent()
        dispatch(resendInvitation({ UserId: details?.id }))
        closeResendInvitationModal()
    }

    const closeResendInvitationModal = () => {
        setIsResendInvitationModalShown(false)
    }
    const mirrorUserEvent = usePostCustomEvent(pageTitle.UsersOfficeAdmin, customEvents.MirrorUser)
    const handleMirrorOnClick = () => {
        mirrorUserEvent()
    }
    const currentUser = useCurrentUser()
    const isReadOnly = currentUser?.primaryRole === ERoles.Quality

    return (
        <>
            {isToggleEnabledModalShown && details?.isActive && (
                <DisableUserConfirmationBox
                    userFirstName={details?.firstName}
                    userLastName={details?.lastName}
                    onConfirm={toggleUserEnabledState}
                    onCancel={closeIsToggleEnabledModal}
                />
            )}

            {isToggleEnabledModalShown && !details?.isActive && (
                <EnableUserConfirmationBox
                    userFirstName={details?.firstName}
                    userLastName={details?.lastName}
                    onConfirm={toggleUserEnabledState}
                    onCancel={closeIsToggleEnabledModal}
                />
            )}

            {isResendInvitationModalShown && (
                <ResendInvitationConfirmationBox
                    userFirstName={details?.firstName}
                    userLastName={details?.lastName}
                    onConfirm={resendInvitationEmail}
                    onCancel={closeResendInvitationModal}
                />
            )}

            {!isCancelingEdit && (
                <StyledEditUserContainer>
                    <Typography type={TYPOGRAPHY_TYPES.h3}>User Information</Typography>
                    <StyledUserDetailsTitleContainer>
                        <StyledUserTypeContainer>
                            <CustomSvgIcon iconSet={{ icon: medicalFolder }} svg></CustomSvgIcon>
                            <Typography style={{ paddingLeft: 15 }} type={TYPOGRAPHY_TYPES.p}>
                                {personalInformation.office_admin}
                            </Typography>
                        </StyledUserTypeContainer>
                        <LoginOrInvitationStatus
                            userState={details.userState}
                            invitationSent={details.invitationSent}
                            invitationExpired={details.invitationExpired}
                            daysToInvitationExpiry={details.daysToInvitationExpiry}
                            lastLogin={details.lastLogin}
                        />
                    </StyledUserDetailsTitleContainer>

                    <StyledUserDetailsControllerContainer>
                        {!isReadOnly && details?.isActive && (
                            <StyledUserDetailsControllerButton onClick={handleEditButton}>
                                <CustomSvgIcon iconSet={{ icon: editPen }} svg></CustomSvgIcon>Edit
                            </StyledUserDetailsControllerButton>
                        )}

                        {!isReadOnly &&
                            details?.role &&
                            (details?.sid ? (
                                <StyledUserDetailsControllerButton onClick={handleToggleEnabled}>
                                    {details?.isActive ? (
                                        <CustomSvgIcon iconSet={{ icon: redCircleDisable }} svg />
                                    ) : (
                                        <CustomSvgIcon iconSet={{ icon: checkCircleEnable }} svg />
                                    )}
                                    {details?.isActive ? 'Disable' : 'Enable'}
                                </StyledUserDetailsControllerButton>
                            ) : (
                                <StyledUserDetailsControllerButton onClick={handleResendInvitation}>
                                    <CustomSvgIcon iconSet={{ icon: envelopeResendInvitation }} svg />
                                    Resend Invitation
                                </StyledUserDetailsControllerButton>
                            ))}
                        {details?.sid && (
                            <Link
                                to={appPathForRoutes(`/dashboard?${impersonationUserIdKey}=${details?.sid}`)}
                                target="_blank"
                                style={{ textDecoration: 'none' }}
                                onClick={handleMirrorOnClick}
                            >
                                <StyledUserDetailsControllerButton>
                                    <CustomSvgIcon iconSet={{ icon: mirrorIcon }} svg></CustomSvgIcon>Mirror
                                </StyledUserDetailsControllerButton>
                            </Link>
                        )}
                    </StyledUserDetailsControllerContainer>

                    <NewOfficeAdmin
                        userDetails={details}
                        hasNewUserDetail={hasNewUserDetail}
                        onFormDataOutput={handleFormData}
                        onSetValidForm={setFormValid}
                        formSubmitted={hasBeenSubmitted}
                        onValidationComplete={onValidationComplete}
                        isEdit={isEditEnabled}
                        isUserDetails={true}
                    />

                    {/* Enable once we have Edit functionality */}
                    {!isEditEnabled && (
                        <StyledBottomButtonsContainer>
                            <StyledButton secondary onClick={handleCancelButton}>
                                Cancel
                            </StyledButton>
                            <StyledButton primary onClick={() => submitOfficeAdminFormData()}>
                                Save
                            </StyledButton>
                        </StyledBottomButtonsContainer>
                    )}
                </StyledEditUserContainer>
            )}
        </>
    )
}

export default OfficeAdminUser
