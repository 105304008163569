import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { white } = UI_COLORS

export const StyledFunctionalAssessmentTableContainer = styled.article`
    width: ${pxToRem(1145)};
    margin-bottom: 30px;
`

export const StyledFunctionalAssessmentTableHeader = styled.ul`
    list-style: none;
    background-color: #f7f8fa;
    border: 1px solid #dee5ef;
    padding: ${pxToRem(11)} ${pxToRem(30)};

    display: flex;
    flex-direction: row;
    margin: 0;
`

export const StyledFunctionalAssessmentTableHeaderItem = styled.li`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
    color: #73839c;

    :nth-child(1) {
        width: ${pxToRem(470)};
    }

    :nth-child(2) {
        width: ${pxToRem(120)};
    }
`

export const StyledFunctionalAssessmentTableBody = styled.ul`
    width: ${pxToRem(1195)};
    margin: 0;
    list-style: none;

    display: flex;
    flex-direction: row;
    align-items: stretch;

    padding: 0;
`

export const StyledFunctionalAssessmentTableBodyItem = styled.li`
    display: flex;
    align-items: center;

    :nth-child(1) {
        width: ${pxToRem(500)};
    }

    :nth-child(2) {
        width: ${pxToRem(120)};
    }
`

const StyledFunctionalAssessmentTableBodyItemInner = styled(StyledFunctionalAssessmentTableBodyItem)`
    background-color: ${white};
`

export const StyledFunctionalAssessmentTableBodyItemLeft = styled(StyledFunctionalAssessmentTableBodyItemInner)<{
    isInError?: any
}>`
    border-bottom: 1px solid #dee5ef;
    border-left: 1px solid #dee5ef;
    padding: ${pxToRem(19)} 0 ${pxToRem(19)} ${pxToRem(30)};

    ${(props) => {
        if (props.isInError) {
            return `
                border-left: solid 3px red;
                padding-left: 28px;
                `
        }
    }}
`

export const StyledFunctionalAssessmentTableBodyItemMiddle = styled(StyledFunctionalAssessmentTableBodyItemInner)`
    border-bottom: 1px solid #dee5ef;
    padding: ${pxToRem(19)} 0 ${pxToRem(19)} 0;
`

export const StyledFunctionalAssessmentTableBodyItemRight = styled(StyledFunctionalAssessmentTableBodyItemInner)`
    border-bottom: 1px solid #dee5ef;
    border-right: 1px solid #dee5ef;
    padding: ${pxToRem(19)} ${pxToRem(34)} ${pxToRem(19)} 0;
`

export const StyledFunctionalAssessmentTableBodyItemActivity = styled.article`
    h3 {
        margin: 0;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 10px;

        color: #293854;
    }
`

export const StyledResponsesContainer = styled.article<{ isInError?: any }>`
    display: flex;
    flex-direction: column;
    padding-right: 20px;

    ${(props) => {
        if (props.isInError) {
            return `border-left: 1px solid red;`
        }
    }}

    input {
        margin: 0;
        margin-right: 10px;
    }

    label {
        display: flex;
        align-items: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;

        color: #293854;
        margin-bottom: 10px;
    }
`

export const StyledScoreContainer = styled.article`
    width: 50px;

    input {
        width: 100%;
        outline: none;

        padding: 13px 10px;

        background-color: #f5f5f5;
        border: 1px solid #73839c;
        border-radius: 4px;

        text-align: center;

        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;

        color: #394a64;
    }
`
export const StyledCommentsContainer = styled.article<{ isInError?: any }>`
    input {
        width: 490px;
        height: 50px;
        background: ${white};
        border: 1px solid #73839c;
        border-radius: 4px;
        padding: 0 10px;
        outline: none;
        ${(props) => {
            if (props.isInError) {
                return `
                    border: 1px solid red;
                `
            }
        }}
    }
`

export const StyledResetButtonContainer = styled.article`
    display: flex;
    height: 50px;
    width: 50px;
    margin: 0 0 0 0;
    justify-content: center;
    align-items: center;

    button {
        :disabled {
            visibility: hidden;
        }
    }
`
