export const Admin = {
    users: 'Users',
    buttonLabel: 'Create New User',
    requests: 'Requests',
    adminEmptyStateTitle: 'No users created yet',
    adminEmptyStateSubTitle: 'Add a new user by clicking on Create New User button',
    adminEmptySearchStateTitle: 'Your search didn’t match any results',
    adminEmptySearchStateSubTitle: 'Please modify your search and try again ',
    back: 'Back'
}
