import styled, { css } from 'styled-components'

const HeaderStyles = css`
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
`

export const StyledHeader1 = styled.h1<{ variant: string | undefined }>`
    ${HeaderStyles}
    letter-spacing: -0.01em;
    ${(props) => {
        if (props.variant === 'variant1') {
            return css``
        }
    }}
`

export const StyledHeader2 = styled.h2`
    ${HeaderStyles}
    letter-spacing: -0.01em;
`

export const StyledHeader3 = styled.h3`
    ${HeaderStyles}
`
export const StyledHeader4 = styled.h4`
    ${HeaderStyles}
`
export const StyledHeader5 = styled.h5`
    ${HeaderStyles}
`
export const StyledHeader6 = styled.h6`
    ${HeaderStyles}
`

export const StyledParagraph = styled.p`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
`

export const StyledCode = styled.code``

export const StyledSpan = styled.span``

export const StyledI = styled.i``
export const StyledB = styled.b``
export const StyledStrong = styled.strong``
export const StyledTime = styled.time``
