import { createSlice } from '@reduxjs/toolkit'
import axios from '../../utilities/axios'
import {
    IGetHccCodeDescriptionsRequest,
    IGetHccCodeDescriptionsResponse,
    IGetIcdDiagnosesRequest,
    IGetIcdDiagnosesResponse,
    IGetIcdDiagnosisHeadingRequest,
    IGetIcdDiagnosisHeadingResponse
} from 'pages/hcp/members/electronicAnnualWellnessVisitForm/index.models'
import { createExceptionAwareAsyncThunk } from 'utilities/createExceptionAwareAsyncThunk'

export type DiagnosisState = {
    diagnosesByFilter: any
    diagnosisHeadingsByCode: any
    hccCodeDescriptionsByDiagnosisCode: any
}

const initialState: DiagnosisState = {
    diagnosesByFilter: {},
    diagnosisHeadingsByCode: {},
    hccCodeDescriptionsByDiagnosisCode: {}
}

const diagnosisSlice = createSlice({
    name: 'diagnosis',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getIcdDiagnoses.fulfilled, (state, action) => {
                state.diagnosesByFilter = {
                    ...state.diagnosesByFilter,
                    [action.meta.arg.filter]: action.payload.diagnoses
                }
            })
            .addCase(getIcdDiagnosisHeading.fulfilled, (state, action) => {
                state.diagnosisHeadingsByCode = {
                    ...state.diagnosisHeadingsByCode,
                    [action.meta.arg.diagCode]: action.payload.heading
                }
            })
            .addCase(getHccCodeDescriptions.fulfilled, (state, action) => {
                state.hccCodeDescriptionsByDiagnosisCode = {
                    ...state.hccCodeDescriptionsByDiagnosisCode,
                    [action.meta.arg.diagCode]: action.payload.hccCodeDescriptions
                }
            })
    }
})

export default diagnosisSlice.reducer

export const getIcdDiagnoses = createExceptionAwareAsyncThunk(
    'diagnosis/getIcdDiagnoses',
    async (args: IGetIcdDiagnosesRequest) => {
        const response = await axios.post<IGetIcdDiagnosesResponse>('api/Diagnosis/GetIcdDiagnoses', args)
        return response.data
    }
)

export const getIcdDiagnosisHeading = createExceptionAwareAsyncThunk(
    'diagnosis/getIcdDiagnosisHeading',
    async (args: IGetIcdDiagnosisHeadingRequest) => {
        const response = await axios.post<IGetIcdDiagnosisHeadingResponse>('api/Diagnosis/GetIcdDiagnosisHeading', args)
        return response.data
    }
)

export const getHccCodeDescriptions = createExceptionAwareAsyncThunk(
    'diagnosis/getHccCodeDescriptions',
    async (args: IGetHccCodeDescriptionsRequest) => {
        const response = await axios.post<IGetHccCodeDescriptionsResponse>('api/Diagnosis/GetHccCodeDescriptions', args)
        return response.data
    }
)
