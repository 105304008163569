import useCurrentUser from './useCurrentUser'
import { isProvider } from 'models/enums/role'
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js'
import { dlm, role } from 'appInsights/appInsights'
import useAuthenticatedUser from './useAuthenticatedUser'

const usePostCustomEvent = (pageTitle: string, eventName: string) => {
    const currentUser = useCurrentUser()
    const authenticatedUser = useAuthenticatedUser()
    const appInsights = useAppInsightsContext()
    const name = (isProvider(currentUser) ? role.Provider : role.HCP) + dlm + pageTitle + dlm + eventName
    const email = authenticatedUser.emailAddress

    const trackEvent = useTrackEvent(appInsights, name, { email: email })

    return () => {
        if (process.env.REACT_APP_LOG_CUSTOM_EVENTS_TO_CONSOLE === 'true') {
            console.log('-')
            console.log(' * CUSTOM EVENT *')
            console.log(`Name: ${name}`)
            console.log(`Email: ${email}`)
            console.log(' ***')
            console.log('-')
        }

        trackEvent({ email: email })
    }
}

export default usePostCustomEvent
