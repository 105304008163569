import styled from 'styled-components'
import { UI_COLORS } from 'theme'

const { light_blue2, grey } = UI_COLORS

export const StyledDocumentList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0 0 15px 0;
`

export const StyledDocumentListItem = styled.li`
    display: flex;
    flex-direction: row;
    padding: 0;

    span {
        display: flex;
        flex-direction: row;

        button {
            padding: 0;
            background-color: transparent;
            border: none;
            margin-right: 12px;

            color: ${light_blue2};
            font-family: 'Inter', 'san-serif';
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            cursor: pointer;

            :disabled {
                cursor: default;
                color: ${grey};
            }
        }
    }
`

export const StyledButton = styled.button`
    margin: 0;
    border: none;
    background-color: transparent;
    padding: 0;
    text-align: left;

    figure {
        margin: 0;
    }

    cursor: pointer;
`

export const StyledUploadButton = styled.button`
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        color: ${light_blue2};
        text-align: center;
        font-family: 'Inter', 'sans-serif';
        font-size: 12px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
    }

    figure {
        margin: 0;
        padding: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        margin-right: 6px;
    }

    :disabled {
        cursor: default;

        p {
            color: ${grey};
        }
    }
`
