
// create a map that maps the sort column to the correct value
const memberOutreachSortColumnMapper = {
  IncentiveRemaining: 'AmountRemaining',
  Status: 'Status',
  TotalIncentive: 'TotalIncentive'
}

// this function gets around the fact that typescript doesn't allow you to index into an object with a variable
function hasKey<O>(obj: O, key: PropertyKey): key is keyof O {
    return key in obj
  }

// we only sort by the above three columns, (IncentiveRemaining, Status, TotalIncentive)
// so if the sort column is not one of those, return null
export const getMemberOutreachSortColumn = (sortColumn: string):string | null => {

    if (hasKey(memberOutreachSortColumnMapper, sortColumn)) {
        const mappedColumn: string = memberOutreachSortColumnMapper[sortColumn]
        return mappedColumn
    }
    else {
        return null
    }
}
