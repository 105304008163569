import { FC, useEffect, useState } from 'react'
import { StyledDocumentsContainer, StyledInfoPanel } from './index.style'
import DocumentsTable from './Table'
import { DocumentType, IProviderDocument } from './models'
import { dispatch } from 'redux/store'
import {
    DocumentsState,
    deleteProviderDocument,
    downloadProviderDocument,
    getMemberNpiProviderDocuments,
    getMemberProviderDocuments,
    reprocessMedicalChart,
    uploadProviderDocument
} from 'redux/slices/documents'
import { useSelector } from 'react-redux'
import Spinner from 'components/Spinner'
import UploadFileModal from 'components/UploadFileModal'
import MessageBox from 'components/MessageBox'
import { UI_COLORS } from 'theme'
import { isFileOutsideMaxSizeLimit } from 'utilities/uploadUtilities'
import { hideMainWindowScrollbars } from 'utilities/style'
import useMemberInfoFromUrl from 'hooks/useMemberInfoFromUrl'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import usePostCustomEvent from 'hooks/usePostCustomEvent'

interface IDocumentsProps {}

const Documents: FC<IDocumentsProps> = ({}) => {
    const [memberId, npi] = useMemberInfoFromUrl()

    const { providerDocuments } = useSelector((state: { documents: DocumentsState }) => state.documents)

    const [providerDocumentsLoaded, setProviderDocumentsLoaded] = useState(false)

    const [uploadedDocumentType, setUploadedDocumentType] = useState<DocumentType>()
    const [uploadModalShown, setUploadModalShown] = useState(false)
    const [fileTooLargeMessageBoxShown, setFileTooLargeMessageBoxShown] = useState(false)

    const [uploading, setUploading] = useState(false)
    const [downloading, setDownloading] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [reprocessing, setReprocessing] = useState(false)

    useEffect(() => {
        if (!providerDocumentsLoaded) {
            if (npi) {
                dispatch(getMemberNpiProviderDocuments({ memberId: memberId, npi: npi })).then(() => {
                    setProviderDocumentsLoaded(true)
                })
            } else {
                dispatch(getMemberProviderDocuments({ memberId: memberId })).then(() => {
                    setProviderDocumentsLoaded(true)
                })
            }
        }
    }, [memberId, npi, providerDocumentsLoaded])

    useEffect(() => {
        hideMainWindowScrollbars(uploadModalShown || fileTooLargeMessageBoxShown)
    }, [uploadModalShown, fileTooLargeMessageBoxShown])

    const handleUploadMedicalChart = () => {
        setUploadedDocumentType(DocumentType.MedicalChart)
        setUploadModalShown(true)
    }

    const handleUploadOtherDocument = () => {
        setUploadedDocumentType(DocumentType.Other)
        setUploadModalShown(true)
    }
    const uploadMedicalChartEvent = usePostCustomEvent(pageTitle.PatientsDocuments, customEvents.UploadMedicalChart)
    const uploadDocumentEvent = usePostCustomEvent(pageTitle.PatientsDocuments, customEvents.UploadDocument)
    const performUpload = (file: File) => {
        if (file) {
            if (isFileOutsideMaxSizeLimit(file, false)) {
                setUploadModalShown(false)
                setFileTooLargeMessageBoxShown(true)
            } else {
                if (uploadedDocumentType === DocumentType.MedicalChart) {
                    uploadMedicalChartEvent()
                } else {
                    uploadDocumentEvent()
                }

                setUploading(true)
                dispatch(uploadProviderDocument({ file, memberId, npi, documentTypeId: uploadedDocumentType })).then(
                    () => {
                        setUploading(false)
                        setUploadModalShown(false)
                    }
                )
            }
        }
    }
    const viewMedicalChartEvent = usePostCustomEvent(pageTitle.PatientsDocuments, customEvents.ViewMedicalChart)
    const viewDocumentEvent = usePostCustomEvent(pageTitle.PatientsDocuments, customEvents.ViewDocument)
    const handleDownload = (documentId: string, documentType: DocumentType) => {
        if (documentType === DocumentType.MedicalChart) {
            viewMedicalChartEvent()
        } else {
            viewDocumentEvent()
        }

        setDownloading(true)
        dispatch(downloadProviderDocument({ providerDocumentId: documentId })).then(() => {
            setDownloading(false)
        })
    }

    const handleDelete = (fileId: string) => {
        setDeleting(true)
        dispatch(deleteProviderDocument({ providerDocumentId: fileId })).then(() => {
            setDeleting(false)
        })
    }
    const handleReprocessMedicalChart = (fileId: string) => {
        setReprocessing(true)
        dispatch(reprocessMedicalChart({ providerDocumentId: fileId })).then(() => {
            setReprocessing(false)
        })
    }

    const providerDocumentsKey = npi ? `${memberId}-${npi}` : `${memberId}`
    const charts =
        providerDocuments[providerDocumentsKey]?.filter(
            (x: IProviderDocument) => x.documentTypeId === DocumentType.MedicalChart
        ) ?? []
    const otherDocuments =
        providerDocuments[providerDocumentsKey]?.filter(
            (x: IProviderDocument) => x.documentTypeId === DocumentType.Other
        ) ?? []
    const isAdmin = npi ? false : true

    const isBusy = uploading || downloading || deleting || reprocessing

    return (
        <>
            {uploadModalShown && (
                <UploadFileModal
                    title={
                        uploadedDocumentType === DocumentType.MedicalChart ? 'Upload Medical Chart' : 'Upload Document'
                    }
                    isUploading={uploading}
                    onUpload={performUpload}
                    onCancel={() => setUploadModalShown(false)}
                />
            )}
            {fileTooLargeMessageBoxShown && (
                <MessageBox
                    title="Documents"
                    body="The file you are trying to upload is too large. Please upload a file up to 10MB in size."
                    closeButtonLabel="OK"
                    closeButtonColor={UI_COLORS.red}
                    onClose={() => setFileTooLargeMessageBoxShown(false)}
                />
            )}
            {providerDocumentsLoaded && charts && otherDocuments ? (
                <StyledDocumentsContainer>
                    <StyledInfoPanel>
                        <Typography type={TYPOGRAPHY_TYPES.p}>
                            Any documents, records, or reports pertaining to the patient (not related to an AWV) can be
                            uploaded here. Please ensure that all medical charts, lab results, and imaging reports that
                            should be considered towards the closure of a gap in care are uploaded under{' '}
                            <b>Medical Charts</b>. This will dramatically improve our processing time.
                        </Typography>
                    </StyledInfoPanel>
                    <DocumentsTable
                        documentType={DocumentType.MedicalChart}
                        documents={charts}
                        isAdmin={isAdmin}
                        isBusy={isBusy}
                        uploadDocument={handleUploadMedicalChart}
                        downloadDocument={(id) => handleDownload(id, DocumentType.MedicalChart)}
                        deleteDocument={handleDelete}
                        reprocessDocument={handleReprocessMedicalChart}
                    />
                    <DocumentsTable
                        documentType={DocumentType.Other}
                        documents={otherDocuments}
                        isAdmin={isAdmin}
                        isBusy={isBusy}
                        uploadDocument={handleUploadOtherDocument}
                        downloadDocument={(id) => handleDownload(id, DocumentType.Other)}
                        deleteDocument={handleDelete}
                    />
                </StyledDocumentsContainer>
            ) : (
                <Spinner />
            )}
        </>
    )
}

export default Documents
