import styled from 'styled-components'
import { UI_COLORS } from 'theme'
import { DEVICE, pxToRem } from 'utilities'

const { light_gray4, grey, white, light_blue2 } = UI_COLORS

export const StyledActionsContainer = styled.article`
    display: flex;
    flex-direction: column;
    margin-top: ${pxToRem(25)};
    width: 100%;

    label {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        color: black;
    }

    ${DEVICE.tablet} {
        position: absolute;
        bottom: ${pxToRem(30)};
        padding-right: ${pxToRem(70)};
    }
`

export const StyledButtonContainer = styled.article`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
`

export const StyledSelectButton = styled.button<{ actionButtonType?: boolean }>`
    width: ${pxToRem(120)};
    height: ${pxToRem(50)};
    border-radius: ${pxToRem(4)};
    border: 0px;
    cursor: pointer;

    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};
    color: ${white};

    background-color: ${light_blue2};

    :active {
        background-color: ${white};
        color: ${light_blue2};
        border: 1px solid ${light_blue2};
    }

    :disabled {
        background-color: ${light_gray4};

        :active {
            background-color: ${light_gray4};
            color: ${white};
            border: 1px solid ${light_gray4};
        }
    }
`

export const StyledCancelButton = styled.button`
    width: ${pxToRem(120)};
    height: ${pxToRem(50)};
    border-radius: ${pxToRem(4)};
    border: 0px;
    cursor: pointer;

    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};

    margin-right: ${pxToRem(16)};
    background-color: ${grey};
    color: ${white};

    :disabled {
        cursor: default;
        background: ${light_gray4};
    }

    :enabled {
        :active {
            background-color: ${white};
            color: ${grey};
            border: 1px solid ${grey};
        }
    }
`

export const StyledSpinnerWrap = styled.article`
    display: flex;
    flex-direction: row;
    justify-content: center;

    width: 120px;
    height: 50px;

    article {
        height: 100%;
        svg {
            width: 50px;
            margin: 0;
        }
    }
`
