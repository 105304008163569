import DropdownSelect from 'components/DropdownSelect'
import TextField from 'components/TextField'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC, useEffect, useRef, useState } from 'react'
import { isZipCodeValid } from 'utilities/addressUtilities'
import { validateZipCode } from 'utilities/validationUtilities'
import { ILocation, ILocationData } from '../../index.models'
import { UsaStates } from './index.models'
import {
    StyledModalBackground,
    StyledModalContainer,
    StyledButtonContainer,
    StyledCityStateContainer,
    StyledSaveButton,
    StyledCancelButton,
    StyledInputWrap,
    StyledInputAddressWrap
} from './index.style'

interface IAddressModalProps {
    locationType?: number
    onCancel: () => void
    onSave: (formData: any, id: any) => void
    locationToEdit?: any
}

const initialObject: ILocationData = {
    locationId: null,
    selectedLocationIndex: -1,
    locationType: 1,
    officePhoneNumber: '',
    address: '',
    suite: '',
    city: '',
    state: UsaStates.UNKNOWN,
    zipCode: '',
    ezCapLocationId: null,
    error: false,
    errorMessage: '',
    locationErrors: {locationTypeError: false, 
        locationAddressError: false,
        officePhoneError: false, 
        locationTypeErrorMessage: '', 
        officePhoneErrorMessage: '',
        locationAddressErrorMessage: ''}
}

const AddressModal: FC<IAddressModalProps> = ({ locationType, onCancel, onSave, locationToEdit }) => {
    const [formData, setFormData] = useState(initialObject)
    const [errors, setErrors] = useState({ address: '', suite: '', city: '', state: '', zipCode: '' })
    const [isValidForm, setIsValidForm] = useState<boolean | null>(false)

    useEffect(() => {
        if (locationToEdit) {
            const receiveAddressToEdit = {
                ...formData,
                suite: locationToEdit.address.address2,
                address: locationToEdit.address.address1,
                city: locationToEdit.address.city,
                state: locationToEdit.address.state,
                zipCode: locationToEdit.address.zip,
                ezCapLocationId: locationToEdit.address.ezCapLocationId
            }

            setFormData(receiveAddressToEdit)
        }
    }, [locationToEdit])

    const handleSelectedState = (data: string) => {
        setFormData({ ...formData, state: UsaStates[data as keyof typeof UsaStates] })
        setErrors({ ...errors, state: '' })
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target     
        setFormData({ ...formData, [name]: value })
        setIsValidForm(true)

        if (name === 'address') {
            setErrors({ ...errors, address: '' })
        }

        if (name === 'city') {
            setErrors({ ...errors, city: '' })
        }

        if (name === 'zipCode') {          
            setErrors({ ...errors, zipCode: '' })
        }
    }

    const handleZipOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        let newErrors = { ...errors }
        let isValid = true
        if (name === 'zipCode') {
            ({isValid, newErrors} = validateZipCode(value, newErrors, isValid))
            setErrors( newErrors )
        }
    }

    const validateNewAddressForm = () => {
        let newErrors = { ...errors }
        let isValid = true
        const { address, city, state, zipCode } = formData

        if (!address) {
            newErrors = { ...newErrors, address: 'Required' }
            isValid = false
        }

        if (!city) {
            newErrors = { ...newErrors, city: 'Required' }
            isValid = false
        }

        if (state === UsaStates.UNKNOWN || !state) {
            newErrors = { ...newErrors, state: 'Required' }
            isValid = false
        }

        ({ newErrors, isValid } = validateZipCode(zipCode, newErrors, isValid))

        setIsValidForm(isValid)
        setErrors(newErrors)
        return isValid
    }

    const submitFormData = () => {
        const isValid = validateNewAddressForm()
        if (isValid) {
            onSave(formData, locationToEdit?.id)
        }
    }

    return (
        <StyledModalBackground>
            <StyledModalContainer>
                <Typography type={TYPOGRAPHY_TYPES.h2}>Please enter your location</Typography>
                <StyledInputAddressWrap>
                    <TextField
                        name="address"
                        placeholder="Street Address"
                        legend="Street Address"
                        inputType={'text'}
                        onTextChanged={handleInput}
                        error={errors?.address.length > 0}
                        errorMessage={errors?.address}
                        value={formData.address}
                    />
                </StyledInputAddressWrap>
                <StyledInputWrap>
                    <TextField
                        name="suite"
                        placeholder="Office/Building Number"
                        legend="Office/Building Number"
                        inputType={'text'}
                        onTextChanged={handleInput}
                        value={formData.suite !== 'null' ? formData.suite : ''}
                    />
                </StyledInputWrap>

                <StyledCityStateContainer>
                    <article style={{ marginBottom: 20 }}>
                        <TextField
                            name="city"
                            placeholder="City"
                            legend="City"
                            inputType={'text'}
                            onTextChanged={handleInput}
                            error={errors?.city.length > 0}
                            errorMessage={errors?.city}
                            value={formData.city}
                        />
                    </article>

                    <article>
                        <DropdownSelect
                            stateValue={formData.state}
                            onSelectedState={handleSelectedState}
                            error={errors?.state.length > 0}
                        />
                    </article>
                </StyledCityStateContainer>

                <StyledInputWrap>
                    <TextField
                        name="zipCode"
                        placeholder="Zip Code"
                        pattern="^[0-9]*$"
                        maxlength={5}
                        legend="Zip Code"
                        inputType={'text'}
                        onTextChanged={handleInput}
                        handleBlur={handleZipOnBlur}
                        error={errors?.zipCode.length > 0}
                        errorMessage={errors?.zipCode}
                        value={formData.zipCode}
                    />
                </StyledInputWrap>

                <StyledButtonContainer>
                    <StyledSaveButton
                        onClick={() => {
                            // onSave(formData)
                            submitFormData()
                        }}
                    >
                        Save
                    </StyledSaveButton>
                    <StyledCancelButton onClick={onCancel}>Cancel</StyledCancelButton>
                </StyledButtonContainer>
            </StyledModalContainer>
        </StyledModalBackground>
    )
}

export default AddressModal


