import { isValid } from 'tin-validator';
import { parsePhoneNumber } from "libphonenumber-js"
import { IOfficeAdminProviderInfo, ITaxIdNpiData } from "pages/hcp/users/create/index.models"
import { isZipCodeValid } from "./addressUtilities"
import { isValidAreaCode, removePhoneNumberPunctuation } from "./stringUtilities"

   export const validateEmail  = (email: string, newErrors: any, userDetailsEmail: string, emailExists: boolean):  [boolean, any] => {
    let isValid = true
    if (!email) {
        newErrors = { ...newErrors, email: 'Required' }
        isValid = false
    } else if (!emailRegex.test(email)) {
        newErrors = { ...newErrors, email: 'Invalid email format' }
        isValid = false
    } else if (emailExists && isANewEmail(email, userDetailsEmail)) {
        newErrors = { ...newErrors, email: 'Email already exists' }
        isValid = false
    } else if (email.includes('@hcpipa.com')) {
        newErrors = { ...newErrors, email: 'Unable to use an hcpipa e-mail' }
        isValid = false
    }

      return [isValid, newErrors.email]
   }

  export const isANewEmail = (email: string, userDetailsEmail: string) => {
    return email?.trim()?.length > 0 && email !== userDetailsEmail
}


export const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i


export const validateAreaCode = (phoneNumber: string, validAreaCodes: number[]): boolean  => {
        if (phoneNumber === undefined) return false
        const strippedPhoneNumber = removePhoneNumberPunctuation(phoneNumber)
        const isValid = isValidAreaCode(strippedPhoneNumber, validAreaCodes)
        return isValid
}

export const validatePhoneIsNotBlank = (phoneNumber: string): boolean => {
    if (phoneNumber === undefined) return false
    const strippedPhoneNumber = removePhoneNumberPunctuation(phoneNumber)
    const isValid = strippedPhoneNumber?.length > 0
    return isValid
}

export const validatePhoneIsCorrectLength = (phoneNumber: string): boolean => {
    if (phoneNumber === undefined) return false
    const strippedPhoneNumber = removePhoneNumberPunctuation(phoneNumber)
    const isValid = strippedPhoneNumber ? strippedPhoneNumber?.length === 10 : false
    return isValid
}
        

export const validatePhone = (phoneNumber: string, newErrors: any, validAreaCodes: number[]):[boolean, any] => {
    const strippedPhoneNumber = removePhoneNumberPunctuation(phoneNumber)


    let isValid = true

    if (!phoneNumber || strippedPhoneNumber?.length === 0) {
        newErrors = { ...newErrors, phoneNumber: 'Required' }
        isValid = false
    }

    else if (strippedPhoneNumber?.length > 0 && strippedPhoneNumber?.length < 10) {
        newErrors = { ...newErrors, phoneNumber: 'Not a valid phone number' }
        isValid = false
    } else if (strippedPhoneNumber?.length > 0 && !isValidAreaCode(strippedPhoneNumber, validAreaCodes)) {
        newErrors = { ...newErrors, phoneNumber: 'Not a US phone number' }
        isValid = false
    }

    return [isValid, newErrors.phoneNumber]
}


export const  validateZipCode = (zipCode: string, newErrors: any, isValid: boolean) : {newErrors: any, isValid: boolean} => {
    if (!zipCode) {
        newErrors = { ...newErrors, zipCode: 'Required' }
        isValid = false
    } else if (!isZipCodeValid(zipCode)) {
        newErrors = { ...newErrors, zipCode: 'Invalid zip code' }
        isValid = false
    }
    return { newErrors, isValid }
}

const handleDuplicateTaxIds = (newData: { taxIdProviderGroupings: ITaxIdNpiData[] }, taxIdIndex: number, taxId: string):void  => {    
    const otherTaxIds = newData.taxIdProviderGroupings.find((taxIdData, index) => taxIdData.taxId === taxId && index !== taxIdIndex)
    const isDuplicate = otherTaxIds !== undefined && taxId !== ''
    if (isDuplicate) {
        // clear the required field errors
        newData.taxIdProviderGroupings[taxIdIndex].error = true
        newData.taxIdProviderGroupings[taxIdIndex].errorMessage = 'Duplicate Tax Id'
    }

}

const TaxIdLength: number = 9

export const validateTaxId = (taxId: string, taxIdIndex: number, currentProviderInfo: IOfficeAdminProviderInfo ): IOfficeAdminProviderInfo  =>      {
    const strippedTaxId = taxId?.replace('-', '').replaceAll('_', '')
    const newData = { ...currentProviderInfo }
    const taxIdData = newData.taxIdProviderGroupings[taxIdIndex]

    if (strippedTaxId === '' || strippedTaxId === undefined) {
        taxIdData.error = true
        taxIdData.errorMessage = 'Required'
    }
    else if (strippedTaxId.length < TaxIdLength) {
        taxIdData.error = true
        taxIdData.errorMessage = 'Invalid Tax Id'
    }

    else if (strippedTaxId.length === TaxIdLength) {
        // check if duplicate tax id is being selected

        taxIdData.taxId = taxId
       handleDuplicateTaxIds(newData, taxIdIndex, taxId)
    }

    return newData
}

export const validateSelectionIndex = (selectionIndex: number): boolean => {
    return selectionIndex !== -1
}

export const validateNpi = (npi: string, npiInfo: any, newErrors: any): {newErrors: any, isValidNpi: boolean}  => {

    let isValidNpi = true

    if (!npi) {
        newErrors = { ...newErrors, npi: 'Required' }
        isValidNpi = false
    }
    else if (npi?.length !== 10) {
        newErrors = { ...newErrors, npi: 'Invalid NPI' }
        isValidNpi = false
    }

    else if (!npiInfo?.npiIsValid) {
        newErrors = { ...newErrors, npi: 'Invalid NPI' }
        isValidNpi = false
    }

    else if (npiInfo?.providerWithNpiAlreadyExists && npiInfo?.npiIsValid) {
        newErrors = { ...newErrors, npi: 'NPI already exists' }
        isValidNpi = false
    }

    return { newErrors, isValidNpi }

}

export     const getIndexFromYupPath = (path: string) => {
    const index = path.split('[')[1].split(']')[0]
    return parseInt(index)
}

       
     