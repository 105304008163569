import { FieldValues, UseFieldArrayAppend, UseFieldArrayMove, UseFieldArrayRemove } from 'react-hook-form'
import { ISection } from '../index.models'

interface IFieldSynchronizationParams {
    items: any[]
    fields: any[]
    append: UseFieldArrayAppend<FieldValues, string>
    remove: UseFieldArrayRemove
    move: UseFieldArrayMove
}

const addMissingItem = (params: IFieldSynchronizationParams): boolean => {
    const { items, fields, append } = params
    for (let i = 0; i < items.length; i++) {
        if (fields.findIndex((field) => field.name === items[i].name) < 0) {
            append({ name: items[i].name }, { shouldFocus: false })
            return true
        }
    }
    return false
}

const removeRedundantField = (params: IFieldSynchronizationParams): boolean => {
    const { items, fields, remove } = params
    for (let i = 0; i < fields.length; i++) {
        if (items.findIndex((item) => item.name === fields[i].name) < 0) {
            remove(i)
            return true
        }
    }
    return false
}

const rearrangeMisplacedField = (params: IFieldSynchronizationParams): void => {
    const { items, fields, move } = params
    for (let i = 0; i < fields.length; i++) {
        if (items.findIndex((item) => item.name === fields[i].name) < i) {
            move(i, i - 1)
            return
        }
    }
}

export const performFieldSynchronizationStep = (
    items: any[],
    fields: any[],
    append: UseFieldArrayAppend<FieldValues, string>,
    remove: UseFieldArrayRemove,
    move: UseFieldArrayMove
): void => {
    const params = { items, fields, append, remove, move }

    if (!addMissingItem(params)) {
        if (!removeRedundantField(params)) {
            rearrangeMisplacedField(params)
        }
    }
}

export const getTopLevelInErrorElementNames = (validationErrors: any): string[] =>
    validationErrors
        ? Object.keys(validationErrors)
              .map((x) => x.split('_')[0])
              .filter((x, index, array) => array.indexOf(x) === index)
              .sort()
        : []

const isElementInError = (elements: any[], topLevelInErrorElementNames: string[]): boolean => {
    for (let i = 0; i < elements.length; i++) {
        if (elements[i].name) {
            if (topLevelInErrorElementNames.includes(elements[i].name)) {
                return true
            }
        } else {
            const children = elements[i].elements ?? elements[i].items
            if (children) {
                if (isElementInError(children, topLevelInErrorElementNames)) {
                    return true
                }
            }
        }
    }
}

export const isSectionInError = (section: ISection, topLevelInErrorElementNames: string[]): boolean =>
    isElementInError(section.elements, topLevelInErrorElementNames)
