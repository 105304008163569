import { Fragment } from "react"
import { useMsal } from "@azure/msal-react"

import { getLoginRequest } from "config/auth"

const AADLogin = () => {
    const { instance } = useMsal()

    const redirect = () => instance.loginRedirect(getLoginRequest())

    return(
        <Fragment>
            {redirect()}
        </Fragment>
    )
}

export default AADLogin
