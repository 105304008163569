import { FC } from 'react'

import CustomSvgIcon from 'components/CustomSvgIcon'

import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'

import { StyledCloseButton, StyledToastContainer, StyledToastInnerContainer } from './index.style'
import closeIcon from 'assets/closeIcon.svg'
import { removeToast } from 'redux/slices/toast'
import { dispatch } from 'redux/store'
import { EToastVariant } from 'models/IToast'
import useCurrentUser from 'hooks/useCurrentUser'

export type IToastProps = {
    id: number
    message: string
    icon: any
    variant: EToastVariant
}

const Toast: FC<IToastProps> = ({ id, message, icon, variant }: IToastProps) => {
    const currentUser = useCurrentUser()

    const handleClose = (id: number) => {
        dispatch(removeToast(id))
    }

    return (
        <StyledToastContainer isImpersonating={currentUser.isImpersonated}>
            <StyledToastInnerContainer variant={variant}>
                <CustomSvgIcon iconSet={{ icon }} svg></CustomSvgIcon>
                <Typography type={TYPOGRAPHY_TYPES.p}>{message}</Typography>
            </StyledToastInnerContainer>
            <StyledCloseButton image={closeIcon} onClick={() => handleClose(id)}></StyledCloseButton>
        </StyledToastContainer>
    )
}

export default Toast
