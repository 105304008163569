import styled, { css } from 'styled-components'
import { pxToRem, DEVICE } from 'utilities'
import { UI_COLORS } from 'theme'

const { dark_gray } = UI_COLORS

export const StyledRadioInputContainer = styled.article`
    margin-bottom: ${pxToRem(53)};
`

export const StyledRadioWrapSingle = styled.article`
    display: flex;
    align-items: center;

    cursor: pointer;

    :nth-child(1) {
        margin-bottom: ${pxToRem(30)};
    }
`

export const StyledRadioInput = styled.input`
    margin-right: ${pxToRem(13)};
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
`

export const StyledRadioLabel = styled.label`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: ${pxToRem(14)};
    width: ${pxToRem(230)};
    color: ${dark_gray};

    ${DEVICE.tablet} {
        font-size: ${pxToRem(16)};
        width: 100%;
    }
`

export const StyledRadioImage = styled.article<{ image: string }>`
    height: 40px;
    width: 40px;

    background-repeat: no-repeat;
    background-size: contain;

    margin-right: ${pxToRem(14)};

    ${(props) => {
        if (props.image) {
            return css`
                background-image: url(${props.image});
            `
        }
    }}
`

export const StyledRadioLabelImageGroup = styled.article`
    display: flex;
    align-items: center;

    figure {
        margin: 0;
        margin-right: 14px;
        svg {
            width: 35px;
            height: 35px;
        }
    }
`
