import { ILocationData, ILocationDetailData } from "pages/hcp/users/create/index.models"

const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/i

export const isZipCodeValid = (zip: string) =>  {
        return zipCodeRegex.test(zip)
    }

export const areLocationsTheSame = (loc1: ILocationData, loc2: ILocationData): boolean => {
    return (loc1.address === loc2.address &&
            loc1.suite === loc2.suite &&
            loc1.city === loc2.city &&
            loc1.state === loc2.state &&
            loc1.zipCode === loc2.zipCode
            )

}

export interface IAddressModalInfo {
    isShown: boolean
    locationType: number
}

export const TranslateToClientLocation = (location: ILocationData): ILocationDetailData => {
    const translatedLocation: ILocationDetailData = {
        city: location.city,
        state: location.state,
        zip: location.zipCode,
        address1: location.address,
        address2: location.suite,
        ezCapLocationId: location.ezCapLocationId,
        locationId: location.locationId,
        isPrimary: location.locationType === 1,
        officePhoneNumber: location.officePhoneNumber
    }

    return translatedLocation
}

export const isLocationDuplicated = (locations: ILocationData[] , locIndex: number) => {
    const currentLocation = locations[locIndex]
    const filteredLocations = locations.filter(
        (location, index) => index !== locIndex
    )
    const isDuplicate = filteredLocations.some((loc) => areLocationsTheSame(loc, currentLocation))
    return isDuplicate
}

export const isLocationLabelDuplicated = (locationLabels: string[] , currentLocationLabel: string) => {
    return locationLabels.some(locLabel => locLabel === currentLocationLabel)
}

export const areAddressesTheSame = (location1: ILocationDetailData, location2: ILocationDetailData) : boolean => {
       
    return (location1.address1 === location2.address1  &&
     location1.address2 === location2.address2  &&
     location1.city === location2.city &&
     location1.state === location2.state &&
     location1.zip === location2.zip &&
     location1.ezCapLocationId === location2.ezCapLocationId
    )
}

export const isExistingAddress = (locations: ILocationDetailData[], address: ILocationDetailData) => {
    return locations.some((loc: ILocationDetailData) =>  areAddressesTheSame(loc, address))
}
