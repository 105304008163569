import { FC } from 'react'
import { StyledDocumentsHeaderTable, StyledDocumentsHeaderTableItem } from '../index.style'

interface IDocumentsTableHeaderProps {
    headers: string[]
}

const DocumentsTableHeader: FC<IDocumentsTableHeaderProps> = ({ headers }) => {
    return (
        <StyledDocumentsHeaderTable>
            {headers.map((headerItem: string, idx: number) => {
                return (
                    <StyledDocumentsHeaderTableItem key={`headerItem-${idx}`}>
                        {headerItem}
                    </StyledDocumentsHeaderTableItem>
                )
            })}
        </StyledDocumentsHeaderTable>
    )
}

export default DocumentsTableHeader
