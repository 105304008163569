import { IMemberProfileLookupData } from 'pages/hcp/users/create/index.models'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchMemberProfileLookups, MemberProfileState } from 'redux/slices/memberProfile'
import { dispatch } from 'redux/store'

const useMemberProfileLookupData = (): IMemberProfileLookupData => {
    const memberProfileLookupData: IMemberProfileLookupData = useSelector(
        (state: { memberProfile: MemberProfileState }) => state.memberProfile.memberProfileLookupData
    )

    useEffect(() => {
        if (memberProfileLookupData) return
        dispatch(fetchMemberProfileLookups())
    }, [memberProfileLookupData])

    return memberProfileLookupData
}

export default useMemberProfileLookupData
