import { FC } from 'react'
import { StyledAWVDigitalInput, StyledResetContainer, StyleFieldResetContainer } from '../index.style'
import { Controller, useFormContext } from 'react-hook-form'
import DropDownListComponent from 'components/DropDownList'
import { dropDownListStyleParams } from './index.style'
import { IDropDownList, IGenderPicker, ISectionElement } from '../index.models'
import { Label } from '../Label'
import { AwvTooltip } from '../AwvTooltip'
import useErrors from 'hooks/useErrors'
import IconButton from '@mui/material/IconButton'
import { Tooltip } from '@mui/material'
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp'
import { useResetValue } from 'hooks/useResetValue'

interface IOption {
    label: string
    value: any
}

interface IDropDownListProps {
    name: string
    label: string
    subLabel: string
    placeholder: string
    hideReset?: boolean
    options: IOption[]
    readOnly?: boolean
}

export const DropDownList: FC<IDropDownListProps> = ({
    name,
    label,
    subLabel,
    placeholder,
    hideReset = false,
    options,
    readOnly = false
}) => {
    const { control, setValue, getValues } = useFormContext()
    const errors = useErrors()
    const resetValue = useResetValue()

    return (
        <AwvTooltip message={errors.getError(name)}>
            <StyledAWVDigitalInput isInError={errors.getError(name)}>
                <Label htmlFor={name} label={label} subLabel={subLabel} />
                <StyleFieldResetContainer>
                    <Controller
                        name={name}
                        control={control}
                        render={({ field }) => (
                            <DropDownListComponent
                                {...field}
                                id={name}
                                placeholder={placeholder}
                                options={options}
                                selectedValue={field.value}
                                setSelectedValue={(value) => {
                                    setValue(name, value)
                                }}
                                isInError={errors.getError(name) ? true : false}
                                styleParams={dropDownListStyleParams}
                                isDisabled={readOnly}
                                isReadOnly={readOnly}
                            />
                        )}
                    />
                    <StyledResetContainer>
                        {!hideReset && !readOnly && getValues(name) !== null && getValues(name) !== undefined && (
                            <Tooltip title="Reset">
                                <IconButton
                                    onClick={() => {
                                        resetValue(name)
                                    }}
                                >
                                    <HighlightOffSharpIcon sx={{ color: '#9e9e9e' }} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </StyledResetContainer>
                </StyleFieldResetContainer>
            </StyledAWVDigitalInput>
        </AwvTooltip>
    )
}

export const renderDropDownList = (element: ISectionElement, key: string) => {
    const dropDownList = element as IDropDownList
    return <DropDownList key={key} {...dropDownList} />
}

export const renderGenderPicker = (element: ISectionElement, key: string) => {
    const genderPicker = element as IGenderPicker
    return <DropDownList key={key} {...genderPicker} readOnly={true} />
}
