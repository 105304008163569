import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux'
import { rootReducer } from './rootReducer'

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false
        })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

const { dispatch } = store

const useSelector = useReduxSelector
const useDispatch = () => useReduxDispatch<AppDispatch>()
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export { store, dispatch, useDispatch, useSelector, useAppSelector }

/**
 * generic key/value pair with dynamic key for Store data
 */
export type dynamicStoreData<T> = {
    [key: string]: T
}
