import styled from 'styled-components'
import { UI_COLORS } from 'theme'

const { dark_gray } = UI_COLORS

export const StyledStandaloneLabelWrapper = styled.article`
    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: ${dark_gray};
    }
`
