import styled from 'styled-components'
import { UI_COLORS } from 'theme/constants'
import { DEVICE, pxToRem } from 'utilities/style'

const { light_blue2, white, grey, dark_gray } = UI_COLORS

export const StyledEditUserContainer = styled.article`
    padding: ${pxToRem(50)} ${pxToRem(30)};

    h2 {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(22)};
        line-height: ${pxToRem(31)};
        letter-spacing: -0.01em;
        color: #293854;
    }

    h3 {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(26)};
        letter-spacing: -0.01em;
        color: #293854;
        position: relative;

        :after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            display: inline-block;
            width: 40px;
            height: 3px;
            background-color: #293854;
        }
    }

    fieldset {
        margin: 0;
        border: 0;
        padding: 0;

        :disabled {
            input {
                background-color: #f5f5f5;
            }
        }
    }

    ${DEVICE.desktop} {
        padding: ${pxToRem(180)} ${pxToRem(30)} ${pxToRem(120)};
    }
`
