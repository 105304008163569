import Modal from 'components/Modal'
import { FC, useEffect, useState } from 'react'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import {
    StyledButtonContainer,
    StyledCancelButton,
    StyledReferralSearchResult,
    StyledReferralSearchResultsWrap,
    StyledSelectButton
} from './index.style'
import { Autocomplete, TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import { GapsInCareState, getReferralSpecialist, getReferralSpecialists } from 'redux/slices/gapsInCare'
import { IReferralSpecialist } from 'pages/hcp/users/create/index.models'
import useDebounce from 'hooks/useDebounce'
import { dispatch } from 'redux/store'

interface IReferralSpecialistModalProps {
    gapInCare: string
    currentReferralSpecialistId: string
    onSelect(referralSpecialist: IReferralSpecialist): void
    onCancel: () => void
}

const ReferralSpecialistModal: FC<IReferralSpecialistModalProps> = ({
    gapInCare,
    currentReferralSpecialistId,
    onSelect,
    onCancel
}) => {
    const { referralSpecialistsById, referralSpecialistsByFilter } = useSelector(
        (state: { gapsInCare: GapsInCareState }) => state.gapsInCare
    )

    const currentReferralSpecialist = currentReferralSpecialistId
        ? referralSpecialistsById[currentReferralSpecialistId]
        : null

    const [inputValue, setInputValue] = useState('')
    const [selectedReferralSpecialist, setSelectedReferralSpecialist] = useState<IReferralSpecialist>(null)
    const [currentReferralSpecialistLoaded, setCurrentReferralSpecialistLoaded] = useState(false)

    const debouncedInputValue = useDebounce(inputValue, 500)

    const options = selectedReferralSpecialist
        ? referralSpecialistsByFilter[inputValue]
            ? referralSpecialistsByFilter[inputValue].find((x) => x.id === selectedReferralSpecialist.id)
                ? referralSpecialistsByFilter[inputValue]
                : [selectedReferralSpecialist, ...referralSpecialistsByFilter[inputValue]]
            : [selectedReferralSpecialist]
        : referralSpecialistsByFilter[inputValue] ?? []

    useEffect(() => {
        if (currentReferralSpecialistId && !currentReferralSpecialist) {
            dispatch(getReferralSpecialist({ referralSpecialistId: currentReferralSpecialistId }))
        }
    }, [currentReferralSpecialistId, currentReferralSpecialist])

    useEffect(() => {
        if (currentReferralSpecialist && !selectedReferralSpecialist && !currentReferralSpecialistLoaded) {
            setCurrentReferralSpecialistLoaded(true)
            setSelectedReferralSpecialist(currentReferralSpecialist)
        }
    }, [currentReferralSpecialist, selectedReferralSpecialist, currentReferralSpecialistLoaded])

    useEffect(() => {
        if (debouncedInputValue?.trim().length >= 3 && !referralSpecialistsByFilter[debouncedInputValue]) {
            dispatch(getReferralSpecialists({ filter: debouncedInputValue }))
        }
    }, [referralSpecialistsByFilter, debouncedInputValue, dispatch])

    const handleSelect = () => {
        if (selectedReferralSpecialist) {
            onSelect(selectedReferralSpecialist)
        }
    }

    return (
        <Modal onClose={onCancel} minWidth={630} minHeight={350}>
            <Typography type={TYPOGRAPHY_TYPES.h2}>Select Referral Specialist</Typography>
            <Typography type={TYPOGRAPHY_TYPES.p}>{gapInCare}</Typography>
            <Autocomplete
                disabled={
                    currentReferralSpecialistId && !selectedReferralSpecialist && !currentReferralSpecialistLoaded
                }
                options={options}
                noOptionsText="No referral specialists found."
                filterOptions={(x) => x}
                getOptionLabel={(option) => `${option.fullName} - ${option.specialty}`}
                value={selectedReferralSpecialist}
                onInputChange={(_, value) => {
                    setInputValue(value)
                }}
                onChange={(_, value) => {
                    setSelectedReferralSpecialist(value)
                }}
                isOptionEqualToValue={(option: IReferralSpecialist, value: IReferralSpecialist) =>
                    option?.id === value?.id
                }
                renderInput={(params) => (
                    <StyledReferralSearchResultsWrap>
                        <TextField {...params} placeholder={'Referral Specialist'} />
                    </StyledReferralSearchResultsWrap>
                )}
                renderOption={(props, option) => (
                    <StyledReferralSearchResult
                        key={option.id}
                        onClick={(e) => {
                            props.onClick(e)
                            setSelectedReferralSpecialist(option)
                        }}
                        isCurrent={option.id === selectedReferralSpecialist?.id}
                    >
                        {`${option.fullName} - ${option.specialty}`} <span>{`(${option.locations})`}</span>
                    </StyledReferralSearchResult>
                )}
            />
            <StyledButtonContainer>
                <StyledCancelButton onClick={onCancel}>Cancel</StyledCancelButton>
                <StyledSelectButton
                    onClick={handleSelect}
                    disabled={
                        !selectedReferralSpecialist || selectedReferralSpecialist.id === currentReferralSpecialistId
                    }
                >
                    Select
                </StyledSelectButton>
            </StyledButtonContainer>
        </Modal>
    )
}

export default ReferralSpecialistModal
