import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC, useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import StyledDocumentsModalActionButton, {
    StyledRemoveFileButtonContainer,
    StyledDropzoneContainer,
    StyledErrorMessageContainer,
    StyledLongDashedBorder,
    StyledSelectedFileContainer,
    StyledSelectFileButton,
    StyledDocumentsModalListContainer,
    StyledIconActionButton
} from './index.style'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { redCrossIcon, deleteIcon, disabledDeleteIcon } from './icons'

interface IDropZoneProps {
    contentType?: string
    extension?: string
    selectedFile: (file: any) => void
    acceptableFile: (accepted: any) => void
    // make optional
    showSelectedFiles?: boolean
    setShowSelectedFiles?: (showSelected: any) => void
    isDocumentsTab?: boolean
    deleteFile?: () => void
    isAWVFile?: boolean
    disabled?: boolean
}

const DropZone: FC<IDropZoneProps> = ({
    contentType = 'application/pdf',
    extension = '.pdf',
    selectedFile,
    acceptableFile,
    showSelectedFiles = true,
    setShowSelectedFiles,
    isDocumentsTab,
    deleteFile,
    isAWVFile = false,
    disabled = false
}) => {
    const onDrop = useCallback((acceptedFiles: any) => {
        selectedFile(acceptedFiles)
        if (setShowSelectedFiles) setShowSelectedFiles(true)
    }, [])

    const { acceptedFiles, getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop,
        accept: {
            [contentType]: [extension]
        },
        multiple: !isAWVFile,
        disabled
    })

    const files = acceptedFiles.map((file: any) => <li key={file.path}>{file.name}</li>)

    const fileRejectionItems = fileRejections.map(({ file, errors }: any) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
            <ul>
                {errors.map((e: any) => (
                    <li key={e.code}>(Error Message: {e.message})</li>
                ))}
            </ul>
        </li>
    ))

    useEffect(() => {
        if (fileRejectionItems.length > 0) {
            acceptableFile(false)
        } else if (files.length > 0) {
            acceptableFile(true)
        }
    }, [files, fileRejectionItems])

    const remove = (file: any) => {
        acceptedFiles.splice(file, 1) // remove the file from the array
        deleteFile()
    }

    return (
        <>
            <StyledLongDashedBorder>
                <StyledDropzoneContainer {...getRootProps()}>
                    <input type="file" {...getInputProps()} disabled={true} />
                    {isAWVFile && <p>Drag and drop your completed AWV file here, or click Select File</p>}
                    <StyledSelectFileButton disabled={disabled}>Select File</StyledSelectFileButton>
                </StyledDropzoneContainer>
            </StyledLongDashedBorder>

            {!isDocumentsTab && files.length > 0 && showSelectedFiles ? (
                <StyledSelectedFileContainer>
                    <ul>
                        <StyledRemoveFileButtonContainer>
                            <Typography type={TYPOGRAPHY_TYPES.p}>{files}</Typography>
                            <StyledIconActionButton onClick={remove}>
                                <CustomSvgIcon iconSet={{ icon: redCrossIcon }} svg></CustomSvgIcon>
                            </StyledIconActionButton>
                        </StyledRemoveFileButtonContainer>
                    </ul>
                </StyledSelectedFileContainer>
            ) : (
                ''
            )}

            {isDocumentsTab && files.length > 0 && showSelectedFiles ? (
                <StyledSelectedFileContainer>
                    <ul>
                        <StyledDocumentsModalListContainer>
                            <Typography type={TYPOGRAPHY_TYPES.p}>{files}</Typography>
                            <StyledDocumentsModalActionButton onClick={remove} disabled={disabled}>
                                <CustomSvgIcon
                                    iconSet={{ icon: disabled ? disabledDeleteIcon : deleteIcon }}
                                    svg
                                ></CustomSvgIcon>
                            </StyledDocumentsModalActionButton>
                        </StyledDocumentsModalListContainer>
                    </ul>
                </StyledSelectedFileContainer>
            ) : (
                ''
            )}

            {/* Custom error message */}

            {isAWVFile && fileRejectionItems.length > 1 ? (
                <StyledErrorMessageContainer>
                    <Typography type={TYPOGRAPHY_TYPES.h4}>Only One AWV file can be uploaded.</Typography>
                </StyledErrorMessageContainer>
            ) : (
                ''
            )}

            {fileRejectionItems.length > 0 ? (
                <StyledErrorMessageContainer>
                    <Typography type={TYPOGRAPHY_TYPES.p}>Rejected File:</Typography>
                    <ul>
                        <Typography type={TYPOGRAPHY_TYPES.p}>{fileRejectionItems}</Typography>
                    </ul>
                </StyledErrorMessageContainer>
            ) : (
                ''
            )}
        </>
    )
}

export default DropZone
