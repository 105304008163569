import { FC, useEffect, useState } from 'react'
import { UserState, clearUserDetails, getUserDetails } from 'redux/slices/user'
import { dispatch, useAppSelector } from 'redux/store'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { StyledNavBarContainer } from 'pages/hcp/members/information/NavBar/index.style'
import { ERoles } from 'models/enums/role'

import { ENavTab } from './navbar/config'
import NavBar from './navbar'
import PCPNPUser from './PCPNPUser'
import OfficeAdminUser from './OfficeAdminUser'
import ProviderReportPage from './reports'
import useTrackPageView from 'hooks/useTrackPageView'
import { pageTitle } from 'appInsights/appInsights'
import Spinner from 'components/Spinner'

interface IProps {
    userId: string
    tab?: ENavTab
    editable?: boolean
}

const tabPageTitles = {
    [ENavTab.GENERAL]: pageTitle.UserDetails,
    [ENavTab.REPORTS]: pageTitle.UserReports
}

const UserMain: FC<IProps> = ({ userId, tab, editable }) => {
    const [selectedTab, setSelectedTab] = useState<ENavTab>(tab == null ? ENavTab.GENERAL : tab)

    const [isUserDataLoaded, setUserDataLoaded] = useState<boolean>(false)

    useEffect(() => {
        dispatch(clearUserDetails())
        setUserDataLoaded(false)
    }, [])

    useEffect(() => {
        if (userId?.length > 0 && !isUserDataLoaded) {
            dispatch(getUserDetails({ userId: userId })).then(() => setUserDataLoaded(true))
        }
    }, [userId, isUserDataLoaded])

    const userData = useAppSelector((state: { user: UserState }) => {
        return state.user.details
    })

    const isPCP = userData?.role === ERoles.Provider || userData?.role === ERoles.Nurse
    const isOffiAdmin = userData?.role === ERoles.OfficeAdmin

    const getProviderNpi = (): string =>
        isPCP
            ? userData &&
              userData.taxIdProviderGroupings &&
              userData.taxIdProviderGroupings.length > 0 &&
              userData.taxIdProviderGroupings[0].providers &&
              userData.taxIdProviderGroupings[0].providers.length > 0 &&
              userData.taxIdProviderGroupings[0].providers[0]?.npi
            : null

    const getOfficeAdminUserId = (): string => (isOffiAdmin ? userData.id : null)

    const trackPageView = useTrackPageView()
    const handleSelectedTab = (selected: ENavTab) => {
        setSelectedTab(selected)
        if (tabPageTitles[selected]) {
            trackPageView(tabPageTitles[selected])
        }
    }
    const showTabContent = () => {
        switch (selectedTab) {
            case ENavTab.REPORTS: {
                return <ProviderReportPage providerNpi={getProviderNpi()} officeAdminUserId={getOfficeAdminUserId()} />
            }
            case ENavTab.GENERAL: {
                if (isPCP) {
                    return <PCPNPUser editable={editable ?? false} />
                } else if (isOffiAdmin) {
                    return <OfficeAdminUser editable={editable ?? false} />
                } else return <Typography type={TYPOGRAPHY_TYPES.p}>User Role Is Not PCP or Office Admin</Typography>
            }
        }
    }

    return (
        <>
            {!isUserDataLoaded && <Spinner />}
            {Object.keys(userData).length > 0 ? (
                <>
                    <StyledNavBarContainer>
                        <NavBar iniTab={tab} onSelect={handleSelectedTab} />
                    </StyledNavBarContainer>
                    {showTabContent()}
                </>
            ) : (
                <Typography type={TYPOGRAPHY_TYPES.p}>User Not Found</Typography>
            )}
        </>
    )
}
export default UserMain
