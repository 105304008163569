import CustomSvgIcon from 'components/CustomSvgIcon'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { useMemberOutreachListConfig } from 'hooks/useListConfig'
import { SortDirection } from 'models/sortColumn'
import { IMemberOutreachSummary } from 'pages/hcp/users/create/index.models'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { fetchMemberOutreachDetails, IncentiveState } from 'redux/slices/incentive'
import { useDispatch, useSelector } from 'redux/store'
import { appPath } from 'utilities/appPath'
import { getMemberOutreachSortColumn } from 'utilities/memberOutreachSortColumnMapper'
import { diagnosticsIcon, viewAllIcon } from './icons'
import {
    StyledMemberOutreachContainer,
    StyledMemberOutreachContentTable,
    StyledMemberOutreachContentTableItem,
    StyledMemberOutreachEmptyStateContainer,
    StyledMemberOutreachTableHeader,
    StyledMemberOutreachTableHeaderItem,
    StyledMemberOutreachTitleContainer,
    StyledMemberOutreachViewAllButton
} from './index.style'

interface IMemberOutreachWidgetProps {
    npi: string
}

const MemberOutreachWidget: FC<IMemberOutreachWidgetProps> = ({ npi }) => {
    const memberOutreachListConfig = useMemberOutreachListConfig()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const memberOutreachData: IMemberOutreachSummary[] = useSelector(
        (state: { incentive: IncentiveState }) => state.incentive?.memberOutreachSummaries
    )

    const navigateToMemberOutreach = () => {
        navigate(appPath('/memberOutreach'))
    }

    const handleViewAllMemberOutreach = () => {
        memberOutreachListConfig.clearFilter()
        navigateToMemberOutreach()
    }

    const handleSelectedMember = (item: IMemberOutreachSummary) => {
        const encloseNameInQuotesIfNeeded = (name: string) => (name.includes(' ') ? `"${name}"` : name)

        dispatch(
            fetchMemberOutreachDetails({
                npi: item.npi,
                memberId: item.membid,
                sortColumn: getMemberOutreachSortColumn(memberOutreachListConfig.sortColumn),
                isDescending: memberOutreachListConfig.sortDirection === SortDirection.descending
            })
        )
        memberOutreachListConfig.setFilter(
            `${encloseNameInQuotesIfNeeded(item.memberFirstName)} ${encloseNameInQuotesIfNeeded(item.memberLastName)} ${
                item.memberDateOfBirth
            }`
        )
        navigateToMemberOutreach()
    }

    return (
        <StyledMemberOutreachContainer>
            <StyledMemberOutreachTitleContainer>
                <Typography type={TYPOGRAPHY_TYPES.p}>Patient Outreach</Typography>
                {npi && (
                    <StyledMemberOutreachViewAllButton onClick={handleViewAllMemberOutreach}>
                        <CustomSvgIcon iconSet={{ icon: viewAllIcon }} svg></CustomSvgIcon>
                        View All
                    </StyledMemberOutreachViewAllButton>
                )}
            </StyledMemberOutreachTitleContainer>
            <StyledMemberOutreachTableHeader>
                <StyledMemberOutreachTableHeaderItem>
                    <Typography type={TYPOGRAPHY_TYPES.p}>Patient Name</Typography>
                </StyledMemberOutreachTableHeaderItem>
                <StyledMemberOutreachTableHeaderItem>
                    <Typography type={TYPOGRAPHY_TYPES.p}>Opportunities</Typography>
                </StyledMemberOutreachTableHeaderItem>
                <StyledMemberOutreachTableHeaderItem>
                    <Typography type={TYPOGRAPHY_TYPES.p}>Avail. Incentive</Typography>
                </StyledMemberOutreachTableHeaderItem>
            </StyledMemberOutreachTableHeader>
            {memberOutreachData.length === 0 ? (
                <StyledMemberOutreachEmptyStateContainer>
                    <CustomSvgIcon iconSet={{ icon: diagnosticsIcon }} svg />
                    <Typography type={TYPOGRAPHY_TYPES.h3}>Data not available at the moment</Typography>
                    <Typography type={TYPOGRAPHY_TYPES.p}>Please check back later</Typography>
                </StyledMemberOutreachEmptyStateContainer>
            ) : (
                memberOutreachData.map((item, idx: number) => {
                    return (
                        <StyledMemberOutreachContentTable
                            key={`memberOutreachItem-${idx}`}
                            onClick={() => handleSelectedMember(item)}
                        >
                            <StyledMemberOutreachContentTableItem>
                                <Typography type={TYPOGRAPHY_TYPES.p}>{item.memberName}</Typography>
                            </StyledMemberOutreachContentTableItem>
                            <StyledMemberOutreachContentTableItem>
                                <Typography type={TYPOGRAPHY_TYPES.p}>{item.pendingIncentives}</Typography>
                            </StyledMemberOutreachContentTableItem>
                            <StyledMemberOutreachContentTableItem>
                                <Typography type={TYPOGRAPHY_TYPES.p}>${item.incentiveRemaining}</Typography>
                            </StyledMemberOutreachContentTableItem>
                        </StyledMemberOutreachContentTable>
                    )
                })
            )}
        </StyledMemberOutreachContainer>
    )
}

export default MemberOutreachWidget
