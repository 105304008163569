import { FC, useState } from 'react'
import Modal from 'components/Modal'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import {
    StyledButtonContainer,
    StyledCancelButton,
    StyledDescriptionWrap,
    StyledFieldSet,
    StyledModalContainer,
    StyledSpinnerWrap,
    StyledUploadButton
} from './index.style'
import DropZone from 'components/DropZone'
import Spinner from 'components/Spinner'

interface IUploadFileModalProps {
    title: string
    isUploading: boolean
    onUpload: (file: any) => void
    onCancel: () => void
}

const UploadFileModal: FC<IUploadFileModalProps> = ({ title, isUploading, onUpload, onCancel }) => {
    const [file, setFile] = useState(null)
    const [validFile, setValidFile] = useState()

    const handleDeleteFile = () => {
        setFile(null)
        setValidFile(null)
    }

    const handleUpload = () => {
        onUpload(file[0])
    }

    const handleCancel = () => {
        if (!isUploading) {
            onCancel()
        }
    }

    return (
        <Modal onClose={handleCancel} minWidth={650} minHeight={550}>
            <StyledModalContainer>
                <Typography type={TYPOGRAPHY_TYPES.h2}>{title}</Typography>
                <StyledDescriptionWrap>
                    <Typography type={TYPOGRAPHY_TYPES.p}>The following are acceptable file extensions:</Typography>
                    <Typography type={TYPOGRAPHY_TYPES.span}>.pdf</Typography>
                </StyledDescriptionWrap>
                <StyledFieldSet disabled={isUploading}>
                    <DropZone
                        selectedFile={setFile}
                        acceptableFile={setValidFile}
                        isDocumentsTab={true}
                        deleteFile={handleDeleteFile}
                        disabled={isUploading}
                    />
                    <StyledButtonContainer>
                        <StyledCancelButton onClick={handleCancel} disabled={isUploading}>
                            Cancel
                        </StyledCancelButton>
                        {isUploading ? (
                            <StyledSpinnerWrap>
                                <Spinner />
                            </StyledSpinnerWrap>
                        ) : (
                            <StyledUploadButton disabled={!validFile} onClick={handleUpload}>
                                Upload
                            </StyledUploadButton>
                        )}
                    </StyledButtonContainer>
                </StyledFieldSet>
            </StyledModalContainer>
        </Modal>
    )
}

export default UploadFileModal
