import useDebounce from 'hooks/useDebounce'

import { inputLabels } from 'messages/inputLabels'
import {
    FullLocationIndexes,
    ILocationData,
    ILocationDetailData,
    IOfficeAdminProviderInfo,
    IProviderData,
    ITaxIdNpiData,
    ITaxProviderInfo,
} from 'pages/hcp/users/create/index.models'

import React, { useState, useEffect } from 'react'
import { getLocationsFromNpi, getNpisFromTaxId } from 'redux/slices/user'
import { dispatch, useSelector } from '../../redux/store'
import { StyledAddUserLink, StyledMainContainer, StyledProviderInfoWrap, StyledTxtfieldWrap, TaxIdHalfHorizontalLine, TaxIdHorizontalLine, TaxIdVerticalLine
    } 
     from './index.style'

import { isValid } from 'tin-validator'

import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js'
import { validateTaxId } from 'utilities/validationUtilities'
import { TranslateToClientLocation } from 'utilities/addressUtilities'
import { ProviderList } from './ProviderList'
import { handleAddingInitialLocation } from './common'
import MaskedTextField from 'components/MaskedTextField'

interface IOfficeAdminProps {
    userId: string | undefined | null
    currentProviderInfo: IOfficeAdminProviderInfo
    isReadOnly: boolean | undefined
    setProviderInfo: (providerInfo: IOfficeAdminProviderInfo) => void
    validFormCallback: any
}


// const initialProviderInfo: IOfficeAdminProviderInfo = { taxIdProviderGroupings: [] }

const TaxProviderInfo: React.FC<IOfficeAdminProps> = ({
    userId,
    currentProviderInfo,
    isReadOnly,
    setProviderInfo,
    validFormCallback
}: IOfficeAdminProps) => {
    const appInsights = useAppInsightsContext() // set up app insights
    
    // ****  States ****
    const taxInfo: ITaxProviderInfo = useSelector((state: { user: any }) => state.user.taxInfo)

    const npiInfo = useSelector((state: { user: any }) => state.user.npiInfo)

    const [taxIdUpdated, setTaxIdUpdated] = useState(false)

    const [npiUpdated, SetNpiUpdated] = useState(false)

    const [dictionaryOfNpis] = useState(new Map<string, IProviderData[]>())

    const [currentNpi, setCurrentNpi] = useState('-1')

    const [currentTaxId, setCurrentTaxId] = useState('-1')

    const [currentTaxIdIndex, setCurrentTaxIdIndex] = useState<number>(0)

    const [addressModalIsVisible, setAddressModalIsVisible] = useState(false)

    const [userDetailsHasData, setUserDetailsHasData] = useState(false)

    const [dictionaryOfLocations] = useState<Map<string, ILocationDetailData[]>>(new Map())

    const [newLocationAdded, setNewLocationAdded] = useState<FullLocationIndexes>({
        taxIdIndex: 0,
        npiIndex: 0,
        locationIndex: 0
    })

    // **********************End Use States**************************

    // *************** Use Effects *******************************

   const debouncedTaxId = useDebounce(currentTaxId, 500)


    useEffect(() => {
        if (currentProviderInfo?.taxIdProviderGroupings?.length === 0) {
            handleAddingNewTaxId()
        }
    })

    useEffect(() => {
        const allPromises: any[] = []

        currentProviderInfo.taxIdProviderGroupings.forEach((grouping) => {
            const taxIdPromise = dispatch(getNpisFromTaxId({ taxId: formatTaxId(grouping.taxId) }))
            allPromises.push(taxIdPromise)
            grouping.providers.forEach((provider) => {
                const npiPromise = dispatch(getLocationsFromNpi({ npi: provider.npi, userId: userId }))
                allPromises.push(npiPromise)
            })

            Promise.all(allPromises).then(() => handleLocationsAfterUpdate())
        })

        // }
    }, [userId])

    useEffect(() => {
        const rawDebouncedTaxId = debouncedTaxId.replace('-', '').replaceAll('_', '')
        if (!rawDebouncedTaxId || rawDebouncedTaxId.length === 0) {
            clearOutNpiAndLocationsForCurrentTaxId()
            return
        }

        if (rawDebouncedTaxId.length >= TaxIdLength) {
            dispatch(getNpisFromTaxId({ taxId: debouncedTaxId }))
        }
    }, [debouncedTaxId])

    useEffect(() => {
        if (
            currentProviderInfo.taxIdProviderGroupings.length === 0 ||
            currentProviderInfo.taxIdProviderGroupings[newLocationAdded.taxIdIndex].providers.length === 0
        )
            return
        const targetedNpi =
            currentProviderInfo.taxIdProviderGroupings[newLocationAdded.taxIdIndex].providers[newLocationAdded.npiIndex]
                .npi
        if (!dictionaryOfLocations.has(targetedNpi)) {
            dispatch(getLocationsFromNpi({ npi: targetedNpi, userId: userId }))
        }
        setCurrentNpi(targetedNpi)
    }, [newLocationAdded])


    useEffect(() => {
        const taxIdFromServer: string = taxInfo.taxId

        // check to make sure the current tax id is not empty
        if (
            currentTaxIdIndex !== -1 &&
            currentProviderInfo?.taxIdProviderGroupings[currentTaxIdIndex]?.taxId?.length === 0
        ) {
            return
        }

        if (taxIdFromServer != null && taxIdFromServer.length < TaxIdLength) return

        const taxId = taxIdFromServer.replace('-', '').replaceAll('_', '')

        if (taxId != null && taxId !== '' && taxId !== '-1') {
            dictionaryOfNpis.set(taxId, taxInfo.providers)

            // reset the npi for the current tax id
            setCurrentNpi('')

            const newdata = currentProviderInfo.taxIdProviderGroupings.map((grouping) => {
                if (grouping.taxId === taxId && isValid(taxId) && taxInfo.providers.length === 0) {
                    return {
                        ...grouping,
                        taxId: taxId,
                        error: true,
                        errorMessage: 'Invalid Tax Id',
                        providers: [{ npi: '', locations: [{}] }]
                    }
                } else {
                    return grouping
                }
            })

            setProviderInfo({ taxIdProviderGroupings: newdata } as IOfficeAdminProviderInfo)
        }

        setTaxIdUpdated(!taxIdUpdated) // force a rerender after dictionary is populated
    }, [dictionaryOfNpis, taxInfo.providers, debouncedTaxId, taxInfo.taxId])

    useEffect(() => {
        const npi: string = npiInfo?.npi
        if (npi != null && npi !== '' && npi !== '-1') {
            if (dictionaryOfLocations.has(`${npi}`) === false) {
                dictionaryOfLocations.set(`${npi}`, npiInfo?.locations)
            }
        }

        SetNpiUpdated(!npiUpdated) // force a rerender after dictionary is populated
    }, [dictionaryOfLocations, npiInfo?.npi, currentNpi, npiInfo?.locations])    


    // ***************************End Use Effects*********************************

    
    function testLocationMatch(testLocation: ILocationData, sourceLocation: ILocationDetailData) {
        return (
            testLocation.address === sourceLocation.address1 &&
            testLocation.city === sourceLocation.city &&
            testLocation.suite === sourceLocation.address2 &&
            testLocation.state === sourceLocation.state
        )
    }

    function findLocationIndex(possibleLocations: ILocationDetailData[], testLocation: ILocationData) {
        if (possibleLocations == null) return -1

        return possibleLocations.findIndex((location) => testLocationMatch(testLocation, location))
    }

   

    const TaxIdLength: number = 9

    const handleTaxIdBlur = (e: React.FocusEvent<HTMLInputElement, Element>, taxIdIndex: number): void => {
        const taxId = e.target.value.replaceAll('_', '').replace('-', '')
        const newData = validateTaxId(taxId, taxIdIndex, currentProviderInfo)
        setProviderInfo(newData)
    }

    const handleTaxIdInput = (e: any, index: number) => {
        let enteredTaxId = e.target.value

        const strippedTaxId = enteredTaxId.replace('-', '').replaceAll('_', '')

        if (strippedTaxId?.length < TaxIdLength || strippedTaxId == null || strippedTaxId.length === 0) {
            currentProviderInfo.taxIdProviderGroupings[index].taxId = strippedTaxId

            if (strippedTaxId.length === 0) {
                setCurrentTaxId(strippedTaxId)
                setCurrentTaxIdIndex(index)
            }
            return
        }

        const isValidTaxId = isValid(strippedTaxId)

        const foundOtherTaxId = currentProviderInfo.taxIdProviderGroupings.findIndex(
            (val, indexTest) =>
                indexTest !== index &&
                val.taxId !== null &&
                val.taxId.replace('-', '') == strippedTaxId &&
                val.taxId.trim() != ''
        )
        if (foundOtherTaxId >= 0) {
            const newData = currentProviderInfo.taxIdProviderGroupings.map((obj, idx) => {
                if (index === idx)
                    // check if the array is the current changed index
                    return {
                        ...obj,
                        taxId: strippedTaxId,
                        error: foundOtherTaxId !== -1,
                        errorMessage: 'Enter Unique tax id'
                    }
                return obj
            })

            setProviderInfo({ taxIdProviderGroupings: newData })
            setCurrentTaxId(strippedTaxId) // update to force fetch of npis from server
            setCurrentTaxIdIndex(index)
        } else {
            const newData = currentProviderInfo.taxIdProviderGroupings.map((obj, idx) => {
                if (index === idx)
                    // check if the array is the current changed index
                    return {
                        ...obj,
                        taxId: strippedTaxId,
                        error: !isValidTaxId,
                        errorMessage: 'Enter Valid Tax Id'
                    }
                return obj
            })

            setProviderInfo({ taxIdProviderGroupings: newData })
            setCurrentTaxId(strippedTaxId) // update to force fetch of npis from server
            setCurrentTaxIdIndex(index)
        }
    }


    const handleAddingNewTaxId = () => {
        let temp = { ...currentProviderInfo }
        validFormCallback(false)
        const taxIdList: ITaxIdNpiData[] = [
            ...temp.taxIdProviderGroupings,
            {
                taxId: '',
                error: false,
                errorMessage: '',
                providers: [
                    {
                        // ezCapLocations : [],
                        error: false,
                        errorMessage: '',
                        npi: '',
                        locations: [                           
                        ]
                    }
                ]
            }
        ]
        temp.taxIdProviderGroupings = taxIdList

        const newTaxGrouping = taxIdList[temp.taxIdProviderGroupings.length - 1]
        handleAddingInitialLocation(newTaxGrouping.providers, 0)

        setProviderInfo(temp)
    }

    const handleAddingNewTaxIdAtIndex = (index: number) => {
        let temp = { ...currentProviderInfo }
        validFormCallback(false)

        const taxIdList: ITaxIdNpiData[] = temp.taxIdProviderGroupings.map((taxId, i) => {
            if (i === index) {
                return {
                    taxId: '',
                    error: false,
                    errorMessage: '',
                    providers: [
                        {
                            // ezCapLocations : [],
                            error: false,
                            errorMessage: '',
                            npi: '',
                            locations: [                                
                            ]
                        }
                    ]
                }
            }
            return taxId
        })

        const newTaxGrouping = taxIdList[index]

        handleAddingInitialLocation(newTaxGrouping.providers, 0)

        temp.taxIdProviderGroupings = taxIdList

        setProviderInfo(temp)
    }


    const formatTaxId = (taxId: string) => {
        if (!taxId || (taxId.length > 9 && taxId.indexOf('-') >= 0)) return taxId // already formatted
        const formattedTaxId = taxId.substring(0, 2) + '-' + taxId.substring(2)
        return formattedTaxId
    }


    const handleLocationsAfterUpdate = () => {
        let firstNpi = ''
        setTimeout(() => {}, 1000)
        const temp = { ...currentProviderInfo }
        // populate location selection indeces
        temp.taxIdProviderGroupings.forEach((grouping) => {
            if (grouping.providers.length > 0) {
                firstNpi = grouping.providers[0].npi
            }
            grouping.providers.forEach((provider) => {
                const npi = provider.npi
                provider.locations.forEach((location, locationIndex) => {
                    const mappedLocations = dictionaryOfLocations.get(`${npi}`)
                    location.selectedLocationIndex = findLocationIndex(mappedLocations, location)
                    if (location.selectedLocationIndex === -1) {
                        // location is not in ezcap, add it to the dictionary
                        const clientLocation = TranslateToClientLocation(location)
                        if (mappedLocations) {
                            const mappedLocationsMore = [...mappedLocations, clientLocation]
                            location.selectedLocationIndex = mappedLocationsMore.length - 1
                            dictionaryOfLocations.set(`${npi}`, mappedLocationsMore)
                        }
                    }
                })
            })
        })

        setProviderInfo(temp)
        setCurrentNpi(firstNpi)
    }

    const clearOutNpiAndLocationsForCurrentTaxId = () => {
        dictionaryOfNpis.delete(currentProviderInfo.taxIdProviderGroupings[currentTaxIdIndex].taxId)
        currentProviderInfo.taxIdProviderGroupings[currentTaxIdIndex].providers.forEach((provider) =>
            dictionaryOfLocations.delete(provider.npi)
        )

        handleAddingNewTaxIdAtIndex(currentTaxIdIndex)
    }

    return (
        <>
            {currentProviderInfo.taxIdProviderGroupings?.map((providerInfo: ITaxIdNpiData, taxIdIndex: number) => {
                const taxId = providerInfo.taxId
                return (
                    <StyledMainContainer>
                        <StyledProviderInfoWrap key={`taxid_wrap_${taxIdIndex}`}>
                                <StyledTxtfieldWrap key={`taxid_text_field_wrap1_${taxIdIndex}`}>
                                    <MaskedTextField key = {`taxid_text_field_11_${taxIdIndex}`}    
                                        
                                        value={currentProviderInfo.taxIdProviderGroupings[taxIdIndex].taxId}
                                        placeholder={inputLabels.TIN_PlaceHolder}
                                        inputType={'text'}
                                        onBlur={(e) => handleTaxIdBlur(e, taxIdIndex)}
                                        onTextChanged={(e) => handleTaxIdInput(e, taxIdIndex)}
                                        error={currentProviderInfo.taxIdProviderGroupings[taxIdIndex].error}
                                        errorMessage={currentProviderInfo.taxIdProviderGroupings[taxIdIndex].errorMessage}
                                        mask={'99-9999999'}
                                    />
                                </StyledTxtfieldWrap>
                                
                                 <TaxIdHorizontalLine /> 
                                 

                                <article key={`article_provider_list_${taxId}_${taxIdIndex}`} style={{height: '200px !important'}}>
                                    <ProviderList key = {`provider_list_1${taxId}_${taxIdIndex}`}
                                        userId={userId}
                                        providerInfo={providerInfo}
                                        taxIdIndex={taxIdIndex}
                                        taxId={taxId}
                                        currentProviderInfo={currentProviderInfo}
                                        setProviderInfo={setProviderInfo}
                                        dictionaryOfNpis={dictionaryOfNpis}
                                        dictionaryOfLocations={dictionaryOfLocations}
                                        isReadOnly={isReadOnly}
                                        currentTaxIdIndex={currentTaxIdIndex}
                                        validFormCallback={validFormCallback}
                                        handleTaxIdInput={(e: any, index: number) => handleTaxIdInput(e, index)}
                                        handleTaxIdBlur={(e: any, index: number) => handleTaxIdBlur(e, index)} 
                                        setNewLocationAdded={setNewLocationAdded}
                                    />
                                </article>
                            </StyledProviderInfoWrap>
                            
                    </StyledMainContainer>
                )

            }
            
)}

<StyledAddUserLink onClick={() => {handleAddingNewTaxId()}}>
+ Add a new Billing Provider Tax ID
</StyledAddUserLink>  
        </>
        
    )
}

export default TaxProviderInfo
