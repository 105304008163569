import Modal from 'components/Modal'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC } from 'react'
import { StyledButtonContainer, StyledCloseButton, StyledTextArea } from './index.style'

interface IErrorBoxProps {
    error: any
    onClose: () => void
}

const ErrorBox: FC<IErrorBoxProps> = ({ error, onClose }) => {
    return (
        <Modal onClose={onClose} minHeight={450} minWidth={750}>
            <Typography type={TYPOGRAPHY_TYPES.h2}>Error</Typography>
            <Typography type={TYPOGRAPHY_TYPES.p}>The following error has occurred in the application:</Typography>
            <StyledTextArea value={error} readOnly={true} />
            <Typography type={TYPOGRAPHY_TYPES.p} style={{ fontSize: '14px', fontWeight: 400 }}>
                If the problem persists, please contact HCP Technical Support at (800) 877-7587.
            </Typography>
            <StyledButtonContainer>
                <StyledCloseButton onClick={onClose}>Close</StyledCloseButton>
            </StyledButtonContainer>
        </Modal>
    )
}

export default ErrorBox
