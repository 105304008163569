import { FC, useEffect } from 'react'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { IDiagnosisHeading, ISectionElement } from '../index.models'
import { StyledDiagnosisHeadingContainer } from './index.style'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { DiagnosisState, getIcdDiagnosisHeading } from 'redux/slices/diagnosis'
import { dispatch } from 'redux/store'

interface IDiagnosisHeadingProps {
    diagnosisCodeFieldName: string
    defaultHeading: string
    parentName: string
}

const DiagnosisHeading: FC<IDiagnosisHeadingProps> = ({ diagnosisCodeFieldName, defaultHeading, parentName }) => {
    const { watch } = useFormContext()

    const diagnosisCode = watch(
        parentName ? diagnosisCodeFieldName.replace('${parent}', parentName) : diagnosisCodeFieldName
    )

    const { diagnosisHeadingsByCode } = useSelector((state: { diagnosis: DiagnosisState }) => state.diagnosis)

    useEffect(() => {
        if (diagnosisCode && !diagnosisHeadingsByCode[diagnosisCode]) {
            dispatch(getIcdDiagnosisHeading({ diagCode: diagnosisCode }))
        }
    }, [diagnosisCode, diagnosisHeadingsByCode])

    return (
        <StyledDiagnosisHeadingContainer>
            <Typography type={TYPOGRAPHY_TYPES.h3}>
                {diagnosisCode ? diagnosisHeadingsByCode[diagnosisCode] ?? diagnosisCode : defaultHeading}
            </Typography>
        </StyledDiagnosisHeadingContainer>
    )
}

export const renderDiagnosisHeading = (element: ISectionElement, key: string, _: any, parentName: string) => {
    const diagnosisHeading = element as IDiagnosisHeading
    return <DiagnosisHeading key={key} parentName={parentName} {...diagnosisHeading} />
}
