import { FC, useEffect } from 'react'
import Modal from 'components/Modal'
import {
    StyledSupportingDocsButtonContainer,
    StyledSupportingDocsCloseButton,
    StyledSupportingDocsContainer,
    StyledSupportingDocsContainerWrap,
    StyledSupportingDocsTableBody,
    StyledSupportingDocsTableBodyItem,
    StyledSupportingDocsTableHeader,
    StyledSupportingDocsTableHeaderItem
} from './index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { useDispatch, useSelector } from 'redux/store'
import { DocumentsState, downloadAwvSupportingDocument, fetchSupportingDocuments } from 'redux/slices/documents'
import { SupportingDocs } from 'pages/hcp/members/information/DocumentsTab/models'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { closeIcon } from './icons'
import { setFormattedDateWithSlash } from 'components/UsersTable/index.utils'
import usePostCustomEvent from 'hooks/usePostCustomEvent'
import { pageTitle, customEvents } from 'appInsights/appInsights'

interface ISupportingDocsModalProps {
    onCancel: () => void
    request: SupportingDocsRequest
}

export type SupportingDocsRequest = {
    memberId: number
    npi: string
    year: number
}

export type DownloadSupportingDocsRequest = {
    awvSupportingDocumentId: string
}

const SupportingDocsModal: FC<ISupportingDocsModalProps> = ({ onCancel, request }) => {
    const dispatch = useDispatch()
    const supportingDocsList = useSelector((state: { documents: DocumentsState }) => state.documents.supportingDocs)

    useEffect(() => {
        dispatch(fetchSupportingDocuments(request))
    }, [])

    const downloadAWVSupportingDocEvent = usePostCustomEvent(
        pageTitle.SubmittedAWVForms,
        customEvents.DownloadAWVSupportingDocument
    )
    const handleSupportingDocsDownload = (docId: string) => {
        downloadAWVSupportingDocEvent()
        dispatch(
            downloadAwvSupportingDocument({
                awvSupportingDocumentId: docId
            })
        )
    }

    return (
        <Modal onClose={onCancel} minWidth={650} minHeight={300}>
            <StyledSupportingDocsContainerWrap>
                <StyledSupportingDocsButtonContainer>
                    <StyledSupportingDocsCloseButton onClick={onCancel}>
                        <CustomSvgIcon iconSet={{ icon: closeIcon }} svg></CustomSvgIcon>
                    </StyledSupportingDocsCloseButton>
                </StyledSupportingDocsButtonContainer>
                <Typography type={TYPOGRAPHY_TYPES.h2}>Encounter/Supporting Documents</Typography>
                <StyledSupportingDocsContainer>
                    <StyledSupportingDocsTableHeader>
                        <StyledSupportingDocsTableHeaderItem>
                            <Typography type={TYPOGRAPHY_TYPES.p}>File Name</Typography>
                        </StyledSupportingDocsTableHeaderItem>
                        <StyledSupportingDocsTableHeaderItem>
                            <Typography type={TYPOGRAPHY_TYPES.p}>Uploaded By</Typography>
                        </StyledSupportingDocsTableHeaderItem>
                        <StyledSupportingDocsTableHeaderItem>
                            <Typography type={TYPOGRAPHY_TYPES.p}>Uploaded On</Typography>
                        </StyledSupportingDocsTableHeaderItem>
                    </StyledSupportingDocsTableHeader>
                    {supportingDocsList?.awvSupportingDocuments?.map((doc: SupportingDocs, idx: number) => {
                        return (
                            <StyledSupportingDocsTableBody key={`supporting-doc-${idx}`}>
                                <StyledSupportingDocsTableBodyItem onClick={() => handleSupportingDocsDownload(doc.id)}>
                                    <Typography type={TYPOGRAPHY_TYPES.p}>{doc.fileName}</Typography>
                                </StyledSupportingDocsTableBodyItem>
                                <StyledSupportingDocsTableBodyItem>
                                    <Typography type={TYPOGRAPHY_TYPES.span}>{doc.uploadedBy}</Typography>
                                </StyledSupportingDocsTableBodyItem>
                                <StyledSupportingDocsTableBodyItem>
                                    <Typography type={TYPOGRAPHY_TYPES.span}>
                                        {setFormattedDateWithSlash(doc.uploadedOn)}
                                    </Typography>
                                </StyledSupportingDocsTableBodyItem>
                            </StyledSupportingDocsTableBody>
                        )
                    })}
                </StyledSupportingDocsContainer>
            </StyledSupportingDocsContainerWrap>
        </Modal>
    )
}

export default SupportingDocsModal
