import { IGapInCareDocument } from 'pages/hcp/users/create/index.models'
import { FC } from 'react'
import { StyledButton, StyledDocumentList, StyledDocumentListItem, StyledUploadButton } from './index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { setFormattedDateTimeWithSlash } from 'components/UsersTable/index.utils'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { deleteIcon, disabledDeleteIcon, disabledUploadIcon, uploadIcon } from './icons'

interface IDocumentListProps {
    documents: IGapInCareDocument[]
    isReadOnly: boolean
    isClosed: boolean
    isBusy: boolean
    onDownload: (document: IGapInCareDocument) => void
    onDelete?: (document: IGapInCareDocument) => void
    onUpload?: () => void
}

const DocumentList: FC<IDocumentListProps> = ({
    documents,
    isReadOnly,
    isClosed,
    isBusy,
    onDownload,
    onDelete,
    onUpload
}) => {
    const isClosedOrBusy = isClosed || isBusy

    const handleDownload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, document: IGapInCareDocument) => {
        e.preventDefault()
        onDownload(document)
    }

    const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, document: IGapInCareDocument) => {
        e.preventDefault()
        onDelete(document)
    }

    const handleUpload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        onUpload()
    }

    return (
        <StyledDocumentList>
            {documents.map((document: IGapInCareDocument) => (
                <StyledDocumentListItem key={`document-${document.id}`}>
                    <span>
                        <button
                            onClick={(e) => handleDownload(e, document)}
                            disabled={isBusy}
                            title={isBusy ? '' : `Uploaded on ${setFormattedDateTimeWithSlash(document.uploadedOn)}`}
                            style={{ margin: isReadOnly ? '8px 12px 8px 0px' : '0 12px 0 0' }}
                        >
                            {document.fileName}
                        </button>
                    </span>
                    {!isReadOnly && (
                        <span>
                            <StyledButton
                                onClick={(e) => handleDelete(e, document)}
                                disabled={isClosedOrBusy}
                                title={isClosedOrBusy ? '' : 'Delete'}
                            >
                                <CustomSvgIcon
                                    iconSet={{ icon: isClosedOrBusy ? disabledDeleteIcon : deleteIcon }}
                                    svg
                                ></CustomSvgIcon>
                            </StyledButton>
                        </span>
                    )}
                </StyledDocumentListItem>
            ))}
            {!isReadOnly && (
                <StyledUploadButton
                    onClick={(e) => handleUpload(e)}
                    disabled={isClosedOrBusy}
                    title={isClosedOrBusy ? '' : 'Upload'}
                >
                    <CustomSvgIcon
                        iconSet={{ icon: isClosedOrBusy ? disabledUploadIcon : uploadIcon }}
                        svg
                    ></CustomSvgIcon>
                    <Typography type={TYPOGRAPHY_TYPES.p}>Upload PDF</Typography>
                </StyledUploadButton>
            )}
        </StyledDocumentList>
    )
}

export default DocumentList
