import { useState } from 'react';
export enum UsaStates {
    UNKNOWN = 'State',
    AL = 'Alabama',
    AK = 'Alaska',
    AZ = 'Arizona',
    AR = 'Arkansas',
    CA = 'California',
    CO = 'Colorado',
    CT = 'Connecticut',
    DE = 'Delaware',
    FL = 'Florida',
    GA = 'Georgia',
    HI = 'Hawaii',
    ID = 'Idaho',
    IL = 'Illinois',
    IN = 'Indiana',
    IA = 'Iowa',
    KS = 'Kansas',
    KY = 'Kentucky',
    LA = 'Louisiana',
    ME = 'Maine',
    MD = 'Maryland',
    MA = 'Massachusetts',
    MI = 'Michigan',
    MN = 'Minnesota',
    MS = 'Mississippi',
    MO = 'Missouri',
    MT = 'Montana',
    NE = 'Nebraska',
    NV = 'Nevada',
    NH = 'New Hampshire',
    NJ = 'New Jersey',
    NM = 'New Mexico',
    NY = 'New York',
    NC = 'North Carolina',
    ND = 'North Dakota',
    OH = 'Ohio',
    OK = 'Oklahoma',
    OR = 'Oregon',
    PA = 'Pennsylvania',
    RI = 'Rhode Island',
    SC = 'South Carolina',
    SD = 'South Dakota',
    TN = 'Tennessee',
    TX = 'Texas',
    UT = 'Utah',
    VT = 'Vermont',
    VA = 'Virginia',
    WA = 'Washington',
    WV = 'West Virginia',
    WI = 'Wisconsin',
    WY = 'Wyoming'
}

// enum of usa states keyed by state name
export enum UsaStatesByName {
    State = 'UNKNOWN',
    Alabama = 'AL',
    Alaska = 'AK',
    Arizona = 'AZ',
    Arkansas = 'AR',
    California = 'CA',
    Colorado = 'CO',
    Connecticut = 'CT',
    Delaware = 'DE',
    Florida = 'FL',
    Georgia = 'GA',
    Hawaii = 'HI',
    Idaho     = 'ID',
    Illinois  = 'IL',
    Indiana   = 'IN',
    Iowa      = 'IA',
    Kansas    = 'KS',
    Kentucky  = 'KY',
    Louisiana = 'LA',
    Maine     = 'ME',
    Maryland  = 'MD',
    Massachusetts = 'MA',
    Michigan  = 'MI',
    Minnesota = 'MN',
    Mississippi = 'MS',
    Missouri  = 'MO',
    Montana   = 'MT',
    Nebraska  = 'NE',
    Nevada    = 'NV',
    NewHampshire = 'NH',
    NewJersey = 'NJ',
    NewMexico = 'NM',
    NewYork   = 'NY',
    NorthCarolina = 'NC',
    NorthDakota = 'ND',
    Ohio      = 'OH',
    Oklahoma  = 'OK',
    Oregon    = 'OR',
    Pennsylvania = 'PA',
    RhodeIsland = 'RI',
    SouthCarolina = 'SC',
    SouthDakota = 'SD',
    Tennessee = 'TN',
    Texas     = 'TX',
    Utah      = 'UT',
    Vermont   = 'VT',
    Virginia  = 'VA',
    Washington = 'WA',
    WestVirginia = 'WV',
    Wisconsin = 'WI',
    Wyoming   = 'WY'

}

        
   

export const getUsaStateAbbreviationByName = (fullStateName: string) => {

    // remove spaces in fullStateName
    const fullStateNameWithoutSpaces = fullStateName.replace(/\s/g, '');
    // test if fullStateName is a valid state name
    if (UsaStatesByName[fullStateNameWithoutSpaces as keyof typeof UsaStatesByName] === undefined) {
        return fullStateName;
    }

     return  UsaStatesByName[fullStateNameWithoutSpaces  as  keyof typeof UsaStatesByName];
}
