export const emptyFeedback = `<svg width="110" height="112" viewBox="0 0 110 112" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M46.8677 86.7283C61.5464 86.7283 73.4458 74.8288 73.4458 60.1501C73.4458 45.4714 61.5464 33.572 46.8677 33.572C32.189 33.572 20.2896 45.4714 20.2896 60.1501C20.2896 74.8288 32.189 86.7283 46.8677 86.7283Z" fill="#F6F9FD"/>
<path d="M48.8364 80.625H15.3677C14.1864 80.625 13.3989 79.8375 13.3989 78.6562C13.3989 77.475 14.1864 76.6875 15.3677 76.6875H48.8364C50.0177 76.6875 50.8052 77.475 50.8052 78.6562C50.8052 79.8375 50.0177 80.625 48.8364 80.625Z" fill="#A4B0C0"/>
<path d="M95.771 36.0186H62.3022C61.121 36.0186 60.3335 35.2311 60.3335 34.0498C60.3335 32.8686 61.121 32.0811 62.3022 32.0811H95.771C96.9523 32.0811 97.7397 32.8686 97.7397 34.0498C97.7397 35.2311 96.9523 36.0186 95.771 36.0186Z" fill="#A4B0C0"/>
<path d="M38.9927 60.5439H15.3677C14.1864 60.5439 13.3989 59.7564 13.3989 58.5752C13.3989 57.3939 14.1864 56.6064 15.3677 56.6064H38.9927C40.1739 56.6064 40.9614 57.3939 40.9614 58.5752C40.9614 59.7564 40.1739 60.5439 38.9927 60.5439Z" fill="#A4B0C0"/>
<path d="M85.9273 15.9375H62.3022C61.121 15.9375 60.3335 15.15 60.3335 13.9688C60.3335 12.7875 61.121 12 62.3022 12H85.9273C87.1085 12 87.896 12.7875 87.896 13.9688C87.896 15.15 87.1085 15.9375 85.9273 15.9375Z" fill="#A4B0C0"/>
<path d="M22.8489 70.5845H15.3677C14.1864 70.5845 13.3989 69.797 13.3989 68.6157C13.3989 67.4345 14.1864 66.647 15.3677 66.647H22.8489C24.0302 66.647 24.8177 67.4345 24.8177 68.6157C24.8177 69.797 24.0302 70.5845 22.8489 70.5845Z" fill="#A4B0C0"/>
<path d="M69.7835 25.978H62.3022C61.121 25.978 60.3335 25.1905 60.3335 24.0093C60.3335 22.828 61.121 22.0405 62.3022 22.0405H69.7835C70.9647 22.0405 71.7522 22.828 71.7522 24.0093C71.7522 25.1905 70.9647 25.978 69.7835 25.978Z" fill="#A4B0C0"/>
<path d="M48.8364 70.5845H31.1177C29.9364 70.5845 29.1489 69.797 29.1489 68.6157C29.1489 67.4345 29.9364 66.647 31.1177 66.647H48.8364C50.0177 66.647 50.8052 67.4345 50.8052 68.6157C50.8052 69.797 50.0177 70.5845 48.8364 70.5845Z" fill="#A4B0C0"/>
<path d="M95.771 25.978H78.0522C76.871 25.978 76.0835 25.1905 76.0835 24.0093C76.0835 22.828 76.871 22.0405 78.0522 22.0405H95.771C96.9522 22.0405 97.7397 22.828 97.7397 24.0093C97.7397 25.1905 96.9522 25.978 95.771 25.978Z" fill="#A4B0C0"/>
<path d="M22.8489 90.6655H15.3677C14.1864 90.6655 13.3989 89.878 13.3989 88.6968C13.3989 87.5155 14.1864 86.728 15.3677 86.728H22.8489C24.0302 86.728 24.8177 87.5155 24.8177 88.6968C24.8177 89.878 24.0302 90.6655 22.8489 90.6655Z" fill="#A4B0C0"/>
<path d="M69.7835 46.0591H62.3022C61.121 46.0591 60.3335 45.2716 60.3335 44.0903C60.3335 42.9091 61.121 42.1216 62.3022 42.1216H69.7835C70.9647 42.1216 71.7522 42.9091 71.7522 44.0903C71.7522 45.2716 70.9647 46.0591 69.7835 46.0591Z" fill="#A4B0C0"/>
<path d="M48.8364 90.6655H31.1177C29.9364 90.6655 29.1489 89.878 29.1489 88.6968C29.1489 87.5155 29.9364 86.728 31.1177 86.728H48.8364C50.0177 86.728 50.8052 87.5155 50.8052 88.6968C50.8052 89.878 50.0177 90.6655 48.8364 90.6655Z" fill="#A4B0C0"/>
<path d="M95.771 46.0591H78.0522C76.871 46.0591 76.0835 45.2716 76.0835 44.0903C76.0835 42.9091 76.871 42.1216 78.0522 42.1216H95.771C96.9522 42.1216 97.7397 42.9091 97.7397 44.0903C97.7397 45.2716 96.9522 46.0591 95.771 46.0591Z" fill="#A4B0C0"/>
<path d="M86.4396 68.4188C86.0459 68.4188 85.4553 68.222 85.2584 68.0251L73.6428 58.5751H54.1521C51.199 58.5751 48.8365 56.2126 48.6396 53.2595V6.00947C48.6396 3.05635 51.0021 0.693848 54.1521 0.693848H104.552C107.505 0.693848 109.868 3.05635 109.868 6.00947V53.2595C109.868 56.2126 107.505 58.5751 104.552 58.5751H88.4084V66.4501C88.4084 67.2376 88.0147 67.8282 87.2271 68.222C87.0303 68.4189 86.6365 68.4188 86.4396 68.4188ZM54.1521 4.63135C53.3646 4.63135 52.774 5.22197 52.5771 6.00947V53.2595C52.5771 54.047 53.1678 54.6376 54.1521 54.6376H74.4303C74.824 54.6376 75.4146 54.8345 75.6115 55.0314L84.4709 62.1189V56.4095C84.4709 55.2282 85.2584 54.4407 86.4396 54.4407H104.552C105.34 54.4407 105.93 53.8501 105.93 53.0626V5.8126C105.93 5.0251 105.34 4.43447 104.552 4.43447H54.1521V4.63135Z" fill="#A4B0C0"/>
<path d="M36.6302 111.338C36.2364 111.338 35.6458 111.141 35.4489 110.944L23.8333 101.494H6.11455C3.16143 101.494 0.798926 99.1313 0.602051 96.1782V48.9282C0.602051 45.975 3.16143 43.6125 6.11455 43.6125H50.6083C51.7896 43.6125 52.5771 44.4 52.5771 45.5813C52.5771 46.7625 51.7896 47.55 50.6083 47.55H6.11455C5.32705 47.55 4.73643 48.1407 4.53955 48.9282V96.1782C4.53955 96.9657 5.13018 97.5563 6.11455 97.5563H24.4239C24.8177 97.5563 25.4083 97.7532 25.6052 97.95L34.4646 105.234V99.525C34.4646 98.3438 35.2521 97.5563 36.4333 97.5563H56.5145C57.3021 97.5563 57.8927 96.9657 58.0896 96.1782V59.3625C58.0896 58.1813 58.8771 57.3938 60.0583 57.3938C61.2396 57.3938 62.0271 58.1813 62.0271 59.3625V96.1782C62.0271 99.1313 59.6646 101.494 56.5145 101.494H38.4021V109.369C38.4021 110.156 38.0083 110.747 37.2208 111.141C37.2208 111.141 36.8271 111.338 36.6302 111.338Z" fill="#A4B0C0"/>
</svg>
`
