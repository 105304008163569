import { FC, useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import {
    StyledRadioBtnTxtFieldFormContainer,
    StyledTableFormBody,
    StyledTableFormBodyItemsContainer,
    StyledTableFormHeader,
    StyledTableFormInputContainer,
    StyledTableFormItem,
    StyledResetContainer,
    StyledTableFormBodyItemLeft,
    StyledTableFormBodyItemMiddle,
    StyledTableFormBodyItemRight
} from './index.style'
import {
    ICommentedMultipleChoiceList,
    ICommentedMultipleChoiceListItem,
    IOption,
    ISectionElement
} from '../index.models'
import { performFieldSynchronizationStep } from '../utils'
import { AwvTooltip } from '../AwvTooltip'
import useErrors from 'hooks/useErrors'
import IconButton from '@mui/material/IconButton'
import { Tooltip } from '@mui/material'
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp'
import { useResetValue } from 'hooks/useResetValue'

interface ICommentedMultipleChoiceListProps {
    name: string
    titleColumnLabel: string
    titleColumnWidth: number
    commentsColumnLabel: string
    commentsColumnWidth: number
    options: IOption[]
    items: ICommentedMultipleChoiceListItem[]
}

const availableWidth = 1060

const CommentedMultipleChoiceList: FC<ICommentedMultipleChoiceListProps> = ({
    name,
    titleColumnLabel,
    titleColumnWidth,
    commentsColumnLabel,
    commentsColumnWidth,
    options,
    items
}) => {
    const { register, control, getValues } = useFormContext()
    const errors = useErrors()
    const resetValue = useResetValue()
    const { fields, append, remove, move } = useFieldArray({ control, name })

    useEffect(() => {
        performFieldSynchronizationStep(items, fields, append, remove, move)
    }, [items, fields, append, remove, move])

    const optionColumnWidth = (availableWidth - titleColumnWidth - commentsColumnWidth) / options.length

    const labelsByName = items.reduce((acc: any, item: any) => {
        acc[item.name] = item.label
        return acc
    }, {})

    return (
        <StyledRadioBtnTxtFieldFormContainer>
            <StyledTableFormHeader>
                <StyledTableFormItem key={`header-${name}-title`} style={{ width: `${titleColumnWidth}px` }}>
                    {titleColumnLabel}
                </StyledTableFormItem>
                {options.map((option: IOption, idx: number) => (
                    <StyledTableFormItem key={`header-${name}-${idx}`} style={{ width: `${optionColumnWidth}px` }}>
                        {option.label}
                    </StyledTableFormItem>
                ))}
                <StyledTableFormItem key={`header-${name}-comments`} style={{ width: `${commentsColumnWidth}px` }}>
                    {commentsColumnLabel}
                </StyledTableFormItem>
            </StyledTableFormHeader>
            {fields.map((field: any, idx: number) => (
                <AwvTooltip
                    key={`tooltip_CMCL.${name}.${idx}`}
                    message={errors.getError(`${name}.${idx}`)}
                    placement="top-start"
                >
                    <StyledTableFormBody key={field.id}>
                        <StyledTableFormBodyItemLeft
                            isInError={errors.getError(`${name}.${idx}`)}
                            style={{ width: `${titleColumnWidth}px` }}
                        >
                            {labelsByName[field.name]}
                        </StyledTableFormBodyItemLeft>

                        <AwvTooltip
                            key={`tooltip_CMCL_${name}.${idx}_value`}
                            message={errors.getError(`${name}.${idx}.value`)}
                        >
                            <StyledTableFormBodyItemsContainer>
                                {options.map((option: IOption) => (
                                    <StyledTableFormBodyItemMiddle
                                        key={`option-${field.id}-${option.value}`}
                                        style={{ width: `${optionColumnWidth}px` }}
                                    >
                                        <input
                                            key={`radio.${name}.${idx}.${option.value}`}
                                            id={`radio.${name}.${idx}.${option.value}`}
                                            {...register(`${name}.${idx}.value`)}
                                            type="radio"
                                            value={option.value}
                                        />
                                    </StyledTableFormBodyItemMiddle>
                                ))}
                            </StyledTableFormBodyItemsContainer>
                        </AwvTooltip>

                        <AwvTooltip message={errors.getError(`${name}.${idx}.comments`)}>
                            <StyledTableFormBodyItemRight>
                                <StyledTableFormInputContainer
                                    isInError={errors.getError(`${name}.${idx}.comments`)}
                                    style={{ width: `${commentsColumnWidth}px` }}
                                >
                                    <input {...register(`${name}.${idx}.comments`)} />
                                </StyledTableFormInputContainer>
                            </StyledTableFormBodyItemRight>
                        </AwvTooltip>
                        <StyledResetContainer>
                            {(getValues(`${name}.${idx}.value`) !== null ||
                                (getValues(`${name}.${idx}.comments`) !== '' &&
                                    getValues(`${name}.${idx}.comments`) !== null)) && (
                                <Tooltip title="Reset">
                                    <IconButton
                                        onClick={() => {
                                            resetValue(`${name}.${idx}.value`, `${name}.${idx}.comments`)
                                        }}
                                    >
                                        <HighlightOffSharpIcon sx={{ color: '#9e9e9e' }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </StyledResetContainer>
                    </StyledTableFormBody>
                </AwvTooltip>
            ))}
        </StyledRadioBtnTxtFieldFormContainer>
    )
}

export const renderCommentedMultipleChoiceList = (element: ISectionElement, key: string) => {
    const commentedMultipleChoiceList = element as ICommentedMultipleChoiceList
    return <CommentedMultipleChoiceList key={key} {...commentedMultipleChoiceList} />
}
