import styled from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { white, light_gray5, grey } = UI_COLORS

export const StyledTxtFieldRadioTableContainer = styled.article`
    width: ${pxToRem(1145)};
    margin-bottom: ${pxToRem(10)};
`

export const StyledTxtFieldRadioTableHeader = styled.ul`
    background-color: ${light_gray5};
    list-style: none;
    margin: 0;
    padding: 0;

    border: 1px solid #dee5ef;

    display: flex;
    flex-direction: row;

    padding: 11px 30px;
`

export const StyledTxtFieldRadioTableHeaderItem = styled.li`
    width: ${pxToRem(300)};
    margin-right: 60px;

    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #73839c;
    }
`

export const StyledTxtFieldRadioTableBody = styled.ul<{ isInError?: any }>`
    background-color: ${white};
    list-style: none;
    margin: 0;
    padding: 0;

    padding: 18px 30px;

    display: flex;
    flex-direction: row;

    border-bottom: 1px solid #dee5ef;
    border-left: 1px solid #dee5ef;
    border-right: 1px solid #dee5ef;

    ${(props) => {
        if (props.isInError) {
            return `border-left: 3px solid red;
            padding-left: 28px;`
        }
    }}
`

export const StyledTxtFieldRadioTableBodyItem = styled.li`
    width: ${pxToRem(300)};
    margin-right: 60px;

    :nth-child(2) {
        input {
            margin-right: 10px;
            margin-bottom: 20px;
        }

        label {
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #293854;
        }

        p {
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #293854;
        }
    }

    :nth-child(3) {
        margin-right: 0;
    }

    :nth-child(4) {
        margin-left: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    /* input {
        outline: none;
        width: 300px;
        height: 50px;

        background-color: #ffffff;
        border: 1px solid #73839c;
        border-radius: 4px;
    } */
`

export const StyledStatusContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
`

export const StyledTableStatusContainer = styled.article<{ isInError?: any }>`
    width: ${pxToRem(248)};
    ${(props) => {
        if (props.isInError) {
            return `border-left: 1px solid red;`
        }
    }}
`
export const StyledTableStatusListContainer = styled.article`
    display: flex;
    flex-direction: column;
`
export const StyledTextFieldContainer = styled.article<{ isInError?: any }>`
    input {
        width: 300px;
        height: 50px;
        background: ${white};
        border: 1px solid #73839c;
        border-radius: 4px;
        ${(props) => {
            if (props.isInError) {
                return `border: 1px solid red;`
            }
        }}
    }
`

export const StyledFormTextAreaContainer = styled.article<{ isInError?: any }>`
    width: ${pxToRem(300)};

    label {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #293854;
        margin-bottom: 15px;
    }

    textarea {
        width: 100%;
        background: ${white};
        border: 1px solid #73839c;
        border-radius: 4px;
        margin-top: 15px;
        padding: 24px 18px;

        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #293854;
        margin-bottom: 15px;
        outline: none;

        resize: none;

        ::placeholder {
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;

            color: #a4b0c0;
        }

        ${(props) => {
            if (props.isInError) {
                return `border: 1px solid red;`
            }
        }}
    }
`

export const StyledDeleteButton = styled.button`
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 0;
    margin: 0;
    padding: 0;
    position: relative;

    cursor: pointer;

    :hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
        border-radius: 20px;
        p {
            visibility: visible;
        }
    }

    :focus {
        outline: none;
    }

    :disabled {
        visibility: collapse;
    }

    p {
        background-color: #293854;
        padding: 5px 0;
        border-radius: 4px;
        width: 70px;
        position: absolute;
        top: -40px;
        right: 10;
        color: #ffffff;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        visibility: hidden;
    }
`

export const StyledExplanatoryNote = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
`

export const StyledAddLink = styled.button`
    border: 0;
    background-color: transparent;
    margin: 0;
    text-align: left;
    height: 18px;
    position: relative;
    top: -10px;
    margin-top: 20px;
    margin-bottom: 5px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    color: #2281c4;
    cursor: pointer;

    :focus {
        outline: none;
    }

    :disabled {
        visibility: collapse;
    }
`

export const StyledResetContainer = styled.article`
    width: ${pxToRem(50)};
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
        :disabled {
            visibility: hidden;
        }
    }
`
