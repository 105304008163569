import { useLocation } from 'react-router-dom'

const useMemberInfoFromUrl = (): [number, string, number] => {
    const location = useLocation()
    /* MemberInfo is in the form of 
        <<Memberid>> or
        <<MemberId>>-<<npi>> or
        <<MemberId>>-<<year>> or
        <<MemberId>>-<<npi>>-<<year>>
    */

    // Split path into segments
    try {
        const pathSegments = location.pathname.split('/')
        const lastSegment = pathSegments[pathSegments.length - 1]
        const tokens = lastSegment.split('-')

        // Find the part before the dash in the last segment, this is the member id
        const memberId = Number(tokens[0])

        // Extract the value between dash and question mark, this is the npi
        let npi = ''
        let yr = 0

        switch (tokens.length) {
            case 2:
                if (tokens[1].length < 5) yr = Number(tokens[1])
                else npi = tokens[1]
                break
            case 3:
                npi = tokens[1]
                yr = Number(tokens[2])
                break
            default:
        }

        return [memberId, npi, yr]
    } catch (error) {
        console.error(error)
        return [0, '', 0]
    }
}

export default useMemberInfoFromUrl
