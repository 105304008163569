import { combineReducers } from 'redux'
import systemReducer from './slices/system'
import userReducer from './slices/user'
import toastReducer from './slices/toast'
import pulse8Reducer from './slices/pulse8'
import incentiveReducer from './slices/incentive'
import memberProfileReducer from './slices/memberProfile'
import pointsInCareReducer from './slices/pointsInCare'
import gapsInCareReducer from './slices/gapsInCare'
import uiReducer from './slices/ui'
import documentsReducer from './slices/documents'
import diagnosisReducer from './slices/diagnosis'
import feedbackReducer from './slices/feedback'

const rootReducer = combineReducers({
    system: systemReducer,
    user: userReducer,
    toast: toastReducer,
    pulse8: pulse8Reducer,
    incentive: incentiveReducer,
    ui: uiReducer,
    memberProfile: memberProfileReducer,
    pointsInCare: pointsInCareReducer,
    gapsInCare: gapsInCareReducer,
    documents: documentsReducer,
    diagnosis: diagnosisReducer,
    feedback: feedbackReducer
})

export { rootReducer }
