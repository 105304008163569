import { IDropDownListStyleParams } from 'components/DropDownList/index.style'
import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem, DEVICE } from 'utilities'

const { light_blue2, white, grey, light_gray4, dark_gray, light_gray2, light_gray3 } = UI_COLORS

export const StyledCreateNewUserContainer = styled.article`
    padding-left: ${pxToRem(30)};
    padding-right: ${pxToRem(30)};
    padding-bottom: ${pxToRem(200)};

    h1 {
        color: ${dark_gray};
        font-size: ${pxToRem(22)};
        font-family: 'Work Sans', sans-serif;
        font-weight: 700;
        font-style: normal;

        ${DEVICE.tablet} {
            font-size: ${pxToRem(26)};
        }
    }

    h2 {
        color: ${dark_gray};
        font-family: 'Work Sans', sans-serif;
        font-weight: 600;
        font-style: normal;
        ${DEVICE.tablet} {
            font-size: ${pxToRem(22)};
        }
    }
`

export const StyledInputContainer = styled.article<{ secondary?: boolean; isPhoneField?: boolean }>`
    width: 100%;
    margin-right: ${pxToRem(20)};
    margin-bottom: ${pxToRem(24)};

    ${DEVICE.tablet} {
        width: ${pxToRem(400)};

        ${(props) => {
            if (props.secondary) {
                return css`
                    width: ${pxToRem(220)};
                `
            }
        }}
    }

    ${DEVICE.desktop} {
        ${(props) => {
            if (props.secondary) {
                return css`
                    width: ${pxToRem(220)};
                `
            }
        }}

        ${(props) => {
            if (props.isPhoneField) {
                return css`
                    width: ${pxToRem(250)};
                `
            }
        }}
    }
`

export const StyledInputContainerWrap = styled.article<{ secondary?: boolean }>`
    display: flex;
    flex-direction: column;

    ${(props) => {
        if (props.secondary) {
            return css`
                padding-top: ${pxToRem(20)};
                padding-bottom: ${pxToRem(20)};
                border-bottom: 1px solid #dee5ef;
            `
        }
    }}

    ${DEVICE.desktop} {
        flex-direction: row;
    }
`
export const StyledCreateButtonsContainer = styled.article`
    padding: ${pxToRem(15)};
    width: 100%;
    position: fixed;
    z-index: 9;
    bottom: 0;
    left: 0;
    background-color: white;
    border-top: 1px solid #dee5ef;

    ${DEVICE.tablet} {
        padding: ${pxToRem(20)};
        width: 100%;
        display: flex;

        justify-content: end;
    }
`

export const StyledButton = styled.button<{ secondary?: boolean }>`
    width: 100%;
    height: ${pxToRem(50)};

    background-color: ${light_blue2};
    border: 0;
    color: ${white};
    border-radius: 4px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};

    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: ${pxToRem(24)};

    ${(props) => {
        if (props.secondary) {
            return css`
                background-color: ${grey};
            `
        }
    }}

    cursor: pointer;

    ${DEVICE.tablet} {
        width: ${pxToRem(200)};
        margin-right: ${pxToRem(35)};
        margin-bottom: 0;
    }

    &:active {
        border: 1px solid ${light_blue2};
        background: transparent;
        color: ${light_blue2};

        figure {
            span {
                svg {
                    path {
                        fill: ${light_blue2};
                    }
                }
            }
        }
    }

    figure {
        margin: 0;
        margin-right: ${pxToRem(15)};
        transform: translateY(1px);
        span {
            svg {
                width: ${pxToRem(15)};
                height: ${pxToRem(15)};
            }
        }
    }
`
export const StyledInputContainerGroup = styled.article<{ secondary?: boolean }>`
    ${DEVICE.tablet} {
        ${(props) => {
            if (props.secondary) {
                return css`
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                `
            }
        }}
    }

    ${DEVICE.desktop} {
        display: flex;
        flex-direction: row;
    }
`
export const StyledUserDetailsFieldSet = styled.fieldset`
    border: 0;
    margin: 0;
    padding: 0;
    padding-bottom: 150px;

    :disabled {
        input {
            background: ${light_gray2};
        }
    }
`

export const locationDropDownListStyleParams: IDropDownListStyleParams = {
    width: 400,
    marginTop: '0px',
    singleValueMarginTop: '-5px',
    indicatorMarginTop: '-85px',
    paddingTop: '-10px !important',
    fontSize: '14px',
    placeholderFontSize: '14px',  
    placeholderTop: '-10px !important',

}

export const locationTypeDropDownListStyleParams: IDropDownListStyleParams = {
    width: 225,
    marginTop: '0px',
    singleValueMarginTop: '-5px',
    indicatorMarginTop: '-85px',
    paddingTop: '0px !important',
    fontSize: '14px',  
    placeholderColor: light_gray3 + ' !important;',
    placeholderFontSize: '14px'
}

export const StyledInputSelectLegend = styled.label<{ isHeaderVisible?: boolean }>`
    margin-bottom: ${pxToRem(15)};
    display: block;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(14)};
    color: ${dark_gray};

    ${(props) => {
        if (props.isHeaderVisible) {
            return css`
                visibility: hidden;
            `
        }
    }}
`

export const StyledLocationTypeSelect = styled.select<{ error?: boolean }>`
    width: ${pxToRem(225)};
    height: ${pxToRem(50)};
    opacity: 1;
    border: 1px solid #dee5ef;
    border-radius: ${pxToRem(4)};

    padding-left: ${pxToRem(10)};

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};
    text-transform: capitalize;
    color: #293854;

    :disabled {
        background: ${light_gray4};
    }

    option {
        text-transform: capitalize;
    }

    ${(props) => {
        if (props.error) {
            return css`
                outline: solid 2px red;
            `
        }
    }}
`

export const StyledLocationTypeInputContainer = styled.article<{ secondary?: boolean }>`
    width: 100%;
    margin-right: ${pxToRem(-5)};
    margin-bottom: ${pxToRem(24)};

    ${DEVICE.tablet} {
        width: ${pxToRem(250)};
    }
`

export const StyledAddLocationButton = styled.button`
    border: 0;
    background-color: transparent;

    margin-top: 16px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */
    color: #2281c4;

    cursor: pointer;

    :disabled {
        color: ${grey};
        cursor: default;
    }
`

export const StyledEditLocationButton = styled.button`
    width: ${pxToRem(48)};
    height: ${pxToRem(48)};
    background-color: transparent;
    border: 0;

    cursor: pointer;

    p {
        background-color: #293854;
        padding: 5px 0;
        border-radius: 4px;
        width: 70px;
        position: absolute;
        top: -10px;
        right: 10;
        color: #ffffff;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }
`

export const StyledEditPCPLocationButton = styled.button`
    width: ${pxToRem(48)};
    height: ${pxToRem(48)};
    background-color: transparent;
    position: relative;
    top: 12px;
    border: 0;

    cursor: pointer;

    :hover {
        p {
            visibility: visible;
        }
    }
`

export const EditButtonPlaceHolder = styled.div`
    height: 30px;
`

export const StyledPCPToolTip = styled.p`
    background-color: #293854;
    padding: 5px;
    border-radius: 4px;
    margin-top: 10px;
    width: 70px;
    position: relative;
    top: -70px;
    right: 10;
    color: #ffffff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    visibility: hidden;
`

export const StyledAddLocationButtonWrap = styled.article`
    ${DEVICE.tablet} {
        padding-top: ${pxToRem(32)};
    }

    figure {
        margin: 0;
        svg {
            width: 100%;
            height: 100%;
        }
    }
`

export const StyledAdminAddLocationButtonWrap = styled.article`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    ${DEVICE.tablet} {
        padding-top: ${pxToRem(16)};
        padding-left: ${pxToRem(10)};
    }

    figure {
        margin: 0;
        svg {
            width: 100%;
            height: 100%;
        }
    }
`

export const StyledLocationErrorMessage = styled.article`
    color: #b11b1b;
    font-family: 'Inter', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    position: relative;
    top: 0px;
`

export const ProviderLocationTypeHorizontalLine = styled.div`
    ${DEVICE.desktop} {
        &:after {
            content: '';
            position: absolute;
            transform: TranslateX(-100%);
            border-bottom: 2px solid #2281c4;
            width: 20px;
            height: 55px;
        }
    }
`

export const ProviderOfficePhoneHorizontalLine = styled.div`
    ${DEVICE.desktop} {
        &:after {
            content: '';
            position: absolute;
            transform: TranslateX(-100%);
            border-bottom: 2px solid #2281c4;
            width: 20px;
            height: 55px;
        }
    }
`

export const ProviderError = styled.small`
    color: #b11b1b;
    font-family: 'Inter', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    position: relative;
`
