import CustomSvgIcon from 'components/CustomSvgIcon'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import {
    IMemberProfileDataItem,
    IMemberProfileDetails,
    IMemberProfileLookupData,
    IMemberProfilePersonalDetails
} from 'pages/hcp/users/create/index.models'
import { FC, useEffect, useState } from 'react'
import { useForm, Controller, FieldValues } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
    fetchMemberProfileDetail,
    MemberProfileState,
    resetUserHasFiles,
    updateMemberProfilePersonalDetails
} from 'redux/slices/memberProfile'
import { getValidAreaCodes } from 'redux/slices/user'
import { dispatch } from 'redux/store'
import { toLocalDate } from 'utilities/dateutilities'
import { StyledMemberInputContainer } from '../MemberInput/index.style'
import { editPen } from './icons'
import {
    StyledMemberButtonsContainer,
    StyledMemberCancelButton,
    StyledMemberEditButton,
    StyledMemberInfoContainer,
    StyledMemberInputWrap,
    StyledMemberInputWrapForEmergencyContact,
    StyledMemberInputWrapForHealthCareProxy,
    StyledMemberSaveButton,
    StyledMemberSelect,
    StyledMemberSelectWrap,
    StyledPersonalEditContainer
} from './index.style'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { isValidAreaCode } from 'utilities/stringUtilities'
import DropDownList from 'components/DropDownList'
import Spinner from 'components/Spinner'
import {
    languageDropDownListStyleParams,
    relationshipDropDownListStyleParams
} from 'components/TaxIdProviderInfo/index.style'
import { Pulse8State, setCurrentNpi } from 'redux/slices/pulse8'
import useCurrentUser from 'hooks/useCurrentUser'
import useMemberProfileLookupData from 'hooks/useMemberProfileLookupData'
import { ERoles } from 'models/enums/role'
import CustomMaskedInput from 'components/CustomMaskInput/index'
import SmallSpinner from 'components/SmallSpinner'

interface IPersonalProps {
    isLoading: (isFormLoading: boolean) => void
}

let areaCodes: number[] | null = []

const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

const checkIfFieldHasValue = (value: any) => {
    return value != null && value != undefined && value != ''
}

const validateOtherEmergencyFieldsAgainstFirstName = (context: any, value: any) => {
    const areOtherEmergencyFieldsEmpty =
        !checkIfFieldHasValue(context.parent.emergencyLastName) &&
        !checkIfFieldHasValue(context.parent.emergencyContactPhone)
    const areAnyOtherEmergencyFieldsPopulated =
        checkIfFieldHasValue(context.parent.emergencyLastName) ||
        checkIfFieldHasValue(context.parent.emergencyContactPhone)
    return areOtherEmergencyFieldsEmpty || (areAnyOtherEmergencyFieldsPopulated && checkIfFieldHasValue(value))
}

const validateOtherEmergencyFieldsAgainstLastName = (context: any, value: any) => {
    const areOtherEmergencyFieldsEmpty =
        !checkIfFieldHasValue(context.parent.emergencyFirstName) &&
        !checkIfFieldHasValue(context.parent.emergencyContactPhone)
    const areAnyOtherEmergencyFieldsPopulated =
        checkIfFieldHasValue(context.parent.emergencyFirstName) ||
        checkIfFieldHasValue(context.parent.emergencyContactPhone)
    return areOtherEmergencyFieldsEmpty || (areAnyOtherEmergencyFieldsPopulated && checkIfFieldHasValue(value))
}

const validateOtherEmergencyFieldsAgainstPhone = (context: any, value: any) => {
    const areOtherEmergencyFieldsEmpty =
        !checkIfFieldHasValue(context.parent.emergencyFirstName) &&
        !checkIfFieldHasValue(context.parent.emergencyLastName)
    const areAnyOtherEmergencyFieldsPopulated =
        checkIfFieldHasValue(context.parent.emergencyFirstName) ||
        checkIfFieldHasValue(context.parent.emergencyLastName)
    return areOtherEmergencyFieldsEmpty || (areAnyOtherEmergencyFieldsPopulated && checkIfFieldHasValue(value))
}

const validateOtherHealthCareProxyFieldsAgainstFirstName = (context: any, value: any) => {
    const areOtherHealthCareProxyFieldsEmpty =
        !checkIfFieldHasValue(context.parent.healthCareProxyLastName) &&
        !checkIfFieldHasValue(context.parent.healthCareProxyPhone)
    const areAnyOtherHealthCareProxyFieldsPopulated =
        checkIfFieldHasValue(context.parent.healthCareProxyLastName) ||
        checkIfFieldHasValue(context.parent.healthCareProxyPhone)
    return (
        areOtherHealthCareProxyFieldsEmpty || (areAnyOtherHealthCareProxyFieldsPopulated && checkIfFieldHasValue(value))
    )
}

const validateOtherHealthCareProxyFieldsAgainstLastName = (context: any, value: any) => {
    const areOtherHealthCareProxyFieldsEmpty =
        !checkIfFieldHasValue(context.parent.healthCareProxyFirstName) &&
        !checkIfFieldHasValue(context.parent.healthCareProxyPhone)
    const areAnyOtherHealthCareProxyFieldsPopulated =
        checkIfFieldHasValue(context.parent.healthCareProxyFirstName) ||
        checkIfFieldHasValue(context.parent.healthCareProxyPhone)
    return (
        areOtherHealthCareProxyFieldsEmpty || (areAnyOtherHealthCareProxyFieldsPopulated && checkIfFieldHasValue(value))
    )
}

const validateOtherHealthCareProxyFieldsAgainstPhone = (context: any, value: any) => {
    const areOtherHealthCareProxyFieldsEmpty =
        !checkIfFieldHasValue(context.parent.healthCareProxyFirstName) &&
        !checkIfFieldHasValue(context.parent.healthCareProxyLastName)
    const areAnyOtherHealthCareProxyFieldsPopulated =
        checkIfFieldHasValue(context.parent.healthCareProxyFirstName) ||
        checkIfFieldHasValue(context.parent.healthCareProxyLastName)
    return (
        areOtherHealthCareProxyFieldsEmpty || (areAnyOtherHealthCareProxyFieldsPopulated && checkIfFieldHasValue(value))
    )
}

const getRawPhone = (value: any) => {
    const rawPhone = value
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('-', '')
        .replaceAll(' ', '')
        .replaceAll('_', '')
    return rawPhone
}

const personalInfoValidationSchema = yup.object().shape({
    firstName: yup.string().required('First Name is Required'),
    lastName: yup.string().required('Last Name is Required'),
    age: yup.number().required().positive().integer(),
    dob: yup.date().required(),
    gender: yup.mixed().required('Gender is Required'),
    language: yup.mixed(),
    email: yup.string().email('Invalid Email'),
    mobilePhone: yup
        .string()
        .matches(phoneRegExp, {
            message: 'Invalid Phone Number',
            name: 'mobilePhone',
            excludeEmptyString: true
        })
        .test('Valid Area code', 'Invalid Area Code', (value) => {
            const rawPhone = getRawPhone(value)
            return isValidAreaCode(rawPhone, areaCodes) || rawPhone == null || rawPhone == undefined || rawPhone == ''
        })
        .test('One Field Required', 'At least one Phone Number is Required', (value, context) => {
            const rawPhone = getRawPhone(value)
            return rawPhone?.length > 0 || context.parent.workPhone?.length > 0 || context.parent.homePhone?.length > 0
        }),
    workPhone: yup
        .string()
        .matches(phoneRegExp, {
            message: 'Invalid Phone Number',
            name: 'mobilePhone',
            excludeEmptyString: true
        })
        .test('Valid Area code', 'Invalid Area Code', (value) => {
            const rawPhone = getRawPhone(value)
            return isValidAreaCode(rawPhone, areaCodes) || rawPhone == null || rawPhone == undefined || rawPhone == ''
        }),
    homePhone: yup
        .string()
        .matches(phoneRegExp, {
            message: 'Invalid Phone Number',
            name: 'mobilePhone',
            excludeEmptyString: true
        })
        .test('Valid Area code', 'Invalid Area Code', (value) => {
            const rawPhone = getRawPhone(value)
            return isValidAreaCode(rawPhone, areaCodes) || rawPhone == null || rawPhone == undefined || rawPhone == ''
        }),
    emergencyFirstName: yup
        .string()
        .test(
            'First Name Required if Last or Phone',
            'Required because another Emergency Field is filled',
            (value, context) => {
                return validateOtherEmergencyFieldsAgainstFirstName(context, value)
            }
        ),
    emergencyLastName: yup
        .string()
        .test(
            'Last Name Required if Last or Phone',
            'Required because another Emergency Field is filled',
            (value, context) => {
                return validateOtherEmergencyFieldsAgainstLastName(context, value)
            }
        ),
    emergencyContactRelationship: yup.mixed(),
    emergencyContactPhone: yup
        .string()
        .matches(phoneRegExp, {
            message: 'Invalid Phone Number',
            name: 'mobilePhone',
            excludeEmptyString: true
        })
        .test('Valid Area code', 'Invalid Area Code', (value) => {
            const rawPhone = getRawPhone(value)
            return isValidAreaCode(rawPhone, areaCodes) || rawPhone == null || rawPhone == undefined || rawPhone == ''
        })
        .test('Valid ePhone', 'Required because another Emergency Field is filled', (value, context) => {
            const rawPhone = getRawPhone(value)
            return validateOtherEmergencyFieldsAgainstPhone(context, rawPhone)
        }),
    healthCareProxyFirstName: yup
        .string()
        .test(
            'First Name Required if Last or Phone',
            'Required because another Health Care Proxy Field is filled',
            (value, context) => {
                return validateOtherHealthCareProxyFieldsAgainstFirstName(context, value)
            }
        ),
    healthCareProxyLastName: yup
        .string()
        .test(
            'Last Name Required if Last or Phone',
            'Required because another Health Care Proxy Field is filled',
            (value, context) => {
                return validateOtherHealthCareProxyFieldsAgainstLastName(context, value)
            }
        ),
    healthCareProxyRelationship: yup.mixed(),
    healthCareProxyPhone: yup
        .string()
        .matches(phoneRegExp, {
            message: 'Invalid Phone Number',
            name: 'mobilePhone',
            excludeEmptyString: true
        })
        .test('Valid Area code', 'Invalid Area Code', (value) => {
            const rawPhone = getRawPhone(value)
            return isValidAreaCode(rawPhone, areaCodes) || rawPhone == null || rawPhone == undefined || rawPhone == ''
        })
        .test('Valid ePhone', 'Required because another Health Care Proxy Field is filled', (value, context) => {
            const rawPhone = getRawPhone(value)
            return validateOtherHealthCareProxyFieldsAgainstPhone(context, rawPhone)
        })
})

const Personal: FC<IPersonalProps> = ({ isLoading }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        watch,
        reset
    } = useForm({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(personalInfoValidationSchema)
    })
    const [isFormDisabled, setIsFormDisabled] = useState(true)
    const [isFormLoading, setIsFormLoading] = useState<boolean>(true)
    const [isCancelling, setIsCancelling] = useState<boolean>(false)

    const { pathname } = useLocation()

    const memberProfileDetails: IMemberProfileDetails = useSelector(
        (state: { memberProfile: MemberProfileState }) => state.memberProfile.memberProfileDetails
    )

    const { currentNpi, providerNpis, providerNpisLoaded } = useSelector(
        (state: { pulse8: Pulse8State }) => state.pulse8
    )

    const currentUser = useCurrentUser()

    const watchEmergencyFields = watch(['emergencyFirstName', 'emergencyLastName'], [null, null])
    const watchHealthCareProxyRelationshipFields = watch(
        ['healthCareProxyFirstName', 'healthCareProxyLastName'],
        [null, null]
    )

    areaCodes = useSelector((state: { user: any }) => state?.user?.validAreaCodes?.areaCodes)

    const memberProfileLookupData: IMemberProfileLookupData = useMemberProfileLookupData()

    useEffect(() => {
        dispatch(resetUserHasFiles())
    }, [])

    useEffect(() => {
        if (!pathname) return
        // pull the last part of the url as the member id and ignore the query string
        const memberid = pathname.split('/')[pathname.split('/').length - 1].split('-')[0]
        const npi = pathname.split('/')[pathname.split('/').length - 1].split('-')[1]
        dispatch(setCurrentNpi(npi))
        if (memberProfileDetails == null || memberProfileDetails?.memberId != memberid) {
            dispatch(fetchMemberProfileDetail({ memberId: memberid, npi: npi })).then(() => {
                setIsFormLoading(false)
                isLoading(false)
            })
        } else {
            setIsFormLoading(false)
            isLoading(false)
        }
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        dispatch(getValidAreaCodes())
        if (memberProfileDetails) {
            setValue('firstName', memberProfileDetails?.firstName)
            setValue('lastName', memberProfileDetails?.lastName ?? '')
            setValue('age', memberProfileDetails?.age ?? '')
            setValue('dob', toLocalDate(memberProfileDetails?.dateOfBirth) ?? '')
            setValue('gender', memberProfileDetails?.gender?.id)
            setValue('email', memberProfileDetails?.email ?? '')
            setValue('mobilePhone', memberProfileDetails?.mobilePhone ?? '')
            setValue('workPhone', memberProfileDetails?.workPhone ?? '')
            setValue('homePhone', memberProfileDetails?.homePhone ?? '')
            setValue('emergencyFirstName', memberProfileDetails?.eContactFirstName ?? '')
            setValue('emergencyLastName', memberProfileDetails?.eContactLastName ?? '')
            setValue('emergencyContactRelationship', memberProfileDetails?.eContactRelationship?.id)
            setValue('emergencyContactPhone', memberProfileDetails?.eContactPhone ?? '')
            setValue('language', memberProfileDetails.preferredLanguage?.id)
            setValue('healthCareProxyFirstName', memberProfileDetails.healthCareProxyFirstName ?? '')
            setValue('healthCareProxyLastName', memberProfileDetails.healthCareProxyLastName ?? '')
            setValue('healthCareProxyPhone', memberProfileDetails.healthCareProxyPhone ?? '')
            setValue('healthCareProxyRelationship', memberProfileDetails?.healthCareProxyRelationship?.id ?? '')
        }
    }, [memberProfileDetails])

    // const onErrors = (errors: any) => console.error(errors)

    const onSubmit = (data: FieldValues) => {
        const personalDetails: IMemberProfilePersonalDetails = {
            npi: currentNpi,
            memberId: Number(memberProfileDetails?.memberId),
            firstName: data.firstName,
            lastName: data.lastName,
            dateOfBirth: new Date(data.dob).toISOString(),
            age: data.age,
            email: data.email,
            mobilePhone: clearTel(data.mobilePhone),
            workPhone: clearTel(data.workPhone),
            homePhone: clearTel(data.homePhone),
            eContactFirstName: data.emergencyFirstName,
            eContactLastName: data.emergencyLastName,
            eContactRelationship: memberProfileLookupData?.relationships?.find(
                (rel) => rel.id == data.emergencyContactRelationship
            ),
            eContactPhone: clearTel(data.emergencyContactPhone),
            healthCareProxyFirstName: data.healthCareProxyFirstName,
            healthCareProxyLastName: data.healthCareProxyLastName,
            healthCareProxyPhone: clearTel(data.healthCareProxyPhone),
            healthCareProxyRelationship: memberProfileLookupData?.relationships?.find(
                (rel) => rel.id == data.healthCareProxyRelationship
            ),
            preferredLanguage: memberProfileLookupData?.languages?.find((lang) => lang.id == data.language),
            gender: memberProfileLookupData?.genders.find((gen) => gen.id === data.gender),
            lastUpdatedBy: currentUser.sid
        }

        dispatch(updateMemberProfilePersonalDetails(personalDetails))
        setIsFormDisabled(true)
    }

    const handleCancel = () => {
        setIsFormDisabled(true)
        setIsCancelling(true)
        dispatch(fetchMemberProfileDetail({ memberId: memberProfileDetails?.memberId, npi: currentNpi })).then(() => {
            setIsCancelling(false)
        })
    }

    const handleEditButton = () => {
        setIsFormDisabled(false)
    }

    const genderAbbreviationMap: Map<string, string> = new Map([
        ['M', 'Male'],
        ['F', 'Female'],
        ['O', 'Other']
    ])

    const getFullGenderNameFromAbbreviation = (abbreviation: string) => {
        return genderAbbreviationMap.get(abbreviation)
    }

    const languageOptions = () => {
        if (memberProfileLookupData?.languages == null) return []
        const english = memberProfileLookupData?.languages?.filter((language) => language.name == 'English')
        const spanish = memberProfileLookupData?.languages?.filter((language) => language.name == 'Spanish')
        const theRest = memberProfileLookupData?.languages?.filter(
            (language) => language.name !== 'English' && language.name !== 'Spanish'
        )

        return [...english, ...spanish, ...theRest]?.map((language) => {
            return { label: language.name, value: language.id }
        })
    }

    const genderOptions = memberProfileLookupData?.genders?.map((gender) => {
        return { label: getFullGenderNameFromAbbreviation(gender.name), value: gender.id }
    })

    const relationshipOptions = memberProfileLookupData?.relationships?.map((relationship: IMemberProfileDataItem) => {
        return { label: relationship.name, value: relationship.id }
    })

    const onError = (errors: any, e: any) => console.log(errors, e)

    /**
     * Custom phone input
     */

    const clearTel = (tel: any) => tel.replace(/[^0-9]/g, '')
    const setLanguage = (language: any) => {
        setValue('language', language)
    }

    const setEmergencyContactRelationship = (relationship: any) => {
        setValue('emergencyContactRelationship', relationship)
    }

    const setHealthCareProxyRelationship = (relationship: any) => {
        setValue('healthCareProxyRelationship', relationship)
    }

    const setGender = (gender: any) => {
        setValue('gender', gender)
    }

    const isReadOnly = [ERoles.CallCenterAdmin, ERoles.ProviderRep, ERoles.Coding, ERoles.Quality].includes(
        currentUser.primaryRole
    )

    return isFormLoading ? (
        <Spinner />
    ) : (
        <StyledMemberInfoContainer>
            <StyledPersonalEditContainer>
                <Typography type={TYPOGRAPHY_TYPES.h3}>Personal Information</Typography>
                {isReadOnly ? (
                    ''
                ) : (
                    <StyledMemberEditButton onClick={handleEditButton}>
                        <CustomSvgIcon iconSet={{ icon: editPen }} svg></CustomSvgIcon>
                        Edit
                    </StyledMemberEditButton>
                )}
                {isCancelling && <SmallSpinner />}
            </StyledPersonalEditContainer>
            <fieldset disabled={isFormDisabled || isReadOnly}>
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <StyledMemberInputWrap>
                        <StyledMemberInputContainer>
                            <label>First Name</label>
                            <input name="firstName" {...register('firstName')} />
                            <small>{errors?.firstName && errors.firstName.message}</small>
                        </StyledMemberInputContainer>
                        <StyledMemberInputContainer>
                            <label>Last Name</label>
                            <input name="lastName" {...register('lastName')} />
                            <small>{errors?.lastName && errors.lastName.message}</small>
                        </StyledMemberInputContainer>
                    </StyledMemberInputWrap>
                    <StyledMemberInputWrap>
                        <StyledMemberInputContainer>
                            <label>Date of Birth</label>
                            <input name="dob" {...register('dob')} />
                            <small>{errors?.dob && errors.dob.message}</small>
                        </StyledMemberInputContainer>
                        <StyledMemberInputContainer>
                            <label>Age</label>
                            <input disabled={true} name="age" {...register('age')} />
                            <small>{errors?.age && errors.age.message}</small>
                        </StyledMemberInputContainer>
                    </StyledMemberInputWrap>

                    <StyledMemberSelectWrap>
                        <StyledMemberSelect>
                            <label>Gender</label>
                            <Controller
                                name="gender"
                                control={control}
                                render={({ field }) => (
                                    <DropDownList
                                        options={genderOptions}
                                        placeholder={'Select Gender'}
                                        {...field}
                                        selectedValue={field.value}
                                        setSelectedValue={(value) => {
                                            setGender(value)
                                        }}
                                        isDisabled={isFormDisabled}
                                        isReadOnly={isFormDisabled}
                                        isInError={errors?.gender != null && errors?.gender != undefined}
                                        styleParams={languageDropDownListStyleParams}
                                    />
                                )}
                            />
                            <small>{errors?.gender && errors.gender.message}</small>
                        </StyledMemberSelect>

                        <StyledMemberSelect>
                            <label>Preferred Language</label>
                            <Controller
                                name="language"
                                control={control}
                                // rules={registerOptions.language}
                                render={({ field }) => (
                                    <DropDownList
                                        options={languageOptions()}
                                        placeholder={'Select Language'}
                                        {...field}
                                        selectedValue={field.value}
                                        setSelectedValue={(value) => {
                                            setLanguage(value)
                                        }}
                                        isDisabled={isFormDisabled}
                                        isReadOnly={isFormDisabled}
                                        isInError={errors?.language != null && errors?.language != undefined}
                                        styleParams={languageDropDownListStyleParams}
                                    />
                                )}
                            />
                            <small>{errors?.language && errors.language.message}</small>
                        </StyledMemberSelect>
                    </StyledMemberSelectWrap>

                    <StyledMemberInputWrap>
                        <StyledMemberInputContainer>
                            <label>Email Address</label>
                            <input name="email" {...register('email')} />
                            <small>{errors?.email && errors.email.message}</small>
                        </StyledMemberInputContainer>

                        <StyledMemberInputContainer>
                            <label>Mobile Phone Number</label>
                            <Controller
                                render={({ field }) => <CustomMaskedInput {...field} />}
                                name="mobilePhone"
                                control={control}
                            />
                            <small>{errors?.mobilePhone && errors.mobilePhone.message}</small>
                        </StyledMemberInputContainer>
                    </StyledMemberInputWrap>
                    <StyledMemberInputWrap>
                        <StyledMemberInputContainer>
                            <label>Work Phone Number</label>
                            <Controller
                                render={({ field }) => <CustomMaskedInput {...field} />}
                                name="workPhone"
                                control={control}
                            />
                            <small>{errors?.workPhone && errors.workPhone.message}</small>
                        </StyledMemberInputContainer>

                        <StyledMemberInputContainer>
                            <label>Home Phone Number</label>
                            <Controller
                                render={({ field }) => <CustomMaskedInput {...field} />}
                                name="homePhone"
                                control={control}
                            />
                            <small>{errors?.homePhone && errors.homePhone.message}</small>
                        </StyledMemberInputContainer>
                    </StyledMemberInputWrap>
                    <Typography type={TYPOGRAPHY_TYPES.h3}>Emergency Contact</Typography>
                    <StyledMemberInputWrap>
                        <StyledMemberInputContainer>
                            <label>First Name</label>
                            <input name="emergencyFirstName" {...register('emergencyFirstName')} />
                            <small>{errors?.emergencyFirstName && errors.emergencyFirstName.message}</small>
                        </StyledMemberInputContainer>
                        <StyledMemberInputContainer>
                            <label>Last Name</label>
                            <input name="emergencyLastName" {...register('emergencyLastName')} />
                            <small>{errors?.emergencyLastName && errors.emergencyLastName.message}</small>
                        </StyledMemberInputContainer>
                    </StyledMemberInputWrap>
                    <StyledMemberInputWrapForEmergencyContact>
                        <StyledMemberInputContainer>
                            <label>Phone Number</label>
                            <Controller
                                render={({ field }) => <CustomMaskedInput {...field} />}
                                name="emergencyContactPhone"
                                control={control}
                            />
                            <small>{errors?.emergencyContactPhone && errors.emergencyContactPhone.message}</small>
                        </StyledMemberInputContainer>

                        <StyledMemberSelect>
                            <label>Relationship</label>
                            <Controller
                                name="emergencyContactRelationship"
                                control={control}
                                // rules={registerOptions.language}
                                render={({ field }) => (
                                    <DropDownList
                                        options={relationshipOptions}
                                        placeholder={'Select Relationship'}
                                        {...field}
                                        selectedValue={field.value}
                                        setSelectedValue={(value) => {
                                            setEmergencyContactRelationship(value)
                                        }}
                                        isDisabled={
                                            isFormDisabled ||
                                            watchEmergencyFields.every((field) => {
                                                return field === '' || field == null || field == undefined
                                            })
                                        }
                                        isReadOnly={
                                            isFormDisabled ||
                                            watchEmergencyFields.every((field) => {
                                                return field === '' || field == null || field == undefined
                                            })
                                        }
                                        isSearchable={true}
                                        isInError={
                                            errors?.emergencyContactRelationship != null &&
                                            errors?.emergencyContactRelationship != undefined
                                        }
                                        styleParams={relationshipDropDownListStyleParams}
                                    />
                                )}
                            />
                            <small>
                                {errors?.emergencyContactRelationship && errors.emergencyContactRelationship.message}
                            </small>
                        </StyledMemberSelect>
                    </StyledMemberInputWrapForEmergencyContact>

                    <Typography type={TYPOGRAPHY_TYPES.h3}>Health Care Proxy</Typography>
                    <StyledMemberInputWrap>
                        <StyledMemberInputContainer>
                            <label>First Name</label>
                            <input name="healthCareProxyFirstName" {...register('healthCareProxyFirstName')} />
                            <small>{errors?.healthCareProxyFirstName && errors.healthCareProxyFirstName.message}</small>
                        </StyledMemberInputContainer>
                        <StyledMemberInputContainer>
                            <label>Last Name</label>
                            <input name="healthCareProxyLastName" {...register('healthCareProxyLastName')} />
                            <small>{errors?.healthCareProxyLastName && errors.healthCareProxyLastName.message}</small>
                        </StyledMemberInputContainer>
                    </StyledMemberInputWrap>
                    <StyledMemberInputWrapForHealthCareProxy>
                        <StyledMemberInputContainer>
                            <label>Phone Number</label>
                            <Controller
                                render={({ field }) => <CustomMaskedInput {...field} />}
                                name="healthCareProxyPhone"
                                control={control}
                            />
                            <small>{errors?.healthCareProxyPhone && errors.healthCareProxyPhone.message}</small>
                        </StyledMemberInputContainer>

                        <StyledMemberSelect>
                            <label>Relationship</label>
                            <Controller
                                name="healthCareProxyRelationship"
                                control={control}
                                // rules={registerOptions.language}
                                render={({ field }) => (
                                    <DropDownList
                                        options={relationshipOptions}
                                        placeholder={'Select Relationship'}
                                        {...field}
                                        selectedValue={field.value}
                                        setSelectedValue={(value) => {
                                            setHealthCareProxyRelationship(value)
                                        }}
                                        isDisabled={
                                            isFormDisabled ||
                                            watchHealthCareProxyRelationshipFields.every((field) => {
                                                return field === '' || field == null || field == undefined
                                            })
                                        }
                                        isReadOnly={
                                            isFormDisabled ||
                                            watchHealthCareProxyRelationshipFields.every((field) => {
                                                return field === '' || field == null || field == undefined
                                            })
                                        }
                                        isSearchable={true}
                                        isInError={
                                            errors?.healthCareProxyRelationship != null &&
                                            errors?.healthCareProxyRelationship != undefined
                                        }
                                        styleParams={relationshipDropDownListStyleParams}
                                    />
                                )}
                            />
                            <small>
                                {errors?.healthCareProxyRelationship && errors.healthCareProxyRelationship.message}
                            </small>
                        </StyledMemberSelect>
                    </StyledMemberInputWrapForHealthCareProxy>

                    {!isFormDisabled && !isReadOnly ? (
                        <StyledMemberButtonsContainer>
                            <StyledMemberCancelButton type="button" onClick={handleCancel}>
                                Cancel
                            </StyledMemberCancelButton>
                            <StyledMemberSaveButton type="submit">Save</StyledMemberSaveButton>
                        </StyledMemberButtonsContainer>
                    ) : (
                        ''
                    )}
                </form>
            </fieldset>
        </StyledMemberInfoContainer>
    )
}

export default Personal
