export const projectEarningsIcon = `<svg width="45" height="42" viewBox="0 0 45 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.5" d="M31.4375 26.6562C38.4274 26.6562 44.0937 20.9899 44.0937 14C44.0937 7.01015 38.4274 1.34375 31.4375 1.34375C24.4476 1.34375 18.7812 7.01015 18.7812 14C18.7812 20.9899 24.4476 26.6562 31.4375 26.6562Z" fill="#40BFFF"/>
<path d="M28.8125 12.4062H19.1562C18.5937 12.4062 18.2188 12.0312 18.2188 11.4687V1.8125C18.2188 1.25 18.5937 0.875 19.1562 0.875C19.7188 0.875 20.0938 1.25 20.0938 1.8125V10.5312H28.8125C29.375 10.5312 29.75 10.9062 29.75 11.4687C29.75 12.0312 29.2812 12.4062 28.8125 12.4062Z" fill="#2281C4"/>
<path d="M16.7188 41.4688H1.53125C0.96875 41.4688 0.59375 41.0938 0.59375 40.5313V1.8125C0.59375 1.25 0.96875 0.875 1.53125 0.875H19.1562C19.4375 0.875 19.625 0.968749 19.8125 1.15625L29.4688 10.8125C29.6562 11 29.75 11.1875 29.75 11.4687V40.4375C29.75 41 29.375 41.375 28.8125 41.375L16.7188 41.4688ZM2.46875 39.5938H27.875V11.9375L18.7813 2.84375H2.46875V39.5938Z" fill="#2281C4"/>
<path d="M38 40.4375C37.625 40.4375 37.3438 40.25 37.1563 39.9688L36.6875 39.0312C34.5313 35.0938 34.4375 34.7188 34.4375 34.3438V7.53125C34.4375 6.3125 35.4688 5.28125 36.6875 5.28125H39.3125C40.5313 5.28125 41.5625 6.3125 41.5625 7.53125V34.3438C41.5625 34.7188 41.4687 35 39.4062 38.9375C39.125 39.4062 38.9375 39.7812 38.9375 39.875C38.6562 40.25 38.375 40.4375 38 40.4375ZM36.3125 34.25C36.5 34.7188 37.4375 36.4063 38 37.5312C38.5625 36.4063 39.5 34.7188 39.6875 34.25V7.53125C39.6875 7.34375 39.5 7.15625 39.3125 7.15625H36.6875C36.5 7.15625 36.3125 7.34375 36.3125 7.53125V34.25Z" fill="#2281C4"/>
<path d="M40.5312 26.5625H35.2812C34.7187 26.5625 34.3438 26.1875 34.3438 25.625C34.3438 25.0625 34.7187 24.6875 35.2812 24.6875H40.5312C41.0937 24.6875 41.4687 25.0625 41.4687 25.625C41.4687 26.1875 41.0937 26.5625 40.5312 26.5625ZM40.5312 23.375H35.2812C34.7187 23.375 34.3438 23 34.3438 22.4375C34.3438 21.875 34.7187 21.5 35.2812 21.5H40.5312C41.0937 21.5 41.4687 21.875 41.4687 22.4375C41.4687 23 41.0937 23.375 40.5312 23.375Z" fill="#2281C4"/>
<path d="M39.5937 7.15625C39.0312 7.15625 38.6562 6.78125 38.6562 6.21875V4.4375C38.6562 4.0625 38.2812 3.78125 37.9062 3.875C37.625 3.875 37.3438 4.15625 37.3438 4.4375V6.21875C37.3438 6.78125 36.9688 7.15625 36.4062 7.15625C35.8437 7.15625 35.4688 6.78125 35.4688 6.21875V4.4375C35.5625 3.03125 36.7812 2 38.1875 2C39.5 2.09375 40.5312 3.125 40.625 4.4375V6.21875C40.5312 6.78125 40.0625 7.15625 39.5937 7.15625Z" fill="#2281C4"/>
<path d="M40.5312 35.2812H35.2812C34.7187 35.2812 34.3438 34.9062 34.3438 34.3438C34.3438 33.7812 34.7187 33.4062 35.2812 33.4062H40.5312C41.0937 33.4062 41.4687 33.7812 41.4687 34.3438C41.4687 34.9062 41.0937 35.2812 40.5312 35.2812Z" fill="#2281C4"/>
<path d="M33.0312 19.25C32.4687 19.25 32.0938 18.875 32.0938 18.3125V12.0313C32.0938 11.75 32.2813 11.4688 32.4688 11.2813L34.8125 9.6875C35.2813 9.40625 35.8437 9.5 36.125 9.96875C36.4062 10.4375 36.3125 11 35.8438 11.2813L33.9688 12.5938V18.3125C34.0625 18.7813 33.5938 19.25 33.0312 19.25C33.125 19.25 33.0312 19.25 33.0312 19.25Z" fill="#2281C4"/>
<path d="M12.3125 14.8438H6.21875C5.65625 14.8438 5.28125 14.4688 5.28125 13.9063V9.125C5.28125 8.5625 5.65625 8.1875 6.21875 8.1875H12.3125C12.875 8.1875 13.25 8.5625 13.25 9.125V13.9063C13.25 14.375 12.7813 14.8438 12.3125 14.8438ZM7.0625 12.9688H11.2813V10.0625H7.0625V12.9688Z" fill="#2281C4"/>
<path d="M12.3125 24.875H6.21875C5.65625 24.875 5.28125 24.5 5.28125 23.9375V19.1562C5.28125 18.5937 5.65625 18.2188 6.21875 18.2188H12.3125C12.875 18.2188 13.25 18.5937 13.25 19.1562V23.9375C13.25 24.5 12.7813 24.875 12.3125 24.875ZM7.0625 23H11.2813V20.0937H7.0625V23Z" fill="#2281C4"/>
<path d="M22.625 24.5H16.625C16.0625 24.5 15.6875 24.125 15.6875 23.5625C15.6875 23 16.0625 22.625 16.625 22.625H22.625C23.1875 22.625 23.5625 23 23.5625 23.5625C23.5625 24.125 23.0938 24.5 22.625 24.5Z" fill="#2281C4"/>
<path d="M23.4687 20.5625H16.5312C15.9687 20.5625 15.5938 20.1875 15.5938 19.625C15.5938 19.0625 15.9687 18.6875 16.5312 18.6875H23.4687C24.0312 18.6875 24.4062 19.0625 24.4062 19.625C24.4062 20.1875 24.0312 20.5625 23.4687 20.5625Z" fill="#2281C4"/>
<path d="M12.3125 35H6.21875C5.65625 35 5.28125 34.625 5.28125 34.0625V29.375C5.28125 28.8125 5.65625 28.4375 6.21875 28.4375H12.3125C12.875 28.4375 13.25 28.8125 13.25 29.375V34.0625C13.25 34.625 12.7813 35 12.3125 35ZM7.0625 33.125H11.2813V30.3125H7.0625V33.125Z" fill="#2281C4"/>
<path d="M22.625 34.625H16.625C16.0625 34.625 15.6875 34.25 15.6875 33.6875C15.6875 33.125 16.0625 32.75 16.625 32.75H22.625C23.1875 32.75 23.5625 33.125 23.5625 33.6875C23.5625 34.25 23.0938 34.625 22.625 34.625Z" fill="#2281C4"/>
<path d="M23.4687 30.6875H16.5312C15.9687 30.6875 15.5938 30.3125 15.5938 29.75C15.5938 29.1875 15.9687 28.8125 16.5312 28.8125H23.4687C24.0312 28.8125 24.4062 29.1875 24.4062 29.75C24.4062 30.3125 24.0312 30.6875 23.4687 30.6875Z" fill="#2281C4"/>
</svg>
`

export const activeMembersIcon = `<svg width="52" height="42" viewBox="0 0 52 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_8614_75569)">
<path opacity="0.5" d="M14.8438 38.2812C21.8336 38.2812 27.5 32.6149 27.5 25.625C27.5 18.6351 21.8336 12.9688 14.8438 12.9688C7.8539 12.9688 2.1875 18.6351 2.1875 25.625C2.1875 32.6149 7.8539 38.2812 14.8438 38.2812Z" fill="#40BFFF"/>
<path d="M27.5 38.6562C27.4062 38.6562 27.3125 38.6562 27.2188 38.5625C23.375 37.0625 19.7187 35.0938 16.3437 32.6562C9.03125 27.4062 5.28125 21.125 5.28125 14.4688C5.28125 7.53125 10.9062 1.90625 17.8438 1.90625C21.5938 1.90625 25.1562 3.59375 27.5 6.5C29.8438 3.59375 33.4062 1.90625 37.1562 1.90625C44.0937 1.90625 49.7188 7.53125 49.7188 14.4688C49.7188 21.125 45.875 27.4062 38.6562 32.6562C35.2812 35.0938 31.625 37.0625 27.7812 38.5625C27.6875 38.6562 27.5938 38.6562 27.5 38.6562ZM17.75 3.78125C11.8437 3.78125 7.0625 8.5625 7.0625 14.4688C7.0625 28.0625 24.7812 35.6562 27.4062 36.6875C30.0312 35.6562 47.75 28.0625 47.75 14.4688C47.75 8.5625 42.9688 3.78125 37.0625 3.78125C33.5 3.78125 30.125 5.5625 28.1562 8.5625C27.9688 8.84375 27.6875 8.9375 27.4062 9.03125C27.125 9.03125 26.8437 8.84375 26.6562 8.5625C24.7812 5.5625 21.4062 3.78125 17.75 3.78125Z" fill="#2281C4"/>
<path d="M28.9062 26.5621C28.4375 26.5621 28.1562 26.2809 27.9688 25.9059L24.4062 15.3121L22.4375 20.1871C22.25 20.5621 21.9688 20.7496 21.5938 20.7496H7.0625C6.5 20.7496 6.125 20.3746 6.125 19.8121C6.125 19.2496 6.5 18.8746 7.0625 18.8746H20.9375L23.6562 12.2184C23.8438 11.8434 24.2188 11.5621 24.5938 11.6559C24.9688 11.6559 25.3437 11.9371 25.4375 12.3121L29.0938 23.2809L30.9688 19.5309C31.1562 19.2496 31.4375 18.9684 31.8125 18.9684H47.6562C48.2188 18.9684 48.5938 19.3434 48.5938 19.9059C48.5938 20.4684 48.2188 20.8434 47.6562 20.8434H32.375L29.75 26.1871C29.5625 26.3746 29.1875 26.5621 28.9062 26.5621Z" fill="#2281C4"/>
</g>
<defs>
<filter id="filter0_d_8614_75569" x="0.1875" y="0.90625" width="51.5312" height="40.75" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8614_75569"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8614_75569" result="shape"/>
</filter>
</defs>
</svg>
`

export const outreachMembersIcon = `<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.5" d="M17.8254 32.7725C23.3299 32.7725 27.7922 28.3102 27.7922 22.8057C27.7922 17.3012 23.3299 12.8389 17.8254 12.8389C12.3209 12.8389 7.85858 17.3012 7.85858 22.8057C7.85858 28.3102 12.3209 32.7725 17.8254 32.7725Z" fill="#40BFFF"/>
<path d="M18.5637 30.4844H6.01288C5.56991 30.4844 5.2746 30.1891 5.2746 29.7461C5.2746 29.3031 5.56991 29.0078 6.01288 29.0078H18.5637C19.0066 29.0078 19.3019 29.3031 19.3019 29.7461C19.3019 30.1891 19.0066 30.4844 18.5637 30.4844Z" fill="#2281C4"/>
<path d="M36.1641 13.7568H23.6133C23.1703 13.7568 22.875 13.4615 22.875 13.0186C22.875 12.5756 23.1703 12.2803 23.6133 12.2803H36.1641C36.607 12.2803 36.9023 12.5756 36.9023 13.0186C36.9023 13.4615 36.607 13.7568 36.1641 13.7568Z" fill="#2281C4"/>
<path d="M14.8723 22.9531H6.01288C5.56991 22.9531 5.2746 22.6578 5.2746 22.2148C5.2746 21.7719 5.56991 21.4766 6.01288 21.4766H14.8723C15.3152 21.4766 15.6105 21.7719 15.6105 22.2148C15.6105 22.6578 15.3152 22.9531 14.8723 22.9531Z" fill="#2281C4"/>
<path d="M32.4727 6.22656H23.6133C23.1703 6.22656 22.875 5.93125 22.875 5.48828C22.875 5.04531 23.1703 4.75 23.6133 4.75H32.4727C32.9156 4.75 33.2109 5.04531 33.2109 5.48828C33.2109 5.93125 32.9156 6.22656 32.4727 6.22656Z" fill="#2281C4"/>
<path d="M8.81835 26.7188H6.01288C5.56991 26.7188 5.2746 26.4234 5.2746 25.9805C5.2746 25.5375 5.56991 25.2422 6.01288 25.2422H8.81835C9.26132 25.2422 9.55663 25.5375 9.55663 25.9805C9.55663 26.4234 9.26132 26.7188 8.81835 26.7188Z" fill="#2281C4"/>
<path d="M26.4188 9.99121H23.6133C23.1703 9.99121 22.875 9.6959 22.875 9.25293C22.875 8.80996 23.1703 8.51465 23.6133 8.51465H26.4188C26.8617 8.51465 27.157 8.80996 27.157 9.25293C27.157 9.6959 26.8617 9.99121 26.4188 9.99121Z" fill="#2281C4"/>
<path d="M18.5637 26.7188H11.9191C11.4762 26.7188 11.1808 26.4234 11.1808 25.9805C11.1808 25.5375 11.4762 25.2422 11.9191 25.2422H18.5637C19.0066 25.2422 19.3019 25.5375 19.3019 25.9805C19.3019 26.4234 19.0066 26.7188 18.5637 26.7188Z" fill="#2281C4"/>
<path d="M36.1641 9.99121H29.5195C29.0766 9.99121 28.7812 9.6959 28.7812 9.25293C28.7812 8.80996 29.0766 8.51465 29.5195 8.51465H36.1641C36.607 8.51465 36.9023 8.80996 36.9023 9.25293C36.9023 9.6959 36.607 9.99121 36.1641 9.99121Z" fill="#2281C4"/>
<path d="M8.81835 34.249H6.01288C5.56991 34.249 5.2746 33.9537 5.2746 33.5107C5.2746 33.0678 5.56991 32.7725 6.01288 32.7725H8.81835C9.26132 32.7725 9.55663 33.0678 9.55663 33.5107C9.55663 33.9537 9.26132 34.249 8.81835 34.249Z" fill="#2281C4"/>
<path d="M26.4188 17.5215H23.6133C23.1703 17.5215 22.875 17.2262 22.875 16.7832C22.875 16.3402 23.1703 16.0449 23.6133 16.0449H26.4188C26.8617 16.0449 27.157 16.3402 27.157 16.7832C27.157 17.2262 26.8617 17.5215 26.4188 17.5215Z" fill="#2281C4"/>
<path d="M18.5637 34.249H11.9191C11.4762 34.249 11.1808 33.9537 11.1808 33.5107C11.1808 33.0678 11.4762 32.7725 11.9191 32.7725H18.5637C19.0066 32.7725 19.3019 33.0678 19.3019 33.5107C19.3019 33.9537 19.0066 34.249 18.5637 34.249Z" fill="#2281C4"/>
<path d="M36.1641 17.5215H29.5195C29.0766 17.5215 28.7812 17.2262 28.7812 16.7832C28.7812 16.3402 29.0766 16.0449 29.5195 16.0449H36.1641C36.607 16.0449 36.9023 16.3402 36.9023 16.7832C36.9023 17.2262 36.607 17.5215 36.1641 17.5215Z" fill="#2281C4"/>
<path d="M32.6648 25.9066C32.5172 25.9066 32.2957 25.8328 32.2218 25.759L27.866 22.2152H20.557C19.4496 22.2152 18.5636 21.3293 18.4898 20.2219V2.50313C18.4898 1.3957 19.3757 0.509766 20.557 0.509766H39.457C40.5644 0.509766 41.4504 1.3957 41.4504 2.50313V20.2219C41.4504 21.3293 40.5644 22.2152 39.457 22.2152H33.4031V25.1684C33.4031 25.4637 33.2554 25.6852 32.9601 25.8328C32.8863 25.9066 32.7386 25.9066 32.6648 25.9066ZM20.557 1.98633C20.2617 1.98633 20.0402 2.20781 19.9664 2.50313V20.2219C19.9664 20.5172 20.1879 20.7387 20.557 20.7387H28.1613C28.3089 20.7387 28.5304 20.8125 28.6043 20.8863L31.9265 23.5441V21.4031C31.9265 20.9602 32.2218 20.6648 32.6648 20.6648H39.457C39.7523 20.6648 39.9738 20.4434 39.9738 20.148V2.4293C39.9738 2.13398 39.7523 1.9125 39.457 1.9125H20.557V1.98633Z" fill="#2281C4"/>
<path d="M13.9863 42.0014C13.8387 42.0014 13.6172 41.9275 13.5433 41.8537L9.18749 38.31H2.54296C1.43553 38.31 0.549597 37.424 0.475769 36.3166V18.5979C0.475769 17.4904 1.43553 16.6045 2.54296 16.6045H19.2281C19.6711 16.6045 19.9664 16.8998 19.9664 17.3428C19.9664 17.7857 19.6711 18.0811 19.2281 18.0811H2.54296C2.24764 18.0811 2.02616 18.3025 1.95233 18.5979V36.3166C1.95233 36.6119 2.17382 36.8334 2.54296 36.8334H9.40897C9.55663 36.8334 9.77811 36.9072 9.85194 36.9811L13.1742 39.7127V37.5717C13.1742 37.1287 13.4695 36.8334 13.9125 36.8334H21.443C21.7383 36.8334 21.9598 36.6119 22.0336 36.3166V22.5107C22.0336 22.0678 22.3289 21.7725 22.7719 21.7725C23.2148 21.7725 23.5101 22.0678 23.5101 22.5107V36.3166C23.5101 37.424 22.6242 38.31 21.443 38.31H14.6508V41.2631C14.6508 41.5584 14.5031 41.7799 14.2078 41.9275C14.2078 41.9275 14.0601 42.0014 13.9863 42.0014Z" fill="#2281C4"/>
</svg>
`

export const YTDGraphIcon = `<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.5" d="M10.1328 25.2344C15.3752 25.2344 19.625 20.9846 19.625 15.7422C19.625 10.4998 15.3752 6.25 10.1328 6.25C4.89042 6.25 0.640625 10.4998 0.640625 15.7422C0.640625 20.9846 4.89042 25.2344 10.1328 25.2344Z" fill="#40BFFF"/>
<path d="M34.1094 34.4453H2.89062C2.46875 34.4453 2.1875 34.1641 2.1875 33.7422V15.8828C2.1875 15.6016 2.32812 15.3906 2.53906 15.25L7.74219 12.2265C8.09375 12.0156 8.51562 12.1562 8.72656 12.5078C8.9375 12.8594 8.79688 13.2812 8.44531 13.4922L3.59375 16.3047V33.0391H33.4766V16.3047L28.625 13.4922C28.2734 13.2812 28.2031 12.8594 28.4141 12.5078C28.625 12.1562 29.0469 12.0859 29.3984 12.2265L34.6016 15.25C34.8125 15.3906 34.9531 15.6016 34.9531 15.8828V33.6719C34.8828 34.0937 34.5312 34.4453 34.1094 34.4453C34.1797 34.4453 34.1094 34.4453 34.1094 34.4453Z" fill="#2281C4"/>
<path d="M28.9062 19.3984C28.4844 19.3984 28.2031 19.1172 28.2031 18.6953V4.35156H23.2812C22.8594 4.35156 22.5781 4.07031 22.5781 3.64844C22.5781 3.22656 22.8594 2.94531 23.2812 2.94531H28.9062C29.3281 2.94531 29.6094 3.22656 29.6094 3.64844V18.6953C29.6094 19.1172 29.3281 19.3984 28.9062 19.3984Z" fill="#2281C4"/>
<path d="M8.09375 19.5391C7.67187 19.5391 7.39062 19.2578 7.39062 18.8359V3.64844C7.39062 3.22656 7.67187 2.94531 8.09375 2.94531H13.7188C14.1406 2.94531 14.4219 3.22656 14.4219 3.64844C14.4219 4.07031 14.1406 4.35156 13.7188 4.35156H8.79688V18.8359C8.79688 19.1875 8.44531 19.5391 8.09375 19.5391Z" fill="#2281C4"/>
<path d="M34.1094 34.4453C33.9687 34.4453 33.8984 34.4453 33.7578 34.375L2.53903 16.5157C2.18747 16.3047 2.11716 15.8828 2.25778 15.5313C2.46872 15.1797 2.8906 15.1094 3.24216 15.25L34.5312 33.0391C34.8828 33.25 34.9531 33.6719 34.8125 34.0235C34.6015 34.3047 34.3906 34.4453 34.1094 34.4453Z" fill="#2281C4"/>
<path d="M18.0781 25.2344C17.7969 25.2344 17.5859 25.0937 17.4453 24.8828C17.2344 24.5312 17.375 24.1094 17.7265 23.9687L33.7578 15.3203C34.1094 15.1094 34.5312 15.25 34.6719 15.6015C34.8828 15.9531 34.7422 16.375 34.3906 16.5859L18.4297 25.1641C18.289 25.2344 18.2187 25.2344 18.0781 25.2344Z" fill="#2281C4"/>
<path d="M19.5547 10.3984H17.3047C16.8828 10.3984 16.6016 10.1172 16.6016 9.69531V8.64062H15.5469C15.125 8.64062 14.8438 8.35938 14.8438 7.9375V5.6875C14.8438 5.26562 15.125 4.98438 15.5469 4.98438H16.6016V3.92969C16.6016 3.50781 16.8828 3.22656 17.3047 3.22656H19.5547C19.9766 3.22656 20.2578 3.50781 20.2578 3.92969V4.98438H21.3125C21.7344 4.98438 22.0156 5.26562 22.0156 5.6875V7.9375C22.0156 8.35938 21.7344 8.64062 21.3125 8.64062H20.2578V9.69531C20.2578 10.1172 19.9062 10.3984 19.5547 10.3984ZM18.0078 8.99219H18.8516V7.9375C18.8516 7.51562 19.1328 7.23438 19.5547 7.23438H20.6094V6.39063H19.5547C19.1328 6.39063 18.8516 6.10938 18.8516 5.6875V4.63281H18.0078V5.6875C18.0078 6.10938 17.7266 6.39063 17.3047 6.39063H16.25V7.23438H17.3047C17.7266 7.23438 18.0078 7.51562 18.0078 7.9375V8.99219Z" fill="#2281C4"/>
<path d="M18.4297 13C14.9844 13 12.1719 10.1875 12.1719 6.8125C12.1719 3.36719 14.9844 0.554688 18.3594 0.554688C21.7344 0.554688 24.6172 3.36719 24.6172 6.74219C24.6172 10.2578 21.875 13 18.4297 13ZM18.4297 1.96094C15.7578 1.96094 13.5781 4.14063 13.5781 6.74219C13.5781 9.34375 15.7578 11.5938 18.3594 11.5938C20.9609 11.5938 23.2109 9.41406 23.2109 6.8125C23.2109 4.14062 21.1016 1.96094 18.4297 1.96094Z" fill="#2281C4"/>
<path d="M23 16.2344H13.8594C13.4375 16.2344 13.1562 15.9531 13.1562 15.5312C13.1562 15.1094 13.4375 14.8281 13.8594 14.8281H23C23.4219 14.8281 23.7031 15.1094 23.7031 15.5312C23.7031 15.9531 23.4219 16.2344 23 16.2344Z" fill="#2281C4"/>
<path d="M22.2969 19.1172H14.5625C14.1406 19.1172 13.8594 18.8359 13.8594 18.4141C13.8594 17.9922 14.1406 17.7109 14.5625 17.7109H22.2969C22.7188 17.7109 23 17.9922 23 18.4141C23 18.8359 22.7188 19.1172 22.2969 19.1172Z" fill="#2281C4"/>
</svg>
`

export const emptyYTD = `<svg width="215" height="131" viewBox="0 0 215 131" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M215 107.5C215 93.3829 212.219 79.404 206.817 66.3615C201.415 53.319 193.496 41.4683 183.514 31.486C173.532 21.5037 161.681 13.5853 148.638 8.18295C135.596 2.78057 121.617 -6.17078e-07 107.5 0C93.3829 6.17078e-07 79.404 2.78057 66.3615 8.18295C53.319 13.5853 41.4683 21.5037 31.486 31.486C21.5037 41.4683 13.5853 53.319 8.18295 66.3615C2.78057 79.404 -1.23416e-06 93.3829 0 107.5L32.25 107.5C32.25 97.618 34.1964 87.8328 37.9781 78.7031C41.7597 69.5733 47.3026 61.2778 54.2902 54.2902C61.2778 47.3026 69.5733 41.7597 78.7031 37.9781C87.8328 34.1964 97.618 32.25 107.5 32.25C117.382 32.25 127.167 34.1964 136.297 37.9781C145.427 41.7597 153.722 47.3026 160.71 54.2902C167.697 61.2778 173.24 69.5733 177.022 78.7031C180.804 87.8328 182.75 97.618 182.75 107.5H215Z" fill="#E3EEF3"/>
<path d="M102.744 91.3636V103H100.619L95.5568 95.6761H95.4716V103H93.0114V91.3636H95.1705L100.193 98.6818H100.295V91.3636H102.744ZM109.8 90.8182L106.05 104.75H103.964L107.714 90.8182H109.8ZM113.017 103H110.381L114.398 91.3636H117.568L121.58 103H118.943L116.028 94.0227H115.938L113.017 103ZM112.852 98.4261H119.08V100.347H112.852V98.4261Z" fill="#293854"/>
<path opacity="0.5" d="M99.1328 71.2344C104.375 71.2344 108.625 66.9846 108.625 61.7422C108.625 56.4998 104.375 52.25 99.1328 52.25C93.8904 52.25 89.6406 56.4998 89.6406 61.7422C89.6406 66.9846 93.8904 71.2344 99.1328 71.2344Z" fill="#40BFFF"/>
<path d="M123.109 80.4453H91.8906C91.4687 80.4453 91.1875 80.1641 91.1875 79.7422V61.8828C91.1875 61.6016 91.3281 61.3906 91.5391 61.25L96.7422 58.2265C97.0937 58.0156 97.5156 58.1562 97.7266 58.5078C97.9375 58.8594 97.7969 59.2812 97.4453 59.4922L92.5937 62.3047V79.0391H122.477V62.3047L117.625 59.4922C117.273 59.2812 117.203 58.8594 117.414 58.5078C117.625 58.1562 118.047 58.0859 118.398 58.2265L123.602 61.25C123.812 61.3906 123.953 61.6016 123.953 61.8828V79.6719C123.883 80.0937 123.531 80.4453 123.109 80.4453C123.109 80.4453 123.18 80.4453 123.109 80.4453Z" fill="#2281C4"/>
<path d="M117.906 65.3984C117.484 65.3984 117.203 65.1172 117.203 64.6953V50.3516H112.281C111.859 50.3516 111.578 50.0703 111.578 49.6484C111.578 49.2266 111.859 48.9453 112.281 48.9453H117.906C118.328 48.9453 118.609 49.2266 118.609 49.6484V64.6953C118.609 65.1172 118.328 65.3984 117.906 65.3984Z" fill="#2281C4"/>
<path d="M97.0938 65.5391C96.6719 65.5391 96.3906 65.2578 96.3906 64.8359V49.6484C96.3906 49.2266 96.6719 48.9453 97.0938 48.9453H102.719C103.141 48.9453 103.422 49.2266 103.422 49.6484C103.422 50.0703 103.141 50.3516 102.719 50.3516H97.7969V64.8359C97.7969 65.1875 97.4453 65.5391 97.0938 65.5391Z" fill="#2281C4"/>
<path d="M123.109 80.4453C122.969 80.4453 122.898 80.4453 122.758 80.375L91.5391 62.5157C91.1875 62.3047 91.1172 61.8828 91.2578 61.5313C91.4688 61.1797 91.8906 61.1094 92.2422 61.25L123.531 79.0391C123.883 79.25 123.953 79.6719 123.813 80.0235C123.602 80.3047 123.391 80.4453 123.109 80.4453Z" fill="#2281C4"/>
<path d="M107.078 71.2344C106.797 71.2344 106.586 71.0937 106.445 70.8828C106.234 70.5312 106.375 70.1094 106.727 69.9687L122.758 61.3203C123.109 61.1094 123.531 61.25 123.672 61.6015C123.883 61.9531 123.742 62.375 123.391 62.5859L107.43 71.1641C107.289 71.2344 107.219 71.2344 107.078 71.2344Z" fill="#2281C4"/>
<path d="M108.555 56.3984H106.305C105.883 56.3984 105.602 56.1172 105.602 55.6953V54.6406H104.547C104.125 54.6406 103.844 54.3594 103.844 53.9375V51.6875C103.844 51.2656 104.125 50.9844 104.547 50.9844H105.602V49.9297C105.602 49.5078 105.883 49.2266 106.305 49.2266H108.555C108.977 49.2266 109.258 49.5078 109.258 49.9297V50.9844H110.312C110.734 50.9844 111.016 51.2656 111.016 51.6875V53.9375C111.016 54.3594 110.734 54.6406 110.312 54.6406H109.258V55.6953C109.258 56.1172 108.906 56.3984 108.555 56.3984ZM107.008 54.9922H107.852V53.9375C107.852 53.5156 108.133 53.2344 108.555 53.2344H109.609V52.3906H108.555C108.133 52.3906 107.852 52.1094 107.852 51.6875V50.6328H107.008V51.6875C107.008 52.1094 106.727 52.3906 106.305 52.3906H105.25V53.2344H106.305C106.727 53.2344 107.008 53.5156 107.008 53.9375V54.9922Z" fill="#2281C4"/>
<path d="M107.43 59C103.984 59 101.172 56.1875 101.172 52.8125C101.172 49.3672 103.984 46.5547 107.359 46.5547C110.734 46.5547 113.617 49.3672 113.617 52.7422C113.617 56.2578 110.875 59 107.43 59ZM107.43 47.9609C104.758 47.9609 102.578 50.1406 102.578 52.7422C102.578 55.3438 104.758 57.5937 107.359 57.5937C109.961 57.5937 112.211 55.4141 112.211 52.8125C112.211 50.1406 110.102 47.9609 107.43 47.9609Z" fill="#2281C4"/>
<path d="M112 62.2344H102.859C102.438 62.2344 102.156 61.9531 102.156 61.5312C102.156 61.1094 102.438 60.8281 102.859 60.8281H112C112.422 60.8281 112.703 61.1094 112.703 61.5312C112.703 61.9531 112.422 62.2344 112 62.2344Z" fill="#2281C4"/>
<path d="M111.297 65.1172H103.563C103.141 65.1172 102.859 64.8359 102.859 64.4141C102.859 63.9922 103.141 63.7109 103.563 63.7109H111.297C111.719 63.7109 112 63.9922 112 64.4141C112 64.8359 111.719 65.1172 111.297 65.1172Z" fill="#2281C4"/>
</svg>
`
