import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC, useContext, useEffect, useRef, useState } from 'react'
import { StyledButton, StyledCreateButtonsContainer, StyledCreateNewUserContainer } from './index.style'

import { Admin } from 'messages'
import { ERoleString } from 'models/enums/role'

import { addSign } from 'pages/hcp/users/list/icons'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { backArrow } from './icons'
import { useNavigate } from 'react-router-dom'
import CreateNewUserTypeRadios from './UserTypeRadios'
import NewOfficeAdmin from './NewOfficeAdmin'
import NewPCPNP from './NewPCPNP'
import { IUser } from './index.models'
import { useDispatch } from 'redux/store'
import { createOfficeManager, createProvider, clearValidations } from 'redux/slices/user'
import { appPath } from 'utilities/appPath'
import { useExternalState } from 'hooks/useExternalState'
import { shallowEqual } from 'react-redux'
import FormSubmitContext from './NewPCPNP/PCPFormContext'
import usePostCustomEvent from 'hooks/usePostCustomEvent'
import { pageTitle, customEvents } from 'appInsights/appInsights'

const CreateUser: FC = () => {
    const [select, setSelect] = useState(ERoleString.OFFICE_ADMIN)
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const [user] = useState<IUser | null>(null)
    const [formData, setFormData] = useState({})
    const [hasBeenSubmited, setHasBeenSubmited] = useState(false)
    const [formValid, setFormValid] = useState(false)

    const commonState = useExternalState()

    useEffect(() => {
        dispatch(clearValidations())
    }, [dispatch, select])

    const backToUsers = () => {
        navigate(appPath('/users'))
    }

    const handleFormData = (data: any) => {
        if (!shallowEqual(data, formData)) {
            setFormData({ ...data, ...commonState.data })
        }
    }
    const newPCPNPEvent = usePostCustomEvent(pageTitle.UsersNewUser, customEvents.NewPCPNP)
    const submitFormData = async () => {
        newPCPNPEvent()
        setHasBeenSubmited(true)
    }

    const onValidationCompleteOfficeAdmin = () => {
        dispatch(createOfficeManager(formData))
        navigate(appPath('/users'))
    }

    // officePhoneNumber, officeAddress, city, state, zipCode

    const onValidationCompleteProvider = () => {
        dispatch(createProvider(formData))
        navigate(appPath('/users'))
    }
    const newOfficeAdminEvent = usePostCustomEvent(pageTitle.UsersNewUser, customEvents.NewOfficeAdmin)
    const submitOfficeAdminFormData = async () => {
        newOfficeAdminEvent()
        setHasBeenSubmited(true)
        setTimeout(() => {
            setHasBeenSubmited(false)
        }, 500)
    }

    const handleNewOfficeAdminFormData = (data: any) => {
        setFormData({ ...data, ...commonState.data })
    }

    const submitRef = useRef<boolean>(false)

    return (
        <StyledCreateNewUserContainer>
            <Typography type={TYPOGRAPHY_TYPES.h1}>Create New User</Typography>
            <Typography type={TYPOGRAPHY_TYPES.h2}>User Information</Typography>

            <CreateNewUserTypeRadios onSelectedUserType={setSelect} selectedValue={select} />

            {select === ERoleString.OFFICE_ADMIN && (
                <NewOfficeAdmin
                    hasNewUserDetail={true}
                    onFormDataOutput={handleNewOfficeAdminFormData}
                    formSubmitted={hasBeenSubmited}
                    onSetValidForm={setFormValid}
                    onValidationComplete={onValidationCompleteOfficeAdmin}
                    commonState={commonState}
                />
            )}
            {select === ERoleString.PCP_NP && (
                <NewPCPNP
                    creatingANewUser={true}
                    userDetails={user}
                    resetHasBeenSubmitted={() => setHasBeenSubmited(false)}
                    formSubmitted={hasBeenSubmited}
                    commonState={commonState}
                />
            )}

            <StyledCreateButtonsContainer>
                <StyledButton onClick={() => backToUsers()} secondary>
                    <CustomSvgIcon iconSet={{ icon: backArrow }} svg></CustomSvgIcon>
                    {Admin.back}
                </StyledButton>
                <StyledButton
                    onClick={() =>
                        select === ERoleString.OFFICE_ADMIN ? submitOfficeAdminFormData() : submitFormData()
                    }
                >
                    <CustomSvgIcon iconSet={{ icon: addSign }} svg></CustomSvgIcon>
                    {Admin.buttonLabel}
                </StyledButton>
            </StyledCreateButtonsContainer>
        </StyledCreateNewUserContainer>
    )
}
export default CreateUser
