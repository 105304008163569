import styled, { css } from 'styled-components'
import { pxToRem, DEVICE } from 'utilities'
import { UI_COLORS } from 'theme'
import { ENavBarItems } from './NavBarConfig'

const { dark_gray, white, grey, light_blue2 } = UI_COLORS

export const StyledNavBarContainer = styled.article`
    background-color: #f7f8fa;
    width: 100%;

    :after {
        z-index: -1;
        position: absolute;
        bottom: 0px;
        content: '';
        display: inline-block;
        width: 100%;
        border-bottom: 2px solid #dee5ef;
    }

    position: relative;
    z-index: 2;

    ${DEVICE.desktop} {
        position: fixed;
    }
`

export const StyledNavBarList = styled.ul<{ isVisible?: boolean }>`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 30px;
    /* border-bottom: 2px solid #dee5ef; */

    ${(props) => {
        if (props.isVisible) {
            return css`
                display: none;
            `
        }
    }}

    ${DEVICE.desktop} {
        flex-direction: row;
        display: flex;
    }
`

export const StyledNavBarListItem = styled.li<{ isActive?: boolean; selectedLabel?: any }>`
    height: 80px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #73839c;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 0 25px;

    figure {
        width: 22px;
        margin: 0;
        margin-right: 10px;
    }

    cursor: pointer;

    ${(props) => {
        if (props.isActive && props.selectedLabel !== ENavBarItems.BENEFITINFO) {
            return css`
                color: ${light_blue2};
                font-weight: 600;
                border-bottom: 2px solid ${light_blue2};

                figure {
                    svg {
                        path {
                            fill: ${light_blue2};
                        }
                        g {
                            path {
                                fill: none;
                                stroke: ${light_blue2};
                            }
                        }
                    }
                }
            `
        } else if (props.isActive && props.selectedLabel === ENavBarItems.BENEFITINFO) {
            return css`
                color: ${light_blue2};
                font-weight: 600;
                border-bottom: 2px solid ${light_blue2};
                figure {
                    svg {
                        path {
                            stroke: ${light_blue2};
                        }
                    }
                }
            `
        }
    }}
`

export const StyledMenuButton = styled.button`
    position: absolute;
    top: 16px;
    right: 15px;

    border: 0;
    background-color: transparent;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #73839c;

    ${DEVICE.desktop} {
        display: none;
    }
`

export const StyledMenuButtonContainer = styled.article`
    width: 100%;
    display: flex;
    background-color: #f5f5f5;
    /* background-color: red; */

    ${DEVICE.desktop} {
        display: none;
    }
`

export const StyledUserInfoHeader = styled.article`
    padding-top: 43px;
    padding-left: 30px;
    margin-bottom: 10px;

    display: flex;
    flex-direction: row;

    h2 {
        margin: 0;
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 31px;
        letter-spacing: -0.01em;
        color: #293854;
    }
`
