import { LogLevel } from '@azure/msal-browser'

const loggerCallback = (level: LogLevel, message: string, containsPii: boolean) => {
    if (containsPii) {
        return
    }
    switch (level) {
        case LogLevel.Error:
            console.error(message)
            return
        case LogLevel.Info:
            // console.info(message)
            return
        case LogLevel.Verbose:
            console.debug(message)
            return
        case LogLevel.Warning:
            console.warn(message)
            return
    }
}

const aadMsalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AAD_CLIENT_ID as string,
        authority: process.env.REACT_APP_AAD_AUTHORITY,
        redirectUri: process.env.REACT_APP_AAD_REDIRECT_URI,
        postLogoutRedirectUri: process.env.REACT_APP_AAD_POST_LOGOUT_REDIRECT_URI,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false
    },
    system: {
        loggerOptions: {
            loggerCallback: loggerCallback
        }
    }
}

const aadLoginRequest = {
    scopes: (process.env.REACT_APP_AAD_SCOPES as string).split(',')
}

const b2cMsalConfig = {
    auth: {
        clientId: process.env.REACT_APP_B2C_CLIENT_ID as string,
        authority: process.env.REACT_APP_B2C_AUTHORITY,
        knownAuthorities: [process.env.REACT_APP_B2C_KNOWN_AUTHORITY],
        redirectUri: process.env.REACT_APP_B2C_REDIRECT_URI,
        postLogoutRedirectUri: process.env.REACT_APP_B2C_POST_LOGOUT_REDIRECT_URI,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false
    },
    system: {
        loggerOptions: {
            loggerCallback: loggerCallback
        }
    }
}

const b2cLoginRequest = {
    scopes: (process.env.REACT_APP_B2C_SCOPES as string).split(',')
}

const b2cPasswordResetMsalConfig = {
    auth: {
        ...b2cMsalConfig.auth,
        authority: process.env.REACT_APP_B2C_PASSWORD_RESET_AUTHORITY
    },
    cache: b2cMsalConfig.cache,
    system: b2cMsalConfig.system
}

function getConfig(adConfig: any, b2cConfig: any): any {
    switch (process.env.REACT_APP_IDENTITY_PROVIDER) {
        case 'AAD':
            return adConfig
        case 'B2C':
            return b2cConfig
        default:
            throw new Error(`'${process.env.REACT_APP_IDENTITY_PROVIDER}' is not a recognized identity provider.`)
    }
}

export const getMsalConfig = () => getConfig(aadMsalConfig, b2cMsalConfig)

export const getLoginRequest = () => getConfig(aadLoginRequest, b2cLoginRequest)

export const getScopes = () => getConfig(aadLoginRequest.scopes, b2cLoginRequest.scopes)

export const getB2CPasswordResetMsalConfig = () => b2cPasswordResetMsalConfig
