export const addSign = `<svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.3333 6.83317H7.33334V11.8332H5.66667V6.83317H0.666672V5.1665H5.66667V0.166504H7.33334V5.1665H12.3333V6.83317Z" fill="white"/>
</svg>
`
export const idCard = `<svg width="126" height="125" viewBox="0 0 126 125" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M75.75 124.5C94.3896 124.5 109.5 109.39 109.5 90.75C109.5 72.1104 94.3896 57 75.75 57C57.1104 57 42 72.1104 42 90.75C42 109.39 57.1104 124.5 75.75 124.5Z" fill="#F6F9FD"/>
<path d="M122.25 107.5H3.75C1.75 107.5 0.25 106 0.25 104V20.5C0.25 18.5 1.75 17 3.75 17H52.25C53.75 17 54.75 18 54.75 19.5C54.75 21 53.75 22 52.25 22H5.25V102.5H121V22H74.75C73.25 22 72.25 21 72.25 19.5C72.25 18 73.25 17 74.75 17H122.5C124.5 17 126 18.5 126 20.5V104C125.75 106 124.25 107.5 122.25 107.5Z" fill="#A4B0C0"/>
<path d="M117.25 115H8.75C6.75 115 5.25 113.5 5.25 111.5V105C5.25 103.5 6.25 102.5 7.75 102.5C9.25 102.5 10.25 103.5 10.25 105V110H116V105C116 103.5 117 102.5 118.5 102.5C120 102.5 121 103.5 121 105V111.5C120.75 113.5 119.25 115 117.25 115Z" fill="#A4B0C0"/>
<path d="M108.75 63.5H78.75C77.25 63.5 76.25 62.5 76.25 61C76.25 59.5 77.25 58.5 78.75 58.5H108.75C110.25 58.5 111.25 59.5 111.25 61C111.25 62.5 110 63.5 108.75 63.5Z" fill="#A4B0C0"/>
<path d="M101.25 51.75H78.5C77 51.75 76 50.75 76 49.25C76 47.75 77 46.75 78.5 46.75H101.25C102.75 46.75 103.75 47.75 103.75 49.25C103.75 50.75 102.75 51.75 101.25 51.75Z" fill="#A4B0C0"/>
<path d="M40.5 90.75C26.5 90.75 15 79.25 15.25 65.25C15.25 51.25 26.5 40 40.5 40C54.5 40 65.75 51.5 65.75 65.5C66 79.5 54.5 90.75 40.5 90.75ZM40.5 45C29.25 45 20 54.25 20 65.5C20 76.75 29.25 86 40.5 86C51.75 86 61 76.75 61 65.5C61 54 51.75 45 40.5 45Z" fill="#A4B0C0"/>
<path d="M40.5 68.75C35.5 68.75 31.25 64.5 31.25 59.5C31.25 54.5 35.5 50.25 40.5 50.25C45.5 50.25 49.75 54.5 49.75 59.5C49.75 64.5 45.5 68.75 40.5 68.75ZM40.5 55.25C38.25 55.25 36.25 57.25 36.25 59.5C36.25 61.75 38.25 63.75 40.5 63.75C42.75 63.75 44.75 61.75 44.75 59.5C44.75 57 42.75 55.25 40.5 55.25Z" fill="#A4B0C0"/>
<path d="M51.75 86.25C50.25 86.25 49.25 85.25 49.25 83.75V80.5C49.25 75.75 45.5 72 40.75 72C39.25 72 38.25 71 38.25 69.5C38.25 68 39.25 67 40.75 67C48.25 67 54.25 73 54.25 80.5V83.75C54 85.25 53 86.25 51.75 86.25Z" fill="#A4B0C0"/>
<path d="M29.5 87.75C28 87.75 27 86.75 27 85.25V80.75C27 73.25 33 67.25 40.5 67.25C42 67.25 43 68.25 43 69.75C43 71.25 42 72.25 40.5 72.25C35.75 72.25 32 76 32 80.75V85.25C32 86.5 30.75 87.5 29.5 87.75Z" fill="#A4B0C0"/>
<path d="M108.75 87.25H78.75C77.25 87.25 76.25 86.25 76.25 84.75V72.25C76.25 70.75 77.25 69.75 78.75 69.75H108.75C110.25 69.75 111.25 70.75 111.25 72.25V84.75C111.25 86 110 87.25 108.75 87.25ZM81.25 82.25H106.25V74.75H81.25V82.25Z" fill="#A4B0C0"/>
<path d="M74.75 31.5H52.25C50.75 31.5 49.75 30.5 49.75 29V3C49.75 1.5 50.75 0.5 52.25 0.5H74.75C76.25 0.5 77.25 1.5 77.25 3V29C77.25 30.5 76 31.5 74.75 31.5ZM54.75 26.5H72.25V5.5H54.75V26.5Z" fill="#A4B0C0"/>
<path d="M63.5 16.5C62 16.5 61 15.5 61 14V11.25C61 9.75 62 8.75 63.5 8.75C65 8.75 66 9.75 66 11.25V14C66 15.5 64.75 16.5 63.5 16.5Z" fill="#A4B0C0"/>
</svg>
`
export const searchIcon = `<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.2083 10.8333H11.4842L11.2275 10.5858C12.1258 9.54083 12.6667 8.18417 12.6667 6.70833C12.6667 3.4175 9.99917 0.75 6.70833 0.75C3.4175 0.75 0.75 3.4175 0.75 6.70833C0.75 9.99917 3.4175 12.6667 6.70833 12.6667C8.18417 12.6667 9.54083 12.1258 10.5858 11.2275L10.8333 11.4842V12.2083L15.4167 16.7825L16.7825 15.4167L12.2083 10.8333ZM6.70833 10.8333C4.42583 10.8333 2.58333 8.99083 2.58333 6.70833C2.58333 4.42583 4.42583 2.58333 6.70833 2.58333C8.99083 2.58333 10.8333 4.42583 10.8333 6.70833C10.8333 8.99083 8.99083 10.8333 6.70833 10.8333Z" fill="#73839C"/>
</svg>
`

export const emptySearchIcon = `<svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M92.75 144.5C111.39 144.5 126.5 129.39 126.5 110.75C126.5 92.1104 111.39 77 92.75 77C74.1104 77 59 92.1104 59 110.75C59 129.39 74.1104 144.5 92.75 144.5Z" fill="#F6F9FD"/>
<path d="M139.25 127.5H20.75C18.75 127.5 17.25 126 17.25 124V40.5C17.25 38.5 18.75 37 20.75 37H69.25C70.75 37 71.75 38 71.75 39.5C71.75 41 70.75 42 69.25 42H22.25V122.5H138V42H91.75C90.25 42 89.25 41 89.25 39.5C89.25 38 90.25 37 91.75 37H139.5C141.5 37 143 38.5 143 40.5V124C142.75 126 141.25 127.5 139.25 127.5Z" fill="#A4B0C0"/>
<path d="M134.25 135H25.75C23.75 135 22.25 133.5 22.25 131.5V125C22.25 123.5 23.25 122.5 24.75 122.5C26.25 122.5 27.25 123.5 27.25 125V130H133V125C133 123.5 134 122.5 135.5 122.5C137 122.5 138 123.5 138 125V131.5C137.75 133.5 136.25 135 134.25 135Z" fill="#A4B0C0"/>
<path d="M79.2792 103.712L60.8449 90.38C59.6295 89.5009 59.4052 88.1046 60.2842 86.8892C61.1633 85.6737 62.5596 85.4494 63.775 86.3285L82.2093 99.6603C83.4248 100.539 83.6491 101.936 82.77 103.151C81.891 104.367 80.4947 104.591 79.2792 103.712Z" fill="#A4B0C0"/>
<path d="M43.4457 94.6986C33.4992 92.1987 27.3726 82.0139 30.0381 72.1595C32.5261 62.2605 42.518 56.3147 52.4645 58.8146C62.4109 61.3144 68.36 71.4546 65.872 81.3536C63.5617 91.2973 53.3922 97.1984 43.4457 94.6986ZM51.5759 62.3499C43.5832 60.3411 35.3676 65.2299 33.3684 73.1845C31.3692 81.139 36.2971 89.3312 44.2898 91.34C52.2825 93.3488 60.4981 88.46 62.4973 80.5055C64.541 72.3741 59.5686 64.3588 51.5759 62.3499Z" fill="#A4B0C0"/>
<path d="M91.75 51.5H69.25C67.75 51.5 66.75 50.5 66.75 49V23C66.75 21.5 67.75 20.5 69.25 20.5H91.75C93.25 20.5 94.25 21.5 94.25 23V49C94.25 50.5 93 51.5 91.75 51.5ZM71.75 46.5H89.25V25.5H71.75V46.5Z" fill="#A4B0C0"/>
<path d="M80.5 36.5C79 36.5 78 35.5 78 34V31.25C78 29.75 79 28.75 80.5 28.75C82 28.75 83 29.75 83 31.25V34C83 35.5 81.75 36.5 80.5 36.5Z" fill="#A4B0C0"/>
<path d="M126.75 80.75H96.75C95.25 80.75 94.25 79.75 94.25 78.25C94.25 76.75 95.25 75.75 96.75 75.75H126.75C128.25 75.75 129.25 76.75 129.25 78.25C129.25 79.75 128 80.75 126.75 80.75Z" fill="#A4B0C0"/>
<path d="M119.25 69H96.5C95 69 94 68 94 66.5C94 65 95 64 96.5 64H119.25C120.75 64 121.75 65 121.75 66.5C121.75 68 120.75 69 119.25 69Z" fill="#A4B0C0"/>
<path d="M126.75 104.5H96.75C95.25 104.5 94.25 103.5 94.25 102V89.5C94.25 88 95.25 87 96.75 87H126.75C128.25 87 129.25 88 129.25 89.5V102C129.25 103.25 128 104.5 126.75 104.5ZM99.25 99.5H124.25V92H99.25V99.5Z" fill="#A4B0C0"/>
</svg>
`
