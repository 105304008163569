import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import * as React from 'react'
import { useEffect, useState } from 'react'
import {
    StyledTopOppsContainer,
    StyledTopOppsGraphButtons,
    StyledTopOppsGraphLegends,
    StyledTopOppsIncentiveButton,
    StyledTopOppsLeyends,
    StyledTopOppsMembersButton,
    StyledTopOppsTitle,
    StyledTopOppsTitleAndLeyends,
    StyledTopOppsTitleContainer,
    StyledViewMoreButton,
    StyledViewMoreButtonContainer,
    StyledViewMoreButtonWrap,
    StyledViewMoreSection,
    VerticalSeparator
} from './index.style'
import { ITopIncentive } from 'pages/hcp/users/create/index.models'
import IncentivesChart from './IncentivesChart'
import MembersChart from './MembersChart'
import AnnualWellnessIncentivesChart from './AnnualWellnessIncentivesChart'
import AnnualWellnessMembersChart from './AnnualWellnessMembersChart'
import { IIncentivesData, IMaxYValues, IMembersData } from './index.model'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { viewMoreArrow } from './MembersChart/icons'
import { StyledMemberOutreachEmptyStateContainer } from '../MemberOutreach/index.style'
import { useSelector } from 'react-redux'
import { IncentiveState } from 'redux/slices/incentive'
import Spinner from 'components/Spinner'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import usePostCustomEvent from 'hooks/usePostCustomEvent'

interface ITopOppsProps {
    npi?: string
}

const TopOpps: React.FunctionComponent<ITopOppsProps> = ({ npi }) => {
    const [isIncentivesActive, setIsIncentivesActive] = useState(true)
    const [isMembersActive, setIsMembersActive] = useState(false)
    const [incentives, setIncentives] = useState<ITopIncentive[]>(null)
    const [isViewingAllTopOpps, setIsViewingAllTopOpps] = useState<boolean>(false)
    const [maxIncentiveValue, setMaxIncentiveValue] = useState<IMaxYValues>({ maxIncentive: 0, maxMembers: 0 })

    const incentivesData: ITopIncentive[] = useSelector(
        (state: { incentive: IncentiveState }) => state.incentive?.topIncentives
    )
    const TOByIncentiveEvent = usePostCustomEvent(pageTitle.Incentives, customEvents.TopOpportunitiesByIncentive)
    const handleIncentivesButton = () => {
        TOByIncentiveEvent()

        if (isIncentivesActive) {
            return
        } else {
            setIsIncentivesActive(true)
            setIsMembersActive(false)
        }
    }

    const roundMaxTickForYAxis = (total: number): number => {
        // start by getting the tick value from a fourth of the total
        let tickValue = total / 4.0

        // reduce tick value until its below 10
        let decimalCount = 1
        while (tickValue > 10.0) {
            tickValue = tickValue / 10.0
            decimalCount *= 10
        }

        // round up the tick value
        tickValue = Math.ceil(tickValue * 2.0) / 2.0

        // use the tick value to calculate the max value for 4 ticks
        tickValue = tickValue * decimalCount * 4.0

        return tickValue
    }

    const handleSortingMembersData = (currentIncentives: ITopIncentive[]) => {
        const sortedMemberIncentives = [...currentIncentives].sort(
            (a: IMembersData, b: IMembersData) => b.totalMembersNotEarned - a.totalMembersNotEarned
        )
        return sortedMemberIncentives
    }

    const handleSortingIncentivesData = (currentIncentives: ITopIncentive[]) => {
        const sortedIncentives = [...currentIncentives].sort(
            (a: IIncentivesData, b: IIncentivesData) => b.totalIncentiveRemaining - a.totalIncentiveRemaining
        )
        return sortedIncentives
    }

    const calculateMaxMemberValue = (filteredIncentives: ITopIncentive[]) => {
        const maxMembers = Math.max(
            ...filteredIncentives.map(
                (filterIncentive) => filterIncentive.totalMembersEarned + filterIncentive.totalMembersNotEarned
            )
        )
        const maxMemberY = roundMaxTickForYAxis(maxMembers)
        return maxMemberY
    }

    const calculateMaxIncentiveValue = (filteredIncentives: ITopIncentive[]) => {
        const maxIncentive = Math.max(
            ...filteredIncentives.map(
                (filterIncentive) => filterIncentive.totalIncentiveEarned + filterIncentive.totalIncentiveRemaining
            )
        )
        const maxIncentiveY = roundMaxTickForYAxis(maxIncentive)
        return maxIncentiveY
    }

    const [sortedForNpi, setSortedForNpi] = useState<string>('')
    const [sortedForIsMemberActive, setSortedForIsMemberActive] = useState<boolean>(false)

    const sortIncentivesData = (currentIncentives: ITopIncentive[]) => {
        const filteredIncentives =
            currentIncentives == null
                ? null
                : currentIncentives?.filter((incentive) => !incentive.incentive.startsWith('Annual Wellness Visit'))

        if (filteredIncentives && filteredIncentives.length > 0) {
            if (isMembersActive) {
                const maxMemberY = calculateMaxMemberValue(filteredIncentives)
                setMaxIncentiveValue({ ...maxIncentiveValue, maxMembers: maxMemberY })
                const sortedMemberIncentives = handleSortingMembersData(currentIncentives)
                setIncentives(sortedMemberIncentives)
                setSortedForNpi(sortedMemberIncentives[0]?.npi)
                setSortedForIsMemberActive(isMembersActive)
            } else {
                const maxIncentiveY = calculateMaxIncentiveValue(filteredIncentives)
                setMaxIncentiveValue({ ...maxIncentiveValue, maxIncentive: maxIncentiveY })
                const sortedIncentives = handleSortingIncentivesData(currentIncentives)
                setIncentives(sortedIncentives)
                setSortedForNpi(sortedIncentives[0]?.npi)
                setSortedForIsMemberActive(isMembersActive)
            }
        }
    }

    useEffect(() => {
        if (incentivesData?.length === 0 || incentivesData?.length == undefined) {
            setSortedForNpi(null)
            return
        }
        if (sortedForNpi === incentivesData[0]?.npi && sortedForIsMemberActive === isMembersActive) {
            return // already sorted
        }
        sortIncentivesData([...incentivesData])
    }, [isMembersActive, incentivesData])

    const TOViewMoreEvent = usePostCustomEvent(pageTitle.Incentives, customEvents.TopOpportunitiesViewMore)
    useEffect(() => {
        if (isViewingAllTopOpps === true) {
            TOViewMoreEvent()
        }
    }, [isViewingAllTopOpps])

    const TOByPatientsEvent = usePostCustomEvent(pageTitle.Incentives, customEvents.TopOpportunitiesByPatients)
    const handleMembersButton = () => {
        TOByPatientsEvent()
        if (isMembersActive) {
            return
        } else {
            setIsMembersActive(true)
            setIsIncentivesActive(false)
        }
    }

    const getGroupedArraysBy = (n: number, topIncentiveList: ITopIncentive[]): ITopIncentive[][] => {
        let incentivesToSplice = [...topIncentiveList]
        // remove first 4 since its handled first
        incentivesToSplice.splice(0, 5)
        const segments = []
        while (incentivesToSplice?.length > n) {
            const nextIncentiveSegment = incentivesToSplice.splice(0, n)
            segments.push([...nextIncentiveSegment])
        }

        if (incentivesToSplice.length > 0) {
            segments.push(incentivesToSplice)
        }

        return segments
    }

    const isIncentivesLoading = () => {
        // we want to indicate loading when either
        // 1. incentives is null
        // 2. incentivesData is null (because it was cleared out)
        // 3. and incentivesData exists, but it is not empty
        //   if incentiveData is not null, but empty, then we want to allow react to show the empty state
        return (incentives == null || incentivesData == null) && incentivesData?.length !== 0
    }

    return (
        <StyledTopOppsContainer>
            {isIncentivesLoading() ? (
                <div style={{ position: 'relative', top: '-250px' }}>
                    <Spinner />
                </div>
            ) : (
                <StyledTopOppsTitleContainer>
                    <>
                        <StyledTopOppsGraphLegends>
                            <StyledTopOppsTitleAndLeyends>
                                <StyledTopOppsTitle>
                                    <Typography type={TYPOGRAPHY_TYPES.p}>Top Opportunities</Typography>
                                </StyledTopOppsTitle>
                                <StyledTopOppsLeyends>
                                    <span />
                                    <Typography type={TYPOGRAPHY_TYPES.p}>Remaining</Typography>
                                    <span />
                                    <Typography type={TYPOGRAPHY_TYPES.p}>Closed</Typography>
                                    <span />
                                    <Typography type={TYPOGRAPHY_TYPES.p}>Paid</Typography>
                                </StyledTopOppsLeyends>
                            </StyledTopOppsTitleAndLeyends>
                        </StyledTopOppsGraphLegends>

                        <StyledTopOppsGraphButtons>
                            <StyledTopOppsIncentiveButton
                                onClick={handleIncentivesButton}
                                isActive={isIncentivesActive}
                            >
                                Incentive
                            </StyledTopOppsIncentiveButton>
                            <StyledTopOppsMembersButton onClick={handleMembersButton} isActive={isMembersActive}>
                                Patients
                            </StyledTopOppsMembersButton>
                        </StyledTopOppsGraphButtons>
                    </>
                </StyledTopOppsTitleContainer>
            )}

            {incentivesData && incentivesData?.length === 0 ? (
                <StyledMemberOutreachEmptyStateContainer style={{ height: 200 }}>
                    <Typography type={TYPOGRAPHY_TYPES.h3}>Data not available at the moment</Typography>
                    <Typography type={TYPOGRAPHY_TYPES.p}>Please check back later</Typography>
                </StyledMemberOutreachEmptyStateContainer>
            ) : (
                <>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {isIncentivesActive && incentives?.length > 0 && (
                            <AnnualWellnessIncentivesChart
                                incentivesData={
                                    incentives.filter((item) => item.incentive === 'Annual Wellness Visit').length >
                                        0 && incentives.filter((item) => item.incentive === 'Annual Wellness Visit')[0]
                                }
                                npi={npi}
                            />
                        )}
                        {isIncentivesActive && <VerticalSeparator />}
                        {isIncentivesActive && incentives?.length > 0 && (
                            <IncentivesChart
                                incentivesData={incentives.slice(0, 5)}
                                maxIncentive={maxIncentiveValue.maxIncentive}
                                npi={npi}
                            />
                        )}
                    </div>

                    {isViewingAllTopOpps &&
                        incentives?.length > 0 &&
                        getGroupedArraysBy(5, incentives).map((segment) => (
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {isIncentivesActive && (
                                    <IncentivesChart
                                        incentivesData={segment}
                                        maxIncentive={maxIncentiveValue.maxIncentive}
                                        npi={npi}
                                    />
                                )}
                            </div>
                        ))}

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {isMembersActive && (
                            <AnnualWellnessMembersChart
                                membersData={
                                    incentives.filter((item) => item.incentive === 'Annual Wellness Visit').length >
                                        0 && incentives.filter((item) => item.incentive === 'Annual Wellness Visit')[0]
                                }
                                npi={npi}
                            />
                        )}
                        {isMembersActive && <VerticalSeparator />}
                        {isMembersActive && incentives?.length > 0 && (
                            <MembersChart
                                membersData={incentives.slice(0, 5)}
                                maxMember={maxIncentiveValue.maxMembers}
                                npi={npi}
                            />
                        )}
                    </div>
                    {isViewingAllTopOpps &&
                        getGroupedArraysBy(5, incentives).map((segment) => (
                            <StyledViewMoreSection>
                                {isMembersActive && (
                                    <MembersChart
                                        membersData={segment}
                                        maxMember={maxIncentiveValue.maxMembers}
                                        npi={npi}
                                    />
                                )}
                            </StyledViewMoreSection>
                        ))}

                    <StyledViewMoreButtonContainer>
                        <StyledViewMoreButtonWrap
                            onClick={() => setIsViewingAllTopOpps(!isViewingAllTopOpps)}
                            isActive={isViewingAllTopOpps}
                        >
                            <StyledViewMoreButton>
                                {isViewingAllTopOpps ? 'Collapse' : 'View More'}
                            </StyledViewMoreButton>
                            <CustomSvgIcon iconSet={{ icon: viewMoreArrow }} svg></CustomSvgIcon>
                        </StyledViewMoreButtonWrap>
                    </StyledViewMoreButtonContainer>
                </>
            )}
        </StyledTopOppsContainer>
    )
}

export default TopOpps
