import { ILocationData, INPILocationsData, IOfficeAdminProviderInfo } from "pages/hcp/users/create/index.models"

export function handleAddingInitialLocation(npiProviders: INPILocationsData[], npiIndex: number) {
    const npiLocations = npiProviders[npiIndex]
    const locationList = npiLocations.locations
    if (locationList.length === 0) {
        const blankLocationData: ILocationData = {
            locationId: null,
            locationType: 1,
            address: '',
            suite: '',
            city: '',
            state: '',
            zipCode: '',
            officePhoneNumber: '',
            selectedLocationIndex: -1,
            error: false,
            errorMessage: '',
            ezCapLocationId: null
        }
        const newLocationList = [...locationList, blankLocationData]
        npiLocations.locations = newLocationList
    }
}


export const handleAddingNewNPI = (taxIdIndex: number, currentProviderInfo: IOfficeAdminProviderInfo, validFormCallback: (valid:boolean) => void, setProviderInfo: (info: IOfficeAdminProviderInfo) => void) => {
    let temp = { ...currentProviderInfo }
    const updatedProviderList: INPILocationsData[] = [
        ...temp.taxIdProviderGroupings[taxIdIndex].providers,
        { npi: '', locations: [], error: false, errorMessage: '' }
    ]

    validFormCallback(false)
    temp.taxIdProviderGroupings[taxIdIndex].providers = updatedProviderList

    const providers = temp.taxIdProviderGroupings[taxIdIndex].providers
    handleAddingInitialLocation(providers, providers.length - 1)

    setProviderInfo(temp)
}    
