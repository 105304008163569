import styled, { css } from 'styled-components'
import { DEVICE, pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { white } = UI_COLORS

export const StyledActionsContainer = styled.article`
    display: flex;
    flex-direction: column;
    margin-top: ${pxToRem(25)};
    width: 100%;

    label {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        color: black;
    }

    ${DEVICE.tablet} {
        position: absolute;
        bottom: ${pxToRem(30)};
        padding-right: ${pxToRem(70)};
    }
`

export const StyledButtonContainer = styled.article`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
`

export const StyledCloseButton = styled.button<{ color: string }>`
    width: ${pxToRem(120)};
    height: ${pxToRem(50)};
    border-radius: ${pxToRem(4)};
    border: 0px;
    cursor: pointer;

    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};
    color: ${white};

    :active {
        background-color: ${white};
    }

    ${(props) => {
        return css`
            background-color: ${props.color};

            :active {
                color: ${props.color};
                border: 1px solid ${props.color};
            }
        `
    }}
`
