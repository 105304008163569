import { FC, useEffect } from 'react'
import { IDatedBooleanList, IDatedBooleanListItem, ISectionElement } from '../index.models'
import {
    StyledRadioBtnTxtFieldFormContainer,
    StyledTableFormBody,
    StyledTableFormBodyItemRadiosWrapper,
    StyledTableFormHeader,
    StyledTableFormInputContainer,
    StyledTableFormItem,
    StyledResetContainer,
    StyledTableFormBodyItemLeft,
    StyledTableFormBodyItemMiddle,
    StyledTableFormBodyItemRight
} from '../CommentedMultipleChoiceList/index.style'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { performFieldSynchronizationStep } from '../utils'
import { AwvTooltip } from '../AwvTooltip'
import useErrors from 'hooks/useErrors'
import IconButton from '@mui/material/IconButton'
import { Tooltip } from '@mui/material'
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp'
import { useResetValue } from 'hooks/useResetValue'

interface IDatedBooleanListProps {
    name: string
    titleColumnLabel: string
    trueColumnLabel: string
    falseColumnLabel: string
    dateColumnLabel: string
    items: IDatedBooleanListItem[]
}

const titleColumnWidth = 380

const trueColumnWidth = 140

const falseColumnWidth = 140

const dateColumnWidth = 400

const DatedBooleanList: FC<IDatedBooleanListProps> = ({
    name,
    titleColumnLabel,
    trueColumnLabel,
    falseColumnLabel,
    dateColumnLabel,
    items
}) => {
    const { register, control, setValue, getValues, watch } = useFormContext()
    const errors = useErrors()
    const resetValue = useResetValue()
    const { fields, append, remove, move } = useFieldArray({ control, name })

    useEffect(() => {
        performFieldSynchronizationStep(items, fields, append, remove, move)
    }, [items, fields, append, remove, move])

    useEffect(() => {
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i] as any
            if (field.value !== true && field.date) {
                setValue(`${name}.${i}.date`, null)
            }
        }
    }, [fields])

    const labelsByName = items.reduce((acc: any, item: any) => {
        acc[item.name] = item.label
        return acc
    }, {})

    return (
        <StyledRadioBtnTxtFieldFormContainer>
            <StyledTableFormHeader>
                <StyledTableFormItem style={{ width: `${titleColumnWidth}px` }}>{titleColumnLabel}</StyledTableFormItem>
                <StyledTableFormItem style={{ width: `${trueColumnWidth}px` }}>
                    {trueColumnLabel ?? 'Yes'}
                </StyledTableFormItem>
                <StyledTableFormItem style={{ width: `${falseColumnWidth}px` }}>
                    {falseColumnLabel ?? 'No'}
                </StyledTableFormItem>
                <StyledTableFormItem style={{ width: `${dateColumnWidth}px` }}>
                    {dateColumnLabel ?? 'Date'}
                </StyledTableFormItem>
            </StyledTableFormHeader>
            {fields.map((field: any, idx: number) => (
                <AwvTooltip
                    key={`tooltip.DBL.${name}.${idx}`}
                    message={errors.getError(`${name}.${idx}`)}
                    placement="top-start"
                >
                    <StyledTableFormBody key={field.id}>
                        <StyledTableFormBodyItemLeft
                            isInError={errors.getError(`${name}.${idx}`)}
                            style={{ width: `${titleColumnWidth}px` }}
                        >
                            {labelsByName[field.name]}
                        </StyledTableFormBodyItemLeft>
                        <AwvTooltip
                            key={`tooltip.DBL.${name}.${idx}.value`}
                            message={errors.getError(`${name}.${idx}.value`)}
                        >
                            <StyledTableFormBodyItemRadiosWrapper isInError={errors.getError(`${name}.${idx}.value`)}>
                                <StyledTableFormBodyItemMiddle style={{ width: `${trueColumnWidth}px` }}>
                                    <Controller
                                        name={`${name}.${idx}.value`}
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                id={`${name}.${idx}.true`}
                                                {...field}
                                                type="radio"
                                                value="true"
                                                checked={field.value === true}
                                                onChange={() => setValue(`${name}.${idx}.value`, true)}
                                            />
                                        )}
                                    />
                                </StyledTableFormBodyItemMiddle>
                                <StyledTableFormBodyItemMiddle style={{ width: `${falseColumnWidth}px` }}>
                                    <Controller
                                        name={`${name}.${idx}.value`}
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                id={`${name}.${idx}.false`}
                                                {...field}
                                                type="radio"
                                                value="false"
                                                checked={field.value === false}
                                                onChange={() => {
                                                    setValue(`${name}.${idx}.value`, false)
                                                    setValue(`${name}.${idx}.date`, null)
                                                }}
                                            />
                                        )}
                                    />
                                </StyledTableFormBodyItemMiddle>
                            </StyledTableFormBodyItemRadiosWrapper>
                        </AwvTooltip>
                        <StyledTableFormBodyItemRight>
                            <AwvTooltip
                                key={`tooltip_DBL.${name}.${idx}_date`}
                                message={errors.getError(`${name}.${idx}.date`)}
                            >
                                <StyledTableFormInputContainer
                                    isInError={errors.getError(`${name}.${idx}.date`)}
                                    style={{ width: `${dateColumnWidth}px` }}
                                >
                                    <input
                                        type="date"
                                        readOnly={watch(`${name}.${idx}.value`) !== true}
                                        disabled={watch(`${name}.${idx}.value`) !== true}
                                        {...register(`${name}.${idx}.date`)}
                                    />
                                </StyledTableFormInputContainer>
                            </AwvTooltip>
                        </StyledTableFormBodyItemRight>
                        <StyledResetContainer>
                            {getValues(`${name}.${idx}.value`) !== undefined &&
                                getValues(`${name}.${idx}.value`) !== null && (
                                    <Tooltip title="Reset">
                                        <IconButton
                                            onClick={() => {
                                                resetValue(`${name}.${idx}.value`, `${name}.${idx}.date`)
                                            }}
                                        >
                                            <HighlightOffSharpIcon sx={{ color: '#9e9e9e' }} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                        </StyledResetContainer>
                    </StyledTableFormBody>
                </AwvTooltip>
            ))}
        </StyledRadioBtnTxtFieldFormContainer>
    )
}

export const renderDatedBooleanList = (element: ISectionElement, key: string) => {
    const datedBooleanList = element as IDatedBooleanList
    return <DatedBooleanList key={key} {...datedBooleanList} />
}
