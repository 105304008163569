import { FC, useEffect, useState } from 'react'
import { DropDownList } from '../DropDownList'
import { IHccCodeDescriptionPicker, ISectionElement } from '../index.models'
import { useSelector } from 'react-redux'
import { DiagnosisState, getHccCodeDescriptions } from 'redux/slices/diagnosis'
import { useFormContext } from 'react-hook-form'
import { dispatch } from 'redux/store'

interface IHccCodeDescriptionPickerProps {
    name: string
    parentName: string
    label: string
    subLabel: string
    placeholder: string
    diagnosisCodeFieldName: string
}

const HccCodeDescriptionPicker: FC<IHccCodeDescriptionPickerProps> = ({
    name,
    parentName,
    label,
    subLabel,
    placeholder,
    diagnosisCodeFieldName
}) => {
    const { watch, setValue } = useFormContext()

    const diagnosisCode = watch(
        parentName ? diagnosisCodeFieldName.replace('${parent}', parentName) : diagnosisCodeFieldName
    )

    const currentValue = watch(name)

    const { hccCodeDescriptionsByDiagnosisCode } = useSelector(
        (state: { diagnosis: DiagnosisState }) => state.diagnosis
    )

    const [savedCurrentValue, setSavedCurrentValue] = useState(null)

    useEffect(() => {
        if (currentValue) {
            setSavedCurrentValue(currentValue)
        }
    }, [currentValue])

    useEffect(() => {
        if (diagnosisCode && !hccCodeDescriptionsByDiagnosisCode[diagnosisCode]) {
            dispatch(getHccCodeDescriptions({ diagCode: diagnosisCode }))
        }
    }, [diagnosisCode, hccCodeDescriptionsByDiagnosisCode])

    useEffect(() => {
        if (diagnosisCode) {
            if (hccCodeDescriptionsByDiagnosisCode[diagnosisCode]) {
                if (
                    hccCodeDescriptionsByDiagnosisCode[diagnosisCode].length > 0 &&
                    (!currentValue ||
                        !hccCodeDescriptionsByDiagnosisCode[diagnosisCode].find((x: any) => x.code === currentValue))
                ) {
                    if (
                        savedCurrentValue &&
                        hccCodeDescriptionsByDiagnosisCode[diagnosisCode].find((x: any) => x.code === savedCurrentValue)
                    ) {
                        setValue(name, savedCurrentValue)
                    } else {
                        setValue(name, hccCodeDescriptionsByDiagnosisCode[diagnosisCode][0].code)
                    }
                } else if (currentValue && hccCodeDescriptionsByDiagnosisCode[diagnosisCode].length === 0) {
                    setValue(name, null)
                }
            }
        } else if (currentValue) {
            setValue(name, null)
        }
    }, [diagnosisCode, currentValue, savedCurrentValue, hccCodeDescriptionsByDiagnosisCode])

    const options = diagnosisCode
        ? hccCodeDescriptionsByDiagnosisCode[diagnosisCode]
            ? hccCodeDescriptionsByDiagnosisCode[diagnosisCode].map((x: any) => ({
                  label: `${x.code}: ${x.description}`,
                  value: x.code
              }))
            : []
        : []

    return (
        <DropDownList
            name={name}
            label={label}
            subLabel={subLabel}
            placeholder={placeholder}
            hideReset={true}
            options={options}
        />
    )
}

export const renderHccCodeDescriptionPicker = (element: ISectionElement, key: string, _: any, parentName: string) => {
    const hccCodeDescriptionPicker = element as IHccCodeDescriptionPicker
    return <HccCodeDescriptionPicker key={key} parentName={parentName} {...hccCodeDescriptionPicker} />
}
