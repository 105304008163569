import Modal from 'components/Modal'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC } from 'react'
import { UI_COLORS } from 'theme'
import { StyledButtonContainer, StyledCancelButton, StyledConfirmButton } from './index.style'

interface IUnvalidatedAwvSubmissionDialogProps {
    validationErrorCount: number | null | undefined
    formType?: string | null
    onEdit: () => void
    onCancel: () => void
}

const UnvalidatedAwvSubmissionDialog: FC<IUnvalidatedAwvSubmissionDialogProps> = ({
    validationErrorCount,
    formType,
    onEdit,
    onCancel
}) => (
    <Modal minHeight={350} minWidth={700} onClose={onCancel}>
        <Typography type={TYPOGRAPHY_TYPES.h2}>{formType ?? 'Annual Wellness Visit'}</Typography>
        <Typography type={TYPOGRAPHY_TYPES.p} style={{ color: UI_COLORS.red, fontWeight: 'bold' }}>
            {validationErrorCount > 0 ? 'VALIDATION FAILED - CANNOT SUBMIT' : 'UNVALIDATED FORM - CANNOT SUBMIT'}
        </Typography>
        <Typography type={TYPOGRAPHY_TYPES.p}>
            {validationErrorCount > 0
                ? `The validation of this ${formType ?? 'AWV'} form resulted in ${validationErrorCount} ${
                      validationErrorCount === 1 ? 'error' : 'errors'
                  }. Would you like to edit the form now?`
                : `This ${formType ?? 'AWV'} form has not been validated. Would you like to edit the form now?`}
        </Typography>
        <StyledButtonContainer>
            <StyledCancelButton onClick={onCancel}>Cancel</StyledCancelButton>
            <StyledConfirmButton onClick={onEdit}>Edit</StyledConfirmButton>
        </StyledButtonContainer>
    </Modal>
)

export default UnvalidatedAwvSubmissionDialog
