import { StyledButtonContainer, StyledCancelButton, StyledConfirmButton } from 'components/ConfirmationBox/index.style'
import Modal from 'components/Modal'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC, useState } from 'react'
import { UI_COLORS } from 'theme/constants'
import DropDownList, { IOption } from 'components/DropDownList'
import { dispatch, useAppSelector } from 'redux/store'
import { Pulse8State, setCurrentNpiCredential } from 'redux/slices/pulse8'
import { updateMyCredential } from 'redux/slices/memberProfile'
import Spinner from 'components/Spinner'
import { StyledSpinnerWrap } from 'components/UploadFileModal/index.style'

import useMemberProfileLookupData from 'hooks/useMemberProfileLookupData'

interface IProps {
    onUpdated?: () => void | null
    onCancel?: () => void | null
    onError?: () => void | null
}

const PCPCredentialModal: FC<IProps> = ({ onUpdated, onCancel, onError }) => {
    const credentialDropdownOptions: IOption[] = useMemberProfileLookupData()?.credentials.map((x) => ({
        label: x.name,
        value: x.id
    }))
    const [credential, setCredential] = useState(
        useAppSelector(
            (state: { pulse8: Pulse8State }) =>
                state.pulse8.providerNpis.find((x) => x.npi === state.pulse8.currentNpi)?.defaultCredential
        )
    )
    const [isInError, setIsInError] = useState(credential == null)
    const [isUpdating, setIsUpdating] = useState(false)

    const handleUpdate = () => {
        if (credential == null) {
            setIsInError(true)
        } else {
            setIsUpdating(true)
            dispatch(updateMyCredential({ credential: credential }))
                .then((x) => {
                    if (x.meta.requestStatus === 'fulfilled') {
                        dispatch(setCurrentNpiCredential(credential))
                        onUpdated && onUpdated()
                    } else {
                        onError && onError()
                    }
                })
                .then(() => {
                    setIsUpdating(false)
                })
        }
    }
    const handleCancle = () => {
        if (!isUpdating) onCancel()
    }

    return (
        <Modal onClose={handleCancle} minWidth={550} minHeight={400}>
            <Typography type={TYPOGRAPHY_TYPES.h2}>Select Credentials</Typography>
            <Typography type={TYPOGRAPHY_TYPES.p}>
                We noticed that you have not yet selected your default credentials required to submit these forms.
                Please select the one you want to use when attesting to the Annual Wellness Visit or Patient Assessment
                Forms.
            </Typography>

            <DropDownList
                key="defaultCredential"
                placeholder="Select Credential"
                options={credentialDropdownOptions}
                selectedValue={credential}
                setSelectedValue={(value) => {
                    setCredential(value)
                    setIsInError(false)
                }}
                isInError={isInError}
                isDisabled={isUpdating}
            />

            <StyledButtonContainer>
                <StyledCancelButton onClick={onCancel} disabled={isUpdating}>
                    Cancel
                </StyledCancelButton>
                {isUpdating ? (
                    <StyledSpinnerWrap>
                        <Spinner />
                    </StyledSpinnerWrap>
                ) : (
                    <StyledConfirmButton
                        onClick={handleUpdate}
                        disabled={isUpdating || isInError}
                        color={UI_COLORS.light_blue2}
                    >
                        Update
                    </StyledConfirmButton>
                )}
            </StyledButtonContainer>
        </Modal>
    )
}

export default PCPCredentialModal
