import { number } from "yargs"

export const removePhoneNumberPunctuation = (phone: string) => {
    if (phone == null) return ''
    return phone?.replaceAll('_', '').replaceAll('-', '').replace('(', '').replace(')', '').replaceAll(' ', '')
}

export const getAreaCodeFromPhone = (phone: string) => {
    if (phone && phone.length > 2) {
        return Number(phone.substring(0, 3))
    }
    
    return ''
}

export const formatUSNumber = (phone: string) => {
    // put raw phone number in the form (xxx) xxx-xxxx
    if (phone == null) return ''
    phone = removePhoneNumberPunctuation(phone)
    if (phone.length == 10) {
        // use regular expression
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    }
    return phone
}

export const isValidAreaCode = (phone: string, validAreaCodes: number[]): boolean => {
    const areaCodeToTest = getAreaCodeFromPhone(removePhoneNumberPunctuation(phone))

    const isAreaCodeInList = validAreaCodes.some(areaCode => areaCodeToTest === areaCode)

    return isAreaCodeInList
}

export const limitStringCharacters = (numberOfCharacters: number, stringToLimit: string): string => {
    stringToLimit = stringToLimit?.trim()
    if (stringToLimit?.length > numberOfCharacters) {
        const truncatedString = stringToLimit.substring(0, numberOfCharacters)
        // add ellipsis to end of string
        return truncatedString + '...'
    }

    return stringToLimit
}