import styled from 'styled-components'
import { UI_COLORS } from 'theme'

const { grey } = UI_COLORS

const StyledDocumentsModalActionButton = styled.button`
    margin-left: 20px;
    width: 50px;
    height: 50px;

    background-color: transparent;
    border: none;

    cursor: pointer;

    figure {
        margin: 0;
        padding: 0;
    }

    :disabled {
        cursor: default;
    }
`
export default StyledDocumentsModalActionButton

export const StyledDocumentsModalListContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export const StyledRemoveFileButtonContainer = styled(StyledDocumentsModalListContainer)`
    justify-content: flex-start;
    p {
        margin-top: 12px;
    }
`

export const StyledDropzoneContainer = styled.article`
    margin: 3px;
    width: 100%;
    height: 180px;
    background-color: #f5f5f5;
    border: none;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const StyledErrorMessageContainer = styled.article`
    h4 {
        color: red;
        margin-bottom: 15px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        p {
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: red;
        }
    }
`

export const StyledLongDashedBorder = styled.div`
    /* Set the border width and color */
    margin-top: 30px;
    margin-bottom: 40px;
    border-width: 1px;
    border-color: #a4b0c0;
    background-color: #f5f5f5;

    /* Create a long dashed effect using background gradients */
    background-image: linear-gradient(to right, #a4b0c0 50%, transparent 50%),
        linear-gradient(to left, #a4b0c0 50%, transparent 50%), linear-gradient(to bottom, #a4b0c0 50%, transparent 50%),
        linear-gradient(to top, #a4b0c0 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 30px 1px, 30px 1px, 1px 30px, 1px 30px; /* Adjust the dash length and spacing here */
    background-position: top, bottom, left, right;

    /* Add padding to prevent content from overlapping the dashed border */
    padding: 4px;
    box-sizing: border-box;
`

export const StyledSelectedFileContainer = styled.article`
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
        p {
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #2281c4;
        }
    }
`

export const StyledSelectFileButton = styled.button`
    width: 131px;
    height: 50px;

    background: #ffffff;
    border: 1px solid #2281c4;
    border-radius: 4px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    color: #2281c4;

    cursor: pointer;

    :disabled {
        color: ${grey};
        border: 1px solid ${grey};
        cursor: default;
    }
`

export const StyledIconActionButton = styled(StyledDocumentsModalActionButton)`
    margin-left: 8px;
    width: 20px;
    height: 20px;
    padding: 0;

    background-color: transparent;
    border: none;

    cursor: pointer;

    figure {
        margin: 0;
        padding: 0;
    }
`
