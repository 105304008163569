import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import moment from 'moment'
import { ReadOnlyTextField } from '../ReadOnlyTextField'
import { IAgeField, ISectionElement } from '../index.models'

interface IAgeFieldProps {
    name: string
    label: string
    subLabel: string
    description: string
    dateOfBirthFieldName: string
    dateOfServiceFieldName: string
}

const AgeField: FC<IAgeFieldProps> = ({
    name,
    label,
    subLabel,
    description,
    dateOfBirthFieldName,
    dateOfServiceFieldName
}) => {
    const { watch } = useFormContext()

    const dateOfBirth = watch(dateOfBirthFieldName)
    const dateOfService = dateOfServiceFieldName ? watch(dateOfServiceFieldName) : undefined

    const age =
        dateOfBirth && moment.utc(dateOfBirth).isValid()
            ? Math.max(
                  (dateOfService && moment.utc(dateOfService).isValid()
                      ? moment.utc(dateOfService)
                      : moment.utc(moment().format('YYYY-MM-DD'))
                  ).diff(moment.utc(dateOfBirth), 'years'),
                  0
              )
            : null

    return <ReadOnlyTextField name={name} label={label} subLabel={subLabel} description={description} value={age} />
}

export const renderAgeField = (element: ISectionElement, key: string) => {
    const ageField = element as IAgeField
    return <AgeField key={key} {...ageField} />
}
