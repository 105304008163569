import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { white, light_gray5, grey } = UI_COLORS

export const StyledTxtFieldRowListContainer = styled.article`
    width: ${pxToRem(1145)};
    margin-bottom: ${pxToRem(10)};
`

export const StyledTxtFieldRowListHeader = styled.ul`
    background-color: ${light_gray5};
    list-style: none;
    margin: 0;
    padding: 0;

    border: 1px solid #dee5ef;

    display: flex;
    flex-direction: row;

    padding: 11px 30px;
`

export const StyledTxtFieldRowListHeaderItem = styled.li`
    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #73839c;
    }
`

export const StyledTxtFieldRowListBody = styled.ul<{ isInError?: any }>`
    background-color: ${white};
    list-style: none;
    margin: 0;
    padding: 0;

    padding: 18px 30px;

    display: flex;
    flex-direction: row;

    border-bottom: 1px solid #dee5ef;
    border-left: 1px solid #dee5ef;
    border-right: 1px solid #dee5ef;
    ${(props) => {
        if (props.isInError) {
            return `border-left: 3px solid red;`
        }
    }}
`

export const StyledTxtFieldRowListBodyItem = styled.li<{ isInError?: any }>`
    input {
        outline: none;
        width: 100%;
        height: 50px;

        background-color: #ffffff;
        border: 1px solid #73839c;
        border-radius: 4px;
        ${(props) => {
            if (props.isInError) {
                return `border: 1px solid red;`
            }
        }}
    }
`

export const StyledDeleteListBodyItem = styled.li`
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const StyledDeleteButton = styled.button`
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 0;
    margin: 0;
    padding: 0;
    position: relative;

    cursor: pointer;

    :hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
        border-radius: 20px;
        p {
            visibility: visible;
        }
    }

    :focus {
        outline: none;
    }

    :disabled {
        visibility: collapse;
    }

    p {
        background-color: #293854;
        padding: 5px 0;
        border-radius: 4px;
        width: 70px;
        position: absolute;
        top: -40px;
        right: 10;
        color: #ffffff;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        visibility: hidden;
    }
`

export const StyledAddLink = styled.button`
    border: 0;
    background-color: transparent;
    margin: 0;
    text-align: left;
    height: 18px;
    position: relative;
    top: -10px;
    margin-top: 20px;
    margin-bottom: 25px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    color: #2281c4;
    cursor: pointer;

    :focus {
        outline: none;
    }

    :disabled {
        visibility: collapse;
    }
`
