import styled, { css } from 'styled-components'
import { pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { dark_gray, white, grey } = UI_COLORS

export const StyledDocumentsContainer = styled.article`
    width: ${pxToRem(1145)};
    padding-top: ${pxToRem(220)};
    padding-left: ${pxToRem(30)};
`
export const StyledInfoPanel = styled.article`
    p {
        width: 100%;
        font-family: 'Inter', 'sans-serif';
        font-style: normal;
        font-weight: 500;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(22)};
        color: ${dark_gray};
        margin: 0;
        margin-bottom: ${pxToRem(30)};
    }
`