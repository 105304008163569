import styled, { css } from 'styled-components'
import { DEVICE, pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { white, grey, black } = UI_COLORS

export const StyledFormTable = styled.table`
    margin: ${pxToRem(15)} 0 ${pxToRem(30)} ${pxToRem(15)};
    color: ${black};

    td {
        padding: ${pxToRem(8)} ${pxToRem(20)} 0 0;
    }
`

export const StyledButtonContainer = styled.article`
    display: flex;
    flex-direction: row;
    margin-top: ${pxToRem(25)};

    ${DEVICE.tablet} {
        position: absolute;
        bottom: 30px;
        right: 30px;
    }
`

export const StyledConfirmButton = styled.button<{ color: string }>`
    width: ${pxToRem(120)};
    height: ${pxToRem(50)};
    border-radius: ${pxToRem(4)};
    border: 0px;
    cursor: pointer;

    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};
    color: ${white};

    :active {
        background-color: ${white};
    }

    ${(props) => {
        return css`
            background-color: ${props.color};

            :active {
                color: ${props.color};
                border: 1px solid ${props.color};
            }
        `
    }}
`

export const StyledCancelButton = styled.button`
    width: ${pxToRem(120)};
    height: ${pxToRem(50)};
    border-radius: ${pxToRem(4)};
    border: 0px;
    cursor: pointer;

    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};

    background-color: ${grey};
    color: ${white};

    :active {
        background-color: ${white};
        color: ${grey};
        border: 1px solid ${grey};
    }
`
