import { ISecurityUser } from 'pages/hcp/users/create/index.models'

export enum ERoles {
    SuperAdmin = 1,
    CallCenterAdmin = 2,
    OfficeAdmin = 3,
    Provider = 4,
    Nurse = 5,
    ProviderRep = 6,
    Coding = 7,
    Quality = 8
}

export enum ERoleString {
    OFFICE_ADMIN = 'OFFICE_ADMIN',
    PCP_NP = 'PCP_NP'
}

export enum ERoleLabel {
    'Super Admin',
    'Call Center Admin',
    'Office Admin',
    'Provider',
    'Nurse',
    'Provider Rep',
    'Coding',
    'Quality'
}

export const isProvider = (currentUser: ISecurityUser): boolean => {
    return currentUser.isImpersonated
        ? false
        : currentUser?.primaryRole === ERoles.OfficeAdmin ||
              currentUser?.primaryRole === ERoles.Provider ||
              currentUser?.primaryRole === ERoles.Nurse
}

/**
 * 'Internal' or 'External'
 */
export enum EUserSide {
    Internal = 1,
    External = 2
}

/**
 * return internal/external (EUserSide) based on user role (ERoles)
 * @param user role (ERoles)
 * @returns
 */
export const getUserSide = (role: ERoles): string => {
    return [ERoles.OfficeAdmin, ERoles.Provider, ERoles.Nurse].includes(role)
        ? EUserSide[EUserSide.External]
        : EUserSide[EUserSide.Internal]
}
