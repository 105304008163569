import { FC } from 'react'
import {
    StyledAddLink,
    StyledDeleteButton,
    StyledDeleteListBodyItem,
    StyledTxtFieldRowListBody,
    StyledTxtFieldRowListBodyItem,
    StyledTxtFieldRowListContainer,
    StyledTxtFieldRowListHeader,
    StyledTxtFieldRowListHeaderItem
} from './index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { IObjectList, IObjectProperty, ISectionElement } from '../index.models'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { AwvTooltip } from '../AwvTooltip'
import useErrors from 'hooks/useErrors'

interface IObjectListProps {
    name: string
    addLinkLabel: string
    deleteTooltip: string
    gapBetweenPropertyFields: number
    properties: IObjectProperty[]
}

const deleteIcon = require('assets/delete-2.svg').default

const availableWidth = 1020

const ObjectList: FC<IObjectListProps> = ({
    name,
    addLinkLabel,
    deleteTooltip,
    gapBetweenPropertyFields,
    properties
}) => {
    const { register, control } = useFormContext()
    const errors = useErrors()
    const { fields, append, remove } = useFieldArray({ control, name })

    const cellWidth = (availableWidth - (properties.length - 1) * gapBetweenPropertyFields) / properties.length

    const handleAdd = (e: any) => {
        e.preventDefault()
        append({})
    }

    const handleRemove = (e: any, index: number) => {
        e.preventDefault()
        remove(index)
    }

    return (
        <>
            {fields?.length > 0 && (
                <StyledTxtFieldRowListContainer>
                    <StyledTxtFieldRowListHeader>
                        {properties.map((property: IObjectProperty, index: number) => (
                            <StyledTxtFieldRowListHeaderItem
                                key={`${name}-rowListTitle-${index}`}
                                style={{ width: `${cellWidth + gapBetweenPropertyFields}px` }}
                            >
                                <Typography type={TYPOGRAPHY_TYPES.p}>{property.label}</Typography>
                            </StyledTxtFieldRowListHeaderItem>
                        ))}
                    </StyledTxtFieldRowListHeader>
                    {fields.map((field: any, fieldIndex: number) => (
                        <AwvTooltip
                            key={`tooltip.OL.${name}.${fieldIndex}`}
                            message={errors.getError(`${name}.${fieldIndex}`)}
                            placement="top-start"
                        >
                            <StyledTxtFieldRowListBody
                                key={field.id}
                                isInError={errors.getError(`${name}.${fieldIndex}`)}
                            >
                                {properties.map((property, propertyIndex) => (
                                    <AwvTooltip
                                        key={`tooltip.OL.${name}.${fieldIndex}_${property.name}`}
                                        message={errors.getError(`${name}.${fieldIndex}.${property.name}`)}
                                    >
                                        <StyledTxtFieldRowListBodyItem
                                            key={`${field.id}-${property.name}`}
                                            isInError={errors.getError(`${name}.${fieldIndex}.${property.name}`)}
                                            style={{
                                                width: `${cellWidth}px`,
                                                marginRight:
                                                    propertyIndex !== properties.length - 1
                                                        ? `${gapBetweenPropertyFields}px`
                                                        : '0'
                                            }}
                                        >
                                            <input {...register(`${name}.${fieldIndex}.${property.name}`)} />
                                        </StyledTxtFieldRowListBodyItem>
                                    </AwvTooltip>
                                ))}
                                <StyledDeleteListBodyItem key={`delete-${field.id}`}>
                                    <StyledDeleteButton onClick={(e) => handleRemove(e, fieldIndex)}>
                                        <img src={deleteIcon} alt={deleteTooltip ?? 'Delete'} />
                                        <p>{deleteTooltip ?? 'Delete'}</p>
                                    </StyledDeleteButton>
                                </StyledDeleteListBodyItem>
                            </StyledTxtFieldRowListBody>
                        </AwvTooltip>
                    ))}
                </StyledTxtFieldRowListContainer>
            )}
            <StyledAddLink onClick={handleAdd}>{addLinkLabel}</StyledAddLink>
        </>
    )
}

export const renderObjectList = (element: ISectionElement, key: string) => {
    const objectList = element as IObjectList
    return <ObjectList key={key} {...objectList} />
}
