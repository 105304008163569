import styled, { css } from 'styled-components'
import { DEVICE, pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { dark_gray, white, grey } = UI_COLORS

export const StyledDashboardContainer = styled.article`
    width: 100%;
    /* height: 90vh; */
    padding: 0 ${pxToRem(30)} ${pxToRem(30)} ${pxToRem(30)};
    max-width: 1205px;
    margin: 0 auto;
`

export const StyledDashboardHeader = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: ${pxToRem(30)};
`

export const StyledWelcomeMessage = styled.h3`
    flex-grow: 1;
    margin: ${pxToRem(5)} 0 0 0;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-size: ${pxToRem(26)};
    line-height: 120%;

    display: flex;
    align-items: center;
    letter-spacing: -0.01em;

    color: #293854;
`

export const StyledDashboardImgContainer = styled.article<{ image?: string }>`
    width: 100%;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 0;
    border-radius: 4px;

    :after {
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        content: '';
        background: linear-gradient(270deg, rgba(34, 129, 196, 0.8) 25.78%, rgba(255, 255, 255, 0) 71.44%);
        border-radius: 4px;
    }

    ${DEVICE.tablet} {
        width: 100%;
        height: ${pxToRem(348)};
    }

    ${DEVICE.desktop} {
        width: ${pxToRem(430)};
        height: ${pxToRem(348)};
    }

    ${(props) => {
        if (props.image) {
            return css`
                background-image: url(${props.image});
            `
        }
    }}
`

export const StyledPulse8Button = styled.button`
    z-index: 2;
    position: absolute;
    bottom: ${pxToRem(52)};
    right: ${pxToRem(25)};

    width: ${pxToRem(120)};
    height: ${pxToRem(50)};

    border-radius: ${pxToRem(4)};
    border: 0;

    background-color: ${white};
    color: ${dark_gray};

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};

    box-shadow: 0 ${pxToRem(2)} ${pxToRem(5)} rgba(0, 0, 0, 0.07);

    cursor: pointer;

    &:active {
        background: #fff;
        border: ${pxToRem(1)} solid ${grey};
        color: ${grey};
    }
`

export const StyledDashboardLastRowContainer = styled.article`
    overflow: auto;

    ${DEVICE.desktop} {
        flex-direction: row;
        width: ${pxToRem(1145)};
    }
`

export const StyledDashboardMemberOutreachContainer = styled.article`
    height: ${pxToRem(348)};
    overflow: auto;
    margin-bottom: 15px;

    ${DEVICE.desktop} {
        margin-bottom: 0;
    }
`

export const StyledTopOppsWrapper = styled.article`
    overflow: auto;
`

export const StyledLastUpdatedContainer = styled.article`
    h3 {
        color: #73839c;
        font-family: 'Inter';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        margin: 0;
    }

    margin-bottom: ${pxToRem(25)};
`

export const StyledNpiDropdownContainer = styled.article`
    div {
        margin: 0;
    }
`
