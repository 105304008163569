import { FC, useEffect } from 'react'

import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import Typography from 'components/Typography'

import { StyledHeader, StyledMemberProfileContainerWrap } from 'pages/hcp/members/list/index.style'

import useCurrentUser from 'hooks/useCurrentUser'
import ProviderDropdown from '../../../components/ProviderDropdown'
import { fetchProviderNpis, Pulse8State, setCurrentNpi } from 'redux/slices/pulse8'
import { dispatch, useAppSelector } from 'redux/store'

import { ERoles } from 'models/enums/role'

import ProviderReports from 'components/ProviderReports'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import { DocumentsState, updateReportCountByNpi } from 'redux/slices/documents'
import usePostCustomEvent from 'hooks/usePostCustomEvent'

interface IProps {}

const ProviderReportsPage: FC<IProps> = () => {
    const currentUser = useCurrentUser()
    const { newReportCounts } = useAppSelector((state: { documents: DocumentsState }) => state.documents)
    const { currentNpi, providerNpis, providerNpisLoaded } = useAppSelector(
        (state: { pulse8: Pulse8State }) => state.pulse8
    )
    const changeProviderEvent = usePostCustomEvent(pageTitle.ProviderReportsPage, customEvents.ChangeProvider)

    useEffect(() => {
        if (!providerNpisLoaded) {
            dispatch(fetchProviderNpis())
        }
    }, [providerNpisLoaded])

    useEffect(() => {
        const npisWithNewReports = (newReportCounts ?? []).filter((x) => x.count > 0)
        if (npisWithNewReports.length > 0) {
            dispatch(setCurrentNpi(npisWithNewReports[0].npi))
        }
    }, [newReportCounts])

    const setNpi = (newNpi: string) => {
        if (currentNpi && newNpi && currentNpi !== newNpi) {
            changeProviderEvent()
            dispatch(setCurrentNpi(newNpi))
        }
    }

    const reducerReportCount = (npi: string) => dispatch(updateReportCountByNpi(npi))

    return (
        <>
            <StyledMemberProfileContainerWrap>
                <StyledHeader>
                    <Typography type={TYPOGRAPHY_TYPES.p}>Reports</Typography>
                    {currentUser.primaryRole === ERoles.OfficeAdmin && providerNpis.length > 0 && (
                        <div>
                            <ProviderDropdown
                                providerNpis={providerNpis}
                                newProviderReportCounts={newReportCounts}
                                selectedNpi={currentNpi}
                                setSelectedNpi={setNpi}
                            />
                        </div>
                    )}
                </StyledHeader>
                <ProviderReports
                    npi={currentNpi}
                    page={pageTitle.ProviderReportsPage}
                    updateReportCount={reducerReportCount}
                />
            </StyledMemberProfileContainerWrap>
        </>
    )
}

export default ProviderReportsPage
