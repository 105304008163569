import { useNavigate } from 'react-router-dom'
import { appPath } from 'utilities/appPath'
import { useMemberOutreachListConfig } from './useListConfig'

export const useNavigateToIncentive = () => {
    const memberOutreachListConfig = useMemberOutreachListConfig()
    const navigate = useNavigate()

    return (incentive: string | null | undefined) => {
        if (incentive) {
            memberOutreachListConfig.setFilter(`${incentive} Not Paid`)
            navigate(appPath('/memberOutreach'))
        }
    }
}
