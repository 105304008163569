import CustomSvgIcon from 'components/CustomSvgIcon'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import React, { useEffect, useState } from 'react'
import { editPen } from '../Personal/icons'
import {
    StyledMemberButtonsContainer,
    StyledMemberCancelButton,
    StyledMemberEditButton,
    StyledMemberInfoContainer,
    StyledMemberSaveButton,
    StyledMemberSelect,
    StyledMemberSelectWrap
} from '../Personal/index.style'
import { useForm, Controller, useFieldArray, FieldValues } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { StyledMemberInputContainer } from '../MemberInput/index.style'
import Select from 'react-select'
import {
    SelectStyledStateWrap,
    StyledAddButton,
    StyledAddressContainer,
    StyledAddressEditContainer,
    StyledAddressSeparator,
    StyledInputWrap,
    StyledNumberInputWrap,
    StyledStreetAddressInputWrap
} from './index.style'
import { DeleteButton } from './DeleteButton'
import {
    fetchMemberProfileDetail,
    MemberProfileState,
    updateMemberProfileAddressDetails
} from 'redux/slices/memberProfile'
import { UsaStates } from 'pages/hcp/users/create/NewOfficeAdmin/AddressModal/index.models'
import {
    IMemberProfileAddressDetails,
    IMemberProfileLocation,
    IMemberProfileLocationClient
} from 'pages/hcp/users/create/index.models'
import { dispatch } from 'redux/store'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { isZipCodeValid } from 'utilities/addressUtilities'
import { Pulse8State } from 'redux/slices/pulse8'
import useCurrentUser from 'hooks/useCurrentUser'
import { createDropdownIndicator, createStyles } from 'components/DropDownList/index.style'
import { addressDropDownListStyleParams, stateDropDownListStyleParams } from 'components/TaxIdProviderInfo/index.style'
import { ERoles } from 'models/enums/role'
import Spinner from 'components/Spinner'
import { getIndexFromYupPath } from 'utilities/validationUtilities'
import SmallSpinner from 'components/SmallSpinner'

interface IAddressesProps {}

interface IAddressType {
    label: string
    value: number
}

interface IStateType {
    label: string
    value: string
}

const validateState = (value: string): boolean => {
    return value !== 'State' && value !== '' && value !== undefined && value !== null
}

const Addresses: React.FC<IAddressesProps> = ({ children }) => {
    const addressValidationsSchema = yup.object().shape({
        memberLocations: yup.array().of(
            yup.object().shape({
                addressType: yup
                    .mixed()
                    .required()
                    .test(
                        'validate one primary location',
                        'Multiple Primary Locations',
                        (val: any, context) =>
                            validateUniqueLabels(getIndexFromYupPath(context.path), getAllLocationTypeLabelsFromForm) ||
                            val.value === 2
                    )
                    .test('missing primary location type', 'Primary Location Required', (val: any, context) =>
                        validateAtLeastOnePrimaryLocation(
                            getIndexFromYupPath(context.path),
                            getAllLocationTypeLabelsFromForm
                        )
                    ),
                streetAddress: yup.string().required('Address is Required'),
                houseOrApartmentNumber: yup.string(),
                state: yup
                    .mixed()
                    .required('State is Required')
                    .test('valid state', 'State is invalid', (val: any) => validateState(val?.value)),
                city: yup.string().required('City is Required'),
                zipCode: yup
                    .string()
                    .required('Zip Code is Required')
                    .test('len', 'Must be exactly 5 characters', (val) => val.length === 5)
                    .test('valid zipcode', 'Invalid Zip Code', (value) => isZipCodeValid(value))
            })
        )
    })

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        getValues
    } = useForm({
        resolver: yupResolver(addressValidationsSchema)
    })

    const validateUniqueLabels = (index: number, retrieveLabels: () => any[]) => {
        const values = retrieveLabels()
        const value = values[index]
        const filtered = values.filter((v) => v === value)
        return filtered.length <= 1
    }

    const validateAtLeastOnePrimaryLocation = (index: number, retrieveLabels: () => string[]) => {
        const labels = retrieveLabels()
        if (labels == null) return true
        return labels.some((val) => val.indexOf('Primary') >= 0)
    }

    const getAllLocationTypeLabelsFromForm = (): string[] => {
        const memberLocations = getValues('memberLocations')
        const locationLabels = memberLocations.map((locBundle: any) => locBundle?.addressType?.label)
        return locationLabels
    }

    const { fields, append, prepend, remove } = useFieldArray({
        name: 'memberLocations',
        control,
        rules: {
            required: 'Please append at least 1 item'
        }
    })

    const [isFormDisabled, setIsFormDisabled] = useState<boolean>(true)
    const [isFormLoading, setIsFormLoading] = useState<boolean>(true)

    const [isCancelling, setIsCancelling] = useState<boolean>(false)

    const memberProfileDetails = useSelector(
        (state: { memberProfile: MemberProfileState }) => state.memberProfile.memberProfileDetails
    )

    const memberProfileLookupData = useSelector(
        (state: { memberProfile: MemberProfileState }) => state.memberProfile.memberProfileLookupData
    )

    const [defaultAddressType, setDefaultAddressType] = useState<IAddressType>({ label: '', value: 0 })

    const { currentNpi, providerNpis, providerNpisLoaded } = useSelector(
        (state: { pulse8: Pulse8State }) => state.pulse8
    )

    const currentUser = useCurrentUser()

    useEffect(() => {
        if (memberProfileLookupData) {
            setDefaultAddressType({
                label: memberProfileLookupData?.addressTypes[0].name,
                value: memberProfileLookupData?.addressTypes[0].id
            })
        }
    }, [memberProfileLookupData])

    useEffect(() => {
        if (memberProfileDetails) {
            setIsFormLoading(false)
            remove()
            let index = 0
            memberProfileDetails.locations.forEach((loc) => {
                append(loc)
                setValue(`memberLocations[${index}].addressType`, {
                    label: loc.addressType.name,
                    value: loc.addressType.id
                })
                setValue(`memberLocations[${index}].state`, { label: loc.state, value: loc.state })
                index++
            })
        }
    }, [memberProfileDetails])

    const handleUpdate = (data: FieldValues) => {
        let serverPayload: IMemberProfileAddressDetails = {
            npi: currentNpi,
            Locations: [],
            memberId: Number(memberProfileDetails?.memberId),
            lastUpdatedBy: currentUser?.sid
        }

        data.memberLocations.forEach((loc: IMemberProfileLocationClient) => {
            serverPayload.Locations.push({
                memberAddressId: loc.memberAddressId,
                addressType: memberProfileLookupData?.addressTypes?.find(
                    (addType) => addType.id == (loc.addressType as any).value
                ),
                streetAddress: loc.streetAddress,
                houseOrApartmentNumber: loc.houseOrApartmentNumber,
                city: loc.city,
                state: loc.state.value,
                zipCode: loc.zipCode
            })
        })

        dispatch(updateMemberProfileAddressDetails(serverPayload))
        setIsFormDisabled(true)
    }

    const handleCancel = () => {
        setIsCancelling(true)
        setIsFormDisabled(true)
        dispatch(fetchMemberProfileDetail({ memberId: memberProfileDetails?.memberId, npi: currentNpi })).then(() => {
            setIsCancelling(false)
        })
    }

    const handleEditButton = () => {
        setIsFormDisabled(false)
    }

    const initialAddressValue: IMemberProfileLocation = {
        addressType: { name: '', id: 0 },
        memberAddressId: 0,
        streetAddress: '',
        houseOrApartmentNumber: '',
        city: '',
        state: '',
        zipCode: ''
    }

    const handleAdd = () => {
        append({
            ...initialAddressValue,
            addressType: {
                label: memberProfileLookupData?.addressTypes[1].name,
                value: memberProfileLookupData?.addressTypes[1].id
            }
        })
    }

    const handleRemove = (index: number) => {
        remove(index)
    }

    const addressTypeOptions: IAddressType[] = memberProfileLookupData?.addressTypes?.map((addressType) => {
        return { label: addressType.name, value: addressType.id }
    })

    const stateOptions = Object.entries(UsaStates).map(([key, value]) => ({ value: key, label: value }))

    const getErrorObject = (index: number): any => {
        return (errors.memberLocations as unknown as Array<any>)?.[index]
    }

    const isReadOnly = [ERoles.CallCenterAdmin, ERoles.Quality].includes(currentUser.primaryRole)

    return (
        <>
            {isFormLoading === true ? (
                <Spinner />
            ) : (
                <StyledMemberInfoContainer>
                    <StyledAddressEditContainer>
                        <Typography type={TYPOGRAPHY_TYPES.h3}>Address Information</Typography>
                        {isReadOnly ? (
                            ''
                        ) : (
                            <StyledMemberEditButton onClick={handleEditButton}>
                                <CustomSvgIcon iconSet={{ icon: editPen }} svg></CustomSvgIcon>
                                Edit
                            </StyledMemberEditButton>
                        )}
                        {isCancelling && <SmallSpinner />}
                    </StyledAddressEditContainer>
                    <fieldset disabled={isFormDisabled}>
                        <form onSubmit={handleSubmit(handleUpdate)}>
                            <>
                                {fields.map(
                                    (field, index) => {
                                        return (
                                            <div key={`address_${field.id}`}>
                                                <StyledMemberSelectWrap>
                                                    <StyledMemberSelect>
                                                        <label>Address Type</label>
                                                        <Controller
                                                            name={`memberLocations.${index}.addressType`}
                                                            control={control}
                                                            defaultValue={defaultAddressType}
                                                            render={({ field }) => (
                                                                <Select
                                                                    options={addressTypeOptions}
                                                                    {...field}
                                                                    components={{
                                                                        DropdownIndicator:
                                                                            createDropdownIndicator(
                                                                                addressDropDownListStyleParams
                                                                            )
                                                                    }}
                                                                    placeholder={'Select Address Type'}
                                                                    styles={createStyles(
                                                                        addressDropDownListStyleParams,
                                                                        isFormDisabled,
                                                                        errors?.addressType != null
                                                                    )}
                                                                    isDisabled={isFormDisabled}
                                                                    isSearchable={false}
                                                                    defaultValue={
                                                                        addressTypeOptions && defaultAddressType
                                                                    }
                                                                    value={
                                                                        addressTypeOptions &&
                                                                        addressTypeOptions?.find(
                                                                            (option) =>
                                                                                option.value === field?.value?.value
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                        <small>
                                                            {getErrorObject(index)?.addressType &&
                                                                getErrorObject(index)?.addressType?.message}
                                                        </small>
                                                    </StyledMemberSelect>
                                                </StyledMemberSelectWrap>
                                                <StyledAddressContainer key={field.id}>
                                                    <StyledStreetAddressInputWrap>
                                                        <StyledMemberInputContainer>
                                                            <label>Street Address</label>
                                                            <input
                                                                type="string"
                                                                {...register(`memberLocations.${index}.streetAddress`)}
                                                            />
                                                            <small>
                                                                {getErrorObject(index)?.streetAddress &&
                                                                    getErrorObject(index)?.streetAddress?.message}
                                                            </small>
                                                        </StyledMemberInputContainer>
                                                    </StyledStreetAddressInputWrap>

                                                    <StyledNumberInputWrap>
                                                        <StyledMemberInputContainer>
                                                            <label>House/Apt Number</label>
                                                            <input
                                                                type="string"
                                                                {...register(
                                                                    `memberLocations.${index}.houseOrApartmentNumber`
                                                                )}
                                                            />
                                                            <small>
                                                                {errors?.houseNumber && errors.houseNumber.message}
                                                            </small>
                                                        </StyledMemberInputContainer>
                                                    </StyledNumberInputWrap>
                                                    {index > 0 && !isFormDisabled ? (
                                                        <DeleteButton
                                                            index={index}
                                                            handleClick={() => handleRemove(index)}
                                                        ></DeleteButton>
                                                    ) : (
                                                        ''
                                                    )}
                                                </StyledAddressContainer>

                                                <StyledAddressContainer key={`second_row_${field.id}_${index}`}>
                                                    <StyledInputWrap>
                                                        <StyledMemberInputContainer>
                                                            <label>City</label>
                                                            <input
                                                                type="string"
                                                                {...register(`memberLocations.${index}.city`)}
                                                            />
                                                            <small>
                                                                {getErrorObject(index)?.city &&
                                                                    getErrorObject(index)?.city?.message}
                                                            </small>
                                                        </StyledMemberInputContainer>
                                                    </StyledInputWrap>

                                                    <StyledMemberSelectWrap>
                                                        <SelectStyledStateWrap>
                                                            <StyledMemberSelect>
                                                                <label>State</label>
                                                                <Controller
                                                                    name={`memberLocations.${index}.state`}
                                                                    control={control}
                                                                    defaultValue={null}
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            options={stateOptions}
                                                                            components={{
                                                                                DropdownIndicator:
                                                                                    createDropdownIndicator(
                                                                                        addressDropDownListStyleParams
                                                                                    )
                                                                            }}
                                                                            {...field}
                                                                            placeholder={'Select State'}
                                                                            styles={createStyles(
                                                                                stateDropDownListStyleParams,
                                                                                isFormDisabled,
                                                                                errors?.state != null
                                                                            )}
                                                                            isDisabled={isFormDisabled}
                                                                            isSearchable={true}
                                                                            defaultValue={null}
                                                                            value={stateOptions?.find(
                                                                                (c) => c.value === field.value?.value
                                                                            )}
                                                                        />
                                                                    )}
                                                                />
                                                                <small>
                                                                    {getErrorObject(index)?.state &&
                                                                        getErrorObject(index)?.state?.message}
                                                                </small>
                                                            </StyledMemberSelect>
                                                        </SelectStyledStateWrap>
                                                    </StyledMemberSelectWrap>
                                                    <StyledInputWrap>
                                                        <StyledMemberInputContainer>
                                                            <label>Zip Code</label>
                                                            <input
                                                                type="string"
                                                                {...register(`memberLocations.${index}.zipCode`)}
                                                            />
                                                            <small>
                                                                {getErrorObject(index)?.zipCode &&
                                                                    getErrorObject(index)?.zipCode?.message}
                                                            </small>
                                                        </StyledMemberInputContainer>
                                                    </StyledInputWrap>
                                                </StyledAddressContainer>
                                                <StyledAddressSeparator />
                                            </div>
                                        )
                                    } // return
                                )}
                            </>

                            <StyledAddButton type="button" onClick={handleAdd} disabled={isFormDisabled || isReadOnly}>
                                + Add
                            </StyledAddButton>

                            {!isFormDisabled && !isReadOnly? (
                                <StyledMemberButtonsContainer>
                                    <StyledMemberCancelButton type="button" onClick={handleCancel}>
                                        Cancel
                                    </StyledMemberCancelButton>
                                    <StyledMemberSaveButton type="submit">Save</StyledMemberSaveButton>
                                </StyledMemberButtonsContainer>
                            ) : (
                                ''
                            )}
                        </form>
                    </fieldset>
                </StyledMemberInfoContainer>
            )}
        </>
    )
}

export default Addresses
