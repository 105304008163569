import ConfirmationBox from 'components/ConfirmationBox'
import { FC } from 'react'
import { UI_COLORS } from 'theme'

interface IDisableUserConfirmationBox {
    userFirstName: string
    userLastName: string
    onConfirm: () => void
    onCancel: () => void
}

const DisableUserConfirmationBox: FC<IDisableUserConfirmationBox> = ({
    userFirstName,
    userLastName,
    onConfirm,
    onCancel
}) => (
    <ConfirmationBox
        title="Disable Account"
        body={`Are you sure you want to disable this account (${userFirstName} ${userLastName})? (You can enable it again later.)`}
        confirmButtonLabel="Disable"
        confirmButtonColor={UI_COLORS.red}
        onConfirm={onConfirm}
        onCancel={onCancel}
    />
)

export default DisableUserConfirmationBox
