export const impersonationUserIdKey = 'impersonatedUserId'

export const isImpersonating = () => {
    const queryString = window.location.search
    const queryParams = new URLSearchParams(queryString)
    const impersonating = queryParams.has(impersonationUserIdKey)
    return impersonating
}

export const getImpersonationId = () => {
    const queryString = window.location.search
    const queryParams = new URLSearchParams(queryString)
    return queryParams.get(impersonationUserIdKey)
}

export const appendImpersonatedUserId = (link: string) => {
    if (isImpersonating() && link.indexOf(impersonationUserIdKey) < 0) {
        return `${link}?${impersonationUserIdKey}=${getImpersonationId()}`
    } else {
        return link
    }
}
