import { IBannerIncentive, IMemberOutreach, IMemberOutreachSummary } from './../../pages/hcp/users/create/index.models'
import { createSlice } from '@reduxjs/toolkit'
import { ITopIncentive } from 'pages/hcp/users/create/index.models'
import axios from '../../utilities/axios'
import { dispatch } from 'redux/store'
import { createExceptionAwareAsyncThunk } from 'utilities/createExceptionAwareAsyncThunk'

export interface IIncentiveRequest {
    npi: string
}

export interface IFilteredIncentiveRequest {
    npi: string
    year: number | null
}

export interface IMemberOutreachSummaryRequest {
    npi: string
    year: number | null
    maxNumberOfSummaries: number | null
}

export interface IMemberOutreachRequest {
    npi: string
    pageNumber: number | null
    size: number | null
    sortColumn: string | null
    filter: string | null
    isDescending: boolean | null
    year: number | null
}

export interface IMemberOutreachDetailsRequest {
    npi: string
    memberId: string
    sortColumn: string | null
    isDescending: boolean | null
}

export type IncentiveState = {
    topIncentives: ITopIncentive[]
    bannerIncentive: IBannerIncentive
    memberOutreachSummaries: IMemberOutreachSummary[]
    memberOutreaches: IMemberOutreach[]
    memberOureachesRequestId: string | null
    memberOutreachesLoading: boolean
    totalNumberOfMemberOutreaches: number
    filteredNumberOfMemberOutreaches: number
    memberOutreachDetails: any
    memberOutreachDetailsLoading: any
    years: any
    lastUpdated: string
}

const initialBannerIncentive: IBannerIncentive = {
    npi: '',
    changeInMembers: null,
    numOfCurrentMembers: null,
    outreachMembers: null,
    totalIncentiveBudgeted: null,
    totalIncentiveEarned: 0,
    totalIncentiveRemaining: 0,
    lastUpdated: ''
}

const initialState: IncentiveState = {
    bannerIncentive: initialBannerIncentive,
    topIncentives: [],
    memberOutreachSummaries: [],
    memberOutreaches: [],
    memberOureachesRequestId: null,
    memberOutreachesLoading: false,
    totalNumberOfMemberOutreaches: 0,
    filteredNumberOfMemberOutreaches: 0,
    memberOutreachDetails: {},
    memberOutreachDetailsLoading: {},
    years: {},
    lastUpdated: null
}

export const incentiveSlice = createSlice({
    name: 'incentive',
    initialState,
    reducers: {
        resetIncentives: (state) => {
            state.topIncentives = []
            state.bannerIncentive = initialBannerIncentive
            state.memberOutreachSummaries = []
            state.memberOutreaches = []
            state.memberOureachesRequestId = null
            state.memberOutreachesLoading = false
            state.totalNumberOfMemberOutreaches = 0
            state.filteredNumberOfMemberOutreaches = 0
            state.memberOutreachDetails = {}
            state.memberOutreachDetailsLoading = {}
        },
        memberOutreachDetailsLoading: (state, action) => {
            state.memberOutreachDetailsLoading = { ...state.memberOutreachDetailsLoading, [action.payload]: true }
        },
        deleteMemberOutreachDetails: (state, action) => {
            state.memberOutreachDetails = { ...state.memberOutreachDetails, [action.payload]: undefined }
        },
        clearTopIncentives: (state) => {
            state.topIncentives = null
        },
        memberOutreachFeedbackRead: (state, action) => {
            state.memberOutreaches = state.memberOutreaches.map((x) => ({
                ...x,
                hasNewFeedback:
                    x.membid === action.payload.membid && x.npi === action.payload.npi ? false : x.hasNewFeedback
            }))
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchYearsForNpi.fulfilled, (state, action) => {
                state.years = { ...state.years, [action.meta.arg.npi]: action.payload.years }
            })
            .addCase(fetchTopIncentives.fulfilled, (state, action) => {
                state.topIncentives = action.payload.topIncentives
            })
            .addCase(fetchBannerIncentive.fulfilled, (state, action) => {
                state.bannerIncentive = action.payload.banner
            })
            .addCase(fetchMemberOutreachSummary.fulfilled, (state, action) => {
                state.memberOutreachSummaries = action.payload.memberOutreachSummaries
            })
            .addCase(fetchMemberOutreach.pending, (state, action) => {
                state.memberOureachesRequestId = action.meta.requestId
                state.memberOutreachesLoading = true
            })
            .addCase(fetchMemberOutreach.fulfilled, (state, action) => {
                if (state.memberOureachesRequestId === action.meta.requestId) {
                    state.memberOutreaches = action.payload.memberOutreaches
                    state.memberOutreachesLoading = false
                    state.totalNumberOfMemberOutreaches = action.payload.totalNumberOfMemberOutreaches
                    state.filteredNumberOfMemberOutreaches = action.payload.filteredNumberOfMemberOutreaches
                }
            })
            .addCase(fetchMemberOutreach.rejected, (state, action) => {
                if (state.memberOureachesRequestId === action.meta.requestId) {
                    state.memberOutreachesLoading = false
                }
            })
            .addCase(fetchMemberOutreachDetails.fulfilled, (state, action) => {
                state.memberOutreachDetails = {
                    ...state.memberOutreachDetails,
                    [action.meta.arg.memberId]: action.payload
                }
                state.memberOutreachDetailsLoading = {
                    ...state.memberOutreachDetailsLoading,
                    [action.meta.arg.memberId]: false
                }
            })
            .addCase(fetchMemberOutreachDetails.rejected, (state, action) => {
                state.memberOutreachDetailsLoading = {
                    ...state.memberOutreachDetailsLoading,
                    [action.meta.arg.memberId]: false
                }
            })
    }
})

export default incentiveSlice.reducer

export const { deleteMemberOutreachDetails, resetIncentives, clearTopIncentives, memberOutreachFeedbackRead } =
    incentiveSlice.actions

export const fetchYearsForNpi = createExceptionAwareAsyncThunk(
    'incentive/YearsForNpi',
    async (args: IIncentiveRequest) => {
        const response = await axios.post('api/Incentives/YearsForNpi', args)
        return response.data
    }
)

export const fetchTopIncentives = createExceptionAwareAsyncThunk(
    'incentive/getTopIncentives',
    async (args: IFilteredIncentiveRequest) => {
        const response = await axios.post('api/Incentives/TopIncentives', args)
        return response.data
    }
)

export const fetchBannerIncentive = createExceptionAwareAsyncThunk(
    'incentive/getBannerIncentive',
    async (args: IFilteredIncentiveRequest) => {
        const response = await axios.post('api/Incentives/BannerIncentive', args)
        return response.data
    }
)

export const fetchMemberOutreachSummary = createExceptionAwareAsyncThunk(
    'incentive/getMemberOutreachSummary',
    async (args: IMemberOutreachSummaryRequest) => {
        const response = await axios.post('api/Incentives/MemberOutreachSummary', args)
        return response.data
    }
)

export const fetchMemberOutreach = createExceptionAwareAsyncThunk(
    'incentive/getMemberOutreach',
    async (args: IMemberOutreachRequest) => {
        const response = await axios.post('api/Incentives/MemberOutreach', args)
        return response.data
    }
)

export const fetchMemberOutreachDetails = createExceptionAwareAsyncThunk(
    'incentive/getMemberOutreachDetails',
    async (args: IMemberOutreachDetailsRequest) => {
        dispatch(incentiveSlice.actions.memberOutreachDetailsLoading(args.memberId))
        const response = await axios.post('api/Incentives/MemberOutreachDetails', args)
        return response.data
    }
)
