import { IDropDownListStyleParams } from 'components/DropDownList/index.style'

export const npiDropDownListStyleParams: IDropDownListStyleParams = {
    width: 300,
    indicatorLeftTranslate: 30,
    indicatorMarginTop: '-40px',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    optionColor: '#2281C4',
    backgroundColor: '#2281C4',
    paddingTop: '15px',
    paddingLeft: '20px',
    optionPaddingLeft: '30px',
    singleValueTextAlign: 'center',
    singleValueMaxWidth: '250px',
    singleValueColor: 'white',
    marginTop: '0'
}
