import useMemberProfileLookupData from 'hooks/useMemberProfileLookupData'
import { FC, useEffect, useState } from 'react'
import { DropDownList } from '../DropDownList'
import { ILanguagePicker, ISectionElement } from '../index.models'

interface ILanguagePickerProps {
    name: string
    label: string
    subLabel: string
    placeholder: string
}

const LanguagePicker: FC<ILanguagePickerProps> = ({ name, label, subLabel, placeholder }) => {
    const languages = useMemberProfileLookupData()?.languages
    const [languageOptions, setLanguageOptions] = useState([])

    useEffect(() => {
        if (languages) {
            setLanguageOptions(languages.map((x) => ({ label: x.name, value: x.name })))
        }
    }, [languages])

    return (
        <DropDownList
            name={name}
            options={languageOptions}
            label={label}
            subLabel={subLabel}
            placeholder={placeholder}
        />
    )
}

export const renderLanguagePicker = (element: ISectionElement, key: string) => {
    const languagePicker = element as ILanguagePicker
    return <LanguagePicker key={key} {...languagePicker} />
}
