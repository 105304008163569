export interface IUser {
    sid: string
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    isActive: boolean
    role: number
    lastLogin: string
    id: string
    roleIds: string
    taxIdProviderGroupings: ITaxProviderGrouping[]
    avatarUrl: string
    invitationSent: string
    invitationExpired: boolean
    daysToInvitationExpiry: number
    userState: string
    newReportCount: number
    defaultCredential: number
}

export interface IOfficeAdminServerUpdate {
    userId: string
    sid: string
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    role: number
    taxIdProviderGroupings: ITaxProviderGrouping[]
}

export interface IEditAddress {
    isEditable: boolean
    address: any
    id: number | null
    isNewAddress: boolean
}

export interface FullLocationIndexes {
    taxIdIndex: number
    npiIndex: number
    locationIndex: number
}

export const emptyLocationError: ILocationError = {
    locationTypeError: false,
    officePhoneError: false,
    locationAddressError: false,
    locationTypeErrorMessage: '',
    officePhoneErrorMessage: '',
    locationAddressErrorMessage: ''
}

export const emptyAddress: ILocationData = {
    locationId: null,
    locationType: 1,
    officePhoneNumber: '',
    suite: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    ezCapLocationId: null,
    selectedLocationIndex: -1,
    error: false,
    errorMessage: '',
    locationErrors: { ...emptyLocationError }
}

export interface IOfficeAdminClientUpdate {
    userId: string
    sid: string
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    role: number
    createdBy: string
    taxIdProviderGroupings: ITaxIdNpiData[]
}

export interface ITaxProviderGrouping {
    taxId: string
    providers: IProvider[]
}

export interface IProvider {
    npi: string
    locations: ILocation[]
}

export interface IProviderCommonDetails {
    userId: string
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    role: number
    npi: string
    locations: ILocation[]
    defaultCredentialId: number
}

export interface IProviderCreateDetails extends IProviderCommonDetails {
    createdBy: string
}

export interface IProviderUpdateDetails extends IProviderCommonDetails {
    updatedBy: string
}

export interface ILocation {
    locationId: string | null
    locationType: number
    officePhoneNumber: string
    suite: string
    address: string
    city: string
    state: string
    zipCode: string
    ezCapLocationId: number | null
}

export interface IErrorInfo {
    error: boolean
    errorMessage: string
}

export interface ILocationError {
    locationTypeError: boolean
    officePhoneError: boolean
    locationAddressError: boolean
    locationTypeErrorMessage: string
    officePhoneErrorMessage: string
    locationAddressErrorMessage: string
}

export interface ILocationData extends IErrorInfo {
    locationId: string | null
    selectedLocationIndex: number
    locationType: number
    officePhoneNumber: string
    address: string
    suite: string
    city: string
    state: string
    zipCode: string
    ezCapLocationId: number | null
    locationErrors?: ILocationError | null
    locationAddress?: any
}

export interface INpiLocationsOption {
    label: string
    value: {
        location: ILocationDetailData
    }
}

export interface ILocationDetailData {
    isPrimary: boolean
    locationId: string | null
    address1: string
    address2: string
    city: string
    state: string
    zip: string
    ezCapLocationId: number | null
    officePhoneNumber: string
}

export interface INPILocationsData extends IErrorInfo {
    npi: string
    locations: ILocationData[]
}

export interface ITaxIdNpiData extends IErrorInfo {
    taxId: string
    providers: INPILocationsData[]
}

export interface ITaxProviderInfo extends IErrorInfo {
    taxId: string
    providers: IProviderData[]
}

export interface IProviderData {
    firstName: string
    lastName: string
    npi: string
}

export interface IEmailValidation {
    emailExists: boolean
}

export interface IAreaCodeValidation {
    areaCodeExists: boolean
}

export interface IAreaCodeResult {
    areaCodes: number[]
}

export interface INPIDetail {
    firstName: string
    lastName: string
    npi: string
}

export interface IOfficeAdminProviderInfo {
    taxIdProviderGroupings: ITaxIdNpiData[]
}

export interface IProviderUpdateFormData {
    firstName: string
    lastName: string
    phoneNumber: string
    email: string
    npi: string
    locations: ILocation[]
    role: number
    createdBy: string
}

export interface IUpdateProviderDataRequest {
    userId: string
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    role: number
    npi: string
    locations: ILocation[]
    updatedBy: string
}

export interface ICreateProviderDataRequest {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    role: number
    npi: string
    locations: ILocation[]
    createdBy: string
}

export interface ISecurityUser {
    sid: string
    userId: string
    isImpersonated: boolean
    emailAddress: string
    firstName: string
    lastName: string
    fullName: string
    primaryRole: number
}

/// **************   Incentives **************
export interface IBannerIncentive {
    npi: string
    totalIncentiveBudgeted: number | null
    totalIncentiveEarned: number
    totalIncentiveRemaining: number
    numOfCurrentMembers: number | null
    changeInMembers: number | null
    outreachMembers: number | null
    lastUpdated: string
}

export interface ITopIncentive {
    npi: string
    incentive: string
    totalIncentiveBudgeted: number | null
    totalMembers: number | null
    totalIncentiveEarned: number
    totalMembersEarned: number
    totalIncentiveRemaining: number
    totalMembersNotEarned: number
    totalIncentiveClosed: number
    totalMembersClosed: number
}

export interface IMemberOutreachSummary {
    npi: string
    membid: string
    memberName: string
    memberFirstName: string
    memberLastName: string
    memberDateOfBirth: string
    pendingIncentives: number | null
    incentiveRemaining: number | null
    opportunity: string
}

export interface IMemberOutreach {
    npi: string
    membid: string | null
    memberName: string
    memberDateOfBirth: string | null
    pendingIncentives: number | null
    incentiveRemaining: number | null
    incentiveEarned: number | null
    totalIncentive: number | null
    opportunity: string | null
    phoneNumber: string | null
    status: string
    hasFeedback: boolean
    hasNewFeedback: boolean
}

export interface IMemberOutreachDetail {
    npi: string
    membid: string | null
    memberName: string
    memberDateOfBirth: string | null
    incentive: string
    totalIncentive: number | null
    incentiveEarned: number | null
    incentiveRemaining: number | null
    status: string
}

export interface IMemberOutreachDetails {
    memberOutreachDetails: IMemberOutreachDetail[]
    feedback: string | null
}

// ***** App Insights Interfaces

export interface IUserIdTracker {
    user: string
    address: string
}

export interface IMemberProfileLocation {
    memberAddressId: number
    addressType: IMemberDataItem
    streetAddress: string
    houseOrApartmentNumber: string
    city: string
    state: string
    zipCode: string
}

export interface IMemberProfileClientSelectItem {
    label: string
    value: string
}

export interface IMemberProfileLocationClient {
    memberAddressId: number
    addressType: number
    streetAddress: string
    houseOrApartmentNumber: string
    city: string
    state: IMemberProfileClientSelectItem
    zipCode: string
}

export interface IMemberDataItem {
    id: number
    name: string
}

export interface IMemberProfileDetails {
    memberId: string
    membId: string
    firstName: string
    lastName: string
    dateOfBirth: string
    age: number
    gender: IMemberDataItem
    homePhone: string
    workPhone: string
    mobilePhone: string
    email: string
    preferredLanguage: IMemberDataItem
    eContactFirstName: string
    eContactLastName: string
    eContactRelationship: IMemberDataItem
    eContactPhone: string
    locations: IMemberProfileLocation[]
    benefits: IMemberProfileBenefit[]
    npi: string
    isEligible: boolean
    healthCareProxyFirstName: string
    healthCareProxyLastName: string
    healthCareProxyPhone: string
    healthCareProxyRelationship: IMemberDataItem
}

export interface IAwvSummary {
    $type: string
    providerNpi: string
    providerFirstName: string
    providerLastName: string
    year: number
    status: string
    statusEffective: string
    awvId: string
    statusId: string
    formTypeName: string
}
export interface IAwvHistory {
    fileName: string
    type: string
    addedOn: string
    status: string
}

export interface IAwvFormDocumentResponseSummary extends IAwvSummary {
    fileName: string
    contentType: string
    length: number
}

export interface IAwvFormElectronicResponseSummary extends IAwvSummary {
    formTypeId: string
    formTypeName: string
    validationErrorCount: number
}

export interface awvSummaries {
    awvSummaries: IAwvSummary[]
}

export interface awvHistories {
    awvHistories: IAwvHistory[]
}

export interface IAwvScheduleStatus {
    awvStatusId: string
    awvStatus: string
    scheduledDOS?: string
}

export interface IAwvServiceStatus {
    awvScheduleStatus: IAwvScheduleStatus
    lastDateOfService?: string
}

export interface IAwvServiceStatusRequest {
    npi: string
    memberId: number
}

export interface IAwvSupportingDocument {
    id: string
    fileName: string
    contentType: string
    length: number
    uploadedOn: string
    uploadedBy: string
}

export interface IUploadAwvSupportingDocumentRequest {
    memberId: number
    npi: string
    year: number
    file: File
}

export interface IUploadAwvSupportingDocumentResponse {
    awvSupportingDocument: IAwvSupportingDocument
}

export interface IGetAwvSupportingDocumentsRequest {
    memberId: number
    npi: string
    year: number
}

export interface IGetAwvSupportingDocumentsResponse {
    awvSupportingDocuments: IAwvSupportingDocument[]
}

export interface IDownloadAwvSupportingDocumentRequest {
    awvSupportingDocumentId: string
}

export interface IDeleteAwvSupportingDocumentRequest {
    awvSupportingDocumentId: string
}

export interface IMemberProfileBenefit {
    isEligible: boolean
    coveredFrom: string
    coveredThru: string
    healthPlanName: string
    lineOfBusiness: string
}

export interface IMemberProfilePersonalDetails {
    npi: string
    memberId: number
    firstName: string
    lastName: string
    dateOfBirth: string
    age: number
    gender: IMemberDataItem
    homePhone: string
    workPhone: string
    mobilePhone: string
    email: string
    preferredLanguage: IMemberDataItem
    eContactFirstName: string
    eContactLastName: string
    eContactPhone: string
    eContactRelationship: IMemberDataItem
    healthCareProxyFirstName: string
    healthCareProxyLastName: string
    healthCareProxyPhone: string
    healthCareProxyRelationship: IMemberDataItem
    lastUpdatedBy: string
}

export interface IMemberProfileAddressDetails {
    npi: string
    memberId: number
    Locations: IMemberProfileLocation[]
    lastUpdatedBy: string
}

export interface IMemberProfileScheduleAwvData {
    npi: string
    memberId: number
    scheduledDOS: string
}

export interface IMemberProfileUnscheduleAwvData {
    npi: string
    memberId: number
}

export interface IDownloadAwvFileData {
    memberId: number
    npi: string
}

export interface IAWVUploadFileRequest {
    memberId: number
    npi: string
    year: number
    file: File
}

export interface IMemberProfile {
    memberId: number | null
    membID: string
    firstName: string
    lastName: string
    dateOfBirth: string
    provider: string
    providerNpi: string
    phoneNumber: string
    email?: string
    lineOfBusiness: string
    healthPlan: string
    status: string
    lastPCPVisitDate: string
    nextAWVDate: string
    awvStatus: string
    awvScheduledDate: string
    awvSubmissionDate: string
    hasNewFeedback: boolean
    awvEncouterFilesCount: number
}

export interface IMemberProfileAwvScheduleStatus {
    awvStatus: string
    scheduledDOS: string
}

export interface IMemberProfileDataItem {
    id: number
    name: string
}

export interface IMemberProfileLookupData {
    relationships: IMemberProfileDataItem[]
    genders: IMemberProfileDataItem[]
    addressTypes: IMemberProfileDataItem[]
    languages: IMemberProfileDataItem[]
    credentials: IMemberProfileDataItem[]
}

export enum ConditionFeedbackStatus {
    ConditionPresent,
    ConditionNotPresent,
    NeedsFurtherEvaluation
}

export enum QualityFeedbackStatus {
    CareActionTaken,
    UpToDate,
    PatientRefused,
    NeedsFurtherEvaluation
}

export enum ConfidenceLevel {
    Low,
    High,
    VeryHigh
}

export enum QualityStatus {
    Open,
    Closed,
    Scheduled
}

export interface IPointsInCareReview {
    targetedConditionUnderReviewId: string
    pointOfCareRecaptureId: string
    recaptureStatus: string
    confidence: number
    diagnosisCode: string
    hcc: string
    conditionCategoryDescription: string
    lastReported: string
    reportingProvider: string
    feedback: ConditionFeedbackStatus | null
    reviewDocuments: IReviewDocument[]
}

export interface IPointsInCareConsideration {
    targetedConditionForConsiderationId: string
    confidence: ConfidenceLevel
    suspectedConditionToConsider: string
    primaryConditionIndicator: string
    feedback: ConditionFeedbackStatus | null
}

export interface IQualityDocument {
    qualityDocumentId: string
    documentId: string
    dateOfSubmission: string | null
    documentType: string
    documentName: string
}

export interface IReviewDocument {
    reviewDocumentId: string
    documentId: string
    dateOfSubmission: string | null
    documentType: string
    documentName: string
}

export interface IAwvPointInCare {
    scheduledDateOfService: string | null
}

export interface IQualityPointsInCare {
    awv: IAwvPointInCare
    qualities: IPointsInCareQuality[]
}

export interface IPointsInCareQuality {
    targetedConditionQualityId: string
    gapStatus: string
    gapInCareId: string
    pointOfCareGapId: string
    status: QualityStatus
    diagnosisCode: string
    notes: string
    dateOfService: string
    referral: ISpecialist | null
    feedbackStatus: QualityFeedbackStatus | null
    qualityDocuments: IQualityDocument[]
}

export interface IPointsInCareSupportingDoc {
    supportDocumentId: string
    documentId: string
    pointOfCareId: string
    dateOfSubmission: string
    documentName: string
    documentType: string
}

export interface IPointsInCare {
    reviews: IPointsInCareReview[]
    considerations: IPointsInCareConsideration[]
    quality: IPointsInCareQuality[]
    support: IPointsInCareSupportingDoc[]
}

export interface IPointsInCareAwv {
    documentId: string
    documentName: string
    lastName: string
    firstName: string
    dateOfSubmission: string
    scheduledDate: string
}

export interface ISpecialist {
    id: string // guid
    npi: string
    firstName: string
    lastName: string
    specialty: string
    locations: any
}

export interface IFeedbackDataItem {
    isProvider: boolean
    feedback: string
    feedbackDate: string
    feedbackByFirstName: string
    feedbackByLastName: string
    feedbackByRole: string
}

export interface IFeedbackData {
    memberId: number
    speciality: string
    npi: string
    feedbacks: IFeedbackDataItem[]
    title: string
}

export interface IQualityFeedbackRequest {
    npi: string
    memberId: number
    pointOfCareId: string
    gapInCareId: string
    pointOfCareGapId: string
}

export interface IReviewFeedbackRequest {
    npi: string
    memberId: number
    pointOfCareId: string
    pointOfCareRecaptureId: string
}

export interface IAwvFeedbackRequest {
    npi: string
    memberId: number
    awvStatusId: string
}

export interface IGetTreatmentPlanRequest {
    recaptureId: string
}

export interface ISaveTreatmentPlanRequest {
    recaptureId: string
    treatmentPlan: string
}

export interface IAddQualityFeedbackRequest extends IQualityFeedbackRequest {
    npi: string
    memberId: number
    pointOfCareId: string
    gapInCareId: string
    pointOfCareGapId: string
    feedbackText: string
}

export interface IAddReviewFeedbackRequest extends IQualityFeedbackRequest {
    npi: string
    memberId: number
    pointOfCareId: string
    pointOfCareRecaptureId: string
    feedbackText: string
}

export interface IDownloadGapFormRequest {
    documentId: string
    memberId: number
    npi: string
    gap: string
    fileName: string
}

export interface IDeletePointsInCareDocument {
    pointOfCareDocumentId: string
    documentId: string
}

export interface IUpdateGapFormResponse {
    gapInCareId: string
    documentInfo: IQualityDocument
}

export interface IUpdateSupportDocumentResponse {
    pointOfCareId: string
    documentInfo: IPointsInCareSupportingDoc
}

export interface ISpecialistRequest {
    filter: string
    specialty?: string
}

export interface ISpecialistTypeRequest {
    filter: string
}

export interface IUpdateReferralRequest {
    pointsOfCareId: string
    pointsInCareGapId: string
    gapInCareId: string
    memberId: number
    npi: string
    referralId: string
}

export interface IMemberPointsInCareRequest {
    memberId: number
    npi: string
}

export interface IGetAwvFeedbackRequest {
    awvId: string
}

export interface IGetMemberFeedbackRequest {
    membid: string
    npi: string
}

export interface IGetProviderDocumentFeedbackRequest {
    providerDocumentId: string
}

export interface IGetGapInCareFeedbackRequest {
    gapInCareId: string
    npi: string
}

export interface IAddFeedbackRequestBase {
    parentId: string
    feedbackText: string
}

export interface IAddAwvFeedbackRequest extends IAddFeedbackRequestBase {
    awvId: string
}

export interface IAddMemberFeedbackRequest extends IAddFeedbackRequestBase {
    membid: string
    npi: string
}

export interface IAddProviderDocumentFeedbackRequest extends IAddFeedbackRequestBase {
    providerDocumentId: string
}

export interface IAddGapInCareFeedbackRequest extends IAddFeedbackRequestBase {
    gapInCareId: string
    npi: string
}

export interface IFeedbackItem {
    id: string
    parentId: string
    feedbackText: string
    feedbackDate: string
    byName: string
    bySide: string
    byRoleEnum: number
    firstReadBy: string
    firstReadDate: string
    isNew: boolean
    isDeleted: boolean
    level: number
}

export interface IHasNewFeedbackResponse {
    hasNewFeedback: boolean
    newFeedbackCount: number
}

export interface IGetGapsInCareMemberYearProvidersRequest {
    memberId: number
}

export interface IGapsInCareYearProvider {
    year: number
    providerNpi: string
    providerFirstName: string
    providerLastName: string
}

export interface IGetGapsInCareMemberNpiYearsRequest {
    memberId: number
    npi: string
}

export interface IGetGapsInCareRequest {
    memberId: number
    year: number
    npi: string
}

export interface IGetGapInCareRequest {
    gapInCareId: string
    npi: string
}

export interface IGapInCareDocument {
    id: string
    fileName: string
    uploadedOn: string
}

export interface IGapInCare {
    id: string
    gap: string
    isCompliant: boolean
    isUserCreated: boolean
    lastCompletionDate: string
    referralSpecialistId: string
    referralFullName: string
    referralSpecialty: string
    serviceNote: string
    newFeedbackCount: number
    documents: IGapInCareDocument[]
}

export interface IGetAvailableGapsRequest {
    memberId: number
    year: number
}

export interface ICreateGapInCareRequest {
    memberId: number
    year: number
    gapId: string
    lastCompletionDate: string
    serviceNote: string
}

export interface IUpdateGapInCareRequest {
    gapInCareId: string
    npi: string
    lastCompletionDate: string
    serviceNote: string
}

export interface IDeleteGapInCareRequest {
    gapInCareId: string
}

export interface IGap {
    id: string
    name: string
    healthPlan: string
}

export interface IGetReferralSpecialistRequest {
    referralSpecialistId: string
}

export interface IGetReferralSpecialistsRequest {
    filter: string
}

export interface IReferralSpecialist {
    id: string
    fullName: string
    specialty: string
    locations: string
}

export interface ISetGapInCareReferralSpecialistRequest {
    gapInCareId: string
    referralSpecialistId: string
}

export interface IClearGapInCareReferralSpecialistRequest {
    gapInCareId: string
}

export interface IUploadGapInCareDocumentRequest {
    gapInCareId: string
    file: File
}

export interface IDownloadGapInCareDocumentRequest {
    gapInCareDocumentId: string
}

export interface IDeleteGapInCareDocumentRequest {
    gapInCareDocumentId: string
}

export interface IGetAwvFormGapsInCareRequest {
    memberId: number
    npi: string
    year: number
}
