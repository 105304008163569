import { FC } from 'react'
import Select from 'react-select'
import { IDropDownListStyleParams, createDropdownIndicator, createStyles } from './index.style'

export interface IOption {
    label: string
    value: any
}

interface IDropDownListProps {
    options: IOption[]
    selectedValue: any
    setSelectedValue: (value: any) => void
    id?: string
    placeholder?: string
    isDisabled?: boolean
    isSearchable?: boolean
    isReadOnly?: boolean
    isInError?: boolean
    styleParams?: IDropDownListStyleParams
}

const DropDownList: FC<IDropDownListProps> = ({
    options,
    selectedValue,
    setSelectedValue,
    id,
    placeholder,
    isDisabled,
    isSearchable,
    isReadOnly,
    isInError,
    styleParams
}) => {
    const selectedOption = options?.find((x) => x.value === selectedValue)

    const setSelectedOption = (selectedOption: IOption) => {
        setSelectedValue(selectedOption.value)
    }

    const actualStyleParams = styleParams ?? {}

    return (
        <div>
            <Select
                value={options?.find((x) => x.value === selectedOption?.value) ? selectedOption : null}
                id={id}
                aria-live="polite"
                isMulti={false}
                isSearchable={isSearchable}
                components={{ DropdownIndicator: createDropdownIndicator(actualStyleParams) }}
                onChange={setSelectedOption}
                styles={createStyles(actualStyleParams, isReadOnly, isInError)}
                options={options}
                placeholder={placeholder}
                isDisabled={isDisabled}
                menuPlacement="auto"
                menuPosition="fixed"
                minMenuHeight={300}
                menuPortalTarget={document.getElementById('WrappedDropDown')}
            />
        </div>
    )
}

export default DropDownList
