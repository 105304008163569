import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 10000
})

instance.interceptors.request.use(async (config) => {
    if (config.headers) {
        config.headers['Timezone-Offset'] = new Date().getTimezoneOffset()
    }
    return config
})

export default instance
