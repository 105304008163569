import { INavBarConfig } from '../members/information/NavBar/NavBarConfig'
import { calendarIcon } from '../members/information/NavBar/icons'
import { EAdminNavBarItems } from './EAdminNavBarItems'
import { pageTitle } from 'appInsights/appInsights'

export const AdminNavBarConfig: INavBarConfig[] = [
    {
        icon: calendarIcon,
        label: EAdminNavBarItems.AwvPdfTemplateUpload,
        link: '',
        pageTitle: pageTitle.AdminUpdateAWVPDFTemplate
    },
    {
        icon: calendarIcon,
        label: EAdminNavBarItems.AwvElectronicTemplateUpload,
        link: '',
        pageTitle: pageTitle.AdminUpdateAWVElectronicTemplate
    }
]
