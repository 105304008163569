import styled from 'styled-components'
import { UI_COLORS } from 'theme'
import { DEVICE, pxToRem } from 'utilities/style'

const { light_blue2, white, dark_gray } = UI_COLORS

export const StyledMemberOutreachContainerWrap = styled.article`
    padding-top: ${pxToRem(30)};
    padding-left: ${pxToRem(30)};
    max-width: 1205px;
    margin: 0 auto;
`

export const StyledButtonsContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export const StyledBackButton = styled.button`
    color: ${light_blue2};
    /* margin-left: ${pxToRem(15)}; */
    background-color: transparent;
    border: 0;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};

    cursor: pointer;

    figure {
        margin: ${pxToRem(0)};
        margin-right: ${pxToRem(15)};
        span {
            svg {
                path {
                    fill: ${light_blue2};
                }
            }
        }
    }
`

export const StyledHeader = styled.article`
    display: flex;
    flex-direction: row;
    padding-top: ${pxToRem(16)};
    padding-right: ${pxToRem(30)};
    padding-bottom: ${pxToRem(14)};

    p {
        flex-grow: 1;
        margin: ${pxToRem(5)} 0 0 0;

        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(26)};
        line-height: 120%;

        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: ${dark_gray};

        ${DEVICE.tablet} {
            font-size: ${pxToRem(26)};
            line-height: ${pxToRem(31)};
        }
    }
`

export const StyledActionsContainer = styled.article`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${pxToRem(30)};
    padding-right: ${pxToRem(30)};

    ${DEVICE.tablet} {
        flex-direction: row;
        padding-right: 0;
        height: ${pxToRem(70)};
    }

    ${DEVICE.desktop} {
        width: ${pxToRem(1145)};
    }
`

export const StyledSearchBarContainer = styled.article`
    width: 100%;
    height: ${pxToRem(50)};
    margin-bottom: ${pxToRem(16)};

    ${DEVICE.tablet} {
        width: 50%;
        margin-bottom: ${pxToRem(0)};
    }
`

export const StyledButton = styled.button`
    width: 100%;
    height: ${pxToRem(50)};

    background-color: ${light_blue2};
    border: 0;
    color: ${white};
    border-radius: 4px;

    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    ${DEVICE.tablet} {
        width: ${pxToRem(200)};
        margin-right: ${pxToRem(35)};
    }

    ${DEVICE.desktop} {
        margin-right: ${pxToRem(0)};
    }

    :hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
    }

    &:active {
        border: 1px solid ${light_blue2};
        background: transparent;
        color: ${light_blue2};

        figure {
            span {
                svg {
                    path {
                        fill: ${light_blue2};
                    }
                }
            }
        }
    }

    figure {
        margin: 0;
        margin-right: ${pxToRem(15)};
        transform: translateY(1px);
        span {
            svg {
                width: ${pxToRem(15)};
                height: ${pxToRem(15)};
            }
        }
    }
`

export const StyledTableWrap = styled.article`
    overflow: auto;
    padding-right: ${pxToRem(30)};
`

export const StyledEmptyState = styled.article`
    width: 100%;
    height: ${pxToRem(250)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h4 {
        margin: 0;
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(22)};
        line-height: ${pxToRem(26)};
        letter-spacing: -0.01em;
        color: #293854;
    }

    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(18)};

        color: #73839c;
    }
`
