import styled from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { light_blue2, white, light_gray4, grey, dark_gray } = UI_COLORS

export const StyledScheduleModalItemsContainer = styled.article`
    h2 {
        margin: 0;
        margin-bottom: ${pxToRem(16)};
    }
`

export const StyledCalendarContainer = styled.article`
    display: flex;
    flex-direction: column;

    p {
        margin: 0;
        color: red;

        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(20)};
    }
`

export const StyledCalendarWrapper = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;

    .MuiSvgIcon-root {
        color: ${light_blue2};
    }

    div {
        div {
            input {
                width: ${pxToRem(450)};
            }
        }
    }
`

export const StyledCurrentScheduledDOSContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: ${pxToRem(9)};

    p {
        width: 100%;
        text-align: right;
        padding-top: ${pxToRem(1)};
        padding-right: ${pxToRem(10)};
        margin: 0;
    }
`

export const StyledUnscheduleButton = styled.button`
    background-color: transparent;
    border: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    :hover {
        box-shadow: 0 ${pxToRem(2)} ${pxToRem(5)} rgba(0, 0, 0, 0.07);
        border-radius: ${pxToRem(20)};
        p {
            visibility: visible;
        }
    }

    width: ${pxToRem(30)};
    height: ${pxToRem(30)};
    margin-right: ${pxToRem(8)};

    p {
        background-color: ${dark_gray};
        padding: ${pxToRem(5)} 0;
        border-radius: ${pxToRem(4)};
        width: ${pxToRem(80)};
        position: absolute;
        top: ${pxToRem(120)};
        right: 10;
        color: ${white};
        text-align: center;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: ${pxToRem(12)};
        line-height: ${pxToRem(16)};
    }
`

export const StyledUnscheduleButtonTooltip = styled.p`
    background-color: ${dark_gray};
    padding: ${pxToRem(5)};
    border-radius: ${pxToRem(4)};
    margin-top: ${pxToRem(10)};
    width: ${pxToRem(70)};
    position: relative;
    right: 10;
    color: ${white};
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(16)};
    visibility: hidden;
`

export const StyledActionsContainer = styled.article`
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    margin-top: ${pxToRem(27)};
`

export const StyledButtonsContainer = styled.article``

export const StyledCancelButton = styled.button`
    width: ${pxToRem(130)};
    height: ${pxToRem(50)};

    background-color: ${grey};
    border: 0;
    border-radius: ${pxToRem(4)};

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};

    color: ${white};

    margin-right: ${pxToRem(35)};
    cursor: pointer;

    :active {
        background-color: ${white};
        color: ${grey};
        border: solid ${pxToRem(1)} ${grey};
    }
`

export const StyledSaveButton = styled.button`
    width: ${pxToRem(130)};
    height: ${pxToRem(50)};
    border: 0;
    background: ${light_blue2};
    border-radius: ${pxToRem(4)};

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};

    color: ${white};

    cursor: pointer;

    :active {
        background-color: ${white};
        color: ${light_blue2};
        border: solid ${pxToRem(1)} ${light_blue2};
    }

    :disabled {
        background-color: ${light_gray4};
        cursor: default;

        :active {
            background-color: ${light_gray4};
            color: ${white};
            border: solid ${pxToRem(1)} ${light_gray4};
        }
    }
`
