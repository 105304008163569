import Avatar from 'components/Avatar'
import { FC, useEffect, useRef, useState } from 'react'
import {
    StyledActionsContainer,
    StyledLastLoginContainer,
    StyledTableBodyItem,
    StyledTableBodyRow,
    StyledTableBodyRowWrap,
    StyledTableButton,
    StyledTableHeaderItem,
    StyledTableHeaderRow,
    StyledUsersTableContainer
} from './index.style'
import { ETableType, setFormatedDateTime } from './index.utils'
import { StatusBadge } from 'components/StatusBadge'
import { Link, useNavigate } from 'react-router-dom'
import { ERoleLabel, ERoles } from 'models/enums/role'
import { EStatusType } from 'components/StatusBadge/index.utils'
import { IUser } from 'pages/hcp/users/create/index.models'
import { useDispatch } from 'redux/store'
import { disableUser, enableUser, resendInvitation } from 'redux/slices/user'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { arrow, emailAction, mirrorIcon, reportNewIcon, reportIcon, warningIcon } from './icons'
import { appPath } from 'utilities/appPath'
import { AsYouType } from 'libphonenumber-js'
import { impersonationUserIdKey } from 'utilities/impersonationUtilities'
import { appPathForRoutes } from 'utilities/appPathForRoutes'
import { checkCircleEnable, editPen, redCircleDisable } from 'components/PaginationController/icons'
import { IHeaderInfo } from 'models/headerInfo'
import { hideMainWindowScrollbars } from 'utilities'
import { SortDirection } from 'models/sortColumn'
import DisableUserConfirmationBox from 'components/DisableUserConfirmationBox'
import EnableUserConfirmationBox from 'components/EnableUserConfirmationBox'
import ResendInvitationConfirmationBox from 'components/ResendInvitationConfirmationBox'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import usePostCustomEvent from 'hooks/usePostCustomEvent'

interface IUsersProps {
    users: IUser[]
    tableType: ETableType
    headersList: IHeaderInfo[]
    sortColumn: string
    sortDirection: SortDirection
    sortCallback(column: string, direction: SortDirection): void
    isReadOnly: boolean
}

const UsersTable: FC<IUsersProps> = ({
    users,
    headersList,
    tableType,
    sortColumn,
    sortDirection,
    sortCallback,
    isReadOnly
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [isToggleEnabledModalShown, setIsToggleEnabledModalShown] = useState(false)
    const [isResendInvitationModalShown, setIsResendInvitationModalShown] = useState(false)
    const selectedUser = useRef<IUser | null>(null)

    useEffect(() => {
        hideMainWindowScrollbars(isToggleEnabledModalShown || isResendInvitationModalShown)
    }, [isToggleEnabledModalShown, isResendInvitationModalShown])

    const handleColumnClick = (column: string) => {
        if (column !== 'Actions') {
            sortCallback(
                column,
                column === sortColumn
                    ? sortDirection === SortDirection.ascending
                        ? SortDirection.descending
                        : SortDirection.ascending
                    : SortDirection.ascending
            )
        }
    }

    const userRole = (role: ERoles) => {
        return ERoleLabel[role - 1]
    }

    const handleEnableToggleClicked = (user: IUser) => {
        selectedUser.current = user
        setIsToggleEnabledModalShown(true)
    }
    const enableUserEvent = usePostCustomEvent(pageTitle.UsersSummary, customEvents.EnableUser)
    const disableUserEvent = usePostCustomEvent(pageTitle.UsersSummary, customEvents.DisableUser)

    const handleEnableStateForUser = (user: IUser) => {
        let USER_ID = { userId: user.id }

        if (user.isActive) {
            disableUserEvent()
            dispatch(disableUser(USER_ID))
        } else {
            enableUserEvent()
            dispatch(enableUser(USER_ID))
        }
    }

    const toggleUserEnabledState = () => {
        handleEnableStateForUser(selectedUser.current)
        // clear state after action
        selectedUser.current = null
        closeToggleEnabledModal()
    }

    const closeToggleEnabledModal = () => {
        setIsToggleEnabledModalShown(false)
    }

    const showResendInvitationEmailModal = (user: IUser) => {
        selectedUser.current = user
        setIsResendInvitationModalShown(true)
    }
    const resentInvitationEvent = usePostCustomEvent(pageTitle.UsersSummary, customEvents.ResendInvitation)
    const resendInvitationEmail = () => {
        resentInvitationEvent()
        dispatch(resendInvitation({ UserId: selectedUser.current.id }))
        selectedUser.current = null
        closeResendInvitationModal()
    }

    const closeResendInvitationModal = () => {
        setIsResendInvitationModalShown(false)
    }

    const userStatus = (status: boolean, ActiveDirectoryID: any) => {
        if (ActiveDirectoryID === null) {
            return EStatusType.PENDING
        }

        if (status === true) {
            return EStatusType.ACTIVE
        }

        if (status === false) {
            return EStatusType.INACTIVE
        }
    }

    const renderEnableDisableOrResendInvitationAction = (user: IUser, idx: number) => {
        return (
            <StyledTableButton
                size={'48'}
                onClick={() => (user.sid ? handleEnableToggleClicked(user) : showResendInvitationEmailModal(user))}
                tabIndex={1}
                title={user?.sid ? (user?.isActive ? 'Disable' : 'Enable') : 'Resend Invitation'}
                key={user?.sid ? (user?.isActive ? `disable-${idx}` : `enable-${idx}`) : `resendinvite-${idx}`}
            >
                {user?.sid ? (
                    user?.isActive ? (
                        <CustomSvgIcon iconSet={{ icon: redCircleDisable }} svg />
                    ) : (
                        <CustomSvgIcon iconSet={{ icon: checkCircleEnable }} svg />
                    )
                ) : (
                    <CustomSvgIcon iconSet={{ icon: emailAction }} svg />
                )}
            </StyledTableButton>
        )
    }

    const getLastLoginText = (user: IUser) => {
        if (user.invitationExpired) {
            return 'Invite Expired'
        } else if (user.daysToInvitationExpiry === 0) {
            return 'Invite Expires Today'
        } else if (user.daysToInvitationExpiry === 1) {
            return 'Invite Expires in 1 Day'
        } else if (user.daysToInvitationExpiry) {
            return `Invite Expires in ${user?.daysToInvitationExpiry} Days`
        } else if (user.lastLogin) {
            return setFormatedDateTime(user.lastLogin)
        }
    }
    const mirrorUserEvent = usePostCustomEvent(pageTitle.UsersSummary, customEvents.MirrorUser)
    const handleMirrorOnClick = () => {
        mirrorUserEvent()
    }

    return (
        <>
            {isToggleEnabledModalShown && selectedUser.current.isActive && (
                <DisableUserConfirmationBox
                    userFirstName={selectedUser.current.firstName}
                    userLastName={selectedUser.current.lastName}
                    onConfirm={toggleUserEnabledState}
                    onCancel={closeToggleEnabledModal}
                />
            )}

            {isToggleEnabledModalShown && !selectedUser.current.isActive && (
                <EnableUserConfirmationBox
                    userFirstName={selectedUser.current.firstName}
                    userLastName={selectedUser.current.lastName}
                    onConfirm={toggleUserEnabledState}
                    onCancel={closeToggleEnabledModal}
                />
            )}

            {isResendInvitationModalShown && (
                <ResendInvitationConfirmationBox
                    userFirstName={selectedUser.current.firstName}
                    userLastName={selectedUser.current.lastName}
                    onConfirm={resendInvitationEmail}
                    onCancel={closeResendInvitationModal}
                />
            )}

            <StyledUsersTableContainer>
                <StyledTableHeaderRow key={'main-table-header'}>
                    <StyledTableHeaderItem key={'spacer-header'} />
                    {headersList?.map((header: IHeaderInfo, idx: number) => (
                        <StyledTableHeaderItem
                            key={`header-${idx}`}
                            onClick={() => handleColumnClick(header.fieldName)}
                            active={sortDirection === SortDirection.ascending}
                            isAction={header.displayName === 'Actions'}
                        >
                            {header.displayName}
                            {sortColumn === header.fieldName && (
                                <CustomSvgIcon iconSet={{ icon: arrow }} svg></CustomSvgIcon>
                            )}
                        </StyledTableHeaderItem>
                    ))}
                </StyledTableHeaderRow>

                {users &&
                    users?.map((user: IUser, idx: number) => {
                        const userIsActive = user?.isActive
                        const userHasDirectoryId = user?.sid

                        return (
                            <StyledTableBodyRowWrap key={`user-${idx}`}>
                                <article
                                    role="button"
                                    onClick={() => navigate(appPath(`/users/${user.id}`))}
                                    tabIndex={1}
                                    key={`user-row-${idx}`}
                                >
                                    <StyledTableBodyRow key={`userRow-${idx}`}>
                                        <StyledTableBodyItem key={`avatar-${idx}`}>
                                            <Avatar
                                                containerSize={40}
                                                avatarUrl={user?.avatarUrl}
                                                key={`avatar-${idx}`}
                                            />
                                        </StyledTableBodyItem>
                                        <StyledTableBodyItem key={`firstName-${idx}`}>
                                            {user?.firstName}
                                        </StyledTableBodyItem>
                                        <StyledTableBodyItem key={`lastName-${idx}`}>
                                            {user?.lastName}
                                        </StyledTableBodyItem>
                                        <StyledTableBodyItem key={`phone-${idx}`}>
                                            {user?.phoneNumber && new AsYouType('US').input(user?.phoneNumber)}
                                        </StyledTableBodyItem>
                                        <StyledTableBodyItem key={`email-${idx}`}>{user?.email}</StyledTableBodyItem>
                                        <StyledTableBodyItem key={`role-${idx}`}>
                                            {userRole(user?.role)}
                                        </StyledTableBodyItem>

                                        <StyledTableBodyItem key={`status-${idx}`}>
                                            <StatusBadge
                                                status={userStatus(userIsActive, userHasDirectoryId)}
                                                key={`statusBadge-${idx}`}
                                            />
                                        </StyledTableBodyItem>
                                        <StyledTableBodyItem key={`lastLogin-${idx}`}>
                                            <StyledLastLoginContainer>
                                                {user && getLastLoginText(user)}
                                                {user?.invitationExpired && (
                                                    <CustomSvgIcon
                                                        iconSet={{ icon: warningIcon }}
                                                        outerMargin="0 0 0 5px"
                                                        svg
                                                    />
                                                )}
                                            </StyledLastLoginContainer>
                                        </StyledTableBodyItem>
                                    </StyledTableBodyRow>
                                </article>
                                {tableType === ETableType.SUPER_ADMIN ? null : (
                                    <article key={`actionsEdit-${idx}`}>
                                        <StyledActionsContainer key={`user-row-actions-admin-${idx}`}>
                                            {user.isActive && !isReadOnly && (
                                                <StyledTableButton
                                                    size={'48'}
                                                    onClick={() => navigate(appPath(`/users/edit/${user.id}`))}
                                                    tabIndex={1}
                                                    key={`editUser-${idx}`}
                                                    title={'Edit User'}
                                                >
                                                    <CustomSvgIcon iconSet={{ icon: editPen }} svg />
                                                </StyledTableButton>
                                            )}
                                            {!user.isActive && !isReadOnly && <StyledTableButton size={'48'} />}
                                            <StyledTableButton
                                                size={'48'}
                                                onClick={() => navigate(appPath(`/users/reports/${user.id}`))}
                                                tabIndex={1}
                                                key={`viewreports-${user.id}`}
                                                title={'Reports'}
                                            >
                                                <CustomSvgIcon
                                                    iconSet={{
                                                        icon: user.newReportCount > 0 ? reportNewIcon : reportIcon
                                                    }}
                                                    svg
                                                ></CustomSvgIcon>
                                            </StyledTableButton>

                                            {!isReadOnly && renderEnableDisableOrResendInvitationAction(user, idx)}

                                            {user?.sid ? (
                                                <Link
                                                    to={appPathForRoutes(
                                                        `/dashboard?${impersonationUserIdKey}=${user?.sid}`
                                                    )}
                                                    target="_blank"
                                                    style={{ textDecoration: 'none' }}
                                                >
                                                    <StyledTableButton
                                                        size={'48'}
                                                        tabIndex={1}
                                                        key={`mirror-${idx}`}
                                                        title={'Mirror'}
                                                        onClick={handleMirrorOnClick}
                                                    >
                                                        <CustomSvgIcon
                                                            iconSet={{ icon: mirrorIcon }}
                                                            svg
                                                        ></CustomSvgIcon>
                                                    </StyledTableButton>
                                                </Link>
                                            ) : (
                                                <a href={undefined}>
                                                    <StyledTableButton size={'48'} />
                                                </a>
                                            )}
                                        </StyledActionsContainer>
                                    </article>
                                )}
                            </StyledTableBodyRowWrap>
                        )
                    })}
            </StyledUsersTableContainer>
        </>
    )
}

export default UsersTable
