import { FC } from 'react'
import { DropDownList } from '../DropDownList'
import { IDropDownListBoolean, ISectionElement } from '../index.models'

interface IDropDownListBooleanProps {
    name: string
    label: string
    subLabel: string
    placeholder: string
}

const DropDownListBoolean: FC<IDropDownListBooleanProps> = ({ name, label, subLabel, placeholder }) => (
    <DropDownList
        name={name}
        label={label}
        subLabel={subLabel}
        placeholder={placeholder}
        options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
        ]}
    />
)

export const renderDropDownListBoolean = (element: ISectionElement, key: string) => {
    const dropDownListBoolean = element as IDropDownListBoolean
    return <DropDownListBoolean key={key} {...dropDownListBoolean} />
}
