import styled from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { white } = UI_COLORS

export const StyledTableQuestionnaireContainer = styled.article`
    margin-bottom: ${pxToRem(30)};
`

export const StyledTableQuestionnaireHeader = styled.ul`
    width: ${pxToRem(1145)};
    list-style: none;
    margin: 0;
    padding: 0;

    background-color: #f7f8fa;
    border: 1px solid #dee5ef;

    padding: 26px 30px;

    display: flex;
    flex-direction: row;
`
export const StyledTableQuestionnaireHeaderItem = styled.li`
    display: table;
    flex-direction: row;

    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        margin: 0;

        color: #73839c;

        display: table-cell;
        vertical-align: middle;
    }
`

export const StyledTableQuestionnaireRow = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;
    align-items: stretch;

    height: 100px;
`

export const StyledTableQuestionnaireBodyItem = styled.li`
    display: flex;
    align-items: center;
    background-color: ${white};

    padding-left: 30px;

    border-bottom: 1px solid #dee5ef;

    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        color: #293854;
    }
`

export const StyledTableQuestionnaireBodyItemLeft = styled(StyledTableQuestionnaireBodyItem)<{ isInError?: any }>`
    border-bottom: 1px solid #dee5ef;
    border-left: 1px solid #dee5ef;

    ${(props) => {
        if (props.isInError) {
            return `
                border-left: solid 3px red;
                padding-left: 28px;
        `
        }
    }}
`

export const StyledTableQuestionnaireBodyItemMiddle = styled(StyledTableQuestionnaireBodyItem)``

export const StyledTableQuestionnaireBodyItemRight = styled(StyledTableQuestionnaireBodyItem)`
    border-right: 1px solid #dee5ef;
`

export const StyledTableQuestionnaireBodyItemTotals = styled.li`
    display: flex;
    align-items: center;
    background-color: ${white};
    border-bottom: 1px solid #dee5ef;

    input {
        height: 50px;
        outline: none;
        padding-left: 10px;
        border: 1px solid #73839c;
        border-radius: 4px;
    }

    :nth-child(1) {
        border-left: 1px solid #dee5ef;
        padding-left: 30px;
        width: ${pxToRem(430)};
    }

    :nth-child(2) {
        padding-left: 30px;

        input {
            width: 80px;
            margin-right: ${pxToRem(40)};
        }
    }

    :nth-child(3) {
        width: 565px;
        border-right: 1px solid #dee5ef;
        input {
            width: 320px;
        }
    }
`
export const StyledResetContainer = styled.article`
    width: ${pxToRem(50)};
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        :disabled {
            visibility: hidden;
        }
    }
`
