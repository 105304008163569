import { StyledSmallSpinner, StyledSmallSpinnerContainer } from './index.style'

const SmallSpinner = () => (
    <StyledSmallSpinnerContainer>
        <StyledSmallSpinner viewBox="0 0 50 50">
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
        </StyledSmallSpinner>
    </StyledSmallSpinnerContainer>
)

export default SmallSpinner
