import styled from 'styled-components'
import { UI_COLORS } from 'theme'
import { DEVICE, pxToRem } from 'utilities'

const { white, grey, light_gray4, light_blue2 } = UI_COLORS

export const StyledGapContainer = styled.span`
    width: 100%;
    display: block;
    margin-bottom: ${pxToRem(24)};

    display: flex;
    flex-direction: column;

    label {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(18)};
        color: #293854;

        margin-bottom: ${pxToRem(15)};
    }

    input {
        width: ${pxToRem(400)};
        height: ${pxToRem(50)};
        background: #ffffff;
        border: 1px solid #dee5ef;
        border-radius: ${pxToRem(4)};
        border: 1px solid #73839c;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: ${pxToRem(16)};
        line-height: ${pxToRem(22)};
        color: #293854;

        padding-left: ${pxToRem(18)};
        padding-right: ${pxToRem(18)};

        :disabled {
            background-color: #f5f5f5;
        }
    }

    .MuiInputBase-input {
        padding: 0;
        padding-left: ${pxToRem(18)};
    }

    div {
        :before {
            border-bottom: none;
            :hover {
                border-bottom: none;
            }
        }
    }

    div {
        div {
            div {
                margin: 0;
            }
        }
    }
`

export const StyledFormTextAreaContainer = styled.span`
    width: 100%;
    display: block;
    margin-bottom: ${pxToRem(25)};

    label {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #293854;
        margin-bottom: 15px;
    }

    textarea {
        width: 100%;
        background: ${white};
        border: 1px solid #73839c;
        border-radius: 4px;
        margin-top: 15px;
        height: 150px;
        padding: 24px 18px;

        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #293854;
        margin-bottom: 5px;
        resize: none;

        ::placeholder {
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;

            color: #a4b0c0;
        }

        :disabled {
            background-color: #f5f5f5;
        }
    }
`

export const StyledButtonContainer = styled.article`
    display: flex;
    flex-direction: row;
    margin-top: ${pxToRem(25)};

    ${DEVICE.tablet} {
        position: absolute;
        bottom: 30px;
        right: 30px;
    }
`

export const StyledCancelButton = styled.button`
    width: ${pxToRem(120)};
    height: ${pxToRem(50)};
    border-radius: ${pxToRem(4)};
    border: 0px;
    cursor: pointer;

    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};

    background-color: ${grey};
    color: ${white};

    :active {
        background-color: ${white};
        color: ${grey};
        border: 1px solid ${grey};
    }

    :disabled {
        background-color: ${light_gray4};
        cursor: default;
    }
`

export const StyledConfirmButton = styled.button`
    width: ${pxToRem(120)};
    height: ${pxToRem(50)};
    border-radius: ${pxToRem(4)};
    border: 0px;
    cursor: pointer;

    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};
    color: ${white};
    background-color: ${light_blue2};
    margin-left: ${pxToRem(16)};

    :active {
        background-color: ${white};
        color: ${light_blue2};
        border: 1px solid ${light_blue2};
    }

    :disabled {
        background-color: ${light_gray4};
        cursor: default;

        :active {
            background-color: ${light_gray4};
            color: ${white};
            border: 1px solid ${light_gray4};
        }
    }
`
