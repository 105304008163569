import styled, { css } from 'styled-components'
import { pxToRem } from 'utilities'

export const StyledTopOppsContainer = styled.article`
    width: ${pxToRem(1145)};
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
    border-radius: 4px;

    margin-bottom: ${pxToRem(30)};
    padding: ${pxToRem(30)} ${pxToRem(30)} ${pxToRem(10)};
`

export const StyledTopOppsTitleContainer = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;

    margin-bottom: 32px;
`

export const StyledTopOppsGraphLegends = styled.li`
    display: flex;
    flex-direction: row;
    width: 55%;
`

export const StyledTopOppsGraphButtons = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;

    width: 45%;
`

export const StyledTopOppsTitleAndLeyends = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;

    width: 100%;
`

export const StyledTopOppsTitle = styled.li`
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(22)};
        line-height: ${pxToRem(26)};
        letter-spacing: -0.01em;
        color: #293854;
        margin: 0;
    }
`

export const StyledTopOppsLeyends = styled.li`
    width: 50%;
    margin-left: ${pxToRem(100)};
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(18)};
        color: #293854;
    }

    span {
        width: 12px;
        height: 12px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
        border-radius: 6px;

        :nth-child(1) {
            background: #e3eef3;
            margin-right: ${pxToRem(8)};
        }

        :nth-child(3) {
            background: #9addff;
            margin-left: ${pxToRem(24)};
            margin-right: ${pxToRem(8)};
        }

        :nth-child(5) {
            background: #26b7ff;
            margin-left: ${pxToRem(24)};
            margin-right: ${pxToRem(8)};
        }
    }
`

const buttonsStyles = css`
    background: #ffffff;
    border: 1px solid #dee5ef;
    width: ${pxToRem(125)};
    height: ${pxToRem(50)};

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
    color: #051226;

    cursor: pointer;
`

export const StyledTopOppsIncentiveButton = styled.button<{ isActive?: boolean }>`
    ${buttonsStyles}
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    ${(props) => {
        if (props.isActive) {
            return css`
                background: #2281c4;
                color: #ffffff;
            `
        }
    }}
`

export const StyledTopOppsMembersButton = styled.button<{ isActive?: boolean }>`
    ${buttonsStyles}
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    ${(props) => {
        if (props.isActive) {
            return css`
                background: #2281c4;
                color: #ffffff;
            `
        }
    }}
`

export const StyledTopOppsChartsWrapper = styled.article`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    /* identical to box height, or 16px */

    color: #73839c;
`

export const VerticalSeparator = styled.div`
    border-left: 0.5px solid #73839c;
    height: 166px;
    justify-content: center;
    align-items: center;
    width: 0px;
    margin: 5px;
    position: relative;
    top: -20px;
    margin-left: 20px;
`
export const StyledBarMarker = styled.line`
    stroke: rgb(41, 56, 84);
`

export const StyledBarMarkerText = styled.text`
    fill: #293854;
`

export const StyledAxisText = styled.text`
    fill: #73839c;
`

export const StyledYAxisText = styled.text`
    fill: #73839c;
`

export const StyledViewMoreButtonContainer = styled.article`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
`

export const StyledViewMoreButton = styled.button`
    border: none;
    background-color: transparent;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */

    color: #2281c4;

    cursor: pointer;
`

export const StyledViewMoreButtonWrap = styled.article<{ isActive?: boolean }>`
    display: flex;
    flex-direction: column;
    figure {
        margin: 0;
    }

    ${(props) => {
        if (props.isActive) {
            return css`
                figure {
                    transform: rotate(180deg);
                }
            `
        }
    }}

    cursor: pointer;
`

export const StyledViewMoreSection = styled.article`
    display: flex;
    flex-direction: row;
    transition: all 2s linear;
`

export const StyledCustomTooltip = styled.article`
    background-color: #ffffff;
    border-radius: 4px;
    padding: 16px;
    border: none !important;
    outline: transparent !important;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);

    h4 {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(16)};
        line-height: ${pxToRem(26)};
        letter-spacing: -0.01em;
        color: #293854;
        margin: 0;
        margin-bottom: 10px;
    }

    p {
        margin: 0;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 140%;
        color: #293854;
        margin-left: 20px;
    }
`

export const StyledTooltipItem = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

    span {
        margin-right: 8px;
        width: 12px;
        height: 12px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
        border-radius: 6px;
    }

    h5 {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 130%;

        color: #73839c;
        margin: 0;
    }
`

export const StyledTooltipTotal = styled.article`
    margin-top: 5px;
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    border-top: 1px solid #dee5ef;

    p {
        :nth-child(1) {
            margin: 0;
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 130%;

            color: #73839c;
        }

        :nth-child(2) {
            margin: 0;
            margin-left: 5px;
            font-family: 'Inter', sans-serif;

            font-style: normal;
            font-weight: 700;
            font-size: 10px;
            line-height: 140%;

            color: #293854;
        }
    }
`
