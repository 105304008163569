import CustomSvgIcon from 'components/CustomSvgIcon'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import useCurrentUser from 'hooks/useCurrentUser'
import { ERoles } from 'models/enums/role'
import { FC, useState } from 'react'
import { INavBarConfig } from '../members/information/NavBar/NavBarConfig'
import { EAdminNavBarItems } from './EAdminNavBarItems'
import { AdminNavBarConfig } from './AdminNavBarConfig'
import {
    StyledMenuButton,
    StyledMenuButtonContainer,
    StyledNavBarContainer,
    StyledNavBarList,
    StyledNavBarListItem
} from '../members/information/NavBar/index.style'
import useTrackPageView from 'hooks/useTrackPageView'

interface INavBarProps {
    navBarConfig: INavBarConfig[]
    selectedTab?: (selectedNavItem: string) => void
}

const NavBar: FC<INavBarProps> = ({ navBarConfig, selectedTab }) => {
    const [isMenuHidden, setIsMenuHidden] = useState<boolean>(true)
    const [tabLabel, setTabLabel] = useState<string>(EAdminNavBarItems.AwvPdfTemplateUpload)
    const trackPageView = useTrackPageView()

    const currentUser = useCurrentUser()
    const isAdmin =
        currentUser?.primaryRole === ERoles.CallCenterAdmin || currentUser?.primaryRole === ERoles.ProviderRep

    const handleNavBarItem = (selectedItem: string) => {
        selectedTab(selectedItem)
        setTabLabel(selectedItem)
        setIsMenuHidden(!isMenuHidden)

        if (selectedItem) {
            let navBarItems = Object.values(AdminNavBarConfig)

            const navBarConfigItem = navBarItems.find((navItem) => navItem.label === selectedItem)
            if (navBarConfigItem) {
                trackPageView(navBarConfigItem.pageTitle)
            }

            return navBarConfigItem !== null
            // use this object to config the icon and lable for navbar
        }
    }

    const handleMenu = () => {
        setIsMenuHidden(!isMenuHidden)
    }

    return (
        <StyledNavBarContainer>
            <StyledNavBarList isVisible={isMenuHidden}>
                {navBarConfig
                    .filter(
                        (navItem) =>
                            Object.values(EAdminNavBarItems).includes(navItem.label as EAdminNavBarItems) && isAdmin
                    )
                    .map((item: INavBarConfig, idx: number) => {
                        return (
                            <StyledNavBarListItem
                                onClick={() => handleNavBarItem(item.label)}
                                key={`navBarItem-${idx}`}
                                isActive={tabLabel === item.label}
                                selectedLabel={item.label}
                            >
                                <CustomSvgIcon iconSet={{ icon: item.icon }} svg></CustomSvgIcon>

                                {item.label}
                            </StyledNavBarListItem>
                        )
                    })}
            </StyledNavBarList>
            <StyledMenuButtonContainer>
                {isMenuHidden && <Typography type={TYPOGRAPHY_TYPES.p}>{tabLabel}</Typography>}
                <StyledMenuButton onClick={handleMenu}>Menu</StyledMenuButton>
            </StyledMenuButtonContainer>
        </StyledNavBarContainer>
    )
}

export default NavBar
