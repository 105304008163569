import { createSlice, Draft } from '@reduxjs/toolkit'
import {
    IClearGapInCareReferralSpecialistRequest,
    ICreateGapInCareRequest,
    IDeleteGapInCareDocumentRequest,
    IDeleteGapInCareRequest,
    IDownloadGapInCareDocumentRequest,
    IGap,
    IGapInCare,
    IGapInCareDocument,
    IGapsInCareYearProvider,
    IGetAvailableGapsRequest,
    IGetGapInCareRequest,
    IGetGapsInCareMemberNpiYearsRequest,
    IGetGapsInCareMemberYearProvidersRequest,
    IGetGapsInCareRequest,
    IGetReferralSpecialistRequest,
    IGetReferralSpecialistsRequest,
    IReferralSpecialist,
    ISetGapInCareReferralSpecialistRequest,
    IUpdateGapInCareRequest,
    IUploadGapInCareDocumentRequest
} from 'pages/hcp/users/create/index.models'
import { dispatch, dynamicStoreData } from 'redux/store'
import axios, { fileStreamTimeout } from 'utilities/axios'
import { createExceptionAwareAsyncThunk } from 'utilities/createExceptionAwareAsyncThunk'
import { addToast } from './toast'
import { successCheckmark } from 'components/Toast/icons'
import { EToastVariant } from 'models/IToast'

export type GapsInCareState = {
    yearProvidersByMemberId: dynamicStoreData<IGapsInCareYearProvider[]>
    yearsByMemberIdNpi: dynamicStoreData<number[]>
    gapsInCareByMemberIdYearNpi: dynamicStoreData<IGapInCare[]>
    gapsInCareById: dynamicStoreData<IGapInCare>
    availableGapsByMemberIdYear: dynamicStoreData<IGap[]>
    referralSpecialistsById: dynamicStoreData<IReferralSpecialist>
    referralSpecialistsByFilter: dynamicStoreData<IReferralSpecialist[]>
}

const initialState: GapsInCareState = {
    yearProvidersByMemberId: {},
    yearsByMemberIdNpi: {},
    gapsInCareByMemberIdYearNpi: {},
    gapsInCareById: {},
    availableGapsByMemberIdYear: {},
    referralSpecialistsById: {},
    referralSpecialistsByFilter: {}
}

export const getMemberIdNpiKey = (memberId: number, npi: string) => `${memberId}-${npi}`

export const getMemberIdYearNpiKey = (memberId: number, year: number, npi: string) => `${memberId}-${year}-${npi}`

export const getMemberIdYearKey = (memberId: number, year: number) => `${memberId}-${year}`

const processGapInCare = (
    state: Draft<GapsInCareState>,
    id: string,
    processRecord: (gapInCare: IGapInCare) => IGapInCare
): void => {
    Object.keys(state.gapsInCareByMemberIdYearNpi).forEach((key) => {
        const index = state.gapsInCareByMemberIdYearNpi[key].findIndex((x) => x.id === id)
        if (index >= 0) {
            const gapsInCare = [...state.gapsInCareByMemberIdYearNpi[key]]
            gapsInCare[index] = processRecord(gapsInCare[index])
            state.gapsInCareByMemberIdYearNpi = {
                ...state.gapsInCareByMemberIdYearNpi,
                [key]: gapsInCare
            }
            return
        }
    })
}

export const gapsInCareSlice = createSlice({
    name: 'gapsInCare',
    initialState,
    reducers: {
        gapInCareFeedbackRead: (state, action) => {
            processGapInCare(state, action.payload.gapInCareId, (gapInCare) => ({ ...gapInCare, newFeedbackCount: 0 }))
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMemberYearProviders.fulfilled, (state, action) => {
                state.yearProvidersByMemberId = {
                    ...state.yearProvidersByMemberId,
                    [action.meta.arg.memberId]: action.payload.data
                }
            })
            .addCase(getMemberNpiYears.fulfilled, (state, action) => {
                state.yearsByMemberIdNpi = {
                    ...state.yearsByMemberIdNpi,
                    [getMemberIdNpiKey(action.meta.arg.memberId, action.meta.arg.npi)]: action.payload.data
                }
            })
            .addCase(getGapsInCare.fulfilled, (state, action) => {
                state.gapsInCareByMemberIdYearNpi = {
                    ...state.gapsInCareByMemberIdYearNpi,
                    [getMemberIdYearNpiKey(action.meta.arg.memberId, action.meta.arg.year, action.meta.arg.npi)]:
                        action.payload.data
                }
            })
            .addCase(getGapInCare.fulfilled, (state, action) => {
                state.gapsInCareById = {
                    ...state.gapsInCareById,
                    [action.payload.data.id]: action.payload.data
                }
                processGapInCare(state, action.meta.arg.gapInCareId, (_) => action.payload.data)
            })
            .addCase(getAvailableGaps.fulfilled, (state, action) => {
                state.availableGapsByMemberIdYear = {
                    ...state.availableGapsByMemberIdYear,
                    [getMemberIdYearKey(action.meta.arg.memberId, action.meta.arg.year)]: action.payload.data
                }
            })
            .addCase(createGapInCare.fulfilled, (state, action) => {
                const key = getMemberIdYearNpiKey(action.meta.arg.memberId, action.meta.arg.year, action.meta.arg.npi)
                state.gapsInCareByMemberIdYearNpi = {
                    ...state.gapsInCareByMemberIdYearNpi,
                    [key]: [...(state.gapsInCareByMemberIdYearNpi[key] ?? []), action.payload.data].sort((x, y) => {
                        let result = x.gap.localeCompare(y.gap)
                        if (result === 0) {
                            result = x.id.localeCompare(y.id)
                        }
                        return result
                    })
                }
            })
            .addCase(deleteGapInCare.fulfilled, (state, action) => {
                Object.keys(state.gapsInCareByMemberIdYearNpi).forEach((key) => {
                    if (state.gapsInCareByMemberIdYearNpi[key].find((x) => x.id === action.meta.arg.gapInCareId)) {
                        state.gapsInCareByMemberIdYearNpi = {
                            ...state.gapsInCareByMemberIdYearNpi,
                            [key]: state.gapsInCareByMemberIdYearNpi[key].filter(
                                (x) => x.id !== action.meta.arg.gapInCareId
                            )
                        }
                        return
                    }
                })
            })
            .addCase(updateGapInCare.fulfilled, (state, action) => {
                state.gapsInCareById = {
                    ...state.gapsInCareById,
                    [action.payload.data.id]: action.payload.data
                }
                processGapInCare(state, action.meta.arg.gapInCareId, (_) => action.payload.data)
            })
            .addCase(getReferralSpecialist.fulfilled, (state, action) => {
                state.referralSpecialistsById = {
                    ...state.referralSpecialistsById,
                    [action.payload.data.id]: action.payload.data
                }
            })
            .addCase(getReferralSpecialists.fulfilled, (state, action) => {
                state.referralSpecialistsByFilter = {
                    ...state.referralSpecialistsByFilter,
                    [action.meta.arg.filter]: action.payload.data
                }
            })
            .addCase(setReferralSpecialist.fulfilled, (state, action) => {
                processGapInCare(state, action.meta.arg.gapInCareId, (gapInCare) => ({
                    ...gapInCare,
                    referralSpecialistId: action.meta.arg.referralSpecialist.id,
                    referralFullName: action.meta.arg.referralSpecialist.fullName,
                    referralSpecialty: action.meta.arg.referralSpecialist.specialty
                }))
            })
            .addCase(clearReferralSpecialist.fulfilled, (state, action) => {
                processGapInCare(state, action.meta.arg.gapInCareId, (gapInCare) => ({
                    ...gapInCare,
                    referralSpecialistId: null,
                    referralFullName: null,
                    referralSpecialty: null
                }))
            })
            .addCase(uploadDocument.fulfilled, (state, action) => {
                processGapInCare(state, action.meta.arg.gapInCareId, (gapInCare) => ({
                    ...gapInCare,
                    documents: [...gapInCare.documents, action.payload.data].sort((x, y) => {
                        let result = x.fileName.localeCompare(y.fileName)
                        if (result === 0) {
                            result = new Date(x.uploadedOn) < new Date(y.uploadedOn) ? 1 : -1
                        }
                        return result
                    })
                }))
            })
            .addCase(deleteDocument.fulfilled, (state, action) => {
                Object.keys(state.gapsInCareByMemberIdYearNpi).forEach((key) => {
                    for (let i = 0; i < state.gapsInCareByMemberIdYearNpi[key].length; i++) {
                        if (
                            state.gapsInCareByMemberIdYearNpi[key][i].documents.find(
                                (x) => x.id === action.meta.arg.gapInCareDocumentId
                            )
                        ) {
                            const documents = state.gapsInCareByMemberIdYearNpi[key][i].documents.filter(
                                (x) => x.id !== action.meta.arg.gapInCareDocumentId
                            )
                            const gapsInCare = [...state.gapsInCareByMemberIdYearNpi[key]]
                            gapsInCare[i] = {
                                ...gapsInCare[i],
                                documents
                            }
                            state.gapsInCareByMemberIdYearNpi = {
                                ...state.gapsInCareByMemberIdYearNpi,
                                [key]: gapsInCare
                            }
                            return
                        }
                    }
                })
            })
    }
})

export default gapsInCareSlice.reducer

export const { gapInCareFeedbackRead } = gapsInCareSlice.actions

export const getMemberYearProviders = createExceptionAwareAsyncThunk(
    'gapsInCare/getMemberYearProviders',
    async (args: IGetGapsInCareMemberYearProvidersRequest) => {
        const response = await axios.post<IGapsInCareYearProvider[]>('api/GapsInCare/GetMemberYearProviders', args)
        return response
    }
)

export const getMemberNpiYears = createExceptionAwareAsyncThunk(
    'gapsInCare/getMemberNpiYears',
    async (args: IGetGapsInCareMemberNpiYearsRequest) => {
        const response = await axios.post<number[]>('api/GapsInCare/GetMemberNpiYears', args)
        return response
    }
)

export const getGapsInCare = createExceptionAwareAsyncThunk(
    'gapsInCare/getGapsInCare',
    async (args: IGetGapsInCareRequest) => {
        const response = await axios.post<IGapInCare[]>('api/GapsInCare/GetGapsInCare', args)
        return response
    }
)

export const getGapInCare = createExceptionAwareAsyncThunk(
    'gapsInCare/getGapInCare',
    async (args: IGetGapInCareRequest) => {
        const response = await axios.post<IGapInCare>('api/GapsInCare/GetGapInCare', args)
        return response
    }
)

export const getAvailableGaps = createExceptionAwareAsyncThunk(
    'gapsInCare/getAvailableGaps',
    async (args: IGetAvailableGapsRequest) => {
        const response = await axios.post<IGap[]>('api/GapsInCare/GetAvailableGaps', args)
        return response
    }
)

export interface ICreateGapInCareArgs extends ICreateGapInCareRequest {
    npi: string
}

export const createGapInCare = createExceptionAwareAsyncThunk(
    'gapsInCare/createGapInCare',
    async (args: ICreateGapInCareArgs) => {
        const requestBody: ICreateGapInCareRequest = {
            memberId: args.memberId,
            year: args.year,
            gapId: args.gapId,
            lastCompletionDate: args.lastCompletionDate,
            serviceNote: args.serviceNote
        }
        const response = await axios.post<IGapInCare>('api/GapsInCare/CreateGapInCare', requestBody)

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'The gap in care has been created.',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }

        return response
    }
)

export const updateGapInCare = createExceptionAwareAsyncThunk(
    'gapsInCare/updateGapInCare',
    async (args: IUpdateGapInCareRequest) => {
        const response = await axios.post<IGapInCare>('api/GapsInCare/UpdateGapInCare', args)

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'The gap in care has been updated.',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }

        return response
    }
)

export const deleteGapInCare = createExceptionAwareAsyncThunk(
    'gapsInCare/deleteGapInCare',
    async (args: IDeleteGapInCareRequest) => {
        const response = await axios.post('api/GapsInCare/DeleteGapInCare', args)

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'The gap in care has been deleted.',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }

        return response
    }
)

export const getReferralSpecialist = createExceptionAwareAsyncThunk(
    'gapsInCare/getReferralSpecialist',
    async (args: IGetReferralSpecialistRequest) => {
        const response = await axios.post<IReferralSpecialist>('api/GapsInCare/GetReferralSpecialist', args)
        return response
    }
)

export const getReferralSpecialists = createExceptionAwareAsyncThunk(
    'gapsInCare/getReferralSpecialists',
    async (args: IGetReferralSpecialistsRequest) => {
        const response = await axios.post<IReferralSpecialist[]>('api/GapsInCare/GetReferralSpecialists', args)
        return response
    }
)

interface ISetReferralSpecialistArgs {
    gapInCareId: string
    referralSpecialist: IReferralSpecialist
}

export const setReferralSpecialist = createExceptionAwareAsyncThunk(
    'gapsInCare/setReferralSpecialist',
    async (args: ISetReferralSpecialistArgs) => {
        const requestBody: ISetGapInCareReferralSpecialistRequest = {
            gapInCareId: args.gapInCareId,
            referralSpecialistId: args.referralSpecialist.id
        }
        const response = await axios.post('api/GapsInCare/SetReferralSpecialist', requestBody)

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'The referral specialist has been set.',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }

        return response
    }
)

export const clearReferralSpecialist = createExceptionAwareAsyncThunk(
    'gapsInCare/clearReferralSpecialist',
    async (args: IClearGapInCareReferralSpecialistRequest) => {
        const response = await axios.post('api/GapsInCare/ClearReferralSpecialist', args)

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'The referral specialist has been removed.',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }

        return response
    }
)

export const uploadDocument = createExceptionAwareAsyncThunk(
    'gapsInCare/uploadDocument',
    async (args: IUploadGapInCareDocumentRequest) => {
        const response = await axios.post<IGapInCareDocument>('api/GapsInCare/UploadDocument', args, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            timeout: fileStreamTimeout
        })

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'The document has been uploaded.',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }

        return response
    }
)

export const downloadDocument = createExceptionAwareAsyncThunk(
    'gapsInCare/downloadDocument',
    async (args: IDownloadGapInCareDocumentRequest) => {
        const response = await axios.post('api/GapsInCare/DownloadDocument', args, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf'
            }
        })

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', response.headers['file-name'])
            document.body.appendChild(link)
            link.click()

            dispatch(
                addToast({
                    message: 'The document has been downloaded.',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }

        return response
    }
)

export const deleteDocument = createExceptionAwareAsyncThunk(
    'gapsInCare/deleteDocument',
    async (args: IDeleteGapInCareDocumentRequest) => {
        const response = await axios.post('api/GapsInCare/DeleteDocument', args)

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'The document has been deleted.',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }

        return response
    }
)
