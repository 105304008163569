import { FC } from 'react'
import { StyledModalBackground, StyledModalContainer } from './AdjustableModal.style'

interface IModalProps {
    minHeight?: number
    onClose?: () => void
    minWidth?: number
}

const Modal: FC<IModalProps> = ({ minHeight, onClose, children, minWidth }) => (
    <StyledModalBackground onMouseDown={onClose}>
        <StyledModalContainer minHeight={minHeight} minWidth={minWidth} onMouseDown={(e) => e.stopPropagation()}>
            {children}
        </StyledModalContainer>
    </StyledModalBackground>
)

export default Modal
