export const backArrow = `<svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.49994 1.175L2.68327 5L6.49994 8.825L5.32494 10L0.324941 5L5.32494 -1.02722e-07L6.49994 1.175Z" fill="white"/>
</svg>
`

export const pen = `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.25 10.9374V13.7499H3.0625L11.3575 5.45492L8.545 2.64242L0.25 10.9374ZM13.5325 3.27992C13.825 2.98742 13.825 2.51492 13.5325 2.22242L11.7775 0.467422C11.485 0.174922 11.0125 0.174922 10.72 0.467422L9.3475 1.83992L12.16 4.65242L13.5325 3.27992Z" fill="#2281C4"/>
</svg>
`

export const medicalItem = `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.5" d="M17.911 22.2039C22.2445 22.2039 25.7575 18.4034 25.7575 13.7153C25.7575 9.02725 22.2445 5.22681 17.911 5.22681C13.5775 5.22681 10.0645 9.02725 10.0645 13.7153C10.0645 18.4034 13.5775 22.2039 17.911 22.2039Z" fill="#40BFFF"/>
<path d="M16.9806 30C11.7496 30 8.08789 26.5417 8.08789 21.6373V20.1282C8.08789 19.7509 8.3785 19.4365 8.72724 19.4365C9.07597 19.4365 9.36658 19.7509 9.36658 20.1282V21.6373C9.36658 26.416 13.1445 28.5538 16.9225 28.5538C20.7004 28.5538 24.4784 26.416 24.4784 21.6373C24.4784 21.26 24.769 20.9456 25.1177 20.9456C25.4664 20.9456 25.757 21.26 25.757 21.6373C25.8733 26.5417 22.2116 30 16.9806 30Z" fill="#2281C4"/>
<path d="M25.2343 22.3933C22.6188 22.3933 20.4683 20.0668 20.4683 17.2373C20.4683 14.4078 22.6188 12.0813 25.2343 12.0813C27.8498 12.0813 30.0003 14.4078 30.0003 17.2373C30.0003 20.0668 27.8498 22.3933 25.2343 22.3933ZM25.2343 13.4646C23.3162 13.4646 21.7469 15.1623 21.7469 17.1744C21.7469 19.1865 23.3162 20.9471 25.1762 20.9471C27.0361 20.9471 28.6635 19.2494 28.6635 17.2373C28.6635 15.1623 27.0942 13.4646 25.2343 13.4646Z" fill="#2281C4"/>
<path d="M25.2336 19.6892C23.9549 19.6892 22.9668 18.5573 22.9668 17.2369C22.9668 15.9165 24.013 14.7847 25.2336 14.7847C26.5123 14.7847 27.5003 15.9165 27.5003 17.2369C27.5003 18.5573 26.4541 19.6892 25.2336 19.6892ZM25.2336 16.168C24.7105 16.168 24.2455 16.6081 24.2455 17.2369C24.2455 17.8028 24.6523 18.3058 25.2336 18.3058C25.7567 18.3058 26.2216 17.8657 26.2216 17.2369C26.1635 16.6081 25.7567 16.168 25.2336 16.168Z" fill="#2281C4"/>
<path d="M10.4118 20.5682C10.063 20.5682 9.77242 20.2538 9.77242 19.8766C9.77242 19.3107 9.36557 18.8076 8.78434 18.8076C8.20312 18.8076 7.79627 19.2478 7.79627 19.8766C7.79627 20.2538 7.50566 20.5682 7.15692 20.5682C6.80819 20.5682 6.51758 20.2538 6.51758 19.8766C6.51758 18.4932 7.56378 17.4243 8.78434 17.4243C10.0049 17.4243 11.0511 18.5561 11.0511 19.8766C11.0511 20.2538 10.7605 20.5682 10.4118 20.5682Z" fill="#2281C4"/>
<path d="M11.8658 20.8833H5.64669C3.96115 20.8833 2.50809 19.5628 2.27561 17.7394L0.00883821 3.34031C-0.049284 2.64866 0.183205 2.01988 0.59006 1.51685C0.938794 1.07671 1.46189 0.825195 2.04312 0.825195H5.93731C6.28604 0.825195 6.57665 1.13959 6.57665 1.51685C6.57665 1.89412 6.28604 2.20851 5.93731 2.20851H2.04312C1.86875 2.20851 1.69438 2.27139 1.52002 2.46002C1.34565 2.64866 1.28753 2.90017 1.28753 3.15168L3.55429 17.5507C3.72866 18.6197 4.60049 19.4371 5.64669 19.4371H11.9239C12.9701 19.4371 13.8419 18.6825 14.0163 17.5507L16.1668 3.15168C16.1668 2.96305 16.1087 2.71153 15.9925 2.58578C15.8762 2.39714 15.6437 2.27139 15.4112 2.27139H11.517C11.1683 2.27139 10.8777 1.957 10.8777 1.57973C10.8777 1.20246 11.1683 0.888074 11.517 0.888074H15.4112C15.9925 0.888074 16.5737 1.20246 16.9805 1.70549C17.3874 2.20851 17.5036 2.83729 17.4455 3.46607L15.2369 17.8651C15.0625 19.5628 13.6094 20.8833 11.8658 20.8833Z" fill="#2281C4"/>
<path d="M5.99591 3.71785C5.64718 3.71785 5.35656 3.40346 5.35656 3.02619V0.762587C5.29844 0.38532 5.58905 0.00805188 5.93779 0.00805188C6.28652 -0.0548261 6.63525 0.259564 6.63525 0.636832C6.63525 0.69971 6.63525 0.69971 6.63525 0.762587V3.02619C6.63525 3.40346 6.34464 3.71785 5.99591 3.71785Z" fill="#2281C4"/>
<path d="M11.5169 3.71786C11.1682 3.71786 10.8776 3.40347 10.8776 3.0262V0.762592C10.8194 0.385324 11.11 0.00805664 11.4588 0.00805664C11.8075 0.00805664 12.1562 0.259568 12.1562 0.636836C12.1562 0.699714 12.1562 0.699714 12.1562 0.762592V3.0262C12.2144 3.40347 11.9238 3.71786 11.5169 3.71786Z" fill="#2281C4"/>
</svg>
`
