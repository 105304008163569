import styled from 'styled-components'
import { pxToRem, DEVICE } from 'utilities'

import registrationMobile from '../../assets/registration-mobile.png'
import registrationTablet from '../../assets/registration-tablet.png'
import registrationDesktop from '../../assets/registration-desktop.png'

import footerMobile from '../../assets/footer-mobile.svg'
import footerTablet from '../../assets/footer-tablet.svg'
import footerDesktop from '../../assets/footer-desktop.svg'

export const StyledReigstrationContainer = styled.article`
    margin: 0;
    padding: 0;
    background-color: #efefef;
    width: 100%;
`

export const StyledRegistrationMain = styled.article`
    margin-left: auto;
    margin-right: auto;
    background-color: #f6f9fd;
    padding: 0;
    max-width: ${pxToRem(390)};

    ${DEVICE.tablet} {
        max-width: ${pxToRem(768)};
        width: ${pxToRem(768)};
        height: ${pxToRem(1024)};
        position: relative;
    }

    ${DEVICE.desktop} {
        position: relative;
        max-width: ${pxToRem(1440)};
        width: ${pxToRem(1440)};
        height: ${pxToRem(1024)};
        background-image: url(${registrationDesktop});
        background-repeat: no-repeat;
        background-position: right top;
    }
`

export const StyledHcpLogo = styled.article`
    margin: 0 auto 0 auto;
    padding-top: ${pxToRem(30)};
    width: 100%;
    text-align: center;

    ${DEVICE.tablet} {
        text-align: left;
        margin: 0 ${pxToRem(30)} 0 ${pxToRem(30)};
    }

    ${DEVICE.desktop} {
        position: relative;
        left: ${pxToRem(30)};
        right: 77.08%;
        top: ${pxToRem(30)};
        bottom: 90.77%;
        margin: 0;
        padding: 0;
    }
`

export const StyledPassword = styled.article`
    display: block;
    margin: ${pxToRem(30)} ${pxToRem(30)} 0 ${pxToRem(30)};
    padding: 0;

    ${DEVICE.tablet} {
        margin: ${pxToRem(30)} 0 0 0;
        text-align: left;
        width: auto;
        width: ${pxToRem(708)};
        height: ${pxToRem(250)};
    }

    ${DEVICE.desktop} {
        display: none;
    }
`

export const StyledPasswordImage = styled.img`
    width: 100%;
    border-radius: ${pxToRem(4)};
    border: ${pxToRem(1)} solid none;
    content: url(${registrationMobile});

    ${DEVICE.tablet} {
        content: url(${registrationTablet});
        text-align: left;
    }
`

export const StyledFooter = styled.article`
    width: 100%;
    content: url(${footerMobile});
    margin-top: ${pxToRem(5)};

    ${DEVICE.tablet} {
        position: absolute;
        left: 0;
        bottom: 0;
        content: url(${footerTablet});
    }

    ${DEVICE.desktop} {
        position: absolute;
        left: 0;
        bottom: 0;
        content: url(${footerDesktop});
    }
`

export const StyledApi = styled.article`
    margin: ${pxToRem(24)} auto 0 auto;
    width: 100%;
    padding: 0 ${pxToRem(30)} 0 ${pxToRem(30)};
    text-align: left;
    box-sizing: border-box;

    ${DEVICE.tablet} {
        width: ${pxToRem(400)};
        margin: 0 0 0 ${pxToRem(30)};
        padding: 0;
    }

    ${DEVICE.desktop} {
        width: ${pxToRem(400)};
        margin-left: ${pxToRem(148)};
        margin-top: ${pxToRem(105)};
    }
`

export const StyledHeading = styled.h1`
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #293854;
    margin: 0 0 ${pxToRem(13)} 0;
    padding: 0;
    font-size: ${pxToRem(22)};

    ${DEVICE.tablet} {
        margin: ${pxToRem(24)} 0 ${pxToRem(8)} 0;
        font-size: ${pxToRem(30)};
    }
`

export const StyledSubheading = styled.h2`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
    color: #73839c;
    display: block;

    ${DEVICE.desktop} {
        margin-bottom: ${pxToRem(28)};
    }
`

export const StyledEntryItem = styled.article``

export const StyledLabel = styled.label`
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(14)};
    line-height: 130%;
`

export const StyledInput = styled.input`
    background: #ffffff;
    border: ${pxToRem(1)} solid #dee5ef;
    box-sizing: border-box;
    border-radius: ${pxToRem(4)};
    position: static;
    height: ${pxToRem(50)};
    left: 0;
    top: 0;
    width: 100%;
    padding: ${pxToRem(14)} ${pxToRem(18)} ${pxToRem(14)} ${pxToRem(18)};
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(16)};
    line-height: 140%;
    align-items: center;
    color: #73839c;
    margin-top: ${pxToRem(15)};
    margin-bottom: ${pxToRem(42)};

    :focus {
        outline: none;
        border: ${pxToRem(1)} solid #2281c4;
    }
`

export const StyledButton = styled.button`
    position: static;
    width: 100%;
    height: ${pxToRem(50)};
    left: 0;
    top: ${pxToRem(296)};
    background: #2281c4;
    border: 0;
    border-radius: ${pxToRem(4)};
    flex: none;
    order: 3;
    flex-grow: 0;
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: 140%;
    color: #ffffff;

    :focus {
        border: none;
    }

    :disabled {
        background: darkgray;
    }
`
