import CustomSvgIcon from 'components/CustomSvgIcon'
import { arrow } from 'components/MemberOutreachTable/icons'
import {
    StyledTableContainer,
    StyledTableBodyItem,
    StyledTableBodyRow,
    StyledTableBodyRowWrap,
    StyledTableHeaderItem,
    StyledTableHeaderRow,
    StyledEligibilityBadgeContainer,
    StyledBadgeContainer,
    StyledScheduleDate,
    StyledAwvStatusLink,
    StyledActionsContainer,
    StyledActionButton,
    StyledActionButtonPlaceholder,
    StyledActionsContainerHeader,
    StyledDisabledScheduleDate
} from './index.style'
import { IHeaderInfo } from 'models/headerInfo'
import { SortDirection } from 'models/sortColumn'
import {
    IMemberProfile,
    IMemberProfileScheduleAwvData,
    IMemberProfileUnscheduleAwvData
} from 'pages/hcp/users/create/index.models'
import { FC, useEffect, useState } from 'react'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { isElegibleBadge, notElegibleBadge } from '../../information/Personal/icons'
import { calendarIcon, feedbackIcon, feedbackIndicatorIcon, paperClipIcon } from './icons'
import moment from 'moment'
import ScheduleAWVModal from '../scheduleAWVModal'
import { formatUSNumber, limitStringCharacters } from 'utilities/stringUtilities'
import { dispatch, useAppSelector, useSelector } from 'redux/store'
import { scheduleAwv, unscheduleAwv } from 'redux/slices/memberProfile'
import useCurrentUser from 'hooks/useCurrentUser'
import { ERoles } from 'models/enums/role'
import { Pulse8State } from 'redux/slices/pulse8'
import { FeedbackModalDialog } from 'pages/hcp/members/information/PointsInCare/ReviewTable/FeedbackDialog'
import {
    FeedbackState,
    addMemberFeedback,
    getMemberFeedback,
    getMemberFeedbackKey,
    markMemberFeedbackRead
} from 'redux/slices/feedback'
import { hideMainWindowScrollbars } from 'utilities/style'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import usePostCustomEvent from 'hooks/usePostCustomEvent'
import SupportingDocsModal from 'components/ReviewableAwvFormsList/SupportingDocsModal'
import { UiState } from 'redux/slices/ui'
import isYearOpenForAwv from 'utilities/isYearOpenForAwv'

enum EHealthPlanStatus {
    ELIGIBLE = 'Eligible',
    INELIGIBLE = 'Ineligible'
}

interface IMembersTableProps {
    npi: string

    memberProfiles: IMemberProfile[]

    onEditMember: (memberId: number | null) => void

    onEditMemberAwvFile: (e: any, memberId: number | null) => void

    sortColumn: string

    sortDirection: SortDirection

    sortCallback(column: string, direction: SortDirection): void
}

const HEADER_LIST: IHeaderInfo[] = [
    { displayName: 'Status', fieldName: 'IsEligible' },
    { displayName: 'Last Name', fieldName: 'LastName' },
    { displayName: 'First Name', fieldName: 'FirstName' },
    { displayName: 'DOB', fieldName: 'MemberDateOfBirthUnformatted' },
    { displayName: 'Provider', fieldName: 'Provider' },
    { displayName: 'Phone #', fieldName: 'HomePhone' },
    { displayName: 'Patient ID', fieldName: 'Membid' },
    { displayName: 'LOB', fieldName: 'Lob' },
    { displayName: 'Health Plan', fieldName: 'HealthPlan1' },
    { displayName: 'PCP Visit', fieldName: 'LastPCPVisitDateUnformatted' },
    { displayName: 'Next AWV', fieldName: 'NextAWVDateUnformatted' },
    { displayName: 'AWV Status', fieldName: 'AWVStatus' }
]

const MembersTable: FC<IMembersTableProps> = ({
    npi,
    memberProfiles,
    sortColumn,
    sortDirection,
    sortCallback,
    onEditMember,
    onEditMemberAwvFile
}) => {
    const handleColumnClick = (column: string) => {
        sortCallback(
            column,
            column === sortColumn
                ? sortDirection === SortDirection.ascending
                    ? SortDirection.descending
                    : SortDirection.ascending
                : SortDirection.ascending
        )
    }
    const { currentNpi, providerNpis } = useSelector((state: { pulse8: Pulse8State }) => state.pulse8)

    const [display, setDisplay] = useState({ display: 'none' })
    const [isHeaderHovered, setIsHeaderHovered] = useState<number | null>(null)
    const [isScheduleModalVisible, setIsScheduleModalVisible] = useState<boolean>(false)

    const currentUser = useCurrentUser()

    const elegibilityBadge = (status: string) => {
        return (
            <StyledEligibilityBadgeContainer>
                <StyledBadgeContainer>
                    <CustomSvgIcon
                        iconSet={{
                            icon: status === EHealthPlanStatus.ELIGIBLE ? isElegibleBadge : notElegibleBadge
                        }}
                        svg
                    ></CustomSvgIcon>
                </StyledBadgeContainer>
            </StyledEligibilityBadgeContainer>
        )
    }

    const showButton = (e: any) => {
        e.preventDefault()
        setDisplay({ display: 'block' })
    }

    const hideButton = (e: any) => {
        e.preventDefault()
        setDisplay({ display: 'none' })
    }

    const handleScheduler = (memberProfiles: IMemberProfile) => {
        setCurrentMember(memberProfiles)
        setIsScheduleModalVisible(true)
    }

    const handleCloseScheduler = () => {
        setIsScheduleModalVisible(false)
    }
    const scheduleAWVEvent = usePostCustomEvent(pageTitle.PatientsSummary, customEvents.ScheduleAWV)

    const handleScheduleAwv = (data: IMemberProfileScheduleAwvData) => {
        scheduleAWVEvent()

        dispatch(scheduleAwv(data))
        setIsScheduleModalVisible(false)
    }

    const handleUnscheduleAwv = (data: IMemberProfileUnscheduleAwvData) => {
        dispatch(unscheduleAwv(data))
        setIsScheduleModalVisible(false)
    }

    const [currentMember, setCurrentMember] = useState<IMemberProfile | null>(null)

    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState<boolean>(false)
    const [feedbackMembid, setFeedbackMembid] = useState<string>('')
    const [feedbackNpi, setFeedbackNpi] = useState<string>('')
    const [feedbackLoading, setFeedbackLoading] = useState(false)
    const [feedbackSending, setFeedbackSending] = useState(false)
    const { memberFeedback } = useSelector((state: { feedback: FeedbackState }) => state.feedback)
    const viewFeedbackEvent = usePostCustomEvent(pageTitle.PatientsSummary, customEvents.ViewFeedback)

    const handleFeedback = (membid: string, npi: string) => {
        viewFeedbackEvent()
        setFeedbackMembid(membid)
        setFeedbackNpi(npi)
        setFeedbackLoading(true)
        setIsFeedbackModalOpen(true)
        dispatch(
            markMemberFeedbackRead({
                membid,
                npi
            })
        )
        dispatch(
            getMemberFeedback({
                membid,
                npi
            })
        ).then(() => {
            setFeedbackLoading(false)
        })
    }
    const sentFeedbackEvent = usePostCustomEvent(pageTitle.PatientsSummary, customEvents.SendFeedback)
    const handleSendFeedback = (feedbackToPost: string) => {
        sentFeedbackEvent()
        setFeedbackSending(true)
        dispatch(
            addMemberFeedback({
                membid: feedbackMembid,
                npi: feedbackNpi,
                parentId: null,
                feedbackText: feedbackToPost
            })
        ).then(() => {
            setFeedbackSending(false)
        })
    }

    const [isAwvEncounterFilesModalVisible, setAwvEncounterFilesModalVisible] = useState<boolean>(false)
    useEffect(() => {
        hideMainWindowScrollbars(isScheduleModalVisible || isFeedbackModalOpen || isAwvEncounterFilesModalVisible)
    }, [isScheduleModalVisible, isFeedbackModalOpen, isAwvEncounterFilesModalVisible])

    const viewEncounterDocEvent = usePostCustomEvent(pageTitle.PatientsSummary, customEvents.ViewEncounterDocuments)
    const handleAwvEncounterFiles = (item: IMemberProfile) => {
        setCurrentMember(item)
        setAwvEncounterFilesModalVisible(true)
        viewEncounterDocEvent()
    }

    const selectedYear = useAppSelector((state: { ui: UiState }) => {
        return state.ui.listConfigs.memberProfile.year
    })
    const getAwvEncounterFilesRequest = () => {
        return {
            memberId: currentMember.memberId,
            npi: currentMember.providerNpi,
            year: selectedYear
        }
    }

    const showSchedulerIcon = (member: IMemberProfile) => {
        if (![ERoles.Provider, ERoles.OfficeAdmin, ERoles.Nurse].includes(currentUser.primaryRole)) return null

        const bOK = member.status === 'Eligible' && new Date().getFullYear() === selectedYear

        return bOK ? (
            <StyledActionButton onClick={() => handleScheduler(member)}>
                <CustomSvgIcon iconSet={{ icon: calendarIcon }} svg></CustomSvgIcon>
            </StyledActionButton>
        ) : (
            <StyledActionButtonPlaceholder />
        )
    }

    const showAwvEncounterFilesIcon = (member: IMemberProfile) => {
        return member.awvEncouterFilesCount > 0 ? (
            <StyledActionButton onClick={() => handleAwvEncounterFiles(member)}>
                <CustomSvgIcon altText="Encounter Files" iconSet={{ icon: paperClipIcon }} svg></CustomSvgIcon>
            </StyledActionButton>
        ) : (
            <StyledActionButtonPlaceholder />
        )
    }

    const getScheduledDOS = (member: IMemberProfile) => {
        if (member.nextAWVDate && new Date(member.nextAWVDate).getFullYear() >= new Date().getFullYear() + 1)
            return member.nextAWVDate
        else {
            if (['Not Started', 'Scheduled'].includes(member.awvStatus)) return member.awvScheduledDate
            else return null
        }
    }

    const disableLink = (member: IMemberProfile) => {
        if (
            (member.awvStatus === 'Not Started' && !isYearOpenForAwv(selectedYear)) ||
            (['Not Started', 'Scheduled'].includes(member.awvStatus) &&
                ![ERoles.Provider, ERoles.OfficeAdmin, ERoles.Nurse].includes(currentUser.primaryRole))
        )
            return true
        else return false
    }

    return (
        <>
            {isAwvEncounterFilesModalVisible ? (
                <SupportingDocsModal
                    onCancel={() => setAwvEncounterFilesModalVisible(false)}
                    request={getAwvEncounterFilesRequest()}
                />
            ) : null}

            {isScheduleModalVisible ? (
                <ScheduleAWVModal
                    npi={npi}
                    memberId={currentMember.memberId}
                    scheduledDOS={getScheduledDOS(currentMember)}
                    onCancel={handleCloseScheduler}
                    onSaveDate={handleScheduleAwv}
                    onUnschedule={handleUnscheduleAwv}
                    awvStatus={currentMember.awvStatus}
                />
            ) : null}

            {isFeedbackModalOpen ? (
                <FeedbackModalDialog
                    feedback={memberFeedback[getMemberFeedbackKey(feedbackMembid, feedbackNpi)] ?? []}
                    isLoading={feedbackLoading}
                    isSending={feedbackSending}
                    onSend={handleSendFeedback}
                    onClose={() => setIsFeedbackModalOpen(false)}
                />
            ) : null}

            <StyledTableContainer>
                <StyledTableHeaderRow key={'main-table-header'}>
                    {HEADER_LIST.map((header: IHeaderInfo, idx: number) => {
                        return (
                            <StyledTableHeaderItem
                                key={`header-${idx}`}
                                onClick={() => handleColumnClick(header.fieldName)}
                                active={sortDirection === SortDirection.ascending}
                            >
                                {header.displayName}
                                {sortColumn === header.fieldName && (
                                    <CustomSvgIcon iconSet={{ icon: arrow }} svg></CustomSvgIcon>
                                )}
                            </StyledTableHeaderItem>
                        )
                    })}
                    <StyledActionsContainerHeader>Action</StyledActionsContainerHeader>{' '}
                </StyledTableHeaderRow>

                {memberProfiles.map((memberProfile, idx) => {
                    return (
                        <StyledTableBodyRowWrap key={`memberProfile-${idx}`} tabIndex={1}>
                            <article
                                role="button"
                                key={`memberProfile-row-${idx}`}
                                onClick={() => onEditMember(memberProfile.memberId)}
                                onMouseEnter={(e) => {
                                    showButton(e)
                                    setIsHeaderHovered(idx)
                                }}
                                onMouseLeave={(e) => {
                                    hideButton(e)
                                    setIsHeaderHovered(null)
                                }}
                            >
                                <StyledTableBodyRow key={`memberProfileRow-${idx}`}>
                                    <StyledTableBodyItem key={`healthPlanStatus-${idx}`}>
                                        {elegibilityBadge(memberProfile?.status)}
                                    </StyledTableBodyItem>
                                    <StyledTableBodyItem key={`memberLastName-${idx}`}>
                                        {limitStringCharacters(8, memberProfile.lastName)}
                                    </StyledTableBodyItem>
                                    <StyledTableBodyItem key={`memberFirstName-${idx}`}>
                                        {limitStringCharacters(8, memberProfile.firstName)}
                                    </StyledTableBodyItem>
                                    <StyledTableBodyItem key={`memberDOB-${idx}`}>
                                        {moment(memberProfile.dateOfBirth).format('MM/DD/YYYY')}
                                    </StyledTableBodyItem>
                                    <StyledTableBodyItem key={`provider-${idx}`}>
                                        {limitStringCharacters(12, memberProfile.provider)}
                                    </StyledTableBodyItem>
                                    <StyledTableBodyItem key={`phoneNumber-${idx}`}>
                                        {formatUSNumber(memberProfile.phoneNumber)}
                                    </StyledTableBodyItem>
                                    <StyledTableBodyItem key={`membID-${idx}`}>
                                        {memberProfile.membID}
                                    </StyledTableBodyItem>
                                    <StyledTableBodyItem key={`lineOfBusiness-${idx}`}>
                                        {memberProfile.lineOfBusiness}
                                    </StyledTableBodyItem>
                                    <StyledTableBodyItem key={`healthPlan-${idx}`}>
                                        {memberProfile.healthPlan}
                                    </StyledTableBodyItem>
                                    <StyledTableBodyItem key={`lastVisit-${idx}`}>
                                        {' '}
                                        {memberProfile.lastPCPVisitDate == null
                                            ? ''
                                            : moment(memberProfile.lastPCPVisitDate)?.format('MM/DD/YYYY')}
                                    </StyledTableBodyItem>
                                    <StyledTableBodyItem key={`nextAWVDate-${idx}`}>
                                        {' '}
                                        {memberProfile.nextAWVDate == null
                                            ? ''
                                            : moment(memberProfile.nextAWVDate)?.format('MM/DD/YYYY')}
                                    </StyledTableBodyItem>
                                    <StyledTableBodyItem
                                        key={`status-${idx}`}
                                        statusTopMargin={
                                            memberProfile.awvStatus === 'Scheduled' ||
                                            memberProfile.awvStatus === 'Submitted'
                                                ? 10
                                                : 0
                                        }
                                    >
                                        {disableLink(memberProfile) ? (
                                            memberProfile.awvStatus
                                        ) : (
                                            <StyledAwvStatusLink
                                                onClick={(e) => onEditMemberAwvFile(e, memberProfile.memberId)}
                                            >
                                                {memberProfile.awvStatus}
                                            </StyledAwvStatusLink>
                                        )}

                                        {memberProfile.awvStatus === 'Scheduled' &&
                                            (disableLink(memberProfile) ? (
                                                <StyledDisabledScheduleDate>
                                                    {moment(memberProfile.awvScheduledDate).format('ddd MMM DD, YYYY')}
                                                </StyledDisabledScheduleDate>
                                            ) : (
                                                <StyledScheduleDate>
                                                    {moment(memberProfile.awvScheduledDate).format('ddd MMM DD, YYYY')}
                                                </StyledScheduleDate>
                                            ))}

                                        {memberProfile.awvStatus === 'Submitted' && (
                                            <StyledScheduleDate>
                                                {moment(memberProfile.awvSubmissionDate).format('ddd MMM DD, YYYY')}
                                            </StyledScheduleDate>
                                        )}
                                    </StyledTableBodyItem>
                                </StyledTableBodyRow>
                            </article>

                            <StyledActionsContainer>
                                {showSchedulerIcon(memberProfile)}
                                {showAwvEncounterFilesIcon(memberProfile)}
                                <StyledActionButton
                                    onClick={() => handleFeedback(memberProfile.membID, memberProfile.providerNpi)}
                                    hasNewFeedback={memberProfile.hasNewFeedback}
                                >
                                    <div>
                                        <CustomSvgIcon
                                            altText="Feedback"
                                            iconSet={{ icon: feedbackIndicatorIcon }}
                                            svg
                                        ></CustomSvgIcon>
                                    </div>
                                    <CustomSvgIcon iconSet={{ icon: feedbackIcon }} svg></CustomSvgIcon>
                                </StyledActionButton>
                            </StyledActionsContainer>
                        </StyledTableBodyRowWrap>
                    )
                })}
            </StyledTableContainer>
        </>
    )
}

export default MembersTable
