export const nextArrow = `<svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.158203 8.825L3.97487 5L0.158203 1.175L1.3332 0L6.3332 5L1.3332 10L0.158203 8.825Z" fill="#394A64"/>
</svg>
`

export const lastPageArrow = `<svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.658203 1.175L4.4832 5L0.658203 8.825L1.8332 10L6.8332 5L1.8332 0L0.658203 1.175ZM9.3332 0H10.9999V10H9.3332V0Z" fill="#394A64"/>
</svg>
`

export const editPen = `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.25 10.9374V13.7499H3.0625L11.3575 5.45492L8.545 2.64242L0.25 10.9374ZM13.5325 3.27992C13.825 2.98742 13.825 2.51492 13.5325 2.22242L11.7775 0.467422C11.485 0.174922 11.0125 0.174922 10.72 0.467422L9.3475 1.83992L12.16 4.65242L13.5325 3.27992Z" fill="#2281C4"/>
</svg>
`

export const redCircleDisable = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.7539 6.57843C14.7511 3.57743 9.88412 3.57886 6.88311 6.58163C3.88211 9.5844 3.88355 14.4514 6.88632 17.4524C9.83582 20.3389 14.527 20.4168 17.5708 17.6298C18.2131 17.029 18.7483 16.323 19.1532 15.5421C19.5401 14.7909 19.7998 13.9807 19.9217 13.1444C19.9766 12.7706 20.0044 12.3933 20.0049 12.0154C20.0105 9.97526 19.2 8.01758 17.7539 6.57843ZM8.02431 7.70425C10.1389 5.63343 13.4271 5.35838 15.8564 7.04912C15.8963 7.07824 15.9215 7.12337 15.9253 7.17263C15.9291 7.22189 15.9112 7.27036 15.8763 7.30528L7.62406 15.5613C7.58903 15.5961 7.54062 15.6138 7.49143 15.61C7.44225 15.6062 7.39715 15.5812 7.3679 15.5415C5.64178 13.1198 5.9194 9.80509 8.02431 7.70425ZM8.80944 16.9817C11.2313 18.7007 14.5395 18.424 16.6422 16.3266H16.6428C18.7477 14.2253 19.0248 10.9103 17.2979 8.48874C17.2687 8.44902 17.2236 8.42399 17.1744 8.42018C17.1252 8.41636 17.0768 8.43414 17.0418 8.46889L8.78894 16.7256C8.75407 16.7606 8.73625 16.8091 8.74019 16.8584C8.74414 16.9076 8.76944 16.9527 8.80944 16.9817Z" fill="#B11B1B"/>
<mask id="mask0_7181_901" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="4" y="4" width="17" height="16">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.7539 6.57843C14.7511 3.57743 9.88412 3.57886 6.88311 6.58163C3.88211 9.5844 3.88355 14.4514 6.88632 17.4524C9.83582 20.3389 14.527 20.4168 17.5708 17.6298C18.2131 17.029 18.7483 16.323 19.1532 15.5421C19.5401 14.7909 19.7998 13.9807 19.9217 13.1444C19.9766 12.7706 20.0044 12.3933 20.0049 12.0154C20.0105 9.97526 19.2 8.01758 17.7539 6.57843ZM8.02431 7.70425C10.1389 5.63343 13.4271 5.35838 15.8564 7.04912C15.8963 7.07824 15.9215 7.12337 15.9253 7.17263C15.9291 7.22189 15.9112 7.27036 15.8763 7.30528L7.62406 15.5613C7.58903 15.5961 7.54062 15.6138 7.49143 15.61C7.44225 15.6062 7.39715 15.5812 7.3679 15.5415C5.64178 13.1198 5.9194 9.80509 8.02431 7.70425ZM8.80944 16.9817C11.2313 18.7007 14.5395 18.424 16.6422 16.3266H16.6428C18.7477 14.2253 19.0248 10.9103 17.2979 8.48874C17.2687 8.44902 17.2236 8.42399 17.1744 8.42018C17.1252 8.41636 17.0768 8.43414 17.0418 8.46889L8.78894 16.7256C8.75407 16.7606 8.73625 16.8091 8.74019 16.8584C8.74414 16.9076 8.76944 16.9527 8.80944 16.9817Z" fill="white"/>
</mask>
<g mask="url(#mask0_7181_901)">
</g>
</svg>
`

export const checkCircleEnable = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.99707 12.8158L9.63142 15.1353C9.75721 15.3231 9.96602 15.4387 10.192 15.4454C10.418 15.4522 10.6334 15.3493 10.7701 15.1693L16.0021 8.5498" stroke="#089171" stroke-width="1.5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.9998 19.504C16.1445 19.504 19.5045 16.144 19.5045 11.9993C19.5045 7.85459 16.1445 4.49463 11.9998 4.49463C7.85508 4.49463 4.49512 7.85459 4.49512 11.9993C4.49512 16.144 7.85508 19.504 11.9998 19.504Z" stroke="#089171" stroke-width="1.5"/>
</svg>
`

export const envelopeResendInvitation = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4.49532" y="6.99707" width="15.0094" height="10.0063" rx="1.5" stroke="#394A64" stroke-width="1.5"/>
<path d="M14.4595 10.6494L17.0031 13.0009" stroke="#394A64" stroke-width="1.5"/>
<path d="M9.54046 10.6494L6.99687 13.0009" stroke="#394A64" stroke-width="1.5"/>
<path d="M19.2579 7.33984L12.9119 11.7159C12.363 12.0949 11.637 12.0949 11.0881 11.7159L4.74214 7.33984" stroke="#394A64" stroke-width="1.5"/>
</svg>
`
