import Modal from 'components/Modal'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC, useState } from 'react'
import DropDownListComponent from 'components/DropDownList'
import { UI_COLORS } from 'theme'
import {
    StyledActionsContainer,
    StyledButtonContainer,
    StyledCancelButton,
    StyledSelectButton,
    StyledSpinnerWrap
} from './index.style'
import { StyledAWVDigitalInput } from 'pages/hcp/members/electronicAnnualWellnessVisitForm/index.style'
import { dropDownListStyleParams } from 'pages/hcp/members/electronicAnnualWellnessVisitForm/DropDownList/index.style'
import { IAwvElectronicFormType } from 'pages/hcp/members/electronicAnnualWellnessVisitForm/index.models'
import Spinner from 'components/Spinner'

interface IElectronicFormTypePickerProps {
    formTypes: IAwvElectronicFormType[]
    selectedFormTypeId: string | null
    showWarning: boolean
    isChanging?: boolean
    onSelect: (selectedFormTypeId: string, makeDefaultFormType: boolean) => void
    onCancel: () => void
    onClose?: () => void
}

const ElectronicFormTypePicker: FC<IElectronicFormTypePickerProps> = ({
    formTypes,
    selectedFormTypeId,
    showWarning,
    isChanging,
    onSelect,
    onCancel,
    onClose
}) => {
    const [currentFormTypeId, setCurrentFormTypeId] = useState(
        selectedFormTypeId ? selectedFormTypeId : formTypes?.length > 0 ? formTypes[0].id : null
    )
    const [makeDefaultFormType, setMakeDefaultFormType] = useState(false)

    const handleClose = () => {
        if (!isChanging) {
            onClose()
        }
    }

    const disabled = isChanging ? true : false

    return (
        <Modal minHeight={475} minWidth={725} onClose={handleClose}>
            <Typography type={TYPOGRAPHY_TYPES.h2}>Electronic Form Type</Typography>
            {showWarning && (
                <Typography type={TYPOGRAPHY_TYPES.p} style={{ color: UI_COLORS.red }}>
                    WARNING! You are changing the type of an existing form. Data loss may occur!
                </Typography>
            )}
            <Typography type={TYPOGRAPHY_TYPES.p}>Please select a form type:</Typography>
            <Typography type={TYPOGRAPHY_TYPES.p}>
                <StyledAWVDigitalInput>
                    <DropDownListComponent
                        id="formType"
                        placeholder={'Form Type'}
                        isDisabled={disabled}
                        options={formTypes.map((x) => ({ value: x.id, label: x.name }))}
                        selectedValue={currentFormTypeId}
                        setSelectedValue={setCurrentFormTypeId}
                        styleParams={dropDownListStyleParams}
                    />
                </StyledAWVDigitalInput>
            </Typography>
            <Typography type={TYPOGRAPHY_TYPES.p}>
                {formTypes.find((x) => x.id === currentFormTypeId)?.description}
            </Typography>
            <StyledActionsContainer>
                <label>
                    <input
                        type="checkbox"
                        disabled={disabled}
                        checked={makeDefaultFormType}
                        onChange={() => setMakeDefaultFormType(!makeDefaultFormType)}
                    />
                    Make this my default form type
                </label>
                <StyledButtonContainer>
                    <StyledCancelButton onClick={onCancel} disabled={disabled}>
                        Cancel
                    </StyledCancelButton>
                    {isChanging ? (
                        <StyledSpinnerWrap>
                            <Spinner />
                        </StyledSpinnerWrap>
                    ) : (
                        <StyledSelectButton
                            onClick={() => onSelect(currentFormTypeId, makeDefaultFormType)}
                            disabled={currentFormTypeId === selectedFormTypeId}
                        >
                            Select
                        </StyledSelectButton>
                    )}
                </StyledButtonContainer>
            </StyledActionsContainer>
        </Modal>
    )
}

export default ElectronicFormTypePicker
