import { createSlice } from '@reduxjs/toolkit'
import axios, { fileStreamTimeout } from '../../utilities/axios'
import { createExceptionAwareAsyncThunk } from 'utilities/createExceptionAwareAsyncThunk'
import {
    AWVSupportingDocs,
    IGetMemberNpiProviderDocumentsRequest,
    IGetMemberNpiProviderDocumentsResponse,
    IGetMemberProviderDocumentsRequest,
    IGetMemberProviderDocumentsResponse,
    IProviderDocument,
    ProviderDocumentByIdRequest,
    ProviderDocumentUploadRequest
} from 'pages/hcp/members/information/DocumentsTab/models'
import { dispatch, dynamicStoreData } from 'redux/store'
import { addToast } from './toast'
import { successCheckmark } from 'components/Toast/icons'
import { EToastVariant } from 'models/IToast'
import {
    DownloadSupportingDocsRequest,
    SupportingDocsRequest
} from 'components/ReviewableAwvFormsList/SupportingDocsModal'
import {
    IDeleteAwvSupportingDocumentRequest,
    IGetAwvSupportingDocumentsRequest,
    IGetAwvSupportingDocumentsResponse,
    IUploadAwvSupportingDocumentRequest,
    IUploadAwvSupportingDocumentResponse
} from 'pages/hcp/users/create/index.models'
import { setNewReportCount } from './user'

export type DocumentsState = {
    providerDocuments: dynamicStoreData<IProviderDocument[]>
    awvSupportingDocuments: any
    supportingDocs: AWVSupportingDocs
    providerReports: dynamicStoreData<IProviderDocument[]>
    newReportCounts: INewProviderReportCount[]
    newReportCountsByUserId: dynamicStoreData<INewProviderReportCount[]>
}

const initialState: DocumentsState = {
    providerDocuments: {},
    awvSupportingDocuments: {},
    supportingDocs: null,
    providerReports: {},
    newReportCounts: [],
    newReportCountsByUserId: {}
}

export const getAwvKey = (memberId: number, npi: string, year: number) => `${memberId}-${npi}-${year}`

const updateProviderDocuments = (
    providerDocuments: dynamicStoreData<IProviderDocument[]>,
    transformProviderDocument: (providerDocument: IProviderDocument) => IProviderDocument
) => {
    const updatedProviderDocuments: dynamicStoreData<IProviderDocument[]> = {}
    for (const [key, value] of Object.entries<any>(providerDocuments)) {
        updatedProviderDocuments[key] = value.map(transformProviderDocument)
    }
    return updatedProviderDocuments
}

const markProviderDocumentRead = (
    providerDocuments: dynamicStoreData<IProviderDocument[]>,
    providerDocumentId: string
) =>
    updateProviderDocuments(providerDocuments, (x) => ({
        ...x,
        isNew: x.id.toLowerCase() === providerDocumentId.toLowerCase() ? false : x.isNew
    }))

const markProviderDocumentFeedbackRead = (
    providerDocuments: dynamicStoreData<IProviderDocument[]>,
    providerDocumentId: string
) =>
    updateProviderDocuments(providerDocuments, (x) => ({
        ...x,
        hasNewFeedback: x.id === providerDocumentId ? false : x.hasNewFeedback
    }))

const removeProviderDocument = (
    providerDocuments: dynamicStoreData<IProviderDocument[]>,
    providerDocumentId: string
) => {
    const newProviderDocuments: any = {}
    for (const [key, value] of Object.entries<any>(providerDocuments)) {
        if (value) {
            newProviderDocuments[key] = value.filter((x: any) => x.id !== providerDocumentId)
        }
    }
    return newProviderDocuments
}

export const documentsSlice = createSlice({
    name: 'documentsSlice',
    initialState,
    reducers: {
        deleteAwvSupportingDocuments: (state, action) => {
            state.awvSupportingDocuments = {
                ...state.awvSupportingDocuments,
                [getAwvKey(action.payload.memberId, action.payload.npi, action.payload.year)]: undefined
            }
        },
        providerDocumentFeedbackRead: (state, action) => {
            state.providerDocuments = markProviderDocumentFeedbackRead(
                state.providerDocuments,
                action.payload.providerDocumentId
            )
            state.providerReports = markProviderDocumentFeedbackRead(
                state.providerReports,
                action.payload.providerDocumentId
            )
        },
        providerDocumentRead: (state, action) => {
            state.providerDocuments = markProviderDocumentRead(state.providerDocuments, action.payload)
            state.providerReports = markProviderDocumentRead(state.providerReports, action.payload)
        },
        updateReportCountByNpi: (state, action) => {
            const newReportCounts = [...state.newReportCounts]
            newReportCounts.forEach((item, index) => {
                if (item.npi === action.payload && item.count > 0) newReportCounts[index].count--
            })
            state.newReportCounts = newReportCounts

            const newReportCountsByUserId = { ...state.newReportCountsByUserId }
            for (const [key, value] of Object.entries<INewProviderReportCount[]>(newReportCountsByUserId)) {
                newReportCountsByUserId[key] = value.map((x) => {
                    if (x.npi === action.payload && x.count > 0) x.count--
                    return x
                })
            }
            state.newReportCountsByUserId = newReportCountsByUserId
        },
        setUserNpiNewReportCount: (state, action) => {
            const newReportCounts = [...state.newReportCounts]
            newReportCounts.forEach((item, index) => {
                if (item.npi === action.payload.npi) newReportCounts[index].count = action.payload.newReportCount
            })

            const newReportCountsByUserId = { ...state.newReportCountsByUserId }
            for (const [key, value] of Object.entries<INewProviderReportCount[]>(newReportCountsByUserId)) {
                newReportCountsByUserId[key] = value.map((x) => {
                    if (x.npi === action.payload.npi) x.count = action.payload.newReportCount
                    return x
                })
            }
            state.newReportCountsByUserId = newReportCountsByUserId
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMemberProviderDocuments.fulfilled, (state, action) => {
                state.providerDocuments = {
                    ...state.providerDocuments,
                    [`${action.meta.arg.memberId}`]: action.payload.providerDocuments
                }
            })
            .addCase(getMemberNpiProviderDocuments.fulfilled, (state, action) => {
                state.providerDocuments = {
                    ...state.providerDocuments,
                    [`${action.meta.arg.memberId}-${action.meta.arg.npi}`]: action.payload.providerDocuments
                }
            })
            .addCase(uploadProviderDocument.fulfilled, (state, action) => {
                const providerDocumentsKey = `${action.meta.arg.memberId}-${action.meta.arg.npi}`
                if (state.providerDocuments[providerDocumentsKey]) {
                    const documents = [...state.providerDocuments[providerDocumentsKey], action.payload.document].sort(
                        (x, y) => {
                            let result = x.fileName.localeCompare(y.fileName)
                            if (result === 0) {
                                result = new Date(x.dateOfSubmission) < new Date(y.dateOfSubmission) ? -1 : 1
                            }
                            return result
                        }
                    )
                    state.providerDocuments = {
                        ...state.providerDocuments,
                        [providerDocumentsKey]: documents
                    }
                } else {
                    state.providerDocuments = {
                        ...state.providerDocuments,
                        [providerDocumentsKey]: [action.payload.document]
                    }
                }
            })
            .addCase(deleteProviderDocument.fulfilled, (state, action) => {
                state.providerDocuments = removeProviderDocument(
                    state.providerDocuments,
                    action.meta.arg.providerDocumentId
                )
                state.providerReports = removeProviderDocument(
                    state.providerReports,
                    action.meta.arg.providerDocumentId
                )
            })
            .addCase(reprocessMedicalChart.fulfilled, (state, action) => {
                const docs: any = {}
                for (const [key, value] of Object.entries<any>(state.providerDocuments)) {
                    docs[key] = value.map((x: IProviderDocument) => {
                        if (x.id === action.meta.arg.providerDocumentId) {
                            x.statusId = 2
                            x.status = 'Pending'
                        }
                        return x
                    })
                }
                state.providerDocuments = docs
            })

            .addCase(uploadAwvSupportingDocument.fulfilled, (state, action) => {
                const awvKey = getAwvKey(action.meta.arg.memberId, action.meta.arg.npi, action.meta.arg.year)
                if (state.awvSupportingDocuments[awvKey]) {
                    const awvSupportingDocuments = [
                        ...state.awvSupportingDocuments[awvKey],
                        action.payload.awvSupportingDocument
                    ].sort((x, y) => {
                        let result = x.fileName.localeCompare(y.fileName)
                        if (result === 0) {
                            result = new Date(x.uploadedOn) < new Date(y.uploadedOn) ? -1 : 1
                        }
                        return result
                    })
                    state.awvSupportingDocuments = { ...state.awvSupportingDocuments, [awvKey]: awvSupportingDocuments }
                } else {
                    state.awvSupportingDocuments = {
                        ...action.payload.awvSupportingDocument,
                        [awvKey]: [action.payload.awvSupportingDocument]
                    }
                }
            })
            .addCase(getAwvSupportingDocuments.fulfilled, (state, action) => {
                state.awvSupportingDocuments = {
                    ...state.awvSupportingDocuments,
                    [getAwvKey(action.meta.arg.memberId, action.meta.arg.npi, action.meta.arg.year)]:
                        action.payload.awvSupportingDocuments
                }
            })
            .addCase(deleteAwvSupportingDocument.fulfilled, (state, action) => {
                state.awvSupportingDocuments = removeProviderDocument(
                    state.awvSupportingDocuments,
                    action.meta.arg.awvSupportingDocumentId
                )
            })
            .addCase(fetchSupportingDocuments.fulfilled, (state, action) => {
                state.supportingDocs = action.payload
            })

            .addCase(getProviderReports.fulfilled, (state, action) => {
                state.providerReports = {
                    ...state.providerReports,
                    [action.meta.arg.npi]: action.payload.providerReports
                }
            })
            .addCase(uploadProviderReport.fulfilled, (state, action) => {
                state.providerReports[action.meta.arg.npi] = [
                    action.payload.document,
                    ...state.providerReports[action.meta.arg.npi]
                ]
            })
            .addCase(getNewReportCounts.fulfilled, (state, action) => {
                state.newReportCounts = action.payload
            })
            .addCase(getUserNewReportCount.fulfilled, (state, action) => {
                state.newReportCountsByUserId = {
                    ...state.newReportCountsByUserId,
                    [action.meta.arg.userId]: action.payload
                }
            })
    }
})

export default documentsSlice.reducer

export const {
    deleteAwvSupportingDocuments,
    providerDocumentFeedbackRead,
    providerDocumentRead,
    updateReportCountByNpi,
    setUserNpiNewReportCount
} = documentsSlice.actions

/**
 * API Calls for Documents
 */

export const getMemberProviderDocuments = createExceptionAwareAsyncThunk(
    'ProviderDocument/GetMemberProviderDocuments',
    async (args: IGetMemberProviderDocumentsRequest) => {
        const response = await axios.post<IGetMemberProviderDocumentsResponse>(
            'api/ProviderDocument/GetMemberProviderDocuments',
            args
        )
        return response.data
    }
)

export const getMemberNpiProviderDocuments = createExceptionAwareAsyncThunk(
    'ProviderDocument/GetMemberNpiProviderDocuments',
    async (args: IGetMemberNpiProviderDocumentsRequest) => {
        const response = await axios.post<IGetMemberNpiProviderDocumentsResponse>(
            'api/ProviderDocument/GetMemberNpiProviderDocuments',
            args
        )
        return response.data
    }
)

export const uploadProviderDocument = createExceptionAwareAsyncThunk(
    'ProviderDocument/Upload',
    async (args: ProviderDocumentUploadRequest) => {
        const response = await axios.post('api/ProviderDocument/Upload', args, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `The document has been uploaded.`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const deleteProviderDocument = createExceptionAwareAsyncThunk(
    'ProviderDocument/Delete',
    async (args: ProviderDocumentByIdRequest) => {
        const response = await axios.post('api/ProviderDocument/Delete', args)

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `The document has been deleted.`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }

        return response.data
    }
)
export const reprocessMedicalChart = createExceptionAwareAsyncThunk(
    'ProviderDocument/ReprocessMedicalChart',
    async (args: ProviderDocumentByIdRequest) => {
        const response = await axios.post('api/ProviderDocument/ReprocessMedicalChart', args)

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `The document has been sent for reprocessing.`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const downloadProviderDocument = createExceptionAwareAsyncThunk(
    'ProviderDocument/Download',
    async (args: ProviderDocumentByIdRequest) => {
        const response = await axios.post('api/ProviderDocument/Download', args, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf'
            }
        })

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', response.headers['file-name'])
        document.body.appendChild(link)
        link.click()

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `The document has been downloaded.`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const uploadAwvSupportingDocument = createExceptionAwareAsyncThunk(
    'awv/UploadAwvSupportingDocument',
    async (args: IUploadAwvSupportingDocumentRequest) => {
        const response = await axios.post<IUploadAwvSupportingDocumentResponse>(
            'api/Awv/UploadAwvSupportingDocument',
            args,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                timeout: fileStreamTimeout
            }
        )

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `The encounter/supporting document has been uploaded.`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }

        return response.data
    }
)

export const getAwvSupportingDocuments = createExceptionAwareAsyncThunk(
    'awv/GetAwvSupportingDocuments',
    async (args: IGetAwvSupportingDocumentsRequest) => {
        const response = await axios.post<IGetAwvSupportingDocumentsResponse>('api/Awv/GetAwvSupportingDocuments', args)
        return response.data
    }
)

export const downloadAwvSupportingDocument = createExceptionAwareAsyncThunk(
    'Awv/DownloadAwvSupportingDocument',
    async (args: DownloadSupportingDocsRequest) => {
        const response = await axios.post('api/Awv/DownloadAwvSupportingDocument', args, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf'
            }
        })

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', response.headers['file-name'])
        document.body.appendChild(link)
        link.click()

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `The encounter/supporting document has been downloaded.`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }

        return response.data
    }
)

export const deleteAwvSupportingDocument = createExceptionAwareAsyncThunk(
    'awv/DeleteAwvSupportingDocument',
    async (args: IDeleteAwvSupportingDocumentRequest) => {
        const response = await axios.post('api/Awv/DeleteAwvSupportingDocument', args)

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `The encounter/supporting document has been deleted.`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }

        return response.data
    }
)

export const fetchSupportingDocuments = createExceptionAwareAsyncThunk(
    'Awv/GetAwvSupportingDocuments',
    async (args: SupportingDocsRequest) => {
        const response = await axios.post('api/Awv/GetAwvSupportingDocuments', args)
        return response.data
    }
)

export type getProviderReportsRequest = {
    npi: string
}

type getProviderReportsResponse = {
    providerReports: IProviderDocument[]
}

export const getProviderReports = createExceptionAwareAsyncThunk(
    'ProviderReport/Gets',
    async (args: getProviderReportsRequest) => {
        const response = await axios.post<getProviderReportsResponse>('api/ProviderDocument/GetProviderReports', args)
        dispatch(
            setUserNpiNewReportCount({
                npi: args.npi,
                newReportCount: response.data.providerReports.filter((x) => x.isNew).length
            })
        )
        return response.data
    }
)

export type uploadProviderReportRequest = {
    file: any
    npi: string
}

export const uploadProviderReport = createExceptionAwareAsyncThunk(
    'ProviderReport/Upload',
    async (args: uploadProviderReportRequest) => {
        const response = await axios.post('api/ProviderDocument/UploadProviderReport', args, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `The document has been uploaded.`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export interface INewProviderReportCount {
    npi: string
    userId: string
    count: number
}
export const getNewReportCounts = createExceptionAwareAsyncThunk('ProviderDocument/GetNewReportCount', async () => {
    const response = await axios.get<INewProviderReportCount[]>('api/ProviderDocument/GetNewReportCount')
    return response.data
})

interface IGetUserNewReportCountRequest {
    userId: string
}

export const getUserNewReportCount = createExceptionAwareAsyncThunk(
    'ProviderDocument/GetUserNewReportCount',
    async (args: IGetUserNewReportCountRequest) => {
        const response = await axios.post<INewProviderReportCount[]>('api/ProviderDocument/GetUserNewReportCount', args)
        dispatch(
            setNewReportCount({
                userId: args.userId,
                newReportCount: response.data.reduce((n, { count }) => n + count, 0)
            })
        )
        return response.data
    }
)
