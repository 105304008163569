export const uploadIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M16.125 8.36634C15.5583 5.49134 13.0333 3.33301 10 3.33301C7.59167 3.33301 5.5 4.69967 4.45833 6.69967C1.95 6.96634 0 9.09134 0 11.6663C0 14.4247 2.24167 16.6663 5 16.6663H15.8333C18.1333 16.6663 20 14.7997 20 12.4997C20 10.2997 18.2917 8.51634 16.125 8.36634ZM11.6667 10.833V14.1663H8.33333V10.833H5.83333L10 6.66634L14.1667 10.833H11.6667Z" fill="#2281C4"/>
</svg>`

export const disabledUploadIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M16.125 8.36634C15.5583 5.49134 13.0333 3.33301 10 3.33301C7.59167 3.33301 5.5 4.69967 4.45833 6.69967C1.95 6.96634 0 9.09134 0 11.6663C0 14.4247 2.24167 16.6663 5 16.6663H15.8333C18.1333 16.6663 20 14.7997 20 12.4997C20 10.2997 18.2917 8.51634 16.125 8.36634ZM11.6667 10.833V14.1663H8.33333V10.833H5.83333L10 6.66634L14.1667 10.833H11.6667Z" fill="#A4B0C0"/>
</svg>`

export const deleteIcon = `
<svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.5002 12.5V13.3333H26.6668V15H25.8335V25.8333C25.8335 26.75 25.0835 27.5 24.1668 27.5H15.8335C14.9168 27.5 14.1668 26.75 14.1668 25.8333V15H13.3335V13.3333H17.5002V12.5H22.5002ZM15.8335 25.8333H24.1668V15H15.8335V25.8333ZM17.5002 16.6667H19.1668V24.1667H17.5002V16.6667ZM22.5002 16.6667H20.8335V24.1667H22.5002V16.6667Z" fill="#B11B1B"/>
</svg>
`

export const disabledDeleteIcon = `
<svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.5002 12.5V13.3333H26.6668V15H25.8335V25.8333C25.8335 26.75 25.0835 27.5 24.1668 27.5H15.8335C14.9168 27.5 14.1668 26.75 14.1668 25.8333V15H13.3335V13.3333H17.5002V12.5H22.5002ZM15.8335 25.8333H24.1668V15H15.8335V25.8333ZM17.5002 16.6667H19.1668V24.1667H17.5002V16.6667ZM22.5002 16.6667H20.8335V24.1667H22.5002V16.6667Z" fill="#A4B0C0"/>
</svg>
`
