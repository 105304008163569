import { FC } from 'react'
import { StyledAWVDigitalInput, StyleFieldResetContainer, StyledResetContainer } from '../index.style'
import { Controller, useFormContext } from 'react-hook-form'
import { INumericField, ISectionElement } from '../index.models'
import { Label } from '../Label'
import { AwvTooltip } from '../AwvTooltip'
import useErrors from 'hooks/useErrors'
import { useResetValue } from 'hooks/useResetValue'
import IconButton from '@mui/material/IconButton'
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp'
import { Tooltip } from '@mui/material'

interface INumericFieldProps {
    name: string
    label: string
    subLabel: string
    placeholder: string
    minValue: number
    maxValue: number
    step: number
}

const NumericField: FC<INumericFieldProps> = ({ name, label, subLabel, placeholder, minValue, maxValue, step }) => {
    const { control, setValue, getValues } = useFormContext()
    const errors = useErrors()
    const resetValue = useResetValue()

    return (
        <AwvTooltip message={errors.getError(name)}>
            <StyledAWVDigitalInput isInError={errors.getError(name)}>
                <Label htmlFor={name} label={label} subLabel={subLabel} />
                <StyleFieldResetContainer>
                    <Controller
                        name={name}
                        control={control}
                        render={({ field }) => (
                            <input
                                id={name}
                                {...field}
                                type="number"
                                value={field.value ?? ''}
                                min={minValue}
                                max={maxValue}
                                step={step}
                                onChange={(e) => setValue(name, Number(e.target.value).valueOf())}
                                placeholder={placeholder ?? label}
                            />
                        )}
                    />
                    <StyledResetContainer>
                        {getValues(name) !== null && getValues(name) !== undefined && (
                            <Tooltip title="Reset">
                                <IconButton
                                    onClick={() => {
                                        resetValue(name)
                                    }}
                                >
                                    <HighlightOffSharpIcon sx={{ color: '#9e9e9e' }} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </StyledResetContainer>
                </StyleFieldResetContainer>
            </StyledAWVDigitalInput>
        </AwvTooltip>
    )
}

export const renderNumericField = (element: ISectionElement, key: string) => {
    const numericField = element as INumericField
    return <NumericField key={key} {...numericField} />
}
