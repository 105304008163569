import styled, { css } from 'styled-components'
import { DEVICE, pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'
import Typography from 'components/Typography'

const { light_gray3, white } = UI_COLORS

export const StyledYTDContainer = styled.article`
    width: 100%;
    height: auto;
    margin-bottom: ${pxToRem(30)};

    background: ${white};
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
    border-radius: ${pxToRem(4)};

    display: flex;
    flex-direction: row;

    h2 {
        margin-left: ${pxToRem(24)};
    }

    ${DEVICE.desktop} {
        flex-direction: column;
    }
`

export const StyledYTDContainerInner = styled.article`
    width: 100%;
    height: auto;
    margin-bottom: ${pxToRem(30)};
    display: flex;
    flex-direction: row;

    ${DEVICE.desktop} {
        height: ${pxToRem(176)};
        width: ${pxToRem(1145)};
        flex-direction: row;
    }
`

export const StyledYTDInnerContainer = styled.article`
    width: 100%;
    height: auto;
    margin-bottom: ${pxToRem(30)};

    background: ${white};
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
    border-radius: ${pxToRem(4)};

    display: flex;
    flex-direction: row;

    ${DEVICE.desktop} {
        height: ${pxToRem(176)};
        width: ${pxToRem(1145)};
        flex-direction: row;
    }
`

export const StyledYTDGraphContainer = styled.article`
    height: ${pxToRem(264)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;

    p {
        margin: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        /* or 18px */

        color: #394a64;
    }

    svg {
        width: 100%;
        height: auto;
    }

    > span {
        display: none;
    }

    ${DEVICE.desktop} {
        width: 40%;
        height: ${pxToRem(176)};

        > p {
            transform: translateY(-32px);
        }

        > div {
            transform: translateY(-25px);
        }

        ${DEVICE.desktop} {
            > span {
                display: block;
                position: absolute;
                right: 30px;
                bottom: 20px;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: ${pxToRem(12)};
                line-height: ${pxToRem(16)};

                color: #394a64;
            }
        }
    }

    > div {
        > div {
            svg {
                overflow: visible;
            }
        }
    }
`

export const StyledYTDMetricsContainer = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    width: 100%;

    ${DEVICE.tablet} {
        flex-direction: row;
        padding: 0 25px;

        li {
            width: 33%;

            :nth-child(2) {
                border-right: 0.5px solid ${light_gray3};
                border-left: 0.5px solid ${light_gray3};
            }
        }
    }

    ${DEVICE.desktop} {
        width: 60%;
        height: ${pxToRem(200)};

        justify-content: center;
        align-items: center;

        li {
            :nth-child(2) {
                border: 0;
            }
        }
    }
`

export const StyledYTDMetricItemContainer = styled.li<{ isClickable?: boolean }>`
    height: ${pxToRem(60)};
    display: flex;
    flex-direction: row;
    justify-content: center;

    margin-bottom: ${pxToRem(32)};

    ${DEVICE.tablet} {
        margin-bottom: ${pxToRem(32)};
    }

    ${DEVICE.tablet} {
        :nth-child(1) {
            border-right: ${pxToRem(0.5)} solid ${light_gray3};
        }

        :nth-child(3) {
            border-left: ${pxToRem(0.5)} solid ${light_gray3};
        }
    }

    ${(props) => {
        if (props.isClickable && props.isClickable === true) {
            return css`
                &:hover {
                    cursor: pointer;
                }
            `
        } else {
            return css`
                &:hover {
                    cursor: default;
                }
            `
        }
    }}
`

export const StyledYTDMetricIconWrap = styled.article`
    width: 60px;

    display: flex;
    align-items: center;
    justify-content: center;

    figure {
        margin: 0;
    }
`

export const StyledYTDMetricTitleAndItemWrap = styled.article`
    width: 150px;
    height: 60px;

    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const StyledYTDMetricTitleWrap = styled.article`
    width: 100%;

    display: flex;
    align-items: center;

    p {
        margin: 0;

        font-family: 'Inter', 'sans-serif';
        font-style: normal;
        font-weight: 600;
        font-size: ${pxToRem(16)};
        line-height: ${pxToRem(22)};
        color: ${light_gray3};
    }

    ${DEVICE.tablet} {
        p {
            font-size: ${pxToRem(14)};
            line-height: ${pxToRem(18)};
        }
    }
`

export const StyledFilterBlock = styled.div`
    display: block;
    padding-top: 25px;
    margin-right: -50px;
    width: 100%;
`

export const StyledCalendarDropDownContainer = styled.div`
    margin-right: ${pxToRem(15)};
`

export const StyledLOBDropDownContainer = styled.div`
    display: block;
    float: right;
    margin-left: auto;
    width: 250px;
`

export const StyledCalendarIconForIncentives = styled.div<{ image?: string }>`
    position: relative;
    &:after {
        content: '';
        position: absolute;
        transform: TranslateX(-100%);
        width: 20px;
        height: 70px;
        top: 15px;
        left: 35px;

        width: ${pxToRem(16)};
        height: ${pxToRem(16)};

        z-index: 1;

        border: 0;

        background-size: cover;

        cursor: pointer;

        ${(props) => {
            if (props.image) {
                return css`
                    background-image: url(${props.image});
                `
            }
        }};
    }
`

export const StyledLOBIconForIncentives = styled.div<{ image?: string }>`
    &:after {
        content: '';
        position: absolute;
        transform: TranslateX(-100%);
        width: 20px;
        height: 70px;

        width: ${pxToRem(16)};
        height: ${pxToRem(16)};
        margin-top: ${pxToRem(15)};
        margin-left: ${pxToRem(30)};
        z-index: 1;
        border: 0;

        background-size: cover;
        background-color: white;

        cursor: pointer;
        z-index: 0;

        ${(props) => {
            if (props.image) {
                return css`
                    background-image: url(${props.image});
                `
            }
        }};
    }
`

export const StyledOverviewSpacer = styled.div`
    display: flex;
    flex-direction: column;
    width: 30px;
`

export const StyledOverviewText = styled.div``

export const StyledOverviewContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: ${pxToRem(100)};
    width: 100%;
    background: ${white};
    /* margin-left: ${pxToRem(100)}; */
`

export const StyledYTDTitle = styled.li`
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(22)};
        line-height: ${pxToRem(26)};
        letter-spacing: -0.01em;
        color: #293854;
        margin: 0;
    }
`

export const StyledYTDMetricItemWrap = styled.article`
    width: 100%;

    display: flex;
    align-items: center;

    p {
        margin: 0;
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(16)};
        line-height: ${pxToRem(19)};

        letter-spacing: -0.01em;
        color: #394a64;

        :nth-child(2) {
            width: 55px;
            height: 19px;
            background-color: #e3eef3;
            border-radius: 10px;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 120%;

            color: #0bc196;
        }
    }
`

export const StyledYTDGraphMetricsGroup = styled.article`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: ${pxToRem(56)};

    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(20)};
        line-height: ${pxToRem(28)};

        color: #293854;
    }

    span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: ${pxToRem(12)};
        line-height: ${pxToRem(16)};

        color: #394a64;
    }

    figure {
        margin: 0;
    }

    ${DEVICE.desktop} {
        bottom: ${pxToRem(15)};

        span {
            display: none;
        }
    }
`

export const StyledYTDRemainingLabel = styled.article`
    position: absolute;
    right: 35px;
    top: 80px;
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    width: 93px;
    height: 45px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 140%;
        /* identical to box height, or 17px */

        color: #293854;
    }

    ${DEVICE.tablet} {
        width: 115px;
        height: 65px;

        right: 108px;
        top: 60px;
    }

    ${DEVICE.desktop} {
        right: 17px;

        top: 41px;
    }
`

export const StyledYTDRemainingLabelWrap = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
        display: block;
        width: 10px;
        height: 10px;
        background-color: rgb(227, 238, 243);
        border-radius: 10px;
        margin-right: 5px;
    }

    h3 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 130%;
        /* identical to box height, or 16px */

        color: #73839c;
        margin: 0;
    }
`

export const StyledYTDActiveMemberContainer = styled.article`
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;

    width: 55px;
    background-color: #f6f9fd;
    border-radius: 10px;
    margin-left: 8px;

    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 120%;
        color: #0bc196;
    }

    figure {
        margin: 0;
    }
`

export const StyledEmptyStateContainer = styled.article`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    figure {
        margin: 0;
    }
`
