import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'

const reactPlugin = new ReactPlugin()
export const browserHistory = createBrowserHistory({})
const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING,
        extensions: [reactPlugin],
        extensionConfig: {},
        enableAutoRouteTracking: false,
        disableAjaxTracking: false,
        autoTrackPageVisitTime: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true
    }
})
appInsights.loadAppInsights()

appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
    env.tags = env.tags || []
    env.tags['ai.cloud.role'] = 'testTag'
})

export { reactPlugin, appInsights }
