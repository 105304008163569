import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC, useEffect, useState } from 'react'
import { StyledMemberInputContainer } from '../MemberInput/index.style'
import {
    StyledBenefitInfoContainer,
    StyledBenefitInfoRowContainer,
    StyledBenefitInfoInputContainer,
    StyledBenefitInfoSecondaryInputContainer,
    StyledBenefitInfoSectionContainer
} from './index.style'
import { useForm, useFieldArray } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { MemberProfileState } from 'redux/slices/memberProfile'
import { toLocalDate } from 'utilities/dateutilities'
import { IMemberProfileBenefit, IMemberProfileDetails } from 'pages/hcp/users/create/index.models'
import Spinner from 'components/Spinner'

interface IBenefitInfoProps {}

const BenefitInfo: FC<IBenefitInfoProps> = ({}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        getValues
    } = useForm({})

    const [isFormLoading, setIsFormLoading] = useState<boolean>(true)

    const memberProfileDetails: IMemberProfileDetails = useSelector(
        (state: { memberProfile: MemberProfileState }) => state.memberProfile.memberProfileDetails
    )

    const { fields, append, prepend, remove } = useFieldArray({
        name: 'memberBenefits',
        control,
        rules: {
            required: 'Please append at least 1 item'
        }
    })

    const convertToClient = (benefit: IMemberProfileBenefit) => {
        const clientBenefit = {
            ...benefit, // other fields
            coveredFrom: toLocalDate(benefit.coveredFrom),
            coveredThru: toLocalDate(benefit.coveredThru)
        } as IMemberProfileBenefit

        return clientBenefit
    }

    useEffect(() => {
        if (memberProfileDetails) {
            setIsFormLoading(false)
            remove()
            let index = 0
            memberProfileDetails.benefits.forEach((benefit) => {
                append(convertToClient(benefit))
                index++
            })
        }
    }, [memberProfileDetails])

    return (
        <>
            {isFormLoading === true ? (
                <Spinner />
            ) : (
                <StyledBenefitInfoContainer>
                    <Typography type={TYPOGRAPHY_TYPES.h3}>Benefit Info</Typography>
                    <fieldset disabled>
                        <form>
                            <>
                                {fields.map((field, index) => {
                                    return (
                                        <StyledBenefitInfoSectionContainer key={field.id}>
                                            <StyledBenefitInfoRowContainer>
                                                <StyledBenefitInfoInputContainer>
                                                    <StyledMemberInputContainer>
                                                        <label>Patient ID</label>
                                                        <input
                                                            name={`memberBenefits.${index}.membId`}
                                                            {...register(`memberBenefits.${index}.membId`)}
                                                        />
                                                        <small>{errors?.memberID && errors.memberID.message}</small>
                                                    </StyledMemberInputContainer>
                                                </StyledBenefitInfoInputContainer>
                                                <StyledBenefitInfoSecondaryInputContainer>
                                                    <StyledMemberInputContainer>
                                                        <label>Covered From</label>
                                                        <input
                                                            name={`memberBenefits.${index}.coveredFrom`}
                                                            {...register(`memberBenefits.${index}.coveredFrom`)}
                                                        />
                                                        <small>{errors?.startDate && errors.startDate.message}</small>
                                                    </StyledMemberInputContainer>
                                                </StyledBenefitInfoSecondaryInputContainer>

                                                <StyledBenefitInfoSecondaryInputContainer>
                                                    <StyledMemberInputContainer>
                                                        <label>Through</label>
                                                        <input
                                                            name={`memberBenefits.${index}.coveredThru`}
                                                            {...register(`memberBenefits.${index}.coveredThru`)}
                                                        />
                                                        <small>{errors?.endDate && errors.endDate.message}</small>
                                                    </StyledMemberInputContainer>
                                                </StyledBenefitInfoSecondaryInputContainer>
                                            </StyledBenefitInfoRowContainer>

                                            <StyledBenefitInfoRowContainer>
                                                <StyledBenefitInfoInputContainer>
                                                    <StyledMemberInputContainer>
                                                        <label>Health Plan</label>
                                                        <input
                                                            name={`memberBenefits.${index}.healthPlanName`}
                                                            {...register(`memberBenefits.${index}.healthPlanName`)}
                                                        />
                                                        <small>{errors?.healthPlan && errors.healthPlan.message}</small>
                                                    </StyledMemberInputContainer>
                                                </StyledBenefitInfoInputContainer>

                                                <StyledBenefitInfoInputContainer>
                                                    <StyledMemberInputContainer>
                                                        <label>Line of Business</label>
                                                        <input
                                                            name={`memberBenefits.${index}.lineOfBusiness`}
                                                            {...register(`memberBenefits.${index}.lineOfBusiness`)}
                                                        />
                                                        <small>
                                                            {errors?.lineOfBusiness && errors.lineOfBusiness.message}
                                                        </small>
                                                    </StyledMemberInputContainer>
                                                </StyledBenefitInfoInputContainer>
                                            </StyledBenefitInfoRowContainer>
                                        </StyledBenefitInfoSectionContainer>
                                    )
                                })}
                            </>
                        </form>
                    </fieldset>
                </StyledBenefitInfoContainer>
            )}
        </>
    )
}

export default BenefitInfo
