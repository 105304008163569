import { FC } from 'react'
import {
    StyledAddLink,
    StyledDeleteButton,
    StyledExplanatoryNote,
    StyledFormTextAreaContainer,
    StyledResetContainer,
    StyledStatusContainer,
    StyledTableStatusContainer,
    StyledTableStatusListContainer,
    StyledTextFieldContainer,
    StyledTxtFieldRadioTableBody,
    StyledTxtFieldRadioTableBodyItem,
    StyledTxtFieldRadioTableContainer,
    StyledTxtFieldRadioTableHeader,
    StyledTxtFieldRadioTableHeaderItem
} from './index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { IOtherConditionsList, IOption, ISectionElement } from '../index.models'
import { AwvTooltip } from '../AwvTooltip'
import useErrors from 'hooks/useErrors'
import IconButton from '@mui/material/IconButton'
import { Tooltip } from '@mui/material'
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp'
import { useResetValue } from 'hooks/useResetValue'

interface IOtherConditionsListProps {
    name: string
    activeChronicDiagnosisFieldName: string
    statusFieldName: string
    statusOptions: IOption[]
    icd10CodeFieldName: string
    planFieldName: string
    activeChronicDiagnosisColumnLabel: string
    statusColumnLabel: string
    planColumnLabel: string
    statusLabel: string
    icd10CodeLabel: string
    explanatoryNote: string
    addConditionLinkLabel: string
    deleteTooltip: string
}

const deleteIcon = require('assets/delete-2.svg').default

const baseHeight = 127

const statusOptionHeight = 36

const OtherConditionsList: FC<IOtherConditionsListProps> = ({
    name,
    activeChronicDiagnosisFieldName,
    statusFieldName,
    statusOptions,
    icd10CodeFieldName,
    planFieldName,
    activeChronicDiagnosisColumnLabel,
    statusColumnLabel,
    planColumnLabel,
    statusLabel,
    icd10CodeLabel,
    explanatoryNote,
    addConditionLinkLabel,
    deleteTooltip
}) => {
    const { register, control, getValues } = useFormContext()
    const errors = useErrors()
    const resetValue = useResetValue()
    const { fields, append, remove } = useFieldArray({ control, name })

    const textAreaStyle = { height: `${baseHeight + statusOptions.length * statusOptionHeight}px` }

    const handleAdd = (e: any) => {
        e.preventDefault()
        append({})
    }

    const handleRemove = (e: any, index: number) => {
        e.preventDefault()
        remove(index)
    }

    return (
        <>
            {fields?.length > 0 && (
                <>
                    <StyledTxtFieldRadioTableContainer>
                        <StyledTxtFieldRadioTableHeader>
                            {[activeChronicDiagnosisColumnLabel, statusColumnLabel, planColumnLabel].map(
                                (headerLabel: string, index: number) => (
                                    <StyledTxtFieldRadioTableHeaderItem key={`${name}-header-${index}`}>
                                        <Typography type={TYPOGRAPHY_TYPES.p}>{headerLabel}</Typography>
                                    </StyledTxtFieldRadioTableHeaderItem>
                                )
                            )}
                        </StyledTxtFieldRadioTableHeader>
                        {fields.map((field: any, index: number) => (
                            <AwvTooltip
                                key={`OtherConditionsList.tooltip.${name}.${index}`}
                                message={errors.getError(`${name}.${index}`)}
                                placement="top-start"
                            >
                                <StyledTxtFieldRadioTableBody
                                    key={field.id}
                                    isInError={errors.getError(`${name}.${index}`)}
                                >
                                    <StyledTxtFieldRadioTableBodyItem>
                                        <AwvTooltip
                                            key={`OtherConditionsList_tooltip.${name}.${index}.${activeChronicDiagnosisFieldName}`}
                                            message={errors.getError(
                                                `${name}.${index}.${activeChronicDiagnosisFieldName}`
                                            )}
                                        >
                                            <StyledFormTextAreaContainer
                                                isInError={errors.getError(
                                                    `${name}.${index}.${activeChronicDiagnosisFieldName}`
                                                )}
                                            >
                                                <textarea
                                                    {...register(`${name}.${index}.${activeChronicDiagnosisFieldName}`)}
                                                    style={textAreaStyle}
                                                />
                                            </StyledFormTextAreaContainer>
                                        </AwvTooltip>
                                    </StyledTxtFieldRadioTableBodyItem>
                                    <StyledTxtFieldRadioTableBodyItem>
                                        <Typography type={TYPOGRAPHY_TYPES.p}>{statusLabel}</Typography>
                                        <StyledStatusContainer>
                                            <AwvTooltip
                                                key={`OtherConditionsList_tooltip.${name}.${index}.${statusFieldName}`}
                                                message={errors.getError(`${name}.${index}.${statusFieldName}`)}
                                            >
                                                <StyledTableStatusContainer
                                                    isInError={errors.getError(`${name}.${index}.${statusFieldName}`)}
                                                >
                                                    {statusOptions.map((option: IOption, optionIndex: number) => (
                                                        <StyledTableStatusListContainer
                                                            key={`${field.id}-statusOption-${optionIndex}`}
                                                        >
                                                            <label>
                                                                <input
                                                                    {...register(`${name}.${index}.${statusFieldName}`)}
                                                                    type="radio"
                                                                    value={option.value}
                                                                />
                                                                {option.label}
                                                            </label>
                                                        </StyledTableStatusListContainer>
                                                    ))}
                                                </StyledTableStatusContainer>
                                            </AwvTooltip>
                                            <StyledResetContainer>
                                                {getValues(`${name}.${index}.${statusFieldName}`) !== null &&
                                                    getValues(`${name}.${index}.${statusFieldName}`) !== undefined && (
                                                        <Tooltip title="Reset">
                                                            <IconButton
                                                                onClick={() => {
                                                                    resetValue(`${name}.${index}.${statusFieldName}`)
                                                                }}
                                                            >
                                                                <HighlightOffSharpIcon sx={{ color: '#9e9e9e' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                            </StyledResetContainer>
                                        </StyledStatusContainer>
                                        <Typography type={TYPOGRAPHY_TYPES.p}>{icd10CodeLabel}</Typography>

                                        <AwvTooltip
                                            key={`OtherConditionsList.tooltip.${name}.${index}.${icd10CodeFieldName}`}
                                            message={errors.getError(`${name}.${index}.${icd10CodeFieldName}`)}
                                        >
                                            <StyledTextFieldContainer
                                                isInError={errors.getError(`${name}.${index}.${icd10CodeFieldName}`)}
                                            >
                                                <input {...register(`${name}.${index}.${icd10CodeFieldName}`)} />
                                            </StyledTextFieldContainer>
                                        </AwvTooltip>
                                    </StyledTxtFieldRadioTableBodyItem>
                                    <StyledTxtFieldRadioTableBodyItem>
                                        <AwvTooltip
                                            key={`OtherConditionsList.tooltip.${name}.${index}.${planFieldName}`}
                                            message={errors.getError(`${name}.${index}.${planFieldName}`)}
                                        >
                                            <StyledFormTextAreaContainer
                                                isInError={errors.getError(`${name}.${index}.${planFieldName}`)}
                                            >
                                                <textarea
                                                    {...register(`${name}.${index}.${planFieldName}`)}
                                                    style={textAreaStyle}
                                                />
                                            </StyledFormTextAreaContainer>
                                        </AwvTooltip>
                                    </StyledTxtFieldRadioTableBodyItem>
                                    <StyledTxtFieldRadioTableBodyItem>
                                        <StyledDeleteButton onClick={(e) => handleRemove(e, index)}>
                                            <img src={deleteIcon} alt={deleteTooltip ?? 'Delete'} />
                                            <p>{deleteTooltip ?? 'Delete'}</p>
                                        </StyledDeleteButton>
                                    </StyledTxtFieldRadioTableBodyItem>
                                </StyledTxtFieldRadioTableBody>
                            </AwvTooltip>
                        ))}
                    </StyledTxtFieldRadioTableContainer>
                    {explanatoryNote && <StyledExplanatoryNote>{explanatoryNote}</StyledExplanatoryNote>}
                </>
            )}
            <br />
            <StyledAddLink onClick={handleAdd}>{addConditionLinkLabel}</StyledAddLink>
        </>
    )
}

export const renderOtherConditionsList = (element: ISectionElement, key: string) => {
    const otherConditionsList = element as IOtherConditionsList
    return <OtherConditionsList key={key} {...otherConditionsList} />
}
