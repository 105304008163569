import React from 'react'
import { Bar, BarChart, CartesianGrid, LabelList, Tooltip, XAxis, YAxis } from 'recharts'
import { currencyFormatter, yAxisCurrencyFormatter } from '../YTDWidget/index.utils'
import { StyledAxisText, StyledBarMarker, StyledBarMarkerText, StyledYAxisText } from './index.style'

export const renderCustomizedLabel = (props: any) => {
    const { x, y, width, height, value } = props

    const fireOffset = value.toString().length < 5
    const offset = fireOffset ? -40 : 5
    return (
        <>
            <StyledBarMarker
                x1={x + width - (offset + 65)}
                y1={y + height}
                x2={x + width - (offset + 25)}
                y2={y + height}
            />
            <StyledBarMarkerText x={x + width - (offset + 20)} y={y + 5 + height} textAnchor="start">
                {value}
            </StyledBarMarkerText>
        </>
    )
}

export const renderCustomizedCurrencyLabel = (props: any) => {
    const { x, y, width, height, value } = props

    const fireOffset = value.toString().length < 5
    const offset = fireOffset ? -40 : 5
    return (
        <>
            <StyledBarMarker
                x1={x + width - (offset + 65)}
                y1={y + height}
                x2={x + width - (offset + 25)}
                y2={y + height}
            />
            <StyledBarMarkerText x={x + width - (offset + 20)} y={y + 5 + height} textAnchor="start">
                {currencyFormatter.format(Number(value))}
            </StyledBarMarkerText>
        </>
    )
}

export const renderThickCustomizedLabel = (props: any) => {
    const { x, y, width, height, value } = props

    const fireOffset = value.toString().length < 5
    const offset = fireOffset ? -40 : 5
    return (
        <>
            <StyledBarMarker
                x1={x + width - (offset + 90)}
                y1={y + height}
                x2={x + width - (offset + 25)}
                y2={y + height}
            />
            <StyledBarMarkerText x={x + width - (offset + 20)} y={y + 5 + height} textAnchor="start">
                {value}
            </StyledBarMarkerText>
        </>
    )
}

export const renderThickCustomizedCurrencyLabel = (props: any) => {
    const { x, y, width, height, value } = props

    const fireOffset = value.toString().length < 5
    const offset = fireOffset ? -40 : 5
    return (
        <>
            <StyledBarMarker
                x1={x + width - (offset + 90)}
                y1={y + height}
                x2={x + width - (offset + 25)}
                y2={y + height}
            />
            <StyledBarMarkerText x={x + width - (offset + 20)} y={y + 5 + height} textAnchor="start">
                {currencyFormatter.format(Number(value))}
            </StyledBarMarkerText>
        </>
    )
}

function findIndexWithMinimumNumberOfCharacters(line: string, index: number, minChars: number) {
    let nextIndex = line.indexOf(' ', index)
    while (nextIndex - index < minChars && nextIndex != -1) {
        nextIndex = line.indexOf(' ', nextIndex + 1)
    }
    if (line.substring(nextIndex).length <= 3) {
        return -1
    }
    return nextIndex
}

const getMinimumNumberOfCharacters = (line: string, index: number, minimumNumberOfCharacters: number = 10): string => {
    if (typeof line !== 'string') return ' '
    let nextIndex = findIndexWithMinimumNumberOfCharacters(line, index, minimumNumberOfCharacters)

    if (nextIndex === -1) return line

    const lineWithAtLeastMinimum: string = line.substring(index, nextIndex)
    return lineWithAtLeastMinimum
}

const getTheRestAfterMinimumNumberOfCharacters = (
    line: string,
    index: number,
    minimumNumberOfCharacters: number = 10
) => {
    if (typeof line !== 'string') return ' '
    let nextIndex = findIndexWithMinimumNumberOfCharacters(line, index, minimumNumberOfCharacters)
    if (nextIndex === -1) return ''
    const lineAllowingMinimumCharacters: string = line.substring(nextIndex).trimStart()
    return lineAllowingMinimumCharacters
}

export const CustomizedAxisTick = (props: any) => {
    const { x, y, stroke, payload } = props

    return (
        <g transform={`translate(${x},${y})`}>
            <StyledAxisText width={50} x={0} y={0} dy={16} textAnchor="left" transform="rotate(0)">
                <tspan x={0} dy={10} dx={-13}>
                    {getMinimumNumberOfCharacters(payload.value, 0, 15)}
                </tspan>
                <tspan x={0} dy={20} dx={-13}>
                    {getTheRestAfterMinimumNumberOfCharacters(payload.value, 0, 15)}
                </tspan>
            </StyledAxisText>
        </g>
    )
}

export const CustomizedWellnessAxisTick = (props: any) => {
    const { x, y, stroke, payload } = props
    return (
        <g transform={`translate(${x},${y})`}>
            <StyledAxisText width={50} x={0} y={0} dy={16} textAnchor="left" transform="rotate(0)">
                <tspan x={0} dy={10} dx={-25}>
                    {payload.value}
                </tspan>
            </StyledAxisText>
        </g>
    )
}

export const CustomizedYAxisTick = (props: any) => {
    const { x, y, stroke, payload } = props

    return (
        <g transform={`translate(${x},${y})`}>
            <StyledYAxisText width={50} x={0} y={0} dx={0} textAnchor="end" transform="rotate(0)">
                <tspan x={0} dy={0} dx={0}>
                    {payload.value}
                </tspan>
            </StyledYAxisText>
        </g>
    )
}

const yAxisFormatter = (currency: string) => {
    if (currency === '0') {
        return '0'
    } else if (Number(currency) < 100000) {
        return currencyFormatter.format(Number(currency))
    } else {
        return yAxisCurrencyFormatter.format(Number(currency))
    }
}

export const CustomizedYAxisCurrencyTick = (props: any) => {
    const { x, y, stroke, payload } = props

    return (
        <g transform={`translate(${x},${y})`}>
            <StyledYAxisText width={50} x={0} y={0} dx={0} textAnchor="end" transform="rotate(0)">
                <tspan x={0} dy={0} dx={0}>
                    {yAxisFormatter(payload.value)}
                </tspan>
            </StyledYAxisText>
        </g>
    )
}

export const CustomizedLabel = (props: any) => {
    const { x, y, stroke, payload } = props

    return (
        <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
            {payload}
        </text>
    )
}
