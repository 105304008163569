export const uploadIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M16.125 8.36634C15.5583 5.49134 13.0333 3.33301 10 3.33301C7.59167 3.33301 5.5 4.69967 4.45833 6.69967C1.95 6.96634 0 9.09134 0 11.6663C0 14.4247 2.24167 16.6663 5 16.6663H15.8333C18.1333 16.6663 20 14.7997 20 12.4997C20 10.2997 18.2917 8.51634 16.125 8.36634ZM11.6667 10.833V14.1663H8.33333V10.833H5.83333L10 6.66634L14.1667 10.833H11.6667Z" fill="#2281C4"/>
</svg>`

export const disabledUploadIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M16.125 8.36634C15.5583 5.49134 13.0333 3.33301 10 3.33301C7.59167 3.33301 5.5 4.69967 4.45833 6.69967C1.95 6.96634 0 9.09134 0 11.6663C0 14.4247 2.24167 16.6663 5 16.6663H15.8333C18.1333 16.6663 20 14.7997 20 12.4997C20 10.2997 18.2917 8.51634 16.125 8.36634ZM11.6667 10.833V14.1663H8.33333V10.833H5.83333L10 6.66634L14.1667 10.833H11.6667Z" fill="#A4B0C0"/>
</svg>`

export const feedbackIcon = `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.3841 14.9944L15.0843 20.2963L12.4785 20.8175L12.9997 18.2117L18.3023 12.9098C18.5777 12.6338 18.9516 12.4788 19.3415 12.4788C19.7314 12.4788 20.1052 12.6338 20.3807 12.9098L20.3869 12.9153C20.9602 13.4903 20.9589 14.421 20.3841 14.9944Z" stroke="#394A64" stroke-width="1.5"/>
<path d="M10.3913 18.7327H6.22201C5.64635 18.7327 5.17969 18.2661 5.17969 17.6904V6.22493C5.17969 5.64928 5.64635 5.18262 6.22201 5.18262H16.6452C17.2208 5.18262 17.6875 5.64928 17.6875 6.22493V10.9154" stroke="#394A64" stroke-width="1.5"/>
<path d="M10.3916 8.30957H14.5609" stroke="#394A64" stroke-width="1.5"/>
<path d="M8.30664 11.4365H14.5605" stroke="#394A64" stroke-width="1.5"/>
<path d="M8.30664 14.5635H13.5182" stroke="#394A64" stroke-width="1.5"/>
</svg>
`

export const disabledFeedbackIcon = `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.3841 14.9944L15.0843 20.2963L12.4785 20.8175L12.9997 18.2117L18.3023 12.9098C18.5777 12.6338 18.9516 12.4788 19.3415 12.4788C19.7314 12.4788 20.1052 12.6338 20.3807 12.9098L20.3869 12.9153C20.9602 13.4903 20.9589 14.421 20.3841 14.9944Z" stroke="#A4B0C0" stroke-width="1.5"/>
<path d="M10.3913 18.7327H6.22201C5.64635 18.7327 5.17969 18.2661 5.17969 17.6904V6.22493C5.17969 5.64928 5.64635 5.18262 6.22201 5.18262H16.6452C17.2208 5.18262 17.6875 5.64928 17.6875 6.22493V10.9154" stroke="#A4B0C0" stroke-width="1.5"/>
<path d="M10.3916 8.30957H14.5609" stroke="#A4B0C0" stroke-width="1.5"/>
<path d="M8.30664 11.4365H14.5605" stroke="#A4B0C0" stroke-width="1.5"/>
<path d="M8.30664 14.5635H13.5182" stroke="#A4B0C0" stroke-width="1.5"/>
</svg>
`

export const feedbackIndicatorIcon = `<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5.5 7.5H4.5V4.5H5.5V7.5ZM5.5 3.5H4.5V2.5H5.5V3.5Z" fill="#089171"/>
</svg>
`

export const disabledFeedbackIndicatorIcon = `<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5.5 7.5H4.5V4.5H5.5V7.5ZM5.5 3.5H4.5V2.5H5.5V3.5Z" fill="#A4B0C0"/>
</svg>
`

export const deleteIcon = `
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.5002 12.5V13.3333H26.6668V15H25.8335V25.8333C25.8335 26.75 25.0835 27.5 24.1668 27.5H15.8335C14.9168 27.5 14.1668 26.75 14.1668 25.8333V15H13.3335V13.3333H17.5002V12.5H22.5002ZM15.8335 25.8333H24.1668V15H15.8335V25.8333ZM17.5002 16.6667H19.1668V24.1667H17.5002V16.6667ZM22.5002 16.6667H20.8335V24.1667H22.5002V16.6667Z" fill="#B11B1B"/>
</svg>
`

export const disabledDeleteIcon = `
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.5002 12.5V13.3333H26.6668V15H25.8335V25.8333C25.8335 26.75 25.0835 27.5 24.1668 27.5H15.8335C14.9168 27.5 14.1668 26.75 14.1668 25.8333V15H13.3335V13.3333H17.5002V12.5H22.5002ZM15.8335 25.8333H24.1668V15H15.8335V25.8333ZM17.5002 16.6667H19.1668V24.1667H17.5002V16.6667ZM22.5002 16.6667H20.8335V24.1667H22.5002V16.6667Z" fill="#A4B0C0"/>
</svg>
`

export const processedIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M7.99707 12.8168L9.63142 15.1362C9.75721 15.3241 9.96602 15.4396 10.192 15.4464C10.418 15.4532 10.6334 15.3503 10.7701 15.1702L16.0021 8.55078" stroke="#0BC196" stroke-width="1.5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.9998 19.5045C16.1445 19.5045 19.5045 16.1445 19.5045 11.9998C19.5045 7.85508 16.1445 4.49512 11.9998 4.49512C7.85508 4.49512 4.49512 7.85508 4.49512 11.9998C4.49512 16.1445 7.85508 19.5045 11.9998 19.5045Z" stroke="#0BC196" stroke-width="1.5"/>
</svg>`

export const pendingIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.615 8.79019C14.4347 7.4533 12.6138 6.87571 10.8787 7.28792C9.21288 7.71035 7.86868 8.93789 7.29714 10.5586C7.27995 10.6059 7.28501 10.6584 7.3109 10.7015C7.3368 10.7446 7.38076 10.7737 7.43056 10.7808L8.68334 10.9355C8.80913 10.9506 8.91553 11.0356 8.95796 11.155C9.00039 11.2744 8.97153 11.4075 8.88347 11.4985L6.56402 13.8954C6.5012 13.9602 6.41479 13.9968 6.32454 13.9968C6.31077 13.9978 6.29694 13.9978 6.28318 13.9968C6.17879 13.9839 6.08657 13.9226 6.03435 13.8313L4.38266 10.9349C4.31999 10.825 4.3249 10.6891 4.39533 10.584C4.46395 10.4786 4.58682 10.4219 4.71153 10.4379L5.40062 10.5246C5.48193 10.5351 5.5587 10.4846 5.5814 10.4059C6.25816 8.0631 8.12932 6.25672 10.4945 5.6629C12.828 5.1093 15.2768 5.88665 16.8638 7.68483C17.169 8.02967 17.1369 8.55666 16.7921 8.8619C16.4472 9.16714 15.9202 9.13503 15.615 8.79019ZM19.6188 13.0502C19.6823 13.1596 19.6784 13.2955 19.6088 13.4011C19.5466 13.4941 19.4419 13.5497 19.33 13.5492C19.3167 13.5501 19.3033 13.5501 19.29 13.5492L18.5842 13.4644C18.5031 13.455 18.427 13.5054 18.4041 13.5838C17.7299 15.9305 15.8567 17.7403 13.4883 18.3335C11.1547 18.8871 8.70589 18.11 7.11836 16.3122C6.91282 16.0903 6.84355 15.7745 6.93735 15.487C7.03115 15.1994 7.2733 14.9852 7.57015 14.9272C7.86699 14.8692 8.17198 14.9765 8.36714 15.2075C9.54759 16.5441 11.3683 17.1219 13.1034 16.7105C14.7695 16.2897 16.1142 15.0625 16.685 13.4418C16.7018 13.3939 16.6962 13.341 16.6697 13.2977C16.6431 13.2543 16.5981 13.2256 16.5476 13.2196L15.3148 13.0722C15.1896 13.0568 15.0838 12.972 15.0414 12.8531C14.9991 12.7342 15.0274 12.6017 15.1147 12.5105L17.4201 10.0997C17.492 10.0227 17.5966 9.98523 17.701 9.99894C17.8055 10.0109 17.8982 10.0715 17.9511 10.1624L19.6188 13.0502Z" fill="#73839C"/>
<mask id="mask0_17361_66178" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="4" y="5" width="16" height="14">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.615 8.79019C14.4347 7.4533 12.6138 6.87571 10.8787 7.28792C9.21288 7.71035 7.86868 8.93789 7.29714 10.5586C7.27995 10.6059 7.28501 10.6584 7.3109 10.7015C7.3368 10.7446 7.38076 10.7737 7.43056 10.7808L8.68334 10.9355C8.80913 10.9506 8.91553 11.0356 8.95796 11.155C9.00039 11.2744 8.97153 11.4075 8.88347 11.4985L6.56402 13.8954C6.5012 13.9602 6.41479 13.9968 6.32454 13.9968C6.31077 13.9978 6.29694 13.9978 6.28318 13.9968C6.17879 13.9839 6.08657 13.9226 6.03435 13.8313L4.38266 10.9349C4.31999 10.825 4.3249 10.6891 4.39533 10.584C4.46395 10.4786 4.58682 10.4219 4.71153 10.4379L5.40062 10.5246C5.48193 10.5351 5.5587 10.4846 5.5814 10.4059C6.25816 8.0631 8.12932 6.25672 10.4945 5.6629C12.828 5.1093 15.2768 5.88665 16.8638 7.68483C17.169 8.02967 17.1369 8.55666 16.7921 8.8619C16.4472 9.16714 15.9202 9.13503 15.615 8.79019ZM19.6188 13.0502C19.6823 13.1596 19.6784 13.2955 19.6088 13.4011C19.5466 13.4941 19.4419 13.5497 19.33 13.5492C19.3167 13.5501 19.3033 13.5501 19.29 13.5492L18.5842 13.4644C18.5031 13.455 18.427 13.5054 18.4041 13.5838C17.7299 15.9305 15.8567 17.7403 13.4883 18.3335C11.1547 18.8871 8.70589 18.11 7.11836 16.3122C6.91282 16.0903 6.84355 15.7745 6.93735 15.487C7.03115 15.1994 7.2733 14.9852 7.57015 14.9272C7.86699 14.8692 8.17198 14.9765 8.36714 15.2075C9.54759 16.5441 11.3683 17.1219 13.1034 16.7105C14.7695 16.2897 16.1142 15.0625 16.685 13.4418C16.7018 13.3939 16.6962 13.341 16.6697 13.2977C16.6431 13.2543 16.5981 13.2256 16.5476 13.2196L15.3148 13.0722C15.1896 13.0568 15.0838 12.972 15.0414 12.8531C14.9991 12.7342 15.0274 12.6017 15.1147 12.5105L17.4201 10.0997C17.492 10.0227 17.5966 9.98523 17.701 9.99894C17.8055 10.0109 17.8982 10.0715 17.9511 10.1624L19.6188 13.0502Z" fill="white"/>
</mask>
<g mask="url(#mask0_17361_66178)">
</g>
</svg>`

export const errorIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M12 14.5012V9.49805" stroke="#B11B1B" stroke-width="1.5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.0812 5.16871C12.8786 4.75638 12.4592 4.49512 11.9998 4.49512C11.5404 4.49512 11.121 4.75638 10.9185 5.16871L4.59919 18.0421C4.44454 18.3567 4.46324 18.7288 4.64864 19.0263C4.83404 19.3238 5.15988 19.5046 5.51043 19.5043H18.4892C18.8397 19.5046 19.1656 19.3238 19.351 19.0263C19.5364 18.7288 19.5551 18.3567 19.4004 18.0421L13.0812 5.16871Z" stroke="#B11B1B" stroke-width="1.5" stroke-linejoin="round"/>
<circle cx="12" cy="16.75" r="1" fill="#B11B1B"/>
</svg>`
