import { FC, useEffect, useState } from 'react'
import { useAppSelector, dispatch } from 'redux/store'

import CustomSvgIcon from 'components/CustomSvgIcon'
import {
    uploadEnabledIcon,
    uploadDisabledIcon,
    deleteIcon,
    deleteDisabledIcon,
    feedbackDisabledIcon,
    feedbackEnabledIcon,
    feedbackHasNewEnabledIcon,
    feedbackHasNewDisabledIcon,
    newIndicator
} from './icons'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import {
    TableWrapper,
    BtnUpload,
    TableHeader,
    TableHeaderItem,
    TableBody,
    TableBodyItem,
    TitleWrapper,
    InfoPanel,
    StyledDocumentsTableButton
} from './style'

import {
    FeedbackState,
    addProviderDocumentFeedback,
    getProviderDocumentFeedback,
    markProviderDocumentFeedbackRead
} from 'redux/slices/feedback'

import Moment from 'react-moment'

import UploadFileModal from 'components/UploadFileModal'
import { hideMainWindowScrollbars } from 'utilities/style'
import ConfirmationBox from 'components/ConfirmationBox'
import { UI_COLORS } from 'theme/constants'
import Spinner from 'components/Spinner'
import { FeedbackModalDialog } from 'pages/hcp/members/information/PointsInCare/ReviewTable/FeedbackDialog'

import {
    deleteProviderDocument,
    downloadProviderDocument,
    getProviderReports,
    providerDocumentRead,
    uploadProviderReport,
    uploadProviderReportRequest
} from 'redux/slices/documents'

import { IProviderDocument, ProviderDocumentByIdRequest } from 'pages/hcp/members/information/DocumentsTab/models'
import { IGetProviderDocumentFeedbackRequest } from 'pages/hcp/users/create/index.models'
import useCurrentUser from 'hooks/useCurrentUser'
import { ERoles, getUserSide } from 'models/enums/role'
import usePostCustomEvent from 'hooks/usePostCustomEvent'
import { customEvents } from 'appInsights/appInsights'
import { UserState, setNewReportCount } from 'redux/slices/user'

interface IProps {
    npi: string
    page: string
    updateReportCount: (npi?: string) => void
    setUserDetailsNewReportCount?: boolean
}

const ProviderReports: FC<IProps> = ({ npi, page, updateReportCount, setUserDetailsNewReportCount = false }) => {
    const headers = ['', 'File Name', 'Uploaded On', 'Uploaded By', 'Feedback', 'Delete']

    const [isDataLoaded, setDataLoaded] = useState(false)
    const [isBusy, setIsBusy] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const [isUploadModalOpen, setUploadModalOpen] = useState(false)
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
    const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false)

    const [isFeedbackLoading, setFeedbackLoading] = useState(false)
    const [isFeedbackSending, setFeedbackSending] = useState(false)

    const [itemToWorkOn, setItemToWorkOn] = useState<IProviderDocument>(null)

    useEffect(() => {
        if (npi?.length > 1) {
            setIsBusy(true)
            setDataLoaded(false)
            dispatch(getProviderReports({ npi: npi }))
                .then(() => {})
                .finally(() => {
                    setDataLoaded(true)
                    setIsBusy(false)
                })
        }
    }, [npi])

    useEffect(() => {
        hideMainWindowScrollbars(isUploadModalOpen || isDeleteModalOpen || isFeedbackModalOpen)
    }, [isUploadModalOpen, isDeleteModalOpen, isFeedbackModalOpen])

    const logDownload = usePostCustomEvent(page, customEvents.ProviderReportDownload)
    const doDownload = (doc: IProviderDocument) => {
        setIsDownloading(true)
        setIsBusy(true)
        logDownload()

        let req: ProviderDocumentByIdRequest = { providerDocumentId: doc.id }
        dispatch(downloadProviderDocument(req))
            .then(() => {
                setIsDownloading(false)
            })
            .finally(() => {
                if (doc.isNew) {
                    updateReportCount(doc.providerNpi)
                    dispatch(providerDocumentRead(doc.id))
                }
                setIsBusy(false)
            })
    }
    const doUploadConfirm = () => {
        setUploadModalOpen(true)
    }

    const logUpload = usePostCustomEvent(page, customEvents.ProviderReportUpload)
    const doUpload = (file: File) => {
        if (file) {
            logUpload()
            setIsUploading(true)
            let req: uploadProviderReportRequest = { file: file, npi: npi }
            dispatch(uploadProviderReport(req)).then(() => {
                setIsUploading(false)
                setUploadModalOpen(false)
            })
        }
    }

    const doDeleteConfirm = (item: IProviderDocument): void => {
        setItemToWorkOn(item)
        setDeleteModalOpen(true)
    }

    const logDelete = usePostCustomEvent(page, customEvents.ProviderReportDelete)
    const doDelete = (docId: string) => {
        setDeleteModalOpen(false)
        if (!isDeleting) {
            setIsDeleting(true)
            setIsBusy(true)
            logDelete()
            dispatch(deleteProviderDocument({ providerDocumentId: docId }))
                .then(() => {
                    setIsDeleting(false)
                })
                .finally(() => {
                    setIsBusy(false)
                })
        }
    }

    const logFeedbackRead = usePostCustomEvent(page, customEvents.ViewFeedback)
    const doFeedbackConfirm = (item: IProviderDocument): void => {
        logFeedbackRead()
        setItemToWorkOn(item)
        setFeedbackModalOpen(true)
        setFeedbackLoading(true)
        const hasNewFeedback: boolean = item.hasNewFeedback
        const req: IGetProviderDocumentFeedbackRequest = { providerDocumentId: item.id }
        dispatch(getProviderDocumentFeedback(req))
            .then(() => {
                setFeedbackLoading(false)
            })
            .then(() => {
                hasNewFeedback && dispatch(markProviderDocumentFeedbackRead(req))
            })
    }

    const logFeedbackAdd = usePostCustomEvent(page, customEvents.SendFeedback)
    const doFeedback = (msg: string): void => {
        setFeedbackSending(true)
        logFeedbackAdd()
        dispatch(
            addProviderDocumentFeedback({
                providerDocumentId: itemToWorkOn.id,
                parentId: null,
                feedbackText: msg
            })
        ).then(() => {
            setFeedbackSending(false)
        })
    }

    const docs = useAppSelector((state) => {
        return npi && npi.length > 0 && state.documents.providerReports[npi]
    })
    const feedbacks = useAppSelector((state: { feedback: FeedbackState }) => {
        return itemToWorkOn && state.feedback.providerDocumentFeedback[itemToWorkOn.id]
    })
    const userId = useAppSelector((state: { user: UserState }) => state.user.details?.id)

    const currentUser = useCurrentUser()
    const isDeletable = (item: IProviderDocument): boolean => {
        return item.uploadedBySide === getUserSide(currentUser.primaryRole)
    }

    const getFeedbackSvgIcon = (isEnabled: boolean, hasNew: boolean) => {
        let svg = ''
        if (isEnabled && !hasNew) svg = feedbackEnabledIcon
        else if (isEnabled && hasNew) svg = feedbackHasNewEnabledIcon
        else if (!isEnabled && !hasNew) svg = feedbackDisabledIcon
        else if (!isEnabled && hasNew) svg = feedbackHasNewDisabledIcon
        return svg
    }

    useEffect(() => {
        if (setUserDetailsNewReportCount && userId && isDataLoaded && docs) {
            dispatch(
                setNewReportCount({
                    userId: userId,
                    newReportCount: docs.filter((x) => x.isNew).length
                })
            )
        }
    }, [setUserDetailsNewReportCount, userId, isDataLoaded, docs])

    const isReadOnly = currentUser.primaryRole === ERoles.Quality

    return (
        <>
            {!isDataLoaded && <Spinner />}
            {isUploadModalOpen && (
                <UploadFileModal
                    title={'Upload Provider Report'}
                    isUploading={isUploading}
                    onUpload={doUpload}
                    onCancel={() => setUploadModalOpen(false)}
                />
            )}
            {isDeleteModalOpen && (
                <ConfirmationBox
                    title={'Delete File'}
                    body={`Are you sure you want to delete the file '${itemToWorkOn.fileName}' ?`}
                    confirmButtonLabel="Delete"
                    confirmButtonColor={UI_COLORS.red}
                    onConfirm={() => doDelete(itemToWorkOn.id)}
                    onCancel={() => setDeleteModalOpen(false)}
                />
            )}
            {isFeedbackModalOpen && (
                <FeedbackModalDialog
                    feedback={feedbacks ?? []}
                    isLoading={isFeedbackLoading}
                    isSending={isFeedbackSending}
                    onSend={doFeedback}
                    onClose={() => setFeedbackModalOpen(false)}
                />
            )}

            <TableWrapper>
                <TitleWrapper>
                    <Typography type={TYPOGRAPHY_TYPES.span}> </Typography>
                    <BtnUpload disabled={isReadOnly || isBusy} onClick={doUploadConfirm}>
                        <CustomSvgIcon
                            iconSet={{
                                icon: isReadOnly || isBusy ? uploadDisabledIcon : uploadEnabledIcon
                            }}
                            svg
                        ></CustomSvgIcon>
                        <Typography type={TYPOGRAPHY_TYPES.p}>Upload</Typography>
                    </BtnUpload>
                </TitleWrapper>
                {docs && docs.length > 0 ? (
                    <>
                        <TableHeader>
                            {headers.map((headerItem: string, idx: number) => {
                                return <TableHeaderItem key={`headerItem-${idx}`}>{headerItem}</TableHeaderItem>
                            })}
                        </TableHeader>
                        {docs.map((dataItem: IProviderDocument, idx: number) => {
                            return (
                                <TableBody key={`documentsRow=${idx}`}>
                                    <TableBodyItem key={`isNew-${idx}`}>
                                        <CustomSvgIcon
                                            iconSet={{ icon: dataItem.isNew ? newIndicator : null }}
                                            svg
                                        ></CustomSvgIcon>
                                    </TableBodyItem>
                                    <TableBodyItem key={`bodyFileName-${idx}`}>
                                        <button disabled={isDownloading || isBusy} onClick={() => doDownload(dataItem)}>
                                            {dataItem.fileName}
                                        </button>
                                    </TableBodyItem>
                                    <TableBodyItem key={`bodyDOS-${idx}`}>
                                        <Moment format="MM/DD/YYYY">{dataItem?.dateOfSubmission}</Moment>
                                    </TableBodyItem>
                                    <TableBodyItem key={`bodyUploadedBy-${idx}`}>{dataItem.uploadedBy}</TableBodyItem>
                                    <TableBodyItem key={`bodyFeedBack-${idx}`}>
                                        <StyledDocumentsTableButton
                                            onClick={() => doFeedbackConfirm(dataItem)}
                                            disabled={isBusy}
                                        >
                                            <CustomSvgIcon
                                                iconSet={{ icon: getFeedbackSvgIcon(!isBusy, dataItem.hasNewFeedback) }}
                                                svg
                                            ></CustomSvgIcon>
                                        </StyledDocumentsTableButton>
                                    </TableBodyItem>
                                    <TableBodyItem key={`bodyDelete-${idx}`}>
                                        {isDeletable(dataItem) && (
                                            <StyledDocumentsTableButton
                                                onClick={() => doDeleteConfirm(dataItem)}
                                                disabled={isReadOnly || isDeleting || isBusy}
                                            >
                                                <CustomSvgIcon
                                                    iconSet={{
                                                        icon:
                                                            isReadOnly || isDeleting || isBusy
                                                                ? deleteDisabledIcon
                                                                : deleteIcon
                                                    }}
                                                    svg
                                                ></CustomSvgIcon>
                                            </StyledDocumentsTableButton>
                                        )}
                                    </TableBodyItem>
                                </TableBody>
                            )
                        })}
                    </>
                ) : (
                    <InfoPanel>
                        <Typography type={TYPOGRAPHY_TYPES.p}>No reports have been uploaded.</Typography>
                    </InfoPanel>
                )}
            </TableWrapper>
        </>
    )
}
export default ProviderReports
