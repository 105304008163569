import DropDownList from 'components/DropDownList'
import { FC } from 'react'
import { IProviderNpi } from 'redux/slices/pulse8'
import { npiDropDownListStyleParams } from './index.style'

interface INpiSelectProps {
    providerNpis: IProviderNpi[]
    selectedNpi: string
    setSelectedNpi: (value: string) => void
}

const NpiSelect: FC<INpiSelectProps> = ({ providerNpis, selectedNpi, setSelectedNpi }) => {
    const getLabel = (npi: IProviderNpi): string => {
        return `${npi.lastName}, ${npi.firstName} -  ${npi.npi}`
    }

    const changeNpi = (npi: string) => {
        if (selectedNpi !== npi) {
            setSelectedNpi(npi)
        }
    }

    return (
        <div>
            <DropDownList
                options={providerNpis.map((npi) => ({
                    value: npi.npi,
                    label: getLabel(npi)
                }))}
                selectedValue={selectedNpi}
                setSelectedValue={changeNpi}
                styleParams={npiDropDownListStyleParams}
            />
        </div>
    )
}

export default NpiSelect
