import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { processError } from './processError'

interface IError {
    exception: any
    setRejectionValue: (rejectionValue: any) => void
    setShowModal: (showModal: boolean) => void
    setModalErrorMessage: (modalErrorMessage: string) => void
}

const createError = (exception: any) => {
    let rejectionValue: any = exception?.response?.data ?? exception
    let showModal = true
    let modalErrorMessage: string | null = exception.response?.data?.Message ?? null

    return {
        getRejectionValue: () => rejectionValue,
        getShowModal: () => showModal,
        getModalErrorMessage: () => modalErrorMessage,

        exception,
        setRejectionValue: (value: any) => {
            rejectionValue = value
        },
        setShowModal: (value: boolean) => {
            showModal = value
        },
        setModalErrorMessage: (value: string) => {
            modalErrorMessage = value
        }
    }
}

export const createExceptionAwareAsyncThunk = <Returned, ThunkArg = void>(
    typePrefix: string,
    callApi: (arg: ThunkArg) => Promise<Returned>,
    handleError: (error?: IError) => void = () => {}
): AsyncThunk<Returned, ThunkArg, {}> =>
    createAsyncThunk(typePrefix, async (arg: ThunkArg, { rejectWithValue }) => {
        try {
            return await callApi(arg)
        } catch (exception: any) {
            const error = createError(exception)
            handleError(error)
            processError(exception, {
                showModal: error.getShowModal(),
                modalErrorMessage: error.getModalErrorMessage()
            })
            return rejectWithValue(error.getRejectionValue())
        }
    })
