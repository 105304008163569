export const arrow = `<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.67249 10.1328L12.5313 15.1328L17.3902 10.1328H7.67249Z" fill="#293854"/>
</svg>
`

export const mirrorIcon = `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.5029 6.49797V4.49672C14.5029 3.94409 14.0549 3.49609 13.5023 3.49609H10.834" stroke="#6F43C4" stroke-width="1.5"/>
<path d="M5.16408 3.49609H2.49574C1.94311 3.49609 1.49512 3.94409 1.49512 4.49672V15.5036C1.49512 16.0562 1.94311 16.5042 2.49574 16.5042H6.49824" stroke="#6F43C4" stroke-width="1.5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.6147 4.15429C10.5464 4.35862 10.3551 4.49642 10.1397 4.49651H5.85838C5.64294 4.49642 5.45169 4.35862 5.38341 4.15429L4.71633 2.15304C4.66518 2.00042 4.69069 1.8325 4.78486 1.70196C4.87903 1.57142 5.03033 1.49424 5.19129 1.49463H10.8068C10.9678 1.49424 11.1191 1.57142 11.2132 1.70196C11.3074 1.8325 11.3329 2.00042 11.2818 2.15304L10.6147 4.15429Z" stroke="#6F43C4" stroke-width="1.5"/>
<rect x="8.49902" y="8.49902" width="8.005" height="8.005" rx="1.5" stroke="#6F43C4" stroke-width="1.5"/>
<path d="M10.501 10.5005H14.5035" stroke="#6F43C4" stroke-width="1.5"/>
<path d="M10.501 12.5015H14.5035" stroke="#6F43C4" stroke-width="1.5"/>
<path d="M10.501 14.5029H12.0019" stroke="#6F43C4" stroke-width="1.5"/>
</svg>
`

export const emailAction = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4.49532" y="6.99707" width="15.0094" height="10.0063" rx="1.5" stroke="#394A64" stroke-width="1.5"/>
<path d="M14.4595 10.6494L17.0031 13.0009" stroke="#394A64" stroke-width="1.5"/>
<path d="M9.54046 10.6494L6.99687 13.0009" stroke="#394A64" stroke-width="1.5"/>
<path d="M19.2579 7.33984L12.9119 11.7159C12.363 12.0949 11.637 12.0949 11.0881 11.7159L4.74214 7.33984" stroke="#394A64" stroke-width="1.5"/>
</svg>
`

export const warningIcon = `<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5.5 7.5H4.5V6.5H5.5V7.5ZM5.5 5.5H4.5V2.5H5.5V5.5Z" fill="#DF3030"/>
</svg>
`
export const reportIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M11.4995 13.5083H15.0017" stroke="#394A64" stroke-width="1.5"/>
<path d="M11.4995 16.5101H15.0017" stroke="#394A64" stroke-width="1.5"/>
<path d="M9.4982 13.2582C9.63636 13.2582 9.74836 13.3702 9.74836 13.5083C9.74836 13.6465 9.63636 13.7585 9.4982 13.7585C9.36005 13.7585 9.24805 13.6465 9.24805 13.5083C9.24805 13.3702 9.36005 13.2582 9.4982 13.2582" stroke="#394A64" stroke-width="1.5"/>
<path d="M11.4995 10.5065H15.0017" stroke="#394A64" stroke-width="1.5"/>
<path d="M9.4982 10.2562C9.63636 10.2562 9.74836 10.3682 9.74836 10.5064C9.74836 10.6445 9.63636 10.7565 9.4982 10.7565C9.36005 10.7565 9.24805 10.6445 9.24805 10.5064C9.24805 10.3682 9.36005 10.2562 9.4982 10.2562" stroke="#394A64" stroke-width="1.5"/>
<path d="M9.4982 16.26C9.63636 16.26 9.74836 16.372 9.74836 16.5102C9.74836 16.6483 9.63636 16.7603 9.4982 16.7603C9.36005 16.7603 9.24805 16.6483 9.24805 16.5102C9.24805 16.372 9.36005 16.26 9.4982 16.26" stroke="#394A64" stroke-width="1.5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.5014 7.00425H17.003C17.5556 7.00425 18.0036 7.45224 18.0036 8.00487V18.5114C18.0036 19.0641 17.5556 19.5121 17.003 19.5121H6.99672C6.44409 19.5121 5.99609 19.0641 5.99609 18.5114V8.00487C5.99609 7.45224 6.44409 7.00425 6.99672 7.00425H9.49828C9.49828 5.62267 10.6183 4.50269 11.9998 4.50269C13.3814 4.50269 14.5014 5.62267 14.5014 7.00425Z" stroke="#394A64" stroke-width="1.5"/>
<path d="M11.9997 6.50391C12.1378 6.50391 12.2498 6.61591 12.2498 6.75406C12.2498 6.89222 12.1378 7.00422 11.9997 7.00422C11.8615 7.00422 11.7495 6.89222 11.7495 6.75406C11.7495 6.61591 11.8615 6.50391 11.9997 6.50391" stroke="#394A64" stroke-width="1.5"/>
</svg>`
export const reportNewIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<circle cx="20" cy="5" r="4.5" fill="#089171" stroke="white"/>
<path d="M11.4995 13.5083H15.0017" stroke="#394A64" stroke-width="1.5"/>
<path d="M11.4995 16.5101H15.0017" stroke="#394A64" stroke-width="1.5"/>
<path d="M9.4982 13.2582C9.63636 13.2582 9.74836 13.3702 9.74836 13.5083C9.74836 13.6465 9.63636 13.7585 9.4982 13.7585C9.36005 13.7585 9.24805 13.6465 9.24805 13.5083C9.24805 13.3702 9.36005 13.2582 9.4982 13.2582" stroke="#394A64" stroke-width="1.5"/>
<path d="M11.4995 10.5065H15.0017" stroke="#394A64" stroke-width="1.5"/>
<path d="M9.4982 10.2562C9.63636 10.2562 9.74836 10.3682 9.74836 10.5064C9.74836 10.6445 9.63636 10.7565 9.4982 10.7565C9.36005 10.7565 9.24805 10.6445 9.24805 10.5064C9.24805 10.3682 9.36005 10.2562 9.4982 10.2562" stroke="#394A64" stroke-width="1.5"/>
<path d="M9.4982 16.26C9.63636 16.26 9.74836 16.372 9.74836 16.5102C9.74836 16.6483 9.63636 16.7603 9.4982 16.7603C9.36005 16.7603 9.24805 16.6483 9.24805 16.5102C9.24805 16.372 9.36005 16.26 9.4982 16.26" stroke="#394A64" stroke-width="1.5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.5014 7.00425H17.003C17.5556 7.00425 18.0036 7.45224 18.0036 8.00487V18.5114C18.0036 19.0641 17.5556 19.5121 17.003 19.5121H6.99672C6.44409 19.5121 5.99609 19.0641 5.99609 18.5114V8.00487C5.99609 7.45224 6.44409 7.00425 6.99672 7.00425H9.49828C9.49828 5.62267 10.6183 4.50269 11.9998 4.50269C13.3814 4.50269 14.5014 5.62267 14.5014 7.00425Z" stroke="#394A64" stroke-width="1.5"/>
<path d="M11.9997 6.50391C12.1378 6.50391 12.2498 6.61591 12.2498 6.75406C12.2498 6.89222 12.1378 7.00422 11.9997 7.00422C11.8615 7.00422 11.7495 6.89222 11.7495 6.75406C11.7495 6.61591 11.8615 6.50391 11.9997 6.50391" stroke="#394A64" stroke-width="1.5"/>
</svg>`
