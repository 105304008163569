import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { light_gray2, light_gray3, light_gray4, dark_gray, light_blue2, light_blue3 } = UI_COLORS

const tableReset = css`
    list-style: none;
    padding: 0;
    display: flex;
`

export const StyledTableContainer = styled.article`
    width: ${pxToRem(1145)};

    /* height: ${pxToRem(715)}; */
    height: auto;

    background-color: white;
    margin-bottom: ${pxToRem(30)};
`

export const StyledTableHeaderRow = styled.ul`
    ${tableReset}
    background-color: ${light_blue3};
    padding-left: ${pxToRem(15)};
    height: ${pxToRem(40)};
    border-bottom: 1px solid ${light_blue3};

    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 0;
    margin-bottom: 0;
`

export const StyledTableHeaderItem = styled.li<{ active?: boolean; isAction?: boolean }>`
    margin-right: ${pxToRem(15)};
    color: ${light_gray3};

    font-family: 'Inter', sans-serif;
    font-style: normal;

    font-size: 12px;
    font-weight: 600;
    line-height: 16px;

    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    &:nth-child(1) {
        width: 25px;
    }

    &:nth-child(2) {
        width: 190px;
    }

    &:nth-child(3) {
        width: 105px;
    }

    &:nth-child(4) {
        width: 100px;
    }

    &:nth-child(5) {
        width: 135px;
    }

    &:nth-child(6) {
        width: 135px;
    }

    &:nth-child(7) {
        width: 120px;
    }

    &:nth-child(8) {
        width: 120px;
    }

    &:nth-child(9) {
        width: 60px;
    }

    :hover {
        cursor: pointer;
    }

    ${(props) => {
        if (props.isAction) {
            return css`
                &:hover {
                    cursor: pointer;
                }
            `
        } else {
            return css`
                &:hover {
                    cursor: default;
                }
            `
        }
    }}

    figure {
        margin: 0;
        /* position: absolute; */
        /* top: 50%; */
        /* transform: translateY(-50%); */
        /* right: 10px; */

        ${(props) => {
            if (props.active) {
                return css`
                    transform: rotate(180deg) translateY(10%);
                `
            }
        }}
    }
`

export const StyledTableBodyRowWrap = styled.article`
    display: flex;
    border: 1px solid ${light_gray4};
    border-top: 0;
    border-left: 0;
    border-right: 0;

    :hover {
        background-color: ${light_gray2};
        cursor: pointer;
    }

    article {
        &:nth-child(2) {
            display: flex;
            align-items: center;
        }
    }
`

export const StyledTableBodyRow = styled.ul`
    ${tableReset}

    height: 75px;

    padding-left: ${pxToRem(15)};
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
`

export const StyledTableBodyItem = styled.li`
    margin-right: ${pxToRem(15)};
    color: ${dark_gray};

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(12)};
    line-height: 16px;

    &:nth-child(1) {
        width: 25px;
    }

    &:nth-child(2) {
        width: 190px;
    }

    &:nth-child(3) {
        width: 105px;
    }

    &:nth-child(4) {
        width: 100px;
    }

    &:nth-child(5) {
        width: 135px;
    }

    &:nth-child(6) {
        width: 135px;
    }

    &:nth-child(7) {
        width: 120px;
    }

    &:nth-child(8) {
        width: 120px;
    }

    &:nth-child(9) {
        width: 60px;
        margin: 0 !important;
    }
`

export const StyledTableBodyDetailsRow = styled.ul`
    ${tableReset}

    height: 60px;

    padding-left: ${pxToRem(15)};
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
`

export const StyledTableBodyDetailsItem = styled.li`
    margin-right: ${pxToRem(15)};
    color: ${dark_gray};

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(12)};
    line-height: 16px;

    &:nth-child(1) {
        width: 25px;
    }

    &:nth-child(2) {
        width: 425px;
    }

    &:nth-child(3) {
        width: 135px;
    }

    &:nth-child(4) {
        width: 270px;
    }

    &:nth-child(5) {
        width: 150px;
    }
`

export const StyledTableBodyFeedbackRow = styled.ul`
    ${tableReset}

    margin-bottom: ${pxToRem(30)} !important;

    padding-left: ${pxToRem(15)};
    padding-top: ${pxToRem(19)};
    padding-bottom: ${pxToRem(19)};
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
`

export const StyledTableBodyFeedbackItem = styled.li`
    margin-right: ${pxToRem(15)};
    color: ${dark_gray};

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(12)};
    line-height: 16px;

    &:nth-child(1) {
        width: 25px;
    }

    &:nth-child(2) {
        width: 1005px;
    }
`

export const StyledTableBodyFeedbackItemArticle = styled.article`
    &:nth-child(2) {
        font-weight: 500;
        padding-top: ${pxToRem(5)};
    }
`

export const StyledActionsContainer = styled.article`
    ${tableReset}

    width: 48px;
    height: 100%;
    display: flex;
    align-items: center;
    vertical-align: middle;
    margin: 0 !important;

    figure {
        margin: 0;
    }
`

export const StyledTableButton = styled.button<{ image?: string; size: string; hasFeedback?: boolean }>`
    background-color: transparent;
    border: 0;

    background-repeat: no-repeat;
    background-size: cover;

    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    :hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
        border-radius: 20px;
    }

    ${(props) => {
        if (props.image) {
            return css`
                background-image: url(${props.image});
            `
        }
    }}

    ${(props) => {
        if (props.size) {
            return css`
                width: ${props.size}px;
                height: ${props.size}px;
            `
        }
    }}

    div {
        display: none;
    }

    ${(props) => {
        if (props.hasFeedback) {
            return css`
                div {
                    display: block;
                    position: absolute;
                    top: 7px;
                    right: 12px;
                }
            `
        }
    }}
`

export const StyledSpinnerContainer = styled.article`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const StyledSpinner = styled.svg`
    animation: rotate 2s linear infinite;
    margin: ${pxToRem(2)};

    & .path {
        stroke: ${light_blue2};
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
`
