import styled, { css } from 'styled-components'
import { pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { white } = UI_COLORS

const textColor = white
const bgColor = `#394a64`
const tooltipMargin = `30px`
const arrowSize = '6px'

export const StyledTooltipWrap = styled.div`
    display: inline-block;
    position: relative;
`

export const StyledTooltipContainer = styled.div<{ position: string }>`
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px 10px;
    color: ${textColor};
    background: ${bgColor};
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    line-height: 1;
    z-index: 100;
    white-space: nowrap;

    ::before {
        content: ' ';
        left: 50%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-width: ${arrowSize};
        margin-left: calc(${arrowSize} * -1);
    }

    ${(props) => {
        if (props.position === 'top') {
            return css`
                top: calc(${tooltipMargin} * -1);
                ::before {
                    top: 100%;
                    border-top-color: ${bgColor};
                }
            `
        }
    }}

    ${(props) => {
        if (props.position === 'right') {
            return css`
                left: calc(100% + ${tooltipMargin});
                top: 50%;
                transform: translateX(0) translateY(-50%);
                ::before {
                    left: calc(${arrowSize} * -1);
                    top: 50%;
                    transform: translateX(0) translateY(-50%);
                    border-right-color: ${bgColor};
                }
            `
        }
    }}

${(props) => {
        if (props.position === 'bottom') {
            return css`
                bottom: calc(${tooltipMargin} * -1);
                ::before {
                    bottom: 100%;
                    border-bottom-color: ${bgColor};
                }
            `
        }
    }}

${(props) => {
        if (props.position === 'left') {
            return css`
                left: auto;
                right: calc(100% + ${tooltipMargin});
                top: 50%;
                transform: translateX(0) translateY(-50%);
                ::before {
                    left: auto;
                    right: calc(${arrowSize} * -2);
                    top: 50%;
                    transform: translateX(0) translateY(-50%);
                    border-left-color: ${bgColor};
                }
            `
        }
    }}
`
