import styled, { css } from 'styled-components'
import { DEVICE, pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { light_gray, white, light_gray2, dark_gray, red } = UI_COLORS

export const StyledDropdownWrap = styled.article`
    position: relative;
    margin-bottom: ${pxToRem(15)};

    p {
        margin: 0;
        color: ${red};

        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(20)};
    }
`

export const StyledSelectLabel = styled.label`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
    display: block;
    margin-bottom: ${pxToRem(12)};
    color: ${dark_gray};
`

export const StyledStateSelectButton = styled.button<{ error?: boolean }>`
    width: 100%;
    height: 50px;
    border: 1px solid #dee5ef;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 16px;

    text-align: left;
    background-color: ${white};

    font-family: 'Inter', 'sans-serif';
    color: ${light_gray};

    position: relative;

    display: flex;
    align-items: center;

    figure {
        margin: 0;
        position: absolute;
        right: ${pxToRem(15)};
        top: 50%;
        transform: translateY(-50%);
    }

    ${(props) => {
        if (props.error) {
            return css`
                border: 1px solid ${red};
            `
        }
    }};
`

export const StyledStateSelectInput = styled.input`
    height: 3.125rem;
    width: 100%;
    padding-left: 1rem;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #293854;
    border: 1px solid #dee5ef;
    border-radius: 0.25rem;
`

export const StyledSelectButtonMenu = styled.menu`
    max-height: 250px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 12;
    overflow-y: auto;
    padding: 0;

    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    background-color: ${white};

    > button {
        display: block;
        width: 100%;
        border: 0;
        padding: 19px 18px;
        background-color: ${white};
        text-align: left;
        font-family: 'Inter', 'sans-serif';

        :hover {
            background-color: ${light_gray2};
        }
    }
`

export const StyledSelectButtonMenuWrap = styled.article`
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: transparent;
`
