import CustomSvgIcon from 'components/CustomSvgIcon'
import MemberOutreachTable from 'components/MemberOutreachTable'
import PaginationController from 'components/PaginationController'
import SearchTextField from 'components/SearchTextField'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'redux/store'
import {
    deleteMemberOutreachDetails,
    fetchMemberOutreach,
    fetchMemberOutreachDetails,
    fetchYearsForNpi,
    IncentiveState
} from 'redux/slices/incentive'
import { backArrow, noData } from './icons'
import {
    StyledActionsContainer,
    StyledBackButton,
    StyledButtonsContainer,
    StyledEmptyState,
    StyledHeader,
    StyledMemberOutreachContainerWrap,
    StyledSearchBarContainer,
    StyledTableWrap
} from './index.style'
import { SortDirection } from 'models/sortColumn'
import useDebounce from 'hooks/useDebounce'
import { useNavigate } from 'react-router-dom'
import { appPath } from 'utilities/appPath'
import { fetchProviderNpis, Pulse8State, setCurrentNpi, setCurrentNpiYear } from 'redux/slices/pulse8'
import { ERoles } from 'models/enums/role'
import { IMemberOutreach } from 'pages/hcp/users/create/index.models'
import { hideMainWindowScrollbars } from 'utilities'
import { useMemberOutreachListConfig } from 'hooks/useListConfig'
import { getMemberOutreachSortColumn } from 'utilities/memberOutreachSortColumnMapper'
import NpiSelect from 'components/NpiSelect'
import useCurrentUser from 'hooks/useCurrentUser'
import { StyledCalendarDropDownContainer, StyledCalendarIconForIncentives } from '../dashboard/YTDWidget/index.style'
import smallCalendar from 'assets/smallCalendar.svg'
import DropDownList from 'components/DropDownList'
import { calendarIncentivesDropDownListStyleParams } from 'components/TaxIdProviderInfo/index.style'
import { FeedbackModalDialog } from 'pages/hcp/members/information/PointsInCare/ReviewTable/FeedbackDialog'
import {
    FeedbackState,
    addMemberFeedback,
    getMemberFeedback,
    getMemberFeedbackKey,
    markMemberFeedbackRead
} from 'redux/slices/feedback'
import Spinner from 'components/Spinner'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import usePostCustomEvent from 'hooks/usePostCustomEvent'

interface IMembersProps {}

const MemberOutreach: FC<IMembersProps> = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [initialContentLoaded, setInitialContentLoaded] = useState(false)
    const listConfig = useMemberOutreachListConfig()
    const debouncedFilter = useDebounce(listConfig.effectiveFilter, 500)
    const [yearsDropDownData, setYearsDropDownData] = useState<{ label: string; value: number }[]>([])
    const { years } = useSelector((state: { incentive: IncentiveState }) => state.incentive)

    const currentUser = useCurrentUser()

    const { currentNpi, currentNpiYears, providerNpis, providerNpisLoaded } = useSelector(
        (state: { pulse8: Pulse8State }) => state.pulse8
    )

    const yearsOfService = (currentNpi && years[currentNpi] ? years[currentNpi] : null) as number[]
    const selectedYear = (currentNpi && currentNpiYears[currentNpi] ? currentNpiYears[currentNpi] : null) as number

    const {
        memberOutreaches,
        memberOutreachesLoading,
        totalNumberOfMemberOutreaches,
        filteredNumberOfMemberOutreaches,
        memberOutreachDetails,
        memberOutreachDetailsLoading
    } = useSelector((state: { incentive: IncentiveState }) => state.incentive)

    useEffect(() => {
        if (!providerNpisLoaded) {
            dispatch(fetchProviderNpis())
        }
    }, [providerNpisLoaded, dispatch])

    useEffect(() => {
        if (currentNpi && !yearsOfService) {
            dispatch(fetchYearsForNpi({ npi: currentNpi }))
        }
    }, [currentNpi, yearsOfService])

    useEffect(() => {
        if (currentNpi) {
            dispatch(
                fetchMemberOutreach({
                    npi: currentNpi,
                    pageNumber: listConfig.pageNumber,
                    size: 10,
                    sortColumn: listConfig.sortColumn,
                    filter: debouncedFilter,
                    isDescending: listConfig.sortDirection === SortDirection.descending ? true : false,
                    year: selectedYear
                })
            ).then(() => {
                setInitialContentLoaded(true)
            })
        }
    }, [
        dispatch,
        currentNpi,
        debouncedFilter,
        listConfig.pageNumber,
        listConfig.sortColumn,
        listConfig.sortDirection,
        selectedYear
    ])

    useEffect(() => {
        if (currentNpi && yearsOfService?.length > 0) {
            setYearsDropDownData(yearsOfService.map((year) => ({ label: year.toString(), value: year })))
            if (!selectedYear || !yearsOfService.includes(selectedYear)) {
                dispatch(
                    setCurrentNpiYear({
                        npi: currentNpi,
                        year: Math.max(...yearsOfService)
                    })
                )
            }
        }
    }, [currentNpi, yearsOfService, selectedYear])

    const backToIncentives = () => {
        navigate(appPath('/dashboard'))
    }

    const setNpi = (newNpi: string) => {
        if (currentNpi && newNpi && currentNpi !== newNpi) {
            setInitialContentLoaded(false)
            listConfig.setPageNumber(1)
            listConfig.setFilter('')
            dispatch(setCurrentNpi(newNpi))
        }
    }

    const showMemberOutreachDetails = (memberId: string, sortColumn: string, sortDirection: boolean) => {
        dispatch(
            fetchMemberOutreachDetails({
                npi: currentNpi,
                memberId,
                sortColumn: getMemberOutreachSortColumn(sortColumn),
                isDescending: listConfig.sortDirection === SortDirection.descending
            })
        )
    }

    const hideMemberOutreachDetails = (memberId: string) => {
        dispatch(deleteMemberOutreachDetails(memberId))
    }

    const [feedbackMembid, setFeedbackMembid] = useState('')
    const [feedbackNpi, setFeedbackNpi] = useState('')
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)
    const [feedbackLoading, setFeedbackLoading] = useState(false)
    const [feedbackSending, setFeedbackSending] = useState(false)

    const { memberFeedback } = useSelector((state: { feedback: FeedbackState }) => state.feedback)

    const viewFeedbackEvent = usePostCustomEvent(pageTitle.PatientOutreach, customEvents.ViewFeedback)
    const leaveFeedback = (memberOutreach: IMemberOutreach) => {
        viewFeedbackEvent()
        setFeedbackMembid(memberOutreach.membid)
        setFeedbackNpi(memberOutreach.npi)
        setFeedbackLoading(true)
        setIsFeedbackModalOpen(true)
        dispatch(
            markMemberFeedbackRead({
                membid: memberOutreach.membid,
                npi: memberOutreach.npi
            })
        )
        dispatch(
            getMemberFeedback({
                membid: memberOutreach.membid,
                npi: memberOutreach.npi
            })
        ).then(() => {
            setFeedbackLoading(false)
        })
    }
    const sentFeedbackEvent = usePostCustomEvent(pageTitle.PatientOutreach, customEvents.SendFeedback)
    const saveFeedback = (feedbackToSend: string) => {
        sentFeedbackEvent()
        setFeedbackSending(true)
        dispatch(
            addMemberFeedback({
                membid: feedbackMembid,
                npi: feedbackNpi,
                parentId: null,
                feedbackText: feedbackToSend
            })
        ).then(() => {
            setFeedbackSending(false)
        })
    }

    useEffect(() => {
        hideMainWindowScrollbars(isFeedbackModalOpen)
    }, [isFeedbackModalOpen])

    return yearsOfService ? (
        <>
            {isFeedbackModalOpen ? (
                <FeedbackModalDialog
                    feedback={memberFeedback[getMemberFeedbackKey(feedbackMembid, feedbackNpi)]}
                    isLoading={feedbackLoading}
                    isSending={feedbackSending}
                    onSend={saveFeedback}
                    onClose={() => setIsFeedbackModalOpen(false)}
                />
            ) : null}

            <StyledMemberOutreachContainerWrap>
                <StyledButtonsContainer>
                    <StyledBackButton onClick={backToIncentives}>
                        <CustomSvgIcon iconSet={{ icon: backArrow }} svg></CustomSvgIcon>Back To Incentives
                    </StyledBackButton>
                </StyledButtonsContainer>

                <StyledHeader>
                    <Typography type={TYPOGRAPHY_TYPES.p}>Patient Outreach</Typography>
                    <StyledCalendarDropDownContainer>
                        <StyledCalendarIconForIncentives image={smallCalendar} />
                        <DropDownList
                            key="year-key"
                            placeholder="Year"
                            options={yearsDropDownData}
                            selectedValue={selectedYear}
                            setSelectedValue={(value) => dispatch(setCurrentNpiYear({ npi: currentNpi, year: value }))}
                            styleParams={calendarIncentivesDropDownListStyleParams}
                        />
                    </StyledCalendarDropDownContainer>
                    {currentUser.primaryRole === ERoles.OfficeAdmin && providerNpis.length > 0 && (
                        <div>
                            <NpiSelect providerNpis={providerNpis} selectedNpi={currentNpi} setSelectedNpi={setNpi} />
                        </div>
                    )}
                </StyledHeader>

                {(initialContentLoaded || (providerNpisLoaded && providerNpis.length === 0)) && (
                    <>
                        {totalNumberOfMemberOutreaches > 0 && (
                            <StyledActionsContainer>
                                <StyledSearchBarContainer>
                                    <SearchTextField
                                        placeholder={'Search'}
                                        value={listConfig.filter}
                                        loading={memberOutreachesLoading}
                                        onValueChanged={listConfig.setFilter}
                                        onClearSearch={listConfig.clearFilter}
                                    />
                                </StyledSearchBarContainer>
                            </StyledActionsContainer>
                        )}

                        {memberOutreaches.length > 0 && (
                            <StyledTableWrap>
                                <MemberOutreachTable
                                    memberOutreaches={memberOutreaches}
                                    memberOutreachDetails={memberOutreachDetails}
                                    memberOutreachDetailsLoading={memberOutreachDetailsLoading}
                                    showMemberOutreachDetails={showMemberOutreachDetails}
                                    hideMemberOutreachDetails={hideMemberOutreachDetails}
                                    sortColumn={listConfig.sortColumn}
                                    sortDirection={listConfig.sortDirection}
                                    sortCallback={listConfig.setSorting}
                                    leaveFeedbackCallback={leaveFeedback}
                                ></MemberOutreachTable>
                            </StyledTableWrap>
                        )}

                        {memberOutreaches && filteredNumberOfMemberOutreaches > 0 && (
                            <PaginationController
                                totalItems={filteredNumberOfMemberOutreaches}
                                selectedPage={listConfig.pageNumber}
                                selectedPageChanged={listConfig.setPageNumber}
                                singularRecordName="Member"
                                pluralRecordName="Members"
                            />
                        )}

                        {totalNumberOfMemberOutreaches === 0 && (
                            <StyledEmptyState>
                                <CustomSvgIcon iconSet={{ icon: noData }} svg></CustomSvgIcon>
                                <Typography type={TYPOGRAPHY_TYPES.h4}>Data not available at the moment</Typography>
                                <Typography type={TYPOGRAPHY_TYPES.p}>Please check back later</Typography>
                            </StyledEmptyState>
                        )}

                        {totalNumberOfMemberOutreaches > 0 && filteredNumberOfMemberOutreaches === 0 && (
                            <StyledEmptyState>
                                <CustomSvgIcon iconSet={{ icon: noData }} svg></CustomSvgIcon>
                                <Typography type={TYPOGRAPHY_TYPES.h4}>Your search didn’t match any results</Typography>
                                <Typography type={TYPOGRAPHY_TYPES.p}>
                                    Please modify your search and try again
                                </Typography>
                            </StyledEmptyState>
                        )}
                    </>
                )}
            </StyledMemberOutreachContainerWrap>
        </>
    ) : (
        <Spinner />
    )
}

export default MemberOutreach
