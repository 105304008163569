import { FC, useEffect, useState } from 'react'
import { ISectionElement } from '../index.models'
import { useSelector } from 'react-redux'
import { getAwvFormGapsInCare, MemberProfileState } from 'redux/slices/memberProfile'
import {
    StyledSpinnerContainer,
    StyledGapsInCareContainer,
    StyledGapsInCareOuterContainer,
    StyledGapsInCareTableBody,
    StyledGapsInCareTableBodyDocumentsItem,
    StyledGapsInCareTableBodyItem,
    StyledGapsInCareTableHeader,
    StyledGapsInCareTableHeaderItem,
    StyledRefreshContainer,
    StyledNoGapsInCareContainer,
    StyledExplanatoryNoteContainer
} from './index.style'
import { setFormattedDateWithSlash } from 'components/UsersTable/index.utils'
import DocumentList from '../../information/GapsInCare/DocumentList'
import { IGapInCareDocument } from 'pages/hcp/users/create/index.models'
import { dispatch } from 'redux/store'
import { downloadDocument } from 'redux/slices/gapsInCare'
import SmallSpinner from 'components/SmallSpinner'
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined'
import { IconButton } from '@mui/material'
import { useParams } from 'react-router-dom'
import { appPath } from 'utilities/appPath'
import useCurrentUser from 'hooks/useCurrentUser'
import { ERoles } from 'models/enums/role'

const autoRefreshInterval = 180000

interface IGapsInCareViewerProps {}

const GapsInCareViewer: FC<IGapsInCareViewerProps> = () => {
    const { memberId, npi, year } = useParams()

    const { electronicAwvFormGapsInCare: gapsInCare, electronicAwvForm } = useSelector(
        (state: { memberProfile: MemberProfileState }) => state.memberProfile
    )

    const currentUser = useCurrentUser()
    const isUserInternal = [ERoles.CallCenterAdmin, ERoles.ProviderRep, ERoles.Quality].includes(
        currentUser?.primaryRole
    )

    const [isDownloading, setIsDownloading] = useState(false)
    const [isRefreshing, setIsRefreshing] = useState(false)

    const handleDownloadDocument = (document: IGapInCareDocument) => {
        setIsDownloading(true)
        dispatch(downloadDocument({ gapInCareDocumentId: document.id })).finally(() => setIsDownloading(false))
    }

    const handleRefresh = () => {
        setIsRefreshing(true)
        dispatch(getAwvFormGapsInCare({ memberId: +memberId, npi, year: +year })).finally(() => setIsRefreshing(false))
    }

    const gapsInCareDataAvailable = gapsInCare?.length > 0 ? true : false

    const isBusy = isDownloading || isRefreshing

    const canRefresh =
        !electronicAwvForm.status || electronicAwvForm.status === 'Saved' || electronicAwvForm.status === 'Deleted'

    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        if (refresh) {
            setRefresh(false)
            if (canRefresh && !isBusy) {
                setIsRefreshing(true)
                dispatch(getAwvFormGapsInCare({ memberId: +memberId, npi, year: +year })).finally(() =>
                    setIsRefreshing(false)
                )
            }
        }
    }, [refresh, canRefresh, isBusy])

    useEffect(() => {
        const intervalId = setInterval(() => {
            setRefresh(true)
        }, autoRefreshInterval)

        return () => clearInterval(intervalId)
    }, [])

    const handlePatientsPageClick = () => {
        window.open(appPath(`/members/memberInformation/gapsInCare/${memberId}-${npi}`), '_blank', 'noreferrer')
    }

    return (
        <>
            <StyledExplanatoryNoteContainer>
                <b>Note:</b> Gaps in Care can only be edited on the{' '}
                {isUserInternal ? <>patient page</> : <a onClick={handlePatientsPageClick}>patient page</a>}.&nbsp;
                {gapsInCareDataAvailable && (
                    <>
                        {canRefresh ? (
                            <>The information shown here reflects the most recent data available.</>
                        ) : (
                            <>The information shown here reflects data current when the form was submitted.</>
                        )}
                    </>
                )}
            </StyledExplanatoryNoteContainer>
            {gapsInCareDataAvailable ? (
                <StyledGapsInCareOuterContainer>
                    <StyledGapsInCareContainer>
                        <StyledGapsInCareTableHeader>
                            <StyledGapsInCareTableHeaderItem>Gap</StyledGapsInCareTableHeaderItem>
                            <StyledGapsInCareTableHeaderItem>Source</StyledGapsInCareTableHeaderItem>
                            <StyledGapsInCareTableHeaderItem>Last DOS</StyledGapsInCareTableHeaderItem>
                            <StyledGapsInCareTableHeaderItem>Status</StyledGapsInCareTableHeaderItem>
                            <StyledGapsInCareTableHeaderItem>Service Note</StyledGapsInCareTableHeaderItem>
                        </StyledGapsInCareTableHeader>
                        {gapsInCare.map((gapInCare) => {
                            const showBottomBorder = gapInCare.documents.length === 0

                            return (
                                <>
                                    <StyledGapsInCareTableBody key={`gap-in-care-${gapInCare.id}`}>
                                        <StyledGapsInCareTableBodyItem showBottomBorder={showBottomBorder}>
                                            {gapInCare.gap}
                                        </StyledGapsInCareTableBodyItem>
                                        <StyledGapsInCareTableBodyItem showBottomBorder={showBottomBorder}>
                                            {gapInCare.isUserCreated ? 'Provider' : 'Health Plan'}
                                        </StyledGapsInCareTableBodyItem>
                                        <StyledGapsInCareTableBodyItem showBottomBorder={showBottomBorder}>
                                            {setFormattedDateWithSlash(gapInCare.lastCompletionDate, true)}
                                        </StyledGapsInCareTableBodyItem>
                                        <StyledGapsInCareTableBodyItem showBottomBorder={showBottomBorder}>
                                            {gapInCare.isCompliant ? 'Compliant' : 'Not Compliant'}
                                        </StyledGapsInCareTableBodyItem>
                                        <StyledGapsInCareTableBodyItem showBottomBorder={showBottomBorder}>
                                            {gapInCare.serviceNote}
                                        </StyledGapsInCareTableBodyItem>
                                    </StyledGapsInCareTableBody>
                                    {gapInCare.documents.length > 0 && (
                                        <StyledGapsInCareTableBody key={`gap-in-care-documents-${gapInCare.id}`}>
                                            <StyledGapsInCareTableBodyDocumentsItem>
                                                <DocumentList
                                                    documents={gapInCare.documents}
                                                    isReadOnly={true}
                                                    isClosed={true}
                                                    isBusy={isBusy}
                                                    onDownload={handleDownloadDocument}
                                                />
                                            </StyledGapsInCareTableBodyDocumentsItem>
                                        </StyledGapsInCareTableBody>
                                    )}
                                </>
                            )
                        })}
                    </StyledGapsInCareContainer>
                    {canRefresh && (
                        <StyledRefreshContainer>
                            {isRefreshing ? (
                                <StyledSpinnerContainer>
                                    <SmallSpinner />
                                </StyledSpinnerContainer>
                            ) : (
                                <IconButton onClick={handleRefresh} disabled={isBusy} title={isBusy ? '' : 'Refresh'}>
                                    <ReplayOutlinedIcon sx={{ color: isBusy ? '#aaaaaa' : '#2281C4' }} />
                                </IconButton>
                            )}
                        </StyledRefreshContainer>
                    )}
                </StyledGapsInCareOuterContainer>
            ) : (
                <StyledNoGapsInCareContainer>No gaps in care to show.</StyledNoGapsInCareContainer>
            )}
        </>
    )
}

export const renderGapsInCareViewer = (_: ISectionElement, key: string) => <GapsInCareViewer key={key} />
