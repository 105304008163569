import { FC } from 'react'
import {
    StyledRadioFormContainer,
    StyledRadioFormInnerWrapper,
    StyledRadioFormWrapper,
    StyledResetContainer
} from './index.style'
import { Controller, useFormContext } from 'react-hook-form'
import { IRadioSelection, ISectionElement, IVerticalRadioSelection } from '../index.models'
import { Label } from '../Label'
import { AwvTooltip } from '../AwvTooltip'
import useErrors from 'hooks/useErrors'
import IconButton from '@mui/material/IconButton'
import { Tooltip } from '@mui/material'
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp'
import { placeholderResetStringValue, useResetValue } from 'hooks/useResetValue'

interface IOption {
    label: string
    value: any
}

interface IRadioSelectionProps {
    name: string
    label: string
    subLabel: string
    options: IOption[]
    isVertical: boolean
    showResetForNull?: boolean
}

export const RadioSelection: FC<IRadioSelectionProps> = ({
    name,
    label,
    subLabel,
    options,
    isVertical,
    showResetForNull = false
}) => {
    const { control, setValue, getValues } = useFormContext()
    const errors = useErrors()
    const resetValue = useResetValue(showResetForNull)

    return (
        <StyledRadioFormWrapper>
            <Label htmlFor={name} label={label} subLabel={subLabel} wrapInParagraph={true} />

            <StyledRadioFormInnerWrapper isVertical={isVertical}>
                <AwvTooltip message={errors.getError(name)}>
                    <StyledRadioFormContainer isInError={errors.getError(name)} isVertical={isVertical}>
                        {options.map((option: IOption, idx: number) => (
                            <label key={`${name}-${idx}`}>
                                <Controller
                                    name={name}
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            id={`${name}.${idx}`}
                                            type="radio"
                                            {...field}
                                            value={option.value}
                                            checked={field.value === option.value}
                                            onChange={() => setValue(name, option.value)}
                                        />
                                    )}
                                />
                                {option.label}
                            </label>
                        ))}
                    </StyledRadioFormContainer>
                </AwvTooltip>
                <StyledResetContainer isVertical={isVertical}>
                    {getValues(name) !== undefined &&
                        (showResetForNull || getValues(name) !== null) &&
                        (!showResetForNull || getValues(name) !== placeholderResetStringValue) && (
                            <Tooltip title="Reset">
                                <IconButton
                                    onClick={() => {
                                        resetValue(name)
                                    }}
                                >
                                    <HighlightOffSharpIcon sx={{ color: '#9e9e9e' }} />
                                </IconButton>
                            </Tooltip>
                        )}
                </StyledResetContainer>
            </StyledRadioFormInnerWrapper>
        </StyledRadioFormWrapper>
    )
}

export const renderRadioSelection = (element: ISectionElement, key: string) => {
    const radioSelection = element as IRadioSelection
    return <RadioSelection key={key} {...radioSelection} isVertical={false} />
}

export const renderVerticalRadioSelection = (element: ISectionElement, key: string) => {
    const verticalRadioSelection = element as IVerticalRadioSelection
    return <RadioSelection key={key} {...verticalRadioSelection} isVertical={true} />
}
