import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC, useEffect, useState } from 'react'
import {
    StyledActionsContainer,
    StyledButton,
    StyledEmptyState,
    StyledHeader,
    StyledSearchBarContainer,
    StyledTableWrap,
    StyledUsersContainerWrap
} from './index.style'
import { addSign, emptySearchIcon, idCard } from './icons'
import CustomSvgIcon from 'components/CustomSvgIcon'
import UsersTable from 'components/UsersTable'
import { ADMIN_HEADERS_LIST, ETableType } from 'components/UsersTable/index.utils'
import { Admin } from 'messages'
import { useNavigate } from 'react-router-dom'
import { clearUserDetails, fetchUsers } from 'redux/slices/user'
import { useDispatch, useSelector } from 'redux/store'
import SearchTextField from 'components/SearchTextField'
import useDebounce from 'hooks/useDebounce'
import { appPath } from 'utilities/appPath'
import PaginationController from 'components/PaginationController'
import { SortDirection } from 'models/sortColumn'
import { useUsersListConfig } from 'hooks/useListConfig'
import { ERoles } from 'models/enums/role'
import useCurrentUser from 'hooks/useCurrentUser'

interface IUsersProps {}

const Users: FC<IUsersProps> = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const listConfig = useUsersListConfig()
    const debouncedFilter = useDebounce(listConfig.effectiveFilter, 500)

    const [initialContentLoaded, setInitialContentLoaded] = useState(false)

    const { users, usersLoading, totalNumberOfUsers, filteredNumberOfUsers } = useSelector(
        (state: { user: any }) => state.user
    )

    useEffect(() => {
        dispatch(
            fetchUsers({
                pageNumber: listConfig.pageNumber,
                size: 10,
                sortColumn: listConfig.sortColumn,
                isDescending: listConfig.sortDirection === SortDirection.descending ? true : false,
                filter: debouncedFilter
            })
        ).then(() => {
            setInitialContentLoaded(true)
        })
    }, [dispatch, listConfig.pageNumber, listConfig.sortColumn, listConfig.sortDirection, debouncedFilter])

    useEffect(() => {
        dispatch(clearUserDetails())
    }, [])

    const createNewUser = () => {
        navigate(appPath('/users/create'))
    }
    const currentUser = useCurrentUser()
    const isReadOnly = currentUser.primaryRole === ERoles.Quality

    return (
        <>
            <StyledUsersContainerWrap>
                <StyledHeader>
                    <Typography type={TYPOGRAPHY_TYPES.p}> {Admin.users}</Typography>
                </StyledHeader>

                {initialContentLoaded && (
                    <>
                        <StyledActionsContainer>
                            <StyledSearchBarContainer>
                                {totalNumberOfUsers > 0 && (
                                    <SearchTextField
                                        placeholder={'Search by name, email, phone number and more...'}
                                        value={listConfig.filter}
                                        loading={usersLoading}
                                        onClearSearch={listConfig.clearFilter}
                                        onValueChanged={listConfig.setFilter}
                                    />
                                )}
                            </StyledSearchBarContainer>

                            {!isReadOnly && (
                                <StyledButton onClick={createNewUser}>
                                    <CustomSvgIcon iconSet={{ icon: addSign }} svg></CustomSvgIcon>
                                    {Admin.buttonLabel}
                                </StyledButton>
                            )}
                        </StyledActionsContainer>

                        {users.length > 0 && (
                            <StyledTableWrap>
                                <UsersTable
                                    users={users}
                                    headersList={ADMIN_HEADERS_LIST}
                                    tableType={ETableType.ADMIN}
                                    sortColumn={listConfig.sortColumn}
                                    sortDirection={listConfig.sortDirection}
                                    sortCallback={listConfig.setSorting}
                                    isReadOnly={isReadOnly}
                                />
                            </StyledTableWrap>
                        )}

                        {filteredNumberOfUsers > 0 && (
                            <PaginationController
                                totalItems={filteredNumberOfUsers}
                                selectedPage={listConfig.pageNumber}
                                selectedPageChanged={listConfig.setPageNumber}
                                singularRecordName="User"
                                pluralRecordName="Users"
                            />
                        )}

                        {totalNumberOfUsers === 0 && (
                            <StyledEmptyState>
                                <CustomSvgIcon iconSet={{ icon: idCard }} svg></CustomSvgIcon>
                                <Typography type={TYPOGRAPHY_TYPES.h4}> {Admin.adminEmptyStateTitle}</Typography>
                                <Typography type={TYPOGRAPHY_TYPES.p}> {Admin.adminEmptyStateSubTitle}</Typography>
                            </StyledEmptyState>
                        )}

                        {totalNumberOfUsers > 0 && filteredNumberOfUsers === 0 && (
                            <StyledEmptyState>
                                <CustomSvgIcon iconSet={{ icon: emptySearchIcon }} svg></CustomSvgIcon>
                                <Typography type={TYPOGRAPHY_TYPES.h4}>{Admin.adminEmptySearchStateTitle}</Typography>
                                <Typography type={TYPOGRAPHY_TYPES.p}>
                                    {' '}
                                    {Admin.adminEmptySearchStateSubTitle}
                                </Typography>
                            </StyledEmptyState>
                        )}
                    </>
                )}
            </StyledUsersContainerWrap>
        </>
    )
}

export default Users
