import ConfirmationBox from 'components/ConfirmationBox'
import { FC } from 'react'
import { UI_COLORS } from 'theme'

interface IResendInvitationConfirmationBox {
    userFirstName: string
    userLastName: string
    onConfirm: () => void
    onCancel: () => void
}

const ResendInvitationConfirmationBox: FC<IResendInvitationConfirmationBox> = ({
    userFirstName,
    userLastName,
    onConfirm,
    onCancel
}) => (
    <ConfirmationBox
        title="Resend Invitation"
        body={`Would you like to send another invitation email to ${userFirstName} ${userLastName}?`}
        confirmButtonLabel="Send"
        confirmButtonColor={UI_COLORS.light_blue2}
        onConfirm={onConfirm}
        onCancel={onCancel}
    />
)

export default ResendInvitationConfirmationBox
