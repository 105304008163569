import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem, DEVICE } from 'utilities'

const { dark_gray, light_blue2, white, grey } = UI_COLORS

export const StyledEditPageBackButton = styled.button`
    color: ${light_blue2};
    /* margin-left: ${pxToRem(15)}; */
    background-color: transparent;
    border: 0;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};

    cursor: pointer;

    figure {
        margin: ${pxToRem(0)};
        margin-right: ${pxToRem(15)};
        span {
            svg {
                path {
                    fill: ${light_blue2};
                }
            }
        }
    }
`

export const StyledEditPageButton = styled.button`
    color: ${light_blue2};
    background-color: transparent;
    border: 0;

    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};

    font-family: 'Inter', sans-serif;

    cursor: pointer;
    /* :hover {
        border-bottom: 1px solid ${light_blue2};
    } */

    figure {
        margin: ${pxToRem(0)};
        margin-right: ${pxToRem(15)};
        span {
            svg {
                path {
                    fill: ${light_blue2};
                }
            }
        }
    }
`

export const StyledButtonsContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
export const StyledUserTypeContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${pxToRem(30)};

    p {
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        color: ${dark_gray};
    }

    figure {
        margin: ${pxToRem(0)};
        span {
            svg {
                width: ${pxToRem(35)};
                height: ${pxToRem(35)};
                /* path {
                    fill: ${light_blue2};
                } */
            }
        }
    }
`
export const StyledBottomButtonsContainer = styled.article`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: ${pxToRem(30)};
    width: 100%;
    position: fixed;
    z-index: 9;
    bottom: 0;
    left: 0;
    background-color: white;
    border-top: 1px solid #dee5ef;

    ${DEVICE.tablet} {
        flex-direction: row;
        justify-content: flex-end;
    }
`
export const StyledButton = styled.button<{ primary?: boolean; secondary?: boolean }>`
    width: 100%;
    height: ${pxToRem(50)};
    border: 0;
    border-radius: ${pxToRem(4)};
    color: ${white};
    font-size: ${pxToRem(16)};

    font-family: 'Inter', sans-serif;
    font-weight: 600;

    cursor: pointer;

    ${(props) => {
        if (props.primary) {
            return css`
                background-color: ${light_blue2};
            `
        }
    }}
    ${(props) => {
        if (props.secondary) {
            return css`
                background-color: ${grey};
            `
        }
    }};

    ${DEVICE.tablet} {
        width: ${pxToRem(130)};
        margin-left: ${pxToRem(30)};
    }
`
export const StyledDeleteLocationButton = styled.button<{ size: string }>`
    background-color: transparent;
    border: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 41px;
    left: 20px;

    cursor: pointer;

    :hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
        border-radius: 20px;
        p {
            visibility: visible;
        }
    }

    ${(props) => {
        if (props.size) {
            return css`
                width: ${props.size}px;
                height: ${props.size}px;
            `
        }
    }}

    p {
        background-color: #293854;
        padding: 5px 0;
        border-radius: 4px;
        width: 70px;
        position: absolute;
        top: -40px;
        right: 10;
        color: #ffffff;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }
`
