import {
    IAwvServiceStatus,
    IAwvServiceStatusRequest,
    IDownloadAwvFileData,
    IMemberProfileAddressDetails,
    IMemberProfileLookupData,
    IMemberProfilePersonalDetails,
    IMemberProfileScheduleAwvData,
    IMemberProfileUnscheduleAwvData,
    awvSummaries,
    awvHistories,
    IAWVUploadFileRequest,
    IGapInCare,
    IGetAwvFormGapsInCareRequest
} from './../../pages/hcp/users/create/index.models'
import { IMemberProfile, IMemberProfileDetails } from '../../pages/hcp/users/create/index.models'
import { Draft, createSlice } from '@reduxjs/toolkit'

import axios, { fileStreamTimeout } from '../../utilities/axios'
import { dispatch } from 'redux/store'
import { addToast } from './toast'
import { successCheckmark } from 'components/Toast/icons'
import { EToastVariant } from 'models/IToast'
import { createExceptionAwareAsyncThunk } from 'utilities/createExceptionAwareAsyncThunk'
import moment from 'moment'
import {
    IAwvElectronicFormType,
    IElectronicAwvForm
} from 'pages/hcp/members/electronicAnnualWellnessVisitForm/index.models'
import {
    IGetMemberAwvSummariesRequest,
    IGetMemberNpiAwvSummariesRequest,
    IGetReviewableAwvFormsRequest,
    IReviewableAwvForm,
    ISetAwvFormResponseStatusRequest
} from 'pages/hcp/members/information/PointsInCare/PointsInCare.models'
import { IGetAwvHistoryRequest } from 'pages/hcp/members/information/PointsInCare/PointsInCare.models'

const defaultElectronicFormTypeIdLocalStorageKey = 'default-electronic-form-type-id'

export type MemberProfileState = {
    memberProfiles: IMemberProfile[]
    memberProfilesRequestId: string | null
    memberProfilesLoading: boolean
    totalNumberOfMemberProfiles: number
    filteredNumberOfMemberProfiles: number
    memberProfileDetails: IMemberProfileDetails
    memberProfileLookupData: IMemberProfileLookupData
    updatedProfileDetails: IMemberProfileDetails
    userHasFiles: boolean
    awvSummaries: awvSummaries
    reviewableAwvForms: IReviewableAwvForm[]
    reviewableAwvFormsRequestId: string | null
    reviewableAwvFormsLoading: boolean
    totalNumberOfReviewableAwvForms: number
    filteredNumberOfReviewableAwvForms: number
    awvHistories: awvHistories
    isDeletingFile: boolean
    activeAwvElectronicFormTypes: IAwvElectronicFormType[] | null
    defaultElectronicFormTypeId: string
    electronicFormTypeId: string
    electronicAwvForm: IElectronicAwvForm
    electronicAwvFormGapsInCare: IGapInCare[]
    awvServiceStatus: IAwvServiceStatus
    awvYears: number[]
    saveOrSubmitAwvFormElectronicResponseRequestIds: string[]
    awvElectronicFormTypes: IAwvElectronicFormType[] | null
    allElectronicFormTypeNames: string[] | null
}

const nullElectronicAwvForm: IElectronicAwvForm = {
    awvId: null,
    formTypeId: null,
    formTypeName: null,
    form: null,
    status: null,
    response: null,
    validationErrors: null,
    gapsInCare: null
}

const initialState: MemberProfileState = {
    memberProfiles: [],
    memberProfilesRequestId: null,
    memberProfilesLoading: false,
    totalNumberOfMemberProfiles: 0,
    filteredNumberOfMemberProfiles: 0,
    memberProfileDetails: null,
    memberProfileLookupData: null,
    updatedProfileDetails: null,
    userHasFiles: false,
    awvSummaries: { awvSummaries: [] },
    reviewableAwvForms: [],
    reviewableAwvFormsRequestId: null,
    reviewableAwvFormsLoading: false,
    totalNumberOfReviewableAwvForms: 0,
    filteredNumberOfReviewableAwvForms: 0,
    awvHistories: { awvHistories: [] },
    isDeletingFile: false,
    activeAwvElectronicFormTypes: null,
    defaultElectronicFormTypeId: window.localStorage[defaultElectronicFormTypeIdLocalStorageKey],
    electronicFormTypeId: null,
    electronicAwvForm: nullElectronicAwvForm,
    electronicAwvFormGapsInCare: [],
    awvServiceStatus: null,
    awvYears: null,
    saveOrSubmitAwvFormElectronicResponseRequestIds: [],
    awvElectronicFormTypes: null,
    allElectronicFormTypeNames: null
}

const insertOrUpdateAwvSummary = (
    state: Draft<MemberProfileState>,
    providerNpi: string,
    year: number,
    awvSummary: any
) => {
    const awvSummaries = [...(state.awvSummaries?.awvSummaries ?? [])]
    const awvSummaryIndex = awvSummaries.findIndex((x) => x.providerNpi === providerNpi && x.year === year)
    if (awvSummaryIndex >= 0) {
        awvSummaries[awvSummaryIndex] = awvSummary
    } else {
        awvSummaries.push(awvSummary)
    }
    state.awvSummaries = { awvSummaries }
}

const setReviewableAwvFormStatus = (
    state: Draft<MemberProfileState>,
    memberId: number,
    npi: string,
    year: number,
    status: string,
    statusEffective: string
) => {
    const reviewableAwvForms = [...state.reviewableAwvForms]
    const reviewableAwvFormIndex = reviewableAwvForms?.findIndex(
        (x) => x.memberId === memberId && x.providerNpi === npi && x.year === year
    )
    if (reviewableAwvFormIndex >= 0) {
        reviewableAwvForms[reviewableAwvFormIndex].status = status
        reviewableAwvForms[reviewableAwvFormIndex].statusEffective = statusEffective
    }
    state.reviewableAwvForms = reviewableAwvForms
}

export const generateAwvSummaryLocalStorageKey = (memberId: number) => `AWV-Summary-${memberId}`

const recordSavedOrSubmittedElectronicAwvFormResponseRequestId = (
    state: Draft<MemberProfileState>,
    requestId: string
) => {
    state.saveOrSubmitAwvFormElectronicResponseRequestIds = [
        ...state.saveOrSubmitAwvFormElectronicResponseRequestIds,
        requestId
    ]
}

const processSavedOrSubmittedElectronicAwvFormResponse = (
    state: Draft<MemberProfileState>,
    requestId: string,
    memberId: any,
    payload: any,
    status: string
) => {
    const requestIdIndex = state.saveOrSubmitAwvFormElectronicResponseRequestIds.indexOf(requestId)
    if (requestIdIndex >= 0) {
        state.electronicAwvForm = {
            ...state.electronicAwvForm,
            validationErrors: payload.validationErrors
        }
        window.localStorage.setItem(
            generateAwvSummaryLocalStorageKey(memberId),
            JSON.stringify({ timestamp: Date.now(), awvSummary: payload })
        )

        state.saveOrSubmitAwvFormElectronicResponseRequestIds = [
            ...state.saveOrSubmitAwvFormElectronicResponseRequestIds
        ].slice(requestIdIndex + 1)

        if (payload.form) {
            state.electronicAwvForm = { ...state.electronicAwvForm, form: payload.form }
        }

        if (state.electronicAwvForm.status !== status) {
            state.electronicAwvForm = { ...state.electronicAwvForm, status }
        }

        if (payload.gapsInCare) {
            state.electronicAwvFormGapsInCare = payload.gapsInCare
        }
    }
}

export const memberProfileSlice = createSlice({
    name: 'memberProfile',
    initialState,
    reducers: {
        resetMemberDetails: (state) => {
            state.memberProfileDetails = null
        },
        resetUserHasFiles: (state) => {
            state.userHasFiles = false
            state.awvSummaries = { awvSummaries: [] }
        },
        processNewOrUpdatedAwvSummary: (state, action) => {
            insertOrUpdateAwvSummary(
                state,
                action.payload.awvSummary.providerNpi,
                action.payload.awvSummary.year,
                action.payload.awvSummary
            )
        },
        memberFeedbackRead: (state, action) => {
            state.memberProfiles = state.memberProfiles.map((x) => ({
                ...x,
                hasNewFeedback:
                    x.membID === action.payload.membid && x.providerNpi === action.payload.npi
                        ? false
                        : x.hasNewFeedback
            }))
        },
        reviewableAwvFormFeedbackRead: (state, action) => {
            state.reviewableAwvForms = state.reviewableAwvForms.map((x) => ({
                ...x,
                hasNewFeedback: x.awvId === action.payload.awvId ? false : x.hasNewFeedback
            }))
        },
        setDefaultElectronicFormTypeId: (state, action) => {
            state.defaultElectronicFormTypeId = action.payload
            window.localStorage[defaultElectronicFormTypeIdLocalStorageKey] = action.payload
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchMemberProfiles.pending, (state, action) => {
                state.memberProfilesRequestId = action.meta.requestId
                state.memberProfilesLoading = true
            })
            .addCase(fetchMemberProfiles.fulfilled, (state, action) => {
                if (state.memberProfilesRequestId === action.meta.requestId) {
                    state.memberProfiles = action.payload.memberProfiles as IMemberProfile[]
                    state.memberProfilesLoading = false
                    state.totalNumberOfMemberProfiles = action.payload.totalNumberOfMembers
                    state.filteredNumberOfMemberProfiles = action.payload.filteredNumberOfMembers
                }
            })
            .addCase(fetchMemberProfiles.rejected, (state, action) => {
                if (state.memberProfilesRequestId === action.meta.requestId) {
                    state.memberProfilesLoading = false
                }
            })
            .addCase(fetchAdminMemberProfiles.pending, (state, action) => {
                state.memberProfilesRequestId = action.meta.requestId
                state.memberProfilesLoading = true
            })
            .addCase(fetchAdminMemberProfiles.fulfilled, (state, action) => {
                if (state.memberProfilesRequestId === action.meta.requestId) {
                    state.memberProfiles = action.payload.memberProfiles
                    state.memberProfilesLoading = false
                    state.totalNumberOfMemberProfiles = action.payload.totalNumberOfMembers
                    state.filteredNumberOfMemberProfiles = action.payload.filteredNumberOfMembers
                }
            })
            .addCase(fetchAdminMemberProfiles.rejected, (state, action) => {
                if (state.memberProfilesRequestId === action.meta.requestId) {
                    state.memberProfilesLoading = false
                }
            })
            .addCase(fetchMemberProfileDetail.fulfilled, (state, action) => {
                state.memberProfileDetails = action.payload
            })
            .addCase(fetchMemberProfileLookups.fulfilled, (state, action) => {
                state.memberProfileLookupData = action.payload
            })
            .addCase(updateMemberProfilePersonalDetails.fulfilled, (state, action) => {
                state.updatedProfileDetails = action.payload
            })
            .addCase(updateMemberProfileAddressDetails.fulfilled, (state, action) => {
                state.updatedProfileDetails = action.payload
            })
            .addCase(saveAwvFormDocumentResponse.fulfilled, (state, action) => {
                state.userHasFiles = true
                insertOrUpdateAwvSummary(state, action.meta.arg.npi, action.meta.arg.year, action.payload.awvSummary)
            })
            .addCase(submitSavedAwvFormResponse.fulfilled, (state, action) => {
                insertOrUpdateAwvSummary(state, action.meta.arg.npi, action.meta.arg.year, action.payload.awvSummary)
            })
            .addCase(getAwvElectronicFormTypes.fulfilled, (state, action) => {
                state.awvElectronicFormTypes = action.payload
            })
            .addCase(getMemberAwvSummaries.fulfilled, (state, action) => {
                state.awvSummaries = action.payload
            })
            .addCase(getMemberNpiAwvSummaries.fulfilled, (state, action) => {
                state.awvSummaries = action.payload
            })
            .addCase(getReviewableAwvForms.pending, (state, action) => {
                state.reviewableAwvFormsRequestId = action.meta.requestId
                state.reviewableAwvFormsLoading = true
            })
            .addCase(getReviewableAwvForms.fulfilled, (state, action) => {
                if (state.reviewableAwvFormsRequestId === action.meta.requestId) {
                    state.reviewableAwvForms = action.payload.reviewableAwvForms
                    state.reviewableAwvFormsLoading = false
                    state.totalNumberOfReviewableAwvForms = action.payload.totalNumberOfReviewableAwvForms
                    state.filteredNumberOfReviewableAwvForms = action.payload.filteredNumberOfReviewableAwvForms
                }
            })
            .addCase(getReviewableAwvForms.rejected, (state, action) => {
                if (state.reviewableAwvFormsRequestId === action.meta.requestId) {
                    state.reviewableAwvFormsLoading = false
                }
            })
            .addCase(reviewAwvFormResponse.fulfilled, (state, action) => {
                setReviewableAwvFormStatus(
                    state,
                    action.meta.arg.memberId,
                    action.meta.arg.npi,
                    action.meta.arg.year,
                    'InReview',
                    action.payload.awvSummary.statusEffective
                )
            })
            .addCase(approveAwvFormResponse.fulfilled, (state, action) => {
                setReviewableAwvFormStatus(
                    state,
                    action.meta.arg.memberId,
                    action.meta.arg.npi,
                    action.meta.arg.year,
                    'Approved',
                    action.payload.awvSummary.statusEffective
                )
            })
            .addCase(rejectAwvFormResponse.fulfilled, (state, action) => {
                setReviewableAwvFormStatus(
                    state,
                    action.meta.arg.memberId,
                    action.meta.arg.npi,
                    action.meta.arg.year,
                    'Rejected',
                    action.payload.awvSummary.statusEffective
                )
            })
            .addCase(denyAwvFormResponse.fulfilled, (state, action) => {
                setReviewableAwvFormStatus(
                    state,
                    action.meta.arg.memberId,
                    action.meta.arg.npi,
                    action.meta.arg.year,
                    'Denied',
                    action.payload.awvSummary.statusEffective
                )
            })
            .addCase(getAwvHistories.fulfilled, (state, action) => {
                state.awvHistories = action.payload
            })
            .addCase(deleteAwvFormResponse.fulfilled, (state, action) => {
                state.userHasFiles = false
                if (state.awvSummaries?.awvSummaries?.length > 0) {
                    state.awvSummaries = {
                        awvSummaries: state.awvSummaries.awvSummaries.filter((x) => x.year !== action.meta.arg.year)
                    }
                }
            })
            .addCase(scheduleAwv.fulfilled, (state, action) => {
                const memberProfiles = [...state.memberProfiles]
                const memberProfileIndex = memberProfiles.findIndex(
                    (x) => x.memberId === action.meta.arg.memberId && x.providerNpi === action.meta.arg.npi
                )
                if (memberProfileIndex >= 0) {
                    const formattedScheduledDOS = moment.utc(action.meta.arg.scheduledDOS).format('YYYY-MM-DDTHH:mm:ss')
                    memberProfiles[memberProfileIndex].nextAWVDate = formattedScheduledDOS
                    if (
                        memberProfiles[memberProfileIndex].awvStatus === 'Scheduled' &&
                        new Date(action.meta.arg.scheduledDOS).getFullYear() !==
                            new Date(
                                memberProfiles[memberProfileIndex].awvScheduledDate
                            ).getFullYear() /* current/member profile year*/
                    ) {
                        memberProfiles[memberProfileIndex].awvStatus = 'Not Started'
                        memberProfiles[memberProfileIndex].awvScheduledDate = null
                    } else {
                        if (
                            memberProfiles[memberProfileIndex].awvStatus === 'Not Started' &&
                            new Date(action.meta.arg.scheduledDOS).getFullYear() === new Date().getFullYear()
                        ) {
                            memberProfiles[memberProfileIndex].awvStatus = 'Scheduled'
                        }
                        memberProfiles[memberProfileIndex].awvScheduledDate = formattedScheduledDOS
                        memberProfiles[memberProfileIndex].nextAWVDate = formattedScheduledDOS
                    }
                    state.memberProfiles = memberProfiles
                }
            })
            .addCase(unscheduleAwv.fulfilled, (state, action) => {
                const memberProfiles = [...state.memberProfiles]
                const memberProfileIndex = memberProfiles.findIndex(
                    (x) => x.memberId === action.meta.arg.memberId && x.providerNpi === action.meta.arg.npi
                )
                if (memberProfileIndex >= 0) {
                    memberProfiles[memberProfileIndex].nextAWVDate = null
                    memberProfiles[memberProfileIndex].awvScheduledDate = null
                    if (memberProfiles[memberProfileIndex].awvStatus === 'Scheduled') {
                        memberProfiles[memberProfileIndex].awvStatus = 'Not Started'
                    }
                    state.memberProfiles = memberProfiles
                }
            })
            .addCase(setDeletingAwv.fulfilled, (state, action) => {
                state.isDeletingFile = action.payload
            })
            .addCase(exportPatientsToExcel.fulfilled, (state, action) => {})
            .addCase(exportAdminPatientsToExcel.fulfilled, (state, action) => {})
            .addCase(getActiveAwvElectronicFormTypes.fulfilled, (state, action) => {
                state.activeAwvElectronicFormTypes = action.payload
            })
            .addCase(getAllElectronicFormTypeNames.fulfilled, (state, action) => {
                state.allElectronicFormTypeNames = action.payload
            })
            .addCase(getAwvElectronicFormType.pending, (state) => {
                state.electronicFormTypeId = null
            })
            .addCase(getAwvElectronicFormType.fulfilled, (state, action) => {
                state.electronicFormTypeId = action.payload.formTypeId
            })
            .addCase(getAwvElectronicForm.pending, (state) => {
                state.electronicAwvForm = nullElectronicAwvForm
                state.electronicAwvFormGapsInCare = []
            })
            .addCase(getAwvElectronicForm.fulfilled, (state, action) => {
                state.electronicAwvForm = action.payload
                state.electronicAwvFormGapsInCare = action.payload.gapsInCare
            })
            .addCase(getAwvFormGapsInCare.fulfilled, (state, action) => {
                state.electronicAwvFormGapsInCare = action.payload
            })
            .addCase(getAwvStatus.fulfilled, (state, action) => {
                state.awvServiceStatus = action.payload
            })
            .addCase(getAwvYears.fulfilled, (state, action) => {
                state.awvYears = action.payload
            })
            .addCase(saveAwvFormElectronicResponse.pending, (state, action) => {
                recordSavedOrSubmittedElectronicAwvFormResponseRequestId(state, action.meta.requestId)
            })
            .addCase(submitAwvFormElectronicResponse.pending, (state, action) => {
                recordSavedOrSubmittedElectronicAwvFormResponseRequestId(state, action.meta.requestId)
            })
            .addCase(saveAwvFormElectronicResponse.fulfilled, (state, action) => {
                processSavedOrSubmittedElectronicAwvFormResponse(
                    state,
                    action.meta.requestId,
                    action.meta.arg.memberId,
                    action.payload,
                    'Saved'
                )
            })
            .addCase(submitAwvFormElectronicResponse.fulfilled, (state, action) => {
                processSavedOrSubmittedElectronicAwvFormResponse(
                    state,
                    action.meta.requestId,
                    action.meta.arg.memberId,
                    action.payload,
                    'Submitted'
                )
            })
    }
})

export const {
    resetMemberDetails,
    resetUserHasFiles,
    processNewOrUpdatedAwvSummary,
    memberFeedbackRead,
    reviewableAwvFormFeedbackRead,
    setDefaultElectronicFormTypeId
} = memberProfileSlice.actions

export default memberProfileSlice.reducer

export const fetchMemberProfiles = createExceptionAwareAsyncThunk(
    'memberProfile/getMemberProfiles',
    async (args: any) => {
        const response = await axios.post('api/MemberProfile/MemberProfiles', args)
        return response.data
    }
)
export const fetchAdminMemberProfiles = createExceptionAwareAsyncThunk(
    'adminMemberProfile/getAdminMemberProfiles',
    async (args: any) => {
        const response = await axios.post('api/MemberProfile/AdminMemberProfiles', args)
        return response.data
    }
)
export const getAwvYears = createExceptionAwareAsyncThunk('awv/getAWVYears', async () => {
    const response = await axios.post('api/awv/getAWVYears')
    return response.data
})

export const exportPatientsToExcel = createExceptionAwareAsyncThunk(
    'memberProfile/ExportPatients',
    async (args: any) => {
        const response = await axios.post('api/MemberProfile/ExportPatients', args, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        })

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        // PatientSummary_YYYY_YYYYMMDD.xlsx
        const fileName = `PatientSummary_${args.year}_${moment(new Date()).format('YYYYMMDD')}.xlsx`
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `File Exported Successfully`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const exportAdminPatientsToExcel = createExceptionAwareAsyncThunk(
    'memberProfile/ExportAdminPatients',
    async (args: any) => {
        const response = await axios.post('api/MemberProfile/ExportAdminPatients', args, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        // PatientSummary_YYYY_YYYYMMDD.xlsx
        const fileName = `PatientSummary_${args.year}_${moment(new Date()).format('YYYYMMDD')}.xlsx`
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `File Exported Successfully`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const fetchMemberProfileDetail = createExceptionAwareAsyncThunk(
    'memberProfile/getMemberProfileDetail',
    async (args: any) => {
        const response = await axios.post('api/MemberProfile/MemberProfileDetail', args)
        return response.data
    }
)

export const fetchMemberProfileLookups = createExceptionAwareAsyncThunk(
    'memberProfile/getMemberProfileLookups',
    async () => {
        const response = await axios.post('api/MemberProfile/MemberProfileLookups')
        return response.data
    }
)

export const updateMemberProfilePersonalDetails = createExceptionAwareAsyncThunk(
    'memberProfile/updatePersonalDetails',
    async (args: IMemberProfilePersonalDetails) => {
        const response = await axios.post('api/MemberProfile/UpdateMemberProfilePersonal', args)
        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'Data Updated successfully',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const updateMemberProfileAddressDetails = createExceptionAwareAsyncThunk(
    'memberProfile/updateAddressDetails',
    async (args: IMemberProfileAddressDetails) => {
        const response = await axios.post('api/MemberProfile/UpdateMemberProfileAddress', args)
        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'Address Updated successfully',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const scheduleAwv = createExceptionAwareAsyncThunk(
    'awv/scheduleAwv',
    async (args: IMemberProfileScheduleAwvData) => {
        const response = await axios.post('api/Awv/ScheduleAwv', args)
        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `AWV successfully scheduled for ${moment.utc(args.scheduledDOS).format('MM/DD/YYYY')}`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const unscheduleAwv = createExceptionAwareAsyncThunk(
    'awv/unscheduleAwv',
    async (args: IMemberProfileUnscheduleAwvData) => {
        const response = await axios.post('api/Awv/UnscheduleAwv', args)
        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'AWV successfully unscheduled',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const getAwvStatus = createExceptionAwareAsyncThunk(
    'awv/getAwvStatus',
    async (args: IAwvServiceStatusRequest) => {
        const response = await axios.post('api/Awv/GetAwvStatus', args)
        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'AWV successfully retrieved status info',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const downloadMemberAwvFile = createExceptionAwareAsyncThunk(
    'awv/downloadAwvDocumentForm',
    async (args: IDownloadAwvFileData) => {
        const response = await axios.post('api/Awv/DownloadAwvDocumentForm', args, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf'
            }
        })

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', response.headers['file-name'])
        document.body.appendChild(link)
        link.click()

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `File Downloaded successfully for member: ${args.memberId} `,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const saveAwvFormDocumentResponse = createExceptionAwareAsyncThunk(
    'awv/SaveAwvFormDocumentResponse',
    async (args: IAWVUploadFileRequest) => {
        const response = await axios.post('api/Awv/SaveAwvFormDocumentResponse', args, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            timeout: fileStreamTimeout // This is where we're setting the 30 second timeout
        })

        if (response.status === 200 && window.localStorage.getItem('doNotShowResponseSavedMessageBox')) {
            dispatch(
                addToast({
                    message: 'The AWV Form has been saved.',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }

        return response.data
    }
)

export const submitSavedAwvFormResponse = createExceptionAwareAsyncThunk(
    'awv/SubmitSavedAwvFormResponse',
    async (args: any) => {
        const response = await axios.post('api/Awv/SubmitSavedAwvFormResponse', args)

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'The AWV Form has been submitted.',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }

        return response.data
    }
)

export const uploadAwvDocumentForm = createExceptionAwareAsyncThunk(
    'awvFile/UploadAwvDocumentForm',
    async (args: any) => {
        const response = await axios.post('api/Awv/UploadAwvDocumentForm', args, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `Upload Successful`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const getAwvElectronicFormTypes = createExceptionAwareAsyncThunk(
    'awvFile/GetAwvElectronicFormTypes',
    async () => {
        const response = await axios.post('api/Awv/GetAwvElectronicFormTypes')
        return response.data
    }
)

export const postAwvElectronicForm = createExceptionAwareAsyncThunk(
    'awvFile/PostAwvElectronicForm',
    async (args: any) => {
        const response = await axios.post('api/Awv/PostAwvElectronicForm', args)

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `Upload Successful`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const getMemberAwvSummaries = createExceptionAwareAsyncThunk(
    'awv/GetMemberAwvSummaries',
    async (args: IGetMemberAwvSummariesRequest) => {
        const response = await axios.post('api/Awv/GetMemberAwvSummaries', args)
        return response.data
    }
)

export const getMemberNpiAwvSummaries = createExceptionAwareAsyncThunk(
    'awv/GetMemberNpiAwvSummaries',
    async (args: IGetMemberNpiAwvSummariesRequest) => {
        const response = await axios.post('api/Awv/GetMemberNpiAwvSummaries', args)
        return response.data
    }
)

export const getAwvHistories = createExceptionAwareAsyncThunk(
    'awv/GetAwvHistories',
    async (args: IGetAwvHistoryRequest) => {
        const response = await axios.post('api/Awv/GetAwvHistories', args)
        return response.data
    }
)

export const downloadAwvFormDocumentResponse = createExceptionAwareAsyncThunk(
    'awv/DownloadAwvFormDocumentResponse',
    async (args: any) => {
        const response = await axios.post('api/Awv/DownloadAwvFormDocumentResponse', args, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf'
            },
            timeout: fileStreamTimeout // This is where we're setting the 30 second timeout
        })

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', response.headers['file-name'])
        document.body.appendChild(link)
        link.click()

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `File Downloaded successfully for member: ${args.memberId} `,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const deleteAwvFormResponse = createExceptionAwareAsyncThunk('awv/DeleteAwvFormResponse', async (args: any) => {
    const response = await axios.post('api/Awv/DeleteAwvFormResponse', args)

    if (response.status === 200) {
        dispatch(
            addToast({
                message: `File Deleted Successfully`,
                icon: successCheckmark,
                time: 3000,
                variant: EToastVariant.SUCCESS
            })
        )
    }
    return response.data
})

export const getReviewableAwvForms = createExceptionAwareAsyncThunk(
    'awv/GetReviewableAwvForms',
    async (args: IGetReviewableAwvFormsRequest) => {
        const response = await axios.post('api/Awv/GetReviewableAwvForms', args)
        return response.data
    }
)

export const reviewAwvFormResponse = createExceptionAwareAsyncThunk(
    'awv/ReviewAwvFormResponse',
    async (args: ISetAwvFormResponseStatusRequest) => {
        const response = await axios.post('api/Awv/ReviewAwvFormResponse', args)

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `The form is now in review.`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }

        return response.data
    }
)

export const approveAwvFormResponse = createExceptionAwareAsyncThunk(
    'awv/ApproveAwvFormResponse',
    async (args: ISetAwvFormResponseStatusRequest) => {
        const response = await axios.post('api/Awv/ApproveAwvFormResponse', args)

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `The form has been approved.`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }

        return response.data
    }
)

export const denyAwvFormResponse = createExceptionAwareAsyncThunk(
    'awv/DenyAwvFormResponse',
    async (args: ISetAwvFormResponseStatusRequest) => {
        const response = await axios.post('api/Awv/DenyAwvFormResponse', args)

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `The form has been denied.`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }

        return response.data
    }
)

export const rejectAwvFormResponse = createExceptionAwareAsyncThunk(
    'awv/RejectAwvFormResponse',
    async (args: ISetAwvFormResponseStatusRequest) => {
        const response = await axios.post('api/Awv/RejectAwvFormResponse', args)

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `The form has been rejected.`,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }

        return response.data
    }
)

export const setDeletingAwv = createExceptionAwareAsyncThunk('awvFile/SetDeletingAwv', async (args: any) => {
    return args
})

export const getActiveAwvElectronicFormTypes = createExceptionAwareAsyncThunk(
    'awv/GetActiveAwvElectronicFormTypes',
    async () => {
        const response = await axios.post('api/Awv/GetActiveAwvElectronicFormTypes')
        return response.data
    }
)

export const getAllElectronicFormTypeNames = createExceptionAwareAsyncThunk(
    'awv/GetAllElectronicFormTypeNames',
    async () => {
        const response = await axios.post('api/Awv/GetAllElectronicFormTypeNames')
        return response.data
    }
)

export const getAwvElectronicFormType = createExceptionAwareAsyncThunk(
    'awv/GetAwvElectronicFormType',
    async (args: any) => {
        const response = await axios.post('api/Awv/GetAwvElectronicFormType', args)
        return response.data
    }
)

export const getAwvElectronicForm = createExceptionAwareAsyncThunk('awv/GetAwvElectronicForm', async (args: any) => {
    const response = await axios.post('api/Awv/GetAwvElectronicForm', args)
    return response.data
})

export const getAwvFormGapsInCare = createExceptionAwareAsyncThunk(
    'awv/GetAwvFormGapsInCare',
    async (args: IGetAwvFormGapsInCareRequest) => {
        const response = await axios.post<IGapInCare[]>('api/Awv/GetAwvFormGapsInCare', args)
        return response.data
    }
)

export const renderAwvElectronicFormPdf = createExceptionAwareAsyncThunk(
    'awv/RenderAwvElectronicFormPdf',
    async (args: any) => {
        const response = await axios.post('api/Awv/RenderAwvElectronicFormPdf', args, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf'
            },
            timeout: fileStreamTimeout // This is where we're setting the 30 second timeout
        })

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', response.headers['file-name'])
        document.body.appendChild(link)
        link.click()

        if (response.status === 200) {
            dispatch(
                addToast({
                    message: `Electronic AWV Form rendered successfully for member: ${args.memberId} `,
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
        }
        return response.data
    }
)

export const saveAwvFormElectronicResponse = createExceptionAwareAsyncThunk(
    'awv/SaveAwvFormElectronicResponse',
    async (args: any) => {
        const response = await axios.post('api/Awv/SaveAwvFormElectronicResponse', args)
        return response.data
    }
)

export const submitAwvFormElectronicResponse = createExceptionAwareAsyncThunk(
    'awv/SubmitAwvFormElectronicResponse',
    async (args: any) => {
        const response = await axios.post('api/Awv/SubmitAwvFormElectronicResponse', args)
        return response.data
    }
)

export const getAwvAttestationTemplate = createExceptionAwareAsyncThunk('awv/GetAttestationTemplate', async () => {
    const response = await axios.post('api/Awv/GetAttestationTemplate')
    return response.data
})

interface IUpdateMyCredentialRequest {
    credential: number
}
export const updateMyCredential = createExceptionAwareAsyncThunk(
    'Awv/UpdateMyCredential',
    async (args: IUpdateMyCredentialRequest) => {
        const response = await axios.post('/api/Awv/UpdateMyCredential', args)
        return response.data
    }
)
