import styled, { css } from 'styled-components'
import { DEVICE, pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { light_gray, white, grey, light_gray2, light_blue2, dark_gray } = UI_COLORS

export const StyledModalBackground = styled.article`
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(41, 56, 84, 0.8);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const StyledModalContainer = styled.article`
    position: fixed;
    background: ${white};

    width: ${pxToRem(375)};

    border-radius: 4px;
    padding: ${pxToRem(30)};
    color: black;
    font-size: ${pxToRem(14)};

    h2 {
        margin-top: 0;
        font-size: ${pxToRem(18)};
    }

    select {
        margin-bottom: ${pxToRem(20)};
        margin-top: ${pxToRem(12)};
    }

    ${DEVICE.tablet} {
        font-size: ${pxToRem(14)};
        width: ${pxToRem(650)};
        min-height: ${pxToRem(596)};
        font-size: ${pxToRem(16)};
    }

    ${DEVICE.desktop} {
        width: ${pxToRem(820)};
        padding: 40px;
    }
`

export const StyledSelectLabel = styled.label`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
    display: block;
    margin-bottom: ${pxToRem(12)};
`

export const StyledStateSelect = styled.select`
    width: 100%;
    height: 50px;
    border: 1px solid #dee5ef;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 16px;
`

export const StyledCityStateContainer = styled.article`
    ${DEVICE.tablet} {
        display: flex;
        flex-direction: row;

        > article {
            :nth-child(1) {
                margin-right: ${pxToRem(20)};
                width: ${pxToRem(220)};
            }
            :nth-child(2) {
                width: ${pxToRem(220)};
            }
        }
    }
`

export const StyledButtonContainer = styled.footer`
    display: flex;
    flex-direction: column;
    margin-top: ${pxToRem(25)};

    ${DEVICE.tablet} {
        justify-content: flex-start;
        flex-direction: row-reverse;

        > button {
            :nth-child(1) {
                margin-right: ${pxToRem(15)};
            }
        }
    }
`

export const StyledSaveButton = styled.button`
    width: 100%;
    height: ${pxToRem(50)};
    border-radius: ${pxToRem(4)};
    border: 0px;
    margin-bottom: ${pxToRem(15)};

    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};
    color: ${white};

    background-color: ${light_blue2};

    :active {
        background-color: ${white};
        color: ${light_blue2};
        border: 1px solid ${light_blue2};
    }

    cursor: pointer;

    ${DEVICE.tablet} {
        width: ${pxToRem(120)};
    }
`

export const StyledCancelButton = styled.button`
    width: 100%;
    height: ${pxToRem(50)};
    border-radius: ${pxToRem(4)};
    border: 0px;

    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};

    margin-right: ${pxToRem(16)};
    background-color: ${grey};
    color: ${white};

    :active {
        background-color: ${white};
        color: ${grey};
        border: 1px solid ${grey};
    }

    cursor: pointer;

    ${DEVICE.tablet} {
        width: ${pxToRem(120)};
    }    
`
export const StyledStateSelectButton = styled.article`
    width: 100%;
    height: 50px;
    border: 1px solid #dee5ef;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 16px;

    text-align: left;
    background-color: ${white};

    font-family: 'Inter', 'sans-serif';
    color: ${light_gray};

    position: relative;

    display: flex;
    align-items: center;

    figure {
        margin: 0;
        position: absolute;
        right: ${pxToRem(15)};
        top: 50%;
        transform: translateY(-50%);
    }
`

export const StyledSelectButtonMenuWrap = styled.article`
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: transparent;
`

export const StyledSelectButtonMenu = styled.menu`
    max-height: 250px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 12;
    overflow-y: auto;
    padding: 0;

    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    background-color: ${white};

    > button {
        display: block;
        width: 100%;
        border: 0;
        padding: 19px 18px;
        background-color: ${white};
        text-align: left;
        font-family: 'Inter', 'sans-serif';

        :hover {
            background-color: ${light_gray2};
        }
    }
`
export const StyledAddressModalInputContainer = styled.article<{ secondary?: boolean; isPhoneField?: boolean }>`
    width: 100%;
    marginRight: '60px';
    margin-right: ${pxToRem(20)};
    margin-bottom: ${pxToRem(24)};

    ${DEVICE.tablet} {
        width: ${pxToRem(400)};

        ${(props) => {
            if (props.secondary) {
                return css`
                    width: ${pxToRem(220)};
                `
            }
        }}
    }

    ${DEVICE.desktop} {
        ${(props) => {
            if (props.secondary) {
                return css`
                    width: ${pxToRem(220)};
                `
            }
        }}

        ${(props) => {
            if (props.isPhoneField) {
                return css`
                    width: ${pxToRem(250)};
                `
            }
        }}
    }
`

export const StyledDropdownWrap = styled.article`
    position: relative;
    margin-bottom: ${pxToRem(15)};
`
export const StyledInputWrap = styled.article`
    ${DEVICE.tablet} {
        width: ${pxToRem(220)};
        margin-bottom: ${pxToRem(20)};
    }
`

export const StyledInputAddressWrap = styled.article`
    ${DEVICE.tablet} {
        width: ${pxToRem(400)};
        margin-bottom: ${pxToRem(20)};
    }
`
