import { FC, useState } from 'react'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { feedbackIcon } from './icons'
import { StyledAWVFeedbackButton, StyledAWVFeedbackContainer, StyledAWVNewFeedback } from './index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'

interface IAWVFeedbackProps {
    openFeedback?: () => void
    hasNewFeedback: boolean
    feedbackCount?: any
}

const AWVFeedback: FC<IAWVFeedbackProps> = ({ openFeedback, hasNewFeedback, feedbackCount }) => {
    const [isActive, setIsActive] = useState<boolean>(false)

    return (
        <StyledAWVFeedbackContainer>
            <StyledAWVFeedbackButton
                onMouseEnter={() => setIsActive(true)}
                onMouseLeave={() => setIsActive(false)}
                isActive={isActive}
                onClick={openFeedback}
            >
                <CustomSvgIcon iconSet={{ icon: feedbackIcon }} svg></CustomSvgIcon>
                {isActive ? <Typography type={TYPOGRAPHY_TYPES.span}>Feedback</Typography> : null}
            </StyledAWVFeedbackButton>
            {hasNewFeedback ? <StyledAWVNewFeedback>{feedbackCount}</StyledAWVNewFeedback> : null}
        </StyledAWVFeedbackContainer>
    )
}

export default AWVFeedback
