import { useEffect, useState, useRef } from 'react'

const useHeadsObserver = () => {
    const observer = useRef<IntersectionObserver | null>(null)
    const [activeId, setActiveId] = useState('')

    useEffect(() => {
        const handleObsever = (entries: any) => {
            entries.forEach((entry: any) => {
                if (entry?.isIntersecting) {
                    setActiveId(entry.target.textContent)
                }
            })
        }

        observer.current = new IntersectionObserver(handleObsever, {
            rootMargin: '-61px 0px -650px 0px'
        })

        const elements = document.querySelectorAll('h2')
        elements.forEach((elem) => observer.current.observe(elem))
        return () => observer.current?.disconnect()
    }, [observer.current])

    return { activeId }
}

export default useHeadsObserver
