import styled, { css } from 'styled-components'
import { DEVICE, pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { light_gray3 } = UI_COLORS

export const StyledAddressContainer = styled.article`
    display: flex;
    flex-direction: column;

    ${DEVICE.desktop} {
        flex-direction: row;
        margin-bottom: 10px;
    }
`

export const StyledAddressSeparator = styled.div`
    ${DEVICE.desktop} {
        width: 100%;
        margin-top: ${pxToRem(10)};
        margin-bottom: ${pxToRem(30)};

        border-bottom: 1px solid #dee5ef;
    }
`

export const StyledStreetAddressInputWrap = styled.article`
    width: ${pxToRem(400)};
    margin-right: ${pxToRem(40)};
`

export const StyledNumberInputWrap = styled.article`
    width: ${pxToRem(220)};
`

export const SelectStyledStateWrap = styled.article`
    width: ${pxToRem(240)};
`

export const StyledInputWrap = styled.article`
    width: ${pxToRem(220)};
    margin-right: ${pxToRem(40)};
`

export const StyledAddButton = styled.button`
    border: 0;
    background-color: transparent;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2281c4;

    :disabled {
        color: #a4b0c0;
        cursor: default;
    }

    cursor: pointer;
`

export const StyledPCPToolTip = styled.p`
    background-color: #293854;
    padding: 5px;
    border-radius: 4px;
    margin-top: 10px;
    width: 70px;
    position: absolute;
    top: -40px;
    left: -20px;
    color: #ffffff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    visibility: hidden;
`

export const StyledDeleteButton = styled.button<{ size: string }>`
    background-color: transparent;
    border: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: right;
    position: relative;
    top: 40px;
    left: 75px;

    :hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
        border-radius: 20px;
        cursor: pointer;
        p {
            visibility: visible;
        }
    }

    ${(props) => {
        if (props.size) {
            return css`
                width: ${props.size}px;
                height: ${props.size}px;
            `
        }
    }}
`

export interface IDeleteAddressProps {
    index: number
    handleClick: (index: number) => void
}

export const customAddressTypeDropdownStyles = {
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        border: '1px solid #DEE5EF',
        width: '220px',
        marginRight: '40px',
        backgroundColor: state.isDisabled ? '#dee5ef' : '#ffffff'
    }),
    option: (baseStyles: any) => ({
        ...baseStyles,
        height: '50px',
        fontFamily: 'Inter',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#293854',
        display: 'flex',
        alignItems: 'center'
    }),
    singleValue: (baseStyles: any) => ({
        ...baseStyles,
        fontFamily: 'Inter',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#293854',
        padding: '0px',
        paddingLeft: '18px'
    }),
    menuList: (baseStyles: any) => ({
        ...baseStyles,
        padding: '0px'
    }),
    valueContainer: (baseStyles: any) => ({
        ...baseStyles,
        padding: '0px'
    }),
    input: (baseStyles: any) => ({
        ...baseStyles,
        padding: '0px',
        paddingLeft: '6px',
        fontFamily: 'Inter'
    }),
    placeholder: (baseStyles: any) => ({
        ...baseStyles,
        fontFamily: 'Inter',
        paddingLeft: '6px',
        color: light_gray3
    })
}

export const StyledAddressEditContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 50px;
    margin-bottom: 30px;
    position: relative;

    h3 {
        margin: 0;
        margin-right: 16px;

        :after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            display: inline-block;
            width: 40px;
            height: 3px;
            background-color: #293854;
        }
    }

    button {
        margin: 0;
    }
`
