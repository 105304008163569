import { alertFileLimitExceeded } from 'redux/slices/pointsInCare'
import { dispatch } from 'redux/store'

export const FileLimit = 100000000

export const isFileOutsideMaxSizeLimit = (file: File, showToast: boolean = true) => {
    if (file?.size > FileLimit) {
        if (showToast) {
            dispatch(alertFileLimitExceeded()).then(() => {})
        }

        return true
    }

    return false
}
