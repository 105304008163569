import { FC, useState } from 'react'
import { ENavTab, INavItem, NavConfigItems } from './config'
import {
    StyledMenuButton,
    StyledMenuButtonContainer,
    StyledNavBarList,
    StyledNavBarListItem,
    StyledUserInfoHeader
} from 'pages/hcp/members/information/NavBar/index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { StyledEligibilityBadgeContainer } from 'pages/hcp/members/information/Personal/index.style'
import { StatusBadge } from 'components/StatusBadge'
import { documentIcon, documentNewIcon } from './icons'
import { useAppSelector } from 'redux/store'
import { UserState } from 'redux/slices/user'

interface INavBarProps {
    iniTab?: ENavTab
    onSelect: (selectedTab: ENavTab) => void
}

interface INavBarState {
    navItems: INavItem[]
    isMenuHidden: boolean
    currentTab: ENavTab
}

const NavBar: FC<INavBarProps> = ({ iniTab, onSelect }) => {
    const user = useAppSelector((state: { user: UserState }) => {
        return state.user.details
    })

    const getNavItems = () => {
        let navItems: INavItem[] = []
        if (Object.keys(user).length > 0) {
            // get a sublist from [NavConfigItems] filtered by user role
            navItems = [...NavConfigItems]

            const reportItem = navItems.findIndex((i) => i.title === ENavTab.REPORTS)
            if (reportItem > 0) {
                user.newReportCount > 0
                    ? (navItems[reportItem].icon = documentNewIcon)
                    : (navItems[reportItem].icon = documentIcon)
            }
        }
        return navItems
    }

    const [state, setState] = useState<INavBarState>({
        navItems: getNavItems(),
        isMenuHidden: false,
        currentTab: iniTab == null ? ENavTab.GENERAL : iniTab
    })

    const handleNavBarItem = (selectedItem: ENavTab) => {
        onSelect(selectedItem)
        setState({
            ...state,
            currentTab: selectedItem,
            isMenuHidden: !state.isMenuHidden
        })
    }

    const handleMenu = () => {
        setState({
            ...state,
            isMenuHidden: !state.isMenuHidden
        })
    }

    return (
        <>
            <StyledUserInfoHeader>
                <Typography type={TYPOGRAPHY_TYPES.h2}>
                    {user.lastName}, {user.firstName}
                </Typography>
                <StyledEligibilityBadgeContainer>
                    <StatusBadge status={user.state ?? user.userState} />
                </StyledEligibilityBadgeContainer>
            </StyledUserInfoHeader>
            <StyledNavBarList isVisible={state.isMenuHidden}>
                {state.navItems.map((item: INavItem, idx: number) => {
                    return (
                        <>
                            <StyledNavBarListItem
                                onClick={() => handleNavBarItem(item.title)}
                                key={`navBarItem-${idx}`}
                                isActive={state.currentTab === item.title}
                                selectedLabel={item.title}
                            >
                                <CustomSvgIcon iconSet={{ icon: item.icon }} svg></CustomSvgIcon>
                                {item.title}
                            </StyledNavBarListItem>
                        </>
                    )
                })}
            </StyledNavBarList>
            <StyledMenuButtonContainer>
                {state.isMenuHidden && <Typography type={TYPOGRAPHY_TYPES.p}>{state.currentTab}</Typography>}
                <StyledMenuButton onClick={handleMenu}>Menu</StyledMenuButton>
            </StyledMenuButtonContainer>
        </>
    )
}
export default NavBar
