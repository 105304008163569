import { FC, useEffect, useState } from 'react'
import {
    StyledDashboardContainer,
    StyledDashboardHeader,
    StyledDashboardLastRowContainer,
    StyledDashboardMemberOutreachContainer,
    StyledLastUpdatedContainer,
    StyledNpiDropdownContainer,
    StyledTopOppsWrapper,
    StyledWelcomeMessage
} from './index.style'
import { useDispatch, useSelector } from 'redux/store'
import { fetchProviderNpis, Pulse8State, setCurrentNpi, setCurrentNpiYear } from 'redux/slices/pulse8'
import { ERoles } from '../../../models/enums/role'
import { IBannerIncentive } from 'pages/hcp/users/create/index.models'
import {
    clearTopIncentives,
    fetchBannerIncentive,
    fetchMemberOutreachSummary,
    fetchTopIncentives,
    IncentiveState
} from 'redux/slices/incentive'
import YTDWidget from './YTDWidget'
import TopOpps from './TopOpps'
import MemberOutreachWidget from './MemberOutreach'
import NpiSelect from 'components/NpiSelect'
import useCurrentUser from 'hooks/useCurrentUser'
import moment from 'moment'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { StyledCalendarDropDownContainer, StyledCalendarIconForIncentives } from './YTDWidget/index.style'
import DropDownList from 'components/DropDownList'
import { calendarIncentivesDropDownListStyleParams } from 'components/TaxIdProviderInfo/index.style'
import smallCalendar from 'assets/smallCalendar.svg'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import usePostCustomEvent from 'hooks/usePostCustomEvent'

interface IDashboardProps {}

const Dashboard: FC<IDashboardProps> = () => {
    const currentUser = useCurrentUser()
    const dispatch = useDispatch()

    const { currentNpi, currentNpiYears, providerNpis } = useSelector((state: { pulse8: Pulse8State }) => state.pulse8)

    const selectedYear = currentNpi && currentNpiYears[currentNpi] ? currentNpiYears[currentNpi] : null

    const [lastNpi, setLastNpi] = useState('')
    const [selectedLOB, setSelectedLOB] = useState<string>()
    const [yearsDropDownData, setYearsDropDownData] = useState<{ label: string; value: number }[]>([])
    const { years } = useSelector((state: { incentive: IncentiveState }) => state.incentive)
    const yearsOfService = (currentNpi && years[currentNpi] ? years[currentNpi] : []) as number[]
    const YTDdata: IBannerIncentive = useSelector(
        (state: { incentive: IncentiveState }) => state.incentive?.bannerIncentive
    )

    const refreshIncentives = (npi: string, year: number | null) => {
        setLastNpi(currentNpi)
        dispatch(fetchBannerIncentive({ npi: currentNpi, year: year }))
        dispatch(clearTopIncentives())
        dispatch(fetchTopIncentives({ npi: currentNpi, year: year }))
        dispatch(fetchMemberOutreachSummary({ npi: currentNpi, year: year, maxNumberOfSummaries: 5 }))
    }

    useEffect(() => {
        if (currentNpi && currentNpi.length > 0 && currentNpi != lastNpi) {
            refreshIncentives(currentNpi, selectedYear)
        }
    }, [dispatch, currentNpi])

    useEffect(() => {
        if (dispatch) {
            dispatch(fetchProviderNpis())
        }
    }, [dispatch, currentUser])

    useEffect(() => {
        if (currentNpi && yearsOfService.length > 0) {
            setYearsDropDownData(yearsOfService.map((year) => ({ label: year.toString(), value: year })))
            if (!selectedYear || !yearsOfService.includes(selectedYear)) {
                dispatch(
                    setCurrentNpiYear({
                        npi: currentNpi,
                        year: Math.max(...yearsOfService)
                    })
                )
            }
        }
    }, [currentNpi, yearsOfService, selectedYear])

    const changeProviderEvent = usePostCustomEvent(pageTitle.Incentives, customEvents.ChangeProvider)
    const setNpi = (npi: string) => {
        changeProviderEvent()
        dispatch(setCurrentNpi(npi))
    }

    const lastUpdated: string = YTDdata.lastUpdated
    const changeYearEvent = usePostCustomEvent(pageTitle.Incentives, customEvents.ChangeYear)
    const setSelectedValue = (value: any) => {
        changeYearEvent()
        dispatch(setCurrentNpiYear({ npi: currentNpi, year: value }))
    }

    return (
        <StyledDashboardContainer>
            <StyledDashboardHeader>
                <StyledWelcomeMessage>{`Welcome, ${currentUser.fullName}!`}</StyledWelcomeMessage>
                <StyledCalendarDropDownContainer>
                    <StyledCalendarIconForIncentives image={smallCalendar} />
                    <DropDownList
                        key="year-key"
                        placeholder="Year"
                        options={yearsDropDownData}
                        selectedValue={selectedYear}
                        setSelectedValue={(value) => setSelectedValue(value)}
                        styleParams={calendarIncentivesDropDownListStyleParams}
                    />
                </StyledCalendarDropDownContainer>
                {currentUser.primaryRole === ERoles.OfficeAdmin && providerNpis.length > 0 && (
                    <StyledNpiDropdownContainer>
                        <NpiSelect
                            key="npi-select-key"
                            providerNpis={providerNpis}
                            selectedNpi={currentNpi}
                            setSelectedNpi={setNpi}
                        />
                    </StyledNpiDropdownContainer>
                )}
            </StyledDashboardHeader>
            <StyledLastUpdatedContainer>
                <Typography type={TYPOGRAPHY_TYPES.h3}>{`Last Updated on: ${moment(lastUpdated).format(
                    'MM/DD/YYYY'
                )}`}</Typography>
            </StyledLastUpdatedContainer>
            <YTDWidget
                npi={currentNpi}
                refreshIncentives={refreshIncentives}
                setSelectedYear={(year) => dispatch(setCurrentNpiYear({ npi: currentNpi, year }))}
                setSelectedLob={setSelectedLOB}
                selectedYear={selectedYear}
                selectedLob={selectedLOB}
            />
            <StyledTopOppsWrapper>
                <TopOpps npi={currentNpi} />
            </StyledTopOppsWrapper>
            {(currentUser.primaryRole === ERoles.Provider || providerNpis.length > 0) && (
                <StyledDashboardLastRowContainer>
                    <StyledDashboardMemberOutreachContainer>
                        <MemberOutreachWidget npi={currentNpi} />
                    </StyledDashboardMemberOutreachContainer>
                </StyledDashboardLastRowContainer>
            )}
        </StyledDashboardContainer>
    )
}

export default Dashboard
