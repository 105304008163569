import { IDropDownListStyleParams } from 'components/DropDownList/index.style'
import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme/constants'
import { DEVICE, pxToRem } from 'utilities/style'
import { number } from 'yargs'

const { grey, dark_gray } = UI_COLORS

export const StyledStatusBadge = styled.article`
    width: ${pxToRem(70)};
    height: ${pxToRem(24)};
`

export const StyledNPIErrorMessage = styled.article`
    color: #b11b1b;
    font-family: 'Inter', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    position: relative;
`

export const StyledLocationErrorMessage = styled.article`
    color: #b11b1b;
    font-family: 'Inter', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    position: relative;
`

export const npiDropDownListStyleParams: IDropDownListStyleParams = {
    width: 255,
    fontSize: '14px',
    paddingTop: '14px',
    paddingLeft: '0',
    marginTop: '20px'
}

export const locationDropDownListStyleParams: IDropDownListStyleParams = {
    width: 400,
    fontSize: '14px',
    paddingTop: '14px',
    paddingLeft: '9px',
    marginTop: '0px'
}

export const languageDropDownListStyleParams: IDropDownListStyleParams = {
    width: 400,
    fontSize: '16px',
    paddingTop: '0px',
    paddingLeft: '9px !important',
    marginTop: '0px',
    indicatorMarginTop: '-85px',
    placeholderTop: '-10px !important',
    singleValueMarginTop: '-10px',
    singleValueFontSize: '16px',
    singleValueFontWeight: '400',
    singleValueColor: UI_COLORS.black,
    placeholderColor: UI_COLORS.light_gray3,
    placeholderFontSize: '16px',
    placeholderFontWeight: '500'
}

export const addressDropDownListStyleParams: IDropDownListStyleParams = {
    width: 400,
    fontSize: '16px',
    paddingTop: '0px',
    paddingLeft: '9px !important',
    marginTop: '0px',
    indicatorMarginTop: '-80px',
    placeholderTop: '-10px !important',
    singleValueMarginTop: '-5px',
    singleValueFontSize: '16px',
    singleValueFontWeight: '400',
    singleValueColor: UI_COLORS.black,
    placeholderColor: UI_COLORS.light_gray3,
    placeholderFontSize: '16px',
    placeholderFontWeight: '500'
}

export const stateDropDownListStyleParams: IDropDownListStyleParams = {
    width: 200,
    fontSize: '16px',
    paddingTop: '0px',
    paddingLeft: '9px !important',
    marginTop: '0px',
    indicatorMarginTop: '-80px',
    placeholderTop: '-10px !important',
    singleValueMarginTop: '-5px',
    singleValueFontSize: '16px',
    singleValueFontWeight: '400',
    singleValueColor: UI_COLORS.black,
    placeholderColor: UI_COLORS.light_gray3,
    placeholderFontSize: '16px',
    placeholderFontWeight: '500'
}

export const addressTypeDropDownListStyleParams: IDropDownListStyleParams = {
    width: 200,
    fontSize: '14px',
    paddingTop: '0px',
    paddingLeft: '9px !important',
    marginTop: '0px',
    indicatorMarginTop: '-70px',
    placeholderTop: '0px !important',
    singleValueMarginTop: '-10px'
}

export const addressTypeDropDownForPCPListStyleParams: IDropDownListStyleParams = {
    width: 225,
    fontSize: '14px',
    paddingTop: '0px',
    paddingLeft: '9px !important',
    marginTop: '0px',
    indicatorMarginTop: '-80px',
    placeholderTop: '0px !important',
    singleValueMarginTop: '-5px'
}

export const calendarDropDownListStyleParams: IDropDownListStyleParams = {
    width: 130,
    color: '#394A64',
    fontSize: '14px',
    singleValueColor: '#394A64',
    singleValueFontWeight: '600',
    paddingTop: '0px',
    paddingLeft: '9px !important',
    marginTop: '0px',
    indicatorMarginTop: '-40px',
    placeholderTop: '0px !important',
    singleValueMarginTop: '14px',
    singleValueMarginLeft: '30px'
}

export const calendarIncentivesDropDownListStyleParams: IDropDownListStyleParams = {
    width: 130,
    color: '#394A64',
    fontSize: '14px',
    singleValueColor: '#394A64',
    singleValueFontWeight: '600',
    paddingTop: '0px',
    paddingLeft: '9px !important',
    marginTop: '0px',
    indicatorMarginTop: '-40px',
    placeholderTop: '15px !important',
    placeholderLeft: '35px !important',
    singleValueMarginTop: '14px',
    singleValueMarginLeft: '30px',
    zIndex: '0'
}

export const LOBIncentivesDropDownListStyleParams: IDropDownListStyleParams = {
    width: 220,
    color: '#394A64',
    fontSize: '14px',
    singleValueColor: '#394A64',
    singleValueFontWeight: '600',
    paddingTop: '12px',
    paddingLeft: '35px !important',
    marginTop: '0px',
    indicatorMarginTop: '-40px',
    placeholderTop: '0px !important',
    singleValueMarginTop: '14px',
    singleValueMarginLeft: '30px'
}

export const relationshipDropDownListStyleParams: IDropDownListStyleParams = {
    width: 400,
    fontSize: '16px',
    paddingTop: '0px',
    paddingLeft: '9px !important',
    marginTop: '0px',
    indicatorMarginTop: '-85px',
    placeholderTop: '-10px !important',
    singleValueMarginTop: '-10px',
    singleValueFontWeight: '400',
    singleValueColor: UI_COLORS.black,
    placeholderColor: UI_COLORS.light_gray3,
    placeholderFontSize: '16px',
    placeholderFontWeight: '500'
}

export const StyledAddUserLink = styled.button`
    border: 0;
    background-color: transparent;
    margin: 0;
    text-align: left;
    width: 250px;
    height: 18px;
    position: relative;
    top: -10px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    color: #2281c4;
    cursor: pointer;

    :disabled {
        color: ${grey};
        cursor: default;
    }
`

export const StyledTxtfieldWrap = styled.article`
    width: 100%;

    margin-right: 20px;
    margin-top: 30px;
    ${DEVICE.tablet} {
        width: 250px;
    }

    input {
        ::placeholder {
            font-size: 14px;
        }
    }
`
export const TaxIdHorizontalLine = styled.div`
    ${DEVICE.tablet} {
        &:after {
            content: '';
            position: absolute;
            transform: TranslateX(-100%);
            border-bottom: 2px solid #2281c4;
            width: 20px;
            height: 70px;
        }
    }
`

export const TaxIdHalfHorizontalLine = styled.div<{ customHeight: string }>`
    ${DEVICE.tablet} {
        &:before {
            content: '';
            position: absolute;
            transform: TranslateX(-100%);
            border-bottom: 2px solid #2281c4;
            width: 10px;
            height: ${({ customHeight }) => (customHeight ? customHeight : '185px')};
        }
    }
`

export const TaxIdVerticalLine = styled.div<{ customHeight: string }>`
    ${DEVICE.tablet} {
        &:before {
            content: '';
            position: absolute;
            transform: TranslateX(-140%);
            margin-top: 45px;
            border-right: 2px solid #2281c4;
            width: 20px;
            height: ${({ customHeight }) => (customHeight ? customHeight : '140px')};
        }
    }
`

export const TaxIdAdditionalVerticalLine = styled.div<{ customHeight: string }>`
    ${DEVICE.tablet} {
        &:before {
            content: '';
            position: absolute;
            transform: TranslateX(-140%);
            margin-top: 40px;
            border-right: 2px solid #2281c4;
            width: 20px;
            height: ${({ customHeight }) => (customHeight ? customHeight : '145px')};
        }
    }
`

export const NpiHalfHorizontalLine = styled.div`
    ${DEVICE.tablet} {
        &:after {
            content: '';
            position: absolute;
            transform: TranslateX(-100%);
            border-bottom: 2px solid #2281c4;
            width: 8px;
            height: 144px;
        }
    }
`

export const NpiHorizontalLine = styled.div`
    ${DEVICE.tablet} {
        &:after {
            content: '';
            position: absolute;
            transform: TranslateX(-100%);
            border-bottom: 2px solid #2281c4;
            width: 15px;
            height: 45px;
        }
    }
`

export const NpiVerticalLine = styled.div`
    ${DEVICE.tablet} {
        &:after {
            content: '';
            position: absolute;
            transform: TranslateX(-140%);
            margin-top: 45px;
            border-right: 2px solid #2281c4;
            width: 15px;
            height: 100px;
        }
    }
`

export const StyledTaxIdWrap = styled.article`
    width: 100%;

    ${DEVICE.tablet} {
        width: 250px;
        margin-right: 20px;

        select {
            margin-bottom: 30px;
        }
    }
`
export const StyledLocationWrap = styled.article`
    width: 100%;
    margin-bottom: 31px;

    ${DEVICE.tablet} {
        width: 430px;
    }
`

export const StyledLocationPlaceHolder = styled.article`
    width: 30px;
`

export const StyledLocationGroup = styled.article`
    display: flex;
    flex-direction: column;

    ${DEVICE.tablet} {
        display: flex;
        flex-direction: row;
    }
`

export const StyledProviderInfoWrap = styled.article`
    display: flex;
    flex-direction: column;

    ${DEVICE.tablet} {
        display: flex;
        flex-direction: row;
    }
`
export const StyledNPIWrap = styled.article``

export const StyledNPIandLocationWrap = styled.article`
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    ${DEVICE.tablet} {
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
    }
`
export const StyledNPILink = styled.button`
    margin-top: -10px;

    border: 0;
    background-color: transparent;

    text-align: left;

    width: 221px;
    height: 18px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: #2281c4;

    display: block;
    transform: translateY(-20px);
    cursor: pointer;

    :disabled {
        color: ${grey};
        cursor: default;
    }

    ${DEVICE.tablet} {
        margin: -10px;
    }
`

export const StyledMainContainer = styled.article`
    margin-top: -30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #dee5ef;
    padding-bottom: 30px;
`
export const StyledHeadersRowContainer = styled.article`
    display: none;
    ${DEVICE.tablet} {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #dee5ef;
        padding-bottom: 15px;
        margin-bottom: 27px;

        > p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #293854;

            :nth-child(1) {
                width: 250px;
                margin-right: 20px;
            }

            :nth-child(2) {
                width: 250px;
                margin-right: 20px;
            }
        }
    }
`

export const StyledDeleteNpiButton = styled.button<{ size: string }>`
    background-color: transparent;
    border: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: right;
    position: relative;
    top: 33px;
    left: 15px;

    cursor: pointer;

    :hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
        border-radius: 20px;
    }

    p {
        background-color: #293854;
        padding: 5px 0;
        border-radius: 4px;
        width: 70px;
        position: absolute;
        top: -40px;
        right: 10;
        color: #ffffff;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }

    ${(props) => {
        if (props.size) {
            return css`
                width: ${props.size}px;
                height: ${props.size}px;
            `
        }
    }}
`

export const StyledEditLocationButton = styled.button`
    width: ${pxToRem(29)};
    height: ${pxToRem(29)};
    margin-left: 10px;
    margin-right: 25px;
    background-color: transparent;
    border: 0;
    flex-direction: column;

    cursor: pointer;

    :hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
        border-radius: 20px;

        p {
            visibility: visible;
        }
    }

    p {
        visibility: hidden;
        background-color: #293854;
        padding: 5px 0;
        border-radius: 4px;
        width: 70px;
        position: absolute;
        top: -10px;
        right: 25px;
        color: #ffffff;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }
`

export const StyledDeleteSecondaryLocationButton = styled.button<{ size: string }>`
    background-color: transparent;
    border: 0;
    flex-direction: column;
    align-items: center;
    width: ${pxToRem(48)};
    height: ${pxToRem(48)};

    cursor: pointer;

    :hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
        border-radius: 20px;
        p {
            visibility: visible;
        }
    }

    ${(props) => {
        if (props.size) {
            return css`
                width: ${props.size}px;
                height: ${props.size}px;
            `
        }
    }}

    p {
        visibility: hidden;
        background-color: #293854;
        padding: 5px 0;
        border-radius: 4px;
        width: 70px;
        position: absolute;
        top: -10px;
        right: -25px;
        color: #ffffff;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }
`

export const StyledCenterSecondaryEditLocationIcon = styled.div`
    position: relative;
    top: 50%;
    transform: translateY(-50%);
`

export const StyledDeletePlaceHolder = styled.div`
    width: 30px;
`

export const StyledLocationSelectContainer = styled.article`
    select {
        margin-top: 0;
    }

    label {
        margin: 0px;
        margin-bottom: 5px;
        display: block;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: ${pxToRem(12)};
        color: ${dark_gray};
    }
`

// export const ConnectingBarComponent = styled.div`
//   height: 50px;
//   width: 100px;
//   position: relative;
//   &:after {
//     // whatever you want with normal CSS syntax. Here, a custom orange line as example
//     content: '';
//     width: 60px;
//     height: 4px;
//     background: blue;
//     position: absolute;
//     bottom: 0;
//     left: 0;
//   }
// `
