import {
    StyledAWVDigitalAside,
    StyledAWVDigitalAsideContainer,
    StyledAWVDigitalContainer,
    StyledAWVDigitalContainerWrap,
    StyledAWVDigitalLogoImage,
    StyledAWVDigitalNavBarContainer,
    StyledAWVDigitalToC,
    StyledAWVDigitalUserInfoHeader,
    StyledCloseButton,
    StyledSubmitButton,
    StyledSubmitButtonContainer
} from './index.style'
import { FC, useEffect, useLayoutEffect, useState } from 'react'
import SectionTitle from './SectionTitle'
import SectionDescription from './SectionDescription'
import { useForm, FormProvider, set } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AWVDigitalValidationSchema } from './validationSchema'
import { renderRadioSelection, renderVerticalRadioSelection } from './RadioSelection'
import { renderTextArea } from './TextArea'
import { renderCommentedMultipleChoiceList } from './CommentedMultipleChoiceList'
import { useSelector } from 'react-redux'
import {
    MemberProfileState,
    getActiveAwvElectronicFormTypes,
    getAwvAttestationTemplate,
    getAwvElectronicForm,
    getAwvElectronicFormType,
    saveAwvFormElectronicResponse,
    setDefaultElectronicFormTypeId,
    submitAwvFormElectronicResponse
} from 'redux/slices/memberProfile'
import { dispatch, useAppSelector } from 'redux/store'
import { renderDropDownList, renderGenderPicker } from './DropDownList'
import { renderDropDownListBoolean } from './DropDownListBoolean'
import { renderLanguagePicker } from './LanguagePicker'
import { renderDateOfBirthPicker, renderDatePicker } from './DatePicker'
import { renderPhoneNumberField } from './PhoneNumberField'
import { renderAgeField } from './AgeField'
import { renderHeightField } from './HeightField'
import { renderNumericField } from './NumericField'
import { renderBodyMassIndexField } from './BodyMassIndexField'
import { renderPatientNameField, renderTextField } from './TextField'
import { ISectionElement } from './index.models'
import { renderTwoColumnPanel } from './TwoColumnPanel'
import { renderPainAssessmentSelection } from './PainAssessmentSelection'
import { renderCommentedScoredMultipleChoiceList } from './CommentedScoredMultipleChoiceList'
import { renderNullableRadioBoolean, renderRadioBoolean } from './RadioBoolean'
import { renderStandaloneLabel } from './StandaloneLabel'
import { renderRadioBooleanList } from './RadioBooleanList'
import { renderScoredMultipleChoiceList } from './ScoredMultipleChoiceList'
import { evaluateCondition } from './ConditionEvaluator'
import { renderDatedBooleanList } from './DatedBooleanList'
import { renderObjectList } from './ObjectList'
import { renderCheckBoxList } from './CheckBoxList'
import { renderOtherConditionsList } from './OtherConditionsList'
import { renderRepeater } from './Repeater'
import Spinner from 'components/Spinner'
import useDebounce from 'hooks/useDebounce'
import { useParams } from 'react-router-dom'
import MessageBox from 'components/MessageBox'
import { UI_COLORS } from 'theme/constants'
import useHeadsObserver from 'hooks/useHeadsObserver'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { renderPreventiveScreeningsList } from './PreventiveScreeningsList'
import { renderIcdDiagnosisPicker } from './IcdDiagnosisPicker'
import { renderHccCodeDescriptionPicker } from './HccCodeDescriptionPicker'
import { renderDiagnosisHeading } from './DiagnosisHeading'
import AWVFeedback from '../information/AWV/Feedback'
import { FeedbackModalDialog } from '../information/PointsInCare/ReviewTable/FeedbackDialog'
import { hideMainWindowScrollbars } from 'utilities/style'
import {
    FeedbackState,
    addAwvFeedback,
    getAwvFeedback,
    hasNewAwvFeedback,
    markAwvFeedbackRead
} from 'redux/slices/feedback'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import usePostCustomEvent from 'hooks/usePostCustomEvent'
import useCurrentUser from 'hooks/useCurrentUser'
import { Pulse8State, fetchProviderNpis } from 'redux/slices/pulse8'
import { ERoles } from 'models/enums/role'
import NonPCPSubmissionWarning from '../information/AWV/NonPCPSubmissionWarning'
import ValidationToggle from './ValidationToggler'
import { getTopLevelInErrorElementNames, isSectionInError } from './utils'
import { UiState, getAwvValidationState, getAwvValidationStateKey, setAwvValidationState } from 'redux/slices/ui'
import AttestationModal from '../information/AWV/AttestationModal'
import { unwrapResult } from '@reduxjs/toolkit'
import PCPCredentialModal from '../information/AWV/PCPCredentialModal'
import useMemberProfileLookupData from 'hooks/useMemberProfileLookupData'
import { renderSectionElementGroup } from './SectionElementGroup'
import ElectronicFormTypePicker from 'components/ElectronicFormTypePicker'
import FormTypeButton from './FormTypeButton'
import { renderGapsInCareViewer } from './GapsInCareViewer'

interface IElectronicAnnualWellnessVisitFormProps {
    readOnly: boolean
}

export const ElectronicAnnualWellnessVisitForm: FC<IElectronicAnnualWellnessVisitFormProps> = ({ readOnly }) => {
    const { memberId, npi, year } = useParams()

    const { activeAwvElectronicFormTypes, defaultElectronicFormTypeId, electronicFormTypeId, electronicAwvForm } =
        useSelector((state: { memberProfile: MemberProfileState }) => state.memberProfile)

    const [selectFormTypeShown, setSelectFormTypeShown] = useState(false)
    const [changeFormTypeShown, setChangeFormTypeShown] = useState(false)
    const [formTypeChanging, setFormTypeChanging] = useState(false)

    const { awvId, form, response, validationErrors } = electronicAwvForm

    const memberProfileLookupData = useMemberProfileLookupData()

    const validationErrorCount = validationErrors ? Object.keys(validationErrors).length : null

    const { awvValidationState } = useSelector((state: { ui: UiState }) => state.ui)
    const validating = awvValidationState[getAwvValidationStateKey(memberId, npi, year)] ?? false

    const [selectedFormTypeId, setSelectedFormTypeId] = useState(defaultElectronicFormTypeId)
    const [awvFormTypeLoaded, setAwvFormTypeLoaded] = useState(false)
    const [awvFormLoaded, setAwvFormLoaded] = useState(false)
    const [populated, setPopulated] = useState(false)

    const [statusId, setStatusId] = useState(null)

    const [submitting, setSubmitting] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [validationFailed, setValidationFailed] = useState(false)

    const methods = useForm({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(AWVDigitalValidationSchema)
    })

    const formTypeId = electronicFormTypeId ?? selectedFormTypeId

    const formSections = form?.sections.filter((x) => x.printOnly !== true)

    useLayoutEffect(() => {
        if (!readOnly && !activeAwvElectronicFormTypes) {
            dispatch(getActiveAwvElectronicFormTypes())
        }
    }, [readOnly, activeAwvElectronicFormTypes])

    useLayoutEffect(() => {
        if (!readOnly) {
            if (!awvFormTypeLoaded) {
                dispatch(
                    getAwvElectronicFormType({
                        memberId,
                        npi,
                        year
                    })
                ).then(() => {
                    setAwvFormTypeLoaded(true)
                })
            }
        }
    }, [readOnly, awvFormTypeLoaded])

    useLayoutEffect(() => {
        if (
            !readOnly &&
            activeAwvElectronicFormTypes &&
            awvFormTypeLoaded &&
            !electronicFormTypeId &&
            !selectedFormTypeId
        ) {
            setSelectFormTypeShown(true)
        }
    }, [readOnly, activeAwvElectronicFormTypes, awvFormTypeLoaded, electronicFormTypeId, selectedFormTypeId])

    useLayoutEffect(() => {
        if (readOnly || (awvFormTypeLoaded && (electronicFormTypeId || selectedFormTypeId) && !awvFormLoaded)) {
            dispatch(
                getAwvElectronicForm({
                    memberId,
                    npi,
                    year,
                    overrideMemberInformation: readOnly ? false : true,
                    defaultFormTypeId: formTypeId
                })
            ).then(() => setAwvFormLoaded(true))
            dispatch(getAwvValidationState({ memberId, npi, year }))
        }
    }, [readOnly, awvFormTypeLoaded, electronicFormTypeId, selectedFormTypeId, awvFormLoaded])

    useEffect(() => {
        if (awvFormLoaded && response) {
            methods.reset({ ...methods.watch(), ...response })
            setPopulated(true)
        }
    }, [awvFormLoaded, response, methods])

    useEffect(() => {
        methods.clearErrors()
        if (validationErrors && populated && !readOnly && validating) {
            for (const fieldName in validationErrors) {
                methods.setError(fieldName, { message: validationErrors[fieldName] }, { shouldFocus: false })
            }
        }
    }, [validationErrors, methods, populated, readOnly, validating])

    const saveAwvFormElectronicResponseArgs = useDebounce(
        populated
            ? JSON.stringify({
                  memberId,
                  npi,
                  year,
                  formTypeId,
                  body: methods.watch(),
                  statusId
              })
            : null,
        1500
    )

    useEffect(() => {
        if (!readOnly && !formTypeChanging && !submitting && !submitted && saveAwvFormElectronicResponseArgs) {
            dispatch(saveAwvFormElectronicResponse(JSON.parse(saveAwvFormElectronicResponseArgs))).then((x) => {
                if (x.type === saveAwvFormElectronicResponse.fulfilled.type) {
                    if (x.payload.awvSummary.awvId !== feedbackAwvId) {
                        setLoadedAwvId(x.payload.awvSummary.awvId)
                    }
                    setStatusId(x.payload.awvSummary.statusId)
                }
            })
        }
    }, [readOnly, formTypeChanging, submitting, submitted, saveAwvFormElectronicResponseArgs])

    const elementRenderers: any = {
        ageField: renderAgeField,
        bodyMassIndexField: renderBodyMassIndexField,
        checkBoxList: renderCheckBoxList,
        commentedMultipleChoiceList: renderCommentedMultipleChoiceList,
        commentedScoredMultipleChoiceList: renderCommentedScoredMultipleChoiceList,
        datedBooleanList: renderDatedBooleanList,
        dateOfBirthPicker: renderDateOfBirthPicker,
        datePicker: renderDatePicker,
        diagnosisHeading: renderDiagnosisHeading,
        dropDownList: renderDropDownList,
        dropDownListBoolean: renderDropDownListBoolean,
        gapsInCareViewer: renderGapsInCareViewer,
        genderPicker: renderGenderPicker,
        hccCodeDescriptionPicker: renderHccCodeDescriptionPicker,
        heightField: renderHeightField,
        icdDiagnosisPicker: renderIcdDiagnosisPicker,
        languagePicker: renderLanguagePicker,
        nullableRadioBoolean: renderNullableRadioBoolean,
        numericField: renderNumericField,
        objectList: renderObjectList,
        otherConditionsList: renderOtherConditionsList,
        painAssessmentSelection: renderPainAssessmentSelection,
        patientNameField: renderPatientNameField,
        phoneNumberField: renderPhoneNumberField,
        preventiveScreeningsList: renderPreventiveScreeningsList,
        radioBoolean: renderRadioBoolean,
        radioBooleanList: renderRadioBooleanList,
        radioSelection: renderRadioSelection,
        repeater: renderRepeater,
        scoredMultipleChoiceList: renderScoredMultipleChoiceList,
        sectionElementGroup: renderSectionElementGroup,
        standaloneLabel: renderStandaloneLabel,
        textArea: renderTextArea,
        textField: renderTextField,
        twoColumnPanel: renderTwoColumnPanel,
        verticalRadioSelection: renderVerticalRadioSelection
    }

    const disableInReadOnlyModeOptOut: any = {
        gapsInCareViewer: true
    }

    const renderSectionElement = (element: ISectionElement, key: string, parentName?: string) => {
        const renderer = elementRenderers[element.$type] as (
            element: ISectionElement,
            key: string,
            renderSectionElement: (element: ISectionElement, key: string, parentName?: string) => JSX.Element,
            parentName?: string
        ) => JSX.Element
        return (
            evaluateCondition(element.condition, methods.watch, parentName) &&
            renderer &&
            renderer(element, key, renderSectionElement, parentName)
        )
    }

    const currentUser = useCurrentUser()
    const provider = useAppSelector((state: { pulse8: Pulse8State }) =>
        state.pulse8.providerNpis.find((x) => x.npi === npi)
    )
    useEffect(() => {
        if (!provider) {
            dispatch(fetchProviderNpis())
        }
    }, [provider])
    const [isSubmitByNonPCP, setSubmitByNonPCP] = useState(false)

    // #region Attestation
    const [isAttesting, setIsAttesting] = useState(false)
    const [isAttestDataReady, setIsAttestDataReady] = useState(false)
    const [attestation, setAttestation] = useState<{
        templateId: number
        dateOfService: string | Date | null
        text: string
    }>({
        templateId: 0,
        dateOfService: null,
        text: ''
    })
    const setAttestedDOS = (d: Date) => {
        setAttestation((state) => ({
            ...state,
            dateOfService: d
        }))
    }

    const isPCPCredentialAvailable = provider?.defaultCredential != null

    useEffect(() => {
        if (isAttesting && !isAttestDataReady && isPCPCredentialAvailable) {
            dispatch(getAwvAttestationTemplate())
                .then(unwrapResult)
                .then((result) => {
                    const providerName = `${provider.firstName} ${provider.lastName}`
                    const credential = memberProfileLookupData?.credentials.find(
                        (x) => x.id === provider.defaultCredential
                    ).name
                    setAttestation((state) => ({
                        ...state,
                        templateId: result.attestationId,
                        text: result.text
                            .replace('{ProviderName}', providerName)
                            .replace('{DefaultCredential}', credential),
                        dateOfService: methods.getValues('dateOfService')
                    }))
                })
                .then(() => setIsAttestDataReady(true))
        }
    }, [isAttesting, isAttestDataReady, isPCPCredentialAvailable])

    const handleAttested = () => {
        setIsAttesting(false)
        setSubmitting(true)
        dispatch(
            submitAwvFormElectronicResponse({
                memberId,
                npi,
                year,
                formTypeId,
                body: methods.watch(),
                attestation: {
                    dateOfService: attestation.dateOfService,
                    attestationId: attestation.templateId,
                    text: attestation.text
                },
                statusId
            })
        ).then((x) => {
            setSubmitting(false)
            if (x.type === submitAwvFormElectronicResponse.fulfilled.type) {
                setSubmitted(true)
            }
        })
    }
    // #endregion Attestation

    const handleSubmit = () => {
        if (currentUser?.primaryRole === ERoles.Provider) {
            setValidating(true)
            if (validationErrorCount === 0) {
                setIsAttesting(true)
            } else {
                setValidationFailed(true)
            }
        } else {
            setSubmitByNonPCP(true)
        }
    }
    const submit = () => {}

    const close = () => {
        window.close()
    }
    /** Progress Bar Code */

    const [progressBarValue, setProgressBarValue] = useState(0)

    const handleNavigation = (e: any) => {
        const element = document.getElementById(e)
        // const element = document.querySelector(e)
        const progressBar = (100 / dropDownListData.length) * e
        setProgressBarValue(progressBar)

        if (element) {
            // element.scrollIntoView({ block: 'start', behavior: 'smooth', inline: 'start' })
            scrollToTargetAdjusted(element)
        }
    }

    const scrollToTargetAdjusted = (element: any) => {
        let headerOffset = 100
        let elementPosition = element.getBoundingClientRect().top
        let offsetPosition = elementPosition + window.scrollY - headerOffset

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        })
    }

    const dropDownListData = formSections

    /** End Progress Bar Code */

    /**
     * Navigation Code
     */

    const topLevelInErrorElementNames = getTopLevelInErrorElementNames(validationErrors)

    const [showHeadings, setShowHeadings] = useState(false)
    const { activeId } = useHeadsObserver()

    const headings = formSections
        ?.filter((x) => x.hideInNavigationSidebar !== true && evaluateCondition(x.condition, methods.watch))
        .map((section) => ({
            text: section.title,
            section
        }))

    useEffect(() => {
        if (awvFormLoaded && form && response) {
            setTimeout(() => {
                setShowHeadings(true)
            }, 2000)
        }
    }, [awvFormLoaded, form, response])

    const setValidating = (value: boolean) => {
        if (value != validating) {
            dispatch(setAwvValidationState({ memberId, npi, year, validating: value }))
        }
    }

    const toggleValidation = () => {
        setValidating(validating ? false : true)
        dispatch(setAwvValidationState({ memberId, npi, year, validating: validating ? false : true }))
    }

    const handleErrorCountClick = () => {
        const section = headings?.find((x) => isSectionInError(x.section, topLevelInErrorElementNames))
        if (section) {
            handleNavigation(section.text)
        }
    }

    /**
     * End Navigation Code
     */

    useEffect(() => {
        const index = headings?.findIndex((head: any, idx: number) => {
            return activeId === head?.text
        })

        if (index === 15) {
            let syncItem = document.getElementById(String(headings.length - 1))
            syncItem.scrollIntoView(true)
        }

        if (index === 12 || index === 14) {
            let syncItem = document.getElementById('2')
            syncItem.scrollIntoView(false)
        }
    }, [activeId])

    /**
     * Feedback
     */

    const [feedbackAwvId, setLoadedAwvId] = useState<string>(null)

    useEffect(() => {
        if (awvId) {
            setLoadedAwvId(awvId)
        }
    }, [awvId])

    useEffect(() => {
        if (feedbackAwvId) {
            dispatch(hasNewAwvFeedback({ awvId: feedbackAwvId }))
        }
    }, [feedbackAwvId])

    const [showFeedbackDialog, setShowFeedbackDialog] = useState(false)
    const [feedbackLoading, setFeedbackLoading] = useState(false)
    const [feedbackSending, setFeedbackSending] = useState(false)

    const { awvFeedback, awvHasNewFeedback } = useSelector((state: { feedback: FeedbackState }) => state.feedback)

    const viewFeedbackEvent = usePostCustomEvent(pageTitle.PatientsAWV, customEvents.ViewFeedback)

    const handleFeedback = () => {
        viewFeedbackEvent()

        setFeedbackLoading(true)
        setShowFeedbackDialog(true)
        dispatch(markAwvFeedbackRead({ awvId: feedbackAwvId }))
        dispatch(getAwvFeedback({ awvId: feedbackAwvId })).then(() => {
            setFeedbackLoading(false)
        })
    }

    const sentFeedbackEvent = usePostCustomEvent(pageTitle.PatientsAWV, customEvents.SendFeedback)

    const handleSendFeedback = (feedbackToSend: string) => {
        sentFeedbackEvent()
        setFeedbackSending(true)
        dispatch(
            addAwvFeedback({
                awvId: feedbackAwvId,
                parentId: null,
                feedbackText: feedbackToSend
            })
        ).then(() => {
            setFeedbackSending(false)
        })
    }

    /**
     * End Feedback
     */

    useEffect(() => {
        hideMainWindowScrollbars(
            changeFormTypeShown ||
                submitted ||
                showFeedbackDialog ||
                validationFailed ||
                isSubmitByNonPCP ||
                isAttesting
        )
    }, [changeFormTypeShown, submitted, showFeedbackDialog, validationFailed, isSubmitByNonPCP, isAttesting])

    const selectNewAWVFormTypeEvent = usePostCustomEvent(pageTitle.PatientsAWV, customEvents.SelectNewAWVFormType)

    const handleSelectFormType = (selectedFormTypeId: string, makeDefaultFormType: boolean) => {
        selectNewAWVFormTypeEvent()
        setSelectedFormTypeId(selectedFormTypeId)
        if (makeDefaultFormType) {
            dispatch(setDefaultElectronicFormTypeId(selectedFormTypeId))
        }
        setSelectFormTypeShown(false)
    }

    const changeExistingAWVFormTypeEvent = usePostCustomEvent(
        pageTitle.PatientsAWV,
        customEvents.ChangeExistingAWVFormType
    )

    const handleChangeFormType = (selectedFormTypeId: string, makeDefaultFormType: boolean) => {
        changeExistingAWVFormTypeEvent()
        setFormTypeChanging(true)
        if (makeDefaultFormType) {
            dispatch(setDefaultElectronicFormTypeId(selectedFormTypeId))
        }
        dispatch(
            saveAwvFormElectronicResponse({
                memberId,
                npi,
                year,
                formTypeId: selectedFormTypeId,
                body: methods.watch(),
                statusId
            })
        ).then((x) => {
            setChangeFormTypeShown(false)
            if (x.meta.requestStatus === 'fulfilled') {
                window.location.reload()
            }
        })
    }

    const closeElectronicFormTypePicker = () => {
        setChangeFormTypeShown(false)
    }

    return activeAwvElectronicFormTypes && selectFormTypeShown ? (
        <ElectronicFormTypePicker
            formTypes={activeAwvElectronicFormTypes}
            selectedFormTypeId={selectedFormTypeId}
            showWarning={false}
            onSelect={handleSelectFormType}
            onCancel={close}
        />
    ) : awvFormLoaded && form && formSections && response && validationErrors && memberProfileLookupData ? (
        <>
            {changeFormTypeShown && (
                <ElectronicFormTypePicker
                    formTypes={activeAwvElectronicFormTypes}
                    selectedFormTypeId={electronicAwvForm.formTypeId}
                    showWarning={true}
                    isChanging={formTypeChanging}
                    onSelect={handleChangeFormType}
                    onCancel={closeElectronicFormTypePicker}
                    onClose={closeElectronicFormTypePicker}
                />
            )}

            {isAttesting && !isPCPCredentialAvailable && (
                <PCPCredentialModal
                    onCancel={() => {
                        setIsAttesting(false)
                    }}
                    onError={() => {
                        setIsAttesting(false)
                    }}
                />
            )}

            {isAttesting && isPCPCredentialAvailable && isAttestDataReady && (
                <AttestationModal
                    iniDate={attestation.dateOfService}
                    message={attestation.text}
                    setNewDate={setAttestedDOS}
                    onConfirm={handleAttested}
                    onCancel={() => setIsAttesting(false)}
                />
            )}

            {isSubmitByNonPCP && (
                <NonPCPSubmissionWarning
                    providerInfo={provider}
                    formType={electronicAwvForm.formTypeName}
                    onClose={() => setSubmitByNonPCP(false)}
                />
            )}

            {validationFailed && (
                <MessageBox
                    title={electronicAwvForm.formTypeName}
                    body={`The validation of this form has resulted in ${validationErrorCount} ${
                        validationErrorCount === 1 ? 'error' : 'errors'
                    }. Please review the form and resolve ${
                        validationErrorCount === 1 ? 'this error' : 'these errors'
                    } before submitting.`}
                    closeButtonLabel="OK"
                    closeButtonColor={UI_COLORS.red}
                    onClose={() => setValidationFailed(false)}
                />
            )}

            {submitted && (
                <MessageBox
                    title={electronicAwvForm.formTypeName}
                    body={`Your electronic ${electronicAwvForm.formTypeName} form has been submitted for review.`}
                    closeButtonLabel="OK"
                    closeButtonColor={UI_COLORS.light_blue2}
                    onClose={close}
                />
            )}

            {showFeedbackDialog ? (
                <FeedbackModalDialog
                    feedback={awvFeedback[feedbackAwvId] ?? []}
                    isLoading={feedbackLoading}
                    isSending={feedbackSending}
                    onSend={handleSendFeedback}
                    onClose={() => setShowFeedbackDialog(false)}
                />
            ) : null}

            <StyledAWVDigitalContainerWrap>
                {/* Table of Contents */}
                <StyledAWVDigitalAside>
                    <StyledAWVDigitalLogoImage />
                    <StyledAWVDigitalAsideContainer>
                        <StyledAWVDigitalToC>
                            {showHeadings &&
                                headings.map((item: any, idx: number) => {
                                    return (
                                        <li id={`${idx}`} key={`${item.text}-toc`}>
                                            <button
                                                style={{
                                                    fontWeight: activeId === item.text ? 'bold' : 'normal',
                                                    color:
                                                        !readOnly &&
                                                        validating &&
                                                        isSectionInError(item.section, topLevelInErrorElementNames)
                                                            ? 'red'
                                                            : activeId === item.text
                                                            ? '#2281C4'
                                                            : '#73839C'
                                                }}
                                                onClick={() => handleNavigation(item.text)}
                                            >
                                                {item.text}
                                            </button>
                                        </li>
                                    )
                                })}
                        </StyledAWVDigitalToC>
                    </StyledAWVDigitalAsideContainer>
                </StyledAWVDigitalAside>
                {/* End Table of Contents */}

                {/* Top Navigation */}
                <StyledAWVDigitalNavBarContainer>
                    <StyledAWVDigitalUserInfoHeader
                        style={{ height: activeId !== electronicAwvForm.formTypeName ? '64px' : '' }}
                    >
                        <Typography type={TYPOGRAPHY_TYPES.h1}>{response.patientName}</Typography>
                    </StyledAWVDigitalUserInfoHeader>
                </StyledAWVDigitalNavBarContainer>
                {/* End Top Navigation */}

                <StyledAWVDigitalContainer>
                    <FormProvider {...methods}>
                        <form onSubmit={submit}>
                            {formSections
                                .filter((x) => evaluateCondition(x.condition, methods.watch))
                                .map((section, sectionIndex) => (
                                    <div
                                        key={`section-${sectionIndex}`}
                                        id={section.title}
                                        style={{ marginBottom: '48px' }}
                                    >
                                        <SectionTitle title={section.title} />
                                        {section.description && (
                                            <SectionDescription description={section.description} />
                                        )}
                                        {section.elements &&
                                            section.elements.map((x, elementIndex) => (
                                                <fieldset
                                                    key={`element-field-set-${sectionIndex}-${elementIndex}`}
                                                    disabled={
                                                        (!disableInReadOnlyModeOptOut[x.$type] && readOnly) ||
                                                        submitting ||
                                                        submitted ||
                                                        isAttesting
                                                    }
                                                >
                                                    {renderSectionElement(x, `awvForm-${sectionIndex}-${elementIndex}`)}
                                                </fieldset>
                                            ))}
                                    </div>
                                ))}
                        </form>
                    </FormProvider>
                    <StyledSubmitButtonContainer>
                        <StyledCloseButton onClick={close}>Close</StyledCloseButton>
                        {!readOnly && (
                            <>
                                <StyledSubmitButton
                                    onClick={handleSubmit}
                                    disabled={submitting || submitted || isAttesting}
                                >
                                    Submit
                                </StyledSubmitButton>
                                {submitting && !submitted && <Spinner />}
                            </>
                        )}
                    </StyledSubmitButtonContainer>
                    {feedbackAwvId && (
                        <>
                            {!readOnly && (
                                <FormTypeButton
                                    formTypeName={electronicAwvForm.formTypeName}
                                    changeFormType={() => setChangeFormTypeShown(true)}
                                />
                            )}
                            {!readOnly && validationErrors && (
                                <ValidationToggle
                                    isOn={validating}
                                    errorCount={validationErrorCount}
                                    onBodyClick={toggleValidation}
                                    onErrorCountClick={handleErrorCountClick}
                                />
                            )}
                            <AWVFeedback
                                openFeedback={handleFeedback}
                                hasNewFeedback={awvHasNewFeedback[feedbackAwvId]?.hasNewFeedback ?? false}
                                feedbackCount={awvHasNewFeedback[feedbackAwvId]?.newFeedbackCount ?? 0}
                            />
                        </>
                    )}
                </StyledAWVDigitalContainer>
            </StyledAWVDigitalContainerWrap>
        </>
    ) : (
        <Spinner />
    )
}
