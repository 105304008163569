import { useState } from 'react'

export interface IExternalStateValue<T> {
    get: () => T

    set: (value: T) => void
}

export interface IExternalState {
    data: any

    getValue: <T>(name: string, defaultValue: T) => IExternalStateValue<T>
}

export const useExternalState: () => IExternalState = () => {
    const [state, setState] = useState<any>({})
    return {
        data: state,
        getValue: <T>(name: string, defaultValue: T) => {
            const get = () => state[name] || defaultValue
            const set = (value: T) => setState({ ...state, [name]: value })
            return { get, set }
        }
    }
}
