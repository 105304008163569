export const inputLabels = {
    first_name: 'First Name',
    last_name: 'Last Name',
    phone_number: 'Phone Number',
    email: 'Email Address',
    TIN: 'Billing Provider Tax ID  (TIN)',
    TIN_PlaceHolder: 'Enter Billing Provider Tax ID',
    NPI: 'National Provider Identifier (NPI)',
    NPI_PlaceHolder: 'National Provider Identifier (NPI)',
    Primary_Location: 'Primary Location',
    Office_Phone: 'Office Phone Number',
    Office_Address: 'Office Street Address',
    Office_Suite: 'Office/Suite/Room Number',
    City: 'City',
    State: 'State',
    ZipCode: 'Zip Code'
}
