import { useFormContext } from 'react-hook-form'

export const placeholderResetStringValue =
    'This is a meaningless string that will be removed by the server-side sanitization engine.'

export const useResetValue = (usePlaceholderResetStringValue = false) => {
    const { setValue } = useFormContext()

    return (...valueNames: string[]) => {
        for (let i = 0; i < valueNames.length; i++) {
            setValue(valueNames[i], usePlaceholderResetStringValue ? placeholderResetStringValue : null)
        }
    }
}
