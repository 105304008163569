import CustomSvgIcon from 'components/CustomSvgIcon'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { IMemberProfileDetails } from 'pages/hcp/users/create/index.models'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { MemberProfileState } from 'redux/slices/memberProfile'
import { isElegibleBadge, notElegibleBadge } from '../Personal/icons'
import { StyledBadgeContainer, StyledEligibilityBadgeContainer } from '../Personal/index.style'
import { benefiIconActive } from './icons'
import {
    StyledMenuButton,
    StyledMenuButtonContainer,
    StyledNavBarContainer,
    StyledNavBarList,
    StyledNavBarListItem,
    StyledUserInfoHeader
} from './index.style'
import { ENavBarItems, INavBarConfig, NavBarConfig } from './NavBarConfig'
import useTrackPageView from 'hooks/useTrackPageView'

interface INavBarProps {
    navBarConfig: INavBarConfig[]
    selectedTab?: (selectedNavItem: string) => void
    initialTab?: string
}

const NavBar: FC<INavBarProps> = ({ navBarConfig, selectedTab, initialTab }) => {
    const [isMenuHidden, setIsMenuHidden] = useState<boolean>(true)
    const [tabLabel, setTabLabel] = useState<string>(initialTab == null ? ENavBarItems.PERSONAL : initialTab)

    const trackPageView = useTrackPageView()

    const memberProfileDetails: IMemberProfileDetails = useSelector(
        (state: { memberProfile: MemberProfileState }) => state.memberProfile.memberProfileDetails
    )

    const handleNavBarItem = (selectedItem: string) => {
        selectedTab(selectedItem)
        setTabLabel(selectedItem)
        setIsMenuHidden(!isMenuHidden)

        if (selectedItem) {
            let navBarItems = Object.values(NavBarConfig)

            const navBarConfigItem = navBarItems.find((navItem) => navItem.label === selectedItem)
            if (navBarConfigItem) {
                trackPageView(navBarConfigItem.pageTitle)
            }

            return navBarConfigItem !== null
            // use this object to config the icon and lable for navbar
        }
    }

    const handleMenu = () => {
        setIsMenuHidden(!isMenuHidden)
    }

    const elegibilityBadge = () => {
        return (
            <StyledEligibilityBadgeContainer>
                <Typography type={TYPOGRAPHY_TYPES.p}>
                    {memberProfileDetails?.benefits[0]?.isEligible ? 'Eligible' : 'Ineligible'}
                </Typography>
                <StyledBadgeContainer>
                    <CustomSvgIcon
                        iconSet={{
                            icon: memberProfileDetails?.benefits[0]?.isEligible ? isElegibleBadge : notElegibleBadge
                        }}
                        svg
                    ></CustomSvgIcon>
                </StyledBadgeContainer>
            </StyledEligibilityBadgeContainer>
        )
    }

    return (
        <StyledNavBarContainer>
            <StyledUserInfoHeader>
                <Typography type={TYPOGRAPHY_TYPES.h2}>
                    {memberProfileDetails?.lastName}, {memberProfileDetails?.firstName}
                </Typography>
                {elegibilityBadge()}
            </StyledUserInfoHeader>
            <StyledNavBarList isVisible={isMenuHidden}>
                {navBarConfig.map((item: INavBarConfig, idx: number) => {
                    return (
                        <StyledNavBarListItem
                            onClick={() => handleNavBarItem(item.label)}
                            key={`navBarItem-${idx}`}
                            isActive={tabLabel === item.label}
                            selectedLabel={item.label}
                        >
                            {item.label === 'Benefit Info' && tabLabel === 'Benefit Info' ? (
                                <CustomSvgIcon iconSet={{ icon: benefiIconActive }} svg></CustomSvgIcon>
                            ) : (
                                <CustomSvgIcon iconSet={{ icon: item.icon }} svg></CustomSvgIcon>
                            )}
                            {item.label}
                        </StyledNavBarListItem>
                    )
                })}
            </StyledNavBarList>
            <StyledMenuButtonContainer>
                {isMenuHidden && <Typography type={TYPOGRAPHY_TYPES.p}>{tabLabel}</Typography>}
                <StyledMenuButton onClick={handleMenu}>Menu</StyledMenuButton>
            </StyledMenuButtonContainer>
        </StyledNavBarContainer>
    )
}

export default NavBar
