import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { light_gray2, light_gray3, light_gray4, dark_gray, light_blue2, light_blue3 } = UI_COLORS

const tableReset = css`
    list-style: none;
    padding: 0;
    display: flex;
`

export const StyledSupportingDocsContainerWrap = styled.article`
    h2 {
        padding: 0;
        margin: 0;

        color: #293854;
        font-family: 'Work Sans', sans-serif;
        font-size: ${pxToRem(22)};
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 26.4px */
        letter-spacing: -0.22px;

        margin-bottom: ${pxToRem(32)};
    }
`

export const StyledSupportingDocsContainer = styled.article`
    max-height: 550px;
    overflow-y: auto;

    ::-webkit-scrollbar {
        display: none;
    }

    :hover {
        ::-webkit-scrollbar {
            display: block;
            width: 4px;
        }

        ::-webkit-scrollbar-track {
            background-color: #f6f9fd;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #73839c;
        }
    }

    h2 {
        padding: 0;
        margin: 0;

        color: #293854;
        font-family: 'Work Sans', sans-serif;
        font-size: ${pxToRem(22)};
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 26.4px */
        letter-spacing: -0.22px;

        margin-bottom: ${pxToRem(32)};
    }
`

export const StyledSupportingDocsTableHeader = styled.ul`
    ${tableReset}
    background-color: #EBEFF7;
    margin: 0;
`

export const StyledSupportingDocsTableHeaderItem = styled.li`
    padding: 8px 32px;

    p {
        margin: 0;
        color: #73839c;
        font-family: 'Inter';
        font-size: ${pxToRem(12)};
        font-style: normal;
        font-weight: 600;
        line-height: 130%; /* 15.6px */
    }

    :nth-child(1) {
        /* background-color: red; */
        width: 260px;
    }

    :nth-child(2) {
        /* background-color: blue; */
        width: 150px;
    }

    :nth-child(3) {
        /* background-color: green; */
        width: 150px;
    }
`

export const StyledSupportingDocsTableBody = styled.ul`
    ${tableReset}
    /* padding: 0 32px; */
    border-bottom: 1px solid #dee5ef;
    margin: 0;
`

export const StyledSupportingDocsTableBodyItem = styled.li`
    padding: 30px;
    padding-left: 32px;
    cursor: pointer;

    p {
        margin: 0;
        overflow: hidden;
        color: #2281c4;
        text-overflow: ellipsis;
        font-family: 'Inter';
        font-size: ${pxToRem(12)};
        font-style: normal;
        font-weight: 600;
        line-height: 130%; /* 15.6px */

        :hover {
            text-decoration: underline;
        }
    }

    span {
        margin: 0;
        overflow: hidden;
        color: #394a64;
        text-overflow: ellipsis;
        font-family: 'Inter';
        font-size: ${pxToRem(12)};
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 15.6px */
    }

    :nth-child(1) {
        /* background-color: red; */
        width: 260px;
    }

    :nth-child(2) {
        /* background-color: blue; */
        width: 150px;
    }

    :nth-child(3) {
        /* background-color: green; */
        width: 150px;
    }
`

export const StyledSupportingDocsButtonContainer = styled.article`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
`

export const StyledSupportingDocsCloseButton = styled.button`
    margin: 0;
    background-color: transparent;
    border: none;

    cursor: pointer;

    figure {
        margin: 0;
    }
`
