import { BrowserRouter } from 'react-router-dom'

import Router from 'routes'
import { useEffect, useLayoutEffect } from 'react'
import { dispatch } from 'redux/store'
import Spinner from 'components/Spinner'
import { getCurrentUser, getAuthenticatedUser } from 'redux/slices/system'
import useCurrentUser from 'hooks/useCurrentUser'
import { EUserSide, getUserSide } from 'models/enums/role'
import { getNewReportCounts } from 'redux/slices/documents'

function AuthenticatedApp() {
    useLayoutEffect(() => {
        dispatch(getCurrentUser())
        dispatch(getAuthenticatedUser())
    }, [])

    const currentUser = useCurrentUser()

    useEffect(() => {
        if (currentUser?.userId.length > 0 && getUserSide(currentUser.primaryRole) === EUserSide[EUserSide.External]) {
            // Pull NewReportCount every 15mins for External user (PCP/NP/OfficeAdmin)
            dispatch(getNewReportCounts())
            const intervalId = setInterval(() => {
                dispatch(getNewReportCounts())
            }, 900000)
            return () => {
                clearInterval(intervalId)
            }
        }
    }, [currentUser])

    if (currentUser?.primaryRole) {
        return (
            <BrowserRouter>
                <Router role={currentUser.primaryRole} />
            </BrowserRouter>
        )
    } else {
        return <Spinner />
    }
}

export default AuthenticatedApp
