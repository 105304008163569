import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { ReadOnlyTextField } from '../ReadOnlyTextField'
import { IBodyMassIndexField, ISectionElement } from '../index.models'

interface IBodyMassIndexFieldProps {
    name: string
    label: string
    subLabel: string
    description: string
    weightFieldName: string
    heightFieldName: string
}

const BodyMassIndexField: FC<IBodyMassIndexFieldProps> = ({
    name,
    label,
    subLabel,
    description,
    weightFieldName,
    heightFieldName
}) => {
    const { watch } = useFormContext()

    const bodyMassIndex =
        watch(weightFieldName) &&
        watch(heightFieldName) &&
        !isNaN(watch(weightFieldName)) &&
        !isNaN(watch(heightFieldName)) &&
        watch(weightFieldName) > 0 &&
        watch(heightFieldName) > 0
            ? Math.round(7030 * (watch(weightFieldName) / Math.pow(watch(heightFieldName), 2))) / 10
            : null

    return (
        <ReadOnlyTextField
            name={name}
            label={label}
            subLabel={subLabel}
            description={description}
            value={bodyMassIndex}
        />
    )
}

export const renderBodyMassIndexField = (element: ISectionElement, key: string) => {
    const bodyMassIndexField = element as IBodyMassIndexField
    return <BodyMassIndexField key={key} {...bodyMassIndexField} />
}
