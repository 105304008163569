import { ISectionElement, ISectionElementGroup } from '../index.models'

export const renderSectionElementGroup = (
    element: ISectionElement,
    key: string,
    renderSectionElement: (element: ISectionElement, key: string, parentName?: string) => JSX.Element,
    parentName: string
) => {
    const sectionElementGroup = element as ISectionElementGroup
    return (
        <>
            {sectionElementGroup.elements.map((element, index) =>
                renderSectionElement(element, `${key}-${index}`, parentName)
            )}
        </>
    )
}
