import ConfirmationBox from 'components/ConfirmationBox'
import { FC } from 'react'
import { UI_COLORS } from 'theme'

interface IUnscheduleAwvConfirmationBox {
    onConfirm: () => void
    onCancel: () => void
}

const UnscheduleAwvConfirmationBox: FC<IUnscheduleAwvConfirmationBox> = ({ onConfirm, onCancel }) => (
    <ConfirmationBox
        title="Unschedule AWV"
        body={`Would you like to unschedule the selected Annual Wellness Visit?`}
        confirmButtonLabel="Unschedule"
        confirmButtonColor={UI_COLORS.red}
        onConfirm={onConfirm}
        onCancel={onCancel}
    />
)

export default UnscheduleAwvConfirmationBox
