import styled, { css } from 'styled-components'
import { pxToRem } from 'utilities'

export const StyledGapsInCareContainer = styled.article`
    padding: ${pxToRem(180)} ${pxToRem(30)};
    width: ${pxToRem(1145)};
    font-family: 'Work Sans', sans-serif;
    position: relative;

    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #293854;
    }

    h2 {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 31px;
        letter-spacing: -0.01em;
        color: #293854;
    }

    h4 {
        margin-top: ${pxToRem(20)};
        margin-bottom: ${pxToRem(10)};
        color: #293854;
    }

    p {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 31px;
        margin-top: 5px;

        letter-spacing: -0.01em;

        color: #293854;
    }

    fieldset {
        margin: 0;
        border: 0;
        padding: 0;

        :disabled {
            input {
                background-color: #f5f5f5;
            }
        }
    }
`

export const StyledGapsInCareDetailsContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
`

export const StyledGapsInCareTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;

    h3 {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(26)};
        letter-spacing: -0.01em;
        color: #293854;

        position: relative;

        :after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            display: inline-block;
            width: 40px;
            height: 3px;
            background-color: #293854;
        }
    }
`

export const StyledDropdownContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
        margin-right: 16px;
        margin-top: 15px;
    }
`

export const StyledCalendarIcon = styled.div<{ image?: string }>`
    &:after {
        content: '';
        position: absolute;
        transform: TranslateX(-100%);
        width: 20px;
        height: 70px;

        width: ${pxToRem(16)};
        height: ${pxToRem(16)};
        margin-top: ${pxToRem(-9)};
        margin-left: ${pxToRem(30)};
        z-index: 1;

        border: 0;

        background-size: cover;
        background-color: white;

        cursor: pointer;

        ${(props) => {
            if (props.image) {
                return css`
                    background-image: url(${props.image});
                `
            }
        }};
    }
`

export const StyledEmptyState = styled.article`
    width: 100%;
    height: ${pxToRem(250)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: ${pxToRem(40)};

    h4 {
        margin: 0;
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(22)};
        line-height: ${pxToRem(26)};
        letter-spacing: -0.01em;
        color: #293854;
    }

    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(18)};

        color: #73839c;
    }
`

export const StyledAddLink = styled.button`
    border: 0;
    background-color: transparent;
    margin: 0;
    text-align: left;
    height: 18px;
    position: relative;
    top: -10px;
    margin-top: 25px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    color: #2281c4;
    cursor: pointer;

    :focus {
        outline: none;
    }

    :disabled {
        cursor: default;
        color: #aaaaaa;
    }
`
