export interface IToast {
    id?: number;
    message: string;
    icon: any;
    time: number;
    variant: EToastVariant
}

export enum EToastVariant {
    INFO = "#2980b9",
    ERROR = "#c0392b",
    SUCCESS = "#27ae60",
    WARNING = "#d35400"
}
