import Modal from 'components/Modal'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC, useState } from 'react'
import {
    StyledButtonContainer,
    StyledCancelButton,
    StyledGapContainer,
    StyledFormTextAreaContainer,
    StyledConfirmButton
} from './index.style'
import DropDownList from 'components/DropDownList'
import { IGap, IGapInCare, IUpdateGapInCareRequest } from 'pages/hcp/users/create/index.models'
import { dropDownListStyleParams } from 'pages/hcp/members/electronicAnnualWellnessVisitForm/DropDownList/index.style'
import { ICreateGapInCareArgs } from 'redux/slices/gapsInCare'

export enum GapInCareModalMode {
    Create,
    View,
    Edit
}

interface IGapInCareModalProps {
    mode: GapInCareModalMode
    memberId?: number
    year?: number
    npi?: string
    gaps?: IGap[]
    gapInCare?: IGapInCare
    onCreate?: (request: ICreateGapInCareArgs) => void
    onUpdate?: (request: IUpdateGapInCareRequest) => void
    onCancelOrClose: () => void
}

const GapInCareModal: FC<IGapInCareModalProps> = ({
    mode,
    memberId,
    year,
    npi,
    gaps,
    gapInCare,
    onCreate,
    onUpdate,
    onCancelOrClose
}) => {
    const lastCompletionDateObject = gapInCare?.lastCompletionDate ? new Date(gapInCare.lastCompletionDate) : null
    const [gapId, setGapId] = useState(gaps?.length > 0 ? gaps[0].id : null)
    const [lastCompletionDate, setLastCompletionDate] = useState(
        gapInCare?.lastCompletionDate
            ? `${lastCompletionDateObject.getFullYear()}-${
                  lastCompletionDateObject.getMonth() >= 9
                      ? lastCompletionDateObject.getMonth() + 1
                      : '0' + (lastCompletionDateObject.getMonth() + 1)
              }-${
                  lastCompletionDateObject.getDate() >= 9
                      ? lastCompletionDateObject.getDate()
                      : '0' + lastCompletionDateObject.getDate()
              }`
            : ''
    )
    const [serviceNote, setServiceNote] = useState(gapInCare?.serviceNote ?? '')

    const getLastCompletionDate = () => (lastCompletionDate ? new Date(lastCompletionDate).toJSON() : null)

    return (
        <Modal minHeight={620} minWidth={720} onClose={onCancelOrClose}>
            <Typography type={TYPOGRAPHY_TYPES.h2}>
                {mode === GapInCareModalMode.Create
                    ? 'Create Gap in Care'
                    : mode === GapInCareModalMode.Edit
                    ? 'Edit Gap in Care'
                    : 'Gap in Care'}
            </Typography>
            <Typography type={TYPOGRAPHY_TYPES.p}>
                <StyledGapContainer>
                    <label htmlFor="gapInCare">Gap</label>
                    {mode === GapInCareModalMode.Create ? (
                        <DropDownList
                            id="gapInCare"
                            placeholder="Gap in Care"
                            options={gaps.map((x) => ({
                                value: x.id,
                                label: x.name
                            }))}
                            selectedValue={gapId}
                            setSelectedValue={setGapId}
                            styleParams={dropDownListStyleParams}
                        />
                    ) : (
                        <input id="gapInCare" disabled={true} value={gapInCare?.gap} />
                    )}
                </StyledGapContainer>
            </Typography>
            <Typography type={TYPOGRAPHY_TYPES.p} style={{ width: '400px' }}>
                <StyledGapContainer>
                    <label htmlFor="lastCompletionDate">Last Date of Service</label>
                    <input
                        id="lastCompletionDate"
                        disabled={
                            !(
                                mode === GapInCareModalMode.Create ||
                                (mode === GapInCareModalMode.Edit && gapInCare?.isUserCreated === true)
                            )
                        }
                        name="lastCompletionDate"
                        type="date"
                        aria-label="Last Date of Service"
                        placeholder="Last Date of Service"
                        value={lastCompletionDate}
                        onChange={(e) => setLastCompletionDate(e.target.value)}
                    />
                </StyledGapContainer>
            </Typography>
            <Typography type={TYPOGRAPHY_TYPES.p} style={{ width: '640px' }}>
                <StyledFormTextAreaContainer>
                    <label htmlFor="serviceNote">Service Note</label>
                    <textarea
                        id="serviceNote"
                        name="serviceNote"
                        aria-label="serviceNote"
                        value={serviceNote}
                        onChange={(e) => setServiceNote(e.target.value)}
                        disabled={mode === GapInCareModalMode.View}
                    />
                </StyledFormTextAreaContainer>
            </Typography>
            <StyledButtonContainer>
                <StyledCancelButton onClick={onCancelOrClose}>
                    {mode === GapInCareModalMode.View ? 'Close' : 'Cancel'}
                </StyledCancelButton>
                {(mode === GapInCareModalMode.Create || mode === GapInCareModalMode.Edit) && (
                    <StyledConfirmButton
                        onClick={() =>
                            mode === GapInCareModalMode.Create
                                ? onCreate({
                                      memberId: memberId,
                                      gapId: gapId,
                                      lastCompletionDate: getLastCompletionDate(),
                                      serviceNote: serviceNote,
                                      year: year,
                                      npi: npi
                                  })
                                : onUpdate({
                                      gapInCareId: gapInCare.id,
                                      npi: npi,
                                      lastCompletionDate: getLastCompletionDate(),
                                      serviceNote: serviceNote
                                  })
                        }
                        disabled={mode === GapInCareModalMode.Edit ? (gapInCare ? false : true) : gapId ? false : true}
                    >
                        {mode === GapInCareModalMode.Create ? 'Create' : 'Update'}
                    </StyledConfirmButton>
                )}
            </StyledButtonContainer>
        </Modal>
    )
}

export default GapInCareModal
