import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { light_gray2, light_gray3, light_gray4, dark_gray, light_blue2, light_blue3 } = UI_COLORS

export const StyledExportSpacer = styled.div`
    width: 0px;
`

// group together the StyledExportButton and the SmallSpinner
export const StyledExportButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: left;
    gap: 12px;
`

export const StyledExportButton = styled.button`
    display: flex;
    flex-direction: row;
    align-items: right;
    padding: 12px 5px;
    gap: 12px;
    border-width: 0px;

    position: relative;
    width: 200px;
    height: 50px;

    /* HCP Blue 1 */

    background: #2281c4;
    border-radius: 4px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    align-items: center;
    text-align: center;

    svg {
        width: 25px;
        height: 25px;
    }

    /* White */
    color: #ffffff;
    cursor: pointer;

    &:active {
        color: #2281c4;
        background: #ffffff;
    }
`

export const StyledAppendedSpinner = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: left;
`
