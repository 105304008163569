import { FC, useState } from 'react'
import Addresses from './Addresses'
import NavBar from './NavBar'
import { ENavBarItems, NavBarConfig } from './NavBar/NavBarConfig'
import Personal from './Personal'
import BenefitInfo from './BenefitInfo'
import AWV from './AWV'
import Documents from './DocumentsTab'
import GapsInCare from './GapsInCare'

interface IMemberInformationProps {
    tab?: string
}

const MemberInformation: FC<IMemberInformationProps> = ({ tab }) => {
    const [selectedTab, setSelectedTab] = useState(tab == null ? 'Personal' : tab)
    const [IsFormLoading, setIsFormLoading] = useState<boolean>(true)

    const handleSelectedTab = (selected: any) => {
        setSelectedTab(selected)
    }

    return (
        <>
            {IsFormLoading ? (
                ''
            ) : (
                <NavBar navBarConfig={NavBarConfig} selectedTab={handleSelectedTab} initialTab={tab} />
            )}
            {selectedTab === ENavBarItems.PERSONAL ? <Personal isLoading={setIsFormLoading} /> : ''}
            {selectedTab === ENavBarItems.ADDRESSES ? <Addresses /> : ''}
            {selectedTab === ENavBarItems.BENEFITINFO ? <BenefitInfo /> : ''}
            {selectedTab === ENavBarItems.GAPSINCARE ? <GapsInCare setIsFormLoading={setIsFormLoading} /> : ''}
            {selectedTab === ENavBarItems.AWV ? <AWV isLoading={setIsFormLoading} /> : ''}
            {selectedTab === ENavBarItems.DOCUMENTS ? <Documents /> : ''}
            {/* {selectedTab === ENavBarItems.AWVELECTRONIC ? <AWVDigitalForm /> : ''} */}
            {/* {selectedTab === ENavBarItems.CLAIMS ? <h2>Claims</h2> : ''}
            {selectedTab === ENavBarItems.AUTHORIZATIONS ? <h2>Auth</h2> : ''}
            {selectedTab === ENavBarItems.PHARMACY ? <h2>Pharmacy</h2> : ''} */}
        </>
    )
}

export default MemberInformation
