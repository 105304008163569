import Avatar from 'components/Avatar'
import { FC, useRef, useState } from 'react'
import {
    StyledTableBodyItem,
    StyledTableBodyRow,
    StyledTableBodyRowWrap,
    StyledTableHeaderItem,
    StyledTableHeaderRow,
    StyledUsersTableContainer
} from './index.style'
import { ETableType } from './index.utils'
import { StatusBadge } from 'components/StatusBadge'
import { useNavigate } from 'react-router-dom'
import { ERoleLabel, ERoles } from 'models/enums/role'
import { EStatusType } from 'components/StatusBadge/index.utils'
import { IUser } from 'pages/hcp/users/create/index.models'
import { useDispatch } from 'redux/store'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { arrow } from './icons'
import { appPath } from 'utilities/appPath'
import { setFormatedDateTime } from 'components/UsersTable/index.utils'
import { IHeaderInfo } from 'models/headerInfo'

interface IUsersProps {
    users: IUser[]
    tableType: ETableType
    headersList: IHeaderInfo[]
    sortCallback(columnName: string): any
}

const AdminUsersTable: FC<IUsersProps> = ({ users, headersList, tableType, sortCallback }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [isModalShown, setIsModalShown] = useState(false)
    const isSelectedUserActive = useRef(false)
    const selectedUserID = useRef('')
    const [isHeaderHovered, setIsHeaderHovered] = useState<number | null>(null)
    const [isFilterActive, setIsFilterActive] = useState<number | null>(null)
    const [isFilterDescendingAscending, setIsFilterDescendingAscending] = useState<boolean>(false)

    const editUser = (userId: string) => {
        navigate(appPath(`/users/${userId}/edit`))
    }

    const userDetails = (userId: string, userRole: ERoles) => {
        if (userRole === ERoles.Provider) {
            navigate(appPath(`/users/${userId}`))
        } else if (userRole === ERoles.OfficeAdmin) {
            navigate(appPath(`/users/officeAdmin/${userId}`))
        }
    }

    const userRole = (role: ERoles) => {
        return ERoleLabel[role - 1]
    }

    const userStatus = (status: boolean, ActiveDirectoryID: any) => {
        if (ActiveDirectoryID === null) {
            return EStatusType.PENDING
        }

        if (status === true) {
            return EStatusType.ACTIVE
        }

        if (status === false) {
            return EStatusType.INACTIVE
        }
    }

    return (
        <>
            <StyledUsersTableContainer>
                <StyledTableHeaderRow>
                    <StyledTableHeaderItem key={'spacer-header'} />
                    {headersList?.map((header: IHeaderInfo, idx: number) => {
                        const isHover = idx === isHeaderHovered
                        const isActive = idx === isFilterActive
                        const showArrow = isHover || isActive
                        return (
                            <>
                                <StyledTableHeaderItem
                                    key={`header-${idx}`}
                                    onClick={() => {
                                        sortCallback(header.fieldName)
                                        setIsFilterActive(idx)
                                        setIsFilterDescendingAscending(!isActive)
                                    }}
                                    onMouseEnter={() => {
                                        setIsHeaderHovered(idx)
                                    }}
                                    onMouseOut={() => {
                                        setIsHeaderHovered(null)
                                    }}
                                    active={isFilterDescendingAscending}
                                >
                                    {header.displayName}
                                    {showArrow && <CustomSvgIcon iconSet={{ icon: arrow }} svg></CustomSvgIcon>}
                                </StyledTableHeaderItem>
                            </>
                        )
                    })}
                </StyledTableHeaderRow>

                {users?.map((user: IUser, idx: number) => {
                    const userIsActive = user?.isActive
                    const userHasDirectoryId = user?.sid

                    return (
                        <StyledTableBodyRowWrap key={`user-${idx}`}>
                            <article
                                role={'button'}
                                // onClick={() => userDetails(user.id, user.role)}
                                tabIndex={1}
                                key={`user-row-${idx}`}
                            >
                                <StyledTableBodyRow key={`userRow-${idx}`}>
                                    <StyledTableBodyItem key={`avatar-${idx}`}>
                                        <Avatar containerSize={40} avatarUrl={user?.avatarUrl} key={`avatar-${idx}`} />
                                    </StyledTableBodyItem>
                                    <StyledTableBodyItem key={`firstName-${idx}`}>
                                        {user?.firstName}
                                    </StyledTableBodyItem>
                                    <StyledTableBodyItem key={`lastName-${idx}`}>{user?.lastName}</StyledTableBodyItem>
                                    <StyledTableBodyItem key={`phone-${idx}`}>{user?.phoneNumber}</StyledTableBodyItem>
                                    <StyledTableBodyItem key={`email-${idx}`}>{user?.email}</StyledTableBodyItem>
                                    <StyledTableBodyItem key={`role-${idx}`}>
                                        {userRole(user?.role)}
                                    </StyledTableBodyItem>
                                    {tableType === ETableType.SUPER_ADMIN ? (
                                        <StyledTableBodyItem key={`lastLogin-${idx}`}>
                                            {setFormatedDateTime(user.lastLogin)}
                                        </StyledTableBodyItem>
                                    ) : (
                                        <StyledTableBodyItem key={`status-${idx}`}>
                                            <StatusBadge
                                                status={userStatus(userIsActive, userHasDirectoryId)}
                                                key={`statusBadge-${idx}`}
                                            />
                                        </StyledTableBodyItem>
                                    )}
                                </StyledTableBodyRow>
                            </article>
                        </StyledTableBodyRowWrap>
                    )
                })}
            </StyledUsersTableContainer>
        </>
    )
}

export default AdminUsersTable
