import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { light_gray2, dark_gray, light_gray3, light_gray4, light_blue3 } = UI_COLORS

const tableReset = css`
    list-style: none;
    padding: 0;
    display: flex;
`

export const StyledUsersTableContainer = styled.article`
    width: ${pxToRem(1145)};

    /* height: ${pxToRem(715)}; */
    height: auto;

    background-color: white;
    margin-bottom: ${pxToRem(30)};
`

export const StyledTableHeaderRow = styled.ul`
    ${tableReset}
    background-color: ${light_blue3};
    padding-left: ${pxToRem(15)};
    height: ${pxToRem(40)};
    border-bottom: 1px solid ${light_blue3};

    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 0;
    margin-bottom: 0;
`

export const StyledTableHeaderItem = styled.li<{ active?: boolean; isAction?: boolean }>`
    margin-right: ${pxToRem(15)};
    color: ${light_gray3};

    font-family: 'Inter', sans-serif;
    font-style: normal;

    font-size: 12px;
    font-weight: 600;
    line-height: 16px;

    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    &:nth-child(1) {
        width: 40px;
    }

    &:nth-child(2) {
        width: 105px;
    }

    &:nth-child(3) {
        width: 105px;
    }

    &:nth-child(4) {
        width: 115px;
    }

    &:nth-child(5) {
        width: 165px;
    }

    &:nth-child(6) {
        width: 105px;
    }

    &:nth-child(7) {
        width: 78px;
        padding-left: ${pxToRem(5)};
    }

    &:nth-child(8) {
        width: 135px;
    }

    &:nth-child(9) {
        width: 125px;
        padding-left: ${pxToRem(12)};
    }

    ${(props) => {
        if (props.isAction) {
            return css`
                &:hover {
                    cursor: default;
                }
            `
        } else {
            return css`
                &:hover {
                    cursor: pointer;
                }
            `
        }
    }}

    figure {
        margin: 0;
        /* position: absolute; */
        /* top: 50%; */
        /* transform: translateY(-50%); */
        /* right: 10px; */

        ${(props) => {
            if (props.active) {
                return css`
                    transform: rotate(180deg) translateY(10%);
                `
            }
        }}
    }
`

export const StyledIcon = styled.article``

export const StyledTableBodyRow = styled.ul`
    ${tableReset}

    height: 75px;

    padding-left: ${pxToRem(15)};
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
`
export const StyledTableBodyItem = styled.li`
    margin-right: ${pxToRem(15)};
    color: ${dark_gray};

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(12)};
    line-height: 16px;

    text-overflow: ellipsis;
    overflow: hidden;

    &:nth-child(1) {
        width: 40px;
    }

    &:nth-child(2) {
        width: 105px;
    }

    &:nth-child(3) {
        width: 105px;
    }

    &:nth-child(4) {
        width: 115px;
    }

    &:nth-child(5) {
        width: 165px;
    }

    &:nth-child(6) {
        width: 105px;
    }

    &:nth-child(7) {
        width: 78px;
    }

    &:nth-child(8) {
        width: 135px;
    }

    &:nth-child(9) {
        width: 125px;
    }
`
export const StyledTableBodyRowWrap = styled.article`
    display: flex;
    border: 1px solid ${light_gray4};
    border-top: 0;
    border-left: 0;
    border-right: 0;

    :hover {
        background-color: ${light_gray2};
        cursor: pointer;
    }

    article {
        &:nth-child(2) {
            display: flex;
            align-items: center;
        }
    }
`

export const StyledLastLoginContainer = styled.article`
    display: flex;
    flex-direction: row;
`

export const StyledActionsContainer = styled.ul`
    ${tableReset}

    width: 160px;
    height: 100%;
    display: flex;
    align-items: center;

    figure {
        margin: 0;
    }
`

export const StyledTableButton = styled.button<{ image?: string; size: string }>`
    background-color: transparent;
    border: 0;

    background-repeat: no-repeat;
    background-size: cover;

    cursor: pointer;

    :hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
        border-radius: 20px;
    }

    ${(props) => {
        if (props.image) {
            return css`
                background-image: url(${props.image});
            `
        }
    }}

    ${(props) => {
        if (props.size) {
            return css`
                width: ${props.size}px;
                height: ${props.size}px;
            `
        }
    }}
`
