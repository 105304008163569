import React, { FC } from 'react'
import { StyledAvatarWrap } from './index.style'

const defaultAvatar = require('assets/avatar.svg').default

export interface IAvatarProps {
    avatarUrl?: string
    containerSize?: number
    avatarSize?: number
    altTagTxt?: string
}

const Avatar: FC<IAvatarProps> = ({ avatarUrl, containerSize, altTagTxt = 'avatar', avatarSize }) => {
    return avatarUrl ? (
        <StyledAvatarWrap
            style={{
                width: containerSize,
                height: containerSize
            }}
        >
            <img src={avatarUrl} style={{ width: avatarSize, height: avatarSize }} alt={altTagTxt} />
        </StyledAvatarWrap>
    ) : (
        <StyledAvatarWrap
            style={{
                backgroundImage: `url(${defaultAvatar})`,
                width: containerSize,
                height: containerSize
            }}
        ></StyledAvatarWrap>
    )
}

export default Avatar
