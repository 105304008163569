import { appendImpersonatedUserId } from "./impersonationUtilities";

export const appPath = (sublink: string) => {
    const root = process.env.PUBLIC_URL
    
    const link = `${root}${sublink}`;

    const impersonationAwareLink = appendImpersonatedUserId(link)
    return impersonationAwareLink
}
