import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IToast } from "models/IToast";
import { dispatch } from "redux/store";

export type ToastState = {
    toasts: IToast[];
}

const initialState: ToastState = {
    toasts: []
}

export const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        addToast: (state, action: PayloadAction<IToast>) => {
            action.payload.id = generateToastId(state.toasts.length)

            state.toasts.push(action.payload)

            autoRemoveToast(action.payload.id, action.payload.time)
        },
        removeToast: (state, action: PayloadAction<number>) => {
            state.toasts = state.toasts.filter(toast => toast.id !== action.payload)
        }
    }
})

export const { addToast, removeToast } = toastSlice.actions
export default toastSlice.reducer

const autoRemoveToast = (id: number, time: number) => {
    setTimeout(() => {
        dispatch(removeToast(id))
    }, time)
}

const generateToastId = (toastLength: number) => toastLength + 1
