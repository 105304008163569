import { useMsal } from '@azure/msal-react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import axios from '../../utilities/anonymousAxios'

import {
    StyledApi,
    StyledButton,
    StyledEntryItem,
    StyledFooter,
    StyledHcpLogo,
    StyledHeading,
    StyledInput,
    StyledLabel,
    StyledPassword,
    StyledPasswordImage,
    StyledRegistrationMain,
    StyledReigstrationContainer,
    StyledSubheading
} from './index.style'

import hcplogo from '../../assets/hcp_logo.svg'

const Registration = () => {
    const [user, setUser] = useState<any>()
    const [error, setError] = useState()
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [registrationInProgress, setRegistrationInProgress] = useState(false)
    const [registrationError, setRegistrationError] = useState()

    const { secureToken } = useParams()

    const { instance } = useMsal()

    useEffect(() => {
        if (secureToken) {
            axios
                .get(`api/SignUp/GetUserBySecureToken?secureToken=${encodeURIComponent(secureToken)}`)
                .then((x) => {
                    setUser(x.data)
                })
                .catch((x) => setError(x.message))
        }
    }, [secureToken])

    const isPasswordCompliant = () =>
        password.length >= 8 && /[a-z]/.test(password) && /[A-Z]/.test(password) && /\d/.test(password)

    const isPasswordInError = () => password.length > 0 && !isPasswordCompliant()

    const isPasswordConfirmationInError = () =>
        isPasswordInError() || (passwordConfirmation.length > 0 && passwordConfirmation !== password)

    const canContinue = () => isPasswordCompliant() && password === passwordConfirmation && !registrationInProgress

    function handlePasswordChange(data: any) {
        setPassword(data.target.value)
    }

    function handlePasswordConfirmationChange(data: any) {
        setPasswordConfirmation(data.target.value)
    }

    function handleFinishRegistration(e: any) {
        e.preventDefault()
        setRegistrationInProgress(true)
        setRegistrationError(undefined)
        axios
            .post('api/SignUp/FinishRegistration', {
                secureToken,
                password
            })
            .then(() =>
                instance.logoutRedirect({
                    postLogoutRedirectUri: process.env.PUBLIC_URL
                })
            )
            .catch((x) => {
                setRegistrationError(x.message)
                setRegistrationInProgress(false)
            })
    }

    return (
        <>
            {error ? (
                <div style={{ color: 'red' }}>
                    The attempt to retrieve your information has failed. The link may have expired or your registration
                    may be finished. Please contact HealthCare Partners.
                    <hr />
                    {error}
                </div>
            ) : user ? (
                <StyledReigstrationContainer>
                    <StyledRegistrationMain>
                        <StyledHcpLogo>
                            <img src={hcplogo} alt="HealthCare Partners Logo" />
                            <StyledPassword>
                                <StyledPasswordImage></StyledPasswordImage>
                            </StyledPassword>
                        </StyledHcpLogo>
                        <StyledApi>
                            <StyledHeading>Welcome, {user.firstName}!</StyledHeading>
                            <form>
                                <StyledSubheading>
                                    Please choose a password with at least 8 characters, one lowercase letter, one
                                    uppercase letter and one number
                                </StyledSubheading>
                                <StyledEntryItem>
                                    <StyledLabel>Password</StyledLabel>
                                    <StyledInput
                                        type="password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        autoFocus
                                        style={isPasswordInError() ? { borderColor: 'red' } : {}}
                                        disabled={registrationInProgress}
                                    ></StyledInput>
                                </StyledEntryItem>
                                <StyledEntryItem>
                                    <StyledLabel>Confirm Password</StyledLabel>
                                    <StyledInput
                                        type="password"
                                        value={passwordConfirmation}
                                        onChange={handlePasswordConfirmationChange}
                                        style={isPasswordConfirmationInError() ? { borderColor: 'red' } : {}}
                                        disabled={registrationInProgress}
                                    ></StyledInput>
                                </StyledEntryItem>
                                <StyledButton
                                    type="submit"
                                    onClick={handleFinishRegistration}
                                    disabled={!canContinue()}
                                >
                                    Continue
                                </StyledButton>
                                {registrationError && <span style={{ color: 'red' }}>{registrationError}</span>}
                            </form>
                        </StyledApi>
                        <StyledFooter></StyledFooter>
                    </StyledRegistrationMain>
                </StyledReigstrationContainer>
            ) : (
                <>Loading, please wait...</>
            )}
        </>
    )
}

export default Registration
