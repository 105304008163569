import { FC } from 'react'
import { StyledAWVDigitalInput, StyleFieldResetContainer, StyledResetContainer } from '../index.style'
import { useFormContext } from 'react-hook-form'
import { Label } from '../Label'
import { AwvTooltip } from '../AwvTooltip'
import useErrors from 'hooks/useErrors'

interface InputFieldProps {
    type: string
    name: string
    label: string
    subLabel: string
    placeholder?: string
    readOnly?: boolean
}

export const InputField: FC<InputFieldProps> = ({ type, name, label, subLabel, placeholder, readOnly = false }) => {
    const { register } = useFormContext()
    const errors = useErrors()

    return (
        <AwvTooltip message={errors.getError(name)}>
            <StyledAWVDigitalInput isInError={errors.getError(name)}>
                <Label htmlFor={name} label={label} subLabel={subLabel} />
                <StyleFieldResetContainer>
                    <input
                        type={type}
                        id={name}
                        name={name}
                        aria-label={label}
                        placeholder={placeholder}
                        disabled={readOnly}
                        {...register(name)}
                    />
                    <StyledResetContainer></StyledResetContainer>
                </StyleFieldResetContainer>
            </StyledAWVDigitalInput>
        </AwvTooltip>
    )
}
