export const validateIconOff = `<svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<g fill="none" stroke="grey" stroke-linecap="round" stroke-width="1.5">
  <path d="M8.5 14.5h7.657"/>
  <path d="M8.5 10.5h7.657" />
  <path d="M8.5 6.5h7.657" />
  <path d="M5.5 14.5h0" />
  <path d="M5.5 10.5h0" />
  <path d="M5.5 6.5h0" />
</g>
<path fill="none" stroke="grey" stroke-linecap="round" stroke-width="2" d="M9.128 20.197H3.444a2.22 2.22 0 01-2.229-2.153V3.152A2.153 2.153 0 013.367.997h15.48A2.153 2.153 0 0121 3.152v8.738"/>
<path fill="grey" d="M16.5 23.499a1.464 1.464 0 01-1.094-.484l-2.963-2.969A1.479 1.479 0 0112 18.985a1.5 1.5 0 01.462-1.078 1.56 1.56 0 012.113.037l1.925 1.931 4.943-4.959a1.543 1.543 0 012.132.02 1.461 1.461 0 01.425 1.04 1.5 1.5 0 01-.45 1.068l-5.993 6.012a1.44 1.44 0 01-1.057.443z"/>
</svg>`

export const validateIconOn = `<svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<g fill="none" stroke="white" stroke-linecap="round" stroke-width="1.5">
  <path d="M8.5 14.5h7.657"/>
  <path d="M8.5 10.5h7.657" />
  <path d="M8.5 6.5h7.657" />
  <path d="M5.5 14.5h0" />
  <path d="M5.5 10.5h0" />
  <path d="M5.5 6.5h0" />
</g>
<path fill="none" stroke="white" stroke-linecap="round" stroke-width="2" d="M9.128 20.197H3.444a2.22 2.22 0 01-2.229-2.153V3.152A2.153 2.153 0 013.367.997h15.48A2.153 2.153 0 0121 3.152v8.738"/>
<path fill="white" d="M16.5 23.499a1.464 1.464 0 01-1.094-.484l-2.963-2.969A1.479 1.479 0 0112 18.985a1.5 1.5 0 01.462-1.078 1.56 1.56 0 012.113.037l1.925 1.931 4.943-4.959a1.543 1.543 0 012.132.02 1.461 1.461 0 01.425 1.04 1.5 1.5 0 01-.45 1.068l-5.993 6.012a1.44 1.44 0 01-1.057.443z"/>
</svg>`
