import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { white, grey, light_blue2, light_gray4 } = UI_COLORS

export const StyledModalContainer = styled.article`
    height: 450px;
    position: relative;

    h2 {
        color: #293854;
        font-family: 'Work Sans';
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 26.4px */
        letter-spacing: -0.22px;
        margin-bottom: 15px;
    }

    div {
        margin-top: 20px;
        margin-bottom: 10px;
        article {
            height: 130px;
        }
    }
`

export const StyledFieldSet = styled.fieldset`
    padding: 0;
    margin: 0;
    border: none;
`

export const StyledDescriptionWrap = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
        color: #293854;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
        margin: 0;
        margin-right: 6px;
    }

    span {
        color: #2281c4;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
    }
`

export const StyledButtonContainer = styled.article`
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: end;
`

const buttonStyles = css`
    height: ${pxToRem(50)};
    width: ${pxToRem(120)};

    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    border-radius: 4px;
    border: none;
    cursor: pointer;
`

export const StyledCancelButton = styled.button`
    ${buttonStyles}

    margin-right: ${pxToRem(16)};
    background-color: ${grey};
    color: ${white};

    :disabled {
        cursor: default;
        background: ${light_gray4};
    }

    :enabled {
        :active {
            background-color: ${white};
            color: ${grey};
            border: 1px solid ${grey};
        }
    }
`

export const StyledUploadButton = styled.button`
    ${buttonStyles}
    background-color: ${light_blue2};
    color: ${white};

    :disabled {
        cursor: default;
        background: ${light_gray4};
    }

    :enabled {
        :active {
            color: ${light_blue2};
            background-color: transparent;
            border: 1px solid ${light_blue2};
        }
    }
`

export const StyledSpinnerWrap = styled.article`
    display: flex;
    flex-direction: row;
    justify-content: center;

    width: 120px;
    height: 50px;

    article {
        height: 100%;
        svg {
            width: 50px;
            margin: 0;
        }
    }
`
