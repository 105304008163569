import { StyledAWVDigitalTitleContainer } from '../index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC } from 'react'

interface ISectionTitleProps {
    title: string
}

const SectionTitle: FC<ISectionTitleProps> = ({ title }) => {
    return (
        <StyledAWVDigitalTitleContainer>
            <Typography type={TYPOGRAPHY_TYPES.h2}>{title}</Typography>
        </StyledAWVDigitalTitleContainer>
    )
}

export default SectionTitle
