import { StyledActionsContainer, StyledButtonContainer, StyledCloseButton } from './index.style'
import Modal from 'components/Modal'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC, ReactNode, useState } from 'react'

interface IMessageBoxProps {
    title: string
    body: string | ReactNode 
    closeButtonLabel: string
    closeButtonColor: string
    onClose: (doNotShowAgain: boolean) => void
    showDoNotShowAgainCheckbox?: boolean
}

const MessageBox: FC<IMessageBoxProps> = ({
    title,
    body,
    closeButtonLabel,
    closeButtonColor,
    onClose,
    showDoNotShowAgainCheckbox = false
}) => {
    const [doNotShowAgain, setDoNotShowAgain] = useState(false)

    const handleOnClose = () => {
        onClose(doNotShowAgain)
    }

    return (
        <Modal onClose={() => onClose(false)} minWidth={600} minHeight={showDoNotShowAgainCheckbox ? 330 : 300}>
            <Typography type={TYPOGRAPHY_TYPES.h2}>{title}</Typography>
            <Typography type={TYPOGRAPHY_TYPES.p}>{body}</Typography>
            <StyledActionsContainer>
                {showDoNotShowAgainCheckbox && (
                    <label>
                        <input
                            type="checkbox"
                            checked={doNotShowAgain}
                            onChange={() => setDoNotShowAgain(!doNotShowAgain)}
                        />
                        Do not show again
                    </label>
                )}
                <StyledButtonContainer>
                    <StyledCloseButton onClick={handleOnClose} color={closeButtonColor}>
                        {closeButtonLabel}
                    </StyledCloseButton>
                </StyledButtonContainer>
            </StyledActionsContainer>
        </Modal>
    )
}

export default MessageBox
