import { FC, useEffect, useState } from 'react'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import Typography from 'components/Typography'

import ProviderReports from 'components/ProviderReports'
import { StyledReportsContainer } from './style'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import { dispatch, useSelector } from 'redux/store'
import { setNewReportCount, updateReportCount } from 'redux/slices/user'
import ProviderDropdown from 'components/ProviderDropdown'
import { StyledHeader } from './style'
import { Pulse8State, fetchOfficeAdminProviderNpis } from 'redux/slices/pulse8'
import Spinner from 'components/Spinner'
import { DocumentsState, getUserNewReportCount, updateReportCountByNpi } from 'redux/slices/documents'
import usePostCustomEvent from 'hooks/usePostCustomEvent'

interface IProps {
    providerNpi: string
    officeAdminUserId: string
}

const ProviderReportPage: FC<IProps> = ({ providerNpi, officeAdminUserId }) => {
    const reducerReportCount = (npi: string) => {
        dispatch(updateReportCount())
        dispatch(updateReportCountByNpi(npi))
    }

    const { officeAdminProviderNpis } = useSelector((state: { pulse8: Pulse8State }) => state.pulse8)

    const { newReportCountsByUserId } = useSelector((state: { documents: DocumentsState }) => state.documents)
    const [newReportCountsLoaded, setNewReportCountsLoaded] = useState(false)

    const [selectedNpi, setSelectedNpi] = useState<string>(providerNpi)

    const changeProviderEvent = usePostCustomEvent(pageTitle.UserReports, customEvents.ChangeProvider)

    useEffect(() => {
        if (officeAdminUserId && !officeAdminProviderNpis[officeAdminUserId]) {
            dispatch(fetchOfficeAdminProviderNpis({ userId: officeAdminUserId }))
        }
    }, [officeAdminUserId, officeAdminProviderNpis])

    useEffect(() => {
        if (officeAdminUserId && !newReportCountsLoaded) {
            dispatch(getUserNewReportCount({ userId: officeAdminUserId })).then(() => setNewReportCountsLoaded(true))
        }
    }, [officeAdminUserId, newReportCountsLoaded])

    useEffect(() => {
        if (newReportCountsLoaded && officeAdminUserId && newReportCountsByUserId[officeAdminUserId]) {
            dispatch(
                setNewReportCount({
                    userId: officeAdminUserId,
                    newReportCount: newReportCountsByUserId[officeAdminUserId].reduce(
                        (n, item: any) => n + item.count,
                        0
                    )
                })
            )
        }
    }, [officeAdminUserId, newReportCountsByUserId, newReportCountsLoaded])

    useEffect(() => {
        if (
            !selectedNpi &&
            officeAdminProviderNpis[officeAdminUserId]?.length > 0 &&
            newReportCountsLoaded &&
            newReportCountsByUserId[officeAdminUserId]
        ) {
            const npisWithNewReports = newReportCountsByUserId[officeAdminUserId].filter((x) => x.count > 0)
            if (
                npisWithNewReports.length > 0 &&
                officeAdminProviderNpis[officeAdminUserId].some((x) => x.npi === npisWithNewReports[0].npi)
            ) {
                setSelectedNpi(npisWithNewReports[0].npi)
            } else {
                setSelectedNpi(officeAdminProviderNpis[officeAdminUserId][0].npi)
            }
        }
    }, [selectedNpi, officeAdminUserId, officeAdminProviderNpis, newReportCountsByUserId, newReportCountsLoaded])

    const changeNpi = (npi: string) => {
        changeProviderEvent()
        setSelectedNpi(npi)
    }

    const renderTitle = () => (
        <Typography type={TYPOGRAPHY_TYPES.h3} style={{ width: '100%' }}>
            Reports
        </Typography>
    )

    return (
        <StyledReportsContainer>
            {selectedNpi && (!officeAdminUserId || officeAdminProviderNpis[officeAdminUserId]) ? (
                <>
                    <StyledHeader>
                        {renderTitle()}
                        {officeAdminUserId && (
                            <div>
                                <ProviderDropdown
                                    newProviderReportCounts={newReportCountsByUserId[officeAdminUserId]}
                                    providerNpis={officeAdminProviderNpis[officeAdminUserId]}
                                    selectedNpi={selectedNpi}
                                    setSelectedNpi={changeNpi}
                                />
                            </div>
                        )}
                    </StyledHeader>
                    <ProviderReports
                        npi={selectedNpi}
                        page={pageTitle.UserReports}
                        updateReportCount={reducerReportCount}
                        setUserDetailsNewReportCount={officeAdminUserId ? false : true}
                    />
                </>
            ) : officeAdminUserId && officeAdminProviderNpis[officeAdminUserId]?.length === 0 ? (
                <>
                    {renderTitle()}
                    <Typography type={TYPOGRAPHY_TYPES.p}>The user has no associated providers (NPIs).</Typography>
                </>
            ) : (
                <Spinner />
            )}
        </StyledReportsContainer>
    )
}

export default ProviderReportPage
