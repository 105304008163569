import { components } from 'react-select'
import { UI_COLORS } from 'theme'

const { white, black, light_gray3, light_gray4, dark_gray } = UI_COLORS

export interface IDropDownListStyleParams {
    placeholderTop?: string
    width?: number
    height?: number
    indicatorLeftTranslate?: number
    indicatorMarginTop?: string
    fontSize?: string
    fontWeight?: string
    color?: string
    backgroundColor?: string
    optionColor?: string
    optionBackgroundColor?: string
    paddingTop?: string
    paddingLeft?: string
    optionPaddingLeft?: string
    marginTop?: string
    marginLeft?: string
    singleValueTextAlign?: string
    singleValueMaxWidth?: string
    singleValueMarginTop?: string
    singleValueMarginLeft?: string
    singleValueFontSize?: string
    singleValueFontWeight?: string
    singleValueColor?: string
    placeholderFontSize?: string
    placeholderFontWeight?: string
    placeholderColor?: string
    placeholderLeft?: string
    inputColor?: string
    inputPaddingLeft?: string
    inputPaddingTop?: string
    zIndex?: string
}

export const createDropdownIndicator = (params: IDropDownListStyleParams) => {
    const color = params.color ?? black

    return (props: any) => (
        <components.DropdownIndicator {...props}>
            <svg width="10" height="5" viewBox="0 0 10 5" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.833252 0.333374L4.99992 4.50004L9.16659 0.333374H0.833252Z" fill={color} />
            </svg>
        </components.DropdownIndicator>
    )
}

export const createStyles = (params: IDropDownListStyleParams, isReadOnly: boolean, isInError: boolean) => {
    const width = params.width ?? 225
    const height = params.height ?? 50
    const indicatorLeftTranslate = params.indicatorLeftTranslate ?? 27
    const indicatorMarginTop = params.indicatorMarginTop ?? '-46px'
    const placeholderTop = params.placeholderTop ?? '0px !important'
    const placeholderLeft = params.placeholderLeft ?? params.paddingLeft ?? '6px'
    const fontSize = params.fontSize ?? '1rem'
    const fontWeight = params.fontWeight ?? '400'
    const color = params.color ?? dark_gray
    const optionColor = params.optionColor ?? dark_gray
    const backgroundColor = params.backgroundColor ?? white
    const paddingTop = params.paddingTop ?? '12px'
    const paddingLeft = params.paddingLeft ?? '6px'
    const optionPaddingLeft = params.optionPaddingLeft ?? '20px'
    const marginTop = params.marginTop ?? '10px'
    const marginLeft = params.marginLeft ?? '0px'
    const singleValueTextAlign = params.singleValueTextAlign ?? 'left'
    const singleValueMaxWidth = params.singleValueMaxWidth
    const singleValueMarginTop = params.singleValueMarginTop ?? '15px'
    const singleValueMarginLeft = params.singleValueMarginLeft ?? '0px'
    const singleValueColor = params.singleValueColor ?? UI_COLORS.black
    const placeholderColor = params.placeholderColor ?? UI_COLORS.light_gray3
    const inputColor = params.inputColor ?? 'transparent'
    const inputPaddingLeft = params.inputPaddingLeft ?? '0px'
    const inputPaddingTop = params.inputPaddingTop ?? '0px'

    return {
        dropdownIndicator: () => ({
            marginLeft: `${width - indicatorLeftTranslate}px !important`,
            marginTop: `${indicatorMarginTop} !important`,
            placeholderTop: `${placeholderTop} !important`
        }),
        option: (provided: any, { isSelected, isFocused }: any) => ({
            ...provided,
            color: optionColor,
            padding: 20,
            paddingLeft: optionPaddingLeft,
            backgroundColor: (isSelected && !isFocused && 'transparent') || (isFocused && '#F2F2F2') || 'white',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontSize,
            fontWeight,
            zIndex: '0',
            '&:hover': {
                backgroundColor: '#F2F2F2'
            }
        }),
        placeholder: (provided: any) => ({
            ...provided,
            paddingTop,
            paddingLeft: placeholderLeft,
            marginTop: placeholderTop,
            fontSize: params.placeholderFontSize ?? fontSize,
            fontWeight: params.placeholderFontWeight ?? fontWeight,
            color: placeholderColor,
            fontFamily: 'Inter'
        }),
        control: (provided: any, { isFocused }: any) => ({
            marginTop,
            marginLeft,
            paddingRight: '10px',
            width,
            height,
            backgroundColor: isReadOnly ? '#f5f5f5' : backgroundColor,
            color,
            border: `1px solid #73839C`,
            outline: isInError ? `1px solid red` : isFocused ? `1px solid ${'#2281c4'}` : 'none',
            borderRadius: '5px',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight,
            fontSize
        }),
        singleValue: (provided: any, state: any) => ({
            ...provided,
            opacity: 1,
            textAlign: singleValueTextAlign,
            paddingTop: '0px',
            isDisabled: true,
            paddingLeft,
            maxWidth: singleValueMaxWidth,
            marginLeft: `${singleValueMarginLeft} !important`,
            marginTop: `${singleValueMarginTop} !important`,
            fontSize: params.singleValueFontSize ?? fontSize,
            fontWeight: params.singleValueFontWeight ?? fontWeight,
            color: state.selectProps.menuIsOpen ? '#C2C2C2' : singleValueColor,
            fontFamily: 'Inter',
            fontStyle: 'normal',
            cursor: 'pointer'
        }),
        input: (provided: any) => ({
            ...provided,
            color: inputColor,
            paddingLeft: inputPaddingLeft,
            paddingTop: inputPaddingTop,
            fontFamily: 'Inter',
            cursor: 'pointer'
        })
    }
}
