import useAxiosPost from 'hooks/useAxiosPost'
import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

const Pulse8SingleSignOn = () => {
    const formRef = useRef<any>(null)
    const { npi } = useParams()
    const getSamlResponsePost = useAxiosPost(
        npi ? `api/Pulse8SingleSignOn/GenerateSaml/${npi}` : 'api/Pulse8SingleSignOn/GenerateSaml',
        {}
    )

    useEffect(() => {
        if (getSamlResponsePost.data) {
            formRef.current.submit()
        }
    }, [getSamlResponsePost])

    return (
        <>
            {getSamlResponsePost.error ? (
                <span style={{ color: 'red' }}>{getSamlResponsePost.error}</span>
            ) : (
                <>
                    Redirecting to Pulse8 - please wait...
                    <form ref={formRef} action={process.env.REACT_APP_PULSE8_SINGLE_SIGN_ON_FORM_ACTION} method="post">
                        <input
                            type="hidden"
                            id="SAMLResponse"
                            name="SAMLResponse"
                            value={getSamlResponsePost.data || ''}
                        />
                    </form>
                </>
            )}
        </>
    )
}

export default Pulse8SingleSignOn
