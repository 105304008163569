import Avatar from 'components/Avatar'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { AsYouType } from 'libphonenumber-js'
import { IHeaderInfo } from 'models/headerInfo'
import { SortDirection } from 'models/sortColumn'
import { IMemberOutreach, IMemberOutreachDetails } from 'pages/hcp/users/create/index.models'
import { currencyFormatter } from 'pages/provider/dashboard/YTDWidget/index.utils'
import { FC } from 'react'
import { getMemberOutreachSortColumn } from 'utilities/memberOutreachSortColumnMapper'
import { arrow, feedback, feedbackIndicatorIcon } from './icons'
import {
    StyledActionsContainer,
    StyledSpinner,
    StyledSpinnerContainer,
    StyledTableBodyDetailsItem,
    StyledTableBodyDetailsRow,
    StyledTableBodyFeedbackItem,
    StyledTableBodyFeedbackItemArticle,
    StyledTableBodyFeedbackRow,
    StyledTableBodyItem,
    StyledTableBodyRow,
    StyledTableBodyRowWrap,
    StyledTableButton,
    StyledTableContainer,
    StyledTableHeaderItem,
    StyledTableHeaderRow
} from './index.style'

const expandIconUrl = require('assets/expand.svg').default
const collapseIconUrl = require('assets/collapse.svg').default

interface IMemberOutreachTableProps {
    memberOutreaches: IMemberOutreach[]

    memberOutreachDetails: any

    memberOutreachDetailsLoading: any

    showMemberOutreachDetails(memberId: string, sortColumn: string, isDescending: boolean): void

    hideMemberOutreachDetails(memberId: string): void

    sortColumn: string

    sortDirection: SortDirection

    sortCallback(column: string, direction: SortDirection): void

    leaveFeedbackCallback(memberOutreach: IMemberOutreach): void
}

const HEADER_LIST: IHeaderInfo[] = [
    { displayName: 'Patient Name', fieldName: 'MemberName' },
    { displayName: 'Date of Birth', fieldName: 'MemberDateOfBirthUnformatted' },
    { displayName: 'Opportunities', fieldName: 'PendingIncentives' },
    { displayName: 'Total Incentive', fieldName: 'TotalIncentive' },
    { displayName: 'Amount Available', fieldName: 'IncentiveRemaining' },
    { displayName: 'Phone Number', fieldName: 'PhoneNumber' },
    { displayName: 'Status', fieldName: 'Status' },
    { displayName: 'Feedback', fieldName: 'Feedback' }
]

const MemberOutreachTable: FC<IMemberOutreachTableProps> = ({
    memberOutreaches,
    memberOutreachDetails,
    memberOutreachDetailsLoading,
    showMemberOutreachDetails,
    hideMemberOutreachDetails,
    sortColumn,
    sortDirection,
    sortCallback,
    leaveFeedbackCallback
}) => {
    const handleColumnClick = (column: string) => {
        if (column && column !== 'Feedback') {
            sortCallback(
                column,
                column === sortColumn
                    ? sortDirection === SortDirection.ascending
                        ? SortDirection.descending
                        : SortDirection.ascending
                    : SortDirection.ascending
            )
        }
    }

    const spinner = () => (
        <StyledSpinnerContainer>
            <StyledSpinner viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2.5" />
            </StyledSpinner>
        </StyledSpinnerContainer>
    )

    return (
        <StyledTableContainer>
            <StyledTableHeaderRow key={'main-table-header'}>
                <StyledTableHeaderItem key={'spacer-header'} isAction={false} />
                {HEADER_LIST.map((header: IHeaderInfo, idx: number) => {
                    return (
                        <StyledTableHeaderItem
                            key={`header-${idx}`}
                            onClick={() => handleColumnClick(header.fieldName)}
                            active={sortDirection === SortDirection.ascending}
                            isAction={header.fieldName !== 'Feedback'}
                        >
                            {header.displayName}
                            {sortColumn === header.fieldName && (
                                <CustomSvgIcon iconSet={{ icon: arrow }} svg></CustomSvgIcon>
                            )}
                        </StyledTableHeaderItem>
                    )
                })}
            </StyledTableHeaderRow>

            {memberOutreaches.map((memberOutreach, idx) => {
                const details = memberOutreachDetails[memberOutreach.membid] as IMemberOutreachDetails | undefined

                const handleClick = () => {
                    if (details) {
                        hideMemberOutreachDetails(memberOutreach.membid)
                    } else if (!memberOutreachDetailsLoading[memberOutreach.membid]) {
                        showMemberOutreachDetails(
                            memberOutreach.membid,
                            getMemberOutreachSortColumn(sortColumn),
                            sortDirection === SortDirection.descending
                        )
                    }
                }

                return (
                    <StyledTableBodyRowWrap
                        key={`memberOutreach-${idx}`}
                        onClick={handleClick}
                        tabIndex={1}
                        style={details ? { backgroundColor: '#f8f8f8' } : {}}
                    >
                        <article role="button" key={`memberOutreach-row-${idx}`}>
                            <StyledTableBodyRow key={`memberOutreachRow-${idx}`}>
                                <StyledTableBodyItem key={`expand-${idx}`}>
                                    {memberOutreachDetailsLoading[memberOutreach.membid] ? (
                                        spinner()
                                    ) : (
                                        <Avatar
                                            containerSize={25}
                                            avatarUrl={details ? collapseIconUrl : expandIconUrl}
                                            key={`avatar-${idx}`}
                                        />
                                    )}
                                </StyledTableBodyItem>
                                <StyledTableBodyItem key={`memberName-${idx}`}>
                                    {memberOutreach.memberName}
                                </StyledTableBodyItem>
                                <StyledTableBodyItem key={`memberDateOfBirth-${idx}`}>
                                    {memberOutreach.memberDateOfBirth}
                                </StyledTableBodyItem>
                                <StyledTableBodyItem key={`pendingIncentives-${idx}`}>
                                    {memberOutreach.pendingIncentives}
                                </StyledTableBodyItem>
                                <StyledTableBodyItem key={`totalIncentive-${idx}`}>
                                    {currencyFormatter.format(Number(memberOutreach.totalIncentive))}
                                </StyledTableBodyItem>
                                <StyledTableBodyItem key={`incentiveRemaining-${idx}`}>
                                    {currencyFormatter.format(Number(memberOutreach.incentiveRemaining))}
                                </StyledTableBodyItem>
                                <StyledTableBodyItem key={`phoneNumber-${idx}`}>
                                    {memberOutreach.phoneNumber &&
                                        new AsYouType('US').input(memberOutreach.phoneNumber)}
                                </StyledTableBodyItem>
                                <StyledTableBodyItem key={`status-${idx}`}>{memberOutreach.status}</StyledTableBodyItem>
                                <StyledTableBodyItem>
                                    <StyledActionsContainer key={`member-outreach-actions-${idx}`}>
                                        <StyledTableButton
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                leaveFeedbackCallback(memberOutreach)
                                            }}
                                            size={'48'}
                                            tabIndex={1}
                                            key={`leave-feedback-${idx}`}
                                            title="Feedback"
                                            hasFeedback={memberOutreach.hasNewFeedback}
                                        >
                                            <div>
                                                <CustomSvgIcon
                                                    iconSet={{ icon: feedbackIndicatorIcon }}
                                                    svg
                                                ></CustomSvgIcon>
                                            </div>
                                            <CustomSvgIcon iconSet={{ icon: feedback }} svg></CustomSvgIcon>
                                        </StyledTableButton>
                                    </StyledActionsContainer>
                                </StyledTableBodyItem>
                            </StyledTableBodyRow>
                            {details &&
                                details.memberOutreachDetails.map((detail, detailIndex) => (
                                    <StyledTableBodyDetailsRow
                                        key={`details-${idx}-${detailIndex}`}
                                        style={
                                            detailIndex === details.memberOutreachDetails.length - 1 &&
                                            !details.feedback
                                                ? { marginBottom: '30px' }
                                                : {}
                                        }
                                    >
                                        <StyledTableBodyDetailsItem
                                            key={`blank-${idx}-${detailIndex}`}
                                        ></StyledTableBodyDetailsItem>
                                        <StyledTableBodyDetailsItem key={`incentive-${idx}-${detailIndex}`}>
                                            {detail.incentive}
                                        </StyledTableBodyDetailsItem>
                                        <StyledTableBodyDetailsItem>
                                            {currencyFormatter.format(Number(detail.totalIncentive))}
                                        </StyledTableBodyDetailsItem>
                                        <StyledTableBodyDetailsItem>
                                            {currencyFormatter.format(Number(detail.incentiveRemaining))}
                                        </StyledTableBodyDetailsItem>
                                        <StyledTableBodyDetailsItem>{detail.status}</StyledTableBodyDetailsItem>
                                    </StyledTableBodyDetailsRow>
                                ))}
                            {details?.feedback && (
                                <StyledTableBodyFeedbackRow>
                                    <StyledTableBodyFeedbackItem></StyledTableBodyFeedbackItem>
                                    <StyledTableBodyFeedbackItem>
                                        <StyledTableBodyFeedbackItemArticle>
                                            Your feedback:
                                        </StyledTableBodyFeedbackItemArticle>
                                        <StyledTableBodyFeedbackItemArticle>
                                            {details.feedback}
                                        </StyledTableBodyFeedbackItemArticle>
                                    </StyledTableBodyFeedbackItem>
                                </StyledTableBodyFeedbackRow>
                            )}
                        </article>
                    </StyledTableBodyRowWrap>
                )
            })}
        </StyledTableContainer>
    )
}

export default MemberOutreachTable
