import { Fragment, MouseEventHandler } from 'react'
import {
    StyledMenu,
    StyledLogoImageMobile,
} from '../../index.styles'

import hcplogo from 'assets/hcp_logo.svg'

interface IMobileMenu {
    open: boolean
    onClick: MouseEventHandler<HTMLButtonElement>
}

const MobileMenu = ({open, onClick}: IMobileMenu) => {
    return(
        <Fragment>
            <StyledMenu open={open} onClick={onClick}>
                <div></div>
                <div></div>
                <div></div>
            </StyledMenu>
            <StyledLogoImageMobile image={hcplogo} mobile />
        </Fragment>
    )
}

export default MobileMenu
