import Modal from 'components/Modal'
import { FC, useEffect, useState } from 'react'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { StyledAWVHistoryModalContainer } from './index.style'
import { dispatch } from 'redux/store'
import { getAwvHistories } from 'redux/slices/memberProfile'
import { useSelector } from 'react-redux'
import AwvHistoryTable from './AwvHistoryTable/Index'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Spinner from 'components/Spinner'

interface IAWVHistoryProps {
    memberId: number
    npi: string
    year: number
    onClose: () => void
}


const AWVHistoryModal: FC<IAWVHistoryProps> = ({ memberId, npi, year, onClose }) => {
    const [initialContentLoaded, setInitialContentLoaded] = useState(false)

    useEffect(() => {
        if (npi && !initialContentLoaded) {
            dispatch(getAwvHistories({ memberId, npi, year })).then(() => {
                setInitialContentLoaded(true)
            })
        }
    }, [initialContentLoaded, getAwvHistories, memberId, npi, year])

    const { awvHistories: awvHistories } = useSelector((state: { memberProfile: any }) => state.memberProfile)

    return (

        <Modal minHeight={300} minWidth={850}>

            <StyledAWVHistoryModalContainer>
                <Typography type={TYPOGRAPHY_TYPES.h2}>AWV Form History</Typography>

                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                {/* <Typography type={TYPOGRAPHY_TYPES.h4}>MEMBER ID: {memberId}</Typography>
                <Typography type={TYPOGRAPHY_TYPES.h4}>NPI: {npi}</Typography>
                <Typography type={TYPOGRAPHY_TYPES.h4}>YEAR: {year}</Typography> */}
                {!initialContentLoaded && <Spinner />}

                {initialContentLoaded ?

                    <AwvHistoryTable awvHistories={awvHistories}></AwvHistoryTable>
                    : ''
                }

            </StyledAWVHistoryModalContainer>
        </Modal>
    )
}

export default AWVHistoryModal