import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'
import { EStatusType } from './index.utils'

const { green, yellow } = UI_COLORS

export const StyledStatusBadge = styled.article`
    width: ${pxToRem(70)};
    height: ${pxToRem(24)};

    border: 1px solid #dee5ef;
    box-sizing: border-box;
    border-radius: 12px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: ${pxToRem(10)};
`

export const StyledStatusBadgeColor = styled.span<{ status: EStatusType }>`
    width: ${pxToRem(7)};
    height: ${pxToRem(7)};
    border-radius: ${pxToRem(5)};

    margin-right: ${pxToRem(5)};

    ${(props) => {
        if (props.status === EStatusType.ACTIVE) {
            return css`
                background-color: ${green};
            `
        }
    }}

    ${(props) => {
        if (props.status === EStatusType.INACTIVE) {
            return css`
                background-color: red;
            `
        }
    }}

    ${(props) => {
        if (props.status === EStatusType.PENDING) {
            return css`
                background-color: ${yellow};
            `
        }
    }}
`
