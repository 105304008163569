import React, { FC } from 'react'
import { StyledStatusBadge, StyledStatusBadgeColor } from './index.style'
import { EStatusType } from './index.utils'

interface IStatusBadgeProps {
    status: any
}

export const StatusBadge: FC<IStatusBadgeProps> = ({ status }) => {
    return (
        <StyledStatusBadge>
            <StyledStatusBadgeColor status={status} />
            {status}
        </StyledStatusBadge>
    )
}
