import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { ETableType, SUPER_ADMIN_HEADERS_LIST } from 'components/UsersTable/index.utils'
import { superAdmin } from 'messages'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'redux/store'
import { fetchAdmins, fetchUsers } from 'redux/slices/user'
import { StyledAdminPageContainer, StyledHeader, StyledTableWrap } from './index.style'
import AdminUsersTable from 'components/AdminUsersTable'
import { ISortColumnInfo, SortDirection } from 'models/sortColumn'

interface IAdminsProps {}

const initialAPIBodyState = {
    pageNumber: 1,
    size: 100,
    sortColumn: 'LastName',
    filter: '',
    isDescending: false
}

const Admins: FC<IAdminsProps> = ({}) => {
    const dispatch = useDispatch()

    const InitialUserSortColumn: ISortColumnInfo = { sortColumn: 'LastName', direction: SortDirection.ascending }
    const [currentUserSortColumn, setCurrentUserSortColumn] = useState(InitialUserSortColumn)

    useEffect(() => {
        initialAPIBodyState.sortColumn = currentUserSortColumn.sortColumn
        initialAPIBodyState.isDescending = currentUserSortColumn.direction === SortDirection.descending

        dispatch(fetchAdmins(initialAPIBodyState))
    }, [dispatch, currentUserSortColumn.sortColumn, currentUserSortColumn.direction])

    // useEffect(() => {
    //     dispatch(fetchAdmins(initialAPIBodyState))
    // }, [])

    const { users } = useSelector((state: { user: any }) => state.user)

    const sortUserColumns = (header: string) => {
        if (currentUserSortColumn.sortColumn === header) {
            // already selected, just need to toggle
            setCurrentUserSortColumn({
                sortColumn: header,
                direction:
                    currentUserSortColumn.direction === SortDirection.ascending
                        ? SortDirection.descending
                        : SortDirection.ascending
            })
        } else {
            setCurrentUserSortColumn({ sortColumn: header, direction: SortDirection.ascending })
        }
    }

    return (
        <StyledAdminPageContainer>
            <StyledHeader>
                <Typography type={TYPOGRAPHY_TYPES.p}> {superAdmin.pageTitle}</Typography>
            </StyledHeader>
            <StyledTableWrap>
                <AdminUsersTable
                    users={users}
                    headersList={SUPER_ADMIN_HEADERS_LIST}
                    tableType={ETableType.SUPER_ADMIN}
                    sortCallback={sortUserColumns}
                />
            </StyledTableWrap>
        </StyledAdminPageContainer>
    )
}

export default Admins
