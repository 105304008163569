import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { setFormatedDate, setFormatedDateTime } from 'components/UsersTable/index.utils'
import { FC } from 'react'
import { UI_COLORS } from 'theme/constants'
import { StyledLoginOrInvitationStatusWrap } from './index.style'

interface ILoginOrInvitationStatusProps {
    userState: string
    invitationSent: string
    invitationExpired: boolean
    daysToInvitationExpiry: number
    lastLogin: string
}

const { dark_green, goldenOcher } = UI_COLORS

const LoginOrInvitationStatus: FC<ILoginOrInvitationStatusProps> = ({
    userState,
    invitationSent,
    invitationExpired,
    daysToInvitationExpiry,
    lastLogin
}) => {
    const statusLabel = userState === 'Pending' ? 'Invitation Status' : 'Last Login'

    const [status, statusColor] =
        userState === 'Pending'
            ? invitationExpired
                ? ['Expired', 'red']
                : daysToInvitationExpiry === 0
                ? ['Expires Today', goldenOcher]
                : daysToInvitationExpiry === 1
                ? ['Expires Tomorrow', goldenOcher]
                : daysToInvitationExpiry
                ? [`Expires in ${daysToInvitationExpiry} Days`, goldenOcher]
                : invitationSent
                ? [`Sent on ${setFormatedDate(invitationSent)}`, dark_green]
                : ['N/A', goldenOcher]
            : lastLogin
            ? [setFormatedDateTime(lastLogin), dark_green]
            : ['Has Not Logged In', goldenOcher]

    return (
        <StyledLoginOrInvitationStatusWrap>
            <Typography type={TYPOGRAPHY_TYPES.p}>
                <>
                    {statusLabel}: <span style={{ color: statusColor }}>{status}</span>
                </>
            </Typography>
        </StyledLoginOrInvitationStatusWrap>
    )
}

export default LoginOrInvitationStatus
