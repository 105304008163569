import DropdownSelect from 'components/DropdownSelect'
import MaskedTextField from 'components/MaskedTextField'
import Modal from 'components/Modal'
import TextField from 'components/TextField'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { parsePhoneNumber } from 'libphonenumber-js'
import React, { FC, useEffect, useState } from 'react'
import { removePhoneNumberPunctuation } from 'utilities/stringUtilities'
import { validatePhone, validateZipCode } from 'utilities/validationUtilities'
import { ILocationData } from '../../index.models'
import { StyledInputSelectLegend, StyledLocationTypeInputContainer, StyledLocationTypeSelect } from '../../index.style'
import { UsaStates } from '../AddressModal/index.models'
import { StyledButtonContainer, StyledCancelButton, StyledSaveButton } from '../AddressModal/index.style'
import {
    StyledButtonsContainer,
    StyledInputGroup,
    StyledLargeInputWrap,
    StyledLocationModalContainer,
    StyledMediumInputWrap,
    StyledSmallInputWrap
} from './index.style'

interface ILocationModalProps {    
    locationType?: number
    onCancel: () => void
    onSave: (formData: any, id: any) => void
    locationToEdit?: any
    validAreaCodes: number[]
}

const initialObject: ILocationData = {
    locationId: null,
    selectedLocationIndex: -1,
    locationType: 1,
    officePhoneNumber: '',
    address: '',
    suite: '',
    city: '',
    state: UsaStates.UNKNOWN,
    zipCode: '',
    ezCapLocationId: null,
    error: false,
    errorMessage: '',
    locationErrors: {
        locationTypeError: false,
        locationAddressError: false,
        officePhoneError: false,
        locationTypeErrorMessage: '',
        officePhoneErrorMessage: '',
        locationAddressErrorMessage: ''
    }
}



const LocationModal: FC<ILocationModalProps> = ({locationType, onCancel, onSave, locationToEdit, validAreaCodes}) => {
    const [formData, setFormData] = useState(initialObject)

    const [errors, setErrors] = useState({officePhoneNumber: '', address: '', suite: '', city: '', state: '', zipCode: '',  })
    const [isValidForm, setIsValidForm] = useState<boolean | null>(false)

    useEffect(() => {
        if (locationToEdit) {
            const receiveAddressToEdit : ILocationData = {
                ...formData,
                suite: locationToEdit.address.address2,
                address: locationToEdit.address.address1,
                city: locationToEdit.address.city,
                state: locationToEdit.address.state,
                zipCode: locationToEdit.address.zip,
                ezCapLocationId: locationToEdit.address.ezCapLocationId,
                officePhoneNumber: locationToEdit.address.officePhoneNumber,
                locationType: locationType
            }
    
            setFormData(receiveAddressToEdit)            
        }
    }, [locationToEdit])

    const handleClose = () => {
        console.log('closed')
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
        setIsValidForm(true)

        if (name === 'address') {
            setErrors({ ...errors, address: '' })
        }

        if (name === 'officePhoneNumber' && removePhoneNumberPunctuation(value)?.length > 0) {
            setErrors({ ...errors, officePhoneNumber: '' })
        }

        if (name === 'city') {
            setErrors({ ...errors, city: '' })
        }

        if (name === 'zipCode') {          
            setErrors({ ...errors, zipCode: '' })
        }
    }

    const handleSelectedState = (data: string) => {
        setFormData({ ...formData, state: UsaStates[data as keyof typeof UsaStates] })
        setErrors({ ...errors, state: '' })
    }

    const handleOfficePhoneBlur = () => {
        const { officePhoneNumber } = formData
        
        let newErrors = { ...errors }
        const  [isValidPhone, phoneError] = validatePhone(officePhoneNumber, newErrors, validAreaCodes)
        if (isValidPhone === false) {
            setErrors( {...newErrors, officePhoneNumber: phoneError })
        }
        
    }

    const handleZipOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        let newErrors = { ...errors }
        let isValid = true
        if (name === 'zipCode') {
            ({isValid, newErrors} = validateZipCode(value, newErrors, isValid))
            setErrors( newErrors )
        }
    }

    const validateNewAddressForm = () => {
        let newErrors = { ...errors }
        let isValid = true
        const { address, city, state, zipCode, officePhoneNumber } = formData

        if (!address) {
            newErrors = { ...newErrors, address: 'Required' }
            isValid = false
        }

        if (!city) {
            newErrors = { ...newErrors, city: 'Required' }
            isValid = false
        }

        if (state === UsaStates.UNKNOWN || !state) {
            newErrors = { ...newErrors, state: 'Required' }
            isValid = false
        }

        ({newErrors, isValid} = validateZipCode(zipCode, newErrors, isValid) )      
       

        const [isValidPhone, phoneError] = validatePhone(officePhoneNumber, newErrors, validAreaCodes)

        if (isValidPhone === false) {
            newErrors = { ...newErrors, officePhoneNumber: phoneError }
            isValid = isValidPhone && isValid
        }
     

        setIsValidForm(isValid)
        setErrors(newErrors)
        return isValid
    }

    const submitFormData = () => {
        const isValid = validateNewAddressForm()
        if (isValid) {
            onSave(formData, locationToEdit?.id)
        }
    }

    return (
        <Modal minHeight={450} minWidth={820} onClose={handleClose}>
            <StyledLocationModalContainer>
                <Typography type={TYPOGRAPHY_TYPES.h2}>Please enter your location</Typography>

                <StyledInputGroup>
                    <StyledMediumInputWrap>
                        <StyledInputSelectLegend>Location Type</StyledInputSelectLegend>
                        <StyledLocationTypeSelect value={formData.locationType} disabled>
                            <option value={1} key={`location-primary`}>
                                Primary Office
                            </option>
                            <option value={2} key={`location-secondary`}>
                                Secondary Office
                            </option>
                        </StyledLocationTypeSelect>
                    </StyledMediumInputWrap>

                    <StyledMediumInputWrap>
                        <StyledInputSelectLegend>Office Phone</StyledInputSelectLegend>
                        <MaskedTextField
                            name={`officePhoneNumber`}
                            placeholder={'Office Phone'}
                            inputType={'tel'}
                            onTextChanged={handleInput}
                            onBlur={handleOfficePhoneBlur}
                            mask={'(999) 999-9999'}
                            value={formData?.officePhoneNumber}
                            error={errors?.officePhoneNumber.length > 0}
                            errorMessage={errors?.officePhoneNumber}
                        />
                    </StyledMediumInputWrap>
                </StyledInputGroup>

                <StyledInputGroup>
                    <StyledLargeInputWrap>
                        <TextField
                            name="address"
                            placeholder="Street Address"
                            legend="Street Address"
                            inputType={'text'}
                            onTextChanged={handleInput}
                            value={formData.address}
                            error={errors?.address.length > 0}
                            errorMessage={errors?.address}
                        />
                    </StyledLargeInputWrap>

                    <StyledSmallInputWrap>
                        <TextField
                            name="suite"
                            placeholder="Office/Building Number"
                            legend="Office/Building Number"
                            inputType={'text'}
                            onTextChanged={handleInput}
                            value={formData.suite !== 'null' ? formData.suite : ''}
                        />
                    </StyledSmallInputWrap>
                </StyledInputGroup>

                <StyledInputGroup>
                    <StyledSmallInputWrap>
                        <TextField
                            name="city"
                            placeholder="City"
                            legend="City"
                            inputType={'text'}
                            onTextChanged={handleInput}
                            value={formData.city}
                            error={errors?.city.length > 0}
                            errorMessage={errors?.city}
                        />
                    </StyledSmallInputWrap>

                    <StyledSmallInputWrap>
                        <DropdownSelect stateValue={formData.state} onSelectedState={handleSelectedState}  error={errors?.state.length > 0} />
                    </StyledSmallInputWrap>

                    <StyledSmallInputWrap>
                        <TextField
                            name="zipCode"
                            placeholder="Zip Code"
                            pattern="^[0-9]*$"
                            maxlength={5}
                            legend="Zip Code"
                            inputType={'text'}
                            handleBlur={handleZipOnBlur}
                            onTextChanged={handleInput}
                            value={formData.zipCode}
                            error={errors?.zipCode.length > 0}
                            errorMessage={errors?.zipCode}
                        />
                    </StyledSmallInputWrap>
                </StyledInputGroup>

                <StyledButtonsContainer>
                    <StyledCancelButton onClick={onCancel}>Cancel</StyledCancelButton>
                    <StyledSaveButton onClick={() => {submitFormData()}}>Save</StyledSaveButton>
                </StyledButtonsContainer>
            </StyledLocationModalContainer>
        </Modal>
    )
}

export default LocationModal
