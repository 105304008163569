import { BasePickerProps } from '@mui/x-date-pickers/internals'
import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { light_gray2, light_gray3, light_gray4, dark_gray, light_blue2, light_blue3 } = UI_COLORS

export const StyledAWVFeedbackContainer = styled.article`
    position: fixed;
    z-index: 10;
    bottom: 24px;
    right: 24px;
`

export const StyledAWVFeedbackButton = styled.button<{ isActive?: boolean }>`
    position: relative;
    width: 48px;
    height: 48px;

    background-color: #2281c4;
    border: none;
    border-radius: 25px;
    box-shadow: 2px 2px 3px #999;
    transition: all 0.4s ease 0s;
    color: transparent;

    figure {
        margin: 0;
        margin-top: 5px;
        margin-left: 4px;
    }

    ${(props) => {
        if (props.isActive) {
            return css`
                width: 200px;
                display: flex;
                flex-direction: row;
                align-items: center;

                color: #ffffff;
                font-family: 'Inter', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;

                figure {
                    margin: 0;
                    margin-top: 5px;
                    margin-left: 14px;
                    margin-right: 14px;
                }

                :active {
                    background-color: #394a64;
                }
            `
        }
    }}

    cursor: pointer;
`

export const StyledAWVNewFeedback = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: -5px;
    right: -5px;
    width: 20px;
    height: 20px;
    border: 2px solid #f6f9fd;
    border-radius: 25px;
    background-color: #0bc196;

    color: #ffffff;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 15.6px */
    letter-spacing: -0.36px;
`
