import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem, DEVICE } from 'utilities'

const { light_blue2, white, grey, dark_gray } = UI_COLORS

export const StyledEditPageBackButton = styled.button`
    color: ${light_blue2};
    /* margin-left: ${pxToRem(15)}; */
    background-color: transparent;
    border: 0;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};

    cursor: pointer;

    figure {
        margin: ${pxToRem(0)};
        margin-right: ${pxToRem(15)};
        span {
            svg {
                path {
                    fill: ${light_blue2};
                }
            }
        }
    }
`

export const StyledEditPageButton = styled.button`
    color: ${light_blue2};
    background-color: transparent;
    border: 0;

    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};

    cursor: pointer;

    figure {
        margin: ${pxToRem(0)};
        margin-right: ${pxToRem(15)};
        span {
            svg {
                path {
                    fill: ${light_blue2};
                }
            }
        }
    }
`

export const StyledButtonsContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
export const StyledUserTypeContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${pxToRem(30)};

    p {
        color: ${dark_gray};
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        font-size: ${pxToRem(16)};
    }

    figure {
        margin: ${pxToRem(0)};
        span {
            svg {
                width: ${pxToRem(35)};
                height: ${pxToRem(35)};
                /* path {
                    fill: ${light_blue2};
                } */
            }
        }
    }
`

export const StyledBottomButtonsContainer = styled.article`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: ${pxToRem(30)};
    width: 100%;
    position: fixed;
    z-index: 9;
    bottom: 0;
    left: 0;
    background-color: white;
    border-top: 1px solid #dee5ef;

    ${DEVICE.tablet} {
        flex-direction: row;
        justify-content: flex-end;
    }
`
export const StyledButton = styled.button<{ primary?: boolean; secondary?: boolean }>`
    width: 100%;
    height: ${pxToRem(50)};
    border: 0;
    border-radius: ${pxToRem(4)};
    color: ${white};
    font-size: ${pxToRem(16)};
    font-family: 'Inter', sans-serif;
    font-weight: 600;

    cursor: pointer;

    ${(props) => {
        if (props.primary) {
            return css`
                background-color: ${light_blue2};
            `
        }
    }}
    ${(props) => {
        if (props.secondary) {
            return css`
                background-color: ${grey};
            `
        }
    }};

    ${DEVICE.tablet} {
        width: ${pxToRem(130)};
        margin-left: ${pxToRem(30)};
    }
`

export const StyledUserDetailsTitleContainer = styled.article`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: ${pxToRem(30)};

    ${DEVICE.desktop} {
        width: ${pxToRem(890)};
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0;
    }
`

export const StyledUserDetailsControllerContainer = styled.article`
    width: ${pxToRem(410)};
    margin-bottom: ${pxToRem(30)};
    display: flex;

    ${DEVICE.tablet} {
        width: ${pxToRem(510)};
    }
`

export const StyledUserDetailsControllerButton = styled.button`
    width: ${pxToRem(105)};
    height: ${pxToRem(44)};
    margin-right: ${pxToRem(18)};
    border-radius: ${pxToRem(4)};
    border: 1px solid #dee5ef;
    background-color: #ffffff;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};

    cursor: pointer;

    color: #394a64;

    figure {
        margin: 0;
        margin-right: ${pxToRem(10)};

        /* span {
            svg {
                path {
                    fill: ${light_blue2};
                }
            }
        } */
    }

    ${DEVICE.tablet} {
        width: ${pxToRem(170)};
    }
`
