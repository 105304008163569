import { FC } from 'react'
import {
    StyledAWVFileTableBody,
    StyledAWVFileTableBodyItem,
    StyledAWVFileTableContainer,
    StyledAWVFileTableHeader,
    StyledAWVFileTableHeaderItem,
    StyledAWVTableBodyRowWrap
} from '../index.style'
import { IAwvHistory } from 'pages/hcp/users/create/index.models'
import { setFormattedDateTimeWithSlash } from 'components/UsersTable/index.utils'

interface IFileTableProps {
    awvHistories: IAwvHistory[]
}

const tableItems = ['File Name', 'Type', 'Added On', 'Status']

const AwvHistoryTable: FC<IFileTableProps> = ({ awvHistories }) => (
    <>
        <StyledAWVFileTableHeader>
            {tableItems.map((headerItemLabel: string, idx: number) => {
                return (
                    <StyledAWVFileTableHeaderItem key={`headerLabel-${idx}`}>
                        {headerItemLabel}
                    </StyledAWVFileTableHeaderItem>
                )
            })}
        </StyledAWVFileTableHeader>

        {awvHistories.length > 0 &&
            <StyledAWVFileTableContainer>
                {awvHistories
                    .map((awvHistory: any, idx) => {
                        return (
                            <StyledAWVFileTableBody key={`awvHistory-${idx}`}>
                                <StyledAWVTableBodyRowWrap>
                                    <StyledAWVFileTableBodyItem>{awvHistory.fileName}</StyledAWVFileTableBodyItem>
                                    <StyledAWVFileTableBodyItem>{awvHistory.type}</StyledAWVFileTableBodyItem>
                                    <StyledAWVFileTableBodyItem>{setFormattedDateTimeWithSlash(awvHistory.addedOn)}</StyledAWVFileTableBodyItem>
                                    <StyledAWVFileTableBodyItem>{awvHistory.status}</StyledAWVFileTableBodyItem>
                                </StyledAWVTableBodyRowWrap>
                            </StyledAWVFileTableBody>
                        )
                    })}
            </StyledAWVFileTableContainer>
        }
    </>
)

export default AwvHistoryTable
