import { IDropDownListStyleParams } from 'components/DropDownList/index.style'
import { UI_COLORS } from 'theme'

export const dropDownListStyleParams: IDropDownListStyleParams = {
    width: 400,
    fontSize: '16px',
    paddingTop: '0px',
    paddingLeft: '9px !important',
    marginTop: '0px',
    indicatorMarginTop: '-92px',
    placeholderTop: '-10px !important',
    singleValueMarginTop: '-10px',
    singleValueFontSize: '16px',
    singleValueFontWeight: '400',
    singleValueColor: UI_COLORS.black,
    placeholderColor: UI_COLORS.light_gray3,
    placeholderFontSize: '16px',
    placeholderFontWeight: '500'
}
