import { FC, useEffect, useState } from 'react'
import AwvTemplateUpload from './AwvTemplateUpload'
import { EAdminNavBarItems } from './EAdminNavBarItems'
import AdminNavBar from './AdminNavBar'
import { AdminNavBarConfig } from './AdminNavBarConfig'
import { dispatch, useSelector } from 'redux/store'
import {
    MemberProfileState,
    getAwvElectronicFormTypes,
    postAwvElectronicForm,
    uploadAwvDocumentForm
} from 'redux/slices/memberProfile'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import usePostCustomEvent from 'hooks/usePostCustomEvent'
import Spinner from 'components/Spinner'
import DropDownList from 'components/DropDownList'
import { electronicFormTypeDropDownListStyleParams } from './index.style'

interface IMembersProps {}

const Admin: FC<IMembersProps> = ({}) => {
    const { awvElectronicFormTypes } = useSelector(
        (state: { memberProfile: MemberProfileState }) => state.memberProfile
    )

    const [selectedTab, setSelectedTab] = useState(EAdminNavBarItems.AwvPdfTemplateUpload)
    const [selectedAwvElectronicFormType, setSelectedAwvElectronicFormType] = useState<string>(null)

    const uploadAWVPDFEvent = usePostCustomEvent(pageTitle.AdminUpdateAWVPDFTemplate, customEvents.UploadAWVPDFTemplate)
    const uploadAWVElectronicEvent = usePostCustomEvent(
        pageTitle.AdminUpdateAWVPDFTemplate,
        customEvents.UploadAWVElectronicTemplate
    )

    useEffect(() => {
        if (!awvElectronicFormTypes) {
            dispatch(getAwvElectronicFormTypes())
        } else if (awvElectronicFormTypes.length > 0) {
            setSelectedAwvElectronicFormType(awvElectronicFormTypes[0].id)
        }
    }, [awvElectronicFormTypes])

    const handleSelectedTab = (selected: any) => {
        setSelectedTab(selected)
    }

    const handleAwvPdfTemplateUpload = async (file: any) => {
        uploadAWVPDFEvent()
        await dispatch(uploadAwvDocumentForm({ File: file }))
    }

    const handleAwvElectronicTemplateUpload = async (file: any) => {
        uploadAWVElectronicEvent()
        const awvForm = JSON.parse(await file.text())
        await dispatch(postAwvElectronicForm({ formTypeId: selectedAwvElectronicFormType, awvForm }))
    }

    return (
        <>
            <AdminNavBar navBarConfig={AdminNavBarConfig} selectedTab={handleSelectedTab} />
            {selectedTab === EAdminNavBarItems.AwvPdfTemplateUpload ? (
                <>
                    <AwvTemplateUpload
                        contentType="application/pdf"
                        extension=".pdf"
                        title="Upload your new AWV PDF Template here."
                        uploadAwvTemplate={handleAwvPdfTemplateUpload}
                    />
                </>
            ) : selectedTab === EAdminNavBarItems.AwvElectronicTemplateUpload ? (
                awvElectronicFormTypes ? (
                    <>
                        <AwvTemplateUpload
                            contentType="application/json"
                            extension=".json"
                            title="Upload your new electronic AWV Template here."
                            uploadAwvTemplate={handleAwvElectronicTemplateUpload}
                            innerContent={
                                <DropDownList
                                    options={awvElectronicFormTypes.map((x) => ({ label: x.name, value: x.id }))}
                                    selectedValue={selectedAwvElectronicFormType}
                                    setSelectedValue={setSelectedAwvElectronicFormType}
                                    styleParams={electronicFormTypeDropDownListStyleParams}
                                />
                            }
                        />
                    </>
                ) : (
                    <Spinner />
                )
            ) : (
                ''
            )}
        </>
    )
}

export default Admin
