export const excelExport = `<svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.8099 7.32802C12.858 7.71391 12.7381 8.10186 12.4806 8.39327C12.2123 8.68463 11.8343 8.85041 11.4382 8.85041C11.0422 8.85041 10.6642 8.68463 10.3959 8.39327C10.1384 8.10186 10.0185 7.71391 10.0666 7.32802L10.3945 4.68262H12.4792L12.8099 7.32802Z" stroke="white" stroke-width="1.5"/>
<path d="M11.4368 10.9365C11.5808 10.9365 11.6974 11.0532 11.6974 11.1971C11.6974 11.341 11.5808 11.4577 11.4368 11.4577C11.2929 11.4577 11.1763 11.341 11.1763 11.1971C11.1763 11.0532 11.2929 10.9365 11.4368 10.9365" stroke="white" stroke-width="1.5"/>
<path d="M11.4368 14.0635C11.5808 14.0635 11.6974 14.1801 11.6974 14.3241C11.6974 14.468 11.5808 14.5846 11.4368 14.5846C11.2929 14.5846 11.1763 14.468 11.1763 14.3241C11.1763 14.1801 11.2929 14.0635 11.4368 14.0635" stroke="white" stroke-width="1.5"/>
<path d="M11.4368 17.1904C11.5808 17.1904 11.6974 17.3071 11.6974 17.451C11.6974 17.5949 11.5808 17.7116 11.4368 17.7116C11.2929 17.7116 11.1763 17.5949 11.1763 17.451C11.1763 17.3071 11.2929 17.1904 11.4368 17.1904" stroke="white" stroke-width="1.5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.7329 20.3174H7.26742C6.69176 20.3174 6.2251 19.8507 6.2251 19.2751V5.72493C6.2251 5.14928 6.69176 4.68262 7.26742 4.68262H16.7379C17.0142 4.68268 17.2791 4.7924 17.4745 4.98767L19.4702 6.98336C19.6655 7.17876 19.7752 7.44368 19.7752 7.71993V19.2751C19.7752 19.8507 19.3086 20.3174 18.7329 20.3174Z" stroke="white" stroke-width="1.5"/>
</svg>`

export const excelExportActive = `<svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.8099 7.32802C12.858 7.71391 12.7381 8.10186 12.4806 8.39327C12.2123 8.68463 11.8343 8.85041 11.4382 8.85041C11.0422 8.85041 10.6642 8.68463 10.3959 8.39327C10.1384 8.10186 10.0185 7.71391 10.0666 7.32802L10.3945 4.68262H12.4792L12.8099 7.32802Z" stroke="#2281C4" stroke-width="1.5"/>
<path d="M11.4368 10.9365C11.5808 10.9365 11.6974 11.0532 11.6974 11.1971C11.6974 11.341 11.5808 11.4577 11.4368 11.4577C11.2929 11.4577 11.1763 11.341 11.1763 11.1971C11.1763 11.0532 11.2929 10.9365 11.4368 10.9365" stroke="#2281C4" stroke-width="1.5"/>
<path d="M11.4368 14.0635C11.5808 14.0635 11.6974 14.1801 11.6974 14.3241C11.6974 14.468 11.5808 14.5846 11.4368 14.5846C11.2929 14.5846 11.1763 14.468 11.1763 14.3241C11.1763 14.1801 11.2929 14.0635 11.4368 14.0635" stroke="#2281C4" stroke-width="1.5"/>
<path d="M11.4368 17.1904C11.5808 17.1904 11.6974 17.3071 11.6974 17.451C11.6974 17.5949 11.5808 17.7116 11.4368 17.7116C11.2929 17.7116 11.1763 17.5949 11.1763 17.451C11.1763 17.3071 11.2929 17.1904 11.4368 17.1904" stroke="#2281C4" stroke-width="1.5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.7329 20.3174H7.26742C6.69176 20.3174 6.2251 19.8507 6.2251 19.2751V5.72493C6.2251 5.14928 6.69176 4.68262 7.26742 4.68262H16.7379C17.0142 4.68268 17.2791 4.7924 17.4745 4.98767L19.4702 6.98336C19.6655 7.17876 19.7752 7.44368 19.7752 7.71993V19.2751C19.7752 19.8507 19.3086 20.3174 18.7329 20.3174Z" stroke="#2281C4" stroke-width="1.5"/>
</svg>`

export const downloadAwv =`<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.75815 2.23674C6.75815 1.55095 7.3141 0.995003 7.99989 0.995003C8.68568 0.995003 9.24162 1.55095 9.24162 2.23674V8.0426C9.24162 8.13103 9.3133 8.2027 9.40173 8.2027H11.1045C11.3478 8.2028 11.5686 8.3449 11.6695 8.56626C11.7704 8.78762 11.7328 9.04751 11.5733 9.23119L8.46866 12.8021C8.3508 12.9379 8.17977 13.016 7.99989 13.016C7.82001 13.016 7.64898 12.9379 7.53112 12.8021L4.42646 9.23119C4.26695 9.04751 4.22935 8.78762 4.33024 8.56626C4.43114 8.3449 4.65196 8.2028 4.89523 8.2027H6.59805C6.68647 8.2027 6.75815 8.13103 6.75815 8.0426V2.23674ZM14.0549 12.6183C14.0549 12.1896 14.4024 11.8421 14.831 11.8421C15.0374 11.8412 15.2357 11.9226 15.3819 12.0683C15.5282 12.2139 15.6104 12.4119 15.6104 12.6183V13.8062C15.6104 15.2209 14.4635 16.3678 13.0488 16.3678H2.95098C1.53749 16.366 0.392578 15.2197 0.392578 13.8062V12.6183C0.392578 12.1896 0.740079 11.8421 1.16874 11.8421C1.59741 11.8421 1.94491 12.1896 1.94491 12.6183V13.8062C1.94561 14.3614 2.39574 14.8113 2.95098 14.8116H13.0488C13.604 14.8113 14.0542 14.3614 14.0549 13.8062V12.6183Z" fill="#2281C4"/>
</svg>
`
