import { ReactNode, CSSProperties } from 'react'
export enum TYPOGRAPHY_TYPES {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
    p = 'p',
    code = 'code',
    span = 'span',
    i = 'i',
    b = 'b',
    strong = 'strong',
    time = 'time'
}

export interface ITypographyProps {
    id?: string
    type: TYPOGRAPHY_TYPES
    children: string | string[] | ReactNode | ReactNode[]
    style?: CSSProperties
    tabIndex?: number
    className?: string
    dateTime?: string
    variant?: string
}
