import { useNavigateToIncentive } from 'hooks/useNavigateToIncentive'
import { ITopIncentive } from 'pages/hcp/users/create/index.models'
import { FC } from 'react'
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts'
import { currencyFormatter } from '../../YTDWidget/index.utils'
import { StyledCustomTooltip, StyledTooltipItem, StyledTooltipTotal, StyledTopOppsChartsWrapper } from '../index.style'
import { CustomizedWellnessAxisTick, CustomizedYAxisTick } from '../index.utilities'

interface IAnnualMembersChartProps {
    membersData: ITopIncentive
    npi?: string
}

interface IMembersData {
    incentive: string
    totalMembers: number
    totalMembersEarned: number
    totalMembersNotEarned: number
}

const AnnualWellnessMembersChart: FC<IAnnualMembersChartProps> = ({ membersData, npi }) => {
    const data: IMembersData[] = []
    const navigateToIncentive = useNavigateToIncentive()

    if (!membersData) return

    let membersObject = {
        incentive: membersData.incentive,
        totalMembersNotEarned: membersData.totalMembersNotEarned,
        totalMembersEarned: membersData.totalMembersEarned,
        totalMembersClosed: membersData.totalMembersClosed,
        totalMembers: membersData.totalMembers
    }

    data.push(membersObject)

    const yAxisFormatter = (currency: string) => {
        if (currency === '0') {
            return '0'
        } else {
            // return yAxisCurrencyFormatter.format(Number(currency))
            return currencyFormatter.format(Number(currency))
        }
    }

    const sortMemberData = (): IMembersData[] => {
        return data.sort((a: IMembersData, b: IMembersData) => b.totalMembersEarned - a.totalMembersEarned)
    }

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <StyledCustomTooltip>
                    <StyledTooltipItem>
                        <span style={{ backgroundColor: '#E3EEF3' }}></span>
                        <h5>Remaining:</h5>
                    </StyledTooltipItem>
                    <p>{`${payload[0].payload.totalMembersNotEarned}`}</p>
                    <StyledTooltipItem>
                        <span style={{ backgroundColor: '#9ADDFF' }}></span>
                        <h5>Closed:</h5>
                    </StyledTooltipItem>
                    <p>{`${payload[0].payload.totalMembersClosed}`}</p>
                    <StyledTooltipItem>
                        <span style={{ backgroundColor: '#40BFFF' }}></span>
                        <h5>Paid:</h5>
                    </StyledTooltipItem>
                    <p>{`${payload[0].payload.totalMembersEarned}`}</p>

                    <StyledTooltipTotal>
                        <p>Total:</p>
                        <p>{`${payload[0].payload.totalMembers}`}</p>
                    </StyledTooltipTotal>
                </StyledCustomTooltip>
            )
        }

        return null
    }

    return (
        <div style={{ overflow: 'hidden', overflowY: 'hidden', width: '220px', position: 'relative', top: '-20px' }}>
            <StyledTopOppsChartsWrapper>
                <BarChart
                    key={Math.random()}
                    width={250}
                    height={250}
                    data={data}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 30
                    }}
                >
                    <CartesianGrid strokeDasharray="1" vertical={false} />
                    <XAxis
                        padding={{ left: -110 }}
                        stroke={'#CCCCCC'}
                        strokeDasharray={1}
                        strokeWidth={1}
                        dataKey="incentive"
                        tick={<CustomizedWellnessAxisTick x="0" y="0" />}
                        tickLine={false}
                        onClick={(e: any) => navigateToIncentive(e.value)}
                    />
                    <YAxis
                        stroke={'white'}
                        strokeWidth={1}
                        tickLine={false}
                        tick={<CustomizedYAxisTick x="0" y="0" />}
                        interval={0}
                    />
                    <Tooltip content={<CustomTooltip />} cursor={false} />
                    <Bar
                        barSize={50}
                        dataKey="totalMembersEarned"
                        stackId="a"
                        fill="#26B7FF"
                        onClick={(e: any) => navigateToIncentive(e.incentive)}
                    ></Bar>
                    <Bar
                        barSize={50}
                        dataKey="totalMembersClosed"
                        stackId="a"
                        fill="#75D1FF"
                        onClick={(e: any) => navigateToIncentive(e.incentive)}
                    ></Bar>{' '}
                    {/* change datakey once we get data from DB */}
                    <Bar
                        barSize={50}
                        dataKey="totalMembersNotEarned"
                        stackId="a"
                        fill="#E3EEF3"
                        onClick={(e: any) => navigateToIncentive(e.incentive)}
                    >
                        {/* <LabelList
                            dataKey="totalMembersEarned"
                            content={renderThickCustomizedLabel}
                            position="insideRight"
                            style={{ fill: 'white' }}
                        /> */}
                    </Bar>
                </BarChart>
            </StyledTopOppsChartsWrapper>
        </div>
    )
}

export default AnnualWellnessMembersChart
