import { FC } from 'react'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { StyledSubLabel } from './index.style'

interface ILabelProps {
    htmlFor?: string
    label: string
    subLabel: string
    wrapInParagraph?: boolean
}

export const Label: FC<ILabelProps> = ({ htmlFor, label, subLabel, wrapInParagraph }) => {
    if (wrapInParagraph) {
        return (
            <>
                {label && (
                    <Typography type={TYPOGRAPHY_TYPES.p}>
                        {label}
                        {subLabel && <StyledSubLabel>&nbsp;{subLabel}</StyledSubLabel>}
                    </Typography>
                )}
            </>
        )
    } else
        return (
            <>
                {label && (
                    <label htmlFor={htmlFor}>
                        {label}
                        {subLabel && <StyledSubLabel>&nbsp;{subLabel}</StyledSubLabel>}
                    </label>
                )}
            </>
        )
}
