import { FC, useEffect } from 'react'
import { activeMembersIcon, emptyYTD, outreachMembersIcon, YTDGraphIcon } from './icons'
import {
    StyledEmptyStateContainer,
    StyledYTDContainer,
    StyledYTDContainerInner,
    StyledYTDGraphContainer,
    StyledYTDGraphMetricsGroup,
    StyledYTDMetricsContainer,
    StyledYTDRemainingLabel,
    StyledYTDRemainingLabelWrap
} from './index.style'
import SingleMetricItemGroup from './SingleMetricItemGroup'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { IBannerIncentive } from 'pages/hcp/users/create/index.models'
import { currencyFormatter } from './index.utils'
import { useNavigate } from 'react-router-dom'
import { appPath } from 'utilities/appPath'
import { useMemberOutreachListConfig } from 'hooks/useListConfig'
import { useSelector } from 'react-redux'
import { IncentiveState, fetchYearsForNpi } from 'redux/slices/incentive'
import { dispatch } from 'redux/store'

const COLORS = ['#26B7FF', '#E3EEF3']

interface IYTDWidgetProps {
    npi: string
    setSelectedYear: (year: number) => void
    setSelectedLob: (lob: string) => void
    refreshIncentives: (npi: string, year: number | null, lob: string | null) => void
    selectedYear: number | null
    selectedLob: string | null
}

const YTDWidget: FC<IYTDWidgetProps> = ({ npi, refreshIncentives, setSelectedYear, selectedYear, selectedLob }) => {
    const NotApplicable: string = 'N/A'
    const YTDdata: IBannerIncentive = useSelector(
        (state: { incentive: IncentiveState }) => state.incentive?.bannerIncentive
    )

    const data = [
        { name: 'totalIncentiveEarned', value: YTDdata.totalIncentiveEarned },
        { name: 'totalIncentiveRemaining', value: YTDdata.totalIncentiveRemaining }
    ]

    const totalIncentiveEmpty = data[0].value === 0

    const navigate = useNavigate()
    const memberOutreachListConfig = useMemberOutreachListConfig()

    const navigateToMemberOutreach = () => {
        navigate(appPath('/memberOutreach'))
    }

    const handleNavigateToMemberOutreach = () => {
        memberOutreachListConfig.clearFilter()
        navigateToMemberOutreach()
    }

    useEffect(() => {
        if (npi == null || npi.length == 0) return
        dispatch(fetchYearsForNpi({ npi: npi }))
    }, [npi])

    useEffect(() => {
        //if either year or lob changes, fetch all the data again
        if (npi == null || npi.length == 0) return
        refreshIncentives(npi, selectedYear, selectedLob)
    }, [selectedYear, selectedLob])

    return (
        <>
            {/* <StyledLOBDropDownContainer>
                        <StyledLOBIconForIncentives image={lineOfBusinessIcon} />
                        <DropDownList
                            key="lob-key"
                            placeholder="Line Of Business"
                            options={linesOfBusinessDropDownData}
                            selectedValue={selectedLob}
                            setSelectedValue={(value) => setSelectedLob(value)}
                            styleParams={LOBIncentivesDropDownListStyleParams}
                        />
                    </StyledLOBDropDownContainer>  */}

            <StyledYTDContainer>
                <Typography type={TYPOGRAPHY_TYPES.h2}>Overview</Typography>
                <StyledYTDContainerInner>
                    <StyledYTDGraphContainer>
                        {!totalIncentiveEmpty ? (
                            <>
                                <Typography type={TYPOGRAPHY_TYPES.p}>YTD Incentive Paid</Typography>
                                <ResponsiveContainer width="100%" height="28%">
                                    <PieChart width={215} height={215}>
                                        <Pie
                                            data={data}
                                            cy={100}
                                            startAngle={180}
                                            endAngle={0}
                                            innerRadius={70}
                                            outerRadius={100}
                                            fill="#8884d8"
                                            paddingAngle={0}
                                            dataKey="value"
                                        >
                                            {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </>
                        ) : (
                            <StyledEmptyStateContainer>
                                <Typography type={TYPOGRAPHY_TYPES.p}>YTD Incentive Paid</Typography>
                                <CustomSvgIcon iconSet={{ icon: emptyYTD }} svg />
                            </StyledEmptyStateContainer>
                        )}
                        {!totalIncentiveEmpty && (
                            <StyledYTDGraphMetricsGroup>
                                <CustomSvgIcon iconSet={{ icon: YTDGraphIcon }} svg></CustomSvgIcon>
                                <Typography type={TYPOGRAPHY_TYPES.p}>
                                    {YTDdata.totalIncentiveEarned === 0
                                        ? NotApplicable
                                        : currencyFormatter.format(YTDdata.totalIncentiveEarned)}
                                </Typography>
                                <Typography type={TYPOGRAPHY_TYPES.span}>
                                    Total:{' '}
                                    {YTDdata.totalIncentiveBudgeted == null
                                        ? NotApplicable
                                        : currencyFormatter.format(YTDdata.totalIncentiveBudgeted)}
                                </Typography>
                            </StyledYTDGraphMetricsGroup>
                        )}
                        {YTDdata.totalIncentiveRemaining !== 0 && (
                            <StyledYTDRemainingLabel>
                                <StyledYTDRemainingLabelWrap>
                                    <span />
                                    <Typography type={TYPOGRAPHY_TYPES.h3}>Remaining</Typography>
                                </StyledYTDRemainingLabelWrap>
                                <Typography type={TYPOGRAPHY_TYPES.p}>
                                    {currencyFormatter.format(YTDdata.totalIncentiveRemaining)}
                                </Typography>
                            </StyledYTDRemainingLabel>
                        )}

                        <Typography
                            type={TYPOGRAPHY_TYPES.span}
                            style={
                                YTDdata.totalIncentiveBudgeted
                                    ? { marginBottom: '5px', marginRight: '10px' }
                                    : { marginBottom: '12px', marginRight: '20px' }
                            }
                        >
                            Total:{' '}
                            {YTDdata.totalIncentiveBudgeted == null
                                ? NotApplicable
                                : currencyFormatter.format(YTDdata.totalIncentiveBudgeted)}
                        </Typography>
                    </StyledYTDGraphContainer>
                    <StyledYTDMetricsContainer>
                        <SingleMetricItemGroup
                            svgIcon={activeMembersIcon}
                            metricTitle={'Active Patients'}
                            metricAmount={
                                String(YTDdata.numOfCurrentMembers) === 'null'
                                    ? NotApplicable
                                    : String(YTDdata.numOfCurrentMembers)
                            }
                            metricAdditionalAmount={
                                String(YTDdata.changeInMembers) === 'null'
                                    ? NotApplicable
                                    : String(YTDdata.changeInMembers)
                            }
                        />

                        <SingleMetricItemGroup
                            svgIcon={outreachMembersIcon}
                            metricTitle={'Outreach Patients'}
                            metricAmount={
                                String(YTDdata.outreachMembers) === 'null'
                                    ? NotApplicable
                                    : String(YTDdata.outreachMembers)
                            }
                            handleClick={() => {
                                handleNavigateToMemberOutreach()
                            }}
                        />
                    </StyledYTDMetricsContainer>
                </StyledYTDContainerInner>
            </StyledYTDContainer>
        </>
    )
}

export default YTDWidget
