import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { light_gray2, light_gray3, light_gray4, dark_gray, light_blue2, light_blue3 } = UI_COLORS

const tableReset = css`
    list-style: none;
    padding: 0;
    display: flex;
`

export const StyledTableContainer = styled.article`
    width: ${pxToRem(1145)};

    /* height: ${pxToRem(715)}; */
    height: auto;

    background-color: white;
    margin-bottom: ${pxToRem(30)};
`

export const StyledTableHeaderRow = styled.ul`
    ${tableReset}
    background-color: ${light_blue3};
    padding-left: ${pxToRem(15)};
    height: ${pxToRem(40)};
    border-bottom: 1px solid ${light_blue3};

    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 0;
    margin-bottom: 0;
`

export const StyledScheduleDate = styled.div`
    width: 97px;
    height: 12px;
    position: relative;
    top: 5px;
    /* Paragraph Extra Small/Semi-Bold */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 120%;
    /* identical to box height, or 12px */

    /* HCP Blue 1 */

    color: #2281c4;
`
export const StyledDisabledScheduleDate = styled.div`
    width: 97px;
    height: 12px;
    position: relative;
    top: 5px;
    /* Paragraph Extra Small/Semi-Bold */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 120%;
`

export const StyledTableHeaderItem = styled.li<{ active?: boolean }>`
    // margin-right: ${pxToRem(15)};
    color: ${light_gray3};

    font-family: 'Inter', sans-serif;
    font-style: normal;

    font-size: 12px;
    font-weight: 600;
    line-height: 16px;

    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    // Status
    &:nth-child(1) {
        width: 60px;
        text-align: left;
        /* background-color: red; */
    }

    // last name
    &:nth-child(2) {
        width: 100px;
        /* background-color: blue; */
    }

    // First Name
    &:nth-child(3) {
        width: 100px;
        /* background-color: orange; */
    }

    // DOB
    &:nth-child(4) {
        width: 70px;
        /* background-color: yellow; */
    }

    // Provider
    &:nth-child(5) {
        width: 100px;
    }

    //phone number
    &:nth-child(6) {
        width: 105px;
        /* background-color: red; */
    }

    //Member ID
    &:nth-child(7) {
        width: 95px;
        /* background-color: blue; */
    }

    /* Line of bussiness */
    &:nth-child(8) {
        width: 60px;
        /* background-color: grey; */
    }

    // Health Plan
    &:nth-child(9) {
        width: 95px;
        /* background-color: green; */
    }

    // Last visit
    &:nth-child(10) {
        width: 85px;
        /* background-color: tomato; */
    }
    // Next AWV date
    :nth-child(11) {
        width: 90px;
        /* background-color: tomato; */
    }

    // AWV Status
    &:nth-child(12) {
        width: 95px;
    }

    :hover {
        cursor: pointer;
    }

    figure {
        margin: 0;
        ${(props) => {
            if (props.active) {
                return css`
                    transform: rotate(180deg) translateY(10%);
                `
            }
        }}
    }
`

export const StyledTableBodyRowWrap = styled.article`
    display: flex;
    border: 1px solid ${light_gray4};
    border-top: 0;
    border-left: 0;
    border-right: 0;

    :hover {
        background-color: ${light_gray2};
        cursor: pointer;
    }

    article {
        &:nth-child(2) {
            display: flex;
            align-items: center;
        }
    }
`

export const StyledTableBodyRow = styled.ul`
    ${tableReset}

    height: 75px;

    padding-left: ${pxToRem(15)};
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
`

export const StyledTableBodyItem = styled.li<{ statusTopMargin?: number }>`
    // margin-right: ${pxToRem(15)};
    color: ${dark_gray};

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(12)};
    line-height: 16px;
    // Status
    &:nth-child(1) {
        width: 60px;
        text-align: left;
        /* background-color: red; */
    }

    // last name
    &:nth-child(2) {
        width: 90px;
        /* background-color: blue; */
    }

    // First Name
    &:nth-child(3) {
        width: 90px;
        /* background-color: orange; */
    }

    // DOB
    &:nth-child(4) {
        width: 80px;
        /* background-color: yellow; */
    }

    // Provider
    &:nth-child(5) {
        width: 105px;
    }

    //phone number
    &:nth-child(6) {
        width: 105px;
        /* background-color: red; */
    }

    //Member ID
    &:nth-child(7) {
        width: 95px;
        /* background-color: blue; */
    }

    /* Line of bussiness */
    &:nth-child(8) {
        width: 65px;
        /* background-color: grey; */
    }

    // Health Plan
    &:nth-child(9) {
        width: 95px;
        /* background-color: green; */
    }

    // Last visit
    &:nth-child(10) {
        width: 85px;
        /* background-color: tomato; */
    }
    // Next AWV Date
    :nth-child(11) {
        width: 85px;
        /* background-color: tomato; */
    }

    // AWV Status
    &:nth-child(12) {
        width: 85px;
    }
`

export const StyledTableBodyDetailsRow = styled.ul`
    ${tableReset}

    height: 60px;

    padding-left: ${pxToRem(15)};
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
`
/* TODO : To be removed. Seems orphan css 
export const StyledTableBodyDetailsItem = styled.li`
    margin-right: ${pxToRem(15)};
    color: ${dark_gray};

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(12)};
    line-height: 16px;

    &:nth-child(1) {
        width: 25px;
    }

    &:nth-child(2) {
        width: 425px;
    }

    &:nth-child(3) {
        width: 135px;
    }

    &:nth-child(4) {
        width: 270px;
    }

    &:nth-child(5) {
        width: 150px;
    }
`

export const StyledTableBodyFeedbackRow = styled.ul`
    ${tableReset}

    margin-bottom: ${pxToRem(30)} !important;

    padding-left: ${pxToRem(15)};
    padding-top: ${pxToRem(19)};
    padding-bottom: ${pxToRem(19)};
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
`

export const StyledTableBodyFeedbackItem = styled.li`
    margin-right: ${pxToRem(15)};
    color: ${dark_gray};

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(12)};
    line-height: 16px;

    &:nth-child(1) {
        width: 25px;
    }

    &:nth-child(2) {
        width: 1005px;
    }
`

export const StyledTableBodyFeedbackItemArticle = styled.article`
    &:nth-child(2) {
        font-weight: 500;
        padding-top: ${pxToRem(5)};
    }
`
*/

export const StyledAwvStatusLink = styled.a`
    color: #2281c4;
    &:hover {
        // lighter blue
        color: #4d9ee0;
        cursor: pointer;
    }
`

export const StyledAwvStatusNoLink = styled.span``

export const StyledDownloadAwvCell = styled.div``

export const StyledTableButton = styled.button<{ image?: string; size: string }>`
    background-color: transparent;
    border: 0;

    background-repeat: no-repeat;
    background-size: cover;

    cursor: pointer;

    :hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
        border-radius: 20px;
    }

    ${(props) => {
        if (props.image) {
            return css`
                background-image: url(${props.image});
            `
        }
    }}

    ${(props) => {
        if (props.size) {
            return css`
                width: ${props.size}px;
                height: ${props.size}px;
            `
        }
    }}
`

export const StyledSpinnerContainer = styled.article`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const StyledSpinner = styled.svg`
    animation: rotate 2s linear infinite;
    margin: ${pxToRem(2)};

    & .path {
        stroke: ${light_blue2};
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
`

export const StyledEligibilityBadgeContainer = styled.article`
    width: 30px;
    height: 30px;
    border-radius: 30px;
    /* margin-left: 90px; */
    padding: 0 20px;
    background-color: #dee5ef;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;

    color: #394a64;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

export const StyledBadgeContainer = styled.article<{ isElegible?: boolean }>`
    figure {
        margin: 0;
        margin-left: 2px;
    }

    ${(props) => {
        if (props.isElegible) {
            return css`
                /* font-weight: 600; */
            `
        }
    }}
`

export const StyledActionsContainer = styled.article`
    width: 70px;
    display: flex;
    flex-direction: row;
    align-items: right;
`
export const StyledActionsContainerHeader = styled.article`
    width: 70px;
    color: ${light_gray3};
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
`

export const StyledActionButton = styled.button<{ hasNewFeedback?: boolean }>`
    margin-left: ${pxToRem(5)};
    width: ${pxToRem(25)};
    height: ${pxToRem(25)};
    padding: 0;
    background-color: transparent;
    border: none;

    position: relative;

    figure {
        margin: 0;
    }

    cursor: pointer;

    div {
        display: none;
    }
    ${(props) => {
        if (props.hasNewFeedback) {
            return css`
                div {
                    display: block;
                    position: absolute;
                    top: 7px;
                    right: 12px;
                }
            `
        }
    }}
`

export const StyledActionButtonPlaceholder = styled.article`
    margin-left: ${pxToRem(5)};
    width: ${pxToRem(25)};
    height: ${pxToRem(25)};
`
