import { useNavigateToIncentive } from 'hooks/useNavigateToIncentive'
import { ITopIncentive } from 'pages/hcp/users/create/index.models'
import { FC } from 'react'
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts'
import { currencyFormatter } from '../../YTDWidget/index.utils'
import { StyledCustomTooltip, StyledTooltipItem, StyledTooltipTotal, StyledTopOppsChartsWrapper } from '../index.style'
import { CustomizedAxisTick, CustomizedYAxisTick } from '../index.utilities'

interface IMembersChartProps {
    membersData: ITopIncentive[]
    maxMember: number
    npi?: string
}

interface IMembersData {
    incentive: string
    totalMembers: number
    totalMembersEarned: number
    totalMembersNotEarned: number
    totalMembersClosed: number
}

const MembersChart: FC<IMembersChartProps> = ({ membersData, maxMember, npi }) => {
    const navigateToIncentive = useNavigateToIncentive()
    const data: IMembersData[] = []

    membersData.map((memberItem: IMembersData) => {
        if (memberItem.incentive === 'Annual Wellness Visit') {
            return
        }
        let incentivesObject = {
            incentive: memberItem.incentive,
            totalMembersNotEarned: memberItem.totalMembersNotEarned,
            totalMembersEarned: memberItem.totalMembersEarned,
            totalMembersClosed: memberItem.totalMembersClosed,
            totalMembers: memberItem.totalMembers
        }
        data.push(incentivesObject)
    })

    const yAxisFormatter = (currency: string) => {
        if (currency === '0') {
            return '0'
        } else {
            // return yAxisCurrencyFormatter.format(Number(currency))
            return currencyFormatter.format(Number(currency))
        }
    }

    const sortMemberData = (): IMembersData[] => {
        return data.sort((a: IMembersData, b: IMembersData) => b.totalMembersNotEarned - a.totalMembersNotEarned)
    }

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <StyledCustomTooltip>
                    <StyledTooltipItem>
                        <span style={{ backgroundColor: '#E3EEF3' }}></span>
                        <h5>Remaining:</h5>
                    </StyledTooltipItem>
                    <p>{`${payload[0].payload.totalMembersNotEarned}`}</p>
                    <StyledTooltipItem>
                        <span style={{ backgroundColor: '#9ADDFF' }}></span>
                        <h5>Closed:</h5>
                    </StyledTooltipItem>
                    <p>{`${payload[0].payload.totalMembersClosed}`}</p>
                    <StyledTooltipItem>
                        <span style={{ backgroundColor: '#40BFFF' }}></span>
                        <h5>Paid:</h5>
                    </StyledTooltipItem>
                    <p>{`${payload[0].payload.totalMembersEarned}`}</p>

                    <StyledTooltipTotal>
                        <p>Total:</p>
                        <p>{`${payload[0].payload.totalMembers}`}</p>
                    </StyledTooltipTotal>
                </StyledCustomTooltip>
            )
        }

        return null
    }

    return (
        <div style={{ overflow: 'hidden', overflowY: 'hidden', position: 'relative', top: '-20px' }}>
            <StyledTopOppsChartsWrapper>
                <BarChart
                    key={Math.random()}
                    width={180 * (data.length + 0.5)}
                    height={250}
                    data={sortMemberData()}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 30
                    }}
                >
                    <CartesianGrid strokeDasharray="1" vertical={false} />
                    <XAxis
                        padding={{ left: -80 }}
                        stroke={'#CCCCCC'}
                        strokeDasharray={1}
                        strokeWidth={1}
                        dataKey="incentive"
                        tick={<CustomizedAxisTick x="0" y="0" />}
                        interval={0}
                        tickLine={false}
                        onClick={(e: any) => navigateToIncentive(e.value)}
                    />
                    <YAxis
                        stroke={'white'}
                        strokeWidth={1}
                        tickLine={false}
                        tick={<CustomizedYAxisTick x="0" y="0" />}
                        interval={0}
                        domain={[0, maxMember]}
                    />
                    <Tooltip content={<CustomTooltip />} cursor={false} />
                    <Bar
                        barSize={25}
                        dataKey="totalMembersEarned"
                        stackId="a"
                        fill="#26B7FF"
                        onClick={(e: any) => navigateToIncentive(e.incentive)}
                    ></Bar>
                    <Bar
                        barSize={50}
                        dataKey="totalMembersClosed"
                        stackId="a"
                        fill="#75D1FF"
                        onClick={(e: any) => navigateToIncentive(e.incentive)}
                    ></Bar>{' '}
                    {/* change datakey once we get data from DB */}
                    <Bar
                        barSize={25}
                        dataKey="totalMembersNotEarned"
                        stackId="a"
                        fill="#E3EEF3"
                        onClick={(e: any) => navigateToIncentive(e.incentive)}
                    >
                        {/* <LabelList
                            dataKey="totalMembersEarned"
                            content={renderCustomizedLabel}
                            position="insideRight"
                            style={{ fill: 'white' }}
                        /> */}
                    </Bar>
                </BarChart>
            </StyledTopOppsChartsWrapper>
        </div>
    )
}

export default MembersChart
