import { FC } from 'react'
import {
    StyledContainer,
    StyledCopyRightContainer,
    StyledImgContainer,
    StyledPainAssesmentContainer,
    StyledRadioBtnListContainer,
    StyledRadioBtnListItem,
    StyledPainAssessmentResetContainer,
    StyledResetContainer
} from './index.style'
import { Controller, useFormContext } from 'react-hook-form'
import { IPainAssessmentSelection, ISectionElement } from '../index.models'
import { Label } from '../Label'
import { AwvTooltip } from '../AwvTooltip'
import useErrors from 'hooks/useErrors'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp'
import { useResetValue } from 'hooks/useResetValue'

interface IPainAssessmentSelectionProps {
    name: string
    label: string
    subLabel: string
}

const painAssessmentValues = [0, 2, 4, 6, 8, 10]

const PainAssessmentSelection: FC<IPainAssessmentSelectionProps> = ({ name, label, subLabel }) => {
    const { setValue, control, getValues } = useFormContext()
    const resetValue = useResetValue()
    const errors = useErrors()

    return (
        <StyledContainer>
            <Label htmlFor={name} label={label} subLabel={subLabel} wrapInParagraph={true} />
            <AwvTooltip message={errors.getError(name)}>
                <StyledPainAssessmentResetContainer isInError={errors.getError(name)}>
                    <StyledPainAssesmentContainer>
                        <StyledImgContainer></StyledImgContainer>
                        <StyledRadioBtnListContainer>
                            {painAssessmentValues.map((value: number, idx: number) => (
                                <StyledRadioBtnListItem key={`painValue-${idx}`}>
                                    <Controller
                                        key={`${name}-${idx}`}
                                        name={name}
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                id={`${name}.${value}`}
                                                type="radio"
                                                value={value}
                                                checked={field.value === value}
                                                onChange={(e) => {
                                                    setValue(name, Number(e.target.value).valueOf())
                                                }}
                                            />
                                        )}
                                    />
                                </StyledRadioBtnListItem>
                            ))}
                        </StyledRadioBtnListContainer>
                        <StyledCopyRightContainer>
                            <p>
                                Copyright 1983, Wong-Baker FACES Foundation, www.WongBakerFACES.org. Used with
                                permission.
                            </p>
                        </StyledCopyRightContainer>
                    </StyledPainAssesmentContainer>
                    <StyledResetContainer>
                        {getValues(name) !== undefined && getValues(name) !== null && (
                            <Tooltip title="Reset">
                                <IconButton
                                    onClick={() => {
                                        resetValue(name)
                                    }}
                                >
                                    <HighlightOffSharpIcon sx={{ color: '#9e9e9e' }} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </StyledResetContainer>
                </StyledPainAssessmentResetContainer>
            </AwvTooltip>
        </StyledContainer>
    )
}

export const renderPainAssessmentSelection = (element: ISectionElement, key: string) => {
    const painAssessmentSelection = element as IPainAssessmentSelection
    return <PainAssessmentSelection key={key} {...painAssessmentSelection} />
}
