import { PublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import CustomSvgIcon from 'components/CustomSvgIcon'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { getB2CPasswordResetMsalConfig, getLoginRequest } from 'config/auth'
import { Fragment } from 'react'

import { StyledMainMenuContainer, StyledMainMenuItem } from '../../index.styles'
import { changePasswordIcon, logOut } from './icons'
import { StyledLogOutButtonBackground } from './index.style'

interface IMainLogout {
    active: boolean
    onBackgroundClick: () => void
}

interface IMainMenuItem {
    label: string
    icon: string
    action: () => void
    identityProvider?: string
}

const MainLogout = ({ active, onBackgroundClick }: IMainLogout) => {
    const { instance } = useMsal()

    const handleLogout = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: '/'
        })
    }

    const handleChangePassword = () => {
        const msal = new PublicClientApplication(getB2CPasswordResetMsalConfig())
        msal.loginRedirect(getLoginRequest())
    }

    const menuItems: IMainMenuItem[] = [
        { label: 'Change Password', icon: changePasswordIcon, action: handleChangePassword, identityProvider: 'B2C' },
        { label: 'Sign Out', icon: logOut, action: handleLogout }
    ]

    return (
        <Fragment>
            {active && (
                <Fragment>
                    <StyledLogOutButtonBackground onClick={onBackgroundClick}></StyledLogOutButtonBackground>
                    <StyledMainMenuContainer>
                        {menuItems
                            .filter(
                                (x) =>
                                    !x.identityProvider ||
                                    x.identityProvider === process.env.REACT_APP_IDENTITY_PROVIDER
                            )
                            .map((item: IMainMenuItem, idx: number) => {
                                return (
                                    <StyledMainMenuItem key={`menuItem-${idx}`} onClick={item.action}>
                                        <CustomSvgIcon iconSet={{ icon: item.icon }} svg></CustomSvgIcon>
                                        <Typography type={TYPOGRAPHY_TYPES.p}>{item.label}</Typography>
                                    </StyledMainMenuItem>
                                )
                            })}
                    </StyledMainMenuContainer>
                </Fragment>
            )}
        </Fragment>
    )
}

export default MainLogout
