export const redCrossIcon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Bold / delete-2">
<path id="Icons / Bold / delete-2" fill-rule="evenodd" clip-rule="evenodd" d="M5.29002 5.28612C7.89286 2.68247 12.1134 2.68115 14.7178 5.28318C17.3184 7.89004 17.3184 12.11 14.7178 14.7169C12.1134 17.3189 7.89286 17.3176 5.29002 14.7139C2.68718 12.1103 2.68718 7.88977 5.29002 5.28612ZM12.5973 12.5864C12.8054 12.3774 12.8141 12.0423 12.6171 11.8228L10.8882 10.0945C10.8341 10.0403 10.8341 9.95253 10.8882 9.89829L12.6171 8.16943C12.8343 7.95237 12.8344 7.60032 12.6173 7.38311C12.4003 7.16589 12.0482 7.16577 11.831 7.38283L10.1022 9.11168C10.0761 9.13782 10.0407 9.15251 10.0038 9.15251C9.96684 9.15251 9.93144 9.13782 9.90537 9.11168L8.17707 7.38283C7.95708 7.1842 7.61995 7.19309 7.41074 7.40304C7.20154 7.61299 7.19384 7.95015 7.39324 8.16943L9.1221 9.89829C9.14821 9.92426 9.16288 9.95958 9.16288 9.9964C9.16288 10.0332 9.14821 10.0685 9.1221 10.0945L7.39324 11.8228C7.28886 11.9271 7.2302 12.0686 7.2302 12.2161C7.2302 12.3637 7.28886 12.5052 7.39324 12.6094C7.6127 12.8211 7.96039 12.8211 8.17985 12.6094L9.90815 10.8806C9.9627 10.8267 10.0504 10.8267 10.1049 10.8806L11.8338 12.6094C12.0541 12.8055 12.3892 12.7954 12.5973 12.5864Z" fill="#B11B1B"/>
<mask id="mask0_17442_44591" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="3" y="3" width="14" height="14">
<path id="Icons / Bold / delete-2_2" fill-rule="evenodd" clip-rule="evenodd" d="M5.29002 5.28612C7.89286 2.68247 12.1134 2.68115 14.7178 5.28318C17.3184 7.89004 17.3184 12.11 14.7178 14.7169C12.1134 17.3189 7.89286 17.3176 5.29002 14.7139C2.68718 12.1103 2.68718 7.88977 5.29002 5.28612ZM12.5973 12.5864C12.8054 12.3774 12.8141 12.0423 12.6171 11.8228L10.8882 10.0945C10.8341 10.0403 10.8341 9.95253 10.8882 9.89829L12.6171 8.16943C12.8343 7.95237 12.8344 7.60032 12.6173 7.38311C12.4003 7.16589 12.0482 7.16577 11.831 7.38283L10.1022 9.11168C10.0761 9.13782 10.0407 9.15251 10.0038 9.15251C9.96684 9.15251 9.93144 9.13782 9.90537 9.11168L8.17707 7.38283C7.95708 7.1842 7.61995 7.19309 7.41074 7.40304C7.20154 7.61299 7.19384 7.95015 7.39324 8.16943L9.1221 9.89829C9.14821 9.92426 9.16288 9.95958 9.16288 9.9964C9.16288 10.0332 9.14821 10.0685 9.1221 10.0945L7.39324 11.8228C7.28886 11.9271 7.2302 12.0686 7.2302 12.2161C7.2302 12.3637 7.28886 12.5052 7.39324 12.6094C7.6127 12.8211 7.96039 12.8211 8.17985 12.6094L9.90815 10.8806C9.9627 10.8267 10.0504 10.8267 10.1049 10.8806L11.8338 12.6094C12.0541 12.8055 12.3892 12.7954 12.5973 12.5864Z" fill="white"/>
</mask>
<g mask="url(#mask0_17442_44591)">
</g>
</g>
</svg>`

export const deleteIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5002 2.5V3.33333H16.6668V5H15.8335V15.8333C15.8335 16.75 15.0835 17.5 14.1668 17.5H5.8335C4.91683 17.5 4.16683 16.75 4.16683 15.8333V5H3.3335V3.33333H7.50016V2.5H12.5002ZM5.8335 15.8333H14.1668V5H5.8335V15.8333ZM7.50016 6.66667H9.16683V14.1667H7.50016V6.66667ZM12.5002 6.66667H10.8335V14.1667H12.5002V6.66667Z" fill="#B11B1B"/>
</svg>`

export const disabledDeleteIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5002 2.5V3.33333H16.6668V5H15.8335V15.8333C15.8335 16.75 15.0835 17.5 14.1668 17.5H5.8335C4.91683 17.5 4.16683 16.75 4.16683 15.8333V5H3.3335V3.33333H7.50016V2.5H12.5002ZM5.8335 15.8333H14.1668V5H5.8335V15.8333ZM7.50016 6.66667H9.16683V14.1667H7.50016V6.66667ZM12.5002 6.66667H10.8335V14.1667H12.5002V6.66667Z" fill="#A4B0C0"/>
</svg>`
