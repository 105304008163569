import { IGapInCare, IGapInCareDocument } from 'pages/hcp/users/create/index.models'
import { FC } from 'react'
import {
    StyledTableBodyItem,
    StyledTableBodyRowOdd,
    StyledTableBodyRowContainer,
    StyledTableContainerWrap,
    StyledTableHeader,
    StyledTableHeaderItem,
    StyledTableBodyRowEven,
    StyledActionsContainer,
    StyledTableButton,
    StyledServiceNoteCheckMarkContainer
} from './index.style'
import React from 'react'
import DocumentList from '../DocumentList'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import CustomSvgIcon from 'components/CustomSvgIcon'
import {
    feedbackIcon,
    disabledFeedbackIcon,
    newFeedbackIcon,
    disabledNewFeedbackIcon,
    deleteIcon,
    disabledDeleteIcon,
    disabledEditIcon,
    editIcon
} from './icons'
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import { setFormattedDateWithSlash } from 'components/UsersTable/index.utils'
import { StyledEmptyState } from '../index.style'
import { noData } from 'pages/provider/memberOutreach/icons'

interface IGapsInCareTableProps {
    gapsInCare: IGapInCare[]
    yearOpen: boolean
    isReadOnly: boolean
    isBusy: boolean
    onChangeReferralSpecialist: (gapInCare: IGapInCare) => void
    onClearReferralSpecialist: (gapInCare: IGapInCare) => void
    onView: (gapInCare: IGapInCare) => void
    onEdit: (gapInCare: IGapInCare) => void
    onDelete: (gapInCare: IGapInCare) => void
    onFeedback: (gapInCare: IGapInCare) => void
    onDownloadDocument: (document: IGapInCareDocument) => void
    onDeleteDocument: (document: IGapInCareDocument) => void
    onUploadDocument: (gapInCare: IGapInCare) => void
}

const GapsInCareTable: FC<IGapsInCareTableProps> = ({
    gapsInCare,
    yearOpen,
    isReadOnly,
    isBusy,
    onView,
    onEdit,
    onDelete,
    onFeedback,
    onDownloadDocument,
    onDeleteDocument,
    onUploadDocument
}) => (
    <StyledTableContainerWrap>
        <StyledTableHeader>
            <StyledTableHeaderItem>Gap In Care</StyledTableHeaderItem>
            <StyledTableHeaderItem>Source</StyledTableHeaderItem>
            <StyledTableHeaderItem>Last DOS</StyledTableHeaderItem>
            <StyledTableHeaderItem>Status</StyledTableHeaderItem>
            <StyledTableHeaderItem>Service Note</StyledTableHeaderItem>
            <StyledTableHeaderItem>Actions</StyledTableHeaderItem>
        </StyledTableHeader>
        {gapsInCare.length > 0 ? (
            <StyledTableBodyRowContainer>
                {gapsInCare.map((gapInCare) => {
                    const isClosed = !yearOpen || gapInCare.isCompliant
                    const isBusyOrClosed = isClosed || isBusy
                    const isBusyClosedOrReadOnly = isBusyOrClosed || isReadOnly
                    const isBusyOrClosedOrNotUserCreated = isClosed || isBusy || !gapInCare.isUserCreated

                    return (
                        <React.Fragment key={`gapInCare-${gapInCare.id}`}>
                            <StyledTableBodyRowOdd>
                                <StyledTableBodyItem>
                                    <Typography type={TYPOGRAPHY_TYPES.p}>{gapInCare.gap}</Typography>
                                </StyledTableBodyItem>
                                <StyledTableBodyItem>
                                    <Typography type={TYPOGRAPHY_TYPES.p}>
                                        {gapInCare.isUserCreated ? 'Provider' : 'Health Plan'}
                                    </Typography>
                                </StyledTableBodyItem>
                                <StyledTableBodyItem>
                                    <Typography type={TYPOGRAPHY_TYPES.p}>
                                        {setFormattedDateWithSlash(gapInCare.lastCompletionDate, true)}
                                    </Typography>
                                </StyledTableBodyItem>
                                <StyledTableBodyItem>
                                    <Typography type={TYPOGRAPHY_TYPES.p}>
                                        {gapInCare.isCompliant ? 'Compliant' : 'Not Compliant'}
                                    </Typography>
                                </StyledTableBodyItem>
                                <StyledTableBodyItem>
                                    <Typography type={TYPOGRAPHY_TYPES.p}>
                                        <StyledServiceNoteCheckMarkContainer>
                                            {gapInCare?.serviceNote && (
                                                <CheckOutlinedIcon
                                                    sx={{
                                                        color: 'green',
                                                        height: '19px',
                                                        padding: '0'
                                                    }}
                                                />
                                            )}
                                        </StyledServiceNoteCheckMarkContainer>
                                    </Typography>
                                </StyledTableBodyItem>
                                <StyledTableBodyItem>
                                    <StyledActionsContainer>
                                        <StyledTableButton
                                            onClick={() => onView(gapInCare)}
                                            disabled={isBusy}
                                            title={isBusy ? '' : 'View'}
                                        >
                                            <ZoomInOutlinedIcon sx={{ color: isBusy ? '#aaaaaa' : '#2281C4' }} />
                                        </StyledTableButton>
                                        {!isReadOnly && (
                                            <StyledTableButton
                                                onClick={() => onEdit(gapInCare)}
                                                disabled={isBusyOrClosed}
                                                title={isBusyOrClosed ? '' : 'Edit'}
                                            >
                                                <CustomSvgIcon
                                                    iconSet={{
                                                        icon: isBusyOrClosed ? disabledEditIcon : editIcon
                                                    }}
                                                    svg
                                                ></CustomSvgIcon>
                                            </StyledTableButton>
                                        )}
                                        <StyledTableButton
                                            onClick={() => onFeedback(gapInCare)}
                                            disabled={isBusy}
                                            title={isBusy ? '' : 'Feedback'}
                                        >
                                            <CustomSvgIcon
                                                iconSet={{
                                                    icon: isBusy
                                                        ? gapInCare.newFeedbackCount
                                                            ? disabledNewFeedbackIcon
                                                            : disabledFeedbackIcon
                                                        : gapInCare.newFeedbackCount
                                                        ? newFeedbackIcon
                                                        : feedbackIcon
                                                }}
                                                svg
                                            ></CustomSvgIcon>
                                        </StyledTableButton>
                                        {!isReadOnly && (
                                            <StyledTableButton
                                                onClick={() => onDelete(gapInCare)}
                                                disabled={isBusyOrClosedOrNotUserCreated}
                                                title={isBusyOrClosedOrNotUserCreated ? '' : 'Delete'}
                                            >
                                                <CustomSvgIcon
                                                    iconSet={{
                                                        icon: isBusyOrClosedOrNotUserCreated
                                                            ? disabledDeleteIcon
                                                            : deleteIcon
                                                    }}
                                                    svg
                                                ></CustomSvgIcon>
                                            </StyledTableButton>
                                        )}
                                    </StyledActionsContainer>
                                </StyledTableBodyItem>
                            </StyledTableBodyRowOdd>
                            <StyledTableBodyRowEven>
                                <li>
                                    <div>
                                        <DocumentList
                                            documents={gapInCare.documents}
                                            isReadOnly={isReadOnly}
                                            isClosed={isClosed}
                                            isBusy={isBusy}
                                            onDownload={onDownloadDocument}
                                            onDelete={onDeleteDocument}
                                            onUpload={() => onUploadDocument(gapInCare)}
                                        />
                                    </div>
                                </li>
                            </StyledTableBodyRowEven>
                        </React.Fragment>
                    )
                })}
            </StyledTableBodyRowContainer>
        ) : (
            <StyledEmptyState>
                <CustomSvgIcon iconSet={{ icon: noData }} svg></CustomSvgIcon>
                <Typography type={TYPOGRAPHY_TYPES.h4}>Data not available at the moment</Typography>
                <Typography type={TYPOGRAPHY_TYPES.p}>Please add a record/records or check back later</Typography>
            </StyledEmptyState>
        )}
    </StyledTableContainerWrap>
)

export default GapsInCareTable
