import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import NewPCPNP from 'pages/hcp/users/create/NewPCPNP'
import {
    StyledBottomButtonsContainer,
    StyledButton,
    StyledUserTypeContainer
} from 'pages/hcp/users/details/PCPNPUser/style'

import { medicalItem, pen } from 'pages/hcp/users/details/PCPNPUser/icons'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { personalInformation } from 'messages/personalInformation'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'redux/store'
import { clearUserDetails, disableUser, enableUser, resendInvitation } from 'redux/slices/user'
import { appPath } from 'utilities/appPath'
import { IProviderUpdateFormData, IUser } from 'pages/hcp/users/create/index.models'
import {
    StyledUserDetailsControllerButton,
    StyledUserDetailsControllerContainer,
    StyledUserDetailsTitleContainer
} from 'pages/hcp/users/details/OfficeAdminUser/style'
import { checkCircleEnable, envelopeResendInvitation, redCircleDisable } from 'components/PaginationController/icons'
import { appPathForRoutes } from 'utilities/appPathForRoutes'
import { impersonationUserIdKey } from 'utilities/impersonationUtilities'
import { mirrorIcon } from 'components/UsersTable/icons'
import { hideMainWindowScrollbars } from 'utilities'
import DisableUserConfirmationBox from 'components/DisableUserConfirmationBox'
import EnableUserConfirmationBox from 'components/EnableUserConfirmationBox'
import ResendInvitationConfirmationBox from 'components/ResendInvitationConfirmationBox'
import LoginOrInvitationStatus from 'pages/hcp/users/create/LoginOrInvitationStatus'
import { StyledEditUserContainer } from '../style'

import { customEvents, pageTitle } from 'appInsights/appInsights'
import usePostCustomEvent from 'hooks/usePostCustomEvent'
import { ERoles } from 'models/enums/role'
import useCurrentUser from 'hooks/useCurrentUser'

interface IPCPNPUserProps {
    editable: boolean
}

const formInitialState: IProviderUpdateFormData = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    npi: '',
    locations: [],
    role: 3,
    createdBy: 'Test'
}

const PCPNPUser: FC<IPCPNPUserProps> = ({ editable }) => {
    const [isCancelingEdit, setIsCancelingEdit] = useState(false)
    const [formData, setFormData] = useState(formInitialState)
    const [isEditEnabled, setIsEditEnabled] = useState(editable)
    const [isUpdateDataReady, setIsUpdateDataReady] = useState(false)
    const [hasBeenSubmited, setHasBeenSubmited] = useState(false)
    const [isToggleEnabledModalShown, setIsToggleEnabledModalShown] = useState(false)
    const [isResendInvitationModalShown, setIsResendInvitationModalShown] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { details } = useSelector((state: { user: any }) => state.user)

    const generateFormData = (userDetails: IUser) => {
        if (userDetails.email === undefined || userDetails.email.length === 0) return

        const userLocations = userDetails.taxIdProviderGroupings[0].providers[0].locations
        const npi = userDetails.taxIdProviderGroupings[0].providers[0].npi

        const state: IProviderUpdateFormData = {
            npi,
            locations: [...userLocations],
            email: userDetails.email,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            phoneNumber: userDetails.phoneNumber,
            role: userDetails.role,
            createdBy: 'Test'
        }

        return state
    }

    useEffect(() => {
        if (isCancelingEdit) {
            setIsCancelingEdit(false)
            setFormData({ ...generateFormData(details) })
        }
    }, [isCancelingEdit, details])

    useEffect(() => {
        hideMainWindowScrollbars(isToggleEnabledModalShown || isResendInvitationModalShown)
    }, [isToggleEnabledModalShown, isResendInvitationModalShown])

    useEffect(() => {
        setFormData({ ...generateFormData(details) })
    }, [details])

    useEffect(() => {
        isEditEnabled && handleEditButton()
    }, [isEditEnabled])

    useEffect(() => {
        if (isUpdateDataReady === false) return
        setIsUpdateDataReady(false)
        setHasBeenSubmited(true)
    }, [isUpdateDataReady])

    const handleFormData = (data: IProviderUpdateFormData) => {
        setFormData(data)
    }

    const handleEditButton = () => {
        setIsEditEnabled(true)
    }

    const handleBackButton = () => {
        dispatch(clearUserDetails())
        navigate(appPath('/users'))
    }

    const handleCancelButton = () => {
        if (isEditEnabled) {
            handleBackButton()
        } else {
            setIsCancelingEdit(true)
            setFormData(formInitialState)
            setIsEditEnabled(false)
            setIsUpdateDataReady(false)
            setHasBeenSubmited(false)
            setIsToggleEnabledModalShown(false)
            setIsResendInvitationModalShown(false)
        }
    }

    const enableUserEvent = usePostCustomEvent(pageTitle.UsersProvider, customEvents.EnableUser)
    const disableUserEvent = usePostCustomEvent(pageTitle.UsersProvider, customEvents.DisableUser)

    const handleEnableStateForUser = (isActive: boolean, userId: string) => {
        let USER_ID = { userId: details?.id }
        if (isActive) {
            disableUserEvent()
            dispatch(disableUser(USER_ID))
        } else {
            enableUserEvent()
            dispatch(enableUser(USER_ID))
        }
    }

    const closeIsToggleEnabledModal = () => {
        setIsToggleEnabledModalShown(false)
    }

    const toggleUserEnabledState = () => {
        handleEnableStateForUser(details?.isActive, details?.id)
        closeIsToggleEnabledModal()
    }

    const handleToggleEnabled = () => {
        setIsToggleEnabledModalShown(true)
    }

    const handleResendInvitation = () => {
        setIsResendInvitationModalShown(true)
    }

    const resentInvitationEvent = usePostCustomEvent(pageTitle.UsersProvider, customEvents.ResendInvitation)
    const resendInvitationEmail = () => {
        resentInvitationEvent()
        dispatch(resendInvitation({ UserId: details?.id }))
        closeResendInvitationModal()
    }

    const closeResendInvitationModal = () => {
        setIsResendInvitationModalShown(false)
    }

    const mirrorUserEvent = usePostCustomEvent(pageTitle.UsersProvider, customEvents.MirrorUser)
    const handleMirrorOnClick = () => {
        mirrorUserEvent()
    }

    const updateInfoEvent = usePostCustomEvent(pageTitle.UsersProvider, customEvents.UpdateInfo)
    const handleSaveOnClick = () => {
        updateInfoEvent()
        setHasBeenSubmited(true)
    }
    const currentUser = useCurrentUser()
    const isReadOnly = currentUser?.primaryRole === ERoles.Quality

    return (
        <>
            {isToggleEnabledModalShown && details?.isActive && (
                <DisableUserConfirmationBox
                    userFirstName={details?.firstName}
                    userLastName={details?.lastName}
                    onConfirm={toggleUserEnabledState}
                    onCancel={closeIsToggleEnabledModal}
                />
            )}

            {isToggleEnabledModalShown && !details?.isActive && (
                <EnableUserConfirmationBox
                    userFirstName={details?.firstName}
                    userLastName={details?.lastName}
                    onConfirm={toggleUserEnabledState}
                    onCancel={closeIsToggleEnabledModal}
                />
            )}

            {isResendInvitationModalShown && (
                <ResendInvitationConfirmationBox
                    userFirstName={details?.firstName}
                    userLastName={details?.lastName}
                    onConfirm={resendInvitationEmail}
                    onCancel={closeResendInvitationModal}
                />
            )}

            {!isCancelingEdit && (
                <StyledEditUserContainer>
                    <Typography type={TYPOGRAPHY_TYPES.h3}>User Information</Typography>
                    <StyledUserDetailsTitleContainer>
                        <StyledUserTypeContainer>
                            <CustomSvgIcon iconSet={{ icon: medicalItem }} svg></CustomSvgIcon>
                            <Typography style={{ paddingLeft: 15 }} type={TYPOGRAPHY_TYPES.p}>
                                {personalInformation.PCP_NP}
                            </Typography>
                        </StyledUserTypeContainer>
                        <LoginOrInvitationStatus
                            userState={details.userState}
                            invitationSent={details.invitationSent}
                            invitationExpired={details.invitationExpired}
                            daysToInvitationExpiry={details.daysToInvitationExpiry}
                            lastLogin={details.lastLogin}
                        />
                    </StyledUserDetailsTitleContainer>
                    <StyledUserDetailsControllerContainer>
                        {details?.isActive && !isReadOnly && (
                            <StyledUserDetailsControllerButton onClick={handleEditButton}>
                                <CustomSvgIcon iconSet={{ icon: pen }} svg></CustomSvgIcon>Edit
                            </StyledUserDetailsControllerButton>
                        )}
                        {details?.role &&
                            !isReadOnly &&
                            (details?.sid ? (
                                <StyledUserDetailsControllerButton onClick={handleToggleEnabled}>
                                    {details?.isActive ? (
                                        <CustomSvgIcon iconSet={{ icon: redCircleDisable }} svg />
                                    ) : (
                                        <CustomSvgIcon iconSet={{ icon: checkCircleEnable }} svg />
                                    )}
                                    {details?.isActive ? 'Disable' : 'Enable'}
                                </StyledUserDetailsControllerButton>
                            ) : (
                                <StyledUserDetailsControllerButton onClick={handleResendInvitation}>
                                    <CustomSvgIcon iconSet={{ icon: envelopeResendInvitation }} svg />
                                    Resend Invitation
                                </StyledUserDetailsControllerButton>
                            ))}
                        {details?.sid && (
                            <Link
                                to={appPathForRoutes(`/dashboard?${impersonationUserIdKey}=${details?.sid}`)}
                                target="_blank"
                                style={{ textDecoration: 'none' }}
                                onClick={handleMirrorOnClick}
                            >
                                <StyledUserDetailsControllerButton>
                                    <CustomSvgIcon iconSet={{ icon: mirrorIcon }} svg></CustomSvgIcon>Mirror
                                </StyledUserDetailsControllerButton>
                            </Link>
                        )}
                    </StyledUserDetailsControllerContainer>
                    <NewPCPNP
                        creatingANewUser={false}
                        userDetails={details}
                        resetHasBeenSubmitted={() => setHasBeenSubmited(false)}
                        formSubmitted={hasBeenSubmited}
                        readonly={!isEditEnabled}
                    />
                    {isEditEnabled && (
                        <StyledBottomButtonsContainer>
                            <StyledButton secondary onClick={handleCancelButton}>
                                Cancel
                            </StyledButton>
                            <StyledButton primary onClick={handleSaveOnClick}>
                                Save
                            </StyledButton>
                        </StyledBottomButtonsContainer>
                    )}
                </StyledEditUserContainer>
            )}
        </>
    )
}

export default PCPNPUser
