import { FC } from 'react'
import {
    StyledRadioInput,
    StyledRadioInputContainer,
    StyledRadioLabel,
    StyledRadioLabelImageGroup,
    StyledRadioWrapSingle
} from './index.style'
import { personalInformation } from 'messages'
import { ERoleString } from 'models/enums/role'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { medicalFolder, medicalItem } from './icons'

interface ICreateNewUserTypeRadiosProps {
    selectedValue: ERoleString
    onSelectedUserType: (selectedValue: ERoleString) => void
}

const CreateNewUserTypeRadios: FC<ICreateNewUserTypeRadiosProps> = ({ onSelectedUserType, selectedValue }) => {
    return (
        <StyledRadioInputContainer>
            <StyledRadioWrapSingle onClick={() => onSelectedUserType(ERoleString.OFFICE_ADMIN)}>
                <StyledRadioInput
                    type="radio"
                    name={'radio'}
                    value={ERoleString.OFFICE_ADMIN}
                    checked={selectedValue === ERoleString.OFFICE_ADMIN}
                    onChange={() => onSelectedUserType(ERoleString.OFFICE_ADMIN)}
                />
                <StyledRadioLabelImageGroup>
                    <CustomSvgIcon iconSet={{ icon: medicalFolder }} svg></CustomSvgIcon>
                    <StyledRadioLabel>{personalInformation.office_admin}</StyledRadioLabel>
                </StyledRadioLabelImageGroup>
            </StyledRadioWrapSingle>

            <StyledRadioWrapSingle onClick={() => onSelectedUserType(ERoleString.PCP_NP)}>
                <StyledRadioInput
                    type="radio"
                    name={'radio'}
                    value={ERoleString.PCP_NP}
                    checked={selectedValue === ERoleString.PCP_NP}
                    onChange={() => onSelectedUserType(ERoleString.PCP_NP)}
                />
                <StyledRadioLabelImageGroup>
                    <CustomSvgIcon iconSet={{ icon: medicalItem }} svg></CustomSvgIcon>
                    <StyledRadioLabel>{personalInformation.PCP_NP}</StyledRadioLabel>
                </StyledRadioLabelImageGroup>
            </StyledRadioWrapSingle>
        </StyledRadioInputContainer>
    )
}

export default CreateNewUserTypeRadios
