import { StyledButtonContainer, StyledCancelButton } from 'components/ConfirmationBox/index.style'
import Modal from 'components/Modal'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC, useRef, useState } from 'react'
import { UI_COLORS } from 'theme/constants'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { StyleHeaderContainer, StyledConfirmAndSubmitButton } from './index.style'
import { warningIcon } from 'components/TextField/icons'
import { StyledSpinnerWrap } from 'components/UploadFileModal/index.style'
import Spinner from 'components/Spinner'

interface IProps {
    message?: string
    iniDate?: Date | string
    setNewDate: (d: Date) => void
    onConfirm: () => void
    onCancel: () => void
}

const AwvAttestationModal: FC<IProps> = ({ message, iniDate, setNewDate, onConfirm, onCancel }) => {
    const myRef = useRef<{
        dateOfService: HTMLInputElement | null
        isConfirmed: HTMLInputElement | null
    }>({ dateOfService: null, isConfirmed: null })

    const isCheckerNeeded = (message ?? '').length > 0
    const isDateValid = (v: Date | string) => dayjs(v).isValid() && !dayjs(v).isAfter(dayjs())

    const [date, setDate] = useState<Dayjs | null>(dayjs(iniDate))
    const [isCheckerInError, setIsCheckerInError] = useState(true)
    const [isDateInError, setIsDateInError] = useState(!isDateValid(iniDate))
    const [isConfirmed, setIsConfirmed] = useState(false)

    const handleConfirm = () => {
        let isOK = true
        if (isCheckerNeeded && !myRef.current.isConfirmed.checked) {
            setIsCheckerInError(true)
            isOK = false
        }
        if (!isDateValid(myRef.current.dateOfService.value)) {
            setIsDateInError(true)
            isOK = false
        }
        if (isOK) {
            onConfirm()
            setIsConfirmed(true)
        }
    }
    const handleCancle = () => {
        if (!isConfirmed) onCancel()
    }

    return (
        <Modal onClose={handleCancle} minWidth={600} minHeight={400}>
            <Typography type={TYPOGRAPHY_TYPES.h2}>Attestation</Typography>
            <Typography type={TYPOGRAPHY_TYPES.p}>{message}</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Date Of Service"
                    value={date}
                    disableFuture
                    onChange={(newDate) => {
                        setDate(newDate)
                        setNewDate(dayjs(newDate).toDate())
                        setIsDateInError(false)
                    }}
                    slotProps={{
                        field: { clearable: true }
                    }}
                    inputRef={(ref) => {
                        myRef.current.dateOfService = ref
                    }}
                    disabled={isConfirmed}
                />
                {isDateInError && <CustomSvgIcon iconSet={{ icon: warningIcon }} svg></CustomSvgIcon>}
            </LocalizationProvider>
            {isCheckerNeeded && (
                <StyleHeaderContainer>
                    <input
                        ref={(ref) => {
                            myRef.current.isConfirmed = ref
                        }}
                        type="checkbox"
                        onClick={() => setIsCheckerInError(false)}
                        disabled={isConfirmed}
                    />
                    <Typography type={TYPOGRAPHY_TYPES.p}>{'  '}I attest</Typography>
                    {isCheckerInError && <CustomSvgIcon iconSet={{ icon: warningIcon }} svg></CustomSvgIcon>}
                </StyleHeaderContainer>
            )}
            <StyledButtonContainer>
                <StyledCancelButton onClick={onCancel} disabled={isConfirmed}>
                    Cancel
                </StyledCancelButton>
                {isConfirmed ? (
                    <StyledSpinnerWrap>
                        <Spinner />
                    </StyledSpinnerWrap>
                ) : (
                    <StyledConfirmAndSubmitButton
                        disabled={(isCheckerNeeded && isCheckerInError) || isDateInError}
                        onClick={handleConfirm}
                        color={UI_COLORS.light_blue2}
                    >
                        Confirm & Submit
                    </StyledConfirmAndSubmitButton>
                )}
            </StyledButtonContainer>
        </Modal>
    )
}

export default AwvAttestationModal
