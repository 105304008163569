import styled, { css } from 'styled-components'
import { DEVICE, pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const {light_gray4, light_gray2 } = UI_COLORS

export const StyledAWVHistoryModalContainer = styled.article`
    max-height: 690px;
    h2 {
        margin: 0;
        padding: 0;
        color: #293854;
        font-family: 'Work Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.22px;
        margin-bottom: 32px;
    }
    
    article {
        max-height: 600px;
    }

`

export const StyledAWVFileTableContainer = styled.article`
    width: 100%;
    max-height: 600px;
    overflow: auto;
`

export const StyledAWVFileTableHeader = styled.ul`
    height: 40px;
    background-color: #ebeff7;

    padding: 0;
    list-style: none;

    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
`

export const StyledAWVFileTableHeaderItem = styled.li`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding: 3px;

    color: #73839c;

    :nth-child(1) {
        width: 300px;
        margin-left: 26px;
    }

    :nth-child(2) {
        width: 100px;
    }

    :nth-child(3) {
        width: 200px;
    }

    :nth-child(4) {
        width: 125px;
    }
`
export const StyledAWVFileTableBodyItem = styled.li`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    padding: 3px;
    
    :nth-child(1) {
        width: 300px;
        margin-left: 26px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    :nth-child(2) {
        width: 100px;
    }

    :nth-child(3) {
        width: 200px;
    }

    :nth-child(4) {
        width: 125px;
    }
`

export const StyledAWVFileTableBody = styled.ul`
    height: 84px;
    background-color: #ffffff;

    padding: 0;
    list-style: none;

    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    border-bottom: 1px solid ${light_gray4};

        :hover {
        background-color: ${light_gray2};
    }
`
export const StyledAWVTableBodyRowWrap = styled.article`
    display: flex;
    // border: 1px solid ${light_gray4};
    border-top: 0;
    border-left: 0;
    border-right: 0;

    article {
        &:nth-child(2) {
            display: flex;
            align-items: center;
        }
    }
`

