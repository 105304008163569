export const logOut = `<svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.1669 20.4205C14.1669 19.96 14.5402 19.5867 15.0007 19.5867C15.4613 19.5867 15.8346 19.96 15.8346 20.4205V22.9221C15.8346 23.8431 15.0879 24.5898 14.1669 24.5898H5.41142C4.49037 24.5898 3.74371 23.8431 3.74371 22.9221V7.07884C3.74371 6.15779 4.49037 5.41113 5.41142 5.41113H14.1669C15.0879 5.41113 15.8346 6.15779 15.8346 7.07884V9.5804C15.8346 10.0409 15.4613 10.4143 15.0007 10.4143C14.5402 10.4143 14.1669 10.0409 14.1669 9.5804V7.49577C14.1669 7.26551 13.9802 7.07884 13.75 7.07884H5.82835C5.59809 7.07884 5.41142 7.26551 5.41142 7.49577V22.5051C5.41142 22.7354 5.59809 22.9221 5.82835 22.9221H13.75C13.9802 22.9221 14.1669 22.7354 14.1669 22.5051V20.4205ZM18.382 11.389L23.3851 14.7244V14.7236C23.6171 14.8783 23.7564 15.1386 23.7564 15.4174C23.7564 15.6962 23.6171 15.9565 23.3851 16.1111L18.382 19.4466C18.245 19.5379 18.0839 19.5867 17.9192 19.5867C17.4613 19.5804 17.0917 19.2107 17.0854 18.7528V16.6682H9.58069C9.13383 16.6682 8.72091 16.4298 8.49748 16.0428C8.27405 15.6558 8.27405 15.179 8.49748 14.792C8.72091 14.405 9.13383 14.1666 9.58069 14.1666H17.0854V12.082C17.0856 11.7745 17.2551 11.4922 17.5262 11.3472C17.7974 11.2023 18.1263 11.2184 18.382 11.389Z" fill="#293854"/>
<mask id="mask0_9980_2579" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="3" y="5" width="21" height="20">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.1669 20.4205C14.1669 19.96 14.5402 19.5867 15.0007 19.5867C15.4613 19.5867 15.8346 19.96 15.8346 20.4205V22.9221C15.8346 23.8431 15.0879 24.5898 14.1669 24.5898H5.41142C4.49037 24.5898 3.74371 23.8431 3.74371 22.9221V7.07884C3.74371 6.15779 4.49037 5.41113 5.41142 5.41113H14.1669C15.0879 5.41113 15.8346 6.15779 15.8346 7.07884V9.5804C15.8346 10.0409 15.4613 10.4143 15.0007 10.4143C14.5402 10.4143 14.1669 10.0409 14.1669 9.5804V7.49577C14.1669 7.26551 13.9802 7.07884 13.75 7.07884H5.82835C5.59809 7.07884 5.41142 7.26551 5.41142 7.49577V22.5051C5.41142 22.7354 5.59809 22.9221 5.82835 22.9221H13.75C13.9802 22.9221 14.1669 22.7354 14.1669 22.5051V20.4205ZM18.382 11.389L23.3851 14.7244V14.7236C23.6171 14.8783 23.7564 15.1386 23.7564 15.4174C23.7564 15.6962 23.6171 15.9565 23.3851 16.1111L18.382 19.4466C18.245 19.5379 18.0839 19.5867 17.9192 19.5867C17.4613 19.5804 17.0917 19.2107 17.0854 18.7528V16.6682H9.58069C9.13383 16.6682 8.72091 16.4298 8.49748 16.0428C8.27405 15.6558 8.27405 15.179 8.49748 14.792C8.72091 14.405 9.13383 14.1666 9.58069 14.1666H17.0854V12.082C17.0856 11.7745 17.2551 11.4922 17.5262 11.3472C17.7974 11.2023 18.1263 11.2184 18.382 11.389Z" fill="white"/>
</mask>
<g mask="url(#mask0_9980_2579)">
</g>
</svg>
`

export const changePasswordIcon = `<svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.8428 13.553L17.059 20.3395L13.7236 21.0066L14.3907 17.6712L21.1781 10.8847C21.5306 10.5315 22.0092 10.333 22.5082 10.333C23.0073 10.333 23.4858 10.5315 23.8384 10.8847L23.8464 10.8918C24.5801 11.6277 24.5785 12.8191 23.8428 13.553Z" stroke="#293854" stroke-width="1.5"/>
<path d="M11.0517 18.3383H5.71506C4.97822 18.3383 4.38089 17.741 4.38089 17.0041V2.32831C4.38089 1.59147 4.97822 0.994141 5.71506 0.994141H19.0567C19.7936 0.994141 20.3909 1.59147 20.3909 2.32831V8.33206" stroke="#293854" stroke-width="2"/>
<path d="M11.0517 4.99707H16.3884" stroke="#293854" stroke-width="1.5"/>
<path d="M8.38336 8.99902H16.3884" stroke="#293854" stroke-width="1.5"/>
<path d="M8.38336 13.002H15.0542" stroke="#293854" stroke-width="1.5"/>
</svg>
`
