import { StyledButtonContainer, StyledCancelButton, StyledConfirmButton, StyledFormTable } from './index.style'
import Modal from 'components/Modal'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { IReviewableAwvForm } from 'pages/hcp/members/information/PointsInCare/PointsInCare.models'
import { FC } from 'react'
import { UI_COLORS } from 'theme/constants'

export enum ReviewableAwvFormAction {
    openOrDownload,
    review,
    approve,
    reject,
    deny
}

interface IReviewableAwvFormActionConfirmationBoxProps {
    form: IReviewableAwvForm
    action: ReviewableAwvFormAction
    onConfirm: () => void
    onCancel: () => void
    onCancelAll?: () => void
}

const ReviewableAwvFormActionConfirmationBox: FC<IReviewableAwvFormActionConfirmationBoxProps> = ({
    form,
    action,
    onConfirm,
    onCancel,
    onCancelAll
}) => {
    const formType = form.formType?.length > 0 ? form.formType : 'Annual Wellness Visit'
    const getTitle = () => {
        switch (action) {
            case ReviewableAwvFormAction.openOrDownload:
                return form.type === 'PDF' ? `Download ${formType} Form` : `Open ${formType} Form`
            case ReviewableAwvFormAction.review:
                return `Review ${formType} Form`
            case ReviewableAwvFormAction.approve:
                return `Approve ${formType} Form`
            case ReviewableAwvFormAction.reject:
                return `Reject ${formType} Form`
            case ReviewableAwvFormAction.deny:
                return `Deny ${formType} Form`
            default:
                return ''
        }
    }

    const getPrompt = () => {
        switch (action) {
            case ReviewableAwvFormAction.openOrDownload:
                return `Would you like to change the status of the ${formType} form to 'In Review' before ${
                    form.type === 'PDF' ? 'downloading' : 'opening'
                } it?`
            case ReviewableAwvFormAction.review:
                return `Would you like to review the ${formType} form?`
            case ReviewableAwvFormAction.approve:
                return `Would you like to approve the ${formType} form?`
            case ReviewableAwvFormAction.reject:
                return `Would you like to reject the ${formType} form?`
            case ReviewableAwvFormAction.deny:
                return `Would you like to deny the ${formType} form?`
            default:
                return ''
        }
    }

    const getCurrentStatus = () => {
        switch (form.status) {
            case 'Submitted':
                return 'Submitted'
            case 'Rejected':
                return 'Rejected'
            default:
                return form.status
        }
    }

    const getBottomNote = () => {
        switch (action) {
            case ReviewableAwvFormAction.openOrDownload:
            case ReviewableAwvFormAction.review:
                return 'This action will lock the form, prevent it from being edited and/or resubmitted.'
            case ReviewableAwvFormAction.approve:
            case ReviewableAwvFormAction.deny:
                return 'This action cannot be undone!'
            case ReviewableAwvFormAction.reject:
                return 'This action will unlock the form, allow it to be edited and/or resubmitted.'
            default:
                return ''
        }
    }

    const getBottomNoteColor = () =>
        action === ReviewableAwvFormAction.approve || action === ReviewableAwvFormAction.deny
            ? UI_COLORS.red
            : UI_COLORS.black

    const getConfirmButtonLabel = () => {
        switch (action) {
            case ReviewableAwvFormAction.review:
                return 'Review'
            case ReviewableAwvFormAction.approve:
                return 'Approve'
            case ReviewableAwvFormAction.reject:
                return 'Reject'
            case ReviewableAwvFormAction.deny:
                return 'Deny'
            default:
                return ''
        }
    }

    const getConfirmButtonColor = () => {
        switch (action) {
            case ReviewableAwvFormAction.openOrDownload:
            case ReviewableAwvFormAction.review:
                return UI_COLORS.light_blue2
            case ReviewableAwvFormAction.approve:
                return UI_COLORS.light_green
            case ReviewableAwvFormAction.reject:
                return UI_COLORS.red
            case ReviewableAwvFormAction.deny:
                return UI_COLORS.red
            default:
                return ''
        }
    }

    const nonEdgeButtonStyle = { marginRight: '16px' }

    return (
        <Modal
            onClose={onCancelAll ?? onCancel}
            minWidth={785}
            minHeight={
                action === ReviewableAwvFormAction.openOrDownload || action === ReviewableAwvFormAction.review
                    ? 485
                    : 455
            }
        >
            <Typography type={TYPOGRAPHY_TYPES.h2}>{getTitle()}</Typography>
            <Typography type={TYPOGRAPHY_TYPES.p}>{getPrompt()}</Typography>
            <Typography type={TYPOGRAPHY_TYPES.p}>
                <StyledFormTable>
                    <tbody>
                        <tr>
                            <td>Patient:</td>
                            <td>{form.patient}</td>
                        </tr>
                        <tr>
                            <td>Year:</td>
                            <td>{form.year}</td>
                        </tr>
                        <tr>
                            <td>Provider:</td>
                            <td>{form.provider}</td>
                        </tr>
                        <tr>
                            <td>Provider NPI:</td>
                            <td>{form.providerNpi}</td>
                        </tr>
                        {(action === ReviewableAwvFormAction.openOrDownload ||
                            action === ReviewableAwvFormAction.review) && (
                            <tr>
                                <td>Current Status:</td>
                                <td>{getCurrentStatus()}</td>
                            </tr>
                        )}
                    </tbody>
                </StyledFormTable>
            </Typography>
            <Typography type={TYPOGRAPHY_TYPES.p} style={{ color: getBottomNoteColor() }}>
                {getBottomNote()}
            </Typography>
            <StyledButtonContainer>
                {action === ReviewableAwvFormAction.openOrDownload ? (
                    <>
                        <StyledConfirmButton
                            onClick={onConfirm}
                            color={getConfirmButtonColor()}
                            style={nonEdgeButtonStyle}
                        >
                            Yes
                        </StyledConfirmButton>
                        <StyledCancelButton onClick={onCancel} style={nonEdgeButtonStyle}>
                            No
                        </StyledCancelButton>
                        <StyledCancelButton onClick={onCancelAll}>Cancel</StyledCancelButton>
                    </>
                ) : (
                    <>
                        <StyledCancelButton onClick={onCancel} style={nonEdgeButtonStyle}>
                            Cancel
                        </StyledCancelButton>
                        <StyledConfirmButton onClick={onConfirm} color={getConfirmButtonColor()}>
                            {getConfirmButtonLabel()}
                        </StyledConfirmButton>
                    </>
                )}
            </StyledButtonContainer>
        </Modal>
    )
}

export default ReviewableAwvFormActionConfirmationBox
