import { FC } from 'react'
import { useParams } from 'react-router-dom'
import UserMain from './UserMain'
import { ENavTab } from './navbar/config'

interface IProps {}

const UserEditPage: FC<IProps> = () => {
    const { userId } = useParams()
    return <UserMain userId={userId} tab={ENavTab.GENERAL} editable={true}  />
}
export default UserEditPage
