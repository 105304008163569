export const editPen = `<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 15.2501V19.0001H4.25L15.31 7.94006L11.56 4.19006L0.5 15.2501ZM18.21 5.04006C18.6 4.65006 18.6 4.02006 18.21 3.63006L15.87 1.29006C15.48 0.900059 14.85 0.900059 14.46 1.29006L12.63 3.12006L16.38 6.87006L18.21 5.04006Z" fill="#2281C4"/>
</svg>
`

export const isElegibleBadge = `<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM4 7.5L1.5 5L2.205 4.295L4 6.085L7.795 2.29L8.5 3L4 7.5Z" fill="#089171"/>
</svg>
`

export const notElegibleBadge = `<svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.5 0C2.74 0 0.5 2.24 0.5 5C0.5 7.76 2.74 10 5.5 10C8.26 10 10.5 7.76 10.5 5C10.5 2.24 8.26 0 5.5 0ZM6 7.5H5V6.5H6V7.5ZM6 5.5H5V2.5H6V5.5Z" fill="#FF7A00"/>
</svg>
`
