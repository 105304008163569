import CustomSvgIcon from 'components/CustomSvgIcon'
import { FC, useEffect, useState } from 'react'
import { lastPageArrow, nextArrow } from './icons'
import { paginate } from './index.logic'
import {
    StyledInformationContainer,
    StyledPaginationControl,
    StyledPaginationContainerWrap,
    StyledPaginationControllers,
    StyledPaginationContainer,
    StyledInformation
} from './index.style'

const defaultState = {
    currentPage: 0,
    endIndex: 0,
    endPage: 0,
    pageSize: 0,
    pages: [1],
    startIndex: 0,
    startPage: 0,
    totalItems: 0,
    totalPages: 0
}

interface IPaginationControllerProps {
    singularRecordName: string
    pluralRecordName: string
    totalItems: number
    selectedPage: number
    selectedPageChanged: (value: number) => void
}

const PaginationController: FC<IPaginationControllerProps> = ({
    totalItems,
    selectedPage,
    selectedPageChanged,
    singularRecordName,
    pluralRecordName
}) => {
    const [currentPager, setCurrentPager] = useState(defaultState)

    useEffect(() => {
        const pager = paginate(totalItems, selectedPage, 10, 5)
        setCurrentPager(pager)
    }, [selectedPage, totalItems])

    useEffect(() => {
        if ((selectedPage - 1) * 10 > totalItems && selectedPage > 1) {
            selectedPageChanged(1)
        }
    }, [totalItems, selectedPage, selectedPageChanged])

    const handleSelectedPage = (item: number) => {
        selectedPageChanged(item)
    }

    const handleNextPage = () => {
        if (selectedPage < currentPager.endPage) {
            selectedPageChanged(selectedPage + 1)
        }
        return
    }

    const handlePreviousPage = () => {
        if (selectedPage !== 1) {
            selectedPageChanged(selectedPage - 1)
        }
        return
    }

    const handleFirstPage = () => {
        selectedPageChanged(1)
    }

    const handleLastPage = () => {
        selectedPageChanged(currentPager?.totalPages)
    }

    const getRecordName = () => (currentPager.totalItems > 1 ? pluralRecordName : singularRecordName)

    return (
        <StyledPaginationContainerWrap>
            <StyledPaginationContainer>
                {currentPager.totalPages > 1 && (
                    <StyledPaginationControl>
                        <StyledPaginationControllers onClick={handleFirstPage}>
                            <CustomSvgIcon iconSet={{ icon: lastPageArrow }} svg></CustomSvgIcon>
                        </StyledPaginationControllers>
                        <StyledPaginationControllers onClick={handlePreviousPage}>
                            <CustomSvgIcon iconSet={{ icon: nextArrow }} svg></CustomSvgIcon>
                        </StyledPaginationControllers>
                        {currentPager?.pages?.map((item: any, idx: number) => {
                            return (
                                <StyledPaginationControllers
                                    key={`button-${idx}`}
                                    onClick={() => handleSelectedPage(item)}
                                    isActive={item === selectedPage}
                                >
                                    {item}
                                </StyledPaginationControllers>
                            )
                        })}
                        <StyledPaginationControllers onClick={handleNextPage}>
                            <CustomSvgIcon iconSet={{ icon: nextArrow }} svg></CustomSvgIcon>
                        </StyledPaginationControllers>
                        <StyledPaginationControllers onClick={handleLastPage}>
                            <CustomSvgIcon iconSet={{ icon: lastPageArrow }} svg></CustomSvgIcon>
                        </StyledPaginationControllers>
                    </StyledPaginationControl>
                )}
            </StyledPaginationContainer>
            <StyledInformationContainer>
                <StyledInformation>
                    {currentPager.totalPages > 1
                        ? `Showing ${(currentPager.startIndex + 1).toLocaleString()}-${Math.min(
                              currentPager.startIndex + currentPager.pageSize,
                              currentPager.totalItems
                          ).toLocaleString()} of ${currentPager.totalItems.toLocaleString()} ${getRecordName()}`
                        : `Showing ${currentPager.totalItems.toLocaleString()} ${getRecordName()}`}
                </StyledInformation>
            </StyledInformationContainer>
        </StyledPaginationContainerWrap>
    )
}

export default PaginationController
