import useCurrentUser from 'hooks/useCurrentUser'
import { useUsersListConfig } from 'hooks/useListConfig'
import { Fragment } from 'react'
import { dispatch, useAppSelector } from 'redux/store'
import { PATH_PAGE } from 'routes/paths'
import { sidebarReportsIcon, sidebarReportsWithNewIcon } from './icons'
import { StyledAsideContainer } from './index.style'
import { ESidebarTitle, ISidebarItem, SidebarItem } from './SidebarItem'
import { DocumentsState, getNewReportCounts } from 'redux/slices/documents'

const Sidebar = ({ sidebarConfig }: any) => {
    const currentUser = useCurrentUser()
    const usersListConfig = useUsersListConfig()

    const handleNavigating = (path: string) => {
        if (path === PATH_PAGE.user.list) {
            usersListConfig.clearFilter()
        } else if (path === PATH_PAGE.providerReports) {
            dispatch(getNewReportCounts())
        }
    }

    const countOnReport: number = useAppSelector((state: { documents: DocumentsState }) => {
        return (state.documents.newReportCounts ?? []).reduce((n, { count }) => n + count, 0)
    })

    const mySidebarConfig = (() => {
        let navItems: ISidebarItem[] = [...sidebarConfig].filter((x) => x.roles.includes(currentUser.primaryRole))
        let reportItem = navItems.findIndex((x) => x.title === ESidebarTitle.REPORTS)
        if (reportItem > 0) {
            countOnReport > 0
                ? (navItems[reportItem].icon = sidebarReportsWithNewIcon)
                : (navItems[reportItem].icon = sidebarReportsIcon)
        }
        return navItems
    })()

    return (
        <StyledAsideContainer>
            {mySidebarConfig.map((item: any, index: number) => {
                return (
                    <Fragment key={index}>
                        <SidebarItem
                            path={item.path}
                            alternatePath={item.alternatePath}
                            title={item.title}
                            icon={item.icon}
                            onNavigating={handleNavigating}
                        />
                    </Fragment>
                )
            })}
        </StyledAsideContainer>
    )
}

export default Sidebar
