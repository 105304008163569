import TaxProviderInfo from 'components/TaxIdProviderInfo'
import { StyledHeadersRowContainer } from 'components/TaxIdProviderInfo/index.style'
import TextField from 'components/TextField'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { inputLabels } from 'messages/inputLabels'
import { FC, useEffect, useState } from 'react'
import { doesEmailAlreadyExist, getValidAreaCodes } from 'redux/slices/user'
import {
    ILocationData,
    INPILocationsData,
    IOfficeAdminClientUpdate,
    IOfficeAdminProviderInfo,
    ITaxIdNpiData,
    IUser
} from '../index.models'
import { StyledInputContainerWrap, StyledInputContainer, StyledUserDetailsFieldSet } from '../index.style'
import MaskedTextField from 'components/MaskedTextField'
import { dispatch, useSelector } from 'redux/store'
import { isValidAreaCode, removePhoneNumberPunctuation } from 'utilities/stringUtilities'
import { IExternalState } from 'hooks/useExternalState'
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js'
import { validateEmail, validatePhone } from 'utilities/validationUtilities'
import { ERoles } from 'models/enums/role'
import useCurrentUser from 'hooks/useCurrentUser'

interface INewOfficeAdminProps {
    onFormDataOutput: (values: any) => void
    formSubmitted?: boolean
    onSetValidForm?: (isValid: boolean) => void
    onValidationComplete: () => void
    userDetails?: IUser | null
    hasNewUserDetail: boolean
    isEdit?: boolean
    isUserDetails?: boolean
    commonState?: IExternalState
}

const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
const phoneNumberRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i

const NewOfficeAdmin: FC<INewOfficeAdminProps> = ({
    onFormDataOutput,
    formSubmitted,
    onSetValidForm,
    onValidationComplete,
    userDetails,
    hasNewUserDetail,
    isEdit,
    commonState
}) => {
    const appInsights = useAppInsightsContext()

    const formInitialState: IOfficeAdminClientUpdate = {
        userId: userDetails?.id ?? '',
        sid: '',
        firstName: userDetails?.firstName ?? '',
        lastName: userDetails?.lastName ?? '',
        phoneNumber: userDetails?.phoneNumber ?? '',
        email: userDetails?.email ?? '',
        role: userDetails?.role ?? 3,
        taxIdProviderGroupings: [] as ITaxIdNpiData[],
        createdBy: 'test'
    }

    const validFormCallback = onSetValidForm || (() => {})

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        taxId: '',
        npi: '',
        location: null
    })

    const emailInfo = useSelector((state: { user: any }) => state.user.emailInfo)

    const validAreaCodes: number[] = useSelector((state: { user: any }) => state.user.validAreaCodes.areaCodes)

    const setProviderInfo = (providerInfo: IOfficeAdminProviderInfo) => {
        setCurrentProviderInfo(providerInfo)
        setFormData({ ...formData, taxIdProviderGroupings: providerInfo.taxIdProviderGroupings })
    }

    const [formData, setFormData] = useState(formInitialState)

    const currentUser = useCurrentUser()

    useEffect(() => {
        // only get valid area codes if the user is a call center admin
        if (currentUser?.primaryRole === ERoles.CallCenterAdmin || currentUser?.primaryRole === ERoles.ProviderRep) {
            if (!validAreaCodes || validAreaCodes.length === 0) {
                dispatch(getValidAreaCodes())
            }
        }
    }, [currentUser])

    useEffect(() => {
        if (hasNewUserDetail === false) return
        setFormData({ ...formInitialState, taxIdProviderGroupings: currentProviderInfo.taxIdProviderGroupings })
    }, [userDetails?.id, hasNewUserDetail])

    const validateTaxProviderInfo = (taxIdProviderGroupings: ITaxIdNpiData[]) => {
        let isInvalid = false

        if (taxIdProviderGroupings.length === 0) return true

        for (let taxIdIndex: number = 0; taxIdIndex < taxIdProviderGroupings.length; taxIdIndex++) {
            isInvalid = isInvalid || taxIdProviderGroupings[taxIdIndex].taxId.length > 0

            for (let npiIndex: number = 0; npiIndex < taxIdProviderGroupings[taxIdIndex].providers.length; npiIndex++) {
                isInvalid = isInvalid || taxIdProviderGroupings[taxIdIndex].providers[npiIndex].npi.length === 0
                for (
                    let locationIndex: number = 0;
                    locationIndex < taxIdProviderGroupings[taxIdIndex].providers[npiIndex].locations.length;
                    locationIndex++
                ) {
                    isInvalid =
                        isInvalid ||
                        taxIdProviderGroupings[taxIdIndex].providers[npiIndex].locations[locationIndex].address
                            .length === 0
                }
            }
        }

        return isInvalid
    }

    useEffect(() => {
        const { firstName, lastName, phoneNumber, email, taxIdProviderGroupings } = formData
        if (
            !firstName ||
            !lastName ||
            !phoneNumber ||
            !phoneNumberRegex.test(phoneNumber) ||
            !isValidAreaCode(phoneNumber, validAreaCodes) ||
            !email ||
            !emailRegex.test(email) ||
            (emailInfo.emailExists && isANewEmail(email)) ||
            validateTaxProviderInfo(taxIdProviderGroupings)
        ) {
            validFormCallback(false)
        }

        if (formData && formInitialState !== formData) {
            onFormDataOutput(formData)
        }
    }, [formData])

    useEffect(() => {
        if (formSubmitted) {
            validateOfficeAdminForm()
        }
    }, [formSubmitted])

    const initialProviderInfo: IOfficeAdminProviderInfo = { taxIdProviderGroupings: [] }

    const [currentUserId, setCurrentUserId] = useState<string | undefined | null>('')

    const [currentProviderInfo, setCurrentProviderInfo] = useState<IOfficeAdminProviderInfo>(initialProviderInfo)

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
        validFormCallback(true)
        if (name === 'firstName') {
            setErrors({ ...errors, firstName: '' })
        }

        if (name === 'lastName') {
            setErrors({ ...errors, lastName: '' })
        }

        if (name === 'phoneNumber' && removePhoneNumberPunctuation(value)?.length > 0) {
            setErrors({ ...errors, phoneNumber: '' })
        }

        if (name === 'email') {
            setErrors({ ...errors, email: '' })
        }

        if (name === 'email') {
            setErrors({ ...errors, email: '' })
        }

        if (name === 'taxProviderInfo') {
            setErrors({ ...errors, taxId: '' })
        }
    }

    const validateTaxId = (taxId: string) => {
        const regexp = new RegExp('^\\d{2}\\-?\\d{7}$')

        return taxId != null && taxId.trim().length > 0 && regexp.test(taxId)
    }

    const validateNpi = (npidata: INPILocationsData) => {
        return npidata?.npi?.trim()?.length > 0
    }

    const validateLocation = (location: ILocationData) => {
        return location?.address?.trim()?.length > 0
    }

    const isANewEmail = (email: string) => {
        return email?.trim()?.length > 0 && email !== userDetails?.email
    }

    const trackFormEntryEvent = useTrackEvent(appInsights, 'OfficeAdminFormSubmissionInvalid', formData)

    const handleValidationCallback = (isValid: boolean) => {
        validFormCallback(isValid)
        if (isValid) {
            onValidationComplete()
        } else {
            trackFormEntryEvent(formData)
        }
    }

    const stripTaxId = (taxId: string): string => {
        if (!taxId) return taxId

        return taxId.trim().replace('_', '').replace('-', '')
    }

    const validateAllTaxProviderInfo = (isValid: boolean, newErrors: any) => {
        const newProviderData = [...currentProviderInfo.taxIdProviderGroupings]

        for (let taxIndex = 0; taxIndex < newProviderData.length; taxIndex++) {
            const taxIdData = newProviderData[taxIndex]
            const currentTaxId = stripTaxId(taxIdData?.taxId)
            if (currentTaxId?.length === 0) {
                isValid = false
                newProviderData[taxIndex].error = true
                newProviderData[taxIndex].errorMessage = 'Tax ID Required'
                newErrors = { ...newErrors, taxId: 'Tax Id Required' }
            } else if (newProviderData[taxIndex].error) {
                isValid = false
                newErrors = { ...newErrors, taxId: 'Invalid TaxId' }
            } else if (!validateTaxId(currentTaxId)) {
                newProviderData[taxIndex].error = true
                newProviderData[taxIndex].errorMessage = 'Invalid Tax Id'
                isValid = false
                newErrors = { ...newErrors, taxId: 'Invalid TaxId' }
            }

            for (let npiIndex = 0; npiIndex < taxIdData.providers.length; npiIndex++) {
                const provider = taxIdData.providers[npiIndex]
                if (newProviderData[taxIndex].providers[npiIndex].error === true) {
                    isValid = false
                    newErrors = { ...newErrors, taxId: 'Duplicate NPIs' }
                } else if (!validateNpi(provider)) {
                    newProviderData[taxIndex].providers[npiIndex].error = true
                    newProviderData[taxIndex].providers[npiIndex].errorMessage = 'NPI Required'
                    isValid = false
                    newErrors = { ...newErrors, taxId: 'Invalid Npi Selection' }
                }

                for (
                    let locationIndex = 0;
                    locationIndex < taxIdData.providers[npiIndex].locations.length;
                    locationIndex++
                ) {
                    const location = provider.locations[locationIndex]
                    if (newProviderData[taxIndex].providers[npiIndex].locations[locationIndex].error === true) {
                        isValid = false
                        newErrors = { ...newErrors, taxId: 'Duplicate Locations' }
                    }
                    if (!validateLocation(location)) {
                        newProviderData[taxIndex].providers[npiIndex].locations[locationIndex].error = true
                        newProviderData[taxIndex].providers[npiIndex].locations[locationIndex].errorMessage =
                            'Location Required'
                        isValid = false
                        newErrors = { ...newErrors, taxId: 'Invalid Location Selection' }
                    }
                }
            }
        }

        return [isValid, newErrors, newProviderData]
    }

    const validateOfficeAdminForm = () => {
        let newErrors = { ...errors }
        let isValid = true

        const data = { ...formData, ...commonState?.data }
        const { firstName, lastName, phoneNumber, email, taxIdProviderGroupings } = data

        if (!firstName) {
            newErrors = { ...newErrors, firstName: 'Required' }
            isValid = false
        }

        if (!lastName) {
            newErrors = { ...newErrors, lastName: 'Required' }
            isValid = false
        }

        const [isValidPhone, phoneError] = validatePhone(phoneNumber, newErrors, validAreaCodes)
        if (isValidPhone === false) {
            newErrors = { ...newErrors, phoneNumber: phoneError }
            isValid = isValid && isValidPhone
        }

        if (!email) {
            newErrors = { ...newErrors, email: 'Required' }
            isValid = false
        } else if (!emailRegex.test(email)) {
            newErrors = { ...newErrors, email: 'Invalid email format' }
            isValid = false
        } else if (emailInfo.emailExists && isANewEmail(email)) {
            newErrors = { ...newErrors, email: 'Email already exists' }
            isValid = false
        } else if (email.includes('@hcpipa.com')) {
            newErrors = { ...newErrors, email: 'Unable to use an hcpipa e-mail' }
            isValid = false
        }

        const [valid, err, newProviderData] = validateAllTaxProviderInfo(isValid, newErrors)

        // update validation errors from tax info validation
        isValid = valid
        newErrors = err

        setErrors(newErrors)
        setCurrentProviderInfo({ taxIdProviderGroupings: newProviderData })

        handleValidationCallback(isValid)
    }

    useEffect(() => {
        if (hasNewUserDetail === false) return
        const providerInfo = translateServerUserDetailsToClient(userDetails)
        setCurrentProviderInfo(providerInfo)
        setCurrentUserId(userDetails?.id)
    }, [userDetails?.id, hasNewUserDetail])

    const translateServerUserDetailsToClient = (serverDetails: IUser | null | undefined): IOfficeAdminProviderInfo => {
        if (
            serverDetails == null ||
            serverDetails.taxIdProviderGroupings == null ||
            serverDetails.taxIdProviderGroupings.length === 0
        )
            return { taxIdProviderGroupings: [] }

        const clientDetails = serverDetails?.taxIdProviderGroupings.map((grouping) => {
            return {
                taxId: grouping.taxId,
                providers: grouping.providers.map((provider) => {
                    return {
                        npi: provider.npi,
                        locations: provider.locations.map((location, index) => {
                            return {
                                locationId : location.locationId,
                                address: location.address,
                                city: location.city,
                                zipCode: location.zipCode,
                                ezCapLocationId: location.ezCapLocationId,
                                error: false,
                                errorMessage: '',
                                suite: location.suite,
                                locationType: index === 0 ? 1 : 2,
                                officePhoneNumber: location.officePhoneNumber,
                                state: location.state
                            } as ILocationData
                        }),
                        error: false,
                        errorMessage: ''
                    } as INPILocationsData
                }),
                error: false,
                errorMessage: ''
            } as ITaxIdNpiData
        })

        const providerInfo = { taxIdProviderGroupings: clientDetails ?? [] }

        return providerInfo
    }

    useEffect(() => {
        if (emailInfo?.emailExists) {
            validateEmailHandler()
        }
    }, [emailInfo])

    const validateEmailHandler = () => {
        let newErrors = { ...errors }
        let isValid = true

        const data = { ...formData, ...commonState?.data }
        const { email } = data

        const [isValidTemp, emailError] = validateEmail(email, newErrors, userDetails?.email, emailInfo?.emailExists)

        isValid = isValidTemp && isValid
        newErrors = { ...newErrors, email: emailError }

        if (onSetValidForm) {
            onSetValidForm(isValid)
        }

        setErrors(newErrors)
    }

    const handleEmailOnBlur = () => {
        const data = { ...formData, ...commonState?.data }
        const { email } = data

        validateEmailHandler()
        dispatch(doesEmailAlreadyExist(email))
    }

    const handlePhoneOnBlur = () => {
        let newErrors = { ...errors }
        let isValid = true

        const data = { ...formData, ...commonState?.data }
        const { phoneNumber } = data

        const [isValidPhone, phoneError] = validatePhone(phoneNumber, newErrors, validAreaCodes)

        if (isValidPhone === false) {
            isValid = isValidPhone && isValid
            newErrors = { ...newErrors, phoneNumber: phoneError }
        }

        if (onSetValidForm) {
            onSetValidForm(isValid)
        }

        setErrors(newErrors)
    }

    return (
        <article>
            <StyledUserDetailsFieldSet disabled={isEdit}>
                <StyledInputContainerWrap>
                    <StyledInputContainer>
                        <TextField
                            name="firstName"
                            placeholder={inputLabels.first_name}
                            legend={inputLabels.first_name}
                            inputType={'text'}
                            value={userDetails?.firstName}
                            onTextChanged={handleInput}
                            error={errors?.firstName.length > 0}
                            errorMessage={errors?.firstName}
                            externalStateValue={commonState?.getValue('firstName', '')}
                        />
                    </StyledInputContainer>
                    <StyledInputContainer>
                        <TextField
                            name="lastName"
                            placeholder={inputLabels.last_name}
                            legend={inputLabels.last_name}
                            inputType={'text'}
                            value={userDetails?.lastName}
                            onTextChanged={handleInput}
                            error={errors?.lastName.length > 0}
                            errorMessage={errors?.lastName}
                            externalStateValue={commonState?.getValue('lastName', '')}
                        />
                    </StyledInputContainer>
                </StyledInputContainerWrap>
                <StyledInputContainerWrap>
                    <StyledInputContainer>
                        <MaskedTextField
                            name="phoneNumber"
                            placeholder={inputLabels.phone_number}
                            legend={inputLabels.phone_number}
                            inputType={'tel'}
                            value={userDetails?.phoneNumber}
                            onTextChanged={handleInput}
                            onBlur={handlePhoneOnBlur}
                            error={errors?.phoneNumber.length > 0}
                            errorMessage={errors?.phoneNumber}
                            mask={'(999) 999-9999'}
                            externalStateValue={commonState?.getValue('phoneNumber', '')}
                        />
                    </StyledInputContainer>
                    <StyledInputContainer>
                        <TextField
                            name="email"
                            placeholder={inputLabels.email}
                            legend={inputLabels.email}
                            inputType={'email'}
                            value={userDetails?.email}
                            onTextChanged={handleInput}
                            handleBlur={handleEmailOnBlur}
                            error={errors?.email.length > 0}
                            errorMessage={errors?.email}
                            externalStateValue={commonState?.getValue('email', '')}
                        />
                    </StyledInputContainer>
                </StyledInputContainerWrap>

                <Typography type={TYPOGRAPHY_TYPES.h2}>Provider Information</Typography>
                <StyledHeadersRowContainer>
                    <Typography type={TYPOGRAPHY_TYPES.p}>Billing Provider Tax ID (TIN)</Typography>
                    <Typography type={TYPOGRAPHY_TYPES.p}>National Provider Identifier (NPI)</Typography>
                    <Typography type={TYPOGRAPHY_TYPES.p}>Location</Typography>
                </StyledHeadersRowContainer>

                <TaxProviderInfo
                    userId={currentUserId}
                    currentProviderInfo={currentProviderInfo}
                    isReadOnly={isEdit}
                    setProviderInfo={setProviderInfo}
                    validFormCallback={validFormCallback}
                />
            </StyledUserDetailsFieldSet>
        </article>
    )
}

export default NewOfficeAdmin
