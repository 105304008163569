import * as yup from 'yup'

export const AWVDigitalValidationSchema = yup.object().shape({
    // patientName: yup.string().required('Patient Name is Required'),
    // dob: yup.date().required()
    // dos: yup.date().required(),
    // age: yup.number().required().positive().integer(),
    // gender: yup.mixed().required('Gender is Required'),
    // language: yup.mixed(),
    // email: yup.string().required('Email is Required').email('Invalid Email')
    // mobilePhone: yup
    // .string()
    // .matches(phoneRegExp, {
    //     message: 'Invalid Phone Number',
    //     name: 'mobilePhone',
    //     excludeEmptyString: true
    // })
    // .test('Valid Area code', 'Invalid Area Code', (value) => {
    //     const rawPhone = getRawPhone(value)
    //     return isValidAreaCode(rawPhone, areaCodes) || rawPhone == null || rawPhone == undefined || rawPhone == ''
    // })
})
