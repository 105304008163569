import styled, { css } from 'styled-components'

export const StyledFormTypeButtonContainer = styled.article`
    position: fixed;
    z-index: 10;
    bottom: 136px;
    right: 24px;
`

export const StyledFormTypeButton = styled.button<{ isActive?: boolean }>`
    position: relative;
    width: 48px;
    height: 48px;

    background-color: #2281c4;
    border: none;
    border-radius: 25px;
    box-shadow: 2px 2px 3px #999;
    transition: all 0.4s ease 0s;
    color: transparent;

    figure {
        margin: 0;
        margin-top: 5px;
        margin-left: 4px;
    }

    ${(props) => {
        if (props.isActive) {
            return css`
                width: 264px;
                display: flex;
                flex-direction: row;
                align-items: center;

                color: #ffffff;
                font-family: 'Inter', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;

                figure {
                    margin: 0;
                    margin-top: 5px;
                    margin-left: 14px;
                    margin-right: 14px;
                }

                :active {
                    background-color: #394a64;
                }
            `
        }
    }}

    cursor: pointer;
`
