export const generalIcon = `<svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.49998 10.4169C11.2631 10.4169 13.5031 8.17692 13.5031 5.41377C13.5031 2.65062 11.2631 0.410645 8.49998 0.410645C5.73683 0.410645 3.49686 2.65062 3.49686 5.41377C3.49962 8.17578 5.73798 10.4141 8.49998 10.4169ZM8.49998 8.74919C6.65788 8.74919 5.16457 7.25587 5.16457 5.41377C5.16595 4.99314 5.24922 4.57681 5.40972 4.188C5.43303 4.132 5.47914 4.08861 5.53647 4.06876C5.59429 4.04937 5.65772 4.05639 5.70991 4.08794C6.95061 4.83165 8.37011 5.22421 9.81664 5.22365C10.4058 5.22326 10.9932 5.15726 11.5677 5.02686C11.625 5.01447 11.6849 5.02689 11.7326 5.06104C11.7802 5.09519 11.8112 5.14789 11.8179 5.20614C11.8275 5.27498 11.8334 5.34429 11.8354 5.41377C11.8354 7.25587 10.3421 8.74919 8.49998 8.74919ZM0.578369 19.1724C0.583424 14.7995 4.12709 11.2558 8.49998 11.2507C12.8729 11.2558 16.4165 14.7995 16.4216 19.1724C16.4216 19.4026 16.2349 19.5893 16.0047 19.5893H0.995296C0.765034 19.5893 0.578369 19.4026 0.578369 19.1724Z" fill="#73839C"/>
</svg>
`

export const documentIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
<g stroke="#A4B0C0" stroke-width="1.5">
<path d="M14.3745 16.8853H18.7522" />
<path d="M14.3745 20.6377H18.7522"/>
<path d="M11.8728 16.5728C12.0455 16.5728 12.1854 16.7128 12.1854 16.8854C12.1854 17.0581 12.0455 17.1981 11.8728 17.1981C11.7001 17.1981 11.5601 17.0581 11.5601 16.8854C11.5601 16.7128 11.7001 16.5728 11.8728 16.5728" />
<path d="M14.3745 13.1333H18.7522" />
<path d="M11.8728 12.8203C12.0455 12.8203 12.1854 12.9603 12.1854 13.133C12.1854 13.3057 12.0455 13.4457 11.8728 13.4457C11.7001 13.4457 11.5601 13.3057 11.5601 13.133C11.5601 12.9603 11.7001 12.8203 11.8728 12.8203" />
<path d="M11.8728 20.3252C12.0455 20.3252 12.1854 20.4652 12.1854 20.6379C12.1854 20.8106 12.0455 20.9506 11.8728 20.9506C11.7001 20.9506 11.5601 20.8106 11.5601 20.6379C11.5601 20.4652 11.7001 20.3252 11.8728 20.3252" />
<path d="M18.1268 8.75537H21.2537C21.9445 8.75537 22.5045 9.31536 22.5045 10.0062V23.1394C22.5045 23.8301 21.9445 24.3901 21.2537 24.3901H8.7459C8.05511 24.3901 7.49512 23.8301 7.49512 23.1394V10.0062C7.49512 9.31536 8.05511 8.75537 8.7459 8.75537H11.8729C11.8729 7.0284 13.2728 5.62842 14.9998 5.62842C16.7268 5.62842 18.1268 7.0284 18.1268 8.75537Z" />
<path d="M14.9997 8.12988C15.1724 8.12988 15.3124 8.26988 15.3124 8.44258C15.3124 8.61528 15.1724 8.75527 14.9997 8.75527C14.827 8.75527 14.687 8.61528 14.687 8.44258C14.687 8.26988 14.827 8.12988 14.9997 8.12988" />
</g>
</svg>`

export const documentNewIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
<g stroke="#A4B0C0" stroke-width="1.5">
<circle cx="25" cy="5" r="4" fill="#089171" />
<path d="M14.3745 16.8853H18.7522" />
<path d="M14.3745 20.6377H18.7522"/>
<path d="M11.8728 16.5728C12.0455 16.5728 12.1854 16.7128 12.1854 16.8854C12.1854 17.0581 12.0455 17.1981 11.8728 17.1981C11.7001 17.1981 11.5601 17.0581 11.5601 16.8854C11.5601 16.7128 11.7001 16.5728 11.8728 16.5728" />
<path d="M14.3745 13.1333H18.7522" />
<path d="M11.8728 12.8203C12.0455 12.8203 12.1854 12.9603 12.1854 13.133C12.1854 13.3057 12.0455 13.4457 11.8728 13.4457C11.7001 13.4457 11.5601 13.3057 11.5601 13.133C11.5601 12.9603 11.7001 12.8203 11.8728 12.8203" />
<path d="M11.8728 20.3252C12.0455 20.3252 12.1854 20.4652 12.1854 20.6379C12.1854 20.8106 12.0455 20.9506 11.8728 20.9506C11.7001 20.9506 11.5601 20.8106 11.5601 20.6379C11.5601 20.4652 11.7001 20.3252 11.8728 20.3252" />
<path d="M18.1268 8.75537H21.2537C21.9445 8.75537 22.5045 9.31536 22.5045 10.0062V23.1394C22.5045 23.8301 21.9445 24.3901 21.2537 24.3901H8.7459C8.05511 24.3901 7.49512 23.8301 7.49512 23.1394V10.0062C7.49512 9.31536 8.05511 8.75537 8.7459 8.75537H11.8729C11.8729 7.0284 13.2728 5.62842 14.9998 5.62842C16.7268 5.62842 18.1268 7.0284 18.1268 8.75537Z" />
<path d="M14.9997 8.12988C15.1724 8.12988 15.3124 8.26988 15.3124 8.44258C15.3124 8.61528 15.1724 8.75527 14.9997 8.75527C14.827 8.75527 14.687 8.61528 14.687 8.44258C14.687 8.26988 14.827 8.12988 14.9997 8.12988" />
</g>
</svg>`
