import { FC } from 'react'
import { StyledExplanatoryNote, StyledFormTextAreaContainer } from './index.style'
import { useFormContext } from 'react-hook-form'
import { ISectionElement, ITextArea } from '../index.models'
import { Label } from '../Label'
import { AwvTooltip } from '../AwvTooltip'
import useErrors from 'hooks/useErrors'

interface ITextAreaProps {
    name: string
    label: string
    subLabel: string
    placeholder: string
    explanatoryNote: string
}

const TextArea: FC<ITextAreaProps> = ({ name, label, subLabel, placeholder, explanatoryNote }) => {
    const { register } = useFormContext()
    const errors = useErrors()

    const fieldName = name.replaceAll('.', '_')
    return (
        <AwvTooltip message={errors.getError(fieldName)}>
            <StyledFormTextAreaContainer isInError={errors.getError(fieldName)}>
                <Label htmlFor={name} label={label} subLabel={subLabel} />
                <textarea name={name} aria-label={label} placeholder={placeholder} {...register(name)} />
                {explanatoryNote ? <StyledExplanatoryNote>{explanatoryNote}</StyledExplanatoryNote> : ''}
            </StyledFormTextAreaContainer>
        </AwvTooltip>
    )
}

export const renderTextArea = (element: ISectionElement, key: string) => {
    const textArea = element as ITextArea
    return <TextArea key={key} {...textArea} />
}
