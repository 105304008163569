export const uploadEnabledIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M16.125 8.36634C15.5583 5.49134 13.0333 3.33301 10 3.33301C7.59167 3.33301 5.5 4.69967 4.45833 6.69967C1.95 6.96634 0 9.09134 0 11.6663C0 14.4247 2.24167 16.6663 5 16.6663H15.8333C18.1333 16.6663 20 14.7997 20 12.4997C20 10.2997 18.2917 8.51634 16.125 8.36634ZM11.6667 10.833V14.1663H8.33333V10.833H5.83333L10 6.66634L14.1667 10.833H11.6667Z" fill="#2281C4"/>
</svg>`

export const uploadDisabledIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M16.125 8.36634C15.5583 5.49134 13.0333 3.33301 10 3.33301C7.59167 3.33301 5.5 4.69967 4.45833 6.69967C1.95 6.96634 0 9.09134 0 11.6663C0 14.4247 2.24167 16.6663 5 16.6663H15.8333C18.1333 16.6663 20 14.7997 20 12.4997C20 10.2997 18.2917 8.51634 16.125 8.36634ZM11.6667 10.833V14.1663H8.33333V10.833H5.83333L10 6.66634L14.1667 10.833H11.6667Z" fill="#A4B0C0"/>
</svg>`

export const feedbackEnabledIcon = `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.3841 14.9944L15.0843 20.2963L12.4785 20.8175L12.9997 18.2117L18.3023 12.9098C18.5777 12.6338 18.9516 12.4788 19.3415 12.4788C19.7314 12.4788 20.1052 12.6338 20.3807 12.9098L20.3869 12.9153C20.9602 13.4903 20.9589 14.421 20.3841 14.9944Z" stroke="#394A64" stroke-width="1.5"/>
<path d="M10.3913 18.7327H6.22201C5.64635 18.7327 5.17969 18.2661 5.17969 17.6904V6.22493C5.17969 5.64928 5.64635 5.18262 6.22201 5.18262H16.6452C17.2208 5.18262 17.6875 5.64928 17.6875 6.22493V10.9154" stroke="#394A64" stroke-width="1.5"/>
<path d="M10.3916 8.30957H14.5609" stroke="#394A64" stroke-width="1.5"/>
<path d="M8.30664 11.4365H14.5605" stroke="#394A64" stroke-width="1.5"/>
<path d="M8.30664 14.5635H13.5182" stroke="#394A64" stroke-width="1.5"/>
</svg>
`

export const feedbackDisabledIcon = `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.3841 14.9944L15.0843 20.2963L12.4785 20.8175L12.9997 18.2117L18.3023 12.9098C18.5777 12.6338 18.9516 12.4788 19.3415 12.4788C19.7314 12.4788 20.1052 12.6338 20.3807 12.9098L20.3869 12.9153C20.9602 13.4903 20.9589 14.421 20.3841 14.9944Z" stroke="#A4B0C0" stroke-width="1.5"/>
<path d="M10.3913 18.7327H6.22201C5.64635 18.7327 5.17969 18.2661 5.17969 17.6904V6.22493C5.17969 5.64928 5.64635 5.18262 6.22201 5.18262H16.6452C17.2208 5.18262 17.6875 5.64928 17.6875 6.22493V10.9154" stroke="#A4B0C0" stroke-width="1.5"/>
<path d="M10.3916 8.30957H14.5609" stroke="#A4B0C0" stroke-width="1.5"/>
<path d="M8.30664 11.4365H14.5605" stroke="#A4B0C0" stroke-width="1.5"/>
<path d="M8.30664 14.5635H13.5182" stroke="#A4B0C0" stroke-width="1.5"/>
</svg>
`
export const feedbackHasNewEnabledIcon = `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="5" r="5" fill="#089171" stroke="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.3841 14.9944L15.0843 20.2963L12.4785 20.8175L12.9997 18.2117L18.3023 12.9098C18.5777 12.6338 18.9516 12.4788 19.3415 12.4788C19.7314 12.4788 20.1052 12.6338 20.3807 12.9098L20.3869 12.9153C20.9602 13.4903 20.9589 14.421 20.3841 14.9944Z" stroke="#394A64" stroke-width="1.5"/>
<path d="M10.3913 18.7327H6.22201C5.64635 18.7327 5.17969 18.2661 5.17969 17.6904V6.22493C5.17969 5.64928 5.64635 5.18262 6.22201 5.18262H16.6452C17.2208 5.18262 17.6875 5.64928 17.6875 6.22493V10.9154" stroke="#394A64" stroke-width="1.5"/>
<path d="M10.3916 8.30957H14.5609" stroke="#394A64" stroke-width="1.5"/>
<path d="M8.30664 11.4365H14.5605" stroke="#394A64" stroke-width="1.5"/>
<path d="M8.30664 14.5635H13.5182" stroke="#394A64" stroke-width="1.5"/>
</svg>
`

export const feedbackHasNewDisabledIcon = `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="5" r="5" fill="#A4B0C0" stroke="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.3841 14.9944L15.0843 20.2963L12.4785 20.8175L12.9997 18.2117L18.3023 12.9098C18.5777 12.6338 18.9516 12.4788 19.3415 12.4788C19.7314 12.4788 20.1052 12.6338 20.3807 12.9098L20.3869 12.9153C20.9602 13.4903 20.9589 14.421 20.3841 14.9944Z" stroke="#A4B0C0" stroke-width="1.5"/>
<path d="M10.3913 18.7327H6.22201C5.64635 18.7327 5.17969 18.2661 5.17969 17.6904V6.22493C5.17969 5.64928 5.64635 5.18262 6.22201 5.18262H16.6452C17.2208 5.18262 17.6875 5.64928 17.6875 6.22493V10.9154" stroke="#A4B0C0" stroke-width="1.5"/>
<path d="M10.3916 8.30957H14.5609" stroke="#A4B0C0" stroke-width="1.5"/>
<path d="M8.30664 11.4365H14.5605" stroke="#A4B0C0" stroke-width="1.5"/>
<path d="M8.30664 14.5635H13.5182" stroke="#A4B0C0" stroke-width="1.5"/>
</svg>
`

export const deleteIcon = `
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.5002 12.5V13.3333H26.6668V15H25.8335V25.8333C25.8335 26.75 25.0835 27.5 24.1668 27.5H15.8335C14.9168 27.5 14.1668 26.75 14.1668 25.8333V15H13.3335V13.3333H17.5002V12.5H22.5002ZM15.8335 25.8333H24.1668V15H15.8335V25.8333ZM17.5002 16.6667H19.1668V24.1667H17.5002V16.6667ZM22.5002 16.6667H20.8335V24.1667H22.5002V16.6667Z" fill="#B11B1B"/>
</svg>
`
export const deleteDisabledIcon = `
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.5002 12.5V13.3333H26.6668V15H25.8335V25.8333C25.8335 26.75 25.0835 27.5 24.1668 27.5H15.8335C14.9168 27.5 14.1668 26.75 14.1668 25.8333V15H13.3335V13.3333H17.5002V12.5H22.5002ZM15.8335 25.8333H24.1668V15H15.8335V25.8333ZM17.5002 16.6667H19.1668V24.1667H17.5002V16.6667ZM22.5002 16.6667H20.8335V24.1667H22.5002V16.6667Z" fill="#A4B0C0"/>
</svg>
`

export const newIndicator = `<svg width="10" height="10" viewBox="0 0 12 12">
<circle cx="6" cy="6" r="5" fill="#089171"  />
</svg>`
