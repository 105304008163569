import React, { useState, useEffect, FC } from 'react'
import { StyledCustomSvgIconFigure } from './index.style'

interface ICustomSvgIconSetProps {
    hoverIcon?: any
    activeIcon?: any
    icon?: any
}

export interface ICustomSvgIconProps {
    iconSet: ICustomSvgIconSetProps
    altText?: string
    style?: object
    isActive?: boolean
    margin?: string
    outerMargin?: string
    size?: number
    svg?: any
    className?: string
}

const CustomSvgIcon: FC<ICustomSvgIconProps> = ({
    iconSet,
    altText,
    style,
    isActive,
    margin,
    outerMargin,
    size,
    svg,
    className = ''
}) => {
    const [currIcon, setCurrIcon] = useState(iconSet.icon)
    const wrapperTag = 'span'

    const onMouseOver = () => {
        if (iconSet.hoverIcon) {
            if (iconSet.activeIcon && isActive) {
                setCurrIcon(iconSet.activeIcon)
            } else {
                setCurrIcon(iconSet.hoverIcon)
            }
        }
    }

    const onMouseOut = () => {
        if (iconSet.activeIcon && isActive) {
            setCurrIcon(iconSet.activeIcon)
        } else {
            setCurrIcon(iconSet.icon)
        }
    }

    useEffect(() => {
        if (iconSet.activeIcon && isActive) {
            setCurrIcon(iconSet.activeIcon)
        } else {
            setCurrIcon(iconSet.icon)
        }
    }, [isActive, iconSet.activeIcon, iconSet.icon])
    const newSvg = React.createElement(wrapperTag, {
        dangerouslySetInnerHTML: { __html: currIcon },
        style: {
            display: 'block',
            width: size,
            height: size,
            ...style
        },
        onMouseOver,
        onMouseOut
    })

    return (
        <StyledCustomSvgIconFigure
            className={className}
            style={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: size,
                height: size,
                margin: outerMargin ?? ''
            }}
        >
            {svg ? (
                newSvg
            ) : (
                <img
                    className={className}
                    src={`${currIcon}`}
                    style={{
                        ...style,
                        margin: margin ? margin : '0'
                    }}
                    alt={altText}
                    onMouseOver={() => onMouseOver()}
                    onMouseOut={() => onMouseOut()}
                />
            )}
        </StyledCustomSvgIconFigure>
    )
}

export default CustomSvgIcon
