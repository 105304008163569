import styled from 'styled-components'
import { UI_COLORS } from 'theme/constants'

const { dark_gray } = UI_COLORS

export const StyledLoginOrInvitationStatusWrap = styled.article`
    p {
        color: ${dark_gray};
    }
`
