import { useState } from 'react'

import { StyledMainNavWrapper, StyledAvatarMenu, StyledDropDownButton } from '../../index.styles'

import arrowDown from 'assets/arrowDown.svg'

import Avatar from 'components/Avatar'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import MainLogout from './MainLogout'
import useCurrentUser from 'hooks/useCurrentUser'

const MainProfile = () => {
    const currentUser = useCurrentUser()
    const [active, setActive] = useState(false)

    return (
        <>
            <StyledMainNavWrapper>
                <StyledAvatarMenu onClick={() => setActive(!active)}>
                    <Avatar containerSize={40}></Avatar>
                    <Typography type={TYPOGRAPHY_TYPES.p}>{currentUser.fullName}</Typography>
                    <StyledDropDownButton buttonImg={arrowDown} active={active}></StyledDropDownButton>
                </StyledAvatarMenu>
            </StyledMainNavWrapper>
            {!currentUser.isImpersonated && <MainLogout active={active} onBackgroundClick={() => setActive(false)} />}
        </>
    )
}

export default MainProfile
