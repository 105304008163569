import MaskedTextField from 'components/MaskedTextField'
import { StyledMainContainer } from 'index.styles'
import { inputLabels } from 'messages/inputLabels'
import LocationModal from 'pages/hcp/users/create/NewOfficeAdmin/LocationModal'
import { IAddressModalInfo } from 'utilities/addressUtilities'
import {
    FullLocationIndexes,
    IAreaCodeResult,
    IEditAddress,
    ILocationDetailData,
    IOfficeAdminProviderInfo,
    INPIDetail,
    INPILocationsData
} from '../../pages/hcp/users/create/index.models'

interface ILocationModalDialogProps {
    userId: string
    taxId: string
    addressModalInfo: IAddressModalInfo
    validAreaCodes: number[]
    editingAddress: IEditAddress
    handleCloseModal: () => void
    handleAddressFromModal: (address: any) => void
}

export const LocationModalDialog = ({
    userId,
    taxId,
    addressModalInfo,
    validAreaCodes,
    editingAddress,

    handleCloseModal,
    handleAddressFromModal
}: ILocationModalDialogProps) => {
    return (
        <div key={`modal_${taxId}_${userId}`}>
            {addressModalInfo.isShown && (
                <LocationModal
                    locationType={addressModalInfo.locationType}
                    onCancel={handleCloseModal}
                    onSave={handleAddressFromModal}
                    locationToEdit={editingAddress}
                    validAreaCodes={validAreaCodes}
                ></LocationModal>
            )}
        </div>
    )
}
