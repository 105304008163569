import { FC } from 'react'
import { InputField } from '../InputField'
import { IDateOfBirthPicker, IDatePicker, ISectionElement } from '../index.models'

interface IDatePickerProps {
    name: string
    label: string
    subLabel: string
    readOnly?: boolean
}

const DatePicker: FC<IDatePickerProps> = ({ name, label, subLabel, readOnly = false }) => (
    <InputField type="date" name={name} label={label} subLabel={subLabel} readOnly={readOnly} />
)

export const renderDatePicker = (element: ISectionElement, key: string) => {
    const datePicker = element as IDatePicker
    return <DatePicker key={key} {...datePicker} />
}

export const renderDateOfBirthPicker = (element: ISectionElement, key: string) => {
    const dateOfBirthPicker = element as IDateOfBirthPicker
    return <DatePicker key={key} {...dateOfBirthPicker} readOnly={true} />
}
