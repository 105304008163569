import { StyledAWVDigitalDescriptionContainer, StyledAWVDigitalTitleContainer } from '../index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC } from 'react'

interface ISectionDescriptionProps {
    description: string
}

const SectionDescription: FC<ISectionDescriptionProps> = ({ description }) => {
    return (
        <StyledAWVDigitalDescriptionContainer>
            <Typography type={TYPOGRAPHY_TYPES.p}>
                <article dangerouslySetInnerHTML={{ __html: description }}></article>
            </Typography>
        </StyledAWVDigitalDescriptionContainer>
    )
}

export default SectionDescription
