import { FC } from 'react'
import {
    StyledInlineQuestionContainer,
    StyledInlineQuestionRow,
    StyledInlineQuestionRowItem,
    StyledResetContainer
} from './index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { Controller, useFormContext } from 'react-hook-form'
import { IRadioBooleanList, IRadioBooleanListItem, ISectionElement } from '../index.models'
import { Label } from '../Label'
import { evaluateCondition } from '../ConditionEvaluator'
import { AwvTooltip } from '../AwvTooltip'
import useErrors from 'hooks/useErrors'
import IconButton from '@mui/material/IconButton'
import { Tooltip } from '@mui/material'
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp'
import { useResetValue } from 'hooks/useResetValue'

interface IRadioBooleanListProps {
    name: string
    label: string
    trueLabel: string
    falseLabel: string
    items: IRadioBooleanListItem[]
}

const RadioBooleanList: FC<IRadioBooleanListProps> = ({ name, label, trueLabel, falseLabel, items }) => {
    const { control, setValue, getValues, watch } = useFormContext()
    const errors = useErrors()
    const resetValue = useResetValue()

    return (
        <StyledInlineQuestionContainer>
            {label && <Typography type={TYPOGRAPHY_TYPES.p}>{label}</Typography>}
            <div>
                {items.map(
                    (item, index) =>
                        evaluateCondition(item.condition, watch) && (
                            <AwvTooltip key={`tooltip.RBL.${name}.${index}`} message={errors.getError(item.name)}>
                                <StyledInlineQuestionRow key={`${name}-${index}`}>
                                    <StyledInlineQuestionRowItem isInError={errors.getError(item.name)}>
                                        <Label label={item.label} subLabel={item.subLabel} wrapInParagraph={true} />
                                    </StyledInlineQuestionRowItem>
                                    <StyledInlineQuestionRowItem>
                                        <label>
                                            <Controller
                                                name={item.name}
                                                control={control}
                                                render={({ field }) => (
                                                    <input
                                                        id={`${item.name}.true`}
                                                        {...field}
                                                        type="radio"
                                                        value={'True'}
                                                        checked={field.value === true}
                                                        onChange={() => setValue(item.name, true)}
                                                    />
                                                )}
                                            />
                                            {item.trueLabel ?? trueLabel ?? 'Yes'}
                                        </label>
                                    </StyledInlineQuestionRowItem>
                                    <StyledInlineQuestionRowItem>
                                        <label>
                                            <Controller
                                                name={item.name}
                                                control={control}
                                                render={({ field }) => (
                                                    <input
                                                        id={`${item.name}.false`}
                                                        {...field}
                                                        type="radio"
                                                        value={'False'}
                                                        checked={field.value === false}
                                                        onChange={() => setValue(item.name, false)}
                                                    />
                                                )}
                                            />
                                            {item.falseLabel ?? falseLabel ?? 'No'}
                                        </label>
                                    </StyledInlineQuestionRowItem>
                                    <StyledInlineQuestionRowItem>
                                        <StyledResetContainer>
                                            {getValues(item.name) !== undefined && getValues(item.name) !== null && (
                                                <Tooltip title="Reset">
                                                    <IconButton
                                                        onClick={() => {
                                                            resetValue(item.name)
                                                        }}
                                                    >
                                                        <HighlightOffSharpIcon sx={{ color: '#9e9e9e' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </StyledResetContainer>
                                    </StyledInlineQuestionRowItem>
                                </StyledInlineQuestionRow>
                            </AwvTooltip>
                        )
                )}
            </div>
        </StyledInlineQuestionContainer>
    )
}

export const renderRadioBooleanList = (element: ISectionElement, key: string) => {
    const radioBooleanList = element as IRadioBooleanList
    return <RadioBooleanList key={key} name={key} {...radioBooleanList} />
}
