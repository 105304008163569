import { css } from 'styled-components'

export const pxToRem = (pixels: number, base = 16) => {
    return css`
        ${pixels / base + 'rem'}
    `
}

/**
 * Method converts string hex to rgb or rgba
 * @param hex - string hex color
 * @param percentage - number between 0 and 1
 * @returns string rgb @OR string rgba if percentage is present and less than 1
 */
export const hex2rgb = (hex: string, percentage: number) => {
    if (hex === null || percentage > 1) return hex
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
    return percentage ? `rgba(${r},${g},${b},${Math.abs(percentage)})` : `rgb(${r},${g},${b})`
}

export const MOBILE = 375
export const TABLET = 768
export const TABLET_LANDSCAPE = 1024
export const DESKTOP = 1440
export const DESKTOP_TALL = 1100

export const SIZE = {
    MOBILE,
    TABLET,
    TABLET_LANDSCAPE,
    DESKTOP,
    DESKTOP_TALL
}
export const DEVICE = {
    mobile: `@media screen and (min-width: ${SIZE.MOBILE}px)`,
    tablet: `@media screen and (min-width: ${SIZE.TABLET}px)`,
    tablet_landscape: `@media screen and (min-width: ${SIZE.TABLET_LANDSCAPE}px)`,
    desktop: `@media screen and (min-width: ${SIZE.DESKTOP}px)`,
    desktop_tall: `@media screen and (min-height: ${SIZE.DESKTOP_TALL}px)`
}

export const hideMainWindowScrollbars = (hide: boolean) => {
    if (hide) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'visible'
}
