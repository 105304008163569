import styled from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { dark_gray, white } = UI_COLORS

export const StyledInlineQuestionContainer = styled.article`
    width: ${pxToRem(1195)};
    margin-bottom: ${pxToRem(30)};

    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: ${dark_gray};
    }
`

export const StyledInlineQuestionRow = styled.ul<{ isInError?: any }>`
    list-style: none;

    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;

    ${(props) => {
        if (props.isInError) {
            return `
            border-left: solid 3px red;
            padding-left: 27px;
            `
        }
    }}
`

export const StyledInlineQuestionRowItem = styled.li<{ isInError?: any }>`
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #293854;
        display: flex;
        flex-direction: row;
        align-items: center;

        cursor: pointer;
    }

    padding-top: 26px;
    padding-bottom: 26px;

    input {
        margin: 0;
        padding: 0;
        margin-right: 8px;
        width: 20px;
        height: 20px;
    }

    :nth-child(1) {
        width: ${pxToRem(832)};
        background: ${white};
        padding-left: 30px;
        border-bottom: 1px solid #dee5ef;

        ${(props) => {
            if (props.isInError) {
                return `
            border-left: solid 3px red;
            padding-left: 27px;
            `
            }
        }}
    }

    :nth-child(2) {
        width: ${pxToRem(141)};
        background: ${white};
        border-bottom: 1px solid #dee5ef;
    }

    :nth-child(3) {
        width: ${pxToRem(171)} !important;
        background: ${white};
        border-bottom: 1px solid #dee5ef;
    }

    :nth-child(3) {
        width: ${pxToRem(50)};
    }
`
export const StyledResetContainer = styled.article`
    width: ${pxToRem(50)};
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        :disabled {
            visibility: hidden;
        }
    }
`
