export const dropDownArrow = `<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.67249 10.1328L12.5313 15.1328L17.3902 10.1328H7.67249Z" fill="#293854"/>
</svg>`

export const closeIcon = `<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.8334 1.34163L10.6584 0.166626L6.00008 4.82496L1.34175 0.166626L0.166748 1.34163L4.82508 5.99996L0.166748 10.6583L1.34175 11.8333L6.00008 7.17496L10.6584 11.8333L11.8334 10.6583L7.17508 5.99996L11.8334 1.34163Z" fill="#A4B0C0"/>
</svg>
`

export const backArrow = `<svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.49994 1.175L2.68327 5L6.49994 8.825L5.32494 10L0.324939 5L5.32494 8.50953e-07L6.49994 1.175Z" fill="#2281C4"/>
</svg>
`
