import { FC, useEffect, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { StyledFormCheckBoxListContainer, StyledRadioList, StyledRadioListItem } from './index.style'
import { ICheckBoxList, ICheckBoxListItem, ISectionElement } from '../index.models'
import { Label } from '../Label'
import { performFieldSynchronizationStep } from '../utils'
import { AwvTooltip } from '../AwvTooltip'
import useErrors from 'hooks/useErrors'

interface ICheckBoxListProps {
    name: string
    label: string
    subLabel: string
    items: ICheckBoxListItem[]
}

const CheckBoxList: FC<ICheckBoxListProps> = ({ name, label, subLabel, items }) => {
    const { register, control } = useFormContext()
    const { fields, append, remove, move } = useFieldArray({ control, name })

    const errors = useErrors()

    useEffect(() => {
        performFieldSynchronizationStep(items, fields, append, remove, move)
    }, [items, fields, append, remove, move])

    const labelsByName = items.reduce((acc: any, item: any) => {
        acc[item.name] = item.label
        return acc
    }, {})

    return (
        <StyledFormCheckBoxListContainer>
            <Label label={label} subLabel={subLabel} wrapInParagraph={true} />
            <AwvTooltip key={`tooltip_${name}`} message={errors.getError(name)}>
                <StyledRadioList isInError={errors.getError(name)}>
                    {fields.map((field: any, index: number) => (
                        <StyledRadioListItem key={field.id}>
                            <label>
                                <input {...register(`${name}.${index}.value`)} type="checkbox" />
                                {labelsByName[field.name]}
                            </label>
                        </StyledRadioListItem>
                    ))}
                </StyledRadioList>
            </AwvTooltip>
        </StyledFormCheckBoxListContainer>
    )
}

export const renderCheckBoxList = (element: ISectionElement, key: string) => {
    const checkBoxList = element as ICheckBoxList
    return <CheckBoxList key={key} {...checkBoxList} />
}
