import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { DEVICE, pxToRem } from 'utilities'

const { white, grey, light_gray4, light_blue2 } = UI_COLORS

export const StyledReferralSearchResultsWrap = styled.article`
    position: relative;
`

export const StyledReferralSearchResultsContainer = styled.ul`
    position: absolute;
    margin: 0;
    padding: 0;
    list-style: none;

    width: ${pxToRem(520)};
    z-index: 1;

    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
`

export const StyledReferralSearchResult = styled.li<{ isCurrent: boolean }>`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    color: #293854;

    padding: 19px 18px;

    ${(props) => {
        if (props.isCurrent) {
            return css`
                background-color: #edf4fb;
            `
        }
    }}

    :hover {
        cursor: pointer;
        ${(props) => {
            if (props.isCurrent) {
                return css`
                    background-color: #e3eefa;
                `
            } else {
                return css`
                    background-color: #f5f5f5;
                `
            }
        }}
    }

    span {
        color: #73839c;
        font-style: italic;
    }
`

export const StyledButtonContainer = styled.article`
    display: flex;
    flex-direction: row;
    margin-top: ${pxToRem(25)};

    ${DEVICE.tablet} {
        position: absolute;
        bottom: 30px;
        right: 30px;
    }
`

export const StyledCancelButton = styled.button`
    width: ${pxToRem(120)};
    height: ${pxToRem(50)};
    border-radius: ${pxToRem(4)};
    border: 0px;
    cursor: pointer;

    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};

    margin-right: ${pxToRem(16)};
    background-color: ${grey};
    color: ${white};

    :active {
        background-color: ${white};
        color: ${grey};
        border: 1px solid ${grey};
    }

    :disabled {
        background-color: ${light_gray4};
        cursor: default;
    }
`

export const StyledSelectButton = styled.button`
    width: ${pxToRem(120)};
    height: ${pxToRem(50)};
    border-radius: ${pxToRem(4)};
    border: 0px;
    cursor: pointer;

    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};
    color: ${white};
    background-color: ${light_blue2};

    :active {
        background-color: ${white};
        color: ${light_blue2};
        border: 1px solid ${light_blue2};
    }

    :disabled {
        background-color: ${light_gray4};
        cursor: default;

        :active {
            background-color: ${light_gray4};
            color: ${white};
            border: 1px solid ${light_gray4};
        }
    }
`
