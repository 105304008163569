import styled from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { white } = UI_COLORS

export const StyledRadioBtnTxtFieldFormContainer = styled.article`
    margin-bottom: 30px;
`

export const StyledTableFormHeader = styled.ul`
    width: ${pxToRem(1145)};
    background-color: #f7f8fa;
    list-style: none;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0 30px;
    border: 1px solid #dee5ef;
`

export const StyledTableFormItem = styled.li`
    padding: 11px 0;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #73839c;
`

export const StyledTableFormBody = styled.ul`
    width: ${pxToRem(1195)};
    list-style: none;
    height: 86px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 0;
    padding: 0;
`
export const StyledTableFormBodyItemsContainer = styled.li`
    display: flex;
`

export const StyledTableFormBodyItemRadiosWrapper = styled.article<{ isInError?: any }>`
    display: flex;
    ${(props) => {
        if (props.isInError) {
            return `
                border-left: 1px solid red;
                `
        }
    }}
`

const StyledTableFormBodyItemInner = styled.li`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #293854;
    padding: 0 31px;

    display: flex;
    align-items: center;
    background-color: ${white};
`

export const StyledTableFormBodyItemLeft = styled(StyledTableFormBodyItemInner)<{ isInError?: any }>`
    border-left: 1px solid #dee5ef;
    border-bottom: 1px solid #dee5ef;

    ${(props) => {
        if (props.isInError) {
            return `
                border-left: 3px solid red;
                padding-left: 29px;
                `
        }
    }}
`

export const StyledTableFormBodyItemMiddle = styled(StyledTableFormBodyItemInner)`
    border-bottom: 1px solid #dee5ef;
`

export const StyledTableFormBodyItemRight = styled(StyledTableFormBodyItemInner)`
    padding-right: 53px;
    border-right: 1px solid #dee5ef;
    border-bottom: 1px solid #dee5ef;
`

export const StyledTableFormInputContainer = styled.article<{ isInError?: any }>`
    input {
        width: 400px;
        height: 50px;
        border: 1px solid #73839c;
        border-radius: 4px;

        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;

        color: #394a64;

        outline: none;
        padding: 0 10px;
        ${(props) => {
            if (props.isInError) {
                return `border: 1px solid red;`
            }
        }}
    }
`
export const StyledResetContainer = styled.article`
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        :disabled {
            visibility: hidden;
        }
    }
`
