import { FC } from 'react'
import { InputField } from '../InputField'
import { ITextField, ISectionElement, IPatientNameField } from '../index.models'

interface ITextFieldProps {
    name: string
    label: string
    subLabel: string
    placeholder: string
    readOnly?: boolean
}

const TextField: FC<ITextFieldProps> = ({ name, label, subLabel, placeholder, readOnly = false }) => (
    <InputField
        type="text"
        name={name}
        label={label}
        subLabel={subLabel}
        placeholder={placeholder ?? label}
        readOnly={readOnly}
    />
)

export const renderTextField = (element: ISectionElement, key: string) => {
    const textField = element as ITextField
    return <TextField key={key} {...textField} />
}

export const renderPatientNameField = (element: ISectionElement, key: string) => {
    const patientNameField = element as IPatientNameField
    return <TextField key={key} {...patientNameField} readOnly={true} />
}
