import { FieldValues, UseFormWatch } from 'react-hook-form'
import { ICondition, ISumCondition, IValueCondition } from '../index.models'

const evaluateSumCondition = (fieldName: string, condition: ICondition, watch: UseFormWatch<FieldValues>) => {
    const sumCondition = condition as ISumCondition
    const sum =
        watch(fieldName) && watch(fieldName).some((item: any) => !isNaN(item.value) && item.value !== null)
            ? watch(fieldName).reduce(
                  (acc: number, item: any) => acc + (item.value && !isNaN(item.value) ? item.value : 0),
                  0
              )
            : null
    return (
        sum !== null &&
        (sumCondition.minValue === null || sumCondition.minValue === undefined || sum >= sumCondition.minValue) &&
        (sumCondition.maxValue === null || sumCondition.maxValue === undefined || sum <= sumCondition.maxValue)
    )
}

const evaluateValueCondition = (fieldName: string, condition: ICondition, watch: UseFormWatch<FieldValues>) => {
    const valueCondition = condition as IValueCondition
    return valueCondition.values.includes(watch(fieldName))
}

const conditionEvaluators: any = {
    sum: evaluateSumCondition,
    value: evaluateValueCondition
}

export const evaluateCondition = (
    condition: ICondition,
    watch: UseFormWatch<FieldValues>,
    parentName?: string
): boolean => {
    if (condition) {
        const conditionEvaluator = conditionEvaluators[condition.$type] as (
            fieldName: string,
            condition: ICondition,
            watch: UseFormWatch<FieldValues>
        ) => boolean
        if (conditionEvaluator) {
            return conditionEvaluator(
                parentName ? condition.fieldName.replaceAll('${parent}', parentName) : condition.fieldName,
                condition,
                watch
            )
        } else {
            throw new Error(`'${condition.$type}' doesn't represent a valid condition type.`)
        }
    } else {
        return true
    }
}
