import styled from 'styled-components'
import { UI_COLORS } from 'theme/constants'
import { DEVICE, pxToRem } from 'utilities'

const { dark_gray } = UI_COLORS

export const StyledReportsContainer = styled.article`
    padding: ${pxToRem(50)} ${pxToRem(30)};
    max-width: 1205px;

    padding: ${pxToRem(50)} ${pxToRem(30)};

    h2 {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(22)};
        line-height: ${pxToRem(31)};
        letter-spacing: -0.01em;
        color: #293854;
    }

    h3 {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(26)};
        letter-spacing: -0.01em;
        color: #293854;
        position: relative;

        :after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            display: inline-block;
            width: 40px;
            height: 3px;
            background-color: #293854;
        }
    }

    fieldset {
        margin: 0;
        border: 0;
        padding: 0;

        :disabled {
            input {
                background-color: #f5f5f5;
            }
        }
    }

    ${DEVICE.desktop} {
        padding: ${pxToRem(180)} ${pxToRem(30)} ${pxToRem(120)};
    }
`

export const StyledHeader = styled.article`
    display: flex;
    flex-direction: row;
    padding-top: ${pxToRem(0)};
    padding-right: ${pxToRem(30)};

    p {
        flex-grow: 1;
        margin: ${pxToRem(5)} 0 0 0;

        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(0)};
        letter-spacing: -0.01em;
        color: ${dark_gray};

        display: flex;
        align-items: center;

        ${DEVICE.tablet} {
            font-size: ${pxToRem(26)};
            line-height: ${pxToRem(31)};
        }
    }
`
