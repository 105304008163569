import { documentIcon, generalIcon } from './icons'

export interface INavItem {
    icon: string
    title: ENavTab
}

export enum ENavTab {
    GENERAL = 'General',
    REPORTS = 'Reports'
}

export const NavConfigItems: INavItem[] = [
    {
        icon: generalIcon,
        title: ENavTab.GENERAL
    },
    {
        icon: documentIcon,
        title: ENavTab.REPORTS
    }
]
