import { TYPOGRAPHY_TYPES, ITypographyProps } from './index.models'
import {
    StyledHeader1,
    StyledHeader2,
    StyledHeader3,
    StyledHeader4,
    StyledHeader5,
    StyledHeader6,
    StyledParagraph,
    StyledCode,
    StyledSpan,
    StyledI,
    StyledB,
    StyledStrong,
    StyledTime
} from './index.style'

const Typography = ({ id, type, children, style, className, dateTime, tabIndex, variant }: ITypographyProps) => {
    switch (type) {
        case TYPOGRAPHY_TYPES.h1:
            return (
                <StyledHeader1 id={id} tabIndex={tabIndex} style={style} className={className} variant={variant}>
                    {children}
                </StyledHeader1>
            )
        case TYPOGRAPHY_TYPES.h2:
            return (
                <StyledHeader2 id={id} tabIndex={tabIndex} style={style} className={className}>
                    {children}
                </StyledHeader2>
            )
        case TYPOGRAPHY_TYPES.h3:
            return (
                <StyledHeader3 id={id} tabIndex={tabIndex} style={style} className={className}>
                    {children}
                </StyledHeader3>
            )
        case TYPOGRAPHY_TYPES.h4:
            return (
                <StyledHeader4 id={id} tabIndex={tabIndex} style={style} className={className}>
                    {children}
                </StyledHeader4>
            )
        case TYPOGRAPHY_TYPES.h5:
            return (
                <StyledHeader5 id={id} tabIndex={tabIndex} style={style} className={className}>
                    {children}
                </StyledHeader5>
            )
        case TYPOGRAPHY_TYPES.h6:
            return (
                <StyledHeader6 id={id} tabIndex={tabIndex} style={style} className={className}>
                    {children}
                </StyledHeader6>
            )
        case TYPOGRAPHY_TYPES.p:
            return (
                <StyledParagraph id={id} tabIndex={tabIndex} style={style} className={className}>
                    {children}
                </StyledParagraph>
            )
        case TYPOGRAPHY_TYPES.code:
            return (
                <StyledCode id={id} tabIndex={tabIndex} style={style} className={className}>
                    {children}
                </StyledCode>
            )
        case TYPOGRAPHY_TYPES.span:
            return (
                <StyledSpan id={id} tabIndex={tabIndex} style={style} className={className}>
                    {children}
                </StyledSpan>
            )
        case TYPOGRAPHY_TYPES.i:
            return (
                <StyledI id={id} tabIndex={tabIndex} style={style} className={className}>
                    {children}
                </StyledI>
            )
        case TYPOGRAPHY_TYPES.b:
            return (
                <StyledB id={id} tabIndex={tabIndex} style={style} className={className}>
                    {children}
                </StyledB>
            )
        case TYPOGRAPHY_TYPES.strong:
            return (
                <StyledStrong id={id} tabIndex={tabIndex} style={style} className={className}>
                    {children}
                </StyledStrong>
            )
        case TYPOGRAPHY_TYPES.time:
            return (
                <StyledTime id={id} tabIndex={tabIndex} style={style} className={className} dateTime={dateTime}>
                    {children}
                </StyledTime>
            )
        default:
            break
    }
    return null
}

export default Typography
