import styled from 'styled-components'
import { UI_COLORS } from 'theme'
import { DEVICE, pxToRem } from 'utilities'

const { light_gray4, grey, white, light_blue2 } = UI_COLORS

export const StyledButtonContainer = styled.article`
    display: flex;
    flex-direction: row;
    margin-top: ${pxToRem(25)};

    ${DEVICE.tablet} {
        position: absolute;
        bottom: 30px;
        right: 30px;
    }
`

export const StyledConfirmButton = styled.button<{ actionButtonType?: boolean }>`
    width: ${pxToRem(120)};
    height: ${pxToRem(50)};
    border-radius: ${pxToRem(4)};
    border: 0px;
    cursor: pointer;

    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};
    color: ${white};

    background-color: ${light_blue2};

    :active {
        background-color: ${white};
        color: ${light_blue2};
        border: 1px solid ${light_blue2};
    }

    :disabled {
        background-color: ${light_gray4};

        :active {
            background-color: ${light_gray4};
            color: ${white};
            border: 1px solid ${light_gray4};
        }
    }
`

export const StyledCancelButton = styled.button`
    width: ${pxToRem(120)};
    height: ${pxToRem(50)};
    border-radius: ${pxToRem(4)};
    border: 0px;
    cursor: pointer;

    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};

    margin-right: ${pxToRem(16)};
    background-color: ${grey};
    color: ${white};

    :active {
        background-color: ${white};
        color: ${grey};
        border: 1px solid ${grey};
    }
`
