import styled, { css } from 'styled-components'
import { DEVICE, pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { white, dark_gray } = UI_COLORS

export const StyledLocationModalContainer = styled.article`
    h2 {
        margin-top: 0;
    }
`

export const StyledInputGroup = styled.article`
    display: flex;
    flex-direction: column;

    ${DEVICE.tablet} {
        flex-direction: row;
    }
`

export const StyledLargeInputWrap = styled.article`
    width: 100%;
    margin-right: ${pxToRem(40)};
    margin-bottom: ${pxToRem(24)};

    ${DEVICE.tablet} {
        width: ${pxToRem(400)};
    }
`

export const StyledMediumInputWrap = styled.article`
    width: 100%;
    margin-right: ${pxToRem(40)};
    margin-bottom: ${pxToRem(24)};

    ${DEVICE.tablet} {
        width: ${pxToRem(250)};
    }
`

export const StyledSmallInputWrap = styled.article`
    width: 100%;
    margin-right: ${pxToRem(40)};
    margin-bottom: ${pxToRem(24)};

    ${DEVICE.tablet} {
        width: ${pxToRem(220)};
    }
`
export const StyledButtonsContainer = styled.article`
    display: flex;
    flex-direction: row;
    justify-content: end;

    > button {
        margin-bottom: 0;
    }
`
