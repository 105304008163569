import CustomSvgIcon from 'components/CustomSvgIcon'
import React, { FC, useEffect, useRef, useState } from 'react'
import { searchIcon } from './icons'
import {
    StyledSearchInput,
    StyledSearchContainer,
    StyledIconButton,
    StyledSpinner,
    StyledSpinnerContainer
} from './index.style'

interface ISearchTextFieldProps {
    name?: string
    disabled?: boolean
    inputType?: string
    placeholder: string
    value?: string
    loading?: boolean
    onValueChanged: (newValue: string) => void
    onClearSearch: any
}

const SearchTextField: FC<ISearchTextFieldProps> = ({
    name,
    disabled,
    inputType,
    placeholder,
    value,
    loading,
    onClearSearch,
    onValueChanged
}) => {
    const searchInputRef = useRef<any>(null)
    const [focused, setFocused] = useState(false)

    useEffect(() => {
        if (searchInputRef?.current) {
            searchInputRef.current.onfocus = () => {
                setFocused(true)
            }

            searchInputRef.current.onblur = () => {
                setFocused(false)
            }
        }
    }, [searchInputRef])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        onValueChanged(e.target.value)
    }

    const handleClearSearch = () => {
        onClearSearch()
    }

    return (
        <>
            <StyledSearchContainer>
                <CustomSvgIcon iconSet={{ icon: searchIcon }} svg></CustomSvgIcon>
                <StyledSearchInput
                    name={name}
                    type={inputType}
                    ref={searchInputRef}
                    placeholder={placeholder}
                    onChange={handleInputChange}
                    disabled={disabled}
                    value={value}
                />
                {focused && loading ? (
                    <StyledSpinnerContainer>
                        <StyledSpinner viewBox="0 0 50 50">
                            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2.5" />
                        </StyledSpinner>
                    </StyledSpinnerContainer>
                ) : (
                    <>
                        {value.trim().length > 0 ? (
                            <StyledIconButton size={30} onClick={handleClearSearch}>
                                <img src={require('../../assets/delete-cross.svg').default} alt="clear search" />
                            </StyledIconButton>
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </StyledSearchContainer>
        </>
    )
}

export default SearchTextField
