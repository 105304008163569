import { FC, ReactElement, useEffect } from 'react'
import { Tooltip } from '@mui/material'
import React from 'react'

interface IProps {
    message: any
    placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top'
}

export const AwvTooltip: FC<IProps> = ({ message, placement, children }) => {
    const title = message == null || (typeof message === 'string' && message.trim().length === 0) ? '' : message
    return (
        <Tooltip
            title={title}
            arrow
            PopperProps={{
                sx: {
                    '.MuiTooltip-tooltip': {
                        bgcolor: 'lightyellow',
                        color: 'red',
                        outline: '1px solid red',
                        fontFamily: "'Inter', sans-serif",
                        fontSize: '0.8em'
                    },
                    '.MuiTooltip-arrow': {
                        color: 'red'
                    }
                }
            }}
            placement={placement}
        >
            {React.Children.only(children) as ReactElement}
        </Tooltip>
    )
}
