import { Fragment, ReactNode, useEffect, useState } from 'react'

import {
    StyledMainNav,
    StyledAside,
    StyledLogoImage,
    StyledMainContainer,
    StyledMirrorControlBar,
    StyledUserMirroringDetails,
    StyledEndMirroring,
    StyledBackButton
} from '../index.styles'

import MainProfile from './main/MainProfile'
import MobileMenu from './main/MobileMenu'

import Sidebar from 'layouts/main/Sidebar'
import sideBarConfig from 'layouts/main/Sidebar/SidebarConfig'
import Toasts from 'components/Toasts'
import { useLocation } from 'react-router-dom'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { successCheckmark } from 'components/Toast/icons'
import { useNavigate } from 'react-router-dom'
import { appPath } from 'utilities/appPath'
import { backArrow } from 'icons'
import useCurrentUser from 'hooks/useCurrentUser'
import { ERoles } from 'models/enums/role'

interface IPageLayout {
    children: ReactNode
}

const PageLayout = ({ children }: IPageLayout) => {
    const [open, setOpen] = useState(false)
    const [isMirroredUser, setIsMirroredUser] = useState(false)
    const [isMembersPage, setIsMembersPage] = useState<boolean>(false)
    const [isUsersPage, setIsUsersPage] = useState<boolean>(false)
    const location = useLocation()
    const navigate = useNavigate()

    const currentUser = useCurrentUser()
    const currentUserImpersonating = currentUser?.fullName

    useEffect(() => {
        setIsMirroredUser(location?.search.includes('impersonatedUserId'))
    }, [location])

    useEffect(() => {
        setIsMembersPage(location?.pathname.includes('memberInformation'))
    }, [location])

    useEffect(() => {
        setIsUsersPage(location?.pathname.includes('users/'))
    }, [location])

    const handleOpenClick = () => setOpen(!open)

    const terminateMirroring = () => {
        window.close()
    }

    const handleBackToMembers = () => {
        navigate(appPath('/members'))
    }

    const handleBackToMembersCallCenterAdmin = () => {
        navigate(appPath('/callCenterAdminMembers'))
    }

    const handleBackToUsers = () => {
        navigate(appPath('/users'))
    }

    const MirroringBar = () => {
        return isMirroredUser ? (
            <StyledMirrorControlBar>
                <StyledUserMirroringDetails>
                    <CustomSvgIcon iconSet={{ icon: successCheckmark }} svg size={20}></CustomSvgIcon>
                    <Typography type={TYPOGRAPHY_TYPES.p}>
                        You are now viewing this portal as{' '}
                        <Typography type={TYPOGRAPHY_TYPES.span}> {currentUserImpersonating}</Typography>
                    </Typography>
                </StyledUserMirroringDetails>
                <StyledEndMirroring onClick={terminateMirroring}>End Mirroring</StyledEndMirroring>
            </StyledMirrorControlBar>
        ) : null
    }

    return (
        <Fragment>
            {/* Mirror/Impersonation Control bar */}
            <MirroringBar />

            {/* Main navigation */}
            <StyledMainNav isMirroring={isMirroredUser}>
                <MobileMenu open={open} onClick={handleOpenClick} />
                {isMembersPage ? (
                    <StyledBackButton
                        onClick={
                            currentUser.primaryRole === ERoles.CallCenterAdmin ||
                            currentUser.primaryRole === ERoles.ProviderRep
                                ? handleBackToMembersCallCenterAdmin
                                : handleBackToMembers
                        }
                    >
                        <CustomSvgIcon iconSet={{ icon: backArrow }} svg></CustomSvgIcon>
                        <Typography type={TYPOGRAPHY_TYPES.p}>{`Back To Patients`}</Typography>
                    </StyledBackButton>
                ) : (
                    ''
                )}
                {isUsersPage ? (
                    <StyledBackButton onClick={handleBackToUsers}>
                        <CustomSvgIcon iconSet={{ icon: backArrow }} svg></CustomSvgIcon>
                        <Typography type={TYPOGRAPHY_TYPES.p}>{`Back To Users`}</Typography>
                    </StyledBackButton>
                ) : (
                    ''
                )}
                {!isMirroredUser && <MainProfile />}
            </StyledMainNav>

            {/* Sidebar */}
            <StyledAside open={open} isMirroring={isMirroredUser}>
                <StyledLogoImage />
                <Sidebar sidebarConfig={sideBarConfig} />
            </StyledAside>

            {/* Main container */}
            <StyledMainContainer isMirroring={isMirroredUser}>{children}</StyledMainContainer>

            {/* Toasts */}
            <Toasts />
        </Fragment>
    )
}

export default PageLayout
