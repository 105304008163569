import styled, { css } from 'styled-components'
import { pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { grey, dark_gray, light_gray, light_gray4, light_blue2, red, light_gray3, light_gray2 } = UI_COLORS

export const StyledInputContainer = styled.article<{ hasLegend: boolean }>`
    width: 100%;
    position: relative;

    figure {
        position: absolute;
        top: ${pxToRem(48)};
        right: ${pxToRem(10)};
        margin: 0;

        ${(props) => {
            if (!props.hasLegend) {
                return css`
                    top: ${pxToRem(16)};
                `
            }
        }};
    }

    p {
        margin: 0;
        color: ${red};

        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(20)};
    }
`

export const StyledInput = styled.input<{ error?: boolean }>`
    height: ${pxToRem(50)};
    width: 100%;
    padding-left: ${pxToRem(16)};

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(16)};
    color: ${dark_gray};
    border: 1px solid #73839c;
    border-radius: ${pxToRem(4)};

    :focus {
        border: 1px solid ${light_blue2};
        outline: none;
    }

    ::placeholder {
        color: ${light_gray3} !important;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: ${pxToRem(14)};
    }

    :disabled {
        opacity: 1;
        color: ${grey};
        background: ${light_gray2};

        ::placeholder {
            color: #293854;
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: ${pxToRem(14)};
        }
    }

    ${(props) => {
        if (props.error) {
            return css`
                border: 1px solid red;
            `
        }
    }};
`
export const StyledInputLegend = styled.label`
    margin-bottom: ${pxToRem(15)};
    display: block;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(14)};
    color: ${dark_gray};
`
