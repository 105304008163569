import CustomSvgIcon from 'components/CustomSvgIcon'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC, useState } from 'react'
import { downloadMemberAwvFile } from 'redux/slices/memberProfile'
import { dispatch } from 'redux/store'
import { downloadIcon } from '../icons'
import {
    StyledDetailsContainer,
    StyledDetailsWrap,
    StyledDetailsButton,
    StyledDownloadButtonWrap,
    StyledAWVLongInstructionWrap
} from '../index.style'
import Spinner from 'components/Spinner'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import usePostCustomEvent from 'hooks/usePostCustomEvent'

interface IFileDownloaderProps {
    memberId: number
    npi: string
    isBusy: boolean
}

const FileDownloader: FC<IFileDownloaderProps> = ({ memberId, npi, isBusy }) => {
    const [downloadingFile, setDownloadingFile] = useState<boolean>(false)
    const downloadAWVPDFEvent = usePostCustomEvent(pageTitle.PatientsAWV, customEvents.DownloadAWVPDF)
    const handleDownload = () => {
        setDownloadingFile(true)
        if (memberId) {
            downloadAWVPDFEvent()
            dispatch(downloadMemberAwvFile({ memberId, npi })).then(() => {
                setDownloadingFile(false)
            })
        }
    }

    return (
        <>
            <Typography type={TYPOGRAPHY_TYPES.h4}>
                <b>
                    <i>Download the Annual Wellness Visit PDF</i>
                </b>
            </Typography>
            <StyledDetailsContainer>
                <StyledAWVLongInstructionWrap>
                    <StyledDetailsWrap>
                        <Typography type={TYPOGRAPHY_TYPES.p}>
                            Download the most recent version of the AWV Form. You have the option to:
                            <ul>
                                <li>Complete the Web-based form</li>
                                or
                                <li>Print it, fill it out manually, and scan and upload it as a PDF </li>
                            </ul>
                        </Typography>
                    </StyledDetailsWrap>
                </StyledAWVLongInstructionWrap>
                <StyledDownloadButtonWrap>
                    <StyledDetailsButton
                        disabled={downloadingFile || isBusy}
                        onClick={() => handleDownload()}
                        noFill={true}
                        stroke={true}
                    >
                        Download <CustomSvgIcon iconSet={{ icon: downloadIcon }} svg></CustomSvgIcon>
                    </StyledDetailsButton>
                    {downloadingFile && <Spinner />}
                </StyledDownloadButtonWrap>
            </StyledDetailsContainer>
        </>
    )
}

export default FileDownloader
