import { FC, useState } from 'react'
import { StyledErrorCount, StyledValidationTogglerButton, StyledValidationTogglerContainer } from './style'
import CustomSvgIcon from 'components/CustomSvgIcon'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { validateIconOff, validateIconOn } from './icons'
import { Switch } from '@mui/material'

interface IProp {
    isOn: boolean
    errorCount: number
    onBodyClick: () => void
    onErrorCountClick: () => void
}

const ValidationToggle: FC<IProp> = ({ onBodyClick, isOn = false, errorCount = 0, onErrorCountClick }) => {
    const [isHovered, setIsHovered] = useState<boolean>(false)
    const validateIcon = isOn ? validateIconOn : validateIconOff
    const msg = !isOn
        ? 'Validation Off'
        : errorCount === 0
        ? 'Passes Validation'
        : errorCount === 1
        ? '1 Error'
        : `${errorCount} Errors`

    return (
        <StyledValidationTogglerContainer>
            <StyledValidationTogglerButton
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                isActive={isHovered}
                isOn={isOn}
                errorCount={errorCount}
                onClick={onBodyClick}
            >
                <CustomSvgIcon iconSet={{ icon: validateIcon }} svg></CustomSvgIcon>
                {isHovered && (
                    <>
                        <Typography type={TYPOGRAPHY_TYPES.span} style={{ width: 150 }}>
                            {msg}
                        </Typography>
                        <Switch checked={isOn} color="default" />
                    </>
                )}
            </StyledValidationTogglerButton>
            {isOn && errorCount > 0 && <StyledErrorCount onClick={onErrorCountClick}>{errorCount}</StyledErrorCount>}
        </StyledValidationTogglerContainer>
    )
}

export default ValidationToggle
