import CustomSvgIcon from 'components/CustomSvgIcon'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import React, { FC, useEffect, useState } from 'react'
import { warningIcon } from './icons'
import { StyledInput, StyledInputContainer, StyledInputLegend } from './index.style'
import { ITextFieldProps } from '../TextField'
import InputMask from 'react-input-mask'
interface IMaskedTextFieldProps extends ITextFieldProps {
    mask: string
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
}

const MaskedTextField: FC<IMaskedTextFieldProps> = ({
    name,
    legend,
    disabled,
    inputType,
    placeholder,
    value,
    onTextChanged,
    error,
    errorMessage,
    mask,
    onBlur,
    externalStateValue
}) => {
    const [inputValue, setInputValue] = useState(value ?? '')

    useEffect(() => {
        setInputValue(value ?? '')
    }, [value])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        onTextChanged(e)
        if (externalStateValue) {
            externalStateValue.set(e.target.value)
        } else {
            setInputValue(e.target.value)
        }
    }

    const hasError = () => {
        return (
            <>
                <CustomSvgIcon iconSet={{ icon: warningIcon }} svg></CustomSvgIcon>
                <Typography type={TYPOGRAPHY_TYPES.p}>{errorMessage}</Typography>
            </>
        )
    }

    return (
        <InputMask
            mask={mask}
            value={externalStateValue ? externalStateValue.get() : inputValue}
            onChange={handleInputChange}
            alwaysShowMask={false}
            maskPlaceholder={''}
        >
            {(inputProps: any) => {
                return (
                    <StyledInputContainer hasLegend={legend ? legend.length > 0 : false}>
                        <StyledInputLegend>{legend}</StyledInputLegend>
                        <StyledInput
                            name={name}
                            type={inputType}
                            placeholder={placeholder}
                            disabled={disabled}
                            error={error}
                            onBlur={onBlur}
                            {...inputProps}
                        />
                        {error && hasError()}
                    </StyledInputContainer>
                )
            }}
        </InputMask>
    )
}

export default MaskedTextField
