import * as React from 'react'
import DocumentsTableHeader from './Header'
import { StyledDocumentsTableWrapper, StyledInfoPanel, StyledTitleWrapper, StyledUploadBtn } from './index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import DocumentsTableBody from './Body'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { disabledUploadIcon, uploadIcon } from './icons'
import { DocumentType, IProviderDocument } from '../models'

interface IDocumentsPage {
    documentType: DocumentType
    documents?: IProviderDocument[]
    isAdmin: boolean
    isBusy: boolean
    uploadDocument: () => void
    downloadDocument: (documentId: string) => void
    deleteDocument: (documentId: string) => void
    reprocessDocument?: (documentId: string) => void
}

const adminChartsTableHeaders = ['File Name', 'Provider', 'Uploaded On', 'Uploaded By', 'Status', 'Feedback', 'Delete']
const adminOtherDocsTableHeaders = ['File Name', 'Provider', 'Uploaded On', 'Uploaded By', '', 'Feedback', 'Delete']
const chartsTableHeaders = ['File Name', '', 'Uploaded On', 'Uploaded By', 'Status', 'Feedback', 'Delete']
const otherDocsTableHeaders = ['File Name', '', 'Uploaded On', 'Uploaded By', '', 'Feedback', 'Delete']

const DocumentsTable: React.FunctionComponent<IDocumentsPage> = ({
    documentType,
    documents,
    isAdmin,
    isBusy,
    uploadDocument,
    downloadDocument,
    deleteDocument,
    reprocessDocument
}) => (
    <>
        <StyledDocumentsTableWrapper>
            <StyledTitleWrapper>
                <Typography type={TYPOGRAPHY_TYPES.h3}>
                    {documentType === DocumentType.MedicalChart ? 'Medical Charts' : 'Other Documents'}
                </Typography>
                {!isAdmin && (
                    <StyledUploadBtn onClick={uploadDocument} disabled={isBusy}>
                        <CustomSvgIcon
                            iconSet={{ icon: !isBusy ? uploadIcon : disabledUploadIcon }}
                            svg
                        ></CustomSvgIcon>
                        <Typography type={TYPOGRAPHY_TYPES.p}>Upload</Typography>
                    </StyledUploadBtn>
                )}
            </StyledTitleWrapper>
            {documents?.length > 0 ? (
                <>
                    <DocumentsTableHeader
                        headers={
                            documentType === DocumentType.MedicalChart
                                ? isAdmin
                                    ? adminChartsTableHeaders
                                    : chartsTableHeaders
                                : isAdmin
                                ? adminOtherDocsTableHeaders
                                : otherDocsTableHeaders
                        }
                    ></DocumentsTableHeader>
                    <DocumentsTableBody
                        tableData={documents}
                        documentType={documentType}
                        isAdmin={isAdmin}
                        isBusy={isBusy}
                        downloadDocument={downloadDocument}
                        deleteDocument={deleteDocument}
                        reprocessDocument={reprocessDocument}
                    ></DocumentsTableBody>
                </>
            ) : (
                <StyledInfoPanel>
                    <Typography type={TYPOGRAPHY_TYPES.p}>
                        {documentType === DocumentType.MedicalChart
                            ? 'No medical charts have been uploaded.'
                            : 'No documents have been uploaded.'}
                    </Typography>
                </StyledInfoPanel>
            )}
        </StyledDocumentsTableWrapper>
    </>
)

export default DocumentsTable
