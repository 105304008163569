import { Fragment } from "react"

import { useSelector } from "redux/store"

import Toast from "components/Toast"

const Toasts = () => {
    const { toasts } = useSelector((state: { toast: any }) => state.toast)

    return(
        <Fragment>
            {toasts.map((toast: any, index: number) => <Toast id={toast.id} message={toast.message} icon={toast.icon} key={index} variant={toast.variant} /> )}
        </Fragment>
    )
}

export default Toasts
