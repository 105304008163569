import { FC, useEffect, useState } from 'react'
import {
    StyledDocumentsBodyTable,
    StyledDocumentsBodyTableItem,
    StyledDocumentsTableButton,
    StyledStatusIcon
} from '../index.style'
import { DocumentType, IProviderDocument } from '../../models'
import CustomSvgIcon from 'components/CustomSvgIcon'
import {
    deleteIcon,
    disabledDeleteIcon,
    disabledFeedbackIcon,
    disabledFeedbackIndicatorIcon,
    errorIcon,
    feedbackIcon,
    feedbackIndicatorIcon,
    pendingIcon,
    processedIcon
} from '../icons'
import moment from 'moment'
import { dispatch, useSelector } from 'redux/store'
import Tooltip from 'components/Tooltip'
import { FeedbackModalDialog } from '../../../PointsInCare/ReviewTable/FeedbackDialog'
import ConfirmationBox from 'components/ConfirmationBox'
import { UI_COLORS } from 'theme'
import { hideMainWindowScrollbars } from 'utilities/style'
import {
    FeedbackState,
    addProviderDocumentFeedback,
    getProviderDocumentFeedback,
    markProviderDocumentFeedbackRead
} from 'redux/slices/feedback'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import usePostCustomEvent from 'hooks/usePostCustomEvent'
import useCurrentUser from 'hooks/useCurrentUser'
import { ERoles } from 'models/enums/role'

interface IDocumentsTableBodyProps {
    tableData?: IProviderDocument[]
    documentType: DocumentType
    isAdmin: boolean
    isBusy: boolean
    downloadDocument: (documentId: string) => void
    deleteDocument: (documentId: string) => void
    reprocessDocument: (documentId: string) => void
}

export enum EDocStatus {
    NEW = 'New',
    PENDING = 'Pending',
    PROCESSED = 'Processed',
    ERRORED = 'Errored',
    DELETED = 'Reviewed'
}

const DocumentsTableBody: FC<IDocumentsTableBodyProps> = ({
    tableData,
    documentType,
    isAdmin,
    isBusy,
    downloadDocument,
    deleteDocument,
    reprocessDocument
}) => {
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isHandleErroredMedicalChartModalOpen, setHandleErroredMedicalChartModalOpen] = useState(false)
    const [feedbackProviderDocumentId, setFeedbackProviderDocumentId] = useState('')
    const [feedbackLoading, setFeedbackLoading] = useState(false)
    const [feedbackSending, setFeedbackSending] = useState(false)
    const [fileId, setFileId] = useState('')
    const [fileName, setFilename] = useState('')

    const deleteMedicalChartEvent = usePostCustomEvent(pageTitle.PatientsDocuments, customEvents.DeleteMedicalChart)
    const reprocessMedicalChartEvent = usePostCustomEvent(
        pageTitle.PatientsDocuments,
        customEvents.ReprocessMedicalChart
    )
    const deleteDocumentEvent = usePostCustomEvent(pageTitle.PatientsDocuments, customEvents.DeleteDocument)
    const handleFileDelete = (fileId: string) => {
        if (documentType === DocumentType.MedicalChart) {
            deleteMedicalChartEvent()
        } else {
            deleteDocumentEvent()
        }
        setIsDeleteModalOpen(false)
        deleteDocument(fileId)
    }

    const handleDelete = (fileNameToDelete: string, fileIdToDelete: string) => {
        setFilename(fileNameToDelete)
        setFileId(fileIdToDelete)
        setIsDeleteModalOpen(true)
    }

    const doReprocessMedicalChart = (fileId: string) => {
        reprocessMedicalChartEvent()
        reprocessDocument(fileId)
        setHandleErroredMedicalChartModalOpen(false)
    }
    const handleErroredMedicalChart = (fileName: string, fileId: string) => {
        setFilename(fileName)
        setFileId(fileId)
        setHandleErroredMedicalChartModalOpen(true)
    }

    const getStatusIcon = (status: string) => {
        switch (status) {
            case EDocStatus.PROCESSED:
                return processedIcon
            case EDocStatus.PENDING:
                return pendingIcon
            case EDocStatus.ERRORED:
                return errorIcon
            default:
                return ''
        }
    }

    const { providerDocumentFeedback } = useSelector((state: { feedback: FeedbackState }) => state.feedback)

    const currentUser = useCurrentUser()
    const canReprocessMedicalChart = [
        ERoles.Provider,
        ERoles.OfficeAdmin,
        ERoles.ProviderRep,
        ERoles.CallCenterAdmin
    ].includes(currentUser?.primaryRole)
    const isReadOnly = currentUser.primaryRole === ERoles.Quality

    useEffect(() => {
        hideMainWindowScrollbars(isDeleteModalOpen || isFeedbackModalOpen || isHandleErroredMedicalChartModalOpen)
    }, [isDeleteModalOpen, isFeedbackModalOpen, isHandleErroredMedicalChartModalOpen])

    const viewFeedbackEvent = usePostCustomEvent(pageTitle.PatientsDocuments, customEvents.ViewFeedback)
    const handleFeedback = (id: string) => {
        viewFeedbackEvent()
        setFeedbackProviderDocumentId(id)
        setFeedbackLoading(true)
        setIsFeedbackModalOpen(true)
        dispatch(
            markProviderDocumentFeedbackRead({
                providerDocumentId: id
            })
        )
        dispatch(
            getProviderDocumentFeedback({
                providerDocumentId: id
            })
        ).then(() => {
            setFeedbackLoading(false)
        })
    }
    const sentFeedbackEvent = usePostCustomEvent(pageTitle.PatientsDocuments, customEvents.SendFeedback)
    const handleSendFeedback = (feedbackToSend: string) => {
        sentFeedbackEvent()
        setFeedbackSending(true)
        dispatch(
            addProviderDocumentFeedback({
                providerDocumentId: feedbackProviderDocumentId,
                parentId: null,
                feedbackText: feedbackToSend
            })
        ).then(() => {
            setFeedbackSending(false)
        })
    }

    return (
        <>
            {isHandleErroredMedicalChartModalOpen && (
                <ConfirmationBox
                    title="Reprocess Medical Chart"
                    body={`A problem occurred when tried to process the '${fileName}' medical chart. 
                         Do you want to retry?`}
                    confirmButtonLabel="Reprocess"
                    confirmButtonColor={UI_COLORS.light_blue2}
                    onConfirm={() => doReprocessMedicalChart(fileId)}
                    onCancel={() => setHandleErroredMedicalChartModalOpen(false)}
                />
            )}

            {isDeleteModalOpen ? (
                <ConfirmationBox
                    title={documentType === DocumentType.MedicalChart ? 'Delete Medical Chart' : 'Delete Document'}
                    body={`Are you sure you want to delete the ${
                        documentType === DocumentType.MedicalChart ? 'medical chart' : 'document'
                    } ${fileName}?`}
                    confirmButtonLabel="Delete"
                    confirmButtonColor={UI_COLORS.red}
                    onConfirm={() => handleFileDelete(fileId)}
                    onCancel={() => setIsDeleteModalOpen(false)}
                />
            ) : null}

            {isFeedbackModalOpen ? (
                <FeedbackModalDialog
                    feedback={providerDocumentFeedback[feedbackProviderDocumentId] ?? []}
                    isLoading={feedbackLoading}
                    isSending={feedbackSending}
                    onSend={handleSendFeedback}
                    onClose={() => setIsFeedbackModalOpen(false)}
                />
            ) : null}

            {tableData?.map((dataItem: IProviderDocument, idx: number) => {
                return (
                    <StyledDocumentsBodyTable key={`documentsRow=${idx}`}>
                        <StyledDocumentsBodyTableItem key={`bodyFileName-${idx}`}>
                            <button disabled={isBusy} onClick={() => downloadDocument(dataItem.id)}>
                                {dataItem.fileName}
                            </button>
                        </StyledDocumentsBodyTableItem>
                        <StyledDocumentsBodyTableItem>
                            {isAdmin ? `${dataItem.providerLastName}, ${dataItem.providerFirstName}` : ''}
                        </StyledDocumentsBodyTableItem>
                        <StyledDocumentsBodyTableItem key={`bodyDOS-${idx}`}>
                            {moment(dataItem?.dateOfSubmission).format('MM/DD/YYYY')}
                        </StyledDocumentsBodyTableItem>
                        <StyledDocumentsBodyTableItem key={`bodyUploadedBy-${idx}`}>
                            {dataItem.uploadedBy}
                        </StyledDocumentsBodyTableItem>
                        <StyledDocumentsBodyTableItem key={`bodyStatus-${idx}`}>
                            {documentType === DocumentType.MedicalChart && (
                                <Tooltip content={dataItem.status} direction="top" delay={0}>
                                    <StyledStatusIcon
                                        disabled={isBusy}
                                        isClickable={canReprocessMedicalChart && dataItem.status === EDocStatus.ERRORED}
                                        onClick={() =>
                                            canReprocessMedicalChart &&
                                            dataItem.status === EDocStatus.ERRORED &&
                                            handleErroredMedicalChart(dataItem.fileName, dataItem.id)
                                        }
                                    >
                                        <CustomSvgIcon
                                            iconSet={{ icon: getStatusIcon(dataItem.status) }}
                                            svg
                                        ></CustomSvgIcon>
                                    </StyledStatusIcon>
                                </Tooltip>
                            )}
                        </StyledDocumentsBodyTableItem>
                        <StyledDocumentsBodyTableItem key={`bodyFeedBack-${idx}`}>
                            <StyledDocumentsTableButton
                                onClick={() => handleFeedback(dataItem.id)}
                                hasFeedback={dataItem.hasNewFeedback}
                                disabled={isBusy}
                            >
                                <div>
                                    <CustomSvgIcon
                                        iconSet={{
                                            icon: !isBusy ? feedbackIndicatorIcon : disabledFeedbackIndicatorIcon
                                        }}
                                        svg
                                    ></CustomSvgIcon>
                                </div>
                                <CustomSvgIcon
                                    iconSet={{ icon: !isBusy ? feedbackIcon : disabledFeedbackIcon }}
                                    svg
                                ></CustomSvgIcon>
                            </StyledDocumentsTableButton>
                        </StyledDocumentsBodyTableItem>
                        <StyledDocumentsBodyTableItem key={`bodyDelete-${idx}`}>
                            <StyledDocumentsTableButton
                                onClick={() => handleDelete(dataItem.fileName, dataItem.id)}
                                disabled={isReadOnly || isBusy}
                            >
                                <CustomSvgIcon
                                    iconSet={{ icon: !isReadOnly && !isBusy ? deleteIcon : disabledDeleteIcon }}
                                    svg
                                ></CustomSvgIcon>
                            </StyledDocumentsTableButton>
                        </StyledDocumentsBodyTableItem>
                    </StyledDocumentsBodyTable>
                )
            })}
        </>
    )
}

export default DocumentsTableBody
