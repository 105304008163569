import { useState, useEffect } from 'react'
import axios from '../utilities/axios'

const useAxiosPost = (url: any, payload: any | null = null) => {
    const [data, setData] = useState<any>(null)
    const [error, setError] = useState('')
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        axios
            .post(url, payload)
            .then((response) => setData(response.data))
            .catch((error) => setError(error.message))
            .finally(() => setLoaded(true))
    }, [])

    return { data, error, loaded }
}

export default useAxiosPost
