import { useNavigateToIncentive } from 'hooks/useNavigateToIncentive'
import { ITopIncentive } from 'pages/hcp/users/create/index.models'
import { FC } from 'react'
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts'
import { currencyFormatter } from '../../YTDWidget/index.utils'
import { IIncentivesData } from '../index.model'
import { StyledCustomTooltip, StyledTooltipItem, StyledTooltipTotal, StyledTopOppsChartsWrapper } from '../index.style'
import { CustomizedAxisTick, CustomizedYAxisCurrencyTick } from '../index.utilities'

interface IIncentivesChartProps {
    incentivesData: ITopIncentive[]
    maxIncentive: number
    npi?: string
}

const IncentivesChart: FC<IIncentivesChartProps> = ({ incentivesData, maxIncentive, npi }) => {
    const navigateToIncentive = useNavigateToIncentive()

    const data: IIncentivesData[] = []

    incentivesData.map((incentiveItem: IIncentivesData, idx: number) => {
        if (incentiveItem.incentive === 'Annual Wellness Visit') {
            return
        }
        let incentivesObject = {
            incentive: incentiveItem.incentive,
            totalIncentiveRemaining: incentiveItem.totalIncentiveRemaining,
            totalIncentiveEarned: incentiveItem.totalIncentiveEarned,
            totalIncentiveClosed: incentiveItem.totalIncentiveClosed,
            totalIncentiveBudgeted: incentiveItem.totalIncentiveBudgeted
        }

        data.push(incentivesObject)
    })

    const sortIncentiveData = (): IIncentivesData[] => {
        return data.sort(
            (a: IIncentivesData, b: IIncentivesData) => b.totalIncentiveRemaining - a.totalIncentiveRemaining
        )
    }

    const yAxisFormatter = (currency: string) => {
        if (currency === '0') {
            return '0'
        } else {
            // return yAxisCurrencyFormatter.format(Number(currency))
            return currencyFormatter.format(Number(currency))
        }
    }

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <StyledCustomTooltip>
                    <StyledTooltipItem>
                        <span style={{ backgroundColor: '#E3EEF3' }}></span>
                        <h5>Remaining:</h5>
                    </StyledTooltipItem>
                    <p>{`$${payload[0].payload.totalIncentiveRemaining}`}</p>
                    <StyledTooltipItem>
                        <span style={{ backgroundColor: '#9ADDFF' }}></span>
                        <h5>Closed:</h5>
                    </StyledTooltipItem>
                    <p>{`$${payload[0].payload.totalIncentiveClosed}`}</p>
                    <StyledTooltipItem>
                        <span style={{ backgroundColor: '#40BFFF' }}></span>
                        <h5>Paid:</h5>
                    </StyledTooltipItem>
                    <p>{`$${payload[0].payload.totalIncentiveEarned}`}</p>

                    <StyledTooltipTotal>
                        <p>Total:</p>
                        <p>{`$${payload[0].payload.totalIncentiveBudgeted}`}</p>
                    </StyledTooltipTotal>
                </StyledCustomTooltip>
            )
        }

        return null
    }

    return (
        <div style={{ overflow: 'hidden', overflowY: 'hidden', position: 'relative', top: '-20px' }}>
            <StyledTopOppsChartsWrapper>
                <BarChart
                    key={Math.random()}
                    width={180 * (data.length + 0.5)}
                    height={250}
                    data={sortIncentiveData()}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 30
                    }}
                >
                    <CartesianGrid strokeDasharray="1" vertical={false} />
                    <XAxis
                        padding={{ left: -80 }}
                        stroke={'#CCCCCC'}
                        strokeDasharray={1}
                        strokeWidth={1}
                        width={200}
                        dataKey="incentive"
                        tick={<CustomizedAxisTick x="0" y="0" />}
                        interval={0}
                        tickLine={false}
                        onClick={(e: any) => navigateToIncentive(e.value)}
                    />
                    <YAxis
                        stroke={'white'}
                        strokeWidth={1}
                        tickFormatter={yAxisFormatter}
                        tickLine={false}
                        tick={<CustomizedYAxisCurrencyTick x="0" y="0" />}
                        interval={0}
                        domain={[0, maxIncentive]}
                    />
                    <Tooltip content={<CustomTooltip />} cursor={false} />
                    <Bar
                        barSize={25}
                        dataKey="totalIncentiveEarned"
                        stackId="a"
                        fill="#26B7FF"
                        onClick={(e: any) => navigateToIncentive(e.incentive)}
                    ></Bar>
                    <Bar
                        barSize={50}
                        dataKey="totalIncentiveClosed"
                        stackId="a"
                        fill="#75D1FF"
                        onClick={(e: any) => navigateToIncentive(e.incentive)}
                    ></Bar>{' '}
                    {/* change datakey once we get data from DB */}
                    <Bar
                        barSize={25}
                        dataKey="totalIncentiveRemaining"
                        stackId="a"
                        fill="#E3EEF3"
                        onClick={(e: any) => navigateToIncentive(e.incentive)}
                    >
                        {/* <LabelList
                            dataKey="totalIncentiveEarned"
                            content={renderCustomizedCurrencyLabel}
                            position="insideRight"
                            style={{ fill: 'white' }}
                        /> */}
                    </Bar>
                </BarChart>
            </StyledTopOppsChartsWrapper>
        </div>
    )
}

export default IncentivesChart
