import styled from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { dark_gray, white } = UI_COLORS

export const StyledRadioFormWrapper = styled.article`
    margin-bottom: ${pxToRem(30)};
    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: ${dark_gray};
    }
`

export const StyledRadioFormInnerWrapper = styled.article<{ isVertical: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    ${(props) => {
        if (props.isVertical) {
            return `width: 460px;`
        }
    }}
`

export const StyledRadioFormContainer = styled.article<{ isVertical: boolean; isInError?: any }>`
    background: ${white};
    border-radius: 4px;
    display: flex;
    border-left: solid 3px white;

    ${(props) => {
        if (props.isInError) {
            return `border-color: red;`
        }
    }}

    ${(props) => {
        if (props.isVertical) {
            return `
                width: 400px;
                flex-direction: column;
                align-items: flex-start;
                padding: 26px 20px 10px 20px;
            `
        } else {
            return `
                width: 1145px;
                flex-direction: row;
                align-items: center;
                padding: 26px 20px;
            `
        }
    }}

    label {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(18)};
        color: ${dark_gray};
        margin-right: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;

        cursor: pointer;

        ${(props) => {
            if (props.isVertical) {
                return `
                    margin-bottom: 20px;
                `
            }
        }}
    }

    input {
        margin: 0;
        padding: 0;
        margin-right: 8px;
        width: 20px;
        height: 20px;
    }
`

export const StyledResetContainer = styled.article<{ isVertical: boolean }>`
    width: ${pxToRem(50)};
    display: flex;
    ${(props) => {
        if (props.isVertical) {
            return `justify-content: center;`
        } else {
            return `justify-content: center;`
        }
    }}
    align-items: center;

    button {
        :disabled {
            visibility: hidden;
        }
    }
`
