export const arrow = `<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.67249 10.1328L12.5313 15.1328L17.3902 10.1328H7.67249Z" fill="#293854"/>
</svg>
`

export const feedback = `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.3841 14.9944L15.0843 20.2963L12.4785 20.8175L12.9997 18.2117L18.3023 12.9098C18.5777 12.6338 18.9516 12.4788 19.3415 12.4788C19.7314 12.4788 20.1052 12.6338 20.3807 12.9098L20.3869 12.9153C20.9602 13.4903 20.9589 14.421 20.3841 14.9944Z" stroke="#394A64" stroke-width="1.5"/>
<path d="M10.3913 18.7327H6.22201C5.64635 18.7327 5.17969 18.2661 5.17969 17.6904V6.22493C5.17969 5.64928 5.64635 5.18262 6.22201 5.18262H16.6452C17.2208 5.18262 17.6875 5.64928 17.6875 6.22493V10.9154" stroke="#394A64" stroke-width="1.5"/>
<path d="M10.3916 8.30957H14.5609" stroke="#394A64" stroke-width="1.5"/>
<path d="M8.30664 11.4365H14.5605" stroke="#394A64" stroke-width="1.5"/>
<path d="M8.30664 14.5635H13.5182" stroke="#394A64" stroke-width="1.5"/>
</svg>
`

export const feedbackIndicatorIcon = `<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5.5 7.5H4.5V4.5H5.5V7.5ZM5.5 3.5H4.5V2.5H5.5V3.5Z" fill="#089171"/>
</svg>
`
