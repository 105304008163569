import { StyledButtonContainer, StyledCancelButton, StyledConfirmButton } from './index.style'
import Modal from 'components/Modal'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC } from 'react'

interface IConfirmationBoxProps {
    title: string
    body: string
    confirmButtonLabel: string
    confirmButtonColor: string
    onConfirm: () => void
    onCancel: () => void
}

const ConfirmationBox: FC<IConfirmationBoxProps> = ({
    title,
    body,
    confirmButtonLabel,
    confirmButtonColor,
    onConfirm,
    onCancel
}) => (
    <Modal onClose={onCancel} minWidth={600} minHeight={300}>
        <Typography type={TYPOGRAPHY_TYPES.h2}>{title}</Typography>
        <Typography type={TYPOGRAPHY_TYPES.p}>{body}</Typography>
        <StyledButtonContainer>
            <StyledCancelButton onClick={onCancel}>Cancel</StyledCancelButton>
            <StyledConfirmButton onClick={onConfirm} color={confirmButtonColor}>
                {confirmButtonLabel}
            </StyledConfirmButton>
        </StyledButtonContainer>
    </Modal>
)

export default ConfirmationBox
