import CustomMaskedInput from 'components/CustomMaskInput'
import { FC } from 'react'
import { StyledAWVDigitalInput, StyleFieldResetContainer, StyledResetContainer } from '../index.style'
import { Controller, useFormContext } from 'react-hook-form'
import { IPhoneNumberField, ISectionElement } from '../index.models'
import { Label } from '../Label'
import { AwvTooltip } from '../AwvTooltip'
import useErrors from 'hooks/useErrors'
interface IPhoneNumberFieldProps {
    name: string
    label: string
    subLabel: string
    placeholder: string
}

const PhoneNumberField: FC<IPhoneNumberFieldProps> = ({ name, label, subLabel, placeholder }) => {
    const { control } = useFormContext()
    const errors = useErrors()

    return (
        <AwvTooltip message={errors.getError(name)}>
            <StyledAWVDigitalInput isInError={errors.getError(name)}>
                <Label htmlFor={name} label={label} subLabel={subLabel} />
                <StyleFieldResetContainer>
                    <Controller
                        render={({ field }) => (
                            <CustomMaskedInput {...field} placeholder={placeholder ?? label} width="400px" />
                        )}
                        name={name}
                        control={control}
                    />
                    <StyledResetContainer></StyledResetContainer>
                </StyleFieldResetContainer>
            </StyledAWVDigitalInput>
        </AwvTooltip>
    )
}

export const renderPhoneNumberField = (element: ISectionElement, key: string) => {
    const phoneNumberField = element as IPhoneNumberField
    return <PhoneNumberField key={key} {...phoneNumberField} />
}
