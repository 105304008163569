import styled from 'styled-components'
import { UI_COLORS } from 'theme/constants'

const { light_blue2 } = UI_COLORS

export const StyledSmallSpinnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 25px !important;
    width: 25px;
    margin-left: 30px;
    margin-top: 15px;
`

export const StyledSmallSpinner = styled.svg`
    animation: rotate 2s linear infinite;
    margin: -25px 0 0 -25px;
    width: 25px;
    height: 25px;

    & .path {
        stroke: ${light_blue2};
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
`
