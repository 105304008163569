import react from 'react'
import { IDeleteAddressProps, StyledDeleteButton, StyledPCPToolTip } from './index.style'

export const DeleteButton: React.FC<IDeleteAddressProps> = ({ index, handleClick }) => {
    return (
        <StyledDeleteButton
            size="30"
            onClick={() => {
                handleClick(index)
            }}
        >
            <div>
                <img src={require('../../../../../assets/delete-2.svg').default} alt="remove location" />
            </div>

            <div>
                <StyledPCPToolTip>Delete</StyledPCPToolTip>
            </div>
        </StyledDeleteButton>
    )
}
