import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { white } = UI_COLORS

export const StyledFormTextAreaContainer = styled.article<{ isInError?: any }>`
    width: ${pxToRem(1145)};
    margin-bottom: ${pxToRem(25)};

    label {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #293854;
        margin-bottom: 15px;
    }

    textarea {
        width: 100%;
        background: ${white};
        border: 1px solid #73839c;
        border-radius: 4px;
        margin-top: 15px;
        height: 150px;
        padding: 24px 18px;

        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #293854;
        margin-bottom: 5px;

        ${(props) => {
            if (props.isInError) {
                return `border-left: 3px solid red;`
            }
        }}
        resize: none;

        ::placeholder {
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;

            color: #a4b0c0;
        }
    }
`

export const StyledExplanatoryNote = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
`
