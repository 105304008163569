import styled, { css } from 'styled-components'
import { pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { dark_gray, light_gray, light_blue2 } = UI_COLORS

export const StyledSearchInput = styled.input`
    height: ${pxToRem(50)};
    width: 100%;
    padding-left: ${pxToRem(36)};

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: ${pxToRem(16)};
    color: ${dark_gray};
    border-radius: ${pxToRem(4)};
    border: ${pxToRem(1)} solid #dee5ef;

    :focus {
        outline: none;
        border: ${pxToRem(1)} solid ${light_blue2};
    }

    ::placeholder {
        color: ${light_gray};
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: ${pxToRem(14)};
    }
`
export const StyledSearchContainer = styled.article`
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;

    figure {
        margin: 0;
        position: absolute;
        left: ${pxToRem(10)};
        top: ${pxToRem(16)};
    }
`

export const StyledIconButton = styled.button<{ size: number }>`
    background-color: transparent;
    border: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: ${pxToRem(11)};
    margin-right: ${pxToRem(10)};
    z-index: 10;
    cursor: pointer;

    :hover {    
        box-shadow: 0px ${pxToRem(2)} ${pxToRem(5)} rgba(0, 0, 0, 0.07);
        border-radius: ${pxToRem(20)};
    }

    ${(props) => {
        if (props.size) {
            return css`
                width: ${pxToRem(props.size)};
                height: ${pxToRem(props.size)};
            `
        }
    }}
`

export const StyledSpinnerContainer = styled.article`
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: ${pxToRem(31)};
    height: ${pxToRem(31)};
    right: ${pxToRem(10)};
    top: ${pxToRem(11)};
    position: absolute;
    z-index: 10;
`

export const StyledSpinner = styled.svg`
    animation: rotate 2s linear infinite;
    width: ${pxToRem(19)};
    height: ${pxToRem(19)};

    & .path {
        stroke: ${light_blue2};
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
`
