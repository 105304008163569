import AADLogin from 'pages/authentication/AADLogin'
import Registration from 'pages/registration'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { PATH_PAGE } from 'routes/paths'

function UnauthenticatedApp() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={PATH_PAGE.registration} element={<Registration />} />
                <Route path="*" element={<AADLogin />} />
            </Routes>
        </BrowserRouter>
    )
}

export default UnauthenticatedApp
