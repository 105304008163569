import styled from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { dark_gray, light_blue2, grey, white, light_blue3, light_gray3 } = UI_COLORS

export const StyledSupportingDocumentsContainer = styled.article`
    padding-top: ${pxToRem(16)};
`

export const StyledTitleWrapper = styled.article`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h3 {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(26)};
        letter-spacing: -0.01em;
        color: ${dark_gray};
        margin-block-start: 1em;
        margin-block-end: 1em;

        position: relative;

        :after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            display: inline-block;
            width: ${pxToRem(40)};
            height: ${pxToRem(3)};
            background-color: ${dark_gray};
        }
    }
`

export const StyledUploadButton = styled.button`
    margin: 0 ${pxToRem(16)} 0 0 !important;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        color: ${light_blue2};
        text-align: center;
        font-family: 'Inter', 'sans-serif';
        font-size: ${pxToRem(14)} !important;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
    }

    figure {
        margin: 0;
        padding: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        margin-right: ${pxToRem(6)};
    }

    :disabled {
        cursor: default;

        p {
            color: ${grey};
        }
    }
`

export const StyledInfoPanel = styled.article`
    p {
        width: ${pxToRem(750)};
        font-family: 'Inter', 'sans-serif';
        font-style: normal;
        font-weight: 500;
        font-size: ${pxToRem(15)};
        line-height: ${pxToRem(22)};
        color: ${dark_gray};
        margin: 0;
    }
`

export const StyledSpinnerContainer = styled.article`
    display: flex;

    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: ${pxToRem(50)};

    article {
        width: ${pxToRem(25)};
        height: ${pxToRem(25)};
        margin-left: ${pxToRem(25)};
        margin-top: ${pxToRem(20)};
    }
`

export const StyledDocumentsTableHeader = styled.ul`
    list-style: none;
    padding: 0;
    display: flex;
    width: ${pxToRem(1045)};
    height: ${pxToRem(40)};
    padding-left: ${pxToRem(40)};
    align-items: center;

    background-color: ${light_blue3};
    margin-bottom: 0;
    padding-bottom: 0;
    border-top-right-radius: ${pxToRem(4)};
    border-top-left-radius: ${pxToRem(4)};
`

export const StyledDocumentsTableHeaderItem = styled.li`
    font-family: 'Inter', 'san-serif';
    font-size: ${pxToRem(12)};
    font-weight: 600;
    line-height: ${pxToRem(16)};
    letter-spacing: 0em;
    color: ${light_gray3};

    :nth-child(1) {
        width: ${pxToRem(450)};
    }

    :nth-child(2) {
        width: ${pxToRem(170)};
    }

    :nth-child(3) {
        width: ${pxToRem(285)};
    }

    :nth-child(4) {
        width: ${pxToRem(100)};
    }
`

export const StyledDocumentsBodyTable = styled.ul`
    list-style: none;
    padding: 0;
    display: flex;
    width: ${pxToRem(1045)};
    height: ${pxToRem(76)};
    padding-left: ${pxToRem(40)};
    align-items: center;

    background-color: ${white};
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: ${pxToRem(1)} solid #dee5ef;
`

export const StyledDocumentsBodyTableItem = styled.li`
    font-family: 'Inter', 'san-serif';
    font-size: ${pxToRem(12)};
    font-weight: 600;
    font-style: normal;
    line-height: ${pxToRem(16)};
    letter-spacing: 0em;
    font-weight: 600;
    color: ${dark_gray};

    button {
        padding: 0;
        background-color: transparent;
        border: none;

        color: ${light_blue2};
        font-family: 'Inter', 'san-serif';
        font-size: ${pxToRem(12)};
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        cursor: pointer;

        :disabled {
            cursor: default;
            color: ${grey};
        }
    }

    :nth-child(1) {
        width: ${pxToRem(450)};
    }

    :nth-child(2) {
        width: ${pxToRem(170)};
    }

    :nth-child(3) {
        width: ${pxToRem(285)};
    }

    :nth-child(4) {
        width: ${pxToRem(100)};
    }

    figure {
        margin: 0;
    }
`

export const StyledDocumentsTableButton = styled.button`
    width: ${pxToRem(50)};
    height: ${pxToRem(50)};
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: relative;

    figure {
        margin: 0;
        padding: 0;
    }

    div {
        display: none;
    }

    :disabled {
        cursor: default;

        span {
            cursor: default;
        }
    }
`
