import useCurrentUser from './useCurrentUser'
import { isProvider } from 'models/enums/role'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { dlm, role } from 'appInsights/appInsights'
import useAuthenticatedUser from './useAuthenticatedUser'

const useTrackPageView = () => {
    const currentUser = useCurrentUser()
    const authenticatedUser = useAuthenticatedUser()
    const appInsights = useAppInsightsContext()

    return (pageTitle: string | null | undefined) => {
        if (process.env.REACT_APP_LOG_PAGE_VIEW_TO_CONSOLE === 'true') {
            console.log('-')
            console.log(' * PAGE VIEW *')
            console.log(`Name: ${(isProvider(currentUser) ? role.Provider : role.HCP) + dlm + pageTitle}`)
            console.log(`Email: ${authenticatedUser.emailAddress}`)
            console.log(' ***')
            console.log('-')
        }

        appInsights.trackPageView({
            name: (isProvider(currentUser) ? role.Provider : role.HCP) + dlm + pageTitle,
            properties: {
                email: authenticatedUser.emailAddress
            }
        })
    }
}

export default useTrackPageView
