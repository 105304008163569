import styled, { css } from 'styled-components'
import { DEVICE, pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { light_gray3 } = UI_COLORS

export const StyledMemberInfoContainer = styled.article`
    padding: ${pxToRem(25)} ${pxToRem(30)};

    h2 {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(22)};
        line-height: ${pxToRem(31)};
        letter-spacing: -0.01em;
        color: #293854;
    }

    h3 {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(26)};
        letter-spacing: -0.01em;
        color: #293854;
        position: relative;

        :after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            display: inline-block;
            width: 40px;
            height: 3px;
            background-color: #293854;
        }
    }

    fieldset {
        margin: 0;
        border: 0;
        padding: 0;

        :disabled {
            input {
                background-color: #f5f5f5;
            }
        }
    }

    ${DEVICE.desktop} {
        padding: ${pxToRem(150)} ${pxToRem(30)} ${pxToRem(120)};
    }
`

export const StyledMemberEditButton = styled.button`
    width: ${pxToRem(125)};
    height: ${pxToRem(45)};

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
    color: #394a64;

    background: #ffffff;
    border: 1px solid #dee5ef;
    border-radius: ${pxToRem(4)};

    margin-bottom: ${pxToRem(24)};

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    figure {
        margin: 0;
        margin-right: ${pxToRem(10)};
    }

    cursor: pointer;
`

export const StyledMemberInputWrap = styled.article`
    display: flex;
    flex-direction: column;

    input {
        width: 100%;

        :nth-child(2) {
            margin-bottom: ${pxToRem(24)};
        }
    }

    ${DEVICE.tablet} {
        input {
            width: ${pxToRem(400)};
        }
    }

    ${DEVICE.desktop} {
        flex-direction: row;
        width: ${pxToRem(880)};
        margin-bottom: ${pxToRem(24)};

        input {
            :nth-child(2) {
                margin-bottom: ${pxToRem(0)};
            }
        }
    }
`

export const StyledMemberInputWrapForEmergencyContact = styled.article`
    display: flex;
    flex-direction: column;

    input {
        width: 50%;

        :nth-child(2) {
            margin-bottom: ${pxToRem(24)};
        }
    }

    ${DEVICE.tablet} {
        input {
            width: ${pxToRem(400)};
        }
    }

    ${DEVICE.desktop} {
        flex-direction: row;
        width: ${pxToRem(840)};
        margin-bottom: ${pxToRem(24)};

        input {
            :nth-child(2) {
                margin-bottom: ${pxToRem(0)};
            }
        }
    }
`

export const StyledMemberSelect = styled.article`
    display: flex;
    flex-direction: column;

    label {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(18)};
        color: #293854;

        margin-bottom: ${pxToRem(15)};
    }

    input {
        height: ${pxToRem(50)};
        background: #f5f5f5;
        border: 1px solid #73839c;
        border-radius: ${pxToRem(4)};

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: ${pxToRem(16)};
        line-height: ${pxToRem(22)};
        color: #293854;

        padding-left: ${pxToRem(18)};
    }

    small {
        margin: 0;
        color: #b11b1b;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    div {
        div {
            div {
                margin: 0;
            }
        }
    }
`

export const StyledMemberAddressSelect = styled.article`
    display: flex;
    flex-direction: column;
    width: 255px;

    label {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(18)};
        color: #293854;

        margin-bottom: ${pxToRem(15)};
    }

    input {
        height: ${pxToRem(50)};
        background: #ffffff;
        border: 1px solid #dee5ef;
        border-radius: ${pxToRem(4)};

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: ${pxToRem(16)};
        line-height: ${pxToRem(22)};
        color: #293854;

        padding-left: ${pxToRem(18)};
    }

    small {
        margin: 0;
        color: #b11b1b;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    div {
        div {
            div {
                margin: 0;
            }
        }
    }
`

export const StyledMemberSelectWrap = styled.article`
    display: flex;
    flex-direction: column;

    article {
        margin-bottom: 24px;
    }

    input {
        width: 100%;
    }

    ${DEVICE.desktop} {
        flex-direction: row;
        margin-bottom: 24px;
        article {
            :nth-child(2) {
                margin-left: ${pxToRem(42)};
            }
        }
        article {
            margin-bottom: 0;
        }
    }
`

export const customDropdownStyles = {
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        border: '1px solid #DEE5EF',
        width: '400px',
        marginRight: '40px',
        backgroundColor: state.isDisabled ? '#dee5ef' : '#ffffff'
    }),
    option: (baseStyles: any) => ({
        ...baseStyles,
        height: '50px',
        fontFamily: 'Inter',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#293854',
        display: 'flex',
        alignItems: 'center'
    }),
    singleValue: (baseStyles: any) => ({
        ...baseStyles,
        fontFamily: 'Inter',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#293854',
        padding: '0px',
        paddingLeft: '18px'
    }),
    menuList: (baseStyles: any) => ({
        ...baseStyles,
        padding: '0px'
    }),
    valueContainer: (baseStyles: any) => ({
        ...baseStyles,
        padding: '0px'
    }),
    input: (baseStyles: any) => ({
        ...baseStyles,
        padding: '0px',
        paddingLeft: '6px',
        fontFamily: 'Inter'
    }),
    placeholder: (baseStyles: any) => ({
        ...baseStyles,
        fontFamily: 'Inter',
        paddingLeft: '6px',
        color: light_gray3
    })
}

export const StyledMemberButtonsContainer = styled.article`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    padding: ${pxToRem(30)};
    width: 100%;
    position: fixed;
    z-index: 9;
    bottom: 0;
    left: 0;
    background-color: white;
    border-top: 1px solid #dee5ef;
`

export const StyledMemberCancelButton = styled.button`
    width: 131px;
    height: 50px;
    background: #a4b0c0;
    border-radius: 4px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;

    border: 0;
    margin-right: 30px;
    cursor: pointer;
`

export const StyledMemberSaveButton = styled.button`
    width: 131px;
    height: 50px;
    background: #2281c4;
    border-radius: 4px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    border: 0;

    cursor: pointer;
`

export const StyledUserDetailsContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: ${pxToRem(840)};

    h2 {
        width: ${pxToRem(630)};
    }
`

export const StyledEligibilityBadgeContainer = styled.article`
    width: 120px;
    height: 30px;
    border-radius: 30px;
    margin-left: 20px;
    padding: 0 20px;
    background-color: #dee5ef;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;

    color: #394a64;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

export const StyledBadgeContainer = styled.article<{ isElegible?: boolean }>`
    figure {
        margin: 0;
        margin-left: 6px;
    }

    ${(props) => {
        if (props.isElegible) {
            return css`
                /* font-weight: 600; */
            `
        }
    }}
`

export const StyledPersonalEditContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 50px;
    margin-bottom: 30px;
    position: relative;

    h3 {
        margin: 0;
        margin-right: 16px;
        :after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            display: inline-block;
            width: 40px;
            height: 3px;
            background-color: #293854;
        }
    }

    button {
        margin: 0;
    }
`

export const StyledMemberInputWrapForHealthCareProxy = styled.article`
    display: flex;
    flex-direction: column;

    input {
        width: 50%;

        :nth-child(2) {
            margin-bottom: ${pxToRem(24)};
        }
    }

    ${DEVICE.tablet} {
        input {
            width: ${pxToRem(400)};
        }
    }

    ${DEVICE.desktop} {
        flex-direction: row;
        width: ${pxToRem(840)};
        margin-bottom: ${pxToRem(24)};

        input {
            :nth-child(2) {
                margin-bottom: ${pxToRem(0)};
            }
        }
    }
`
