import styled, { css } from 'styled-components'
import { pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { dark_gray, white, grey } = UI_COLORS

export const StyledMemberOutreachContainer = styled.article`
    width: 100%;
    height: 348px;
    background-color: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    padding: 24px;
`

export const StyledMemberOutreachTitleContainer = styled.article`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 22px;

    p {
        margin: 0;
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 120%;
        /* or 26px */

        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #293854;
    }
`

export const StyledMemberOutreachViewAllButton = styled.button`
    background-color: transparent;
    border: none;

    display: flex;
    flex-direction: row;
    align-items: center;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */

    color: #2281c4;

    cursor: pointer;

    figure {
        margin: 0;
        margin-right: 5px;
    }
`

export const StyledMemberOutreachTableHeader = styled.ul`
    background-color: #f5f5f5;
    list-style: none;
    display: flex;
    flex-direction: row;

    margin: 0;
    /* padding: 4px; */
`

export const StyledMemberOutreachTableHeaderItem = styled.li`
    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        /* or 18px */

        color: #73839c;
    }

    /* :nth-child(1) {
        margin-right: 115px;
    }

    :nth-child(2) {
        margin-right: 125px;
    } */

    :nth-child(1) {
        width: 212px;
    }

    :nth-child(2) {
        width: 208px;
        display: flex;
        justify-content: center;
    }

    :nth-child(3) {
        width: 170px;
        display: flex;
        justify-content: center;
    }
`

export const StyledMemberOutreachContentTable = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #dee5ef;

    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;

    :nth-child(7) {
        border: 0;
    }

    cursor: pointer;

    :hover {
        background-color: #f5f5f5;
    }
`

export const StyledMemberOutreachContentTableItem = styled.li`
    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        /* or 18px */

        color: #293854;

        margin: 0;
        /* margin-bottom: 8px; */
    }

    :nth-child(1) {
        width: 212px;
    }

    :nth-child(2) {
        width: 208px;
        display: flex;
        justify-content: center;
    }

    :nth-child(3) {
        width: 170px;
        display: flex;
        justify-content: center;
    }
`

export const StyledMemberOutreachEmptyStateContainer = styled.article`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    figure {
        margin: 0;

        svg {
            max-width: 110px;
            max-height: 110px;
        }
    }

    h3 {
        margin: 0;
        margin-bottom: 8px;
    }

    p {
        margin: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        color: #73839c;
    }
`
