import styled, { css } from 'styled-components'

export const StyledValidationTogglerContainer = styled.article`
    position: fixed;
    z-index: 10;
    bottom: 80px;
    right: 24px;
`

export const StyledValidationTogglerButton = styled.button<{
    isActive?: boolean
    isOn?: boolean
    errorCount?: number
}>`
    position: relative;
    width: 48px;
    height: 48px;

    border: none;
    border-radius: 25px;
    box-shadow: 2px 2px 3px #999;
    transition: all 0.4s ease 0s;
    color: transparent;

    figure {
        margin: 0;
        margin-top: 8px;
        margin-left: 4px;
    }

    ${(props) => {
        if (!props.isOn) {
            return `background-color: #D3D3D3; color: grey;`
        } else if (props.errorCount > 0) {
            return `background-color: orange; color: #ffffff;`
        } else {
            return `background-color: green; color: #ffffff;`
        }
    }}

    ${(props) => {
        if (props.isActive) {
            return css`
                width: 250px;
                display: flex;
                flex-direction: row;
                align-items: center;

                font-family: 'Inter', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;

                figure {
                    margin-left: 14px;
                    margin-right: 14px;
                }
            `
        }
    }}

    cursor: pointer;
`

export const StyledErrorCount = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: -5px;
    right: -5px;
    width: 26px;
    height: 20px;
    border: 2px solid #f6f9fd;
    border-radius: 25px;
    background-color: red;

    color: #ffffff;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 15.6px */
    letter-spacing: -0.36px;

    cursor: pointer;
`
