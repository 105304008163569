import TextField from 'components/TextField'
import { UsaStates } from 'pages/hcp/users/create/NewOfficeAdmin/AddressModal/index.models'
import React, { FC, useEffect, useState } from 'react'
import {
    StyledDropdownWrap,
    StyledSelectButtonMenu,
    StyledSelectButtonMenuWrap,
    StyledSelectLabel
} from './index.style'

interface IDropdownSelectProps {
    onSelectedState: any
    stateValue: string | undefined
    error?: boolean
}

const DropdownSelect: FC<IDropdownSelectProps> = ({ onSelectedState, stateValue, error }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [selectedState, setSelectedState] = useState<string>()
    const [inputSelectedState, setInputSelectedState] = useState('')

    useEffect(() => {
        if (stateValue !== undefined && stateValue !== 'State') {
            setInputSelectedState(stateValue)
        }
    }, [stateValue])

    useEffect(() => {
        const keys = Object.keys(UsaStates) as Array<keyof typeof UsaStates>
        const stateKey = keys.find((val) => val === stateValue)

        setSelectedState(String(stateKey))
    }, [stateValue])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onSelectedState('')
        const value = e.target.value

        setInputSelectedState(value)
        const keys = Object.keys(UsaStates) as Array<keyof typeof UsaStates>
        const states = Object.values(UsaStates).map((val) => val.toLowerCase())

        if (keys.some((val) => value.toUpperCase() === val.toUpperCase())) {
            setSelectedState(value)
            onSelectedState(value)
        } else if (states.some((val) => value.toLowerCase() === val.toLowerCase())) {
            // get the state name from the state abbreviation
            const stateKey = keys.find((val) => UsaStates[val].toLowerCase() === value.toLowerCase())
            setSelectedState(String(stateKey))
            onSelectedState(String(stateKey))
        } else if (value.length > 0) {
            setIsDropdownOpen(true)
        }
    }

    return (
        <article>
            <StyledDropdownWrap>
                <StyledSelectLabel>State</StyledSelectLabel>

                <TextField
                    name="State"
                    placeholder={'State'}
                    legend={''}
                    inputType={'text'}
                    value={inputSelectedState}
                    onTextChanged={handleInputChange}
                    error={error}
                    errorMessage={'Required'}
                />

                {isDropdownOpen && (
                    <StyledSelectButtonMenu>
                        {Object.entries(UsaStates)
                            .filter(([key, value]) => {
                                const lowerCaseValue = inputSelectedState.toLowerCase()
                                return (
                                    value.toLowerCase().includes(lowerCaseValue) ||
                                    key.toLowerCase().includes(lowerCaseValue)
                                )
                            })
                            .map(([key, value], idx) => {
                                return (
                                    <button
                                        key={`item-${idx}`}
                                        onClick={(e) => {
                                            setSelectedState(String(key))
                                            setIsDropdownOpen(false)
                                            onSelectedState(key)
                                            setInputSelectedState(value)
                                        }}
                                    >
                                        {value}
                                    </button>
                                )
                            })}
                    </StyledSelectButtonMenu>
                )}

                {isDropdownOpen && (
                    <StyledSelectButtonMenuWrap onClick={() => setIsDropdownOpen(false)}></StyledSelectButtonMenuWrap>
                )}
            </StyledDropdownWrap>
        </article>
    )
}

export default DropdownSelect
