import { ReactNode, FC, useEffect, useRef } from 'react'
import useTrackPageView from 'hooks/useTrackPageView'
import useAuthenticatedUser from 'hooks/useAuthenticatedUser'

interface IPageProps {
    children: ReactNode
    pageTitle: string
}

const Page: FC<IPageProps> = ({ children, pageTitle }) => {
    const trackPageView = useTrackPageView()
    const emailAddress = useAuthenticatedUser()?.emailAddress
    const refTrackPageView = useRef(trackPageView)

    useEffect(() => {
        refTrackPageView.current = trackPageView
    }, [trackPageView, refTrackPageView])

    useEffect(() => {
        if (pageTitle && emailAddress?.length > 0) {
            refTrackPageView.current(pageTitle)
        }
    }, [pageTitle, refTrackPageView, emailAddress])

    return <>{children}</>
}

export default Page
