import CustomSvgIcon from 'components/CustomSvgIcon'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC } from 'react'
import {
    StyledYTDMetricItemContainer,
    StyledYTDMetricIconWrap,
    StyledYTDMetricTitleAndItemWrap,
    StyledYTDMetricTitleWrap,
    StyledYTDMetricItemWrap,
    StyledYTDActiveMemberContainer
} from '../index.style'
import { greenArrowUp } from './icons'

interface ISingleMetricItemGroupProps {
    svgIcon: string
    metricTitle: string
    metricAmount: string
    metricAdditionalAmount?: string
    handleClick?: () => void
}

const SingleMetricItemGroup: FC<ISingleMetricItemGroupProps> = ({
    svgIcon,
    metricTitle,
    metricAmount,
    metricAdditionalAmount,
    handleClick
}) => {
    const changeInMembers = () => {
        if (Number(metricAdditionalAmount) > 0) {
            return (
                <StyledYTDActiveMemberContainer>
                    <Typography style={{ marginLeft: '10px' }} type={TYPOGRAPHY_TYPES.p}>
                        {`+${metricAdditionalAmount}`}
                    </Typography>
                    <CustomSvgIcon iconSet={{ icon: greenArrowUp }} svg></CustomSvgIcon>
                </StyledYTDActiveMemberContainer>
            )
        } else if (Number(metricAdditionalAmount) < 0) {
            /**
             * Add color for negative numbers and down arrow icon
             */
            return (
                <StyledYTDActiveMemberContainer>
                    <Typography style={{ marginLeft: '10px' }} type={TYPOGRAPHY_TYPES.p}>
                        {`-${metricAdditionalAmount}`}
                    </Typography>
                    <CustomSvgIcon iconSet={{ icon: greenArrowUp }} svg></CustomSvgIcon>
                </StyledYTDActiveMemberContainer>
            )
        } else if (Number(metricAdditionalAmount) === 0) {
            return
        }
    }

    return (
        <StyledYTDMetricItemContainer isClickable={handleClick != undefined} onClick={handleClick}>
            
            <StyledYTDMetricIconWrap>
                <CustomSvgIcon iconSet={{ icon: svgIcon }} svg></CustomSvgIcon>
            </StyledYTDMetricIconWrap>
            <StyledYTDMetricTitleAndItemWrap>
                <StyledYTDMetricTitleWrap>
                    <Typography type={TYPOGRAPHY_TYPES.p}>{metricTitle}</Typography>
                </StyledYTDMetricTitleWrap>
                <StyledYTDMetricItemWrap>
                    <Typography type={TYPOGRAPHY_TYPES.p}>{metricAmount}</Typography>
                    {changeInMembers()}
                </StyledYTDMetricItemWrap>
            </StyledYTDMetricTitleAndItemWrap>
        </StyledYTDMetricItemContainer>
    )
}

export default SingleMetricItemGroup
