import { useLocation, Link as LinkRouter } from 'react-router-dom'

import { StyledAsideWrap, StyledAsideIcon, StyledAsideLabel, linkStyle } from './index.style'

import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { appendImpersonatedUserId } from 'utilities/impersonationUtilities'
import { ERoles } from 'models/enums/role'

export interface ISidebarItem {
    path: string
    alternatePath?: string
    title: string
    icon: SVGElement | string
    onNavigating?: (path: string) => void
    roles?: ERoles[]
}

export enum ESidebarTitle {
    DASHBOARD = 'Dashboard',
    USERS = 'Users',
    ADMINS = 'Admins',
    PATIENTS = 'Patients',
    AWVForms = 'AWV Forms',
    REPORTS = 'Reports'
}

export const SidebarItem = ({ path, title, icon, alternatePath, onNavigating }: ISidebarItem) => {
    const location = useLocation()

    const handleActive = (path: string) => {
        var isActivePath = location.pathname === path || location.pathname.includes(path)
        isActivePath =
            isActivePath ||
            (alternatePath && (location.pathname === alternatePath || location.pathname.includes(alternatePath)))
        return isActivePath
    }

    const handleClick = () => {
        if (onNavigating) {
            onNavigating(path)
        }
    }

    return (
        <LinkRouter
            to={appendImpersonatedUserId(path)}
            style={linkStyle}
            target={title === 'Pulse8' ? '_blank' : ''}
            onClick={handleClick}
        >
            <StyledAsideWrap>
                <StyledAsideIcon active={handleActive(path)}>
                    <CustomSvgIcon iconSet={{ icon: icon }} svg />
                </StyledAsideIcon>
                <StyledAsideLabel active={handleActive(path)}>
                    <Typography type={TYPOGRAPHY_TYPES.p}>{title}</Typography>
                </StyledAsideLabel>
            </StyledAsideWrap>
        </LinkRouter>
    )
}
