import styled from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { white } = UI_COLORS

export const StyledGapsInCareOuterContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const StyledGapsInCareContainer = styled.article`
    width: ${pxToRem(1145)};
    margin-bottom: ${pxToRem(10)};
`

export const StyledGapsInCareTableHeader = styled.ul`
    list-style: none;
    background-color: #f7f8fa;
    border: 1px solid #dee5ef;
    padding: ${pxToRem(11)} ${pxToRem(30)};

    display: flex;
    flex-direction: row;
    margin: 0;
`

export const StyledGapsInCareTableHeaderItem = styled.li`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
    color: #73839c;

    :nth-child(1) {
        width: ${pxToRem(305)};
    }

    :nth-child(2) {
        width: ${pxToRem(110)};
    }

    :nth-child(3) {
        width: ${pxToRem(110)};
    }

    :nth-child(4) {
        width: ${pxToRem(110)};
    }

    :nth-child(5) {
        width: ${pxToRem(150)};
    }

    :nth-child(5) {
        width: ${pxToRem(500)};
    }
`

export const StyledGapsInCareTableBody = styled.ul`
    width: ${pxToRem(1145)};
    margin: 0;
    list-style: none;

    display: flex;
    flex-direction: row;
    align-items: stretch;

    padding: 0;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #293854;

    background-color: ${white};
`

export const StyledGapsInCareTableBodyItem = styled.li<{ showBottomBorder: boolean }>`
    display: flex;
    align-items: center;
    padding: ${pxToRem(19)} 0 ${pxToRem(19)} ${pxToRem(3)};

    ${(props) => {
        if (props.showBottomBorder) {
            return 'border-bottom: 1px solid #dee5ef;'
        }
    }}

    :nth-child(1) {
        width: ${pxToRem(330)};
        padding-left: ${pxToRem(30)};
        border-left: 1px solid #dee5ef;
    }

    :nth-child(2) {
        width: ${pxToRem(110)};
    }

    :nth-child(3) {
        width: ${pxToRem(110)};
    }

    :nth-child(4) {
        width: ${pxToRem(110)};
    }

    :nth-child(5) {
        width: ${pxToRem(530)};
        border-right: 1px solid #dee5ef;
        padding-right: ${pxToRem(30)};
    }
`

export const StyledGapsInCareTableBodyDocumentsItem = styled.li`
    display: flex;
    align-items: center;
    padding: ${pxToRem(4)} ${pxToRem(30)} ${pxToRem(9)} ${pxToRem(35)};
    border-left: 1px solid #dee5ef;
    border-bottom: 1px solid #dee5ef;
    border-right: 1px solid #dee5ef;
    width: ${pxToRem(1190)};
`

export const StyledRefreshContainer = styled.article`
    width: ${pxToRem(50)};
    height: ${pxToRem(50)};
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledSpinnerContainer = styled.div`
    margin-top: ${pxToRem(8)};
    margin-left: ${pxToRem(-7)};
`

export const StyledNoGapsInCareContainer = styled.p`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(20)};
    color: #293854;
`

export const StyledExplanatoryNoteContainer = styled.p`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(13)};
    font-style: italic;
    line-height: ${pxToRem(20)};
    color: #293854;
    margin-top: 0;
    padding: 0 ${pxToRem(5)} 0 ${pxToRem(5)};

    a {
        color: #2281c4;
        text-decoration: none;
        cursor: pointer;
    }
`
