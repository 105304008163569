import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { getMsalConfig } from './config/auth'
import { store } from './redux/store'
import { Provider } from 'react-redux'

import './index.css'
import App from './App'

export const msalInstance = new PublicClientApplication(getMsalConfig())

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container!)

root.render(
    <MsalProvider instance={msalInstance}>
        <Provider store={store}>
            <App />
        </Provider>
    </MsalProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
