import { FC } from 'react'
import { StyledStandaloneLabelWrapper } from './index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { ISectionElement, IStandaloneLabel } from '../index.models'

interface IStandaloneLabelProps {
    label: string
}

const StandaloneLabel: FC<IStandaloneLabelProps> = ({ label }) => (
    <StyledStandaloneLabelWrapper>
        <Typography type={TYPOGRAPHY_TYPES.p}>{label}</Typography>
    </StyledStandaloneLabelWrapper>
)

export const renderStandaloneLabel = (element: ISectionElement, key: string) => {
    const standaloneLabel = element as IStandaloneLabel
    return <StandaloneLabel key={key} {...standaloneLabel} />
}
