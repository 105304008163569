import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem, DEVICE } from 'utilities'

import logOut from 'assets/logout.svg'
import logo from 'assets/logo.png'
import hcplogo from 'assets/hcp_logo.svg'

const { light_blue, white, gray2, grey } = UI_COLORS

export const StyledMainNav = styled.nav<{ isMirroring?: boolean }>`
    background-color: ${white};
    position: fixed;
    z-index: 10;
    top: 0px;
    left: 0;
    width: 100%;
    height: ${pxToRem(72)};

    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: ${pxToRem(20)};

    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);

    ${(props) => {
        if (props.isMirroring) {
            return css`
                top: ${pxToRem(65)};
            `
        }
    }}

    ${DEVICE.tablet} {
        left: ${pxToRem(74)};
        height: ${pxToRem(60)};
        padding-left: ${pxToRem(0)};
    }

    ${DEVICE.desktop} {
        left: ${pxToRem(235)};
    }
`
export const StyledAside = styled.aside<{ open?: boolean; isMirroring?: boolean }>`
    background-color: ${white};
    position: fixed;
    z-index: 10;
    inset: 0px auto 0px 0px;
    width: 100%;
    height: 100%;
    transition: all 500ms ease-in-out 0s;
    top: ${pxToRem(72)};
    left: ${({ open }) => (open ? '0' : '-100%')};
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);

    ${(props) => {
        if (props.isMirroring) {
            return css`
                top: ${pxToRem(65)};
            `
        }
    }}

    ${DEVICE.tablet} {
        width: ${pxToRem(74)};
        left: 0;
        top: 0;

        ${(props) => {
            if (props.isMirroring) {
                return css`
                    top: ${pxToRem(65)};
                `
            }
        }}
    }

    ${DEVICE.desktop} {
        width: ${pxToRem(235)};
    }
`
export const StyledMainContainer = styled.main<{ isMirroring?: boolean }>`
    padding-top: ${pxToRem(75)};
    min-height: 100vh;
    background-color: ${light_blue};

    html {
        overflow-x: hidden;
        margin-right: calc(-1 * (100vw - 100%));
    }

    ${(props) => {
        if (props.isMirroring) {
            return css`
                padding-top: ${pxToRem(120)};
            `
        }
    }}

    ${DEVICE.tablet} {
        padding-left: ${pxToRem(74)};
        padding-top: ${pxToRem(62)};
    }

    ${DEVICE.desktop} {
        /* padding-top: ${pxToRem(72)}; */
        padding-top: ${pxToRem(60)};
        padding-left: ${pxToRem(235)};

        ${(props) => {
            if (props.isMirroring) {
                return css`
                    padding-top: ${pxToRem(120)};
                `
            }
        }}
    }
`
export const StyledMenu = styled.button<{ open?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 24px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;

    margin-right: ${pxToRem(20)};

    &:focus {
        outline: none;
    }

    div {
        width: 26px;
        height: 2px;
        background: ${({ open }) => (open ? gray2 : gray2)};
        border-radius: ${pxToRem(10)};
        transition: all 0.3s linear;
        position: relative;
        transform-origin: ${pxToRem(1)};

        :first-child {
            transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
        }

        :nth-child(2) {
            opacity: ${({ open }) => (open ? '0' : '1')};
            transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
        }

        :nth-child(3) {
            transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
        }
    }

    ${DEVICE.tablet} {
        display: none;
    }
`

export const StyledLogoImageMobile = styled.article<{ image: string; mobile?: boolean }>`
    height: ${pxToRem(30)};
    width: ${pxToRem(280)};

    background-repeat: no-repeat;
    background-size: contain;

    margin-right: ${pxToRem(14)};

    ${(props) => {
        if (props.image) {
            return css`
                background-image: url(${props.image});
                transform: translateY(2px);
            `
        }
    }}

    ${DEVICE.tablet} {
        ${(props) => {
            if (props.mobile) {
                return css`
                    display: none;
                `
            }
        }}

        margin-left: ${pxToRem(15)};
        margin-top: ${pxToRem(15)};
        margin-bottom: ${pxToRem(64)};
    }
`

export const StyledLogoImage = styled.article`
    display: none;
    width: ${pxToRem(40)};
    height: ${pxToRem(40)};

    background-repeat: no-repeat;
    background-size: contain;

    ${DEVICE.tablet} {
        display: block;
        margin-left: ${pxToRem(15)};
        margin-top: ${pxToRem(15)};
        margin-bottom: ${pxToRem(50)};
        background-image: url(${logo});
    }

    ${DEVICE.desktop} {
        width: ${pxToRem(240)};
        height: ${pxToRem(40)};
        margin-left: ${pxToRem(20)};
        margin-top: ${pxToRem(30)};
        background-image: url(${hcplogo});
    }
`

export const StyledMainNavWrapper = styled.article`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    ${DEVICE.tablet} {
        width: calc(100% - 74px);
        height: 100%;
    }

    ${DEVICE.desktop} {
        width: calc(100% - 235px);
    }
`

export const StyledAvatarMenu = styled.article`
    max-width: ${pxToRem(300)};
    height: ${pxToRem(40)};
    display: flex;
    align-items: center;
    border-left: 1px solid ${grey};
    padding-left: ${pxToRem(16)};

    p {
        max-width: ${pxToRem(180)};
        margin-right: ${pxToRem(5)};
        margin-left: ${pxToRem(5)};
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: ${pxToRem(14)};
        font-weight: 500;
        /* overflow-wrap: break-word; */
    }

    cursor: pointer;
`

export const StyledDropDownButton = styled.button<{ buttonImg?: string; active?: boolean }>`
    height: ${pxToRem(30)};
    width: ${pxToRem(30)};

    background: none;
    border: none;
    cursor: pointer;

    ${(props) => {
        if (props.buttonImg) {
            return css`
                background-image: url(${props.buttonImg});
            `
        }
    }}

    ${(props) => {
        if (props.active) {
            return css`
                transform: rotate(180deg) translateX(5px) translateY(5px);
            `
        }
    }}
`
export const StyledLogOutButton = styled.button`
    position: fixed;
    top: ${pxToRem(70)};
    right: ${pxToRem(30)};

    background-color: ${grey};
    border: none;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
    border-radius: ${pxToRem(4)};
    cursor: pointer;

    width: ${pxToRem(150)};
    height: ${pxToRem(50)};

    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
    color: ${white};

    display: flex;
    align-items: center;
    justify-content: center;

    &:active {
        background: #73839c;
    }
`
export const StyledIcon = styled.article`
    width: ${pxToRem(30)};
    height: ${pxToRem(30)};
    background-image: url(${logOut});
    background-size: contain;
`

export const StyledMirrorControlBar = styled.article`
    width: 100%;
    height: ${pxToRem(65)};
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;

    background-color: #dee5ef;
    z-index: 10;

    html {
        overflow-x: hidden;
        margin-right: calc(-1 * (100vw - 100%));
    }
`

export const StyledUserMirroringDetails = styled.article`
    height: ${pxToRem(40)};
    margin-right: ${pxToRem(8)};

    background-color: #ffffff;
    border-radius: ${pxToRem(4)};
    padding: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(20)};
    color: #089171;

    figure {
        display: none !important;
    }

    span {
        font-weight: 600;
        color: #293854;
    }

    ${DEVICE.tablet} {
        margin-right: ${pxToRem(16)};

        figure {
            display: flex !important;
            margin: 0;
            margin-right: ${pxToRem(10)};
        }
    }
`

export const StyledEndMirroring = styled.button`
    width: ${pxToRem(123)};
    height: ${pxToRem(40)};

    background: #2281c4;
    border: none;
    border-radius: ${pxToRem(4)};

    cursor: pointer;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
    color: #ffffff;

    ${DEVICE.tablet} {
        width: ${pxToRem(175)};
    }
`

export const StyledMainMenuContainer = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    position: fixed;
    top: ${pxToRem(70)};
    right: ${pxToRem(30)};

    width: 210px;

    background: #ffffff;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
`

export const StyledMainMenuItem = styled.li`
    width: 100%;
    height: 50px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #293854;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    padding-left: 20px;

    :hover {
        background-color: #ebeff7;
    }

    cursor: pointer;

    figure {
        margin: 0;
        margin-right: 10px;
    }
`

export const StyledErrorButton = styled.button`
    margin-top: 30px;
    width: 300px;
    height: 50px;
    font-family: 'Inter', sans-serif;
    font-weight: 800;
    font-size: 24px;
    background-color: rgb(34, 129, 196);
    color: white;
    border-radius: 10px;
    border: none;

    :hover {
        cursor: pointer;
    }
`

export const StyledErrorMessage = styled.article`
    font-family: 'Inter', sans-serif;
    font-style: bold;
    font-weight: 800;
    font-size: 96px;
    line-height: 96px;
    margin-bottom: 40px;
`

export const StyledSubErrorMessage = styled.article`
    font-family: 'Inter', sans-serif;
    font-style: bold;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
`

export const StyledErrorMessageContainer = styled.article`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
`

export const StyledBackButton = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;

    border: none;
    background-color: transparent;
    margin-left: 25px;
    position: absolute;

    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #2281c4;
    }

    figure {
        margin: 0;
        margin-right: 20px;
    }

    cursor: pointer;
`
