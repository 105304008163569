import { FC, useState } from 'react'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { StyledFormTypeButton, StyledFormTypeButtonContainer } from './index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { formTypeIcon } from './icons'

interface IFormTypeButtonProps {
    formTypeName: string
    changeFormType: () => void
}

const FormTypeButton: FC<IFormTypeButtonProps> = ({ formTypeName, changeFormType }) => {
    const [isActive, setIsActive] = useState<boolean>(false)

    return (
        <StyledFormTypeButtonContainer>
            <StyledFormTypeButton
                onMouseEnter={() => setIsActive(true)}
                onMouseLeave={() => setIsActive(false)}
                isActive={isActive}
                onClick={changeFormType}
            >
                <CustomSvgIcon iconSet={{ icon: formTypeIcon }} svg></CustomSvgIcon>
                {isActive ? <Typography type={TYPOGRAPHY_TYPES.span}>{formTypeName}</Typography> : null}
            </StyledFormTypeButton>
        </StyledFormTypeButtonContainer>
    )
}

export default FormTypeButton
