import styled from 'styled-components'
import { UI_COLORS } from 'theme/constants'
import { pxToRem } from 'utilities/style'

const { dark_gray } = UI_COLORS

export const StyledDiagnosisHeadingContainer = styled.article`
    position: relative;

    h3 {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(22)};

        letter-spacing: -0.01em;
        color: ${dark_gray};
    }
`
