import styled, { css } from 'styled-components'
import { DEVICE, pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { dark_gray, white, grey } = UI_COLORS

export const StyledMemberProfileContainerWrap = styled.article`
    padding-top: ${pxToRem(20)};
    padding-left: ${pxToRem(30)};
    max-width: 1205px;
    margin: 0 auto;
`

export const StyledSearchBarContainer = styled.article`
    width: 100%;
    height: ${pxToRem(50)};
    margin-bottom: ${pxToRem(16)};
    margin-right: ${pxToRem(200)};

    ${DEVICE.tablet} {
        width: 50%;
        margin-bottom: ${pxToRem(0)};
    }
`

export const StyledHeader = styled.article`
    display: flex;
    flex-direction: row;
    padding-top: ${pxToRem(0)};
    padding-right: ${pxToRem(30)};
    padding-bottom: ${pxToRem(25)};

    p {
        flex-grow: 1;
        margin: ${pxToRem(5)} 0 0 0;

        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(0)};
        letter-spacing: -0.01em;
        color: ${dark_gray};

        display: flex;
        align-items: center;

        ${DEVICE.tablet} {
            font-size: ${pxToRem(26)};
            line-height: ${pxToRem(31)};
        }
    }
`

export const StyledActionsContainer = styled.article`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${pxToRem(30)};
    padding-right: ${pxToRem(30)};

    ${DEVICE.tablet} {
        flex-direction: row;
        padding-right: 0;
        height: ${pxToRem(70)};
    }

    ${DEVICE.desktop} {
        width: ${pxToRem(1145)};
    }
`
export const StyledCalendarIcon = styled.div<{ image?: string }>`
    position: relative;
    &:after {
        content: '';
        position: absolute;
        transform: TranslateX(-100%);
        width: 20px;
        height: 70px;
        top: 15px;
        left: 35px;
        width: ${pxToRem(16)};
        height: ${pxToRem(16)};
        z-index: 1;
        border: 0;
        background-size: cover;
        cursor: pointer;

        ${(props) => {
            if (props.image) {
                return css`
                    background-image: url(${props.image});
                `
            }
        }};
    }
`
export const StyledCalendarDropDownContainer = styled.div`
    margin-right: ${pxToRem(3)};
`
