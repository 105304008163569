import styled from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem, DEVICE } from 'utilities'

const { dark_gray } = UI_COLORS

export const StyledAdminPageContainer = styled.article`
    padding-left: ${pxToRem(30)};
`

export const StyledHeader = styled.article`
    p {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(22)};
        letter-spacing: -0.01em;
        color: ${dark_gray};

        ${DEVICE.tablet} {
            font-size: 26px;
            line-height: 31px;
        }
    }
`
export const StyledTableWrap = styled.article`
    overflow: auto;
    padding-right: ${pxToRem(30)};
`
