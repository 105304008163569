import { logError } from 'redux/slices/system'
import { dispatch } from 'redux/store'

interface IProcessErrorParams {
    showModal?: boolean
    modalErrorMessage?: string
}

export const processError = (error: any, params?: IProcessErrorParams) => {
    dispatch(
        logError({
            error,
            responseData: error.response?.data,
            showModal: params?.showModal === false ? false : true,
            modalErrorMessage: params?.modalErrorMessage
        })
    )
}
