import { FC, useEffect, useRef, useState } from 'react'
import {
    StyledChatList,
    StyledChatListItemLeft,
    StyledChatListItemRight,
    StyledChatListItemContainer,
    StyledFeedbackButtonContainer,
    StyledFeedbackContainer,
    StyledFeedbackTitleContainer,
    StyledFeedbackUserLeft,
    StyledFeedbackUserRight,
    StyledFeedbackTextSeparator,
    StyledFeedbackTextEntry,
    StyledFeedbackDateEntryRight,
    StyledFeedbackDateEntryLeft,
    StyledEmptyStateContainer
} from '../../Quality/FeedbackModalDialog/index.style'
import { StyledCancelButton } from '../../../AWV/index.style'
import { StyledConfirmButton } from 'components/ConfirmationBox/index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import AdjustableModal from 'components/AdjustableModal/AdjustableModal'
import { setFormattedDateTimeWithSlash } from 'components/UsersTable/index.utils'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { emptyFeedback } from '../../Quality/FeedbackModalDialog/icons'
import { useChatScroll } from 'hooks/useChatScroll'
import { ERoleLabel } from 'models/enums/role'
import { IFeedbackItem } from 'pages/hcp/users/create/index.models'

interface IFeedbackProps {
    feedback: IFeedbackItem[]
    isLoading: boolean
    isSending: boolean
    onSend: (feedback: string) => void
    onClose: () => void
}

enum EFeedbackRole {
    INTERNAL = 'Internal',
    EXTERNAL = 'External'
}

export const FeedbackModalDialog: FC<IFeedbackProps> = ({ feedback, isLoading, isSending, onSend, onClose }) => {
    const addFeedbackControlRef = useRef(null)

    const [feedbackFromUser, setFeedbackFromUser] = useState<string>('')

    const ref = useChatScroll(feedback)

    useEffect(() => {
        if (addFeedbackControlRef?.current) {
            addFeedbackControlRef.current.focus()
        }
    }, [])

    const handleSendFeedback = () => {
        onSend(feedbackFromUser)
        setFeedbackFromUser('')
    }

    return (
        <AdjustableModal onClose={onClose} minHeight={650} minWidth={700}>
            <StyledFeedbackContainer>
                <StyledFeedbackTitleContainer>
                    <Typography type={TYPOGRAPHY_TYPES.h2}>Feedback </Typography>
                </StyledFeedbackTitleContainer>

                {feedback?.length < 0 || feedback?.length === 0 ? (
                    <StyledEmptyStateContainer>
                        <Typography type={TYPOGRAPHY_TYPES.h3}>No Feedback Provided</Typography>
                        <Typography type={TYPOGRAPHY_TYPES.p}>
                            Once the feedback is added, it will show up here.
                        </Typography>
                        <CustomSvgIcon iconSet={{ icon: emptyFeedback }} svg></CustomSvgIcon>
                    </StyledEmptyStateContainer>
                ) : (
                    <StyledChatListItemContainer ref={ref as any}>
                        <StyledChatList>
                            {feedback &&
                                feedback?.map((feedback: IFeedbackItem, index: number) => {
                                    return feedback.bySide === EFeedbackRole.INTERNAL ? (
                                        <div>
                                            <StyledChatListItemLeft key={index}>
                                                {feedback.feedbackText}
                                            </StyledChatListItemLeft>
                                            <StyledFeedbackUserLeft>
                                                <div>{`${feedback.byName} - HCP`}</div>
                                                <StyledFeedbackDateEntryLeft>
                                                    {`${setFormattedDateTimeWithSlash(feedback.feedbackDate)}`}
                                                </StyledFeedbackDateEntryLeft>
                                            </StyledFeedbackUserLeft>
                                        </div>
                                    ) : (
                                        <div>
                                            <StyledChatListItemRight key={index}>
                                                {feedback.feedbackText}
                                            </StyledChatListItemRight>
                                            <StyledFeedbackUserRight>
                                                <div>
                                                    {`${feedback.byName} - ${
                                                        Object.values(ERoleLabel)[Number(feedback.byRoleEnum)]
                                                    }`}
                                                </div>
                                                <StyledFeedbackDateEntryRight>
                                                    {`${setFormattedDateTimeWithSlash(feedback.feedbackDate)}`}
                                                </StyledFeedbackDateEntryRight>
                                            </StyledFeedbackUserRight>
                                        </div>
                                    )
                                })}
                        </StyledChatList>
                    </StyledChatListItemContainer>
                )}

                <StyledFeedbackTextSeparator />
                <StyledFeedbackTextEntry
                    ref={addFeedbackControlRef}
                    value={feedbackFromUser}
                    disabled={isLoading || isSending}
                    onChange={(e) => setFeedbackFromUser(e.target.value)}
                    placeholder="Enter your comments here..."
                    maxLength={995}
                >
                    {feedbackFromUser}
                </StyledFeedbackTextEntry>
                <StyledFeedbackButtonContainer>
                    <StyledCancelButton onClick={onClose}>Close</StyledCancelButton>
                    <StyledConfirmButton
                        disabled={isLoading || isSending || feedbackFromUser.length <= 0}
                        onClick={() => handleSendFeedback()}
                        color={'#2281C4'}
                    >
                        Send
                    </StyledConfirmButton>
                </StyledFeedbackButtonContainer>
            </StyledFeedbackContainer>
        </AdjustableModal>
    )
}
