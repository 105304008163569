import styled, { css } from 'styled-components'
import { DEVICE, pxToRem } from 'utilities'

export const StyledPaginationContainerWrap = styled.article`
    width: 100%;
    max-width: ${pxToRem(1145)};
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding-bottom: ${pxToRem(30)};
`

export const StyledPaginationContainer = styled.article`
    flex-grow: 1;
    width: ${pxToRem(327)};
`

export const StyledPaginationControl = styled.ul`
    display: flex;
    height: ${pxToRem(40)};
    margin: 0;
    padding: 0;

    ${DEVICE.tablet} {
        width: ${pxToRem(450)};
    }
`
export const StyledPaginationControllers = styled.li<{ isActive?: boolean }>`
    list-style: none;
    display: flex;
    border: 1px solid #dee5ef;
    border-right: 0;
    background: #ffffff;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #051226;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;

    ${(props) => {
        if (props.isActive) {
            return css`
                font-weight: 600;
            `
        }
    }}

    cursor: pointer;

    :nth-child(1) {
        width: ${pxToRem(50)};
        height: ${pxToRem(40)};
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        figure {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    :nth-child(2) {
        width: ${pxToRem(50)};
        height: ${pxToRem(40)};

        figure {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    :nth-child(3),
    :nth-child(4),
    :nth-child(5),
    :nth-child(6),
    :nth-child(7) {
        width: ${pxToRem(40)};
        height: ${pxToRem(40)};
    }

    :nth-child(8) {
        width: ${pxToRem(50)};
        height: ${pxToRem(40)};
    }

    :nth-child(9) {
        width: ${pxToRem(50)};
        height: ${pxToRem(40)};

        border: 1px solid #dee5ef;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
`

export const StyledInformationContainer = styled.article`
    display: flex;
    flex-direction: row;
    justify-content: top;
`

export const StyledInformation = styled.p`
    margin: auto;
    color: #73839c;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
`
