import styled from 'styled-components'
import { DEVICE, pxToRem } from 'utilities/style'

export const StyledFeedbackTitleContainer = styled.div`
    margin-top: ${pxToRem(-30)};
    margin-bottom: ${pxToRem(0)};
    margin-left: ${pxToRem(10)};
    display: flex;
    flex-direction: row;

    h2 {
        margin-right: 5px;

        :nth-child(2) {
            color: #73839c;
            font-weight: 400;
        }
    }
`

export const StyledFeedbackContainer = styled.div`
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    margin-left: 0px;
`

export const StyledChatList = styled.ul`
    list-style-type: none;
`

export const StyledChatListItemLeft = styled.li`
    display: flex;
    width: 470px;
    height: 100%;
    align-items: left;
    justify-content: flex-start;
    background-color: #2281c4;
    color: #fff;
    padding: 10px;
    border-radius: 10px 10px 10px 0px;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-bottom: 0px;
    margin-top: 15px;
    margin-right: 150px;
    margin-left: -30px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
`

export const StyledChatListItemRight = styled.li`
    display: flex;
    width: 470px;
    height: 100%;
    justify-content: flex-start;
    background-color: #dee5ef;
    color: #394a64;
    padding: 10px;
    border-radius: 10px 10px 0px 10px;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-left: 140px;
    margin-right: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
`

export const StyledFeedbackUserLeft = styled.li`
    display: flex;
    align-items: left;
    justify-content: flex-start;
    color: #394a64;
    padding: 10px;
    border-radius: 10px 10px 10px 0px;
    padding-bottom: 5px;
    padding-top: 0px;
    margin-left: -40px;
    margin-bottom: 15px;
    margin-top: 5px;
    margin-right: 140px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    white-space: nowrap;
`

export const StyledFeedbackUserRight = styled.li`
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    border-radius: 10px 10px 0px 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    margin-left: 135px;
    margin-right: 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    white-space: nowrap;
`

export const StyledFeedbackDateEntryLeft = styled.div`
    display: flex;
    align-items: right;
    justify-content: flex-end;
    width: 100%;
    text-align: right;
    color: #a4b0c0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    margin-right: 30px !important;
`

export const StyledFeedbackDateEntryRight = styled.div`
    display: flex;
    align-items: right;
    justify-content: flex-end;
    width: 100%;
    text-align: right;
    color: #a4b0c0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    margin-right: 0px !important;
`

export const StyledChatListItemContainer = styled.div`
    ${DEVICE.tablet} {
        width: 100%;
        height: 377px;
        overflow-y: auto;
    }
`

export const StyledFeedbackButtonContainer = styled.article`
    display: flex;
    flex-direction: row;
    margin-top: ${pxToRem(10)};
    justify-content: flex-end;

    ${DEVICE.tablet} {
        bottom: 59px;
        right: 30px;
    }
`

export const StyledFeedbackTextSeparator = styled.div`
    position: relative;
    margin-top: 30px;
    margin-bottom: 10px;
    width: 100%;
    /* Grey/200 */
    border: 1px solid #dee5ef;
`

export const StyledFeedbackTextEntry = styled.textarea`
    width: 100%;
    margin-top: 20px;
    margin-left: 0px;
    margin-right: 30px;
    margin-bottom: 10px;
    height: 110px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: none;
    font-family: Arial, sans-serif;
    font-size: 14px;
`

export const StyledEmptyStateContainer = styled.article`
    margin-top: 100px;
    margin-bottom: 100px;
    h3 {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #293854;
        margin-bottom: 8px;
    }

    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        color: #73839c;
        margin: 0;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
