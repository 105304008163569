import { FC, useEffect, useState } from 'react'
import {
    StyledAddLink,
    StyledCalendarIcon,
    StyledDropdownContainer,
    StyledEmptyState,
    StyledGapsInCareContainer,
    StyledGapsInCareDetailsContainer,
    StyledGapsInCareTitle
} from './index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import DropDownList, { IOption } from 'components/DropDownList'
import { calendarDropDownListStyleParams } from 'components/TaxIdProviderInfo/index.style'
import smallCalendar from 'assets/smallCalendar.svg'
import useCurrentUser from 'hooks/useCurrentUser'
import useMemberInfoFromUrl from 'hooks/useMemberInfoFromUrl'
import { ERoles } from 'models/enums/role'
import { useSelector } from 'react-redux'
import {
    clearReferralSpecialist,
    createGapInCare,
    deleteDocument,
    deleteGapInCare,
    downloadDocument,
    GapsInCareState,
    getAvailableGaps,
    getGapInCare,
    getGapsInCare,
    getMemberIdNpiKey,
    getMemberIdYearKey,
    getMemberIdYearNpiKey,
    getMemberNpiYears,
    getMemberYearProviders,
    ICreateGapInCareArgs,
    setReferralSpecialist,
    updateGapInCare,
    uploadDocument
} from 'redux/slices/gapsInCare'
import Spinner from 'components/Spinner'
import { dispatch } from 'redux/store'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { noData } from 'pages/provider/memberOutreach/icons'
import {
    IGapInCare,
    IGapInCareDocument,
    IGapsInCareYearProvider,
    IMemberProfileDetails,
    IReferralSpecialist,
    IUpdateGapInCareRequest
} from 'pages/hcp/users/create/index.models'
import GapsInCareTable from './GapsInCareTable'
import UploadFileModal from 'components/UploadFileModal'
import { hideMainWindowScrollbars } from 'utilities'
import ConfirmationBox from 'components/ConfirmationBox'
import { UI_COLORS } from 'theme'
import {
    addGapInCareFeedback,
    FeedbackState,
    getGapInCareFeedback,
    markGapInCareFeedbackRead
} from 'redux/slices/feedback'
import { FeedbackModalDialog } from '../PointsInCare/ReviewTable/FeedbackDialog'
import isYearOpenForAwv from 'utilities/isYearOpenForAwv'
import ReferralSpecialistModal from './ReferralSpecialistModal'
import GapInCareModal, { GapInCareModalMode } from './GapInCareModal'
import usePostCustomEvent from 'hooks/usePostCustomEvent'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import { fetchMemberProfileDetail, MemberProfileState } from 'redux/slices/memberProfile'
import { setCurrentNpi } from 'redux/slices/pulse8'

const internalUserCalendarDropDownListStyleParams = {
    ...calendarDropDownListStyleParams,
    width: 280
}

interface IGapsInCareProps {
    setIsFormLoading: (isFormLoading: boolean) => void
}

const GapsInCare: FC<IGapsInCareProps> = ({ setIsFormLoading }) => {
    const [memberId, npi] = useMemberInfoFromUrl()

    const memberProfileDetails: IMemberProfileDetails = useSelector(
        (state: { memberProfile: MemberProfileState }) => state.memberProfile.memberProfileDetails
    )

    const currentUser = useCurrentUser()
    const isUserInternal = [ERoles.CallCenterAdmin, ERoles.ProviderRep, ERoles.Quality].includes(
        currentUser?.primaryRole
    )

    const {
        yearProvidersByMemberId,
        yearsByMemberIdNpi,
        gapsInCareByMemberIdYearNpi,
        gapsInCareById,
        availableGapsByMemberIdYear
    } = useSelector((state: { gapsInCare: GapsInCareState }) => state.gapsInCare)
    const yearProviders = yearProvidersByMemberId[memberId]
    const years = yearsByMemberIdNpi[getMemberIdNpiKey(memberId, npi)]

    const { gapInCareFeedback } = useSelector((state: { feedback: FeedbackState }) => state.feedback)

    const dropDownDataAvailable = isUserInternal ? (yearProviders ? true : false) : years ? true : false

    const dropDownOptions: IOption[] = dropDownDataAvailable
        ? isUserInternal
            ? yearProviders.map((yearProvider, index) => ({
                  label: `${yearProvider.year} - ${yearProvider.providerLastName}, ${yearProvider.providerFirstName}`,
                  value: index
              }))
            : years.map((year, index) => ({ label: year.toString(), value: index }))
        : []

    const [isBusy, setIsBusy] = useState(false)
    const [documentUploading, setDocumentUploading] = useState(false)
    const [feedbackLoading, setFeedbackLoading] = useState(false)
    const [feedbackSending, setFeedbackSending] = useState(false)

    const [selectedGapInCare, setSelectedGapInCare] = useState<IGapInCare>(null)
    const [selectedDocument, setSelectedDocument] = useState<IGapInCareDocument>(null)

    const [selectedDropDownOptionIndex, setSelectedDropDownOptionIndex] = useState(0)
    const [selectedYear, setSelectedYear] = useState<number>(null)
    const [selectedYearProvider, setSelectedYearProvider] = useState<IGapsInCareYearProvider>(null)

    const [selectReferralSpecialistModalShown, setSelectReferralSpecialistModalShown] = useState(false)
    const [clearReferralSpecialistModalShown, setClearReferralSpecialistModalShown] = useState(false)
    const [feedbackModalShown, setFeedbackModalShown] = useState(false)
    const [uploadDocumentModalShown, setUploadDocumentModalShown] = useState(false)
    const [deleteDocumentModalShown, setDeleteDocumentModalShown] = useState(false)
    const [viewGapInCareModalShown, setViewGapInCareModalShown] = useState(false)
    const [editGapInCareModalShown, setEditGapInCareModalShown] = useState(false)
    const [createGapInCareModalShown, setCreateGapInCareModalShown] = useState(false)
    const [deleteGapInCareModalShown, setDeleteGapInCareModalShown] = useState(false)

    const gapsInCare =
        (isUserInternal
            ? selectedYearProvider
                ? gapsInCareByMemberIdYearNpi[
                      getMemberIdYearNpiKey(memberId, selectedYearProvider.year, selectedYearProvider.providerNpi)
                  ]
                : null
            : selectedYear
            ? gapsInCareByMemberIdYearNpi[getMemberIdYearNpiKey(memberId, selectedYear, npi)]
            : null) ?? []

    const [gapsInCareLoading, setGapsInCareLoading] = useState(false)

    const effectiveNpi = isUserInternal ? selectedYearProvider?.providerNpi : npi
    const effectiveYear = isUserInternal ? selectedYearProvider?.year : selectedYear
    const effectiveYearOpen = effectiveYear ? isYearOpenForAwv(effectiveYear) : false

    const changeYearEvent = usePostCustomEvent(pageTitle.PatientsGapsInCare, customEvents.ChangeYear)
    const changeYearProviderEvent = usePostCustomEvent(pageTitle.PatientsGapsInCare, customEvents.ChangeYearProvider)
    const uploadDocumentEvent = usePostCustomEvent(pageTitle.PatientsGapsInCare, customEvents.UploadDocument)
    const viewDocumentEvent = usePostCustomEvent(pageTitle.PatientsGapsInCare, customEvents.ViewDocument)
    const deleteDocumentEvent = usePostCustomEvent(pageTitle.PatientsGapsInCare, customEvents.DeleteDocument)
    const setReferralSpecialistEvent = usePostCustomEvent(
        pageTitle.PatientsGapsInCare,
        customEvents.SetReferralSpecialist
    )
    const clearReferralSpecialistEvent = usePostCustomEvent(
        pageTitle.PatientsGapsInCare,
        customEvents.ClearReferralSpecialist
    )
    const createGapInCareEvent = usePostCustomEvent(pageTitle.PatientsGapsInCare, customEvents.CreateGapInCare)
    const viewGapInCareEvent = usePostCustomEvent(pageTitle.PatientsGapsInCare, customEvents.ViewGapInCare)
    const updateGapInCareEvent = usePostCustomEvent(pageTitle.PatientsGapsInCare, customEvents.UpdateGapInCare)
    const deleteGapInCareEvent = usePostCustomEvent(pageTitle.PatientsGapsInCare, customEvents.DeleteGapInCare)
    const viewFeedbackEvent = usePostCustomEvent(pageTitle.PatientsGapsInCare, customEvents.ViewFeedback)
    const sendFeedbackEvent = usePostCustomEvent(pageTitle.PatientsGapsInCare, customEvents.SendFeedback)

    useEffect(() => {
        dispatch(setCurrentNpi(npi))
        if (memberProfileDetails == null || +memberProfileDetails?.memberId !== memberId) {
            dispatch(fetchMemberProfileDetail({ memberId, npi })).then(() => {
                setIsFormLoading(false)
            })
        } else {
            setIsFormLoading(false)
        }
    }, [memberId, npi, memberProfileDetails])

    useEffect(() => {
        if (!dropDownDataAvailable) {
            if (isUserInternal) {
                dispatch(getMemberYearProviders({ memberId }))
            } else {
                dispatch(getMemberNpiYears({ memberId, npi }))
            }
        }
    }, [dropDownDataAvailable, isUserInternal, memberId, npi])

    useEffect(() => {
        if (dropDownDataAvailable) {
            if (isUserInternal) {
                setSelectedYearProvider(yearProviders[selectedDropDownOptionIndex])
            } else {
                setSelectedYear(years[selectedDropDownOptionIndex])
            }
        }
    }, [dropDownDataAvailable, yearProviders, years, selectedDropDownOptionIndex])

    useEffect(() => {
        if (selectedYearProvider) {
            setGapsInCareLoading(true)
            dispatch(
                getGapsInCare({
                    memberId,
                    year: selectedYearProvider.year,
                    npi: selectedYearProvider.providerNpi
                })
            ).then(() => setGapsInCareLoading(false))
        } else if (selectedYear) {
            setGapsInCareLoading(true)
            dispatch(getGapsInCare({ memberId, year: selectedYear, npi })).then(() => setGapsInCareLoading(false))
        }
    }, [isUserInternal, memberId, npi, selectedYear, selectedYearProvider])

    useEffect(() => {
        hideMainWindowScrollbars(
            selectReferralSpecialistModalShown ||
                feedbackModalShown ||
                uploadDocumentModalShown ||
                deleteDocumentModalShown ||
                createGapInCareModalShown ||
                editGapInCareModalShown ||
                viewGapInCareModalShown
        )
    }, [
        selectReferralSpecialistModalShown,
        feedbackModalShown,
        uploadDocumentModalShown,
        deleteDocumentModalShown,
        createGapInCareModalShown,
        editGapInCareModalShown,
        viewGapInCareModalShown
    ])

    const handleSelectedYearProviderChanged = (index: number) => {
        if (index !== selectedDropDownOptionIndex) {
            if (isUserInternal) {
                changeYearProviderEvent()
            } else {
                changeYearEvent()
            }
        }
        setSelectedDropDownOptionIndex(index)
    }

    const handleChangeReferralSpecialist = (gapInCare: IGapInCare) => {
        setSelectedGapInCare(gapInCare)
        setSelectReferralSpecialistModalShown(true)
    }

    const performSetReferralSpecialist = (referralSpecialist: IReferralSpecialist) => {
        setReferralSpecialistEvent()
        setSelectReferralSpecialistModalShown(false)
        setIsBusy(true)
        dispatch(
            setReferralSpecialist({ gapInCareId: selectedGapInCare.id, referralSpecialist: referralSpecialist })
        ).finally(() => setIsBusy(false))
    }

    const handleClearReferralSpecialist = (gapInCare: IGapInCare) => {
        setSelectedGapInCare(gapInCare)
        setClearReferralSpecialistModalShown(true)
    }

    const performClearReferralSpecialist = () => {
        clearReferralSpecialistEvent()
        setClearReferralSpecialistModalShown(false)
        setIsBusy(true)
        dispatch(clearReferralSpecialist({ gapInCareId: selectedGapInCare.id })).finally(() => setIsBusy(false))
    }

    const handleView = (gapInCare: IGapInCare) => {
        viewGapInCareEvent()
        setSelectedGapInCare(gapInCare)
        setIsBusy(true)
        dispatch(getGapInCare({ gapInCareId: gapInCare.id, npi: effectiveNpi }))
            .then((x) => {
                if (x.type === getGapInCare.fulfilled.type) {
                    setViewGapInCareModalShown(true)
                }
            })
            .finally(() => setIsBusy(false))
    }

    const handleEdit = (gapInCare: IGapInCare) => {
        setSelectedGapInCare(gapInCare)
        setIsBusy(true)
        dispatch(getGapInCare({ gapInCareId: gapInCare.id, npi: effectiveNpi }))
            .then((x) => {
                if (x.type === getGapInCare.fulfilled.type) {
                    setEditGapInCareModalShown(true)
                }
            })
            .finally(() => setIsBusy(false))
    }

    const performUpdate = (args: IUpdateGapInCareRequest) => {
        updateGapInCareEvent()
        setEditGapInCareModalShown(false)
        setIsBusy(true)
        dispatch(updateGapInCare(args)).finally(() => setIsBusy(false))
    }

    const handleFeedback = (gapInCare: IGapInCare) => {
        viewFeedbackEvent()
        setSelectedGapInCare(gapInCare)
        setFeedbackLoading(true)
        setFeedbackModalShown(true)
        const request = { gapInCareId: gapInCare.id, npi: effectiveNpi }
        dispatch(markGapInCareFeedbackRead(request))
        dispatch(getGapInCareFeedback(request)).finally(() => setFeedbackLoading(false))
    }

    const handleSendFeedback = (feedbackText: string) => {
        sendFeedbackEvent()
        setFeedbackSending(true)
        dispatch(
            addGapInCareFeedback({
                gapInCareId: selectedGapInCare.id,
                npi: effectiveNpi,
                parentId: null,
                feedbackText
            })
        ).finally(() => setFeedbackSending(false))
    }

    const handleDownloadDocument = (document: IGapInCareDocument) => {
        viewDocumentEvent()
        setIsBusy(true)
        dispatch(downloadDocument({ gapInCareDocumentId: document.id })).finally(() => setIsBusy(false))
    }

    const handleDeleteDocument = (document: IGapInCareDocument) => {
        setSelectedDocument(document)
        setDeleteDocumentModalShown(true)
    }

    const performDocumentDeletion = () => {
        deleteDocumentEvent()
        setDeleteDocumentModalShown(false)
        setIsBusy(true)
        dispatch(deleteDocument({ gapInCareDocumentId: selectedDocument.id })).finally(() => setIsBusy(false))
    }

    const handleUploadDocument = (gapInCare: IGapInCare) => {
        setSelectedGapInCare(gapInCare)
        setUploadDocumentModalShown(true)
    }

    const performDocumentUpload = (file: any) => {
        uploadDocumentEvent()
        setDocumentUploading(true)
        dispatch(uploadDocument({ gapInCareId: selectedGapInCare.id, file })).finally(() => {
            setDocumentUploading(false)
            setUploadDocumentModalShown(false)
        })
    }

    const handleAdd = () => {
        setIsBusy(true)
        dispatch(getAvailableGaps({ memberId, year: effectiveYear }))
            .then((x) => {
                if (x.type === getAvailableGaps.fulfilled.type) {
                    setCreateGapInCareModalShown(true)
                }
            })
            .finally(() => {
                setIsBusy(false)
            })
    }

    const performCreate = (args: ICreateGapInCareArgs) => {
        createGapInCareEvent()
        setCreateGapInCareModalShown(false)
        setIsBusy(true)
        dispatch(createGapInCare(args)).finally(() => setIsBusy(false))
    }

    const handleDeleteGapInCare = (gapInCare: IGapInCare) => {
        setSelectedGapInCare(gapInCare)
        setDeleteGapInCareModalShown(true)
    }

    const performDeleteGapInCare = () => {
        deleteGapInCareEvent()
        setDeleteGapInCareModalShown(false)
        setIsBusy(true)
        dispatch(deleteGapInCare({ gapInCareId: selectedGapInCare.id })).finally(() => setIsBusy(false))
    }

    return dropDownDataAvailable ? (
        <>
            {selectReferralSpecialistModalShown && (
                <ReferralSpecialistModal
                    gapInCare={selectedGapInCare.gap}
                    currentReferralSpecialistId={selectedGapInCare.referralSpecialistId}
                    onSelect={performSetReferralSpecialist}
                    onCancel={() => setSelectReferralSpecialistModalShown(false)}
                />
            )}

            {clearReferralSpecialistModalShown && (
                <ConfirmationBox
                    title="Remove Referral Specialist"
                    body={`Would you like to remove the referral specialist (${selectedGapInCare.referralFullName} - ${selectedGapInCare.referralSpecialty}) for ${selectedGapInCare.gap}?`}
                    confirmButtonLabel="Remove"
                    confirmButtonColor={UI_COLORS.red}
                    onConfirm={performClearReferralSpecialist}
                    onCancel={() => setClearReferralSpecialistModalShown(false)}
                />
            )}

            {feedbackModalShown && (
                <FeedbackModalDialog
                    feedback={gapInCareFeedback[selectedGapInCare.id] ?? []}
                    isLoading={feedbackLoading}
                    isSending={feedbackSending}
                    onSend={handleSendFeedback}
                    onClose={() => setFeedbackModalShown(false)}
                />
            )}

            {uploadDocumentModalShown && (
                <UploadFileModal
                    title="Upload Gap in Care Document"
                    isUploading={documentUploading}
                    onUpload={performDocumentUpload}
                    onCancel={() => setUploadDocumentModalShown(false)}
                />
            )}

            {deleteDocumentModalShown && (
                <ConfirmationBox
                    title="Delete Gap in Care Document"
                    body={`Are you sure you want to delete ${selectedDocument.fileName}?`}
                    confirmButtonLabel="Delete"
                    confirmButtonColor={UI_COLORS.red}
                    onConfirm={performDocumentDeletion}
                    onCancel={() => setDeleteDocumentModalShown(false)}
                />
            )}

            {createGapInCareModalShown && (
                <GapInCareModal
                    mode={GapInCareModalMode.Create}
                    memberId={memberId}
                    year={effectiveYear}
                    npi={effectiveNpi}
                    gaps={availableGapsByMemberIdYear[getMemberIdYearKey(memberId, effectiveYear)] ?? []}
                    onCreate={performCreate}
                    onCancelOrClose={() => setCreateGapInCareModalShown(false)}
                />
            )}

            {viewGapInCareModalShown && gapsInCareById[selectedGapInCare.id] && (
                <GapInCareModal
                    mode={GapInCareModalMode.View}
                    gapInCare={gapsInCareById[selectedGapInCare.id]}
                    onCancelOrClose={() => setViewGapInCareModalShown(false)}
                />
            )}

            {editGapInCareModalShown && gapsInCareById[selectedGapInCare.id] && (
                <GapInCareModal
                    mode={GapInCareModalMode.Edit}
                    npi={effectiveNpi}
                    gapInCare={gapsInCareById[selectedGapInCare.id]}
                    onUpdate={performUpdate}
                    onCancelOrClose={() => setEditGapInCareModalShown(false)}
                />
            )}

            {deleteGapInCareModalShown && (
                <ConfirmationBox
                    title="Delete Gap in Care"
                    body={`Are you sure you want to delete ${selectedGapInCare.gap}?`}
                    confirmButtonLabel="Delete"
                    confirmButtonColor={UI_COLORS.red}
                    onConfirm={performDeleteGapInCare}
                    onCancel={() => setDeleteGapInCareModalShown(false)}
                />
            )}

            <StyledGapsInCareContainer>
                {dropDownOptions.length > 0 ? (
                    <>
                        <StyledGapsInCareDetailsContainer>
                            <StyledGapsInCareTitle>
                                <Typography type={TYPOGRAPHY_TYPES.h3}>Gaps In Care</Typography>
                            </StyledGapsInCareTitle>
                            <StyledDropdownContainer>
                                <>
                                    <Typography type={TYPOGRAPHY_TYPES.p}>
                                        {isUserInternal ? 'Year/Provider:' : 'Year:'}
                                    </Typography>
                                    <StyledCalendarIcon image={smallCalendar} />
                                    <DropDownList
                                        options={dropDownOptions}
                                        isDisabled={isBusy}
                                        selectedValue={selectedDropDownOptionIndex}
                                        setSelectedValue={handleSelectedYearProviderChanged}
                                        styleParams={
                                            isUserInternal
                                                ? internalUserCalendarDropDownListStyleParams
                                                : calendarDropDownListStyleParams
                                        }
                                    />
                                </>
                            </StyledDropdownContainer>
                        </StyledGapsInCareDetailsContainer>
                        {!gapsInCareLoading ? (
                            <GapsInCareTable
                                gapsInCare={gapsInCare}
                                yearOpen={effectiveYearOpen}
                                isReadOnly={isUserInternal}
                                isBusy={isBusy}
                                onChangeReferralSpecialist={handleChangeReferralSpecialist}
                                onClearReferralSpecialist={handleClearReferralSpecialist}
                                onView={handleView}
                                onEdit={handleEdit}
                                onDelete={handleDeleteGapInCare}
                                onFeedback={handleFeedback}
                                onDownloadDocument={handleDownloadDocument}
                                onDeleteDocument={handleDeleteDocument}
                                onUploadDocument={handleUploadDocument}
                            />
                        ) : (
                            <Spinner />
                        )}
                        {!isUserInternal && (
                            <StyledAddLink onClick={handleAdd} disabled={isBusy || !effectiveYearOpen}>
                                + Add
                            </StyledAddLink>
                        )}
                    </>
                ) : (
                    <StyledEmptyState>
                        <CustomSvgIcon iconSet={{ icon: noData }} svg></CustomSvgIcon>
                        <Typography type={TYPOGRAPHY_TYPES.h4}>Data not available at the moment</Typography>
                        <Typography type={TYPOGRAPHY_TYPES.p}>Please check back later</Typography>
                    </StyledEmptyState>
                )}
            </StyledGapsInCareContainer>
        </>
    ) : (
        <Spinner />
    )
}

export default GapsInCare
