import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem, DEVICE } from 'utilities'

const { light_blue2, grey, white, red } = UI_COLORS

export const StyledAsideContainer = styled.nav`
    padding-left: ${pxToRem(20)};
`

export const StyledAsideWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${pxToRem(30)};
    height: 20px;

    text-decoration: none;
    border-left: 3px solid transparent;

    :hover {
        border-left: 3px solid ${light_blue2};
    }
`

export const StyledAsideIcon = styled.article<{ active?: boolean }>`
    height: ${pxToRem(30)};
    width: ${pxToRem(30)};
    margin-right: ${pxToRem(15)};

    figure {
        margin: 0;
    }

    ${(props) => {
        if (props.active) {
            return css`
                color: ${light_blue2};
                figure {
                    svg {
                        path {
                            fill: ${light_blue2};
                        }
                        g {
                            path {
                                stroke: ${light_blue2};
                                fill: none;
                            }
                        }
                    }
                }
            `
        }
    }}
`

export const StyledAsideLabel = styled.article<{ active?: boolean }>`
    font-size: 16px;
    color: ${grey};

    ${(props) => {
        if (props.active) {
            return css`
                color: ${light_blue2};
                figure {
                    svg {
                        path {
                            fill: ${light_blue2};
                        }
                    }
                }
            `
        }
    }}

    ${DEVICE.tablet} {
        display: none;
    }

    ${DEVICE.desktop} {
        display: block;
    }
`

export const linkStyle = {
    textDecoration: 'none'
}

export const StyledPulse8SideNavButton = styled.button`
    ${DEVICE.mobile} {
        display: none;
    }

    ${DEVICE.desktop} {
        display: block;
        border: 0;
        background-color: ${light_blue2};
        width: ${pxToRem(189)};
        height: ${pxToRem(50)};
        border-radius: ${pxToRem(4)};

        font-size: ${pxToRem(16)};
        font-weight: 600;
        color: ${white};

        :active {
            background-color: ${white};
            border: 1px solid ${light_blue2};
            color: ${light_blue2};
        }

        cursor: pointer;
    }
`
