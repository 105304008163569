import {
    StyledTableQuestionnaireBodyItemTotals,
    StyledTableQuestionnaireContainer,
    StyledTableQuestionnaireHeader,
    StyledTableQuestionnaireHeaderItem,
    StyledResetContainer,
    StyledTableQuestionnaireBodyItemRight,
    StyledTableQuestionnaireBodyItemMiddle,
    StyledTableQuestionnaireBodyItemLeft,
    StyledTableQuestionnaireRow
} from './index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import {
    IScoredMultipleChoiceList,
    IScoredMultipleChoiceListItem,
    IScoredMultipleChoiceListOption,
    IScoredMultipleChoiceListOutcome,
    ISectionElement
} from '../index.models'
import { FC, useEffect } from 'react'
import { performFieldSynchronizationStep } from '../utils'
import { AwvTooltip } from '../AwvTooltip'
import useErrors from 'hooks/useErrors'
import IconButton from '@mui/material/IconButton'
import { Tooltip } from '@mui/material'
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp'
import { placeholderResetStringValue, useResetValue } from 'hooks/useResetValue'

interface IScoredMultipleChoiceListProps {
    name: string
    titleColumnLabel: string
    titleColumnWidth: number
    titleColumnMargin: number
    totalScoreLabel: string
    options: IScoredMultipleChoiceListOption[]
    coreItems: IScoredMultipleChoiceListItem[]
    items: IScoredMultipleChoiceListItem[]
    outcomes: IScoredMultipleChoiceListOutcome[]
}

interface IBodyItemProps {
    right: boolean
    width: number
    marginRight: number
    children: any
}

const overallWidth = 1145

const ScoredMultipleChoiceList: FC<IScoredMultipleChoiceListProps> = ({
    name,
    titleColumnLabel,
    titleColumnWidth,
    titleColumnMargin,
    totalScoreLabel,
    options,
    coreItems,
    items,
    outcomes
}) => {
    const { control, watch, setValue, getValues } = useFormContext()
    const errors = useErrors()
    const resetValue = useResetValue(true)
    const { fields, append, remove, move } = useFieldArray({ control, name })

    const isCoreItem = (itemName: string) => coreItems.some((item) => item.name === itemName)

    const hideNonCoreItems =
        watch(name)
            ?.filter((x: any) => isCoreItem(x.name))
            .some((x: any) => x.value !== 0) === false

    const totalScore = watch(name)?.some((x: any) => x.value !== null && x.value !== undefined)
        ? watch(name).reduce((acc: number, item: any) => acc + (item.value && !isNaN(item.value) ? item.value : 0), 0)
        : null

    const outcome =
        totalScore !== null && totalScore !== undefined ? outcomes.find((x) => x.maxScore >= totalScore)?.outcome : null

    useEffect(() => {
        performFieldSynchronizationStep(
            hideNonCoreItems ? coreItems : [...coreItems, ...items],
            fields,
            append,
            remove,
            move
        )
    }, [coreItems, items, watch, fields, append, remove, move, hideNonCoreItems])

    const itemsByName = [...coreItems, ...items].reduce((acc: any, item: any) => {
        acc[item.name] = item
        return acc
    }, {})

    const BodyItem: FC<IBodyItemProps> = ({ right, width, marginRight, children }) => {
        if (right) {
            const columnWidth =
                overallWidth -
                titleColumnWidth -
                titleColumnMargin -
                options.reduce((acc, option) => acc + option.columnWidth + option.columnMargin, 0) +
                width +
                marginRight
            return (
                <StyledTableQuestionnaireBodyItemRight style={{ width: `${columnWidth}px` }}>
                    {children}
                </StyledTableQuestionnaireBodyItemRight>
            )
        } else {
            return (
                <StyledTableQuestionnaireBodyItemMiddle style={{ width: `${width + marginRight}px` }}>
                    {children}
                </StyledTableQuestionnaireBodyItemMiddle>
            )
        }
    }

    return (
        <StyledTableQuestionnaireContainer>
            <StyledTableQuestionnaireHeader>
                <StyledTableQuestionnaireHeaderItem
                    key={`header-${name}-title`}
                    style={{ width: `${titleColumnWidth}px`, marginRight: `${titleColumnMargin}px` }}
                >
                    <Typography type={TYPOGRAPHY_TYPES.p}>{titleColumnLabel}</Typography>
                </StyledTableQuestionnaireHeaderItem>
                {options.map((option: IScoredMultipleChoiceListOption, idx: number) => (
                    <StyledTableQuestionnaireHeaderItem
                        key={`header-${name}-${idx}`}
                        style={{ width: `${option.columnWidth}px`, marginRight: `${option.columnMargin}px` }}
                    >
                        <Typography type={TYPOGRAPHY_TYPES.p}>{option.label}</Typography>
                    </StyledTableQuestionnaireHeaderItem>
                ))}
            </StyledTableQuestionnaireHeader>
            {fields.map((field: any, index: number) => (
                <AwvTooltip key={`tooltip.SMCL.${name}.${index}`} message={errors.getError(`${name}.${index}`)}>
                    <StyledTableQuestionnaireRow key={field.id}>
                        <StyledTableQuestionnaireBodyItemLeft
                            isInError={errors.getError(`${name}.${index}`)}
                            style={{
                                width: `${titleColumnWidth + titleColumnMargin}px`,
                                paddingRight: `${titleColumnMargin}px`
                            }}
                        >
                            <Typography type={TYPOGRAPHY_TYPES.p}>
                                {`${index + 1}. ${itemsByName[field.name].label}`}
                            </Typography>
                        </StyledTableQuestionnaireBodyItemLeft>
                        {options.map((option: IScoredMultipleChoiceListOption, optionIndex: number) => (
                            <BodyItem
                                key={`option-${field.id}-${optionIndex}`}
                                right={optionIndex === options.length - 1}
                                width={option.columnWidth}
                                marginRight={option.columnMargin}
                            >
                                <Controller
                                    name={`${name}.${index}.value`}
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            id={`${name}.${index}.${option.value}`}
                                            {...field}
                                            type="radio"
                                            value={option.value}
                                            checked={field.value === option.value}
                                            onChange={() => setValue(`${name}.${index}.value`, option.value)}
                                        />
                                    )}
                                />
                            </BodyItem>
                        ))}
                        <StyledResetContainer>
                            {getValues(`${name}.${index}.value`) !== undefined &&
                                getValues(`${name}.${index}.value`) !== placeholderResetStringValue && (
                                    <Tooltip title="Reset">
                                        <IconButton
                                            onClick={() => {
                                                resetValue(`${name}.${index}.value`)
                                            }}
                                        >
                                            <HighlightOffSharpIcon sx={{ color: '#9e9e9e' }} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                        </StyledResetContainer>
                    </StyledTableQuestionnaireRow>
                </AwvTooltip>
            ))}
            <StyledTableQuestionnaireRow>
                <StyledTableQuestionnaireBodyItemTotals>
                    <Typography type={TYPOGRAPHY_TYPES.p}>{totalScoreLabel}</Typography>
                </StyledTableQuestionnaireBodyItemTotals>
                <StyledTableQuestionnaireBodyItemTotals>
                    <input readOnly={true} disabled={true} value={totalScore ?? ''} />
                </StyledTableQuestionnaireBodyItemTotals>
                <StyledTableQuestionnaireBodyItemTotals>
                    <input readOnly={true} disabled={true} value={outcome ?? ''} />
                </StyledTableQuestionnaireBodyItemTotals>
            </StyledTableQuestionnaireRow>
        </StyledTableQuestionnaireContainer>
    )
}

export const renderScoredMultipleChoiceList = (element: ISectionElement, key: string) => {
    const scoredMultipleChoiceList = element as IScoredMultipleChoiceList
    return <ScoredMultipleChoiceList key={key} {...scoredMultipleChoiceList} />
}
