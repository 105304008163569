import { FC } from 'react'
import { IRepeater, ISectionElement } from '../index.models'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { StyledAddLink, StyledDeleteLink, StyledRepeaterItemContainer } from './index.style'

interface IRepeaterProps {
    baseKey: string
    name: string
    addLinkLabel: string
    deleteLinkLabel: string
    elements: ISectionElement[]
    renderSectionElement: (element: ISectionElement, key: string, parentName?: string) => JSX.Element
}

const Repeater: FC<IRepeaterProps> = ({
    name,
    baseKey,
    addLinkLabel,
    deleteLinkLabel,
    elements,
    renderSectionElement
}) => {
    const { control } = useFormContext()
    const { fields, append, remove } = useFieldArray({ control, name })

    const handleAdd = (e: any) => {
        e.preventDefault()
        append({})
    }

    const handleRemove = (e: any, index: number) => {
        e.preventDefault()
        remove(index)
    }

    return (
        <>
            {fields.map((field: any, fieldIndex: number) => (
                <StyledRepeaterItemContainer key={field.id}>
                    {elements.map((element, elementIndex) => {
                        const parentName = `${name}.${fieldIndex}`
                        const untypedElement = element as any
                        const namedElement = (
                            untypedElement['name']
                                ? { ...untypedElement, name: `${parentName}.${untypedElement['name']}` }
                                : untypedElement
                        ) as ISectionElement
                        return renderSectionElement(
                            namedElement,
                            `${baseKey}-${fieldIndex}-${elementIndex}`,
                            parentName
                        )
                    })}
                    <StyledDeleteLink onClick={(e) => handleRemove(e, fieldIndex)}>{deleteLinkLabel}</StyledDeleteLink>
                </StyledRepeaterItemContainer>
            ))}
            <StyledAddLink onClick={handleAdd}>{addLinkLabel}</StyledAddLink>
        </>
    )
}

export const renderRepeater = (
    element: ISectionElement,
    key: string,
    renderSectionElement: (element: ISectionElement, key: string, parentName?: string) => JSX.Element
) => {
    const repeater = element as IRepeater
    return <Repeater key={key} baseKey={key} {...repeater} renderSectionElement={renderSectionElement} />
}
